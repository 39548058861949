/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Form, Input, Row, Col, Modal, DatePicker, Spin,
} from 'antd';
import {
  DATE_FORMAT,
} from 'utils/formatters';
import { postDeclaracionAlcoholes, patchGenerarDeclaracion, postRechazarDeclaracion } from 'api/recaudacion';
import { SyncOutlined } from '@ant-design/icons';
import { Button, Text } from 'components';
import Select from 'components/PaginatedSelect';
import _logger from 'utils/logger';
import propTypes from 'prop-types';
import { RFC_PATTERN, CURP_PATTERN } from 'utils/patterns';
import ModalConfirm from './ModalConfirm';

const _rules = {
  required: (message) => ({
    required: true,
    message,
  }),
  numeric: [
    {
      validator: async (_, value) => {
        const regex = /^[0-9]+([.][0-9]{1,2})?$/;
        if (!value) return Promise.resolve();
        if (!Number.isInteger(parseFloat(value))) {
          return Promise.reject(new Error('Solo se permiten números enteros positivos.'));
        }
        if (!regex.test(value)) {
          return Promise.reject(new Error('Solo se permiten números positivos.'));
        }
        return Promise.resolve();
      },
    },
  ],
  mayorCero: [
    {
      validator: async (_, value) => {
        if (value <= 0) {
          throw new Error('El valor debe ser mayor a 0.');
        }
      },
    },
  ],
  entero: [
    {
      validator: async (_, value) => {
        const regex = /^[0-9]+$/;
        if (!value) return Promise.resolve();
        if (!regex.test(value)) {
          return Promise.reject(new Error('Solo se permiten números enteros positivos.'));
        }
        return Promise.resolve();
      },
    },
  ],
  rfc: [{ pattern: RFC_PATTERN, message: ' Ingrese un RFC valido.' }],
  curp: [{ pattern: CURP_PATTERN, message: ' Ingrese una CURP valida.' }],
};

const FormVentaBebidasAlcoholicas = ({
  padron,
  statusDeclaracion,
  visible,
  setVisibleForm,
  selectedCargos,
  isComplementaria,
  callback,
  callbackCancel,
  declaracionId,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isZeroState, setIsZeroState] = useState(false);
  const [disableInput, setDisableInput] = useState(false);
  const [porcentajePeriodo, setPorcentajePeriodo] = useState(0);

  const handleCancel = async () => {
    setLoading(true);
    await postRechazarDeclaracion({
      declaracion: declaracionId,
      canal_de_pago: 5,
    });
    setLoading(false);
  };

  const handleUnload = (event) => {
    const message = 'Se perderán los datos capturados, ¿Estás seguro de salir?';
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.returnValue = message; // Esto mostrará el cuadro de confirmación
    return message;
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (visible) {
      window.addEventListener('unload', handleUnload);
      window.addEventListener('beforeunload', handleCancel);

      return () => {
        window.removeEventListener('unload', handleUnload);
        window.removeEventListener('beforeunload', handleCancel);
      };
    }
  }, [visible]);

  // Resetea el formulario cuando se cierra.
  useEffect(() => {
    if (visible) {
      const _porcentaje = ((selectedCargos[0]?.importe || 5) / 100);
      setPorcentajePeriodo(_porcentaje);
      form.resetFields();
    }
  }, [visible]);

  function redondear(numero) {
    const decimal = numero - Math.floor(numero);

    if (decimal >= 0.51) {
      return Math.ceil(numero);
    }
    return Math.floor(numero);
  }

  const patchDeclaracionStatus = async (values) => {
    try {
      let statusId = null;

      const statusObject = {
        generada: statusDeclaracion.find((status) => !status.declaracion_presentada && !status.declaracion_en_ceros)?.id,
        en_ceros: statusDeclaracion.find((status) => status.declaracion_en_ceros)?.id,
        sin_importe: statusDeclaracion.find((status) => status.descripcion === 'Declaración con Cantidades a Pagar y a Favor en Cero')?.id || 0,
        saldo_favor: statusDeclaracion.find((status) => status.descripcion === 'Declaración con Cantidad a Favor')?.id || 0,
      };

      if (isZeroState) {
        statusId = statusObject.en_ceros;
      } else if (parseFloat(values.cantidad_a_favor) > 0) {
        statusId = statusObject.saldo_favor;
      } else if (parseFloat(values.cantidad_a_pagar) <= 0) {
        statusId = statusObject.sin_importe;
      } else {
        statusId = statusObject.generada;
      }
      const request = await patchGenerarDeclaracion({
        id: values.declaracion_empresarial,
        estatus_de_declaracion_empresarial: statusId,
        razones_para_presentar_en_ceros: values.razones_para_presentar_en_ceros,
      });

      if (!request) throw new Error('Error al actualizar la declaración.');
    } catch (error) {
      _logger(error);
    }
  };

  // Envia los datos del formulario.
  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();

      const _padronValues = {
        razon_social_nombre_completo: padron?.empresa?.contribuyente?.razon_social || '',
        rfc: padron?.empresa?.contribuyente?.rfc || '',
        curp: padron?.empresa?.contribuyente?.curp || '',
        nombre: padron?.empresa?.contribuyente?.nombre_completo || '',
        nombre_completo: padron?.empresa?.contribuyente?.nombre_completo || '',
        apellido_paterno: padron?.empresa?.contribuyente?.apellido_paterno || '',
        apellido_materno: padron?.empresa?.contribuyente?.apellido_materno || '',
      };

      let _values = {
        ...values,
        ..._padronValues,
        declaracion_empresarial: declaracionId,
        numero_de_sucursales_declaradas: values.numero_de_sucursales_declaradas === '' ? 0 : values.numero_de_sucursales_declaradas,
        entidad: 1,
        fecha_del_pago_anterior: isComplementaria && !isZeroState ? values.fecha_del_pago_anterior.format(DATE_FORMAT) : null,
      };

      if (isZeroState) {
        _values = {
          ..._values,
          actualizacion: 0,
          cantidad_a_favor: 0,
          cantidad_a_pagar: 0,
          impuesto: 0,
          impuesto_a_cargo: 0,
          monto_mensual: 0,
          monto_pagado_con_anterioridad: 0,
          multas: 0,
          recargos: 0,
          retenciones: 0,
          total_de_contribuciones: 0,
        };
      }

      if (!isComplementaria) {
        _values = {
          ..._values,
          fecha_del_pago_anterior: null,
          monto_pagado_con_anterioridad: 0,
        };
      }

      await patchDeclaracionStatus(_values);
      await postDeclaracionAlcoholes(_values);

      callback(_values.cantidad_a_pagar <= 0);
      setIsZeroState(false);
      setIsVisibleModal(false);
      setVisibleForm(false);
    } catch (error) {
      _logger(error);
    } finally {
      setLoading(false);
    }
  };

  const validacionesDeOperaciones = (values) => {
    const retencion_rounded = parseFloat(redondear(values.retenciones)).toFixed(2);
    const impuesto_rounded = parseFloat(redondear(values.impuesto)).toFixed(2);

    // ImpuestosCorrespondiente menos Retenciones = ImpuestoCargo(debe ser mayor igual a cero)
    if ((parseFloat(impuesto_rounded) - parseFloat((retencion_rounded))) < 0 && !isZeroState) {
      form.validateFields(['impuesto_a_cargo']).then(() => {
        form.setFields([
          {
            name: 'impuesto_a_cargo',
            errors: ['El valor no puede ser mayor al impuesto correspondiente.'],
          },
        ]);
      });
      form.validateFields(['retenciones']).then(() => {
        form.setFields([
          {
            name: 'retenciones',
            errors: ['El valor no puede ser mayor al impuesto correspondiente.'],
          },
        ]);
      });
    } else if ((parseFloat(impuesto_rounded) - parseFloat((retencion_rounded))) >= 0 && !isZeroState) {
      form.validateFields(['impuesto_a_cargo']).then(() => {
        form.setFields([
          {
            name: 'impuesto_a_cargo',
            errors: [],
          },
        ]);
      });
      form.validateFields(['retenciones']).then(() => {
        form.setFields([
          {
            name: 'retenciones',
            errors: [],
          },
        ]);
      });
    }

    if ((parseFloat(impuesto_rounded) - parseFloat((retencion_rounded))) <= 0 && !isZeroState) {
      form.setFieldsValue({ actualizacion: 0, recargos: 0, multas: 0 });
    }

    const resultado = parseFloat(impuesto_rounded - retencion_rounded);
    const shouldEnableInput = resultado > 0 && !isZeroState;
    setDisableInput(!shouldEnableInput);
  };

  // Calcula el total de la cantidad a pagar.
  const total = () => {
    const values = form.getFieldsValue();
    const impuesto = parseFloat(values.impuesto) || 0;
    const retenciones = parseFloat(values.retenciones) || 0;
    const actualizacion = parseFloat(values.actualizacion) || 0;
    const recargos = parseFloat(values.recargos) || 0;
    const multas = parseFloat(values.multas) || 0;
    const monto_pagado_con_anterioridad = parseFloat(values.monto_pagado_con_anterioridad) || 0;

    const total_de_contribuciones = parseFloat((impuesto - retenciones) + actualizacion + recargos + multas).toFixed(2);

    const cantidad_a_pagar = parseFloat(total_de_contribuciones - monto_pagado_con_anterioridad).toFixed(2);

    const cantidad_a_pagar_rounded = redondear(parseFloat(cantidad_a_pagar).toFixed(2));
    const impuesto_a_cargo_rounded = redondear(parseFloat(parseFloat(impuesto - retenciones).toFixed(2)).toFixed(2));
    const total_de_contribuciones_rounded = redondear(parseFloat(total_de_contribuciones).toFixed(2));
    const impuesto_rounded = redondear(parseFloat(impuesto).toFixed(2));

    form.setFieldsValue({
      impuesto: parseFloat(impuesto_rounded).toFixed(2),
      impuesto_a_cargo: parseInt(impuesto_a_cargo_rounded, 10),
      total_de_contribuciones: parseFloat(total_de_contribuciones_rounded).toFixed(2),
    });

    const isNegative = parseFloat(total_de_contribuciones).toFixed(2) < monto_pagado_con_anterioridad;

    if (isNegative) {
      const total_a_favor_rounded = redondear(parseFloat((monto_pagado_con_anterioridad - total_de_contribuciones)).toFixed(2));
      form.setFieldsValue({
        cantidad_a_favor: parseFloat(total_a_favor_rounded).toFixed(2),
        cantidad_a_pagar: parseFloat(0).toFixed(2),
      });
    } else {
      form.setFieldsValue({
        cantidad_a_pagar: parseFloat(cantidad_a_pagar_rounded).toFixed(2),
        cantidad_a_favor: parseFloat(0).toFixed(2),
      });
    }

    validacionesDeOperaciones(values);
  };

  return (
    <>
      <Modal
        centered
        title={(
          <Row justify="space-between">
            <Text $accent>
              IMPUESTO A LA VENTA DE BEBIDAS CON CONTENIDO ALCOHÓLICO
            </Text>
          </Row>
        )}
        visible={visible}
        closable={false}
        footer={(
          <Row justify="space-between" gutter={[10, 15]}>
            <Col xs={24} sm={24} md={12} style={{ textAlign: 'left' }}>
              <Text $accent>* Este campo es obligatorio</Text>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Button
                style={{ width: '100%', borderRadius: 5 }}
                onClick={() => callbackCancel()}
                loading={loading}
                $accent
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Button
                type="primary"
                style={{ width: '100%', borderRadius: 5 }}
                onClick={() => {
                  form.validateFields().then(() => {
                    setIsVisibleModal(true);
                  }).catch(() => {
                    setIsVisibleModal(false);
                  });
                }}
                loading={loading}
                icon={<SyncOutlined />}
                $accent
              >
                Generar
              </Button>
            </Col>
          </Row>
      )}
        width="50vw"
      >
        <Spin spinning={loading} tip="Cargando...">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
            initialValues={{
              es_declaracion_en_ceros: false,
              retenciones: 0,
              actualizacion: 0,
              recargos: 0,
              multas: 0,
              cantidad_a_pagar: 0,
              cantidad_a_favor: 0,
              total_de_contribuciones: 0,
              impuesto: 0,
              impuesto_a_cargo: 0,
              monto_pagado_con_anterioridad: isComplementaria ? 0 : 0,
            }}
          >
            <Row gutter={10}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="es_declaracion_en_ceros"
                  label="¿Es Declaración en ceros?"
                  required
                >
                  <Select
                    placeholder="Selecciona una opción"
                    trueFalse
                    allowClear={false}
                    onChange={(value) => setIsZeroState(value)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  hasFeedback
                  name="numero_de_sucursales_declaradas"
                  tooltip="En caso de tener sucursales anotar el número de las que declara"
                  rules={[..._rules.numeric]}
                  label="Número de sucursales"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              { isZeroState ? (
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name="razones_para_presentar_en_ceros"
                    label="Razones por las que presenta en ceros"
                    rules={[_rules.required('El campo es requerido')]}
                  >
                    <Input.TextArea />
                  </Form.Item>
                </Col>
              ) : (
                <Col xs={24} sm={24} md={24}>
                  <table style={{ minWidth: '100%' }}>
                    <thead>
                      <tr>
                        <th>Concepto</th>
                        <th>Monto</th>
                      </tr>
                      <tr>
                        <td>Ingreso mensual</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="monto_mensual"
                            tooltip="Monto mensual por ventas de bebidas con contenido alcohólico"
                            rules={[
                              _rules.required('El campo es requerido'),
                              ..._rules.numeric,
                              ..._rules.mayorCero,
                            ]}
                          >
                            <Input
                              prefix="$"
                              onBlur={(val) => {
                                if (!Number.isInteger(parseFloat(val.target.value))) {
                                  const redondeado = val.target.value - Math.floor(parseFloat(val.target.value));

                                  if (parseFloat(redondeado).toFixed(2) >= 0.51) {
                                    form.setFieldsValue({ monto_mensual: parseFloat(Math.ceil(val.target.value)).toFixed(2) });
                                    form.setFieldsValue({ impuesto: parseFloat(Math.ceil(val.target.value) * porcentajePeriodo).toFixed(2) });
                                  } else {
                                    form.setFieldsValue({ monto_mensual: parseFloat(Math.floor(val.target.value)).toFixed(2) });
                                    form.setFieldsValue({ impuesto: parseFloat(Math.floor(val.target.value) * porcentajePeriodo).toFixed(2) });
                                  }
                                }
                                total();
                              }}
                              onChange={(value) => {
                                if (value.target.value === '') {
                                  form.setFieldsValue({ impuesto: 1 * porcentajePeriodo });
                                } else {
                                  const val_monto = parseFloat(value.target.value) * porcentajePeriodo;
                                  form.setFieldsValue({ impuesto: val_monto.toFixed(2) });
                                }
                                total();
                              }}
                            />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Impuesto correspondiente</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="impuesto"
                            rules={[_rules.required('El campo es requerido'), ..._rules.numeric]}
                          >
                            <Input prefix="$" disabled />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Retenciones</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="retenciones"
                            tooltip="Retenciones realizadas por el comerciante, almacenista o distribuidor"
                            rules={[_rules.required('El campo es requerido'), ..._rules.numeric]}
                          >
                            <Input prefix="$" onChange={() => total()} />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Impuesto a cargo</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="impuesto_a_cargo"
                            id="impuesto_a_cargo"
                            rules={[_rules.required('El campo es requerido'), ..._rules.numeric]}
                          >
                            <Input
                              prefix="$"
                              onChange={() => total()}
                              disabled
                            />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Actualización</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="actualizacion"
                            rules={[_rules.required('El campo es requerido'), ..._rules.numeric]}
                          >
                            <Input
                              prefix="$"
                              disabled={disableInput}
                              onChange={() => total()}
                            />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Recargos</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="recargos"
                            rules={[_rules.required('El campo es requerido'), ..._rules.numeric]}
                          >
                            <Input
                              prefix="$"
                              disabled={disableInput}
                              onChange={() => total()}
                            />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Multas</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="multas"
                            rules={[_rules.required('El campo es requerido'), ..._rules.numeric]}
                          >
                            <Input
                              prefix="$"
                              disabled={disableInput}
                              onChange={() => total()}
                            />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Total de contribuciones</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="total_de_contribuciones"
                            rules={[_rules.required('El campo es requerido'), ..._rules.numeric]}
                          >
                            <Input prefix="$" disabled />
                          </Form.Item>
                        </td>
                      </tr>
                      {
                        isComplementaria && (
                          <>
                            <tr>
                              <td>Fecha del pago anterior</td>
                              <td>
                                <Form.Item
                                  name="fecha_del_pago_anterior"
                                  rules={[_rules.required('El campo es requerido')]}
                                >
                                  <DatePicker format={DATE_FORMAT} />
                                </Form.Item>
                              </td>
                            </tr>
                            <tr>
                              <td>Monto pagado con anterioridad</td>
                              <td>
                                <Form.Item
                                  hasFeedback
                                  name="monto_pagado_con_anterioridad"
                                  rules={[
                                    _rules.required('El campo es requerido'),
                                    ..._rules.numeric,
                                    // ..._rules.noMayorAImpustoCorrespondiente,
                                  ]}
                                >
                                  <Input prefix="$" onChange={() => { total(); }} />
                                </Form.Item>
                              </td>
                            </tr>
                          </>
                        )
                      }
                      <tr>
                        <td>Cantidad a pagar</td>
                        <td>
                          <Form.Item
                            name="cantidad_a_pagar"
                          >
                            <Input prefix="$" disabled />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Cantidad a favor</td>
                        <td>
                          <Form.Item
                            name="cantidad_a_favor"
                          >
                            <Input prefix="$" disabled />
                          </Form.Item>
                        </td>
                      </tr>
                    </thead>
                  </table>
                </Col>
              )}
            </Row>
          </Form>
        </Spin>
      </Modal>
      <ModalConfirm
        visible={isVisibleModal}
        setVisible={setIsVisibleModal}
        onFinish={() => onFinish()}
        body="Al generar la declaración no podrá ser modificada. ¿Desea continuar?"
        btnText="Si, generar declaración"
        loading={loading}
      />
    </>
  );
};

FormVentaBebidasAlcoholicas.propTypes = {
  padron: propTypes.shape({
    empresa: propTypes.shape({
      contribuyente: propTypes.shape({
        razon_social: propTypes.string,
        rfc: propTypes.string,
        nombre_completo: propTypes.string,
        curp: propTypes.string,
        nombre: propTypes.string,
        apellido_paterno: propTypes.string,
        apellido_materno: propTypes.string,
        ciudadano: propTypes.shape({
          curp: propTypes.string,
          nombre: propTypes.string,
          apellido_paterno: propTypes.string,
          apellido_materno: propTypes.string,
        }),
      }),
    }),
  }).isRequired,
  selectedCargos: propTypes.arrayOf(propTypes.shape({
    importe: propTypes.number,
  })),
  statusDeclaracion: propTypes.number.isRequired,
  visible: propTypes.bool,
  isComplementaria: propTypes.bool,
  setVisibleForm: propTypes.func.isRequired,
  callback: propTypes.func.isRequired,
  callbackCancel: propTypes.func,
  declaracionId: propTypes.func.isRequired,
};

FormVentaBebidasAlcoholicas.defaultProps = {
  isComplementaria: false,
  visible: false,
  selectedCargos: [],
  callbackCancel: () => {},
};

export default FormVentaBebidasAlcoholicas;
