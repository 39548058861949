import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Button,
  Grid,
} from 'antd';
import {
  ArrowLeftOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

const ModalDeleteControls = ({ onCancel, onDelete, loading }) => {
  const screens = Grid.useBreakpoint();
  return (
    <Row className="form-submit-controls">
      <Button
        type="link"
        onClick={onCancel}
        disabled={loading}
      >
        <ArrowLeftOutlined />
        {screens.md && 'Cancelar'}
      </Button>
      <Button
        type="link"
        onClick={onDelete}
        disabled={loading}
        style={{ marginLeft: 10 }}
        className="submit-delete"
      >
        <DeleteOutlined />
        {screens.md && 'Eliminar'}
      </Button>
    </Row>
  );
};

ModalDeleteControls.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

ModalDeleteControls.defaultProps = {
  loading: false,
};

export default ModalDeleteControls;
