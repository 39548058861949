import { getTickets } from 'api/recaudacion';
import { ENV_NAME, ENV_NAMES_ENUM } from 'utils/env';

const formatReference = async (recibo = null, tramite_en_proceso = true) => {
  const formatters = {
    [ENV_NAMES_ENUM.JUAREZ]: async () => {
      const ticket = await getTickets([recibo.id], tramite_en_proceso);
      if (ticket.success) {
        return { ...recibo, base64: ticket.base64 };
      }
      return null;
    },
  };
  const formatter = formatters[ENV_NAME] || (() => recibo);
  const response = await formatter();
  return response;
};

export default formatReference;
