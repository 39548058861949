import React, { useState, useEffect, useRef } from 'react';
import {
  Form,
  Col,
  Skeleton,
  Card,
  Row,
  Table,
  message,
  Grid,
  Modal,
  Input,
  Radio,
  Tooltip,
  Dropdown,
  Menu,
  // Button as AntButton,
} from 'antd';
import {
  Title,
  Text,
  Button,
  COLORS,
} from 'components';
import { useParams, Navigate } from 'react-router-dom';
import {
  PlusOutlined,
  CheckOutlined,
  MinusOutlined,
  DeleteOutlined,
  SyncOutlined,
  BarcodeOutlined,
  EyeOutlined,
  SearchOutlined,
  CreditCardOutlined,
  FilePdfOutlined,
  HistoryOutlined,
  DownOutlined,
} from '@ant-design/icons';
import {
  getTiposDeCargos,
  getPeriodosFiscales,
  postGenererCargos,
  postGenererReferenciasNetpay,
  postGenererReferenciasBanco,
  getUMA,
  getSM,
  getDeclaraciones,
  getClasesDeDeclaracion,
  getEstatusDeDeclaracion,
  // getDeclaracionISAN,
  getDeclaracionCRIE,
  // getDeclaracionNotarios,
  getDeclaracionesISAN,
  postRechazarDeclaracion,
  getReciboPorOrden,
} from 'api/recaudacion';
import {
  selectConfiguracionEntidad,
} from 'store/auth';
import store from 'store/store';

import gettersMap, {
  getAlcohol,
  getContenTypePorTipoDePadron,
  getHospedaje,
  getJuegoDeAzar,
  getNomina,
  getNotario,
  getVentaAlcoholById,
  getCedularRetencion,
  getNominaRetencion,
  getArrendamiento,
  getCedular,
  getAgencia,
} from 'api/padrones';
import Previewer from 'components/Previewer';
import toSlug, { extractSegment } from 'utils/toSlug';
import {
  toCurrency,
  // DATE_FORMAT,
  DATE_FRONTEND_FORMAT,
} from 'utils/formatters';
import { scrollToTop } from 'components/ScrollToTop';
import NetPayButton, {
  getNetPayBtn,
  paymentLocationsEnum,
  validateLastTransaction,
} from 'views/Payments/components/NetPayButton';
import SearchForm from 'views/Payments/components/SearchForm';
import WaitingAlert from 'views/Payments/components/WaitingAlert';
import useWaitingAlert from 'hooks/useWaitingAlert';
import debtsFormatter, { debtsColumns } from 'utils/debtsFormatter';
import ModalUpdateInfo from 'views/Payments/components/ModalUpdateInfo';
import Moment from 'moment';
import round from 'utils/round';
import { getAdeudoPadronAsync } from 'hooks/useAdeudoPadron';
import {
  CATALOGOS_DE_CANAL_DE_PAGO,
  ENV_NAME,
  ENV_NAMES_ENUM,
  CLAVE_CATASTRAL_SEGMENTOS,
  ID_ENTIDAD,
  SHOW_REDONDEO,
  USER_PORTAL_ID,
} from 'utils/env';
import useQuery from 'hooks/useQuery';
import { useSelector } from 'react-redux';
import {
  selectClasificadoresDeTiposDeCargos,
  selectContentTypesPadrones,
  selectPadrones,
} from 'store/catalogos';
import ModalBancos from 'views/Payments/components/ModalBancos';
import FormISAN from 'components/FormISAN';
import Spinner from 'components/Spinner';
import FormNotarios from 'components/FormNotarios';
import ModalCRIE from 'components/ModalCRIE';
import ModalRegistralesService from 'views/Payments/components/ModalRegistralesService';
import {
  getDeclaracionAlcoholesPDF,
  getDeclaracionCRIEPDF,
  // getDeclaracionNOTARIOPDF,
  getDeclaracionHospedajePDF,
  getDeclaracionNominasPDF,
  getDeclaracionRetencionNominasPDF,
  getDeclaracionJuegosPDF,
  getDeclaracionCedularProfesionalPDF,
  getDeclaracionCedularArrendamientoPDF,
  getDeclaracionCedularRetenedorPDF,
  getPDFGeneral,
} from 'api/reporteador';

import { aplicacionesCargosEnum, padronesEnum, tiposDatosEnum } from 'views/Tramites/GPM/enums';
import { toMoment } from 'utils/normalizers';
import ModalConfirm from 'components/ModalConfirm';
import { DATETIME_FRONTEND_FORMAT } from 'utils/formatValues';
import _logger from 'utils/logger';
import FormVentaBebidasAlcoholicas from 'components/FormVentaBebidasAlcoholicas';
import FormHospedaje from 'components/FormHospedaje';
import FormNomina from 'components/FormNominas';
import FormJuegos from 'components/FormJuegos';
// import FormCedularProfesional from 'components/FormCedularProfesional';
import FormCedularMensual from 'components/FormCedularMensual';
import FormCedularArrendamiento from 'components/FormCedularArrendamientoMensual';
import FormCedularRetenedor from 'components/FormCedularRetenedor';
import FormRetenedorNomina from 'components/FormRetenedorNomina';

export const DATE_NETPAY_FORMAT = 'YYYY/MM/DD';

const isNayarit = ENV_NAME === ENV_NAMES_ENUM.NAYARIT;

const periodosMap = Object.freeze({
  EVENTUAL: 1,
  DIARIO: 2,
  SEMANAL: 3,
  QUINCENAL: 4,
  MENSUAL: 5,
  BIMESTRAL: 6,
  TRIMESTRAL: 7,
  CUATRIMESTRAL: 8,
  SEMESTRAL: 9,
  ANUAL: 10,
});

const allowedRoutesReports = Object.freeze({
  ISAN: ['impuesto-sobre-automoviles-nuevos-(isan)-mensual-14'],
  CRIE: [
    // 'retencion-de-nominas-28',
    'cedular-por-arrendamiento-bienes-inmuebles-retencion-27',
    // 'cedular-por-servicios-profesionales-retencion-27',
    // 'venta-de-bebidas-con-contenido-alcoholico-26',
  ],
  NOTARIO: ['isr-enajenacion-de-bienes-inmuebles-(notarios)-12'],
  // isr-enajenacion-de-bienes-inmuebles-(notarios)-12
  SERVICIOS_REGISTRALES: ['servicios-registrales-12'],
  VENTA_DE_BEBIDAS_ALCOHOLICAS: ['venta-de-bebidas-con-contenido-alcoholico-26'],
  HOSPEDAJE: ['hospedaje-06'],
  NOMINA: ['nominas-08'],
  JUEGOS: ['sobre-juegos-y-apuestas-permitidas,-sobre-rifas,-loterias-y-sorteos-11'],
  CEDULAR_PROFESIONAL_MENSUAL: ['cedular-por-servicios-profesionales-mensual-10'],
  CEDULAR_ARRENDAMIENTO: ['cedular-por-arrendamiento-bienes-inmuebles-mensual-07'],
  CEDULAR_RETENEDOR: ['retencion-de-impuestos-cedulares-27'],
  RETENEDOR_DE_NOMINA: ['retencion-de-nominas-28'],
});

const filtersExtemporaneos = {
  [ENV_NAMES_ENUM.NAYARIT]: {
    6: '40202', //     Hospedaje
    8: '40102', //     Nomina
    9: '4060802', //   Alcohol
    10: '4060502', //  Cédulas
    11: '40404', //    Juegos de Azar
  },
  [ENV_NAMES_ENUM.JUAREZ]: {},
  [ENV_NAMES_ENUM.HERMOSILLO]: {},
};

export const recievePadronFound = async ({
  padron = null,
  setLoading = null,
  setVisibleModalUpdateInfo = null,
  callback = null,
  inNeedsInfo = {
    setPadronId: () => {},
    setTipoDePadron: () => {},
  },
}) => {
  if (typeof padron.infoNeeded === 'boolean') {
    if (!padron.infoNeeded) {
      if (typeof callback === 'function') {
        await callback();
      }
    }
    if (padron.infoNeeded) {
      setLoading(false);
      setVisibleModalUpdateInfo(true);
      inNeedsInfo.setPadronId(padron.idPadronUpdate);
      inNeedsInfo.setTipoDePadron(padron.tipoDePadronNeedsInfo);
    }
  }
  if (padron.infoNeeded?.error) {
    message.warn(padron.infoNeeded.error, 3);
    setLoading(false);
  }
};

const PaymentFormCompose = () => {
  // Hooks
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const [formFilter] = Form.useForm();
  const {
    classifier: _classifier,
    subclassifier: _subclassifier,
  } = useParams();
  const [tipoDeCargo] = useQuery(['tipoDeCargo']);
  const {
    visibleAlert,
    loading,
    setLoading,
  } = useWaitingAlert();
  const refTitleCargosTable = useRef();
  const clasificadores = useSelector(selectClasificadoresDeTiposDeCargos);
  const contentTypesPadrones = useSelector(selectContentTypesPadrones);
  const padrones = useSelector(selectPadrones);

  // State
  const [periodoFiscal, setPeriodoFiscal] = useState();
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [previewBase64, setPreviewBase64] = useState();
  const [visibleBancos, setVisibleBancos] = useState(false);
  const [isNewFolio, setIsNewFolio] = useState(true);
  const [visiblePreviewNetPay, setVisiblePreviewNetPay] = useState(false);
  const [currentClassifier, setCurrentClassifier] = useState();
  const [padron, setPadron] = useState();
  const [tiposDeCargos, setTiposDeCargos] = useState({ results: [] });
  const [_cargosGenerados, setCargosGenerados] = useState([]);
  const [_cargosGeneradosDiff, setCargosGeneradosDiff] = useState([]);
  const [selectedCargos, setSelectedCargos] = useState([]);
  const [periodosFiscales, setPeriodosFiscales] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [referencia, setReferencia] = useState();
  // const [declaracionPDF, setDeclaracionPDF] = useState();
  const [tipoDePadron, setTipoDePadron] = useState();
  const [UMA, setUMA] = useState();
  const [SM, setSM] = useState();
  const [declaraciones, setDeclaraciones] = useState([]);
  const [claseDeDeclaracion, setClaseDeDeclaracion] = useState([]);
  const [statusDeclaracion, setStatusDeclaracion] = useState([]);
  const declaracionAnterior = useRef('');
  const [declaracionId, setDeclaracionId] = useState(0);

  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [idPadron, setIdPadron] = useState(0);

  const [tipoDePadronId, setTipoDePadronId] = useState(0);
  const [referenciaNormal, setReferenciaNormal] = useState(null);

  const [visibleISAN, setVisibleISAN] = useState(false);
  const [createdISAN, setCreatedISAN] = useState(false);

  const [visibleNotarios, setVisibleNotarios] = useState(false);
  const [createdNotarios, setCreatedNotarios] = useState(false);

  const [visibleVentaBebidas, setVisibleVentaBebidas] = useState(false);
  const [createdVentaBebidas, setCreatedVentaBebidas] = useState(false);

  const [visibleHospedaje, setVisibleHospedaje] = useState(false);
  const [createdHospedaje, setCreatedHospedaje] = useState(false);

  const [visibleNomina, setVisibleNomina] = useState(false);
  const [createdNomina, setCreatedNomina] = useState(false);

  const [visibleRetenedorNomina, setVisibleRetenedorNomina] = useState(false);
  const [createdRetenedorNomina, setCreatedRetenedorNomina] = useState(false);

  const [visibleJuegos, setVisibleJuegos] = useState(false);
  const [createdJuegos, setCreatedJuegos] = useState(false);

  const [visibleCedularArrendamiento, setVisibleCedularArrendamiento] = useState(false);
  const [createdCedularArrendamiento, setCreatedCedularArrendamiento] = useState(false);

  const [visibleCedularMensual, setVisibleCedularMensual] = useState(false);
  const [createdCedularMensual, setCreatedCedularMensual] = useState(false);

  const [visibleCedularRetenedor, setVisibleCedularRetenedor] = useState(false);
  const [createdCedularRetenedor, setCreatedCedularRetenedor] = useState(false);

  const [showRegistralesModal, setShowRegistralesModal] = useState(false);
  // const [tempCargos, setTempCargos] = useState([]);
  const [GenerandoRecibo, setGenerandoRecibo] = useState(false);
  const [acceptDeclaration, setAcceptDeclaration] = useState(false);

  const [visibleCRIE, setVisibleCRIE] = useState(false);
  const [createdCRIE, setCreatedCRIE] = useState(false);
  const [debtsAsked, setDebtsAsked] = useState(false);

  const reportCreated = createdCRIE || createdISAN || createdNotarios || createdVentaBebidas
    || createdHospedaje || createdNomina || createdJuegos || createdRetenedorNomina
    || createdCedularMensual || createdCedularArrendamiento || createdCedularRetenedor;

  const [cargosTemp, setCargosTemp] = useState([]);

  const classifierParam = extractSegment(_classifier, '-', -1);
  const subclassifier = extractSegment(_subclassifier, '-', -1);
  const classifier = `${classifierParam}.${subclassifier}`;

  const isDeclaration = classifierParam === '01' || classifierParam === '04';

  const cargosGenerados = debtsFormatter(_cargosGenerados);
  const cargosGeneradosDiff = debtsFormatter(_cargosGeneradosDiff);

  const [zeroDeclaration, setZeroDeclaration] = useState(false);
  const [pagosFilter, setPagosFilter] = useState('es_cargo_unico_true');
  const [pageCargos, setPageCargos] = useState(1);

  const lastClickedCharge = useRef(0);

  const [total, rounded] = round(cargosGenerados
    .reduce((acum, curr) => acum + curr.adeudo_total, 0));

  const calculateAmount = (record) => {
    const { importe, moneda } = record;
    const formatByMoneda = {
      3: () => +(importe * SM),
      4: () => +(importe * UMA),
    };
    return formatByMoneda[moneda] ? formatByMoneda[moneda]() : +(importe);
  };

  const rechazarDeclaraciones = async (ids) => {
    setLoading(true);
    const promises = ids.map((id) => postRechazarDeclaracion({
      declaracion: id,
      canal_de_pago: 5,
    }));
    try {
      await Promise.all(promises);
      message.success('Se han eliminado las declaraciones incompletas');
    } catch (error) {
      message.error('No se pudieron eliminar las declaraciones incompletas');
    } finally {
      const request = await getDeclaraciones({
        object_id_padron: padron.id,
        content_type_padron: contentTypesPadrones
          .find((e) => e.nivel === tipoDePadron)?.id,
        ejercicio_anual: periodoFiscal,
      });
      if (request) {
        setDeclaraciones(request);
      }
      setLoading(false);
    }
  };

  const buscarDeclaracionesIncompletas = async (_declaraciones, _getDeclaracionesGeneradas) => {
    const declaracionesIncompletas = _declaraciones
      .filter((item) => item?.estatus_de_declaracion_empresarial.clave === '1' && item?.cargo?.total_real === 0)
      .map((item) => item.id);

    if (declaracionesIncompletas.length) {
      await rechazarDeclaraciones(declaracionesIncompletas);

      const content_type_padron = contentTypesPadrones
        .find((e) => e.nivel === _getDeclaracionesGeneradas._tipoDepadron)?.id;
      const ejercicio_anual = periodosFiscales
        .find((e) => e.id === _getDeclaracionesGeneradas.periodo_fiscal)?.periodo;

      const _declaracionesGeneradas = await getDeclaraciones({
        object_id_padron: _getDeclaracionesGeneradas?.match?.id,
        content_type_padron,
        ejercicio_anual,
      });
      return setDeclaraciones(_declaracionesGeneradas);
    }
    return setDeclaraciones(_declaraciones);
  };

  const getDeclaracionesGeneradas = async ({
    _tipoDepadron,
    periodo_fiscal,
    match,
    searchIncomplete = false,
  // eslint-disable-next-line consistent-return
  }) => {
    setLoading(true);
    const content_type_padron = contentTypesPadrones.find((e) => e.nivel === _tipoDepadron)?.id;
    const ejercicio_anual = periodosFiscales.find((e) => e.id === periodo_fiscal)?.periodo;
    const _declaraciones = await getDeclaraciones({
      object_id_padron: match.id,
      content_type_padron,
      ejercicio_anual,
    });
    const _clasesDeDeclaraciones = await getClasesDeDeclaracion({ content_type_padron });
    if (_clasesDeDeclaraciones.length) {
      const clase_de_declaracion_empresarial = _clasesDeDeclaraciones[0].id;
      setClaseDeDeclaracion(clase_de_declaracion_empresarial);
    }
    if (searchIncomplete) {
      await buscarDeclaracionesIncompletas(_declaraciones, {
        _tipoDepadron,
        periodo_fiscal,
        match,
      });
    }
    if (!searchIncomplete) {
      setDeclaraciones(_declaraciones);
      setLoading(false);
      return _declaraciones;
    }
    setLoading(false);
  };

  const add = (_tipoDeCargo) => {
    const copy = [...selectedCargos];
    if (!selectedCargos.some((e) => e.id === _tipoDeCargo.id)) {
      copy.push({ ..._tipoDeCargo, cantidad: 1 });
    } else {
      const idx = copy.findIndex((e) => e.id === _tipoDeCargo.id);
      if (copy[idx].cantidad < 50) {
        copy[idx][copy[idx].tipo_de_aplicacion === aplicacionesCargosEnum.MULTIPLO ? 'importe' : 'cantidad'] += 1;
      }
    }
    if (isDeclaration || _tipoDeCargo.variables?.length) {
      setExpandedRowKeys([_tipoDeCargo.id]);
    }
    if (_tipoDeCargo.tipo_de_aplicacion === aplicacionesCargosEnum.CAPTURABLE) {
      // setTempCargos(copy);
    } else {
      setSelectedCargos(copy);
    }
  };

  const isRegistrales = !!allowedRoutesReports.SERVICIOS_REGISTRALES.includes(_subclassifier);
  const isNotario = !!allowedRoutesReports.NOTARIO.includes(_subclassifier);
  const isAlcoholes = padronesEnum.ALCOHOL === tipoDePadron;

  const fetchTiposDeCargos = async (page, descripcion, _params) => {
    const currentMonth = parseInt(Moment().format('MM'), 10);
    const currentYear = parseInt(Moment().format('YYYY'), 10);
    // const periodo_fiscal = form.getFieldValue('periodo_fiscal');
    const currentPeriodoFiscal = periodosFiscales
      .find((e) => e.periodo === currentYear && e.vigente);
    setLoading(true);
    const tiposDeAplicacionNoDeclaration = [
      aplicacionesCargosEnum.FIJO,
      aplicacionesCargosEnum.MULTIPLO,
      aplicacionesCargosEnum.PORCENTAJE,
      aplicacionesCargosEnum.FORMULA,
    ];
    if (classifierParam === '02') {
      // eslint-disable-next-line no-param-reassign
      delete _params.es_cargo_unico;
    }

    const params = {
      es_cargo_unico: (!(_params?.es_cargo_unico || isNotario)),
      ..._params,
      page: page || 1,
      descripcion,
      canales_de_pago: isDeclaration || ENV_NAME === 'nayarit' ? CATALOGOS_DE_CANAL_DE_PAGO.PORTAL_FINANCIERO
        : CATALOGOS_DE_CANAL_DE_PAGO.PAGO_EN_LINEA,
      padron: tipoDePadron,
      tipo_de_aplicacion: isDeclaration
        ? [aplicacionesCargosEnum.FORMULA, aplicacionesCargosEnum.SQL_FUNCTION]
        : tiposDeAplicacionNoDeclaration,
      es_accesorio: false,
      clasificador_de_tipo_de_cargo_en_portal: currentClassifier?.id,
    };
    if (isDeclaration || classifierParam === '02') {
      // Autodeterminables
      params.concepto_de_ingreso__naturaleza_de_ingresos = 2;
    }

    if (currentPeriodoFiscal?.id === (params?.periodo_fiscal || currentPeriodoFiscal?.id)) {
      params.periodo_fiscal = currentPeriodoFiscal?.id;
      params.identificadores = currentMonth;
    } else {
      params.periodo_fiscal = _params?.periodo_fiscal;
      delete params.identificadores;
    }
    if (tipoDeCargo) {
      params.id = tipoDeCargo;
    }
    const _tiposDeCargos = await getTiposDeCargos(params);
    setTiposDeCargos(_tiposDeCargos);
    _tiposDeCargos.results = _tiposDeCargos.results
      .map((_tipoDeCargo) => {
        const accesorios = debtsFormatter(
          _tipoDeCargo.accesorios[0]?.id ? _tipoDeCargo.accesorios : [],
          _tipoDeCargo,
        );
        const importe_total = calculateAmount(_tipoDeCargo);
        const importe_total_accesorios = accesorios
          .reduce((acum, curr) => acum + (curr?.importe || 0), 0);
        const importe_real = ([aplicacionesCargosEnum.FIJO, aplicacionesCargosEnum.MULTIPLO]
          .includes(_tipoDeCargo.tipo_de_aplicacion)
          && accesorios.every((e) => [
            aplicacionesCargosEnum.FIJO,
            aplicacionesCargosEnum.PORCENTAJE,
          ].includes(e?.tipo_de_aplicacion))
          ? importe_total + importe_total_accesorios : 'Por calcular');
        return {
          ..._tipoDeCargo,
          accesorios,
          importe_total_accesorios,
          importe_real,
          importe_total,
        };
      });
    _tiposDeCargos.results.forEach((_, idx) => {
      if (_tiposDeCargos.results[idx].tipo_de_aplicacion === aplicacionesCargosEnum.MULTIPLO) {
        _tiposDeCargos.results[idx].importe = 1;
      }
    });
    setTiposDeCargos(_tiposDeCargos);
    if (tipoDeCargo) {
      setTimeout(() => {
        add(_tiposDeCargos.results[0]);
        refTitleCargosTable.current
          ?.scrollIntoView({ block: 'center', behavior: 'smooth' });
      });
    }
    setLoading(false);
  };

  /**
   * @description Recibe los periodos fiscales y regresa los últimos
   * años definidos por el parametro quantityYears
   * @param {Array} periodosFiscal
   * @param {Number} quantityYears
   * @returns {Array} lastYears
   */
  const getLastYears = (periodosFiscal, quantityYears) => {
    const actualYear = new Date().getFullYear();
    const order = periodosFiscal.sort((a, b) => b.periodo - a.periodo);
    const lastYears = order.filter((e) => e?.periodo <= actualYear
    && e?.periodo >= actualYear - quantityYears);
    return lastYears;
  };

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      if (clasificadores && mounted) {
        const currentPath = window.location.pathname.split('/').at(-2);
        const _currentClassifier = clasificadores.subClasificadores
          .find((e) => toSlug(`${e.nombre} ${e.clasificador.split('.')[1]}`) === currentPath);
        setCurrentClassifier(_currentClassifier);
      }
      const [
        _periodosFiscales,
        _uma,
        _sm,
      ] = await Promise.all([
        getPeriodosFiscales(),
        getUMA(),
        getSM(),
      ]);
      const lastYears = getLastYears(_periodosFiscales, 5);
      const _padron = padrones.find((e) => e.id === parseInt(subclassifier, 10));
      if (mounted) {
        form.setFieldsValue({ padron: !classifier.startsWith('03') ? _padron?.id : 1 });
        setTipoDePadron(!classifier.startsWith('03') ? _padron?.id : 1);
        form.setFieldsValue({
          periodo_fiscal: lastYears
            .find((p) => p.periodo === new Date().getFullYear())?.id,
        });
        setPeriodosFiscales(lastYears);
        setUMA(_uma);
        setSM(_sm);
      }
      setLoading(false);
    };
    fetch();
    return () => { mounted = false; };
  }, []);

  const statusByContentTypes = async (_padron, padron_id) => {
    setLoading(true);
    const content_type_padron = getContenTypePorTipoDePadron(_padron);
    const params = {
      object_id_padron: padron_id,
      content_type_padron,
    };
    const [clase] = await getClasesDeDeclaracion(params);
    const claseId = clase?.id === 13 ? 15 : clase?.id;
    if (clase) {
      const status = await getEstatusDeDeclaracion({
        clase_de_declaracion_empresarial: claseId,
      });
      setStatusDeclaracion(status);
    }
    setLoading(true);
  };

  const onFinishSearch = async (values) => {
    setLoading(true);
    const {
      padron: _padron, q, periodo_fiscal, ...claveRfc
    } = values;
    const defaultParams = { q };
    const paramsObj = {
      3: {
        q: CLAVE_CATASTRAL_SEGMENTOS[ENV_NAME]
          ? [values?.localidad, values?.sector, values?.manzana, values?.lote, values?.condominio].join('-')
          : values.q,
      },
      4: {
        placa: values.placa,
        numero_de_identificacion_vehicular: values.serie,
      },
      16: {
        serie: values.serie,
        numero_de_placa_vigente: values.placa,
      },
      17: {
        serie: values.serie,
        placa: values.placa,
      },
      5: isNayarit ? claveRfc : defaultParams,
      6: isNayarit ? claveRfc : defaultParams,
      7: isNayarit ? claveRfc : defaultParams,
      8: isNayarit ? claveRfc : defaultParams,
      9: isNayarit ? claveRfc : defaultParams,
      10: isNayarit ? claveRfc : defaultParams,
      11: isNayarit ? claveRfc : defaultParams,
      12: isNayarit ? claveRfc : defaultParams,
      14: isNayarit ? claveRfc : defaultParams,
      15: isNayarit ? claveRfc : defaultParams,
      26: isNayarit ? claveRfc : defaultParams,
      27: isNayarit ? claveRfc : defaultParams,
      28: isNayarit ? claveRfc : defaultParams,
    };

    const allowValidObligacionFiscal = async (_values) => {
      const objValues = {
        [padronesEnum.AGENCIA]: () => getAgencia(true, _values),
        [padronesEnum.NOMINA]: () => getNomina(true, _values),
        [padronesEnum.ALCOHOL]: () => getAlcohol(true, _values),
        [padronesEnum.NOTARIO]: () => getNotario(true, _values),
        [padronesEnum.HOSPEDAJE]: () => getHospedaje(true, _values),
        [padronesEnum.VENTA_DE_ALCOHOL]: () => getVentaAlcoholById(_values.id),
        [padronesEnum.JUEGO_DE_AZAR]: () => getJuegoDeAzar(true, _values),
        [padronesEnum.CEDULAR]: () => getCedular(true, _values),
        [padronesEnum.ARRENDAMIENTO]: () => getArrendamiento(true, _values),
        [padronesEnum.RETENEDOR_DE_CEDULAR]: () => getCedularRetencion(true, _values),
        [padronesEnum.RETENEDOR_DE_NOMINA]: () => getNominaRetencion(true, _values),
      };

      const { detail } = await objValues[_padron]();

      if (detail) {
        message.warn(detail);
        setLoading(false);
        return true;
      }
      return false;
    };

    const params = paramsObj[_padron] || defaultParams;
    const match = await gettersMap[_padron]({ ...params }, true);
    if (match) {
      const request = await allowValidObligacionFiscal({ padron: _padron, id: match.id });
      if (request) return;
      await recievePadronFound({
        setLoading,
        padron: match,
        setVisibleModalUpdateInfo: setVisibleUpdate,
        inNeedsInfo: {
          setPadronId: setIdPadron,
          setTipoDePadron: setTipoDePadronId,
        },
        callback: async () => {
          if (tiposDeCargos.page !== 1) await fetchTiposDeCargos();
          setPadron(match);
          setTipoDePadron(_padron);
          setPeriodoFiscal(periodo_fiscal);
        },
      });
      await getDeclaracionesGeneradas({
        _tipoDepadron: _padron,
        periodo_fiscal,
        match,
        searchIncomplete: true,
      });
      await statusByContentTypes(tipoDePadron, padron);
    }
    setLoading(false);
  };

  const minus = (_tipoDeCargo) => {
    const tipo_aplicacion = _tipoDeCargo.tipo_de_aplicacion === aplicacionesCargosEnum.MULTIPLO ? 'importe' : 'cantidad';
    const copy = [...selectedCargos];
    const idx = copy.findIndex((e) => e.id === _tipoDeCargo.id);
    if (_tipoDeCargo[tipo_aplicacion] > 1) {
      copy[idx][tipo_aplicacion] -= 1;
    } else {
      copy[idx][tipo_aplicacion] = 0;
      if (expandedRowKeys[0] === _tipoDeCargo.id) {
        setTimeout(() => setExpandedRowKeys([]));
      }
    }
    setSelectedCargos(copy.filter((e) => e[tipo_aplicacion]));
  };

  const setVisiblePreviewWith = (base64) => {
    setPreviewBase64(base64);
    setVisiblePreview(true);
  };

  const generarReferencia = async (_cargos, clickNetPay, infoNotarios) => {
    setLoading(true);
    let _referencia = null;
    let cargosToReference = _cargos;
    if (debtsAsked) {
      const cargoPadre = _cargos.find((c) => c.id === lastClickedCharge.current);
      const cargosHijos = _cargos.filter((c) => c.cargo_padre === cargoPadre.id);
      cargosToReference = [cargoPadre, ...cargosHijos];
      setCargosTemp(cargosToReference);
    }
    // sino tiene referencia
    if (!referencia?.folio?.length) {
      _referencia = await postGenererReferenciasNetpay(
        tipoDePadron,
        padron,
        cargosToReference,
        padrones,
        undefined,
        infoNotarios || undefined,
      );
      setReferencia(_referencia);
    }

    // si es netpay
    if (clickNetPay) {
      setReferenciaNormal(true);
      setTimeout(() => getNetPayBtn()?.click(), 1500);
    } else if (_referencia?.base64 || referencia?.base64) {
      setVisiblePreviewWith(_referencia?.base64 ?? referencia.base64);
    }

    setLoading(false);
  };

  const generarReferenciaBanco = async (_cargos) => {
    setLoading(true);
    let cargosToReference = _cargos;
    if (debtsAsked) {
      const cargoPadre = _cargos.find((c) => c.id === lastClickedCharge.current);
      const cargosHijos = _cargos.filter((c) => c.cargo_padre === cargoPadre.id);
      cargosToReference = [cargoPadre, ...cargosHijos];
      setCargosTemp(cargosToReference);
    }
    const _referencia = await postGenererReferenciasBanco(
      tipoDePadron,
      padron,
      cargosToReference,
      padrones,
    );
    setReferenciaNormal(false);
    setReferencia(_referencia);
    setIsNewFolio(false);
    setLoading(false);
    return _referencia;
  };

  const isExpiryDate = (cargos, lastState, allowInpc = false) => {
    const entidadInfo = selectConfiguracionEntidad(store.getState());
    const INPC = entidadInfo?.dia_de_mes_para_actualizacion_inpc || 0; // es 10

    let expiryDate = null;
    const currentDate = lastState;
    const lastDayInstance = lastState.endOf('month');
    const vencimiento = toMoment(cargos.find((c) => c?.tipo_de_cargo?.fecha_de_vencimiento)
      ?.tipo_de_cargo?.fecha_de_vencimiento) || null;

    if (INPC !== 0 && allowInpc) {
      const INPC_DATE = toMoment(new Date()).set('date', INPC);

      if (INPC_DATE.isSame(currentDate, 'day')) {
        expiryDate = currentDate;
      } else if (currentDate.isSame(lastDayInstance, 'day')) {
        expiryDate = toMoment(new Date()).add(1, 'month').endOf('month');
      } else if (INPC_DATE.isAfter(currentDate)) {
        expiryDate = INPC_DATE;
      } else if (INPC_DATE.isBefore(currentDate)) {
        expiryDate = lastDayInstance;
      }
      return toMoment(expiryDate).format(DATE_NETPAY_FORMAT);
    }

    if ((vencimiento) && (vencimiento).isSame(currentDate, 'day')) {
      expiryDate = currentDate;
    } else if ((vencimiento) && (vencimiento).isAfter(currentDate)) {
      expiryDate = vencimiento;
    } else if (vencimiento && vencimiento.isBefore(currentDate)) {
      expiryDate = lastDayInstance;
    } else if (!vencimiento && currentDate.isBefore(lastDayInstance)) {
      expiryDate = lastDayInstance;
    } else {
      expiryDate = toMoment(new Date()).add(1, 'month').endOf('month');
    }
    return toMoment(expiryDate).format(DATE_NETPAY_FORMAT);
  };

  const consultarOrdenDePago = async (_cargos) => {
    setLoading(true);
    const _declaracionId = _cargos[0]?.id;
    const lastState = declaraciones
      .find((d) => d?.cargo?.id === _declaracionId)?.fecha_ultimo_estado;

    const fecha = new Date();
    const actualDate = `${fecha.getFullYear()}/${`${fecha.getMonth() + 1}`.padStart(2, '0')}/${`${fecha.getDate()}`.padStart(2, '0')}`;

    const expireDate = isExpiryDate(_cargos, lastState, isAlcoholes);
    if (expireDate < actualDate) {
      message.warn(`La fecha de vencimiento de la orden de pago vencio el ${expireDate}, favor de generar una nueva declaración.`, 4.4);
      setLoading(false);
      return;
    }
    const request = await postGenererReferenciasNetpay(
      tipoDePadron,
      padron,
      _cargos,
      padrones,
    );
    setVisiblePreviewWith(request?.base64);
    setLoading(false);
  };

  const consultarRecibo = async (values) => {
    setLoading(true);
    const { base64 } = await getReciboPorOrden(values);
    setVisiblePreviewWith(base64);
    setLoading(false);
  };

  const getPDF = async (id, { prod, dev }) => {
    setLoading(true);
    const [err, pdf] = await getPDFGeneral({ id: { prod, dev }, declaracionId: id });
    if (err) {
      message.error('Ocurrió un error al obtener el PDF', 3);
      return;
    }
    setPreviewBase64(pdf);
    setVisiblePreview(true);
    setLoading(false);
  };

  const declaracionesColumns = [
    {
      title: 'No. de Declaración',
      dataIndex: 'numero_de_la_declaracion',
      key: 'numero_de_la_declaracion',
      width: 150,
    },
    {
      title: 'Fecha de Presentación',
      dataIndex: 'fecha_de_creacion',
      key: 'fecha_de_creacion',
      width: 200,
      render: (val) => (val ? toMoment(val)?.format(DATETIME_FRONTEND_FORMAT) : 'N/A'),
    },
    {
      title: 'Concepto',
      dataIndex: 'cargo',
      key: 'cargo',
      width: 400,
      render: (val) => (typeof val === 'number' ? val : val?.descripcion),
    },
    {
      title: 'Estatus',
      dataIndex: 'estatus_de_declaracion_empresarial',
      key: 'estatus_de_declaracion_empresarial',
      width: 400,
      render: (val) => (typeof val === 'number' ? val : val?.descripcion),
    },
    {
      title: 'Documentos',
      key: 'id',
      width: 200,
      render: (values, record) => {
        const items = [
          values.estatus_de_declaracion_empresarial?.clave === '3'
          && !record?.cargo?.cargo_padre
          && !record?.cargo?.cancelado
          && {
            key: 7,
            label: 'Recibo de pago',
            onClick: () => consultarRecibo({ folio: record?.folio_referencia }),
          },
          values.estatus_de_declaracion_empresarial?.clave === '1'
          && !record?.cargo?.cargo_padre
          && !record?.cargo?.cancelado
          && {
            key: 2,
            label: 'Orden de Pago',
            onClick: () => {
              const _cargos = record?.cargos_hijos || [];
              consultarOrdenDePago(
                [record.cargo, ..._cargos].map((c) => ({ ...c, adeudo_total: c.total_real })),
              );
            },
          },
          allowedRoutesReports.ISAN.includes(_subclassifier) && {
            key: 3,
            label: 'Impuesto sobre Automóviles Nuevos (ISAN)',
            onClick: async () => {
              setLoading(true);
              const decISAN = await getDeclaracionesISAN(values.id);
              if (!decISAN.length) {
                message.warn('No se encontró algún ISAN relacionado');
                setLoading(false);
                return;
              }
              // const base64 = await getDeclaracionISAN(values.id);
              const [, base64] = await getPDFGeneral({
                id: { prod: 223, dev: 222 }, declaracionId: values.id,
              });
              setPreviewBase64(base64);
              setVisiblePreview(true);
              setLoading(false);
            },
          },
          allowedRoutesReports.CRIE.includes(_subclassifier) && {
            key: 4,
            label: 'Constancia de Retención de Impuestos Estatales (CRIE)',
            onClick: async () => {
              setLoading(true);
              const decCRIE = await getDeclaracionCRIE(values.id);
              if (!decCRIE.length) {
                message.warn('No se encontró alguna CRIE relacionada');
                setLoading(false);
                return;
              }
              const [, base64] = await getDeclaracionCRIEPDF(values.id);
              setPreviewBase64(base64);
              setVisiblePreview(true);
              setLoading(false);
            },
          },
          allowedRoutesReports.NOTARIO.includes(_subclassifier) && {
            key: 5,
            label: 'IMPUESTO I.S.R. ENAJENACIÓN DE BIENES (NOTARIOS)',
            onClick: async () => {
              await getPDF(values.id, { prod: 222, dev: 223 });
            },
          },
          allowedRoutesReports.VENTA_DE_BEBIDAS_ALCOHOLICAS.includes(_subclassifier) && {
            key: 6,
            label: 'Formato de declaración',
            onClick: async () => {
              setLoading(true);
              const alcoholPdf = await getDeclaracionAlcoholesPDF(values.id);
              if (!alcoholPdf.length) {
                message.warn('No se encontró alguna relacionada');
                setLoading(false);
                return;
              }
              const [, base64] = await getDeclaracionAlcoholesPDF(values.id);
              setPreviewBase64(base64);
              setVisiblePreview(true);
              setLoading(false);
            },
          },
          allowedRoutesReports.HOSPEDAJE.includes(_subclassifier) && {
            key: 8,
            label: 'Formato de declaración',
            onClick: async () => {
              setLoading(true);
              const hospedajePdf = await getDeclaracionHospedajePDF(values.id);
              if (!hospedajePdf.length) {
                message.warn('No se encontró alguna relacionada');
                setLoading(false);
                return;
              }
              const [, base64] = await getDeclaracionHospedajePDF(values.id);
              setPreviewBase64(base64);
              setVisiblePreview(true);
              setLoading(false);
            },
          },
          allowedRoutesReports.NOMINA.includes(_subclassifier) && {
            key: 9,
            label: 'Formato de declaración',
            onClick: async () => {
              setLoading(true);
              const hospedajePdf = await getDeclaracionNominasPDF(values.id);
              if (!hospedajePdf.length) {
                message.warn('No se encontró alguna relacionada');
                setLoading(false);
                return;
              }
              const [, base64] = await getDeclaracionNominasPDF(values.id);
              setPreviewBase64(base64);
              setVisiblePreview(true);
              setLoading(false);
            },
          },
          allowedRoutesReports.JUEGOS.includes(_subclassifier) && {
            key: 10,
            label: 'Formato de declaración',
            onClick: async () => {
              setLoading(true);
              const hospedajePdf = await getDeclaracionJuegosPDF(values.id);
              if (!hospedajePdf.length) {
                message.warn('No se encontró alguna relacionada');
                setLoading(false);
                return;
              }
              const [, base64] = await getDeclaracionJuegosPDF(values.id);
              setPreviewBase64(base64);
              setVisiblePreview(true);
              setLoading(false);
            },
          },
          allowedRoutesReports.CEDULAR_ARRENDAMIENTO.includes(_subclassifier) && {
            key: 11,
            label: 'Formato de declaración',
            onClick: async () => {
              setLoading(true);
              const arrendamiento = await getDeclaracionCedularArrendamientoPDF(values.id);
              if (!arrendamiento.length) {
                message.warn('No se encontró alguna relacionada');
                setLoading(false);
                return;
              }
              const [, base64] = await getDeclaracionCedularArrendamientoPDF(values.id);
              setPreviewBase64(base64);
              setVisiblePreview(true);
              setLoading(false);
            },
          },
          allowedRoutesReports.CEDULAR_PROFESIONAL_MENSUAL.includes(_subclassifier) && {
            key: 12,
            label: 'Formato de declaración',
            onClick: async () => {
              setLoading(true);
              const profesional = await getDeclaracionCedularProfesionalPDF(values.id);
              if (!profesional.length) {
                message.warn('No se encontró alguna relacionada');
                setLoading(false);
                return;
              }
              const [, base64] = await getDeclaracionCedularProfesionalPDF(values.id);
              setPreviewBase64(base64);
              setVisiblePreview(true);
              setLoading(false);
            },
          },
          allowedRoutesReports.CEDULAR_RETENEDOR.includes(_subclassifier) && {
            key: 13,
            label: 'Formato de declaración',
            onClick: async () => {
              setLoading(true);
              const arrendamiento = await getDeclaracionCedularRetenedorPDF(values.id);
              if (!arrendamiento.length) {
                message.warn('No se encontró alguna relacionada');
                setLoading(false);
                return;
              }
              const [, base64] = await getDeclaracionCedularRetenedorPDF(values.id);
              setPreviewBase64(base64);
              setVisiblePreview(true);
              setLoading(false);
            },
          },
          allowedRoutesReports.RETENEDOR_DE_NOMINA.includes(_subclassifier) && {
            key: 14,
            label: 'Formato de declaración',
            onClick: async () => {
              setLoading(true);
              const arrendamiento = await getDeclaracionRetencionNominasPDF(values.id);
              if (!arrendamiento.length) {
                message.warn('No se encontró alguna relacionada');
                setLoading(false);
                return;
              }
              const [, base64] = await getDeclaracionRetencionNominasPDF(values.id);
              setPreviewBase64(base64);
              setVisiblePreview(true);
              setLoading(false);
            },
          },
        ].filter((e) => e);

        return (
          <Dropdown overlay={<Menu items={items} />} placement="bottomLeft">
            <Button onClick={(e) => e.stopPropagation()} style={{ marginLeft: 'auto' }}>
              Seleccionar
              <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const columns = [
    {
      title: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 150,
      responsive: ['md'],
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 350,
    },
    {
      title: 'Importe',
      dataIndex: 'importe_real',
      key: 'importe_real',
      align: 'right',
      render: (importe_real, { cantidad = 1, importe = 1, tipo_de_aplicacion }) => (typeof importe_real === 'string' ? importe_real : toCurrency(importe_real * (
        tipo_de_aplicacion === aplicacionesCargosEnum.MULTIPLO ? importe : cantidad
      ))),
      width: 150,
      responsive: ['md'],
    },
  ];

  useEffect(() => {
    validateLastTransaction(isDeclaration, (_recibo) => {
      if (_recibo) {
        setTimeout(() => {
          setGenerandoRecibo(false);
          setReferencia(_recibo);
          setVisiblePreviewNetPay(true);
        }, 3000);
      }
    }, setLoading, paymentLocationsEnum.PAYMENTS, true, setGenerandoRecibo);
  }, []);

  const onCancelNetPay = () => {
    setVisiblePreviewNetPay(false);
    setReferencia();
    // setDeclaracionPDF();
  };

  const onCancel = () => {
    setShowRegistralesModal(false);
    setDeclaracionId(0);
    // setTempCargos([]);
    setShowRegistralesModal(false);
    setDebtsAsked(false);
    setVisibleISAN(false);
    setVisibleNotarios(false);
    setCreatedNotarios(false);
    setVisibleVentaBebidas(false);
    setCreatedVentaBebidas(false);
    setVisibleHospedaje(false);
    setVisibleNomina(false);
    setCreatedNomina(false);
    setVisibleRetenedorNomina(false);
    setCreatedRetenedorNomina(false);
    setVisibleJuegos(false);
    setCreatedJuegos(false);
    setVisibleCedularArrendamiento(false);
    setCreatedCedularArrendamiento(false);
    setVisibleCedularMensual(false);
    setCreatedCedularMensual(false);
    setVisibleCedularRetenedor(false);
    setCreatedCedularRetenedor(false);
    setCreatedHospedaje(false);
    setSelectedCargos([]);
    setExpandedRowKeys([]);
    setPadron();
    setReferenciaNormal(null);
    setIsNewFolio(true);
    setCargosGenerados([]);
    setCargosGeneradosDiff([]);
    setVisiblePreview(false);
    setVisiblePreviewNetPay(false);
    setReferencia();
    setCreatedISAN(false);
    setCreatedCRIE(false);
    // setDeclaracionPDF();
    setAcceptDeclaration(false);
    setZeroDeclaration(false);
    // setReferenciaTemp([]);
    // setReferenciaNormalTemp([]);
    setCargosTemp([]);
    lastClickedCharge.current = 0;
    form.resetFields(['q', 'serie', 'placa']);
    formFilter.resetFields();
    scrollToTop(screens);
    if (localStorage.getItem('nameTemp')) {
      localStorage.removeItem('nameTemp');
    }
    setPagosFilter('es_cargo_unico_true');
    setPageCargos(1);
  };

  const isNormal = (cargos) => {
    const es_notario = allowedRoutesReports.NOTARIO.includes(_subclassifier);
    const { es_cargo_unico } = cargos;
    const es_normal = cargos.es_declaracion_normal;

    if (es_notario) return !es_cargo_unico && !es_normal ? 2 : 1;
    return es_cargo_unico ? 1 : 2;
  };

  const handleShowPagoForm = async () => {
    if (allowedRoutesReports.ISAN.includes(_subclassifier) && !createdISAN) {
      setVisibleISAN(true);
    }
    if (allowedRoutesReports.NOTARIO.includes(_subclassifier) && !createdNotarios) {
      setVisibleNotarios(true);
    }
    if (
      allowedRoutesReports.VENTA_DE_BEBIDAS_ALCOHOLICAS.includes(_subclassifier)
      && !createdVentaBebidas) {
      setVisibleVentaBebidas(true);
    }
    if (allowedRoutesReports.HOSPEDAJE.includes(_subclassifier) && !createdHospedaje) {
      setVisibleHospedaje(true);
    }
    if (allowedRoutesReports.NOMINA.includes(_subclassifier) && !createdNomina) {
      setVisibleNomina(true);
    }
    if (allowedRoutesReports.RETENEDOR_DE_NOMINA.includes(_subclassifier)
        && !createdRetenedorNomina) {
      setVisibleRetenedorNomina(true);
    }
    if (allowedRoutesReports.JUEGOS.includes(_subclassifier) && !createdJuegos) {
      setVisibleJuegos(true);
    }
    if (allowedRoutesReports.CEDULAR_ARRENDAMIENTO.includes(_subclassifier)
        && !createdCedularArrendamiento) {
      setVisibleCedularArrendamiento(true);
    }
    if (allowedRoutesReports.CEDULAR_PROFESIONAL_MENSUAL.includes(_subclassifier)
        && !createdCedularMensual) {
      setVisibleCedularMensual(true);
    }
    if (allowedRoutesReports.CEDULAR_RETENEDOR.includes(_subclassifier)
        && !createdCedularRetenedor) {
      setVisibleCedularRetenedor(true);
    }
  };

  const generarCargos = async () => {
    try {
      setLoading(true);
      const invalid = selectedCargos.find((c) => (c.variables.length && !c.vars)
        || (c.tipo_de_aplicacion === aplicacionesCargosEnum.MULTIPLO && !c.cantidad));
      if (invalid) {
        message.info('Aún no ha ingresado la información necesaria', 3);
        if (!expandedRowKeys.length) {
          setExpandedRowKeys([invalid.id]);
        }
        setLoading(false);
        return;
      }
      const values = selectedCargos.map((t) => ({
        tipo_de_cargo: t.id,
        padron: tipoDePadron,
        padron_id: padron.id,
        empresa: padron.empresa?.id,
        canal_de_pago: isDeclaration || ENV_NAME === 'nayarit' ? CATALOGOS_DE_CANAL_DE_PAGO.PORTAL_FINANCIERO
          : CATALOGOS_DE_CANAL_DE_PAGO.PAGO_EN_LINEA,
        entidad: ID_ENTIDAD,
        // cargo_en_cero: isZeroDeclaration,
        cantidad: t.tipo_de_aplicacion === aplicacionesCargosEnum.MULTIPLO ? t.importe : t.cantidad,
        importe: (t.tipo_de_aplicacion === aplicacionesCargosEnum.MULTIPLO
          ? t.cantidad : t.importe) || undefined,

        variables: t.variables?.map((variable) => {
          let valor = t.vars[variable.nombre_de_variable];
          if (variable.tipo_de_variable.id === tiposDatosEnum.RANGO_DE_FECHAS) {
            const currentDate = Moment();
            const fechaInicial = currentDate;
            valor = 1; // SE ENVIA UNO PORQUE EL RANGO DE FECHAS ES DE UN DIA SOLAMENTE
            declaracionAnterior.current = Moment(fechaInicial).format('YYYY-MM-DD HH:mm:ss');
          } else if (variable.tipo_de_variable.id === tiposDatosEnum.TEXTO_LARGO) {
            valor = valor.toUpperCase();
          } else {
            valor = parseFloat(valor ?? 0);
          }
          return {
            variable_id: variable.id,
            nombre_de_variable: variable.nombre_de_variable,
            valor,
          };
        }),
      }));

      const inZero = (isDeclaration && values[0].variables.every((e) => !e.valor))
        && selectedCargos[0].tipo_de_aplicacion !== aplicacionesCargosEnum.SQL_FUNCTION;
      const decValues = {
        ejercicio_anual: selectedCargos[0]?.periodo_fiscal.periodo,
        periodo_mensual: selectedCargos[0].identificador_periodo || 1,
        tipo_de_cargo: selectedCargos[0].id,
        identificador_del_periodo: selectedCargos[0]?.identificador_periodo || 1,
        periodo: selectedCargos[0].periodo?.id || 1,
        empresa: padron.empresa?.id,
        entidad: ID_ENTIDAD,
        object_id_padron: values[0].padron_id,
        contribuyente: padron.empresa?.contribuyente?.id,
        content_type_padron: contentTypesPadrones.find((e) => e.nivel === values[0].padron)?.id,
        tipo_de_declaracion_empresarial: isNotario ? 1 : isNormal(selectedCargos[0]),
        estatus_de_declaracion_empresarial: inZero
          ? statusDeclaracion[1]?.id : statusDeclaracion[0]?.id,
        usuario_creo: USER_PORTAL_ID[ENV_NAME],
        clase_de_declaracion_empresarial: claseDeDeclaracion || null,
        fecha_ultimo_estado: Moment().format('YYYY-MM-DD HH:mm:ss'),
        numero_de_habitaciones: inZero
          ? 0 : (values[0].variables.find((e) => e.variable_id === 21)?.valor || 0),
      };
      const tipoDeclaracion = selectedCargos[0]?.es_cargo_unico ? 1 : 2;
      if (tipoDeclaracion === 2) {
        decValues.fecha_de_declaracion_anterior = declaracionAnterior.current || null;
      }
      const response = await postGenererCargos(values, isDeclaration, inZero, decValues);
      if (response.success.length && !response.failed.length) {
        const [_cargos] = await getAdeudoPadronAsync(tipoDePadron, padron.id, null, true) || [[]];
        const created = response.success.map((c) => c.id);
        const _cargosGeneradosFormDebts = _cargos.filter((d) => created.includes(d.id)
          || (d.es_accesorio && created.includes(d.cargo_padre)));
        setCargosGenerados(_cargosGeneradosFormDebts);
      } else if (!inZero && response.failed.length) {
        Modal.warn({
          width: '35vw',
          style: { minWidth: 300 },
          title: <Text strong $accent>Advertencia</Text>,
          content: (
            <>
              <Text strong>Los siguientes cargos no han podido ser generados</Text>
              <br />
              <br />
              {response.failed.map((e) => (
                <React.Fragment key={e.tipo_de_cargo}>
                  <b style={{ fontSize: '18px', fontWeight: 'bolder' }}>
                    {selectedCargos.find((s) => s.id === e.tipo_de_cargo)?.descripcion}
                  </b>
                  {': '}
                  <br />
                  <span style={{ fontSize: '18px', fontWeight: 'bolder' }}>
                    <span>
                      -
                      {' '}
                      { (response.failed[0]?.error || 'FAVOR DE REVISAR LA INFORMACIÓN INGRESADA.')}
                    </span>
                  </span>
                </React.Fragment>
              ))}
              <br />
              {!!response.successDiff.length && (
                <Text strong>
                  Los siguientes cargos fueron generado
                </Text>
              )}
              <br />
              {response.successDiff.filter((e) => !e.cargo_padre).map((e) => (
                <React.Fragment key={e.tipo_de_cargo}>
                  {selectedCargos.find((s) => s.id === e.tipo_de_cargo)?.descripcion}
                </React.Fragment>
              ))}
              <br />
              <br />
              {!!response.successDiff.length && (
                <Text>
                  Aún puede generar la orden de pago o continuar con el pago de los cargos generados
                </Text>
              )}
              <Row justify="end" style={{ marginBottom: 20 }}>
                <Button
                  style={{ marginLeft: screens.md ? 10 : 0 }}
                  onClick={() => Modal.destroyAll()}
                  $accent
                >
                  Entendido
                </Button>
              </Row>
            </>
          ),
          okButtonProps: { style: { display: 'none', heigth: 0 } },
          bodyStyle: { paddingBottom: 0 },
        });
      }
      if (response.declaracion) {
        if (inZero) {
          response.success = [{ ...selectedCargos[0], importe: 0 }];
        }
      }
      if (response.success.length) {
        const [_cargos] = await getAdeudoPadronAsync(tipoDePadron, padron.id, null, true);
        const created = response.success.map((c) => c.id);
        const _cargosGeneradosFormDebts = _cargos.filter((d) => created.includes(d.id)
          || (d.es_accesorio && created.includes(d.cargo_padre)));
        if (_cargosGeneradosFormDebts.length) {
          if (isDeclaration) {
            if (response.declaracion) {
              setDeclaracionId(response.declaracion?.id);
              // response.declaracion.base64 = await getDeclaracionPDF(response.declaracion.id);
              await getDeclaracionesGeneradas({
                _tipoDepadron: tipoDePadron,
                periodo_fiscal: periodoFiscal,
                match: padron,
              });

              handleShowPagoForm();
            }
            setCargosGeneradosDiff(_cargosGeneradosFormDebts);
          } else {
            setCargosGeneradosDiff(response.successDiff);
          }
          // setDeclaracionPDF(response.declaracion?.base64);
        }
      }
      setLoading(false);
    } catch (error) {
      _logger.error(error);
      setLoading(false);
      // eslint-disable-next-line consistent-return
      return message.error('Ocurrió un error al generar los cargos', 3);
    }
  };

  const selectedChargesColumns = [
    classifierParam !== '01' && {
      title: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
      width: 200,
      render: (val, record) => (
        <Row align="middle">
          {[aplicacionesCargosEnum.FIJO, aplicacionesCargosEnum.MULTIPLO]
            .includes(record.tipo_de_aplicacion) && (
              <Tooltip placement="top" title="Disminuir cantidad">
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    minus(record);
                  }}
                  type="link"
                >
                  <MinusOutlined />
                </Button>
              </Tooltip>
          )}
          <Text
            editable={aplicacionesCargosEnum.MULTIPLO === record.tipo_de_aplicacion && {
              tooltip: `Click para ingresar ${record.parametro_descripcion
                || 'cantidad'} manualmente`,
              enterIcon: <CheckOutlined />,
              maxLength: 2,
              autoSize: false,
              onChange: (_val) => {
                const parsedVal = parseInt(_val, 10);
                if (!parsedVal || parsedVal > 50 || parsedVal < 1) {
                  message.info('Ingrese un valor entero no mayor a 50', 3);
                } else {
                  const copy = [...selectedCargos];
                  const idx = copy.findIndex((e) => e.id === record.id);
                  copy[idx][record.tipo_de_aplicacion === aplicacionesCargosEnum.MULTIPLO
                    ? 'importe' : 'cantidad'] = parsedVal;
                  setSelectedCargos(copy);
                }
              },
            }}
            style={{ margin: 'auto', width: 'calc(100% - 100px)', textAlign: 'center' }}
          >
            {
              record[
                record.tipo_de_aplicacion === aplicacionesCargosEnum.MULTIPLO ? 'importe' : 'cantidad'
              ]
            }
          </Text>
          {[aplicacionesCargosEnum.FIJO, aplicacionesCargosEnum.MULTIPLO]
            .includes(record.tipo_de_aplicacion) && (
              <Tooltip placement="top" title="Aumentar cantidad">
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    add(record);
                  }}
                  type="link"
                  tooltip="Agregar"
                  disabled={![aplicacionesCargosEnum.FIJO, aplicacionesCargosEnum.MULTIPLO]
                    .includes(record.tipo_de_aplicacion)}
                >
                  <PlusOutlined />
                </Button>
              </Tooltip>
          )}
        </Row>
      ),
    },
    ...columns,
    !!cargosGenerados && !reportCreated && {
      title: 'Acciones',
      dataIndex: '_',
      key: '_',
      width: 150,
      render: (_, record) => (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            minus(record);
          }}
          type="link"
          icon={<DeleteOutlined />}
        >
          Eliminar
        </Button>
      ),
    },
  ].filter((c) => c);

  const getPDFCRIE = async (id) => {
    setLoading(true);
    const [err, pdf] = await getDeclaracionCRIEPDF(id);
    if (err) {
      message.error('Ocurrió un error al obtener el PDF', 3);
      return;
    }
    setPreviewBase64(pdf);
    setVisiblePreview(true);
    setLoading(false);
  };

  const generatedChargesColumns = [
    // debe ser el impuesto a cargo (cargos.importe)
    columns[1],
    {
      title: 'Importe a cargo',
      dataIndex: 'importe',
      key: 'importe',
      render: (val) => <b style={{ color: COLORS.accent }}>{toCurrency(val)}</b>,
      width: 150,
      align: 'right',
    },
    isDeclaration && debtsColumns[5],
    isDeclaration && debtsColumns[6],
    {
      title: 'Subtotal',
      dataIndex: 'adeudo_total',
      key: 'adeudo_total',
      render: (val) => <b style={{ color: COLORS.accent }}>{toCurrency(val)}</b>,
      width: 150,
      align: 'right',
    },
    debtsAsked && {
      title: 'Formas de pago',
      dataIndex: '_',
      key: '_',
      width: 150,
      render: (_, record) => {
        const onClickPay = async (fromNetpay = false) => {
          lastClickedCharge.current = record.id;
          setReferencia(null);
          generarReferencia(cargosGenerados, fromNetpay);
        };

        const items = [
          {
            key: 1,
            label: 'Orden de pago',
            icon: <BarcodeOutlined />,
            onClick: () => onClickPay(),
          },
          {
            key: 2,
            label: 'Pago en línea',
            icon: <CreditCardOutlined />,
            onClick: () => onClickPay(true),
          },
          {
            key: 3,
            label: 'Transferencia bancaria',
            icon: <CreditCardOutlined />,
            onClick: () => {
              lastClickedCharge.current = record.id;
              const cargoPadre = cargosGenerados.find((c) => c.id === record.id);
              const cargosHijos = cargosGenerados.filter((c) => c.cargo_padre === cargoPadre.id);
              setCargosTemp([cargoPadre, ...cargosHijos]);
              setVisibleBancos(true);
            },
          },
        ];

        return !record.es_accesorio && (
          <Dropdown
            overlay={<Menu items={items} />}
            placement="bottomLeft"
          >
            <Button
              onClick={(e) => e.stopPropagation()}
              style={{ marginLeft: 'auto' }}
              type="link"
            >
              Seleccionar
              <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ].filter((c) => c);

  const formatsMapByPeriod = {
    [periodosMap.DIARIO]: 'D',
    [periodosMap.SEMANAL]: 'w',
    [periodosMap.MENSUAL]: 'M',
    [periodosMap.CUATRIMESTRAL]: 'Q',
    /*  [periodosMap.ANUAL]: 1, Preguntar situación con este periodo */
  };

  const chargeTypesColumns = [
    ...columns,
    {
      title: 'Acciones',
      dataIndex: '_',
      key: '_',
      width: 160,
      render: (_, record) => {
        const added = selectedCargos.some((e) => e.id === record.id);
        const format = formatsMapByPeriod[record.periodo?.id];
        let currentMonth = isDeclaration && format && parseInt(Moment()
          .format(format), 10);
        const currentYear = parseInt(toMoment(new Date(), DATE_FRONTEND_FORMAT).format('YYYY'), 10);
        const currentPeriodoFiscal = periodosFiscales.find((p) => p.periodo === currentYear).id;
        if (!format && record.periodo) {
          if (record.periodo.id === periodosMap.QUINCENAL) {
            const day = parseInt(Moment().format('D'), 10);
            const month = parseInt(Moment().format('M'), 10) - 1;
            currentMonth = month * 2 + (day > 15 ? 2 : 1);
          }
          if (record.periodo.id === periodosMap.BIMESTRAL) {
            const month = parseInt(Moment().format('M'), 10);
            currentMonth = (month % 2 === 0 ? month : month + 1) / 2;
          }
          if (record.periodo.id === periodosMap.TRIMESTRAL) {
            const month = parseInt(Moment().format('M'), 10);
            const residual = month % 3;
            const number = !residual ? 0 : 3 - residual;
            currentMonth = ((month + number) / 3);
          }
          if (record.periodo.id === periodosMap.SEMESTRAL) {
            const month = parseInt(Moment().format('M'), 10);
            const residual = month % 6;
            const number = !residual ? 0 : 6 - residual;
            currentMonth = ((month + number) / 6);
          }
        }
        const disabledByLimit = isDeclaration
        && (record.periodo_fiscal.id === currentPeriodoFiscal
          && record.periodo.id !== periodosMap.EVENTUAL
          && record.identificador_periodo >= (!isNotario ? (currentMonth) : (currentMonth + 1)));

        return (
          <Button
            onClick={() => add(record)}
            disabled={added
              || (isDeclaration && (selectedCargos.length || disabledByLimit))
              || cargosGenerados.length}
            type="link"
          >
            {added ? <CheckOutlined /> : <PlusOutlined />}
            <Text>{added ? 'Agregado' : 'Agregar'}</Text>
          </Button>
        );
      },
    },
  ];

  if (!loading && !currentClassifier) {
    return <Navigate to="/not-found" />;
  }

  const conceptosDeIngresoByPadron = filtersExtemporaneos[ENV_NAME] || {};

  const onFinishForm = (values) => {
    const paramsMap = {
      es_cargo_unico_true: { es_cargo_unico: true },
      es_cargo_unico_false: { es_cargo_unico: false },
      extemporaneo: {
        concepto_de_ingreso__clave: conceptosDeIngresoByPadron[tipoDePadron],
        extemporaneo: true,
      },
    };
    setPagosFilter(values.filter);
    fetchTiposDeCargos(
      1,
      values.q,
      { ...paramsMap[values.filter], periodo_fiscal: periodoFiscal },
    );
    if (pageCargos !== 1) {
      setPageCargos(1);
    }
  };

  const changePeriodoFiscal = async (value) => {
    setLoading(true);
    setPeriodoFiscal(value);
    const paramsMap = {
      es_cargo_unico_true: { es_cargo_unico: !isNotario },
      es_cargo_unico_false: { es_cargo_unico: false },
      extemporaneo: {
        concepto_de_ingreso__clave: conceptosDeIngresoByPadron[tipoDePadron],
        extemporaneo: true,
      },
    };
    if (value) {
      fetchTiposDeCargos(1, null, {
        periodo_fiscal: value,
        ...paramsMap[pagosFilter],
        clasificador_de_tipo_de_cargo_en_portal: currentClassifier?.id,
      });
      await getDeclaracionesGeneradas({
        _tipoDepadron: tipoDePadron,
        periodo_fiscal: value,
        match: padron,
      });
      setPageCargos(1);
    }
    setLoading(false);
  };

  const cargosGeneradosExist = !!cargosGenerados.length && reportCreated;

  // VALIDACION DE RUTAS DE REPORTES
  const isSubclassifierAllowed = (value) => allowedRoutesReports[value]?.includes(_subclassifier);

  const visibleCRIEButton = cargosGeneradosExist && isSubclassifierAllowed('CRIE');
  const visibleISANButton = createdISAN && isSubclassifierAllowed('ISAN');
  const visibleNotariosButton = createdNotarios && isSubclassifierAllowed('NOTARIO');
  const visibleVentaBebidasButton = createdVentaBebidas && isSubclassifierAllowed('VENTA_DE_BEBIDAS_ALCOHOLICAS');
  const visibleHospedajeButton = createdHospedaje && isSubclassifierAllowed('HOSPEDAJE');
  const visibleNominaButton = createdNomina && isSubclassifierAllowed('NOMINA');
  const visibleRetenedorNominaButton = createdRetenedorNomina && isSubclassifierAllowed('RETENEDOR_DE_NOMINA');
  const visibleJuegosButton = createdJuegos && isSubclassifierAllowed('JUEGOS');

  // eslint-disable-next-line max-len
  const visibleCedularArrendamientoButton = createdCedularArrendamiento && isSubclassifierAllowed('CEDULAR_ARRENDAMIENTO');
  const visibleCedularMensualButton = createdCedularMensual && isSubclassifierAllowed('CEDULAR_PROFESIONAL_MENSUAL');
  const visibleCedularRetenedorButton = createdCedularRetenedor && isSubclassifierAllowed('CEDULAR_RETENEDOR');

  const visibleTransferenciaButton = !debtsAsked && (cargosGeneradosExist && !zeroDeclaration);
  const visibleNetPayButton = cargosGeneradosExist && !zeroDeclaration;

  const visibleGenerateCargosButton = !debtsAsked && (
    (!cargosGeneradosExist || reportCreated) && !zeroDeclaration
  );

  const handleGetAllDebts = async () => {
    if (selectedCargos.length) {
      message.warn('No se puede consultar adeudos anteriores con cargos seleccionados', 3);
      return;
    }
    setLoading(true);
    const [_cargos] = await getAdeudoPadronAsync(
      tipoDePadron,
      padron.id,
      null,
      true,
    );
    const cargosSinConvenios = _cargos.filter((c) => !c.convenios);
    if (cargosSinConvenios.length) {
      const formattedCargos = debtsFormatter(cargosSinConvenios);
      setCargosGenerados(cargosSinConvenios);
      setCargosGeneradosDiff(formattedCargos);
      setDebtsAsked(true);
      setTimeout(() => {
        getNetPayBtn().style.display = 'none';
      });
    } else {
      message.info('No se encontraron adeudos anteriores', 3);
    }
    setLoading(false);
  };

  const getPDFAlcoholes = async (id) => {
    setLoading(true);
    const [err, pdf] = await getDeclaracionAlcoholesPDF(id);
    if (err) {
      message.error('Ocurrió un error al obtener el PDF', 3);
      return;
    }
    setPreviewBase64(pdf);
    setVisiblePreview(true);
    setLoading(false);
  };

  const getPDFHospedaje = async (id) => {
    setLoading(true);
    const [err, pdf] = await getDeclaracionHospedajePDF(id);
    if (err) {
      message.error('Ocurrió un error al obtener el PDF', 3);
      return;
    }
    setPreviewBase64(pdf);
    setVisiblePreview(true);
    setLoading(false);
  };

  const getPDFNominas = async (id) => {
    setLoading(true);
    const [err, pdf] = await getDeclaracionNominasPDF(id);
    if (err) {
      message.error('Ocurrió un error al obtener el PDF', 3);
      return;
    }
    setPreviewBase64(pdf);
    setVisiblePreview(true);
    setLoading(false);
  };

  const getPDFRetenedorNominas = async (id) => {
    setLoading(true);
    const [err, pdf] = await getDeclaracionRetencionNominasPDF(id);
    if (err) {
      message.error('Ocurrió un error al obtener el PDF', 3);
      return;
    }
    setPreviewBase64(pdf);
    setVisiblePreview(true);
    setLoading(false);
  };

  const getPDFJuegos = async (id) => {
    setLoading(true);
    const [err, pdf] = await getDeclaracionJuegosPDF(id);
    if (err) {
      message.error('Ocurrió un error al obtener el PDF', 3);
      return;
    }
    setPreviewBase64(pdf);
    setVisiblePreview(true);
    setLoading(false);
  };

  const getPDFCedularProfesional = async (id) => {
    setLoading(true);
    const [err, pdf] = await getDeclaracionCedularProfesionalPDF(id);
    if (err) {
      message.error('Ocurrió un error al obtener el PDF', 3);
      return;
    }
    setPreviewBase64(pdf);
    setVisiblePreview(true);
    setLoading(false);
  };

  const getPDFCedularRetenedor = async (id) => {
    setLoading(true);
    const [err, pdf] = await getDeclaracionCedularRetenedorPDF(id);
    if (err) {
      message.error('Ocurrió un error al obtener el PDF', 3);
      return;
    }
    setPreviewBase64(pdf);
    setVisiblePreview(true);
    setLoading(false);
  };

  const getPDFCedularArredamiento = async (id) => {
    setLoading(true);
    const [err, pdf] = await getDeclaracionCedularArrendamientoPDF(id);
    if (err) {
      message.error('Ocurrió un error al obtener el PDF', 3);
      return;
    }
    setPreviewBase64(pdf);
    setVisiblePreview(true);
    setLoading(false);
  };

  const handleCallback = async (type, isZero) => {
    setLoading(true);

    const [_cargos] = (await getAdeudoPadronAsync(
      tipoDePadron,
      padron.id,
      null,
      true,
    )) || [[]];

    const created = cargosGenerados.map((c) => c.id);
    const _cargosGeneradosFormDebts = _cargos.filter((d) => created.includes(d.id)
      || (d.es_accesorio && created.includes(d.cargo_padre)));

    setCargosGenerados(_cargosGeneradosFormDebts);
    setCargosGeneradosDiff(debtsFormatter(_cargosGeneradosFormDebts));

    const declaracionesGeneradas = await getDeclaracionesGeneradas({
      _tipoDepadron: tipoDePadron,
      periodo_fiscal: periodoFiscal,
      match: padron,
    });

    // Esto se hizo porque a veces se mandab la declaracion anterior
    const lastDeclaracion = declaracionesGeneradas[declaracionesGeneradas.length - 1]?.id;
    const _declaracionId = lastDeclaracion !== declaracionId
      ? lastDeclaracion : declaracionId;

    const typesAction = {
      ISAN: async () => {
        setZeroDeclaration(isZero);
        setCreatedISAN(true);
        await getPDF(_declaracionId, { prod: 223, dev: 222 });
      },
      NOTARIO: async () => {
        setVisibleNotarios(false);
        setZeroDeclaration(isZero);
        setCreatedNotarios(true);
        await getPDF(_declaracionId, { prod: 222, dev: 223 });
      },
      BEBIDAS_ALCOHOLICAS: async () => {
        setZeroDeclaration(isZero);
        setCreatedVentaBebidas(true);
        await getPDFAlcoholes(_declaracionId);
      },
      HOSPEDAJE: async () => {
        setZeroDeclaration(isZero);
        setCreatedHospedaje(true);
        await getPDFHospedaje(_declaracionId);
      },
      NOMINA: async () => {
        setZeroDeclaration(isZero);
        setCreatedNomina(true);
        await getPDFNominas(_declaracionId);
      },
      RETENEDOR_DE_NOMINA: async () => {
        setZeroDeclaration(isZero);
        setCreatedRetenedorNomina(true);
        await getPDFRetenedorNominas(_declaracionId);
      },
      JUEGOS: async () => {
        setZeroDeclaration(isZero);
        setCreatedJuegos(true);
        await getPDFJuegos(_declaracionId);
      },
      CEDULAR_ARRENDAMIENTO: async () => {
        setZeroDeclaration(isZero);
        setCreatedCedularArrendamiento(true);
        await getPDFCedularArredamiento(_declaracionId);
      },
      CEDULAR_PROFESIONAL_MENSUAL: async () => {
        setZeroDeclaration(isZero);
        setCreatedCedularMensual(true);
        await getPDFCedularProfesional(_declaracionId);
      },
      CEDULAR_RETENEDOR: async () => {
        setZeroDeclaration(isZero);
        setCreatedCedularRetenedor(true);
        await getPDFCedularRetenedor(_declaracionId);
      },
    };

    if (typesAction[type]) {
      await typesAction[type]();
    }
    setLoading(false);
  };

  const handleAcceptDeclaration = async (isAccept = false) => {
    const canal_de_pago = isDeclaration || ENV_NAME === 'nayarit' ? CATALOGOS_DE_CANAL_DE_PAGO.PORTAL_FINANCIERO
      : CATALOGOS_DE_CANAL_DE_PAGO.PAGO_EN_LINEA;

    const values = selectedCargos.map(() => ({ canal_de_pago }));

    setLoading(true);
    if (isAccept) {
      setAcceptDeclaration(false);
    } else {
      const response = await postRechazarDeclaracion({
        declaracion: declaracionId,
        canal_de_pago: values[0].canal_de_pago,
      });
      if (response) {
        message.success('Cargos eliminados correctamente', 3);
        setAcceptDeclaration(false);
        setSelectedCargos([]);
        setCargosGenerados([]);
        setCargosGeneradosDiff([]);
        setDeclaracionId(0);
        setZeroDeclaration(false);
        setDeclaraciones(declaraciones.filter((d) => d.id !== declaracionId));
      }
    }
    setLoading(false);
  };

  // Construye el array de opciones
  const optionsArray = [
    {
      label: 'Normal',
      value: 'es_cargo_unico_true',
    },
    {
      label: 'Complementaria',
      value: 'es_cargo_unico_false',
    },
  ];

  return (
    <>
      <Col span={24}>
        <Skeleton
          title={{ width: '100%' }}
          paragraph={{ rows: 0 }}
          active
          loading={!currentClassifier && loading}
        >
          <Title style={{ textAlign: 'center', margin: 0 }}>
            {currentClassifier?.nombre || currentClassifier?.descripcion}
          </Title>
        </Skeleton>
      </Col>
      <SearchForm
        padron={padron}
        onCancel={onCancel}
        loading={loading}
        form={form}
        classifierParam={classifierParam}
        padrones={padrones}
        currentClassifier={currentClassifier}
        onFinishSearch={onFinishSearch}
        changePeriodoFiscal={changePeriodoFiscal}
        tipoDePadron={tipoDePadron}
        setTipoDePadron={setTipoDePadron}
        periodosFiscales={periodosFiscales}
        visibleAlert={visibleAlert}
        setLoading={setLoading}
      />
      {padron && (
        <>
          {!cargosGenerados.length && !tipoDeCargo && (
            <Card
              title={(
                <Row justify="space-between" gutter={[10, 10]}>
                  <Col xs={24} sm={24} md={12}>
                    <Title level={3} $accent>
                      Conceptos de pago
                    </Title>
                  </Col>
                  <Col xs={24} sm={24} md={12} xl={8}>
                    {(classifierParam === '04' && !debtsAsked && !createdCRIE) && (
                    <Button
                      $accent
                      block
                      loading={loading}
                      onClick={handleGetAllDebts}
                    >
                      Consultar adeudos anteriores
                      <HistoryOutlined />
                    </Button>
                    )}
                  </Col>
                </Row>
              )}
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%',
              }}
              bordered
            >
              <Form
                layout="vertical"
                form={formFilter}
                onFinish={onFinishForm}
                style={{ width: '100%' }}
                initialValues={{ filter: 'es_cargo_unico_true' }}
              >
                <Row gutter={10} justify="end">
                  {classifierParam === '01' && (
                    <Col span={24} style={{ marginBottom: 10 }}>
                      <Text italic>
                        En esta sección podrá realizar su declaración mensual normal y
                        complementaria, así como la retención del impuesto
                      </Text>
                    </Col>
                  )}
                  <Col span={24}>
                    <Form.Item
                      label="Búsqueda por descripción"
                      name="q"
                      hasFeedback
                    >
                      <Input disabled={cargosGenerados.length} />
                    </Form.Item>
                  </Col>
                  {['01', '04'].includes(classifierParam) && !isNotario && (
                    <Col span={24} style={{ height: screens.md ? 'auto' : 65 }}>
                      <Form.Item
                        name="filter"
                        hasFeedback
                      >
                        <Radio.Group
                          disabled={cargosGenerados.length}
                          onChange={({ target: { value } }) => onFinishForm({ filter: value })}
                          style={{ overflow: 'hidden' }}
                          options={optionsArray}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  <Col style={{
                    height: screens.md ? 'auto' : 100,
                    float: 'right',
                    width: screens.md ? 'auto' : '100%',
                  }}
                  >
                    <Form.Item>
                      <Button
                        block={!screens.md}
                        style={{ marginRight: screens.md ? 10 : 0 }}
                        loading={loading}
                        onClick={() => {
                          formFilter.resetFields();
                          fetchTiposDeCargos(1, null);
                        }}
                        disabled={cargosGenerados.length}
                      >
                        Borrar
                        <DeleteOutlined />
                      </Button>
                      <Button
                        block={!screens.md}
                        style={{ marginTop: screens.md ? 0 : 10 }}
                        htmlType="submit"
                        loading={loading}
                        $accent
                        disabled={cargosGenerados.length}
                      >
                        Buscar
                        <SearchOutlined />
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                <WaitingAlert visible={visibleAlert} />
              </Form>
              <Table
                columns={chargeTypesColumns}
                dataSource={tiposDeCargos.results
                  .sort((a, b) => a.identificador_periodo - b.identificador_periodo)}
                rowKey="id"
                pagination={{
                  defaultCurrent: 1,
                  total: tiposDeCargos.count,
                  onChange: (page) => {
                    setPageCargos(page);
                    const paramsMap = {
                      es_cargo_unico_true: { es_cargo_unico: !isNotario },
                      es_cargo_unico_false: { es_cargo_unico: false },
                      extemporaneo: {
                        concepto_de_ingreso__clave: conceptosDeIngresoByPadron[tipoDePadron],
                        extemporaneo: true,
                      },
                    };
                    fetchTiposDeCargos(
                      page,
                      null,
                      { ...paramsMap[pagosFilter], periodo_fiscal: periodoFiscal },
                    );
                  },
                  current: pageCargos,
                  showSizeChanger: false,
                  disabled: (isDeclaration && selectedCargos.length) || cargosGenerados.length,
                }}
                loading={loading}
                size="small"
                bordered
                scroll={{
                  x: chargeTypesColumns.reduce((acum, curr) => acum + curr.width, 0),
                }}
              />
            </Card>
          )}
          <Card
            title={(
              <>
                <Row justify="space-between">
                  <Title level={3} $accent ref={refTitleCargosTable}>
                    { isDeclaration && `Declaración a ${cargosGenerados[0]?.importe || (zeroDeclaration && reportCreated) ? 'pagar' : 'presentar'}` }
                  </Title>
                  {
                    reportCreated && (
                    <Title level={3} $accent style={{ margin: '0 0 0 auto !important' }}>
                      {cargosGenerados[0]?.importe || (zeroDeclaration) ? toCurrency(total) : '$0.00' }
                    </Title>
                    )
                  }
                </Row>
                {(!!cargosGenerados.length && SHOW_REDONDEO) && (
                  <Row justify="end">
                    <Title level={5} strong>
                      {`* Los centavos del monto total están
                      siendo redondeados (${toCurrency(rounded)}).`}
                    </Title>
                  </Row>
                )}
              </>
            )}
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '100%',
            }}
            bordered
          >
            <Table
              className="cargos-table"
              // eslint-disable-next-line max-len
              expandable={(!cargosGenerados.length && selectedCargos.some((c) => c.variables.length) && {
                rowExpandable: (record) => record.variables?.length,
                expandedRowKeys,
              })}
              columns={cargosGenerados.length ? generatedChargesColumns : selectedChargesColumns}
              scroll={{
                x: (cargosGenerados.length ? generatedChargesColumns : selectedChargesColumns)
                  .reduce((acum, curr) => acum + curr.width, 0),
              }}
              dataSource={cargosGenerados.length ? cargosGeneradosDiff : selectedCargos}
              rowKey="id"
              loading={loading}
              size="small"
              bordered
            />
            <br />
            <Row gutter={[10, 10]} justify="end">
              {visibleCRIEButton && (
                <Col xs={24} sm={24} md={8}>
                  <Tooltip title="Constancia de Retenciones de Impuestos Estatales">
                    <Button
                      onClick={() => {
                        if (!createdCRIE) {
                          setVisibleCRIE(true);
                        } else {
                          getPDFCRIE(declaracionId);
                        }
                      }}
                      loading={loading}
                      block
                      $accent
                    >
                      {createdCRIE ? 'Ver declaración CRIE' : 'Generar CRIE'}
                      {createdCRIE ? <EyeOutlined /> : <FilePdfOutlined />}
                    </Button>
                  </Tooltip>
                </Col>
              )}
              {visibleNetPayButton && (
                <NetPayButton
                  referencia={referencia?.folio}
                  loading={loading}
                  cargos={debtsAsked ? cargosTemp : cargosGenerados}
                  padron={padron}
                  createdBy="PAYMENTS"
                  totalProp="adeudo_total"
                  productCount={debtsAsked ? cargosTemp.length : cargosGeneradosDiff.length}
                  generarReferencia={() => generarReferencia(cargosGenerados, true)}
                  excludeValuesContactPublicoGeneral={false}
                />
              )}
              {visibleGenerateCargosButton && (
                <Col xs={24} sm={24} md={8}>
                  <Button
                    onClick={() => {
                      if ((cargosGenerados.length && padron.id !== 3) || reportCreated) {
                        if (isRegistrales && !referencia?.folio?.length) {
                          setShowRegistralesModal(true);
                        } else {
                          generarReferencia(cargosGenerados, false);
                        }
                      } else if (!cargosGenerados.length) {
                        // handleShowPagoForm();
                        generarCargos();
                      }
                    }}
                    loading={loading}
                    disabled={!debtsAsked
                      ? ((referencia && referenciaNormal) || !selectedCargos.length) : false}
                    block
                    $accent
                  >
                    {((cargosGenerados.length || reportCreated))
                      ? 'Generar orden de pago'
                      : `${isDeclaration ? 'Presentar declaración' : 'Generar cargos'}`}
                    {(cargosGenerados.length || reportCreated)
                      ? <BarcodeOutlined /> : <SyncOutlined />}
                  </Button>
                </Col>
              )}
              {visibleTransferenciaButton && (
                <Col xs={24} sm={24} md={8}>
                  <Button
                    onClick={() => {
                      setTimeout(() => { setVisibleBancos(true); }, 200);
                    }}
                    loading={loading}
                    block
                    $accent
                  >
                    Transferencia bancaria
                    <CreditCardOutlined />
                  </Button>
                </Col>
              )}
              {visibleVentaBebidasButton && createdVentaBebidas && (
                <Col xs={24} sm={24} md={8}>
                  <Tooltip title="Click para llenar el formato de venta de bebidas alcoholicas">
                    <Button
                      onClick={() => {
                        if (!createdVentaBebidas) {
                          setVisibleVentaBebidas(true);
                        } else {
                          getPDFAlcoholes(declaracionId);
                        }
                      }}
                      loading={loading}
                      block
                      $accent
                    >
                      Ver Formato
                      <EyeOutlined />
                    </Button>
                  </Tooltip>
                </Col>
              )}
              {visibleHospedajeButton && createdHospedaje && (
                <Col xs={24} sm={24} md={8}>
                  <Button
                    onClick={() => {
                      if (!createdHospedaje) {
                        setVisibleHospedaje(true);
                      } else {
                        getPDFHospedaje(declaracionId);
                      }
                    }}
                    loading={loading}
                    block
                    $accent
                  >
                    Ver Formato
                    <EyeOutlined />
                  </Button>
                </Col>
              )}
              {visibleNominaButton && createdNomina && (
                <Col xs={24} sm={24} md={8}>
                  <Button
                    onClick={() => {
                      if (!createdNomina) {
                        setVisibleNomina(true);
                      } else {
                        getPDFNominas(declaracionId);
                      }
                    }}
                    loading={loading}
                    block
                    $accent
                  >
                    Ver Formato
                    <EyeOutlined />
                  </Button>
                </Col>
              )}
              {visibleRetenedorNominaButton && createdRetenedorNomina && (
                <Col xs={24} sm={24} md={8}>
                  <Button
                    onClick={() => {
                      if (!createdRetenedorNomina) {
                        setVisibleRetenedorNomina(true);
                      } else {
                        getPDFRetenedorNominas(declaracionId);
                      }
                    }}
                    loading={loading}
                    block
                    $accent
                  >
                    Ver Formato
                    <EyeOutlined />
                  </Button>
                </Col>
              )}
              {visibleJuegosButton && createdJuegos && (
                <Col xs={24} sm={24} md={8}>
                  <Button
                    onClick={() => {
                      if (!createdJuegos) {
                        setVisibleJuegos(true);
                      } else {
                        getPDFJuegos(declaracionId);
                      }
                    }}
                    loading={loading}
                    block
                    $accent
                  >
                    Ver Formato
                    <EyeOutlined />
                  </Button>
                </Col>
              )}
              {visibleCedularArrendamientoButton && createdCedularArrendamiento && (
                <Col xs={24} sm={24} md={8}>
                  <Button
                    onClick={() => {
                      if (!createdCedularArrendamiento) {
                        setVisibleCedularArrendamiento(true);
                      } else {
                        getPDFCedularArredamiento(declaracionId);
                      }
                    }}
                    loading={loading}
                    block
                    $accent
                  >
                    Ver Formato
                    <EyeOutlined />
                  </Button>
                </Col>
              )}
              {visibleCedularRetenedorButton && createdCedularRetenedor && (
                <Col xs={24} sm={24} md={8}>
                  <Button
                    onClick={() => {
                      if (!createdCedularRetenedor) {
                        setVisibleCedularRetenedor(true);
                      } else {
                        getPDFCedularRetenedor(declaracionId);
                      }
                    }}
                    loading={loading}
                    block
                    $accent
                  >
                    Ver Formato
                    <EyeOutlined />
                  </Button>
                </Col>
              )}
              {visibleCedularMensualButton && createdCedularMensual && (
                <Col xs={24} sm={24} md={8}>
                  <Button
                    onClick={() => {
                      if (!createdCedularMensual) {
                        setVisibleCedularMensual(true);
                      } else {
                        getPDFCedularProfesional(declaracionId);
                      }
                    }}
                    loading={loading}
                    block
                    $accent
                  >
                    Ver Formato
                    <EyeOutlined />
                  </Button>
                </Col>
              )}
              {visibleISANButton && createdISAN && (
                <Col xs={24} sm={24} md={8}>
                  <Button
                    onClick={() => {
                      if (!createdISAN) {
                        setVisibleISAN(true);
                      } else {
                        getPDF(declaracionId, { prod: 223, dev: 222 });
                      }
                    }}
                    loading={loading}
                    block
                    $accent
                  >
                    Ver Formato ISAN
                    <EyeOutlined />
                  </Button>
                </Col>
              )}
              {visibleNotariosButton && createdNotarios && (
              <Col xs={24} sm={24} md={8}>
                <Tooltip>
                  <Button
                    onClick={() => {
                      if (!createdNotarios) {
                        setVisibleNotarios(true);
                      } else {
                        getPDF(declaracionId, { prod: 222, dev: 223 });
                      }
                    }}
                    loading={loading}
                    block
                    $accent
                  >
                    Ver formato notarios
                    <EyeOutlined />
                  </Button>
                </Tooltip>
              </Col>
              )}
            </Row>
            <br />
          </Card>
          {isDeclaration && (
            <Card
              title={(
                <Title level={3} $accent>
                  Declaraciones Presentadas
                </Title>
              )}
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%',
              }}
              bordered
            >
              <Col span={24}>
                <Table
                  size="small"
                  bordered
                  columns={declaracionesColumns}
                  dataSource={declaraciones}
                  rowKey="id"
                  loading={loading}
                />
              </Col>
            </Card>
          )}
        </>
      )}
      {ENV_NAME === ENV_NAMES_ENUM.NAYARIT && (
      <ModalBancos
        visible={visibleBancos}
        onCancel={() => { setVisibleBancos(false); }}
        cargosGenerados={debtsAsked ? cargosTemp : cargosGenerados}
        padron={padron}
        padrones={padrones}
        tipoDePadron={tipoDePadron}
        currentClassifier={currentClassifier}
        cbReferenciaBanco={() => generarReferenciaBanco(cargosGenerados)}
        isNewFolio={isNewFolio}
      />
      )}
      <Previewer
        visible={visiblePreview}
        base64={previewBase64}
        onCancel={() => {
          setVisiblePreview(false);
        }}
      />
      <Previewer
        visible={visiblePreviewNetPay}
        base64={referencia?.base64}
        onCancel={onCancelNetPay}
        allowSend={Boolean(referencia?.base64)}
      />
      <ModalUpdateInfo
        visible={visibleUpdate}
        onCancel={() => setVisibleUpdate(false)}
        setVisible={setVisibleUpdate}
        tipoDePadron={tipoDePadron}
        idPadron={idPadron}
        tipoDePadronId={tipoDePadronId}
        callback={() => {
          form.submit();
        }}
      />
      <FormISAN
        declaracionId={declaracionId}
        padron={padron}
        cargosGenerados={cargosGenerados}
        statusDeclaracion={statusDeclaracion}
        visible={visibleISAN}
        setVisible={setVisibleISAN}
        callback={(isZero) => handleCallback('ISAN', isZero)}
        callbackCancel={() => {
          handleAcceptDeclaration(false);
          setVisibleISAN(false);
        }}
      />
      <FormNotarios
        declaracionId={declaracionId}
        padron={padron}
        isComplementaria={selectedCargos[0]?.es_cargo_unico === false}
        visible={visibleNotarios}
        statusDeclaracion={statusDeclaracion}
        setVisible={setVisibleNotarios}
        callback={(isZero) => handleCallback('NOTARIO', isZero)}
        callbackCancel={() => {
          handleAcceptDeclaration(false);
          setVisibleNotarios(false);
        }}
      />
      <ModalCRIE
        visible={visibleCRIE}
        setVisble={setVisibleCRIE}
        declaracionId={declaracionId}
        retenedor={padron}
        callback={() => {
          setCreatedCRIE(true);
          getPDFCRIE(declaracionId);
        }}
        callbackCancel={() => {
          setVisibleCRIE(false);
        }}
      />
      <FormVentaBebidasAlcoholicas
        declaracionId={declaracionId}
        padron={padron}
        isComplementaria={selectedCargos[0]?.es_cargo_unico === false}
        selectedCargos={selectedCargos}
        visible={visibleVentaBebidas}
        statusDeclaracion={statusDeclaracion}
        setVisibleForm={setVisibleVentaBebidas}
        callback={(isZero) => handleCallback('BEBIDAS_ALCOHOLICAS', isZero)}
        callbackCancel={() => {
          handleAcceptDeclaration(false);
          setVisibleVentaBebidas(false);
        }}
      />
      <FormHospedaje
        declaracionId={declaracionId}
        visible={visibleHospedaje}
        setVisibleForm={setVisibleHospedaje}
        isComplementaria={selectedCargos[0]?.es_cargo_unico === false}
        cargosGenerados={selectedCargos}
        statusDeclaracion={statusDeclaracion}
        padron={padron}
        callback={(isZero) => handleCallback('HOSPEDAJE', isZero)}
        callbackCancel={() => {
          handleAcceptDeclaration(false);
          setVisibleHospedaje(false);
        }}
      />
      <FormNomina
        declaracionId={declaracionId}
        visible={visibleNomina}
        setVisibleForm={setVisibleNomina}
        isComplementaria={selectedCargos[0]?.es_cargo_unico === false}
        selectedCargos={selectedCargos}
        statusDeclaracion={statusDeclaracion}
        padron={padron}
        callback={(isZero) => handleCallback('NOMINA', isZero)}
        callbackCancel={() => {
          handleAcceptDeclaration(false);
          setVisibleNomina(false);
        }}
      />
      <FormRetenedorNomina
        declaracionId={declaracionId}
        visible={visibleRetenedorNomina}
        setVisibleForm={setVisibleRetenedorNomina}
        isComplementaria={selectedCargos[0]?.es_cargo_unico === false}
        selectedCargos={selectedCargos}
        statusDeclaracion={statusDeclaracion}
        padron={padron}
        callback={(isZero) => handleCallback('RETENEDOR_DE_NOMINA', isZero)}
        callbackCancel={() => {
          handleAcceptDeclaration(false);
          setVisibleRetenedorNomina(false);
        }}
      />
      <FormJuegos
        declaracionId={declaracionId}
        visible={visibleJuegos}
        setVisibleForm={setVisibleJuegos}
        isComplementaria={selectedCargos[0]?.es_cargo_unico === false}
        statusDeclaracion={statusDeclaracion}
        padron={padron}
        callback={(isZero) => handleCallback('JUEGOS', isZero)}
        callbackCancel={() => {
          handleAcceptDeclaration(false);
          setVisibleJuegos(false);
        }}
      />
      <FormCedularArrendamiento
        visible={visibleCedularArrendamiento}
        setVisibleForm={setVisibleCedularArrendamiento}
        isComplementaria={selectedCargos[0]?.es_cargo_unico === false}
        statusDeclaracion={statusDeclaracion}
        selectedCargos={selectedCargos}
        padron={padron}
        callback={(isZero) => handleCallback('CEDULAR_ARRENDAMIENTO', isZero)}
        callbackCancel={() => {
          handleAcceptDeclaration(false);
          setVisibleCedularArrendamiento(false);
        }}
        declaracionId={declaracionId}
      />
      <FormCedularMensual
        visible={visibleCedularMensual}
        setVisibleForm={setVisibleCedularMensual}
        isComplementaria={selectedCargos[0]?.es_cargo_unico === false}
        statusDeclaracion={statusDeclaracion}
        padron={padron}
        callback={(isZero) => handleCallback('CEDULAR_PROFESIONAL_MENSUAL', isZero)}
        callbackCancel={() => {
          handleAcceptDeclaration(false);
          setVisibleCedularMensual(false);
        }}
        declaracionId={declaracionId}
      />
      <FormCedularRetenedor
        visible={visibleCedularRetenedor}
        setVisibleForm={setVisibleCedularRetenedor}
        isComplementaria={selectedCargos[0]?.es_cargo_unico === false}
        statusDeclaracion={statusDeclaracion}
        selectedCargos={selectedCargos}
        padron={padron}
        callback={(isZero) => handleCallback('CEDULAR_RETENEDOR', isZero)}
        callbackCancel={() => {
          handleAcceptDeclaration(false);
          setVisibleCedularRetenedor(false);
        }}
        declaracionId={declaracionId}
      />
      <ModalRegistralesService
        visible={showRegistralesModal}
        setVisible={setShowRegistralesModal}
        callback={async (val) => {
          const infoNotarios = {
            tipo_de_complemento_referencial_id: 1,
            numero_de_instrumentro_escritura: val.numero_de_instrumentro_escritura,
            nombre_completo_del_notario: val.nombre_completo_del_notario,
            nombre_completo_razon_social: val.nombre_completo_razon_social,
            rfc: val.rfc,
            curp: val.curp,
            entidad_federativa: val.entidad_federativa,
            municipio: val.municipio,
            localidad: val.localidad,
            colonia: val.colonia,
            calle: val.calle,
            numero_y_letra_de_direccion: val.numero_y_letra_de_direccion,
            telefono: val.telefono,
            observaciones_complemento: val.observaciones_complemento,
          };
          setShowRegistralesModal(false);
          await generarReferencia(cargosGenerados, false, infoNotarios);
        }}
      />
      <Spinner visible={GenerandoRecibo} />
      <ModalConfirm
        visible={acceptDeclaration}
        setVisible={setAcceptDeclaration}
        body="Una vez que se genere la declaración no podrá ser modificada. ¿Desea generar la declaración?"
        btnText="Si, generar declaración"
        callback={(isAccept) => handleAcceptDeclaration(isAccept)}
      />
    </>
  );
};

export default PaymentFormCompose;
