import API from 'api';
import _logger from 'utils/logger';
import { postComentarioDeTramite } from 'api/tramites/comentariosDeTramites';
import { message } from 'antd';

export const baseURI = 'tramites/historiales-de-tramites/';

export const generateLogValues = (pasoActual, pasos = [], puede_continuar = false) => {
  if (!pasoActual) {
    return [null, null];
  }
  const pasoSiguiente = pasos.find((p) => p.orden > pasoActual.orden);
  const formValues = {
    paso: pasoActual.id,
    paso_a_mandar: puede_continuar ? pasoSiguiente?.id || pasoActual?.id : pasoActual?.id,
  };
  return [formValues, pasoSiguiente];
};

/**
 * GET Historial de Tramite
 * param {object} params
 * @returns {Promise<object>}
 */
export const getHistorialDeTramite = async (params) => {
  try {
    const response = await API.get(baseURI, { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * POST Historial de Tramite
  * @param {object} values
  * @param {string?} values.comentario
  * @param {boolean} values.es_comentario_interno
  * @param {number} values.tramite
  * @param {number} values.paso
  * @param {number} values.paso_a_mandar
  * @param {File[]} values.archivos
 * @returns {Promise<object|boolean>}
 */
export const postHistorialDeTramite = async ({
  comentario,
  tramite,
  ...values
}) => {
  try {
    const valuesComentario = {
      comentario,
      es_comentario_interno: false,
      tramite,
    };
    const _comentario = comentario ? await postComentarioDeTramite(valuesComentario) : null;
    if (comentario && !_comentario) {
      message.info('Ha ocurrido un error intentelo de nuevo');
      return false;
    }
    const response = await API.post(baseURI, { ...values, comentario: _comentario?.id, tramite });
    return response.data;
  } catch (err) {
    _logger(err);
    return false;
  }
};

/**
 * GET Archivos De Tramites
 * @param {object} params
 * @returns {Promise<object[]>}
 */
export const getArchivosDeComentariosDeTramites = async (params) => {
  try {
    const response = await API.get('tramites/archivos-de-comentarios-de-tramites/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Archivos De Tramites
 * @param {object} params
 * @returns {Promise<object[]>}
 */
export const getArchivosDeComentariosDeTramitesPorIdsComentario = async (idsComentarios) => {
  try {
    const response = await Promise.all(idsComentarios
      .map((comentario) => getArchivosDeComentariosDeTramites({ comentario })));
    return response.flat();
  } catch (err) {
    _logger(err);
    return [];
  }
};
