import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Result,
} from 'antd';
import { Text } from 'components';
import { DEV, PROD } from 'utils/env';

const tryParse = (str) => {
  try {
    return JSON.parse(str);
  } catch {
    return {};
  }
};

const SomethingWentWrong = ({ error }) => {
  const errorAsObject = tryParse(error.message);

  return (
    <div style={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      <Result
        status={errorAsObject.status || '500'}
        title={errorAsObject.title || 'Ooops'}
        subTitle={errorAsObject.subTitle || 'Algo salió mal'}
      />
      <Text>Intenta</Text>
      <Button
        type="link"
        onClick={() => window?.location?.reload()}
      >
        Recargar la página
      </Button>
      {/* <Text>o</Text>
      <Button type="link">
        Contacta soporte
      </Button> */}
      <br />
      {(!PROD || DEV) && <center><span>{error.toString()}</span></center>}
    </div>
  );
};

SomethingWentWrong.propTypes = {
  error: PropTypes.string,
};

SomethingWentWrong.defaultProps = {
  error: null,
};

export default SomethingWentWrong;
