import React from 'react';

// Icons
import {
  HomeOutlined,
  EnvironmentOutlined,
  InboxOutlined,
  ReadOutlined,
  DollarCircleOutlined,
  FileTextOutlined,
  UserOutlined,
  ContactsOutlined,
  SoundOutlined,
  CalendarOutlined,
  TeamOutlined,
  // SmileOutlined,
  // ReconciliationOutlined,
} from '@ant-design/icons';

import {
  ACCOUNT_AVAILABLE,
  APPOINTMENTS_AVAILABLE,
  ENV_NAME,
  ENV_NAMES_ENUM,
  REPUVE,
} from 'utils/env';

// Views
import Home from 'views/Home';
import MiCuentaHome from 'views/MiCuenta/Home';
import Payments from 'views/Payments/List';
import Debts from 'views/Payments/Debts';
import PaymentOrder from 'views/Payments/PaymentOrder';
import PaymentsLayout from 'views/Payments/Layout';
import SubClassifiers from 'views/Payments/SubClassifiers';
import Form from 'views/Payments/Form';
import FormPagoCompuesto from 'views/Payments/FormPagoCompuesto';
import Oficinas from 'views/Oficinas';
import Facturacion from 'views/Facturacion';
import PrivacyNotice from 'views/PrivacyNotice';
import PortalGuide from 'views/PortalGuide';
import Normateca from 'views/Normateca';
import Tramites from 'views/Tramites/index';
import Tramite from 'views/Tramites/Tramite';
import { Outlet } from 'react-router';
import PadronesList from 'views/Payments/PadronesList';
import GPM from 'views/Tramites/GPM';
import Login from 'views/Auth/Login';
import SignIn from 'views/Auth/SignIn';
import MiCuentaLayout from 'views/MiCuenta';
import MisDatos from 'views/MiCuenta/MisDatos';
import MisTramites from 'views/MiCuenta/MisTramites';
import SolicitarCodigo from 'views/Auth/Login/SolicitarCodigo';
import PaymentWebView from 'views/PaymentWebView';
import Appointments from 'views/Appointments';
import ComingSoon from 'components/ComingSoon';
import NewAppointment from 'views/Appointments/NewAppointment';
import ConsultaCita from 'views/Appointments/components/ConsultaCita';
// import TestWithoutAuth from 'components/TestWithoutAuth';
// import MisDeclaraciones from 'views/MiCuenta/MisDeclaraciones';
import Recibos from 'views/Recibos';
import Complains from 'views/Complains';
import NewComplain from 'views/Complains/NewComplain';
import ConsultaQueja from 'views/Complains/components/ConsultaQueja';
import MisEstablecimientos from 'views/MiCuenta/MisEstablecimientos';
import MisObligaciones from 'views/MiCuenta/MisObligaciones';
import MisPagos from 'views/MiCuenta/MisPagos';
import MisConstancias from 'views/MiCuenta/MisConstancias';
import MisDeclaraciones from 'views/MiCuenta/MisDeclaraciones';
import MiExpediente from 'views/MiCuenta/MiExpediente';
import MisVehiculos from 'views/MiCuenta/MisVehiculos';

const Layout = () => <Outlet />;

const getRoutes = (isAuthenticated) => {
  const rutasMiCuenta = [
    {
      index: true,
      element: <MiCuentaHome />,
    },
    {
      path: 'mis-datos',
      element: <MisDatos />,
    },
    {
      path: 'mis-tramites',
      element: <MisTramites />,
    },
    {
      path: 'mis-establecimientos',
      element: <MisEstablecimientos />,
    },
    {
      path: 'mis-obligaciones',
      element: <MisObligaciones />,
    },
    {
      path: 'mis-pagos-de-contribuciones',
      element: <MisPagos />,
    },
    {
      path: 'mis-constancias',
      element: <MisConstancias />,
    },
    {
      path: 'mis-declaraciones',
      element: <MisDeclaraciones />,
    },
    {
      path: 'mi-buzon',
      element: <ComingSoon />,
    },
    {
      path: 'mi-expediente-digital',
      element: <MiExpediente />,
    },
    {
      path: 'mis-vehiculos',
      element: <MisVehiculos />,
    },
  ];

  const rutasAuth = [
    {
      index: true,
      element: <SolicitarCodigo />,
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'sign-in',
      element: <SignIn />,
    },
  ];

  const routes = [
    {
      path: '/',
      label: 'Inicio',
      element: <Home />,
      icon: <HomeOutlined />,
    },
    {
      path: '/tramites',
      label: 'Trámites',
      element: <Layout />,
      icon: <InboxOutlined />,
      children: [
        {
          index: true,
          element: <Tramites />,
        },
        {
          path: 'tramite/:idPlantilla',
          children: [
            {
              index: true,
              element: <Tramite />,
            },
            {
              path: 'nueva',
              element: <GPM />,
            },
          ],
        },
        {
          path: 'seguimiento/:idTramite',
          element: <GPM />,
        },
      ],
    },
    {
      path: REPUVE ? '/modulos' : '/oficinas',
      label: REPUVE ? 'Módulos' : 'Oficinas',
      element: <Oficinas />,
      icon: <EnvironmentOutlined />,
    },
    {
      path: ENV_NAME === ENV_NAMES_ENUM.NAYARIT ? '/regulaciones' : '/normateca',
      label: ENV_NAME === ENV_NAMES_ENUM.NAYARIT ? 'Regulaciones' : 'Normateca',
      element: <Normateca />,
      icon: <ReadOutlined />,
    },
    !REPUVE && {
      path: '/pagos',
      label: 'Pagos',
      icon: <DollarCircleOutlined />,
      element: <PaymentsLayout />,
      children: [
        {
          index: true,
          element: <Payments />,
        },
        {
          path: 'orden-de-pago',
          element: <PaymentOrder />,
        },
        // Aun no se define que se hará aquí
        {
          path: 'consulta-de-recibo',
          element: <Recibos />,
        },
        {
          path: 'consulta-de-adeudos',
          children: [
            {
              index: true,
              element: <PadronesList />,
            },
            {
              path: ':padron',
              element: <Debts />,
            },
          ],
        },
        {
          path: ':classifier',
          children: [
            {
              index: true,
              element: <SubClassifiers />,
            },
            {
              path: ':subclassifier',
              children: [
                {
                  index: true,
                  element: <Form />,
                },
                {
                  element: <FormPagoCompuesto />,
                  path: 'pago-compuesto',
                },
              ],
            },
          ],
        },
      ],
    },
    !REPUVE && ENV_NAME !== ENV_NAMES_ENUM.JUAREZ && {
      path: '/facturacion',
      label: 'Facturación',
      element: <Facturacion />,
      icon: <FileTextOutlined />,
    },
    // !REPUVE && ENV_NAME !== ENV_NAMES_ENUM.JUAREZ && {
    //   path: '/declaraciones',
    //   label: 'Consulta Declaraciones',
    //   element: <MisDeclaraciones />,
    //   icon: <ReconciliationOutlined />,
    //   breakLabel: true,
    // },
    REPUVE && {
      path: '/noticias',
      label: 'Noticias',
      element: <ComingSoon />,
      icon: <SoundOutlined />,
    },
    {
      path: '/asistencia-al-contribuyente',
      label: 'Contacto',
      element: <PortalGuide />,
      icon: <ContactsOutlined />,
      hide: !REPUVE,
    },
    !REPUVE && {
      path: '/pago-webview',
      element: <PaymentWebView />,
      hide: true,
    },
    {
      path: '/aviso-de-privacidad',
      element: <PrivacyNotice />,
      hide: true,
    },
    APPOINTMENTS_AVAILABLE && {
      path: '/citas',
      label: 'Citas',
      icon: <CalendarOutlined />,
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Appointments />,
        },
        {
          path: 'nueva',
          element: <NewAppointment />,
        },
        {
          path: 'consulta',
          element: <ConsultaCita />,
        },
      ],
    },
    {
      path: '/protesta',
      label: 'Quejas y Denuncias',
      icon: <TeamOutlined />,
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Complains />,
        },
        {
          path: 'nueva',
          element: <NewComplain />,
        },
        {
          path: 'consulta',
          element: <ConsultaQueja />,
        },
      ],
    },
    ACCOUNT_AVAILABLE && {
      path: '/mi-cuenta',
      label: 'Mi Cuenta',
      element: isAuthenticated ? <MiCuentaLayout /> : <Layout />,
      icon: <UserOutlined />,
      children: isAuthenticated ? rutasMiCuenta : rutasAuth,
      breakLabel: false,
    },
  ].filter((e) => e);

  if (!ACCOUNT_AVAILABLE) {
    // tramites/tramite/:idPlantilla/nueva
    delete routes[1].children[1].children[1];

    // tramites/seguimiento/:idTramite
    delete routes[1].children[2];
  }

  return routes.filter((e) => e);
};

export const getAuthorizedRoutes = (isAuthenticated) => getRoutes(isAuthenticated)
  .map(({ icon, label, ...route }) => route);

export const menuRoutes = getRoutes().filter((e) => !e.hide);
