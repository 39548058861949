import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CalendarOutlined, DownloadOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
  Tag,
  Grid,
  Card as AntCard,
} from 'antd';
import {
  Button,
  COLORS,
  Text,
  Title,
} from 'components';
import { Link } from 'react-router-dom';
import isWebView from 'utils/isWebView';
import Previewer from 'components/Previewer';
import { listToString } from 'utils/formatters';
import { getPlantillaPDF } from 'api/tramites';
import Skeletons from './SkeletosWrappers';
import { ButtonIniciarTramite } from '..';

const {
  SkeletonWrapperText,
  SkeletosWrapperTitle,
} = Skeletons;
const { useBreakpoint } = Grid;

const ButtonAgendarCita = ({
  plantilla,
  block,
  style,
  shape,
}) => {
  const requiere_cita = typeof plantilla?.requiere_cita === 'string'
    ? /si/i.test(plantilla?.requiere_cita) : plantilla?.requiere_cita;
  if (!requiere_cita || isWebView()) return null;

  return (
    <Button
      style={style}
      bg={COLORS.gray}
      color={COLORS.white}
      block={block}
      shape={shape}
    >
      <Link to={`/citas/nueva/?plantilla=${plantilla.id}`} style={{ fontSize: 'inherit', width: 'auto' }}>
        <Text>
          Agendar Cita
          <CalendarOutlined style={{ fontSize: 'inherit', color: 'inherit', marginLeft: 5 }} />
        </Text>
      </Link>
    </Button>
  );
};

ButtonAgendarCita.propTypes = {
  block: PropTypes.bool,
  plantilla: PropTypes.shape({
    id: PropTypes.number,
    requiere_cita: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  style: PropTypes.shape({}),
  shape: PropTypes.string,
};

ButtonAgendarCita.defaultProps = {
  block: false,
  style: {},
  shape: 'round',
};

const BannerTramiteInfo = ({
  plantilla,
  loading,
}) => {
  const screens = useBreakpoint();
  const isFree = plantilla?.es_tramite_gratuito === 'Si';

  const [visiblePreviewer, setVisiblePreviewer] = useState(false);
  const [base64, setBase64] = useState('');
  const [error, setError] = useState('');
  const buttonAgendarCita = (
    <ButtonAgendarCita
      block={screens.xs || screens.sm}
      plantilla={plantilla}
      style={{ float: 'right', marginTop: screens.xs || screens.sm ? 20 : 0 }}
      shape="round"
    />
  );

  const buttonIniciarTramite = (
    <ButtonIniciarTramite
      block={screens.xs || screens.sm}
      plantilla={plantilla}
      style={{ float: 'right', marginTop: screens.xs || screens.sm ? 20 : 0 }}
      shape="round"
    />
  );

  return (
    <TramiteCard bordered={false}>
      <div
        style={{
          borderLeft: `4px solid ${COLORS.accent}`,
          paddingLeft: 20,
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
        }}
      >
        <Row gutter={[10, 10]} style={{ fontSize: 14 }}>
          <Col span={24}>
            <SkeletosWrapperTitle loading={loading}>
              <Title level={2} $accent>
                {plantilla?.nombre?.toUpperCase()}
              </Title>
            </SkeletosWrapperTitle>
          </Col>
          <Col span={24} style={{ textDecoration: 'underline' }}>
            <SkeletonWrapperText loading={loading}>
              {plantilla?.descripcion}
            </SkeletonWrapperText>
          </Col>
        </Row>
        <Row className="tramite-info" justify="space-between">
          <Col xs={24} sm={24} xxl={16} style={{ whiteSpace: 'break-spaces' }}>
            <Row>
              <Col span={24}>
                <SkeletonWrapperText dataLabel="Categoría" loading={loading}>
                  {listToString(plantilla?.categorias_de_plantillas_de_tramites
                    ?.map((c) => c?.descripcion ?? ''))}
                </SkeletonWrapperText>
              </Col>
              <Col span={24}>
                <SkeletonWrapperText dataLabel="Costo" loading={loading}>
                  {plantilla?.montoLabel}
                  {!isFree && plantilla?.montoLabel && plantilla?.descripcion_de_pago && ', '}
                  {!isFree && plantilla?.descripcion_de_pago}
                </SkeletonWrapperText>
              </Col>
              <Col span={24}>
                <SkeletonWrapperText dataLabel="Tipo" loading={loading}>
                  {plantilla?.tipo_de_tramite}
                </SkeletonWrapperText>
              </Col>
              <Col span={24}>
                <SkeletonWrapperText dataLabel="Clasificación" loading={loading}>
                  {plantilla?.clasificacion}
                </SkeletonWrapperText>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} xxl={8}>
            <Row style={{ height: '100%' }} align="middle">
              <Col span={24}>
                <CustomTag>
                  <Row>
                    <Col span={24}>
                      <SkeletonWrapperText
                        dataLabel="Homoclave"
                        loading={loading}
                      >
                        {plantilla?.homoclave}
                      </SkeletonWrapperText>
                    </Col>
                    <Col span={24}>
                      <SkeletonWrapperText
                        dataLabel="Última actualización"
                        accent
                        loading={loading}
                      >
                        {plantilla?.fechaDeActualizacion}
                      </SkeletonWrapperText>
                    </Col>
                    <Col span={24}>
                      <SkeletonWrapperText
                        dataLabel="Número de visitas"
                        accent
                        loading={loading}
                      >
                        <Text>
                          {plantilla?.volumen_de_consultas || 0}
                        </Text>
                      </SkeletonWrapperText>
                    </Col>
                  </Row>
                </CustomTag>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <br />
      <Row>
        <Col xs={24} sm={24} xl={16}>
          <Row gutter={[10, 10]} align="bottom">
            <Col>
              <Button
                block
                $accent
                shape="round"
                icon={<DownloadOutlined />}
                onClick={async () => {
                  await getPlantillaPDF({
                    id: plantilla.id,
                    setBase64,
                    setError,
                    setVisiblePreviewer,
                  });
                }}
              >
                <Text>
                  Descargar
                </Text>
              </Button>
            </Col>
            {buttonIniciarTramite && (
              <Col>
                {buttonIniciarTramite}
              </Col>
            )}
            {buttonAgendarCita && (
              <Col>
                {buttonAgendarCita}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Previewer
        visible={visiblePreviewer}
        onCancel={() => {
          setVisiblePreviewer(false);
          setBase64('');
          setError('');
        }}
        base64={base64}
        error={error}
      />
    </TramiteCard>
  );
};

BannerTramiteInfo.propTypes = {
  plantilla: PropTypes.shape({
    id: PropTypes.number,
    descripcion_de_pago: PropTypes.string,
    clasificacion: PropTypes.string,
    fechaDeActualizacion: PropTypes.string,
    nombre: PropTypes.string,
    descripcion: PropTypes.string,
    montoLabel: PropTypes.string,
    categorias_de_plantillas_de_tramites: PropTypes.arrayOf(PropTypes.shape({
      descripcion: PropTypes.string,
    })),
    es_tramite_gratuito: PropTypes.bool,
    tipo_de_tramite: PropTypes.string,
    dependencia: PropTypes.string,
    costoLabel: PropTypes.string,
    unidadesAdministrativas: PropTypes.string,
    homoclave: PropTypes.string,
    fecha_de_actualizacion: PropTypes.string,
    volumen_de_consultas: PropTypes
      .oneOfType([PropTypes.string, PropTypes.number]),
    departamentos: PropTypes.arrayOf(PropTypes.string),
  }),
  loading: PropTypes.bool.isRequired,
};

BannerTramiteInfo.defaultProps = {
  plantilla: {},
};

const CustomTag = styled(Tag)`
  border-radius: 10px;
  border: none;
  background-color: ${COLORS.gray}1c;
  padding: 20px 5px;
  min-width: 125px;
  min-width: 300px;
  white-space: break-spaces;
  width: 100%;
  align-content: center;

  @media screen and (max-width: 1599px) {
    max-width: 350px;
  }

`;

const TramiteCard = styled(AntCard)`
  margin-top: 10px;
  border-radius: 10px;

  .tramite-info {
    * {
      font-size: 16px !important;
    }
    .ant-row-no-wrap {
      padding: 5px;
    }
  }

  @media print {    
    &,&*{
      height: auto !important;
      overflow: visible !important;
    }
    .anticon {
      display: none !important;
    }
    .ant-collapse-content {
      display: block !important;
    }
    .ant-card, .ant-card-body {
      border: none !important;
    }
    .ant-card-body {
      padding: 0 !important;
      ${(props) => props.$breakBefore && `
          break-before: page;
      `}
    }
    .tramite-info {
      .ant-row-no-wrap {
        padding: 5px;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .skeleton-text-wrapper {
      .hide-on-print {
        display: none;
      }
      .show-on-print {
        display: unset;
      }
    }
    .ant-card-body{
      padding: 20px;
    }
    margin: 10px;
  }

  .ant-card-body {
    ${(props) => props.$breakBefore && 'padding: 0 !important;'}
  }
`;

export default BannerTramiteInfo;
