/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Card as AntCard,
  Row,
  Col,
  Form,
  Spin,
  Grid,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Text,
  Title,
} from 'components';
import _logger from 'utils/logger';
import { postLogin } from 'api/auth';
import { useDispatch } from 'react-redux';
import useQuery from 'hooks/useQuery';
import { handleLogout } from 'store/auth';
import { LogoutOutlined, RightOutlined } from '@ant-design/icons';
import CodeInput, { joinInputs } from 'components/CodeInput';

const { useBreakpoint } = Grid;

const Login = ({ modal }) => {
  const screens = useBreakpoint();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [redirect, codigo] = useQuery(['redirect', 'codigo']);

  useEffect(() => {
    if (codigo) {
      form.setFieldsValue({ codigo });
      form.submit();
    }
  }, []);

  const setToken = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = joinInputs(form.getFieldsValue());
      const loggedIn = await postLogin(dispatch, values);
      if (loggedIn && !modal) {
        const routeAfterLogin = redirect ? decodeURIComponent(redirect) : '/mi-cuenta/mis-datos';
        setTimeout(() => navigate(routeAfterLogin));
      }
      setLoading(false);
    } catch (err) {
      _logger(err);
      setLoading(false);
    }
  };

  const logout = () => {
    dispatch(handleLogout());
    setTimeout(() => navigate('/'));
  };

  const MODAL_TEXT = 'Introduce el código enviado a tu correo electrónico';
  const DEFAULT_TEXT = 'Se enviará a tu correo electrónico ingresado un enlace con el cual podrás iniciar sesión, solo presionando el enlace anexo. O introduce el código a continuación.';

  return (
    <Spin spinning={loading}>
      <Row gutter={[15, 15]}>
        <Col span={24}>
          <Wrapper
            title={!modal && (
              <Row justify="center">
                <Title level={2}>
                  Mi Cuenta Única
                </Title>
              </Row>
            )}
            style={{ margin: `15px 0 ${screens.md && !modal ? 100 : 6}px 15px` }}
            $modal={modal}
            bordered={!modal}
          >
            <Row justify="center">
              <Col span={24}>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={setToken}
                >
                  <Row justify="center" style={{ textAlign: 'center', marginBottom: 20 }}>
                    <Col span={24}>
                      {!modal && (
                        <Row justify="center" style={{ marginBottom: 20 }}>
                          <Title level={4}>
                            Para completar tu registro deberás activar tu cuenta
                          </Title>
                        </Row>
                      )}
                      <Text>
                        {modal ? MODAL_TEXT : DEFAULT_TEXT}
                      </Text>
                      <br />
                      <Text>
                        No olvides revisar la bandeja de spam.
                      </Text>
                      <br />
                      <Text>
                        O de igual forma, si agregaste tu número de celular ingresa el código
                        de activación enviado.
                      </Text>
                      <br />
                    </Col>
                  </Row>
                  <Row gutter={[15, 15]}>
                    <Col span={24}>
                      <CodeInput />
                    </Col>
                  </Row>
                  <br />
                  <Row justify={modal ? 'space-between' : 'center'}>
                    {modal && (
                    <Col>
                      <Form.Item>
                        <Button disabled={loading} onClick={logout} danger type="primary">
                          <LogoutOutlined />
                          <Text color="inherit">
                            Cerrar sesión
                          </Text>
                        </Button>
                      </Form.Item>
                    </Col>
                    )}
                    <Col>
                      <Form.Item>
                        <Button
                          block={!screens.md}
                          loading={loading}
                          onClick={() => setToken()}
                          type={modal ? 'primary' : null}
                          $accent={modal}
                        >
                          {modal ? 'Continuar' : 'Iniciar'}
                          {modal && (<RightOutlined />)}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item hidden>
                    <Button htmlType="submit" />
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Wrapper>
        </Col>
      </Row>
    </Spin>
  );
};

Login.propTypes = {
  modal: PropTypes.bool,
};
Login.defaultProps = {
  modal: false,
};

const Wrapper = styled(AntCard)`
  border-radius: 0.5rem;
  ${(props) => !props.$modal && 'box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.15);'}
  width: auto;
  height: ${(props) => (props.$modal ? '100%' : 'auto')};

  .ant-card-head {
    line-height: 1.2;
    margin-bottom: 10px;
    overflow: hidden;
    height: auto;
    .ant-typography {
      white-space: break-spaces;
    }
  }
  .ant-card-body {
    padding: 1rem 1rem;
    height: 'auto';
  }
`;

export default Login;
