/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Form, Input, Row, Col, Modal, DatePicker, Spin,
  Divider, Checkbox,
} from 'antd';
import {
  DATE_FORMAT,
} from 'utils/formatters';
import { patchGenerarDeclaracion, postDeclaracionCedularArrendamiento, postRechazarDeclaracion } from 'api/recaudacion';
import { SyncOutlined } from '@ant-design/icons';
import { Button, Text } from 'components';
import Select from 'components/PaginatedSelect';
import _logger from 'utils/logger';
import propTypes from 'prop-types';
import moment from 'moment';
import { RFC_PATTERN, CURP_PATTERN } from 'utils/patterns';
import ModalConfirm from './ModalConfirm';

const _rules = {
  required: (message) => ({
    required: true,
    message,
  }),
  numeric: [
    {
      validator: async (_, value) => {
        const regex = /^[0-9]+([.][0-9]{1,2})?$/;
        if (!value) return Promise.resolve();
        if (!Number.isInteger(parseFloat(value))) {
          return Promise.reject(new Error('Solo se permiten números enteros positivos.'));
        }
        if (!regex.test(value)) {
          return Promise.reject(new Error('Solo se permiten números positivos.'));
        }
        return Promise.resolve();
      },
    },
  ],
  mayorCero: [
    {
      validator: async (_, value) => {
        if (value <= 0) {
          throw new Error('El valor debe ser mayor a 0.');
        }
      },
    },
  ],
  entero: [
    {
      validator: async (_, value) => {
        const regex = /^[0-9]+$/;
        if (!value) return Promise.resolve();
        if (!regex.test(value)) {
          return Promise.reject(new Error('Solo se permiten números enteros positivos.'));
        }
        return Promise.resolve();
      },
    },
  ],
  rfc: [{ pattern: RFC_PATTERN, message: ' Ingrese un RFC valido.' }],
  curp: [{ pattern: CURP_PATTERN, message: ' Ingrese una CURP valida.' }],
};

const FormCedularArrendamiento = ({
  padron,
  statusDeclaracion,
  visible,
  setVisibleForm,
  isComplementaria,
  selectedCargos,
  callback,
  callbackCancel,
  declaracionId,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isZeroState, setIsZeroState] = useState(false);
  const [disabledAutorizadas, setDisabledAutorizadas] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [porcentajePeriodo, setPorcentajePeriodo] = useState(0);

  const handleCancel = async () => {
    setLoading(true);
    await postRechazarDeclaracion({
      declaracion: declaracionId,
      canal_de_pago: 5,
    });
    setLoading(false);
  };

  const handleBeforeUnload = () => {
    handleCancel();
  };

  const handleUnload = (event) => {
    const message = 'Se perderán los datos capturados, ¿Estás seguro de salir?';
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.returnValue = message; // Esto mostrará el cuadro de confirmación
    return message;
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (visible) {
      window.addEventListener('unload', handleUnload);
      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('unload', handleUnload);
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [visible]);

  // Resetea el formulario cuando se cierra.
  useEffect(() => {
    if (visible) {
      const _porcentaje = ((selectedCargos[0]?.importe || 3) / 100);
      setPorcentajePeriodo(_porcentaje);
      form.resetFields();
    }
  }, [visible]);

  function redondear(numero) {
    const decimal = numero - Math.floor(numero);

    if (decimal >= 0.51) {
      return Math.ceil(numero);
    }
    return Math.floor(numero);
  }

  const patchDeclaracionStatus = async (values) => {
    try {
      let statusId = null;

      const statusObject = {
        generada: statusDeclaracion.find((status) => !status.declaracion_presentada && !status.declaracion_en_ceros)?.id,
        en_ceros: statusDeclaracion.find((status) => status.declaracion_en_ceros)?.id,
        sin_importe: statusDeclaracion.find((status) => status.descripcion === 'Declaración con Cantidades a Pagar y a Favor en Cero')?.id || 0,
        saldo_favor: statusDeclaracion.find((status) => status.descripcion === 'Declaración con Cantidad a Favor')?.id || 0,
      };

      if (isZeroState) {
        statusId = statusObject.en_ceros;
      } else if (parseFloat(values.cantidad_a_favor) > 0) {
        statusId = statusObject.saldo_favor;
      } else if (parseFloat(values.cantidad_a_cargo) <= 0) {
        statusId = statusObject.sin_importe;
      } else {
        statusId = statusObject.generada;
      }
      const request = await patchGenerarDeclaracion({
        id: values.declaracion_empresarial,
        estatus_de_declaracion_empresarial: statusId,
        razones_para_presentar_en_ceros: values.razones_para_presentar_en_ceros,
      });

      if (!request) throw new Error('Error al actualizar la declaración.');
    } catch (error) {
      _logger(error);
    }
  };

  // Envia los datos del formulario.
  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();

      const _padronValues = {
        razon_social_nombre_completo: padron?.empresa?.contribuyente?.razon_social || '',
        rfc: padron?.empresa?.contribuyente?.rfc || '',
        curp: padron?.empresa?.contribuyente?.curp || '',
        nombre: padron?.empresa?.contribuyente?.nombre_completo || '',
        nombre_completo: padron?.empresa?.contribuyente?.nombre_completo || '',
        apellido_paterno: padron?.empresa?.contribuyente?.apellido_paterno || '',
        apellido_materno: padron?.empresa?.contribuyente?.apellido_materno || '',
      };

      let _values = {
        ...values,
        ..._padronValues,
        declaracion_empresarial: declaracionId,
        entidad: 1,
        fecha_del_pago_realizado_anterior: isComplementaria && !isZeroState ? values.fecha_del_pago_realizado_anterior.format(DATE_FORMAT) : null,
      };

      if (isZeroState) {
        _values = {
          ..._values,
          cantidad_a_favor: 0,
          cantidad_a_cargo: 0,
          impuesto_cedular_determinado: 0,
          total_de_ingresos_obtenidos: 0,
          impuesto_cedular_a_cargo: 0,
          retenciones_por_terceros: 0,
          base_del_pago: 0,
          deduccion_autorizada_comprobable: 0,
          monto_pagado_con_anterioridad: 0,
          actualizacion: 0,
          multa_por_correccion_fiscal: 0,
          recargos: 0,
          retenciones: 0,
          total_de_contribuciones: 0,
          deduccion_opcional: 0,
          impuesto_predial: 0,
          base_del_pago1_por_deduccion_opcional: 0,
          base_del_pago2_por_deduccion_autorizada_comprobable: 0,
        };
      }

      if (!isComplementaria) {
        _values = {
          ..._values,
          fecha_del_pago_realizado_anterior: null,
          monto_pagado_con_anterioridad: 0,
        };
      }

      await patchDeclaracionStatus(_values);
      await postDeclaracionCedularArrendamiento(_values);

      callback(_values.cantidad_a_cargo <= 0);
      setIsZeroState(false);
      setIsVisibleModal(false);
      setVisibleForm(false);
    } catch (error) {
      _logger(error);
    } finally {
      setLoading(false);
    }
  };

  const validacionesDeOperaciones = (values) => {
    const retencion_rounded = parseFloat(redondear(values.retenciones)).toFixed(2);
    const impuesto_rounded = parseFloat(redondear(values.impuesto)).toFixed(2);
    const ingresos_cedular = parseFloat(redondear(values.total_de_ingresos_obtenidos)).toFixed(2);
    const impuesto_cedular_a_cargo = redondear(parseInt(values.impuesto_cedular_a_cargo, 10));

    const isCheck = form.getFieldValue('usar_deduccion_opcional');

    // ImpuestosCorrespondiente menos Retenciones = ImpuestoCargo(debe ser mayor igual a cero)
    if ((parseFloat(impuesto_rounded) - parseFloat((retencion_rounded))) < 0 && !isZeroState) {
      form.validateFields(['impuesto_a_cargo']).then(() => {
        form.setFields([
          {
            name: 'impuesto_a_cargo',
            errors: ['El valor no puede ser mayor al impuesto correspondiente.'],
          },
        ]);
      });
      form.validateFields(['retenciones']).then(() => {
        form.setFields([
          {
            name: 'retenciones',
            errors: ['El valor no puede ser mayor al impuesto correspondiente.'],
          },
        ]);
      });
    } else if ((parseFloat(impuesto_rounded) - parseFloat((retencion_rounded))) >= 0 && !isZeroState) {
      form.validateFields(['impuesto_a_cargo']).then(() => {
        form.setFields([
          {
            name: 'impuesto_a_cargo',
            errors: [],
          },
        ]);
      });
      form.validateFields(['retenciones']).then(() => {
        form.setFields([
          {
            name: 'retenciones',
            errors: [],
          },
        ]);
      });
    }

    if (parseInt(ingresos_cedular, 10) <= 0) {
      form.setFieldsValue({ ingresos_cedular: 0 });
      setDisableFields(true);
      setDisabledAutorizadas(true);
    } else {
      setDisableFields(false);
      setDisabledAutorizadas(false);
    }

    if (parseFloat(impuesto_cedular_a_cargo) < 0) {
      form.validateFields(['impuesto_cedular_a_cargo']).then(() => {
        form.setFields([
          {
            name: 'impuesto_cedular_a_cargo',
            errors: ['El valor no puede ser menor a 0.'],
          },
        ]);
      });
    }

    if (isCheck) {
      setDisabledAutorizadas(true);
    }

    // Dedduciones autorizadas no debe ser mayor a total_de_ingresos
    if (parseFloat(impuesto_cedular_a_cargo) <= 0) {
      form.validateFields(['impuesto_cedular_a_cargo']).then(() => {
        setDisableFields(true);
      });
    } else {
      form.validateFields(['impuesto_cedular_a_cargo']).then(() => {
        setDisableFields(false);
      });
    }

    if ((parseFloat(impuesto_rounded) - parseFloat((retencion_rounded))) <= 0 && !isZeroState) {
      form.setFieldsValue({ actualizacion: 0, recargos: 0, multa_por_correccion_fiscal: 0 });
    }
  };

  // Calcula el total de la cantidad a pagar.
  const total = () => {
    const values = form.getFieldsValue();

    const actualizacion = parseFloat(values.actualizacion) || 0;
    const recargos = parseFloat(values.recargos) || 0;
    const multas = parseFloat(values.multa_por_correccion_fiscal) || 0;
    const monto_pagado_con_anterioridad = parseFloat(values.monto_pagado_con_anterioridad) || 0;

    const isCheck = form.getFieldValue('usar_deduccion_opcional');

    const impuesto1 = parseInt(values.total_de_ingresos_obtenidos, 10) || 0;
    const impuesto2 = parseInt(values.deduccion_autorizada_comprobable, 10) || 0;
    const impuesto3 = parseInt(values.retenciones_por_terceros, 10) || 0;
    const impuesto4 = parseInt(values.impuesto_predial, 10) || 0;
    const impuesto6 = parseInt(values.deduccion_opcional, 10) || 0;

    // OPERACIONES
    let base_del_pago1 = 0;
    let base_del_pago2 = 0;
    let impuesto_determinado = 0;

    if (isCheck) {
      base_del_pago1 = impuesto1 - (impuesto4 + impuesto6);
      impuesto_determinado = base_del_pago1 * porcentajePeriodo;
    } else {
      base_del_pago1 = 0;
    }

    if (isCheck === false || impuesto2 > 0) {
      base_del_pago2 = impuesto1 - (impuesto4 + impuesto2);
      impuesto_determinado = base_del_pago2 * porcentajePeriodo;
    } else {
      base_del_pago2 = 0;
    }

    const base_del_pago_total = [
      impuesto2,
      impuesto1,
    ].reduce((a, b) => b - a, 0);

    const total_de_contribuciones_total = [
      actualizacion,
      recargos,
      multas,
    ].reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(2);

    // BASE DEL PAGO
    const base_del_pago1_por_deduccion_opcional = base_del_pago1 < 0 ? 0 : redondear(parseInt(base_del_pago1, 10));
    const base_del_pago2_por_deduccion_autorizada_comprobable = base_del_pago2 < 0 ? 0 : redondear(parseInt(base_del_pago2, 10));
    const base_de_pago = base_del_pago_total < 0 ? 0 : redondear(parseFloat(base_del_pago_total).toFixed(2));
    const impuesto_cedular_determinado = impuesto_determinado < 0 ? 0 : redondear(parseInt(impuesto_determinado, 10));

    const impuesto_cedular = [
      impuesto_cedular_determinado,
      impuesto3,
    ].reduce((a, b) => a - b);

    const impuesto_cedular_a_cargo = redondear(parseInt(impuesto_cedular, 10));

    const total_de_contribuciones = redondear(parseInt(
      parseInt(impuesto_cedular_a_cargo, 10)
      + parseInt(total_de_contribuciones_total, 10),
      10,
    ));

    const cantidad_a_cargo = parseFloat(total_de_contribuciones - monto_pagado_con_anterioridad).toFixed(2);
    const cantidad_a_pagar_rounded = redondear(parseFloat(cantidad_a_cargo).toFixed(2));

    form.setFieldsValue({
      base_del_pago: parseFloat(base_de_pago).toFixed(2),
      impuesto_cedular_determinado,
      base_del_pago1_por_deduccion_opcional,
      base_del_pago2_por_deduccion_autorizada_comprobable,
      total_de_contribuciones,
      impuesto_cedular_a_cargo,
    });

    const isNegative = parseInt(total_de_contribuciones, 10) < parseInt(monto_pagado_con_anterioridad, 10);

    if (isNegative) {
      const total_a_favor_rounded = redondear(parseFloat((monto_pagado_con_anterioridad - total_de_contribuciones)).toFixed(2));
      form.setFieldsValue({
        cantidad_a_favor: parseFloat(total_a_favor_rounded).toFixed(2),
        cantidad_a_cargo: parseFloat(0).toFixed(2),
      });
    } else {
      form.setFieldsValue({
        cantidad_a_cargo: parseFloat(cantidad_a_pagar_rounded).toFixed(2),
        cantidad_a_favor: parseFloat(0).toFixed(2),
      });
    }
    validacionesDeOperaciones({
      ...values,
      impuesto_cedular_a_cargo,
      total_de_ingresos_obtenidos: impuesto1,
    });
  };

  return (
    <>
      <Modal
        centered
        title={(
          <Row justify="space-between">
            <Text $accent>
              IMPUESTO CEDULAR ARRENDAMIENTO DE BIENES INMUEBLES
            </Text>
          </Row>
        )}
        visible={visible}
        closable={false}
        footer={(
          <Row justify="space-between" gutter={[10, 15]}>
            <Col xs={24} sm={24} md={12} style={{ textAlign: 'left' }}>
              <Text $accent>* Este campo es obligatorio</Text>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Button
                style={{ width: '100%', borderRadius: 5 }}
                onClick={() => callbackCancel()}
                loading={loading}
                $accent
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Button
                type="primary"
                style={{ width: '100%', borderRadius: 5 }}
                onClick={() => {
                  form.validateFields().then(() => {
                    setIsVisibleModal(true);
                  }).catch(() => {
                    setIsVisibleModal(false);
                  });
                }}
                loading={loading}
                icon={<SyncOutlined />}
                $accent
              >
                Generar
              </Button>
            </Col>
          </Row>
      )}
        width="45vw"
      >
        <Spin spinning={loading} tip="Cargando...">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
            initialValues={{
              fecha_del_pago_anterior: moment(),
              es_declaracion_en_ceros: false,
              total_de_ingresos_obtenidos: 0,
              deduccion_autorizada_comprobable: 0,
              retenciones_por_terceros: 0,
              impuesto_cedular_a_cargo: 0,
              base_del_pago: 0,
              recargos: 0,
              deduccion_opcional: 0,
              base_del_pago1_por_deduccion_opcional: 0,
              base_del_pago2_por_deduccion_autorizada_comprobable: 0,
              impuesto_predial: 0,
              multa_por_correccion_fiscal: 0,
              actualizacion: 0,
              cantidad_a_cargo: 0,
              cantidad_a_favor: 0,
              total_de_contribuciones: 0,
              impuesto_cedular_determinado: 0,
              monto_pagado_con_anterioridad: isComplementaria ? 0 : 0,
              usar_deduccion_opcional: false,
            }}
          >
            <Row gutter={10}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="es_declaracion_en_ceros"
                  label="¿Es Declaración en ceros?"
                  required
                >
                  <Select
                    placeholder="Selecciona una opción"
                    trueFalse
                    allowClear={false}
                    onChange={(value) => setIsZeroState(value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              { isZeroState ? (
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name="razones_para_presentar_en_ceros"
                    label="Razones por las que presenta en ceros"
                    rules={[_rules.required('El campo es requerido')]}
                  >
                    <Input.TextArea />
                  </Form.Item>
                </Col>
              ) : (
                <Col xs={24} sm={24} md={24}>
                  <Text $accent $bold>DETERMINACIÓN DEL IMPUESTO </Text>
                  <table style={{ minWidth: '100%' }}>
                    <thead>
                      <tr>
                        <th>Concepto</th>
                        <th>Monto</th>
                      </tr>
                      <tr>
                        <td>Total de ingresos en el mes</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="total_de_ingresos_obtenidos"
                            rules={[
                              _rules.required('El campo es requerido'),
                              ..._rules.entero,
                              ..._rules.numeric,
                              // ..._rules.mayorCero,
                            ]}
                          >
                            <Input
                              prefix="$"
                              onChange={() => {
                                const isCheck = form.getFieldValue('usar_deduccion_opcional');
                                if (isCheck) {
                                  form.setFieldsValue({
                                    deduccion_opcional: parseInt((form.getFieldValue('total_de_ingresos_obtenidos') * 0.35), 10),
                                  });
                                } else {
                                  form.setFieldsValue({ deduccion_opcional: 0 });
                                  form.setFieldsValue({ deduccion_autorizada_comprobable: 0 });
                                }
                                total();
                              }}
                            />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Deducción opcional (35%)</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="usar_deduccion_opcional"
                            valuePropName="checked"
                          >
                            <Checkbox
                              onChange={(value) => {
                                if (value.target.checked) {
                                  const ingresos = form.getFieldValue('total_de_ingresos_obtenidos');
                                  setDisabledAutorizadas(true);
                                  form.setFieldsValue({ deduccion_opcional: parseInt(ingresos * 0.35, 10) });
                                  form.setFieldsValue({ deduccion_autorizada_comprobable: 0 });
                                } else {
                                  form.setFieldsValue({ deduccion_opcional: 0 });
                                  form.setFieldsValue({ base_del_pago1_por_deduccion_opcional: 0 });
                                  setDisabledAutorizadas(false);
                                }
                                total();
                              }}
                            >
                              Usar deducción opcional
                            </Checkbox>
                          </Form.Item>
                          <Form.Item
                            hasFeedback
                            name="deduccion_opcional"
                            rules={[
                              _rules.required('El campo es requerido'),
                              ..._rules.entero,
                              ..._rules.numeric,
                            ]}
                          >
                            <Input
                              prefix="$"
                              disabled
                              onChange={(_value) => {
                                if (parseInt(_value.target.value, 10) === 0) {
                                  form.validateFields(['deduccion_autorizada_comprobable']);
                                  setDisabledAutorizadas(false);
                                } else {
                                  form.setFieldsValue({ deduccion_autorizada_comprobable: 0 });
                                  form.validateFields(['base_del_pago1_por_deduccion_opcional']);
                                  setDisabledAutorizadas(true);
                                }
                                total();
                              }}
                            />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Impuesto predial</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="impuesto_predial"
                            rules={[
                              _rules.required('El campo es requerido'),
                              ..._rules.entero,
                              ..._rules.numeric,
                            ]}
                          >
                            <Input
                              prefix="$"
                              onChange={() => {
                                total();
                              }}
                            />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Deducción autorizada comprobable</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="deduccion_autorizada_comprobable"
                            rules={[
                              _rules.required('El campo es requerido'),
                              ..._rules.entero,
                              ..._rules.numeric,
                            ]}
                          >
                            <Input
                              disabled={disabledAutorizadas}
                              prefix="$"
                              onChange={(_value) => {
                                if (_value.target.value === '0') {
                                  form.validateFields(['deduccion_opcional']);
                                  form.setFieldsValue({ deduccion_opcional: 0 });
                                } else {
                                  form.setFieldsValue({ deduccion_opcional: 0 });
                                }
                                total();
                              }}
                            />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Base del pago opcionales</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="base_del_pago1_por_deduccion_opcional"
                            rules={
                              [_rules.required('El campo es requerido'),
                                ..._rules.numeric,
                              ]
                            }
                          >
                            <Input prefix="$" disabled />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Base del pago comprobable</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="base_del_pago2_por_deduccion_autorizada_comprobable"
                            rules={
                              [_rules.required('El campo es requerido'),
                                ..._rules.numeric,
                              ]
                            }
                          >
                            <Input prefix="$" disabled />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Impuesto determinado</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="impuesto_cedular_determinado"
                            rules={[
                              _rules.required('El campo es requerido'),
                              ..._rules.entero,
                              ..._rules.numeric,
                            ]}
                          >
                            <Input prefix="$" disabled />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Retenciones por terceros</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="retenciones_por_terceros"
                            rules={[
                              _rules.required('El campo es requerido'),
                              ..._rules.entero,
                              ..._rules.numeric,
                            ]}
                          >
                            <Input
                              prefix="$"
                              onChange={() => { total(); }}
                            />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Impuesto cedular a cargo</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="impuesto_cedular_a_cargo"
                            rules={[
                              _rules.required('El campo es requerido'),
                              ..._rules.entero,
                              ..._rules.numeric,
                            ]}
                          >
                            <Input prefix="$" disabled />
                          </Form.Item>
                        </td>
                      </tr>
                      <Divider />
                      <Text $accent $bold>PAGO DEL IMPUESTO</Text>
                      <tr>
                        <td>Impuesto cedular a cargo</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="impuesto_cedular_a_cargo"
                            rules={[
                              _rules.required('El campo es requerido'),
                              ..._rules.entero,
                              ..._rules.numeric,
                            ]}
                          >
                            <Input prefix="$" disabled />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Actualización (Monto manual)</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="actualizacion"
                            rules={[_rules.required('El campo es requerido'), ..._rules.numeric]}
                          >
                            <Input
                              prefix="$"
                              disabled={disableFields}
                              onChange={() => total()}
                            />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Recargos (Monto manual)</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="recargos"
                            rules={[_rules.required('El campo es requerido'), ..._rules.numeric]}
                          >
                            <Input
                              prefix="$"
                              disabled={disableFields}
                              onChange={() => total()}
                            />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Multas (Monto manual)</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="multa_por_correccion_fiscal"
                            rules={[_rules.required('El campo es requerido'), ..._rules.numeric]}
                          >
                            <Input
                              prefix="$"
                              disabled={disableFields}
                              onChange={() => total()}
                            />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Total de contribuciones</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="total_de_contribuciones"
                            rules={[_rules.required('El campo es requerido'), ..._rules.numeric]}
                          >
                            <Input prefix="$" disabled />
                          </Form.Item>
                        </td>
                      </tr>
                      {
                        isComplementaria && (
                          <>
                            <tr>
                              <td>Fecha del pago anterior</td>
                              <td>
                                <Form.Item
                                  name="fecha_del_pago_realizado_anterior"
                                  rules={[_rules.required('El campo es requerido')]}
                                >
                                  <DatePicker format={DATE_FORMAT} />
                                </Form.Item>
                              </td>
                            </tr>
                            <tr>
                              <td>Monto pagado con anterioridad (Monto manual)</td>
                              <td>
                                <Form.Item
                                  hasFeedback
                                  name="monto_pagado_con_anterioridad"
                                  rules={[
                                    _rules.required('El campo es requerido'),
                                    ..._rules.numeric,
                                    // ..._rules.noMayorAImpustoCorrespondiente,
                                  ]}
                                >
                                  <Input prefix="$" onChange={() => { total(); }} />
                                </Form.Item>
                              </td>
                            </tr>
                          </>
                        )
                      }
                      <tr>
                        <td>Cantidad a pagar</td>
                        <td>
                          <Form.Item
                            name="cantidad_a_cargo"
                          >
                            <Input prefix="$" disabled />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Cantidad a favor</td>
                        <td>
                          <Form.Item
                            name="cantidad_a_favor"
                          >
                            <Input prefix="$" disabled />
                          </Form.Item>
                        </td>
                      </tr>
                    </thead>
                  </table>
                </Col>
              )}
            </Row>
          </Form>
        </Spin>
      </Modal>
      <ModalConfirm
        visible={isVisibleModal}
        setVisible={setIsVisibleModal}
        onFinish={() => onFinish()}
        body="Al generar la declaración no podrá ser modificada. ¿Desea continuar?"
        btnText="Si, generar declaración"
        loading={loading}
      />
    </>
  );
};

FormCedularArrendamiento.propTypes = {
  padron: propTypes.shape({
    empresa: propTypes.shape({
      contribuyente: propTypes.shape({
        razon_social: propTypes.string,
        rfc: propTypes.string,
        nombre_completo: propTypes.string,
        curp: propTypes.string,
        nombre: propTypes.string,
        apellido_paterno: propTypes.string,
        apellido_materno: propTypes.string,
        ciudadano: propTypes.shape({
          curp: propTypes.string,
          nombre: propTypes.string,
          apellido_paterno: propTypes.string,
          apellido_materno: propTypes.string,
        }),
      }),
    }),
  }).isRequired,
  statusDeclaracion: propTypes.number.isRequired,
  visible: propTypes.bool,
  isComplementaria: propTypes.bool,
  setVisibleForm: propTypes.func.isRequired,
  callback: propTypes.func.isRequired,
  callbackCancel: propTypes.func,
  declaracionId: propTypes.func.isRequired,
  selectedCargos: propTypes.arrayOf(propTypes.shape({
    importe: propTypes.number,
  })),
};

FormCedularArrendamiento.defaultProps = {
  isComplementaria: false,
  visible: false,
  selectedCargos: [],
  callbackCancel: () => {},
};

export default FormCedularArrendamiento;
