/* eslint-disable import/no-dynamic-require */
import React, { useEffect } from 'react';
import {
  Layout as AntLayout,
  Grid,
  Row,
  Col,
  message,
  BackTop,
  Button as AntButton,
  Tooltip,
} from 'antd';
import {
  Outlet,
  useLocation,
  Link,
  useOutlet,
  useNavigate,
} from 'react-router-dom';
import styled from 'styled-components';
import {
  FacebookOutlined,
  InfoCircleOutlined,
  TwitterOutlined,
  VerticalAlignTopOutlined,
  YoutubeOutlined,
  InstagramOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import {
  Button,
  Title,
  Text,
  COLORS,
  FlexCol,
} from 'components';
import ScrollToTop, { getScrollableArea } from 'components/ScrollToTop';
import { menuRoutes } from 'routes';
import {
  ACCOUNT_AVAILABLE,
  ENV_NAME,
  ENV_NAMES_ENUM,
  REPUVE,
} from 'utils/env';
import { bg, logo, asistencia } from 'assets';
import SessionExpiredModal from 'components/SessionExpiredModal';
import { useSelector } from 'react-redux';
import { selectToken } from 'store/auth';

const { Header } = AntLayout;

const socialMediaIcons = {
  facebook: (<FacebookOutlined style={{ fontSize: 22 }} />),
  twitter: (<TwitterOutlined style={{ fontSize: 22 }} />),
  youtube: (<YoutubeOutlined style={{ fontSize: 22 }} />),
  instagram: (<InstagramOutlined style={{ fontSize: 22 }} />),
};

const states = {
  [ENV_NAMES_ENUM.NAYARIT]: {
    textColor: '',
    socialMedia: {
      facebook: 'https://facebook.com/GobNay',
      twitter: 'https://twitter.com/NayaritGobierno',
    },
    footer: '2021-2027 Gobierno Constitucional del Estado de Nayarit',
  },
  [ENV_NAMES_ENUM.JUAREZ]: {
    textColor: 'white',
    socialMedia: {
      facebook: 'https://www.facebook.com/GobiernoCdJuarez',
      twitter: 'https://twitter.com/MunicipioJuarez',
    },
    footer: 'Gobierno Municipal 2021 - 2024 | H. Ayuntamiento de Juárez',
  },
  [ENV_NAMES_ENUM.HERMOSILLO]: {
    textColor: '',
    socialMedia: {
      facebook: 'https://www.facebook.com/hermosillogob',
      twitter: 'https://twitter.com/HermosilloGob',
      youtube: 'https://www.youtube.com/user/HermosilloGob',
      instagram: 'https://www.instagram.com/hermosillogob/',
    },
    footer: 'Portal de Gobierno - H. Ayuntamiento de Hermosillo',
  },
  [ENV_NAMES_ENUM.ROATAN]: {
    textColor: '',
    socialMedia: {
      facebook: 'https://www.facebook.com/AlcaldiadeRoatan',
      twitter: 'https://x.com/alcaldia_roatan?s=21&t=ukmrgy1pNBZDyg73zCBtPw',
      whatsapp: 'https://wa.me/50499018629',
    },
    footer: 'ROATÁN, Municipalidad, Islas de la Bahía, Honduras',
  },
};

const statesRepuve = {
  [ENV_NAMES_ENUM.NAYARIT]: {
    textColor: '',
    socialMedia: {
      facebook: 'https://facebook.com/registropublicovehicular',
      twitter: 'https://twitter.com/repuvenayarit',
    },
    footer: (
      <a href="https://www.nayarit.gob.mx/" rel="noreferrer" target="_blank">
        2021-2027 - Gobierno Constitucional del Estado de Nayarit
      </a>
    ),
  },
};

const current = (REPUVE ? statesRepuve : states)[ENV_NAME];

// eslint-disable-next-line react/prop-types
const AppFooter = ({ padding, height, hidePrivacyButton = true }) => {
  const navigation = useNavigate();
  // TODO hacer bien lo de avisos de privacidad
  const action = ENV_NAMES_ENUM.HERMOSILLO === ENV_NAME
    ? () => window.open('https://www.hermosillo.gob.mx/avisosdeprivacidad/', '_blank')
    : () => navigation('/aviso-de-privacidad');

  return (
    <Footer style={{ padding: `5px ${padding}px`, height }}>
      <Row justify="space-evenly">
        <Text style={{ lineHeight: '35px', color: COLORS.white }}>
          {current.footer}
        </Text>
        {!hidePrivacyButton && (
        <Button type="link" color={COLORS.white} onClick={() => action()}>
          Aviso de privacidad
        </Button>
        )}
        <div>
          {Object.keys(current.socialMedia).map((key) => (
            <Button type="link" color={COLORS.white} key={key}>
              <a href={current.socialMedia[key]} rel="noreferrer" target="_blank">
                {socialMediaIcons[key]}
              </a>
            </Button>
          ))}
        </div>
      </Row>
    </Footer>
  );
};

const getHeaderHeight = (screens) => {
  if (ENV_NAME === ENV_NAMES_ENUM.NAYARIT) {
    // return screens.lg || screens.xs ? 'auto' : 'auto';
  }
  return screens.lg ? 100 : 160;
};

const AppLayout = () => {
  const token = useSelector(selectToken);
  const outlet = useOutlet();
  const { pathname } = useLocation();
  const screens = Grid.useBreakpoint();
  const padding = screens.md ? 24 : 12;
  const xPadding = screens.md ? 12 : 3;

  const HEADER_HEIGHT = getHeaderHeight(screens);
  const FOOTER_HEIGHT = screens.md ? 46 : 120;
  const ASSISTANCE_HEIGHT = (screens.md ? 350 : 240) + FOOTER_HEIGHT;

  const selectedKey = `/${pathname.split('/')[1]}`;
  const outletHasElement = !!outlet?.props?.children?.props?.children;

  const gradients = {
    [ENV_NAMES_ENUM.NAYARIT]: `linear-gradient(40deg, #635B5F 0%, ${COLORS.gray} 100%)`,
    [ENV_NAMES_ENUM.JUAREZ]: `linear-gradient(40deg, ${COLORS.gray} 0%, ${COLORS.gray} 100%)`,
    [ENV_NAMES_ENUM.HERMOSILLO]: `linear-gradient(40deg, ${COLORS.white} 0%, ${COLORS.white} 100%)`,
    [ENV_NAMES_ENUM.ROATAN]: 'linear-gradient(40deg, #0084B6 0%, #007474 100%)',
  };

  const borderBottom = ENV_NAME === ENV_NAMES_ENUM.ROATAN ? '#C39260' : COLORS.accent;

  useEffect(() => {
    message.config({
      top: screens.lg ? 150 : 200,
      duration: 10,
      maxCount: 1,
    });
  }, [screens.lg]);

  const restheightcontentcontent = outletHasElement ? HEADER_HEIGHT + ASSISTANCE_HEIGHT : 0;

  const fullSize = { md: 24 };

  const routes = [
    {
      regexp: /mi-cuenta/,
      beakpoints: fullSize,
    },
    {
      regexp: /tramite\/\d+\/nueva/,
      beakpoints: fullSize,
    },
    {
      regexp: /tramites\/seguimiento/,
      beakpoints: fullSize,
    },
    {
      regexp: /citas\/nueva|consulta/,
      beakpoints: fullSize,
    },
  ];

  const defaults = { md: 22, lg: 20, xl: 18 };
  const breakpoints = selectedKey === '/' ? fullSize
    : routes.find((r) => r.regexp.test(pathname))?.beakpoints || defaults;
  const _padding = !screens.md ? 0 : `${xPadding}px ${padding}px`;

  return (
    <React.Fragment key={token}>
      <Layout>
        <Header
          style={{
            padding: _padding,
            backgroundColor: COLORS.gray,
            backgroundImage: gradients[ENV_NAME],
            height: HEADER_HEIGHT,
            borderBottom: `5px solid ${borderBottom}`,
          }}
        >
          <Row
            align="middle"
            gutter={[0, 10]}
            justify={screens.lg ? 'space-between' : 'center'}
            style={{ maxWidth: ENV_NAME === ENV_NAMES_ENUM.NAYARIT ? '' : 1200, marginLeft: 'auto', marginRight: 'auto' }}
          >
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={8}
              style={screens.lg && ENV_NAME !== ENV_NAMES_ENUM.NAYARIT ? {} : { display: 'flex', justifyContent: 'center' }}
            >
              <Link to="/">
                <Logo />
              </Link>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16}>
              <Row className="menu-routes">
                {menuRoutes.map((r) => (
                  <MenuItem
                    key={r.path}
                    type="link"
                    bg={r.path === selectedKey ? '#ffffff19' : 'transparent'}
                    $lastToRight={ACCOUNT_AVAILABLE
                      && (ENV_NAME !== ENV_NAMES_ENUM.NAYARIT || screens.lg)}
                    $breakLabel={!!r.breakLabel}
                  >
                    <Tooltip title={!screens.xl ? r.label : null} placement="bottom">
                      <Link to={r.path}>
                        <div className="icon" style={{ width: '100% ' }}>
                          {r.icon}
                        </div>
                        {screens.xl && (<div>{r.label}</div>)}
                      </Link>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Row>
            </Col>
          </Row>
        </Header>
        <Content
          translatex={screens.md ? 50 : 0}
          restheightcontent={screens.md ? HEADER_HEIGHT : null}
          restheightcontentcontent={restheightcontentcontent}
        >
          <div
            className="site-content"
            style={{
              margin: 0,
            }}
          >
            <div
              className="site-content-content"
              style={{
                margin: 0,
                padding: `${screens.md ? padding : 0}px ${screens.md ? 50 : 0}px 0`,
              }}
            >
              <Row
                justify="center"
                style={{ minHeight: `calc(100vh - ${restheightcontentcontent + padding * 2}px)` }}
              >
                <Col
                  xs={24}
                  sm={24}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...breakpoints}
                >
                  <Outlet />
                </Col>
              </Row>
            </div>
            <Row
              justify="center"
              align="middle"
              className="asistencia"
              style={{
                marginTop: screens.md && outletHasElement ? 100 : 0,
                transform: `translate(0, ${outletHasElement
                  ? 0 : `calc(100vh - ${HEADER_HEIGHT + ASSISTANCE_HEIGHT + padding + 250}px`})`,
                height: ASSISTANCE_HEIGHT - 100,
              }}
            >
              {screens.md && (
                <Col md={10} lg={8}>
                  <SupportImage />
                </Col>
              )}
              <Col xs={24} sm={24} md={12} lg={10}>
                <FlexCol
                  alignItems="start"
                  style={{
                    marginLeft: 'auto',
                    width: 'fit-Content',
                    paddingRight: 20,
                  }}
                >
                  <Title $accent>
                    Asistencia al contribuyente
                  </Title>
                  {pathname !== '/asistencia-al-contribuyente' && (
                    <>
                      <Text color={current.textColor} style={{ mixBlendMode: 'luminosity' }}>
                        ¿Tienes alguna duda al utilizar este portal?
                      </Text>
                      <Button color={COLORS.accent} style={{ marginTop: 15 }}>
                        <Link to="asistencia-al-contribuyente">
                          Guía del portal
                        </Link>
                        <InfoCircleOutlined />
                      </Button>
                      {ENV_NAME === ENV_NAMES_ENUM.NAYARIT && (
                      <Button
                        color={COLORS.accent}
                        style={{ marginTop: 15 }}
                      >
                        <a href="https://web.whatsapp.com/send?phone=+523113918750" rel="noreferrer" target="_blank">
                          Asistencia vía WhatsApp
                        </a>
                        <WhatsAppOutlined />
                      </Button>
                      )}
                      {ENV_NAME === ENV_NAMES_ENUM.ROATAN && (
                      <Button
                        color={COLORS.accent}
                        style={{ marginTop: 15 }}
                      >
                        <a href="https://web.whatsapp.com/send?phone=+50499018629" rel="noreferrer" target="_blank">
                          Asistencia vía WhatsApp
                        </a>
                        <WhatsAppOutlined />
                      </Button>
                      )}
                    </>
                  )}
                </FlexCol>
              </Col>
              <AppFooter
                height={FOOTER_HEIGHT}
                padding={padding}
                hidePrivacyButton={pathname === '/aviso-de-privacidad'}
              />
            </Row>
          </div>
          {!!getScrollableArea(screens) && (
            <BackTop target={() => getScrollableArea(screens)}>
              <Button
                $accent
                style={{
                  width: 40, height: 40, border: '1px solid #f0f2f5', padding: 0,
                }}
              >
                <VerticalAlignTopOutlined style={{ fontSize: 24, margin: 'auto' }} />
              </Button>
            </BackTop>
          )}
        </Content>
      </Layout>
      <ScrollToTop />
      <SessionExpiredModal />
    </React.Fragment>
  );
};

const Layout = styled(AntLayout)`
  height: 100vh;
  @media only screen and (max-width: 576px) {
    overflow: hidden scroll !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  @media print {
    /* overflow: visible !important; */
    .ant-layout-header, .ant-layout-footer, #netpay-checkout, .asistencia, .ant-btn, a {
      display: none !important;
    }
    &,&*{
      height: auto !important;
      overflow: visible !important;
    }
  }
  .ant-row {
    max-width: 100vw;
  }
  .ant-spin-nested-loading {
    .ant-spin-text, .anticon {
      color: ${COLORS.accent};
    }
    .ant-spin-dot.ant-spin-dot-spin * {
      background-color: ${COLORS.accent} !important;
    }
  }

  .menu-routes {
    /* margin-right: 10px; */
    @media only screen and (max-width: 767px) {
      justify-content: center;
    }
  }
`;

const colorsMenuItemsByEntity = {
  [ENV_NAMES_ENUM.HERMOSILLO]: {
    color: COLORS.cream2,
    hoverColor: COLORS.accent,
  },
};

const currentEntity = colorsMenuItemsByEntity[ENV_NAME] || {
  color: COLORS.white,
  hoverColor: COLORS.white,
};

const MenuItem = styled(AntButton)`
  border: none;
  height: 70px;
  padding: 5px 8px;
  border-radius: 8px;
  color: ${currentEntity.color};
  background: ${(props) => props.bg};
  &:last-child {
    margin-left: ${(props) => (props.$lastToRight ? 'auto' : null)};
  }
  &:hover {
    background: rgba(255, 255, 255, 0.05)
  }
  &:active, &:hover, &:visited, &:focus {
    border: none;
    color: ${currentEntity.hoverColor};
  }
  &:active, &:visited, &:focus {
    background: #ffffff19;
  }
  div:first-child {
    width: 100%;
  }
  div:last-child {
    font-size: 16px;
    font-weight: 500;
    ${(props) => props.$breakLabel && `
      width: min-content;
      white-space: break-spaces;
      line-height: 20px;
    `};
  }
  .icon .anticon {
    font-size: 24px;
  }
`;

const asistenciaGradients = {
  [ENV_NAMES_ENUM.NAYARIT]: `linear-gradient(40deg, #f3dbc4 0%, ${COLORS.cream2} 100%)`,
  [ENV_NAMES_ENUM.JUAREZ]: `linear-gradient(40deg, ${COLORS.cream} 0%, ${COLORS.cream2} 100%)`,
  [ENV_NAMES_ENUM.HERMOSILLO]: `linear-gradient(40deg, ${COLORS.white} 0%, ${COLORS.white} 100%)`,
  [ENV_NAMES_ENUM.ROATAN]: 'linear-gradient(40deg, #0083b2 0%, #007474 100%)',
};

const bgPosition = ENV_NAME === ENV_NAMES_ENUM.ROATAN ? 'center' : 'center top';
const bgSize = ENV_NAME === ENV_NAMES_ENUM.ROATAN ? 'cover' : 'auto';

const Content = styled(AntLayout.Content)`
  overflow: hidden scroll;
  background: #f0f2f5;
  &, .site-content {
    ${(props) => props.restheightcontent && `height: calc(100vh - ${props.restheightcontent}px)`}
  }
  .site-content-content {
    ${(props) => props.restheightcontentcontent && `min-height: calc(100vh - ${props.restheightcontentcontent}px)`}
  }
  .card-container:not(:last-of-type):not(.ant-col-md-12) {
    margin-bottom: 50px;
  }
  .home {
    margin-bottom: 0;
    width: calc(100vw - 5px);
    height: 81vh;
    background-image: url(${bg});
    background-position: ${bgPosition};
    background-repeat: no-repeat;
    background-size: ${bgSize};
    transform: translateX(-${(props) => props.translatex}px) translateY(-24px);
    background-size: ${ENV_NAME === ENV_NAMES_ENUM.JUAREZ ? 'cover' : 'auto'};
    padding: 30px 0;
    @media only screen and (max-width: 576px) {
      height: auto;
      background-position: center;
    }
  }
  .asistencia {
    background-color: ${COLORS.cream};
    margin-bottom: 0;
    width: 100vw;
    background-image: ${asistenciaGradients[ENV_NAME]};
    a {
      color: ${COLORS.text};
      font-weight: 500;
    }
    .anticon {
      font-size: 18px;
    }
    * {
      margin-bottom: 0 !important;
    }
  }
  @media only screen and (max-width: 576px) {
    overflow: hidden !important;
    position: absolute;
    top: 160px;
    .card-container:not(:last-of-type) {
      margin-bottom: 30px;
    }
    .card-container:last-of-type {
      margin-bottom: 50px;
    }
    .asistencia {
      padding-top: 20px;
    }
    .ant-layout-footer {
      margin-top: 20px
    }
  }
`;

const Footer = styled(AntLayout.Footer)`
  width: 100vw;
  text-align: center;
  padding: 0;
  font-weight: 400;
  background-color: ${COLORS.accent};
  color: ${COLORS.white};
  margin: 0;
  a {
    font-size: 14px;
    color: ${COLORS.white} !important;
    font-weight: 500;
  }
  .anticon {
    font-size: 18px;
  }
`;

const logosDimensions = {
  [ENV_NAMES_ENUM.NAYARIT]: {
    width: 275,
  },
};

const currentDimensions = logosDimensions[ENV_NAME] || { width: 275 };

const Logo = styled.img.attrs(({ src: logo, alt: 'logo' }))`
  width: ${currentDimensions.width}px;
  height: 60px;
  @media only screen and (max-width: 992px) {
    margin-left: auto;
    margin-right: auto;
  }
`;

const SupportImage = styled.img.attrs(({ src: asistencia, alt: 'asistencia' }))`
  float: right;
  height: 250px;
  transform: translateY(-80px);
`;

export default AppLayout;
