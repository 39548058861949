/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Form, Input, Row, Col, Modal, DatePicker, Spin,
} from 'antd';
import {
  DATE_FORMAT,
} from 'utils/formatters';
import { postDeclaracionNomina, patchGenerarDeclaracion, postRechazarDeclaracion } from 'api/recaudacion';
import { SyncOutlined } from '@ant-design/icons';
import { Button, Text } from 'components';
import Select from 'components/PaginatedSelect';
import _logger from 'utils/logger';
import propTypes from 'prop-types';
import { RFC_PATTERN, CURP_PATTERN } from 'utils/patterns';
import ModalConfirm from './ModalConfirm';

const contribuyentesId = [
  26533,
  16766,
  23903,
  609,
  27420,
  10433,
  410,
  26611,
  18658,
  608,
  24805,
  21976,
  2420,
];

const _rules = {
  required: (message) => ({
    required: true,
    message,
  }),
  numeric: [
    {
      validator: async (_, value) => {
        const regex = /^[0-9]+([.][0-9]{1,2})?$/;
        if (!value) return Promise.resolve();
        if (!Number.isInteger(parseFloat(value))) {
          return Promise.reject(new Error('Solo se permiten números enteros positivos.'));
        }
        if (!regex.test(value)) {
          return Promise.reject(new Error('Solo se permiten números positivos.'));
        }
        return Promise.resolve();
      },
    },
  ],
  mayorCero: [
    {
      validator: async (_, value) => {
        if (value <= 0) {
          throw new Error('El valor debe ser mayor a 0.');
        }
      },
    },
  ],
  mayorIgualCero: [
    {
      validator: async (_, value) => {
        if (value < 0) {
          throw new Error('El valor debe ser mayor o igual a 0.');
        }
      },
    },
  ],
  entero: [
    {
      validator: async (_, value) => {
        const regex = /^[0-9]+$/;
        if (!value) return Promise.resolve();
        if (!regex.test(value)) {
          return Promise.reject(new Error('Solo se permiten números enteros positivos.'));
        }
        return Promise.resolve();
      },
    },
  ],
  rfc: [{ pattern: RFC_PATTERN, message: ' Ingrese un RFC válido.' }],
  curp: [{ pattern: CURP_PATTERN, message: ' Ingrese una CURP válida.' }],
};

const FormNomina = ({
  padron,
  statusDeclaracion,
  visible,
  selectedCargos,
  setVisibleForm,
  isComplementaria,
  callback,
  callbackCancel,
  declaracionId,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isZeroState, setIsZeroState] = useState(false);
  const [porcentajePeriodo, setPorcentajePeriodo] = useState(0);

  const handleCancel = async () => {
    setLoading(true);
    await postRechazarDeclaracion({
      declaracion: declaracionId,
      canal_de_pago: 5,
    });
    setLoading(false);
  };

  const handleBeforeUnload = () => {
    handleCancel();
  };

  const handleUnload = (event) => {
    const message = 'Se perderán los datos capturados, ¿Estás seguro de salir?';
    event.preventDefault();
    event.returnValue = message; // Esto mostrará el cuadro de confirmación
    return message;
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (visible) {
      window.addEventListener('unload', handleUnload);
      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('unload', handleUnload);
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [visible]);

  // Resetea el formulario cuando se cierra.
  useEffect(() => {
    if (visible) {
      const _periodo = selectedCargos[0]?.periodo_fiscal?.periodo || '2024';
      let _porcentaje = ((selectedCargos[0]?.importe || 5) / 100);

      if (padron?.id && contribuyentesId.includes(padron?.id) && _periodo === 2024) {
        _porcentaje = 0.02;
      }
      setPorcentajePeriodo(_porcentaje);
      form.resetFields();
    }
  }, [visible]);

  function redondear(numero) {
    const decimal = numero - Math.floor(numero);

    if (decimal >= 0.51) {
      return Math.ceil(numero);
    }
    return Math.floor(numero);
  }

  const patchDeclaracionStatus = async (values) => {
    try {
      let statusId = null;

      const statusObject = {
        generada: statusDeclaracion.find((status) => !status.declaracion_presentada
          && !status.declaracion_en_ceros)?.id,
        en_ceros: statusDeclaracion.find((status) => status.declaracion_en_ceros)?.id,
        sin_importe: statusDeclaracion.find((status) => status.descripcion === 'Declaración con Cantidades a Pagar y a Favor en Cero')?.id || 0,
        saldo_favor: statusDeclaracion.find((status) => status.descripcion === 'Declaración con Cantidad a Favor')?.id || 0,
      };

      if (isZeroState) {
        statusId = statusObject.en_ceros;
      } else if (parseFloat(values.cantidad_a_favor) > 0) {
        statusId = statusObject.saldo_favor;
      } else if (parseFloat(values.cantidad_a_cargo) <= 0) {
        statusId = statusObject.sin_importe;
      } else {
        statusId = statusObject.generada;
      }

      const request = await patchGenerarDeclaracion({
        id: values.declaracion_empresarial,
        estatus_de_declaracion_empresarial: statusId,
        razones_para_presentar_en_ceros: values.razones_para_presentar_en_ceros,
        numero_de_empleados: values.numero_de_empleados,
      });

      if (!request) throw new Error('Error al actualizar la declaración.');
    } catch (error) {
      _logger(error);
    }
  };

  // Envia los datos del formulario.
  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();

      const _padronValues = {
        razon_social_nombre_completo: padron?.empresa?.contribuyente?.razon_social || '',
        rfc: padron?.empresa?.contribuyente?.rfc || '',
        curp: padron?.empresa?.contribuyente?.curp || '',
        nombre: padron?.empresa?.contribuyente?.nombre_completo || '',
        nombre_completo: padron?.empresa?.contribuyente?.nombre_completo || '',
        apellido_paterno: padron?.empresa?.contribuyente?.apellido_paterno || '',
        apellido_materno: padron?.empresa?.contribuyente?.apellido_materno || '',
      };

      let _values = {
        ...values,
        ..._padronValues,
        declaracion_empresarial: declaracionId,
        numero_de_empleados: values.numero_de_empleados === '' ? 0 : values.numero_de_empleados,
        entidad: 1,
        fecha_del_pago_realizado_anterior: isComplementaria
          && !isZeroState ? values.fecha_del_pago_realizado_anterior.format(DATE_FORMAT) : null,
      };

      if (isZeroState) {
        _values = {
          ..._values,
          bases_exentas: 0,
          bases_gravadas: 0,
          actualizacion: 0,
          cantidad_a_favor: 0,
          cantidad_a_cargo: 0,
          impuesto_determinado: 0,
          base_de_impuesto: 0,
          monto_pagado_con_anterioridad: 0,
          multas: 0,
          recargos: 0,
          retencion: 0,
          total_de_contribuciones: 0,
        };
      }

      if (!isComplementaria) {
        _values = {
          ..._values,
          fecha_del_pago_realizado_anterior: null,
          monto_pagado_con_anterioridad: 0,
        };
      }

      await patchDeclaracionStatus(_values);
      await postDeclaracionNomina(_values);

      callback(_values.cantidad_a_cargo <= 0);
      setIsZeroState(false);
      setIsVisibleModal(false);
      setVisibleForm(false);
    } catch (error) {
      _logger(error);
    } finally {
      setLoading(false);
    }
  };

  // Calcula el total de la cantidad a pagar.
  const total = () => {
    const values = form.getFieldsValue();

    const impuesto_determinado = parseFloat(values.impuesto_determinado) || 0;
    const actualizacion = parseFloat(values.actualizacion) || 0;
    const recargos = parseFloat(values.recargos) || 0;
    const multas = parseFloat(values.multas) || 0;
    const monto_pagado_con_anterioridad = parseFloat(values.monto_pagado_con_anterioridad) || 0;

    const total_de_contribuciones = [
      impuesto_determinado,
      actualizacion,
      recargos,
      multas,
    ].reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(0);

    const cantidad_a_cargo = parseFloat(total_de_contribuciones - monto_pagado_con_anterioridad).toFixed(2);

    const cantidad_a_cargo_rounded = redondear(parseFloat(cantidad_a_cargo).toFixed(2));

    form.setFieldsValue({ total_de_contribuciones: parseFloat(total_de_contribuciones).toFixed(2) });

    const isNegative = parseFloat(total_de_contribuciones).toFixed(2) < monto_pagado_con_anterioridad;

    if (isNegative) {
      const total_a_favor_rounded = redondear(parseFloat((monto_pagado_con_anterioridad
        - total_de_contribuciones)).toFixed(2));

      form.setFieldsValue({
        cantidad_a_favor: parseFloat(total_a_favor_rounded).toFixed(2),
        cantidad_a_cargo: parseFloat(0).toFixed(2),
      });
    } else {
      form.setFieldsValue({
        cantidad_a_cargo: parseFloat(cantidad_a_cargo_rounded).toFixed(2),
        cantidad_a_favor: parseFloat(0).toFixed(2),
      });
    }
  };

  return (
    <>
      <Modal
        centered
        title={(
          <Row justify="space-between">
            <Text $accent>
              IMPUESTO SOBRE NÓMINAS
            </Text>
          </Row>
        )}
        visible={visible}
        closable={false}
        footer={(
          <Row justify="space-between" gutter={[10, 15]}>
            <Col xs={24} sm={24} md={12} style={{ textAlign: 'left' }}>
              <Text $accent>* Este campo es obligatorio</Text>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Button
                style={{ width: '100%', borderRadius: 5 }}
                onClick={() => callbackCancel()}
                loading={loading}
                $accent
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Button
                type="primary"
                style={{ width: '100%', borderRadius: 5 }}
                onClick={() => {
                  form.validateFields().then(() => {
                    setIsVisibleModal(true);
                  }).catch(() => {
                    setIsVisibleModal(false);
                  });
                }}
                loading={loading}
                icon={<SyncOutlined />}
                $accent
              >
                Generar
              </Button>
            </Col>
          </Row>
      )}
        width="50vw"
      >
        <Spin spinning={loading} tip="Cargando...">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError
            initialValues={{
              es_declaracion_en_ceros: false,
              bases_exentas: 0,
              bases_gravadas: 0,
              retencion: 0,
              actualizacion: 0,
              recargos: 0,
              multas: 0,
              cantidad_a_cargo: 0,
              cantidad_a_favor: 0,
              total_de_contribuciones: 0,
              impuesto: 0,
              impuesto_determinado: 0,
              monto_pagado_con_anterioridad: isComplementaria ? 0 : 0,
            }}
          >
            <Row gutter={10}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="es_declaracion_en_ceros"
                  label="¿Es Declaración en ceros?"
                  required
                >
                  <Select
                    placeholder="Selecciona una opción"
                    trueFalse
                    allowClear={false}
                    onChange={(value) => setIsZeroState(value)}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  hasFeedback
                  name="numero_de_empleados"
                  rules={[..._rules.numeric]}
                  label="Total de empleados"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              { isZeroState ? (
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    name="razones_para_presentar_en_ceros"
                    label="Razones por las que presenta en ceros"
                    rules={[_rules.required('El campo es requerido')]}
                  >
                    <Input.TextArea />
                  </Form.Item>
                </Col>
              ) : (
                <Col xs={24} sm={24} md={24}>
                  <table style={{ minWidth: '100%' }}>
                    <thead>
                      <tr>
                        <th>Concepto</th>
                        <th>Monto</th>
                      </tr>
                      <tr>
                        <td>Base exenta</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="bases_exentas"
                            tooltip="Fundamento: Artículo 89, ley de hacienda del estado de Nayarit vigente."
                            rules={[..._rules.numeric, ..._rules.mayorIgualCero]}
                          >
                            <Input prefix="$" />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>Base gravada</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="bases_gravadas"
                            tooltip="Fundamento: Artículo 84, ley de hacienda del estado de Nayarit vigente."
                            rules={[
                              _rules.required('El campo es requerido'),
                              ..._rules.numeric,
                              ..._rules.mayorCero,
                            ]}
                            onChange={(value) => {
                              if (value.target.value === '') {
                                form.setFieldsValue({ impuesto_determinado: 1 * porcentajePeriodo });
                              } else {
                                const val_monto = parseFloat(value.target.value).toFixed(0) * porcentajePeriodo;
                                form.setFieldsValue({ impuesto_determinado: parseFloat(val_monto).toFixed(0) });
                              }
                              total();
                            }}
                          >
                            <Input prefix="$" />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>A. Impuesto determinado</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="impuesto_determinado"
                            rules={[_rules.required('El campo es requerido'), ..._rules.numeric]}
                          >
                            <Input prefix="$" disabled />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>B. Actualización</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="actualizacion"
                            rules={[_rules.required('El campo es requerido'), ..._rules.numeric]}
                          >
                            <Input
                              prefix="$"
                                // disabled={disableInput}
                              onChange={() => total()}
                            />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>C. Recargos</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="recargos"
                            rules={[_rules.required('El campo es requerido'), ..._rules.numeric]}
                          >
                            <Input
                              prefix="$"
                                // disabled={disableInput}
                              onChange={() => total()}
                            />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>D. Multas</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            name="multas"
                            rules={[_rules.required('El campo es requerido'), ..._rules.numeric]}
                          >
                            <Input
                              prefix="$"
                                // disabled={disableInput}
                              onChange={() => total()}
                            />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>E. Total de contribuciones</td>
                        <td>
                          <Form.Item
                            hasFeedback
                            tooltip="(A + B + C + D)"
                            name="total_de_contribuciones"
                            rules={[_rules.required('El campo es requerido'), ..._rules.numeric]}
                          >
                            <Input prefix="$" disabled />
                          </Form.Item>
                        </td>
                      </tr>
                      {
                          isComplementaria && (
                            <>
                              <tr>
                                <td>F. Fecha del pago anterior</td>
                                <td>
                                  <Form.Item
                                    name="fecha_del_pago_realizado_anterior"
                                    rules={[_rules.required('El campo es requerido')]}
                                  >
                                    <DatePicker format={DATE_FORMAT} />
                                  </Form.Item>
                                </td>
                              </tr>
                              <tr>
                                <td>G. Monto pagado con anterioridad</td>
                                <td>
                                  <Form.Item
                                    hasFeedback
                                    name="monto_pagado_con_anterioridad"
                                    rules={[
                                      _rules.required('El campo es requerido'),
                                      ..._rules.numeric,
                                      // ..._rules.noMayorAImpustoCorrespondiente,
                                    ]}
                                  >
                                    <Input prefix="$" onChange={() => { total(); }} />
                                  </Form.Item>
                                </td>
                              </tr>
                            </>
                          )
                        }
                      <tr>
                        <td>H. Cantidad a cargo</td>
                        <td>
                          <Form.Item
                            name="cantidad_a_cargo"
                          >
                            <Input prefix="$" disabled />
                          </Form.Item>
                        </td>
                      </tr>
                      <tr>
                        <td>I. Cantidad a favor</td>
                        <td>
                          <Form.Item
                            name="cantidad_a_favor"
                          >
                            <Input prefix="$" disabled />
                          </Form.Item>
                        </td>
                      </tr>
                    </thead>
                  </table>
                </Col>
              )}
            </Row>
          </Form>
        </Spin>
      </Modal>
      <ModalConfirm
        visible={isVisibleModal}
        setVisible={setIsVisibleModal}
        onFinish={() => onFinish()}
        body="Al generar la declaración no podrá ser modificada. ¿Desea continuar?"
        btnText="Si, generar declaración"
        loading={loading}
      />
    </>
  );
};

FormNomina.propTypes = {
  padron: propTypes.shape({
    id: propTypes.number,
    empresa: propTypes.shape({
      contribuyente: propTypes.shape({
        razon_social: propTypes.string,
        rfc: propTypes.string,
        nombre_completo: propTypes.string,
        curp: propTypes.string,
        nombre: propTypes.string,
        apellido_paterno: propTypes.string,
        apellido_materno: propTypes.string,
        ciudadano: propTypes.shape({
          curp: propTypes.string,
          nombre: propTypes.string,
          apellido_paterno: propTypes.string,
          apellido_materno: propTypes.string,
        }),
      }),
    }),
  }).isRequired,
  selectedCargos: propTypes.arrayOf(propTypes.shape({
    periodo_fiscal: propTypes.shape({
      periodo: propTypes.string,
    }),
    importe: propTypes.number,
  })),
  statusDeclaracion: propTypes.number.isRequired,
  visible: propTypes.bool,
  isComplementaria: propTypes.bool,
  setVisibleForm: propTypes.func.isRequired,
  callback: propTypes.func.isRequired,
  declaracionId: propTypes.number.isRequired,
  callbackCancel: propTypes.func.isRequired,
};

FormNomina.defaultProps = {
  isComplementaria: false,
  visible: false,
  selectedCargos: [],
};

export default FormNomina;
