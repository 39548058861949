import { message } from 'antd';
import API from 'api/index';
import _logger from 'utils/logger';
import { tipoDeDocumentoExpedienteEnum } from 'views/Tramites/GPM/enums';

export const baseURI = 'tramites/expedientes-de-tramites/';

const filesToSearch = [
  {
    tipo: tipoDeDocumentoExpedienteEnum.FOTOGRAFIA,
    name: '__foto__',
  },
  {
    tipo: tipoDeDocumentoExpedienteEnum.GIS,
    name: '__base64croquis__',
  },
];

export const expedienteToAttrs = (expediente) => {
  const attrs = {};
  filesToSearch.forEach((f) => {
    const url = expediente.findLast((e) => e.tipo_de_documento_para_expediente === f.tipo)?.archivo;
    if (url) {
      attrs[f.name] = url;
    }
  });
  return attrs;
};

/**
 * GET Expedientes De Tramites
 * @param {object} params
 * @returns {Promise<object[]>}
 */
export const getExpedientesDeTramites = async (params) => {
  try {
    const response = await API.get(baseURI, { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Expediente De Tramite
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getExpedienteDeTramite = async (id) => {
  try {
    const response = await API.get(`${baseURI}${id}`);
    return response.data;
  } catch (err) {
    _logger(err);
    return null;
  }
};

const formatFileValues = (_values) => {
  if (_values.archivo) {
    const values = { ..._values };
    const formData = new FormData();
    const valuesKeys = Object.keys(values);
    valuesKeys.forEach((key) => {
      formData.append(key, values[key] || '');
    });
    formData.append('tamanio', (values.archivo.size / 1024 / 1024).toFixed(2));
    formData.append('extension_del_documento', values.archivo.name.split('.').pop().toLowerCase());

    const _config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return [formData, _config];
  }
  return [_values];
};

/**
 * POST Expediente De Tramite
  * @param {object} _values
 * @returns {Promise<object|boolean>}
 */
export const postExpedienteDeTramite = async (_values) => {
  try {
    const [values, config] = formatFileValues(_values);
    const response = await API.post(baseURI, values, config);
    message.info(response.data.detail || 'Agregado correctamente');
    return response.data;
  } catch (err) {
    _logger(err);
  }
  return false;
};

/**
 * PATCH Expediente De Tramite
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchExpedienteDeTramite = async (id, _values) => {
  try {
    const [values, config] = formatFileValues(_values);
    const response = await API.patch(`${baseURI}${id}`, values, config);
    message.info(response.data.detail || 'Editado correctamente');
    return response.data;
  } catch (err) {
    _logger(err);
  }
  return false;
};

/**
 * DELETE Expediente De Tramite
 * @param {number} id
 * @returns {Promise<object|boolean>}
 */
export const deleteExpedienteDeTramite = async (id) => {
  try {
    const response = await API.delete(`${baseURI}${id}`);
    message.info(response.data.detail || 'Eliminado correctamente');
    return true;
  } catch (err) {
    _logger(err);
    return false;
  }
};
