import React, { useEffect, useState } from 'react';
import { Button, Title } from 'components';
import { Card, Col, Row } from 'antd';
import {
  ArrowLeftOutlined,
  BankOutlined,
  DollarOutlined,
  HomeOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { extra, getRoute, currentEntity } from 'components/Shortcuts';
import { useNavigate } from 'react-router-dom';
import { ENV_NAME, ENV_NAMES_ENUM } from 'utils/env';
import NavigationCard from 'views/Payments/components/NavigationCard';
import { padronesEnum } from 'views/Tramites/GPM/enums';
import PadronesIcons from 'assets/PadronesIcons';
import { selectPadrones } from 'store/catalogos';
import { useSelector } from 'react-redux';

const entities = [ENV_NAMES_ENUM.JUAREZ];

const icons = {
  [padronesEnum.CIUDADANO]: <UserOutlined />,
  [padronesEnum.EMPRESA]: <BankOutlined />,
  [padronesEnum.PREDIO]: <HomeOutlined />,
};

const padronesText = (padron) => {
  const textByPadron = {
    [padronesEnum.VEHICULO]: 'Refrendo Vehicular',
    [padronesEnum.MOTOCICLETA]: 'Refrendo de Motocicletas',
    [padronesEnum.ALCOHOL]: 'Refrendo de Alcoholes',
  };

  return textByPadron[padron.id] || padron.descripcion;
};

const PadronesList = () => {
  const _padrones = useSelector(selectPadrones);
  const navigate = useNavigate();
  const [padrones, setPadrones] = useState([]);

  const invertArr = (arr) => {
    const _arr = [...arr];
    const invert = entities.includes(ENV_NAME);
    return invert ? _arr.reverse() : _arr;
  };

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      if (mounted) {
        setPadrones(invertArr(_padrones.filter((p) => p.available))
          .concat(currentEntity.concatExtra ? extra() : []));
      }
    };
    fetch();
    return () => { mounted = false; };
  }, []);

  return (
    <Card
      style={{ background: 'transparent' }}
      title={(
        <Row justify="center">
          <Col>
            <Title level={2} $accent $centered>
              Consulta de adeudos
            </Title>
          </Col>
          <Col span={24}>
            <Button
              style={{
                float: 'right',
              }}
              icon={<ArrowLeftOutlined />}
              onClick={() => navigate('/pagos/')}
              $accent
            >
              Regresar
            </Button>
          </Col>
        </Row>
      )}
    >
      <Row gutter={[10, 10]} justify="center">
        {padrones.map((item) => (
          <NavigationCard
            to={getRoute(item)}
            icon={icons?.[item.id] || <PadronesIcons padron={item.id} /> || <DollarOutlined />}
            text={
              ENV_NAME === ENV_NAMES_ENUM.NAYARIT
                ? padronesText(item)
                : item?.descripcion || item?.nombre
            }
            type="button"
            key={item.id}
          />
        ))}
      </Row>
    </Card>
  );
};

export default PadronesList;
