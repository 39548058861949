import React, { useEffect, useMemo, useState } from 'react';
import {
  setDefaultOptions,
  loadCss,
  loadScript,
} from 'esri-loader';
import {
  BrowserRouter,
  useRoutes,
  Navigate,
  Outlet,
} from 'react-router-dom';
import { getAuthorizedRoutes } from 'routes';
import Layout from 'components/Layout';
import NotFound from 'components/NotFound';
import 'antd/dist/antd.min.css';
import 'app.scss';
import {
  Layout as AntLayout, Result, Row, Spin,
} from 'antd';
import { selectEntidad, selectIsAuthenticated, selectUser } from 'store/auth';
import { getContentypesPadrones, getEntidadConfig, getLadas } from 'api/catalogos';
import { useDispatch, useSelector } from 'react-redux';
import { getInfoUser } from 'api/auth';
import { DEV, NETPAY_PUBLIC_KEY, PROD } from 'utils/env';
import { getPadrones } from 'api/padrones';
import { getClasificadoresDeTiposDeCargo } from 'api/recaudacion';
import isWebView from 'utils/isWebView';
import styled from 'styled-components';
import { COLORS } from 'components';
import { getNetPayCheckoutButton } from 'views/Payments/components/NetPayButton';
import { normalizeValue } from 'utils/formatters';
import { selectIsNetpayInitialized, setIsNetpayInitialized } from 'store/app';
import _logger from 'utils/logger';
import useNetwork from 'hooks/useNetwork';

try {
  const version = '4.25';
  setDefaultOptions({ css: true });
  loadCss(version);
  loadScript({ version });
} catch (error) {
  _logger(error);
}

const WebViewLayout = () => (
  <StyledLayout>
    <StyledLayout.Content style={{ height: '100vh', overflowY: 'scroll' }}>
      <Outlet />
    </StyledLayout.Content>
  </StyledLayout>
);

const StyledLayout = styled(AntLayout)`
  @media print {
    &, &* {
      height: auto !important;
      overflow: visible !important;
    }
  }
`;

const Routes = ({ isAuthenticated, user }) => {
  const routes = useMemo(() => [
    {
      path: '/',
      element: isWebView() ? <WebViewLayout /> : <Layout />,
      children: getAuthorizedRoutes(isAuthenticated),
    },
    {
      path: 'not-found',
      element: <NotFound />,
    },
    {
      path: '*',
      element: DEV ? <NotFound /> : <Navigate to="/not-found" />,
    },
  ], [isAuthenticated, user]);
  const privateRoutesElement = useRoutes(routes);
  return privateRoutesElement;
};

const App = () => {
  const network = useNetwork();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const netpayInitialized = useSelector(selectIsNetpayInitialized);
  const user = useSelector(selectUser);
  const [fetchStatus, setFetchStatus] = useState('loading');
  const entidad = useSelector(selectEntidad);

  useEffect(() => {
    const getRequirements = async () => {
      const promises = [
        getEntidadConfig(dispatch),
        getPadrones(dispatch),
        getContentypesPadrones(dispatch),
        getLadas(dispatch),
        getClasificadoresDeTiposDeCargo(dispatch),
      ];
      if (isAuthenticated) {
        promises.push(getInfoUser(dispatch, user));
      }
      const _fetched = (await Promise.all(promises)).every((r) => r);
      if (DEV || _fetched) {
        setFetchStatus('success');
        return;
      }
      setFetchStatus('failed');
    };
    getRequirements();
  }, []);

  useEffect(() => {
    const setupNetpay = () => {
      if (fetchStatus === 'success') {
        if (netpayInitialized) return;
        if (window.NetPay && NETPAY_PUBLIC_KEY) {
          const button = getNetPayCheckoutButton();
          const municipio = entidad.direccion?.codigo_postal?.municipio;
          const street1 = normalizeValue(entidad.direccion?.calle_principal, false);
          const postalCode = normalizeValue(entidad.direccion?.codigo_postal?.d_codigo, false);
          const city = normalizeValue(municipio?.nom_mun, false);
          const state = normalizeValue(municipio?.estado?.nombre_de_AGEE, false);
          button.dataset.street1 = street1;
          button.dataset.country = 'Mexico';
          button.dataset.city = city;
          button.dataset.postalCode = postalCode;
          button.dataset.state = state;
          window.NetPay.init(NETPAY_PUBLIC_KEY);
          window.NetPay.setSandboxMode(!PROD);
          dispatch(setIsNetpayInitialized());
        } else if (!NETPAY_PUBLIC_KEY) {
          // eslint-disable-next-line no-console
          console.log('NETPAY_PUBLIC_KEY not defined');
        } else {
          // eslint-disable-next-line no-console
          console.log('NetPay is not defined');
        }
      }
    };
    setupNetpay();
  }, [fetchStatus]);

  if (['loading', 'failed'].includes(fetchStatus)) {
    return (
      <Row style={{ width: '100vw', height: '100vh' }} align="middle" justify="center">
        {fetchStatus === 'loading' && <Spin spinning style={{ color: COLORS.accent }} />}
        {fetchStatus === 'failed' && (
          <Result
            status="500"
            title={network.online ? '' : 'Verifique su conexión'}
          />
        )}
      </Row>
    );
  }

  return (
    <BrowserRouter>
      <Routes isAuthenticated={isAuthenticated} user={user} />
    </BrowserRouter>
  );
};

export default App;
