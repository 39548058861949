import { message } from 'antd';
import API from 'api';
import { DATE_FORMAT } from 'api/recaudacion';
import _logger from 'utils/logger';

export const baseURI = 'empresas/documentos-para-tramites/';
const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

/**
 * GET Documentos De Tramites
 * @param {object} params
 * @returns {Promise<object[]>}
 */
export const getDocumentosDeTramites = async (params) => {
  try {
    const response = await API.get(baseURI, { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Documento De Tramite
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getDocumentoDeTramite = async (id) => {
  try {
    const response = await API.get(`${baseURI}${id}`);
    return response.data;
  } catch (err) {
    _logger(err);
    return null;
  }
};

const formatFileValues = (_values) => {
  const values = { ..._values };
  values.archivo = values.archivo.file;
  values.fecha_vencimiento = values.fecha_vencimiento?.format(DATE_FORMAT) || '';
  const formData = new FormData();
  const valuesKeys = Object.keys(values);
  valuesKeys.forEach((key) => {
    formData.append(`${key}`, values[key] || '');
  });
  formData.append('tamanio', (values.archivo.size / 1024 / 1024).toFixed(2));
  formData.append('extension_del_documento', values.archivo.name.split('.').pop().toLowerCase());
  return formData;
};

/**
 * POST Documento De Tramite
  * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postDocumentoDeTramite = async (_values) => {
  try {
    const values = formatFileValues(_values);
    const response = await API.post(baseURI, values, config);
    message.info(response.detail || 'Agregado correctamente');
    return response.data;
  } catch (err) {
    _logger(err);
  }
  return false;
};

/**
 * DELETE Documento De Tramite
 * @param {number} id
 * @returns {Promise<object|boolean>}
 */
export const deleteDocumentoDeTramite = async (id) => {
  try {
    const response = await API.delete(`${baseURI}${id}`);
    message.info(response.detail || 'Eliminado correctamente');
    return true;
  } catch (err) {
    _logger(err);
    return false;
  }
};
