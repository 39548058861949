import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import { Title } from 'components';
import { fundamentoLegaleFormat, listToString } from 'utils/formatters';
import Skeletons from './SkeletosWrappers';

const { SkeletonWrapperText } = Skeletons;

const InformacionGeneral = ({ loading, plantilla }) => (
  <>
    <Row justify="center">
      <Title $accent level={3}>
        {plantilla?.nombre}
      </Title>
    </Row>
    <br />
    <ul>
      <li>
        <SkeletonWrapperText
          accent
          bold
          wrap
          loading={loading}
          dataLabel="Ámbito del trámite"
        >
          {plantilla?.ambito_de_ordenamiento}
        </SkeletonWrapperText>
      </li>
      <li>
        <SkeletonWrapperText
          accent
          bold
          wrap
          loading={loading}
          dataLabel="Plazo de respuesta"
        >
          {plantilla?.plazo_real ? plantilla.plazo_real.replace(/ o /g, ' a ') : ''}
        </SkeletonWrapperText>
      </li>
      <li>
        <SkeletonWrapperText
          accent
          bold
          wrap
          loading={loading}
          dataLabel="Vigencia"
        >
          {plantilla?.vigenciaLabel ?? ''}
        </SkeletonWrapperText>
      </li>
      <li>
        <SkeletonWrapperText
          accent
          bold
          wrap
          loading={loading}
          dataLabel="Tipo de ficta"
        >
          {plantilla?.fictaLabel ?? ''}
        </SkeletonWrapperText>
      </li>
      <li>
        <SkeletonWrapperText
          accent
          bold
          wrap
          loading={loading}
          dataLabel="Tipo de personas que pueden realizar el trámite"
        >
          {plantilla?.tipos_de_persona_interesadas}
        </SkeletonWrapperText>
      </li>
      <li>
        <SkeletonWrapperText
          accent
          bold
          wrap
          loading={loading}
          dataLabel="¿En qué casos se puede presentar el trámite?"
        >
          {plantilla?.casos_de_presentacion}
        </SkeletonWrapperText>
      </li>
      {!!plantilla?.documento_de_salida_de_tramite?.length && (
        <li>
          <SkeletonWrapperText
            accent
            bold
            loading={loading}
            dataLabel="Resultado del trámite"
            wrap
          >
            {listToString(plantilla
              ?.documento_de_salida_de_tramite?.map((t) => t?.nombre || '') ?? [''])}
          </SkeletonWrapperText>
        </li>
      )}
      <li>
        <SkeletonWrapperText
          accent
          bold
          wrap
          loading={loading}
          dataLabel="Información adicional"
        >
          {plantilla?.informacion_adicional}
        </SkeletonWrapperText>
      </li>
      <li>
        <SkeletonWrapperText
          accent
          bold
          wrap
          loading={loading}
          dataLabel="Fundamento Legal"
        >
          <br />
          <ul>
            {plantilla?.fundamento_legal.map((f) => (
              <li key={f.id}>
                {fundamentoLegaleFormat(f)}
              </li>
            ))}
          </ul>
        </SkeletonWrapperText>
      </li>
    </ul>
  </>
);

InformacionGeneral.propTypes = {
  loading: PropTypes.bool,
  plantilla: PropTypes.shape({
    vigenciaLabel: PropTypes.string,
    fictaLabel: PropTypes.string,
    nombre: PropTypes.string,
    ambito_de_ordenamiento: PropTypes.string,
    plazo_real: PropTypes.string,
    tipos_de_persona_interesadas: PropTypes.string,
    casos_de_presentacion: PropTypes.string,
    documento_de_salida_de_tramite: PropTypes.arrayOf(PropTypes.shape({
      descripcion: PropTypes.string,
    })),
    informacion_adicional: PropTypes.string,
    fundamento_legal: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      denominacion: PropTypes.string,
    })),
  }),
};

InformacionGeneral.defaultProps = {
  loading: false,
  plantilla: {},
};

export default InformacionGeneral;
