import http from 'api';
import _logger from 'utils/logger';

/**
 * GET Catalogo Clasificaciones de Comercio
 * @param {object} params
 * @returns {Promise<Object[]>}
 */
export const getClasificacionesDeComercioCatalogo = async (params) => {
  try {
    const response = await http.get('/empresas/clasificaciones-de-comercio/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Tipos de Empresas
 * @param {object} params
 * @returns {Promise<Object[]>}
 */
export const getTiposDeEmpresas = async (params) => {
  try {
    const response = await http.get('/empresas/tipos-de-empresas/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Tipos de Establecimiento
 * @param {object} params
 * @returns {Promise<Object[]>}
 */
export const getTiposDeEstablecimiento = async (params) => {
  try {
    const response = await http.get('/empresas/tipos-de-establecimientos/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Unidades de Recaudación
 * @returns {Promise<Object[]>}
 */
export const getUnidadesDeRecaudacion = async (params) => {
  try {
    const response = await http.get('configuracion/unidades-de-recaudacion/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Clases de Actividades Empresariales
 * @param {Object} params
 * @returns {Promise<Object[]>}
 */
export const getClasesDeActividadesEmpresariales = async (params = null) => {
  try {
    const response = await http.get('empresas/clases-de-actividades-empresariales/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Catalogo de Actividad (SAT)
 * @param {object} params
 * @returns {Promise<Object[]>}
 */
export const getActividadEconomica = async (id) => {
  try {
    const response = await http.get(`empresas/actividades-economicas/${id}/`);
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Catalogo de Actividades (SAT)
 * @param {object} params
 * @returns {Promise<Object[]>}
 */
export const getActividadesEconomicas = async (params) => {
  try {
    const response = await http.get('empresas/actividades-economicas/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Catalogo de Actividades
 * @param {object} params
 * @returns {Promise<Object[]>}
 */
export const getCatalogoActividades = async (params) => {
  try {
    const response = await http.get('empresas/actividades-list', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Obligaciones fiscales
 * @param {object} params
 * @returns {Promise<Object[]>}
 */
export const getObligacionesFiscales = async (params) => {
  try {
    const response = await http.get('empresas/obligaciones-fiscales', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Estatus de Obligaciones Fiscales
 * @param {object} params
 * @returns {Promise<Array<Object>>}
 */
export const getEstatusDeObigacionesFiscales = async (params) => {
  try {
    const response = await http.get('/empresas/estatus-de-obligaciones-fiscales/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};
