/* eslint-disable import/prefer-default-export */
import { listToString, toCurrency } from 'utils/formatters';
import _logger from 'utils/logger';
import { toMoment } from 'utils/normalizers';
import { formatReceived } from 'utils/formatValues';
import API from 'api/index';
import { postObtenerDocumento } from './reporteador';

const base = '/tramites/';

export const monedasMap = {
  PESO: 1,
  LEM: 2,
  SM: 3,
  UMA: 4,
};

const getCoords = (_coordList = []) => _coordList.map((e) => ({
  longitud: e?.direccion?.longitud || 0,
  latitud: e?.direccion?.latitud || 0,
  direccion: e?.direccion || '',
}));

const getDescripcion = (e, withKey = false, keyProp = 'clave') => {
  if ('descripcion' in e) {
    const descripcion = e?.descripcion || '';
    if (withKey) {
      return `${descripcion} (${e[keyProp]})`;
    }
    return descripcion;
  }
  if ('nombre' in e) {
    const nombre = e?.nombre || '';
    return nombre;
  }
  return '';
};

const humanize = (data = [], reverse = true) => {
  if ((Array.isArray(data) && data?.length > 0 && data.every((e) => typeof e === 'string'))) {
    return reverse ? listToString(data.reverse()) : listToString(data);
  }
  return '';
};

const insertKey = (data = []) => data.map((e) => ({ ...e, key: e.id }));

const formattValues = (val) => {
  const values = val;
  const keys = Object.keys(values);
  keys.forEach((key) => {
    if (typeof values[key] === 'boolean') {
      values[key] = values[key] ? 'Si' : 'No';
    }
    if (key === 'clasificacion') {
      values[key] = values[key] ? 'Trámite' : 'Servicio';
    }
    if (typeof values[key] === 'object'
      && !Array.isArray(values[key])
      && values[key] !== null
      && key !== 'tipo_de_cargo') {
      values[key] = getDescripcion(values[key]);
    }
    if (['plazo_prevencion', 'formato_requerido'].includes(key) && values[key] === null) {
      values[key] = [];
    }
    if (key === 'tipos_de_persona_interesadas') {
      values[key] = Array.isArray(values[key]) && values[key]?.length
        ? listToString(values[key].map((p) => p.descripcion)) : '';
    }
    if (key === 'tipos_de_persona') {
      values[key] = Array.isArray(values[key]) && values[key]?.length
        ? listToString(values[key].map((p) => p.descripcion)) : '';
    }
    if (key === 'volumen_de_consultas' && !values[key]) {
      values[key] = 0;
    }
  });
  return values;
};

const getFullNameUser = (user) => {
  if (user === '') return user;
  if (!user) return '';
  const { first_name, last_name, second_last_name } = user;
  return `${first_name || ''} ${last_name || ''} ${second_last_name || ''}`;
};

const normalizeHour = ({ hour }) => {
  if (!hour) return '';
  const momentHour = toMoment(hour, 'HH:mm:ss');
  return momentHour.format('h:mm A');
};

const formatData = (values = []) => values.map((e) => {
  const diasLaborales = e?.oficina_de_atencion?.length
    ? e.oficina_de_atencion?.[0].horarios?.[0]?.dias_laborales
      .map((d) => d.descripcion) : [];
  const daysHumanized = humanize(diasLaborales, false);
  const hrario = e.oficina_de_atencion?.length ? e?.oficina_de_atencion?.[0]?.horarios?.[0] : null;
  const horario = hrario ? `De ${normalizeHour({ hour: hrario?.entrada })} a ${normalizeHour({ hour: hrario?.salida })}, los días ${daysHumanized}` : '';
  const dependencia = getDescripcion(e?.departamentos?.[0]?.unidad_operativa);
  const unidadesAdministrativas = humanize(e.departamentos.map((d) => getDescripcion(d)));

  const fundamento_legal = insertKey(e?.fundamento_legal);
  const usuario_responsable_de_queja = {
    ...e.usuario_responsable_de_queja,
    nombre_completo: e?.usuario_responsable_de_queja
      ? `${e.usuario_responsable_de_queja?.first_name
      || ''} ${e.usuario_responsable_de_queja?.last_name
      || ''} ${e.usuario_responsable_de_queja?.second_last_name
      || ''}` : '',
  };

  const plazoDeRespuesta = e?.plazo_maximo?.length
    ? e.plazo_maximo.map((p) => `${p.plazo_maximo} ${p.unidad_de_plazo_maximo.descripcion}`)
    : [];

  const plazo_real = listToString(plazoDeRespuesta, 'disjunction');
  const _coordsList = getCoords(e.oficina_de_atencion);
  const oficina_de_atencion = e?.oficina_de_atencion.map((c) => ({
    ...c,
    horario,
    jefe: c?.jefe_de_recaudadora ? getFullNameUser(c.jefe_de_recaudadora) : '',
    direccion: {
      ...c.direccion,
      title: getDescripcion(c),
      detalle: c.direccion?.calle_principal || ' ',
    },
  }));
  const importePreFormat = e.es_cuota_fija ? e.cuota_fija : e.cuota_variable;
  const importe = toCurrency(importePreFormat, e?.moneda?.id !== monedasMap.PESO);
  const canales_de_pago = humanize(e
    ?.catalogo_de_canales_de_pago?.map((c) => getDescripcion(c)));
  const responsableResol = e.usuario_responsable_de_la_resolucion;
  const encargadoDeResolucionName = getFullNameUser(responsableResol);
  const puestoEncargadaDeResolucion = responsableResol?.funcionario?.puesto_principal?.descripcion
    || responsableResol?.funcion_del_usuario_externo?.descripcion;
  const celularResponsableDeResolucion = responsableResol?.numero_de_celular || '';
  const emailResponsableDeResolucion = responsableResol?.email || '';
  const rolesDeResponsableDeResolucion = humanize(responsableResol?.roles);
  const encargadoDeQueja = getFullNameUser(e.usuario_responsable_de_queja);
  const tipo_de_resolucion_plantilla = Array.isArray(e.tipo_de_resolucion_plantilla)
    ? e.tipo_de_resolucion_plantilla.map((i) => ({
      ...i,
      key: i.id,
      tipo_de_resolucion: getDescripcion(i.tipo_de_resolucion),
      catalogo_de_mes: e?.meses_de_recurrencia,
    })) : [];
  const moneda = e?.moneda ? getDescripcion(e.moneda, true) : '';
  const celularResponsableDeQueja = e?.usuario_responsable_de_queja?.numero_de_celular;

  let vigenciaLabel = '';
  let fictaLabel = '';
  if (e?.tipo_de_resolucion_plantilla?.length) {
    const resolucion = e.tipo_de_resolucion_plantilla[0];
    fictaLabel = resolucion?.tipo_de_ficta?.descripcion ?? 'No aplica';
    if (resolucion.tiene_vigencia) {
      vigenciaLabel = `${resolucion?.vigencia_de_resolucion} ${resolucion?.unidad_de_plazo?.descripcion || ''}`;
    } else {
      vigenciaLabel = 'No aplica';
    }
  }

  let montoLabel = '';
  if (e.es_tramite_gratuito) {
    montoLabel = e.descripcion_de_gratuito;
  } else if (e.es_cuota_fija) {
    montoLabel = `${toCurrency(e?.cuota_fija, e?.moneda?.id !== monedasMap.PESO)
      ?? ''} ${e?.moneda?.clave || ''}`.trim();
  } else {
    montoLabel = `${toCurrency(e?.cuota_variable, e?.moneda?.id !== monedasMap.PESO)
      ?? ''} ${e.moneda?.clave || ''}`.trim();
  }
  if (!/\d/.test(montoLabel) || montoLabel.includes('0.00')) {
    montoLabel = '';
  }

  const fechaDeActualizacion = e.fecha_de_ultimo_movimiento
    ? toMoment(e.fecha_de_ultimo_movimiento).format('D [de] MMMM [del] YYYY') : '';

  return {
    ...e,
    ...formattValues(e),
    catalogo_de_canales_de_pago_objects: e.catalogo_de_canales_de_pago,
    casos_de_los_requisitos: e.casos_de_los_requisitos || [],
    montoLabel,
    fechaDeActualizacion,
    fictaLabel,
    vigenciaLabel,
    moneda,
    emailResponsableDeResolucion,
    tipo_de_resolucion_plantilla,
    encargadoDeQueja,
    rolesDeResponsableDeResolucion,
    celularResponsableDeResolucion,
    puestoEncargadaDeResolucion,
    encargadoDeResolucionName,
    canales_de_pago,
    importe,
    dependencia,
    unidadesAdministrativas,
    plazo_real,
    fundamento_legal,
    usuario_responsable_de_queja,
    _coordsList,
    oficina_de_atencion,
    celularResponsableDeQueja,
  };
});

/**
 * GET Plantillas de Tramites
 * @param {object} params
 * @returns <Promise<object[]>>
 */
export const getPlantillasDeTramites = async (_params = {}) => {
  try {
    const params = {
      ..._params,
      estado_de_ficha: 3,
      desc: true,
    };
    const res = await API.get(`${base}plantillas-de-tramites-indexada/`, { params });
    const results = formatData(res.data.results);
    return { ...res.data, results };
  } catch (error) {
    _logger(error);
  }
  return { results: [], count: 0 };
};

/**
 * GET Plantillas de Tramites
 * @param {object} params
 * @returns <Promise<object[]>>
 */
export const getPlantillasNoIndexadas = async (_params = {}) => {
  try {
    const params = {
      ..._params,
      estado_de_ficha: 3,
      desc: true,
    };
    const res = await API.get(`${base}plantillas-de-tramites-portal/`, { params });
    return res.data;
  } catch (error) {
    _logger(error);
  }
  return { results: [], count: 0 };
};

/**
 *
 * @param {string|number} id
 * @param {number} consultasPrevias
 */
export const patchVistas = async (id, consultasPrevias = 0) => {
  try {
    const response = await API.patch(`${base}plantillas-de-tramites-indexada/${id}/`, {
      volumen_de_consultas: consultasPrevias + 1,
    });
    return response.data;
  } catch (err) {
    _logger(err);
    return null;
  }
};

/**
 * GET Tipos de Tramites
 * @returns <Promise<object[]>>
 */
export const getTiposDeTramites = async () => {
  try {
    const response = await API.get(`${base}tipos-de-tramites-public`);
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Dias no laborales
 * @returns <Promise<object[]>>
 */
export const getDiasNoLaborales = async (params) => {
  try {
    const response = await API.get(`${base}dias-no-laborales-public`, { params });
    return formatReceived(response.data);
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getTipoDeDocumentoParaContribuyente = async (params) => {
  try {
    const response = await API.get('empresas/tipo-de-documento-para-contribuyente', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getPlantillaPDF = async ({
  id,
  setBase64,
  setError,
  setVisiblePreviewer,
}) => {
  const values = {
    ID: 101,
    Parametros: [
      {
        Nombre: 'plantilla_id',
        Valor: `${id}`,
        TipoParametro: 'Texto',
      },
    ],
  };
  const [_error, b64] = await postObtenerDocumento(values);
  if (b64) {
    setBase64(b64);
  } else {
    setError(_error);
  }
  setVisiblePreviewer(true);
};
