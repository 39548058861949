import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Row, Col } from 'antd';
import Login from 'views/Auth/Login';
import { postSolicitarCodigoDeAcceso } from 'api/auth';
import { handleLogout, selectTokenIsExpired, selectUser } from 'store/auth';
import { Text, Button, COLORS } from 'components';
import { useNavigate } from 'react-router';

const SessionExpiredModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tokenIsExpired = useSelector(selectTokenIsExpired);
  const user = useSelector(selectUser);
  const [codeSent, setCodeSent] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!tokenIsExpired) {
      setCodeSent(false);
    }
  }, [tokenIsExpired]);

  const sendCode = async () => {
    setLoading(true);
    const sent = await postSolicitarCodigoDeAcceso(user);
    if (sent) {
      setCodeSent(true);
    }
    setLoading(false);
  };

  const logout = () => {
    dispatch(handleLogout());
    setTimeout(() => navigate('/'));
  };

  return (
    <Modal
      visible={tokenIsExpired}
      title="Sesión expirada"
      closable={false}
      maskClosable={false}
      keyboard={false}
      footer={null}
      destroyOnClose
    >
      {codeSent ? (
        <Login modal />
      ) : (
        <>
          <Text>
            La sesión ha expirado.
            <br />
            Puede solicitar un código nuevo para continuar si lo desea.
          </Text>
          <br />
          <Row justify="end" gutter={10} style={{ marginTop: 30 }}>
            <Col>
              <Button disabled={loading} onClick={logout} danger type="primary" color={COLORS.white}>
                <Text>
                  Cerrar sesión
                </Text>
              </Button>
            </Col>
            <Col>
              <Button disabled={loading} onClick={sendCode} $accent>
                <Text>
                  Solicitar código
                </Text>
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
};

export default SessionExpiredModal;
