/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { ENV_NAME, ENV_NAMES_ENUM } from 'utils/env';

export const initialState = {
  token: null,
  user: null,
  entidad: null,
  contribuyentes: [],
  ciudadano: null,
  tokenIsExpired: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    handleLogin: (state, { payload }) => {
      if (payload) {
        state.token = payload.token;
        state.contribuyentes = payload.contribuyentes;
        state.ciudadano = payload.ciudadano;
        state.user = payload.user;
        state.tokenIsExpired = false;
      }
    },
    handleLogout: (state) => {
      const entidad = { ...state.entidad };
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key] ?? null;
      });
      state.entidad = entidad;
    },
    updateUserInfo: (state, { payload }) => {
      state.contribuyentes = payload.contribuyentes;
      state.ciudadano = payload.ciudadano;
    },
    updateEntidadInfo: (state, { payload }) => {
      state.entidad = { ...state.entidad, ...payload };
      if (!state.entidad.direccion) {
        if (ENV_NAME === ENV_NAMES_ENUM.NAYARIT) {
          state.entidad.direccion = {
            calle_principal: 'Av Mexico Y Abasolo',
            codigo_postal: {
              d_codigo: '63000',
              municipio: {
                nom_mun: 'Tepic',
                estado: {
                  nombre_de_AGEE: 'Nayarit',
                },
              },
            },
          };
        }
        if (ENV_NAME === ENV_NAMES_ENUM.NAYARIT) {
          state.entidad.direccion = {
            calle_principal: 'Francisco Villa',
            codigo_postal: {
              d_codigo: '32000',
              municipio: {
                nom_mun: 'Juarez',
                estado: {
                  nombre_de_AGEE: 'Chihuahua',
                },
              },
            },
          };
        }
      }
    },
    setTokenIsExpired: (state) => {
      state.tokenIsExpired = true;
    },
  },
});

export const {
  handleLogin,
  handleLogout,
  updateUserInfo,
  updateEntidadInfo,
  setTokenIsExpired,
} = authSlice.actions;

// Data Selectors
export const selectIsAuthenticated = ({ auth }) => !!auth.token;
export const selectToken = ({ auth }) => auth.token;
export const selectTokenIsExpired = ({ auth }) => auth.tokenIsExpired;
export const selectUser = ({ auth }) => auth.user;
export const selectContribuyentes = ({ auth }) => auth.contribuyentes;
export const selectCiudadano = ({ auth }) => auth.ciudadano;
export const selectEntidad = ({ auth }) => auth.entidad;
export const selectConfiguracionEntidad = (store) => selectEntidad(store)?.configuracion;
export const selectLadaDefault = (store) => selectConfiguracionEntidad(store)?.lada;
export const selectMoneda = (store) => selectConfiguracionEntidad(store)?.moneda;
export const selectClaveDePredioPrincipal = (store) => selectEntidad(store)
  ?.clave_de_predio_principal;
export const selectRedondeo = (store) => selectConfiguracionEntidad(store)
  ?.utilizar_redondeo || false;
export const selectPublicoGeneral = (store) => selectConfiguracionEntidad(store)
  ?.ciudadano_publico_en_general;
export const selectTipoDeRedondeo = (store) => selectConfiguracionEntidad(store)
  ?.tipo_de_redondeo || 1;

export default authSlice.reducer;
