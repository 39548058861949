import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  DatePicker,
  Form,
  Input,
} from 'antd';
import { tiposDatosEnum, tiposDatosFormulaEnum } from 'views/Tramites/GPM//enums';
import {
  decimalNormalizer,
  DECIMAL_PATTERN,
  INTEGER_PATTERN,
  toInteger,
  toMoment,
  listToString,
} from 'utils/normalizers';
import Select from 'components/Select';
import {
  DATETIME_FRONTEND_FORMAT,
  DATE_FRONTEND_FORMAT,
} from 'utils/formatValues';
import _logger from 'utils/logger';
import styled from 'styled-components';
import { Button, Title } from 'components';

const integerRules = (required) => [
  { required },
  {
    validator: async (_, val) => {
      if (val && !INTEGER_PATTERN.test(val)) {
        throw Error('Ingrese un entero');
      }
    },
  },
];

const decimalRules = (required) => [
  { required },
  {
    validator: async (_, val) => {
      if (val && !DECIMAL_PATTERN.test(val)) {
        throw Error('Ingrese un decimal válido');
      }
    },
  },
];

/**
 * @param {object} values
 * @param {object[]} variables
 * @param {number} variables.id
 * @param {number} variables.nombre_de_variable
 * @param {number} variables.tipo_de_variable
 * @param {string|number} prename
 * @returns object[]
 */
export const valuesToVariables = (values, variables, prename) => variables.map((variable) => {
  let valor = prename
    ? values[prename][variable.nombre_de_variable] : values[variable.nombre_de_variable];
  if (variable.tipo_de_variable === tiposDatosEnum.RANGO_DE_FECHAS) {
    const [fechaInicial, fechaFinal] = valor;
    valor = fechaFinal.diff(fechaInicial, 'days');
  }
  return ({
    id: variable.id,
    nombre_de_variable: variable.nombre_de_variable,
    valor,
  });
});

/**
 * @param {object} values
 * @param {number[]} variables
 * @param {object[]} catalogoDeVariables
 * @param {number} catalogoDeVariables.id
 * @param {string} catalogoDeVariables.nombre_de_variable
 * @param {number} catalogoDeVariables.tipo_de_variable
 * @returns object[]
 */
export const valuesToVariablesComplementos = (
  values,
  variables,
  catalogoDeVariables,
  tramite,
  valores,
) => variables.map((v) => catalogoDeVariables.find((e) => e.id === v)).map((variable) => {
  const tipoDeDato = variable.tipo_de_variable;
  const formValue = values[variable.nombre_de_variable];
  if (!formValue) return null;
  const output = {
    variable: variable.id,
    valores_por_variables_de_seleccion: [],
    tramite,
  };
  if (tipoDeDato === tiposDatosEnum.RANGO_DE_FECHAS) {
    output.valor = formValue.map((e) => e.format()).join();
  } else if (tipoDeDato === tiposDatosEnum.FECHA) {
    output.valor = formValue.format();
  } else if (tipoDeDato === tiposDatosEnum.FECHA_HORA) {
    output.valor = formValue.format(DATETIME_FRONTEND_FORMAT);
  } else if (tipoDeDato === tiposDatosEnum.BOOLEAN) {
    output.valor = formValue ? 'Si' : 'No';
  } else if (tipoDeDato === tiposDatosEnum.SELECCION_MULTIPLE) {
    output.valores_por_variables_de_seleccion = formValue;
    output.valor = listToString(formValue.map((_vs) => valores.find((vs) => vs.id === _vs)?.valor));
  } else if (tipoDeDato === tiposDatosEnum.SELECCION_UNICA) {
    output.valores_por_variables_de_seleccion = [formValue];
    output.valor = valores.find((vs) => vs.id === formValue)?.valor;
  } else {
    output.valor = formValue.toString();
  }
  return output;
}).filter((v) => v);

const variableToFormValue = ({ valor, valores_por_variables_de_seleccion }, tipoDeDato) => {
  try {
    if (tipoDeDato === tiposDatosEnum.RANGO_DE_FECHAS) {
      return valor.split(',').map((val) => toMoment(val, [DATE_FRONTEND_FORMAT]));
    }
    if (tipoDeDato === tiposDatosEnum.FECHA) {
      return toMoment(valor, [DATE_FRONTEND_FORMAT]);
    }
    if (tipoDeDato === tiposDatosEnum.FECHA_HORA) {
      return toMoment(valor, [DATETIME_FRONTEND_FORMAT]);
    }
    if (tipoDeDato === tiposDatosEnum.BOOLEAN) {
      return /si|1/i.test(valor);
    }
    if (tipoDeDato === tiposDatosEnum.SELECCION_UNICA) {
      return valores_por_variables_de_seleccion[0];
    }
    if (tipoDeDato === tiposDatosEnum.SELECCION_MULTIPLE) {
      return valores_por_variables_de_seleccion;
    }
    return valor;
  } catch (error) {
    _logger(error);
  }
  return null;
};

/**
 * @param {object[]} complementos
 * @param {number} complementos.valor
 * @param {number} complementos.variable
 * @param {object[]} catalogoDeVariables
 * @param {number} catalogoDeVariables.id
 * @param {number} catalogoDeVariables.nombre_de_variable
 * @returns object
 */
export const complementosToValues = (
  complementos,
  catalogoDeVariables,
) => {
  const entries = complementos.map((complemento) => {
    const variable = catalogoDeVariables.find((v) => v.id === complemento.variable);
    return [
      variable?.nombre_de_variable,
      variableToFormValue(complemento, variable.tipo_de_variable),
    ];
  }).filter(([name]) => name);
  return Object.fromEntries(entries);
};

export const getVariableRules = (tipoDeDato, required) => {
  if ([tiposDatosEnum.ENTERO, tiposDatosFormulaEnum.ENTERO]
    .includes(tipoDeDato)) {
    return integerRules(required);
  }
  if ([tiposDatosEnum.DECIMAL, tiposDatosFormulaEnum.DECIMAL]
    .includes(tipoDeDato)) {
    return decimalRules(required);
  }
  return [{ required }];
};

const getControl = (variable, disabled, valoresDeVariablesDeSelecion) => {
  if ([tiposDatosEnum.RANGO_DE_FECHAS,
    tiposDatosFormulaEnum.RANGO_DE_FECHAS].includes(variable.tipo_de_variable)) {
    return (
      <DatePicker.RangePicker disabled={disabled} />
    );
  }
  if (variable.tipo_de_variable === tiposDatosEnum.FECHA) {
    return (
      <DatePicker disabled={disabled} format={DATE_FRONTEND_FORMAT} />
    );
  }
  if (variable.tipo_de_variable === tiposDatosEnum.FECHA_HORA) {
    return (
      <DatePicker showTime disabled={disabled} format={DATETIME_FRONTEND_FORMAT} />
    );
  }
  if (variable.tipo_de_variable === tiposDatosEnum.BOOLEAN) {
    return (
      <Select
        options={[{ id: true, descripcion: 'Si' }, { id: false, descripcion: 'No' }]}
        disabled={disabled}
      />
    );
  }
  if ([tiposDatosEnum.SELECCION_UNICA,
    tiposDatosEnum.SELECCION_MULTIPLE].includes(variable.tipo_de_variable)) {
    return (
      <Select
        options={valoresDeVariablesDeSelecion.filter((val) => val.variable === variable.id)}
        mode={variable.tipo_de_variable === tiposDatosEnum.SELECCION_MULTIPLE
          ? 'multiple' : null}
        getLabel={(e) => e.valor}
        disabled={disabled}
      />
    );
  }

  if (variable.tipo_de_variable === tiposDatosEnum.TEXTO_LARGO) {
    return (
      <Input disabled={disabled} />
    );
  }
  if ([tiposDatosEnum.ENTERO,
    tiposDatosEnum.DECIMAL,
    tiposDatosEnum.TEXTO_CORTO,
    tiposDatosFormulaEnum.ENTERO,
    tiposDatosFormulaEnum.DECIMAL].includes(variable.tipo_de_variable)) {
    return (
      <Input disabled={disabled} />
    );
  }
  return <Input placeholder="Tipo de dato no soportado" disabled />;
};

const Variables = ({
  complementos,
  variables: _variables,
  catalogoDeVariables,
  disabled,
  prename,
  valoresDeVariablesDeSelecion,
  onClick,
  categoriasDeVariables,
  required: _required,
}) => {
  const form = Form.useFormInstance();

  useEffect(() => {
    if (complementos) {
      form.setFieldsValue(complementosToValues(complementos, catalogoDeVariables));
    }
  }, [complementos]);

  const variables = _variables
    .map((v) => catalogoDeVariables.find((item) => item.id === v))
    .filter((v) => v);

  const renderVariable = (variable) => (
    <Col
      xs={24}
      sm={24}
      md={variable.tipo_de_variable === tiposDatosEnum.TEXTO_LARGO ? 16 : 8}
      key={variable.id}
      style={{ marginTop: 'auto' }}
    >
      <FormItem
        label={variable.descripcion_de_variable?.replace(/:$/g, '')}
        name={prename ? [prename, variable.nombre_de_variable] : variable.nombre_de_variable}
        rules={getVariableRules(variable.tipo_de_variable, prename || _required
          ? true : variable.es_requerido)}
        normalize={(val) => {
          if ([tiposDatosEnum.ENTERO, tiposDatosFormulaEnum.ENTERO]
            .includes(variable.tipo_de_variable)) {
            return toInteger(val);
          }
          if ([tiposDatosEnum.DECIMAL, tiposDatosFormulaEnum.DECIMAL]
            .includes(variable.tipo_de_variable)) {
            return decimalNormalizer(val);
          }
          return val;
        }}
      >
        {getControl(variable, disabled, valoresDeVariablesDeSelecion)}
      </FormItem>
    </Col>
  );

  const categories = categoriasDeVariables.map((c) => ({
    id: c.id,
    title: c.descripcion,
    variables: variables.filter((v) => v.categoria_de_variable === c.id),
  })).filter((c) => c.variables.length);

  return (
    <>
      {categories.map((c) => (
        <React.Fragment key={c.id}>
          <Title level={3} style={{ width: '100%' }}>
            {c.title}
          </Title>
          {c.variables.map(renderVariable)}
          <Col span={24} />
        </React.Fragment>
      ))}
      {variables.filter((v) => !v.categoria_de_variable).map(renderVariable)}
      <br />
      <Col span={24} />
      {!!onClick && disabled && (
        <>
          <br />
          <Col xs={24} sm={24} md={{ span: 8, push: 16 }}>
            <Button onClick={onClick} block>
              Imprimir
            </Button>
          </Col>
          <br />
        </>
      )}
    </>
  );
};

Variables.propTypes = {
  catalogoDeVariables: PropTypes.arrayOf(PropTypes.shape({
    tipo_de_variable: PropTypes.number.isRequired,
    nombre_de_variable: PropTypes.string.isRequired,
    descripcion_de_variable: PropTypes.string.isRequired,
  })).isRequired,
  variables: PropTypes.arrayOf(PropTypes.number),
  categoriasDeVariables: PropTypes.arrayOf(PropTypes.shape),
  complementos: PropTypes.arrayOf(PropTypes.shape({})),
  valoresDeVariablesDeSelecion: PropTypes.arrayOf(PropTypes.shape({})),
  prename: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  required: PropTypes.bool,
};

Variables.defaultProps = {
  prename: null,
  disabled: false,
  variables: [],
  complementos: [],
  valoresDeVariablesDeSelecion: [],
  onClick: null,
  categoriasDeVariables: [],
  required: false,
};

const FormItem = styled(Form.Item)`
  label {
    height: auto;
  }
`;

export default Variables;
