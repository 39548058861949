import _logger from 'utils/logger';
import API from 'api';
import { toMoment } from 'utils/normalizers';
import {
  setContentTypesPadrones,
  setLadas,
  setMeses,
} from 'store/catalogos';
import { updateEntidadInfo } from 'store/auth';
import { ID_ENTIDAD } from 'utils/env';
import { formatReceived } from 'utils/formatters';
import { getCiudadano } from './padrones';

/**
 * GET Nombres de ordenamiento
 * @param {object} params
 * @returns Promise<object[]>
 */
export const getNombresDeOrdenamiento = async (params = null) => {
  try {
    const response = await API.get('/catalogos/nombre-de-ordenamiento-public/', { params });
    response.data.results = response.data.results.map((e) => ({
      ...e,
      key: e.id,
      tipo: e.tipo_de_ordenamiento?.descripcion?.toUpperCase() || '',
      ambito: e.ambito_de_ordenamiento?.descripcion?.toUpperCase() || '',
      fecha: e.fecha_de_publicacion ? toMoment(e.fecha_de_publicacion)?.format() : '',
    }));
    return response.data;
  } catch (error) {
    _logger(error);
    return { results: [], count: 0 };
  }
};

/**
 * GET Usos de CFDI
 * @returns Promise<object[]>
 */
export const getUsosDeCFDI = async () => {
  try {
    const response = await API.get('catalogos/uso-del-cfdi-public');
    return response.data.map((e) => ({
      label: `${e.uso_del_cfdi} - ${e.descripcion}`,
      value: e.id,
    }));
  } catch (error) {
    _logger(error);
    return [];
  }
};

/**
 * GET Ladas
 * @returns Promise<object[]>
 */
export const getLadas = async (dispatch) => {
  try {
    const response = await API.get('usuarios/lada');
    if (dispatch) {
      dispatch(setLadas(response.data));
    }
    return response.data;
  } catch (error) {
    _logger(error);
    return [];
  }
};

/**
 * GET Tipos de Personas
 * @returns Promise<object[]>
 */
export const getTiposDePersonas = async () => {
  try {
    const response = await API.get('catalogos/tipos-de-personas');
    return response.data;
  } catch (error) {
    _logger(error);
    return [];
  }
};

/**
 * GET Content Types Padrones
 * @returns Promise<object[]>
 */
export const getContentypesPadrones = async (dispatch) => {
  try {
    const response = await API.get('catalogos/content-types-padrones-public');
    if (dispatch) {
      dispatch(setContentTypesPadrones(response.data));
    }
    return response.data;
  } catch (error) {
    _logger(error);
    return [];
  }
};

/**
 * GET Regimenes Capitales
 * @returns Promise<object[]>
 */
export const getRegimenesCapitales = async () => {
  try {
    const response = await API.get('catalogos/regimenes-capitales-public');
    return response.data;
  } catch (error) {
    _logger(error);
    return [];
  }
};

/**
  * GET Configuracion de la Entidad
  * @param {Function} dispatch
  * @returns Promise<object|null>
*/
export const getEntidadConfig = async (dispatch) => {
  try {
    const response = await API.get(`/catalogos/entidad-public/${ID_ENTIDAD}/`);
    if (response.data.configuracion?.ciudadano_publico_en_general) {
      const { ciudadano_publico_en_general: id } = response.data.configuracion;
      const ciudadano = (await getCiudadano(false, { id })).results[0];
      response.data.configuracion.ciudadano_publico_en_general = ciudadano;
    }
    if (typeof dispatch === 'function') {
      dispatch(updateEntidadInfo(response.data));
    }
    return response.data;
  } catch (error) {
    _logger(error);
    return null;
  }
};

/**
 * GET Razones de Rechazo
 * @param {Object} params
 * @returns {Promise<object[]>}
 */
export const getRazonesDeRechazo = async (params = null) => {
  try {
    const response = await API.get('/catalogos/razones-de-rechazo/', { params });
    return response.data;
  } catch (error) {
    _logger(error);
    return [];
  }
};

/**
 * GET Razones de Rechazo
 * @param {Object} params
 * @returns {Promise<object[]>}
 */
export const getRazonesDeRechazoCita = async (params = null) => {
  try {
    const response = await API.get('/recaudacion/razones-de-rechazos-para-citas-public/', { params });
    return response.data;
  } catch (error) {
    _logger(error);
    return [];
  }
};

/**
 * GET Codigo Postal
 * @param {Object} params
 * @returns {Promise<object[]>}
 */
export const getCodigoPostal = async (cp) => {
  try {
    const response = await API.get(`/catalogos/codigos-postales-public/${cp}`);
    return response.data;
  } catch (error) {
    _logger(error);
    return [];
  }
};

export const getVersionesVehiculares = async (params = null) => {
  try {
    const response = await API.get('/catalogos/versiones-vehiculares/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getColoresVehiculos = async (params = null) => {
  try {
    const response = await API.get('/catalogos/catalogos-de-colores-vehiculares/', {
      params: {
        ...params,
        entidad: ID_ENTIDAD,
      },
    });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getCatalogoDePaises = async (params = null) => {
  try {
    const response = await API.get('/catalogos/catalogos-de-paises/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getUsosDeMotocicletas = async (params = null) => {
  try {
    const response = await API.get('/catalogos/usos-de-motocicletas/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getTiposDeCombustibles = async (params = null) => {
  try {
    const response = await API.get('/catalogos/tipos-de-combustibles-vehiculares/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getDocumentosDeRegularizacion = async (params = null) => {
  try {
    const response = await API.get('/catalogos/clave-de-documento-de-regularizacion/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getTiposDeEstablecimientos = async () => {
  try {
    const response = await API.get('/empresas/tipos-de-establecimientos');
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getEstadosDelPadron = async () => {
  try {
    const response = await API.get('/catalogos/estados-del-padron/');
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getObligacionesFiscales = async () => {
  try {
    const response = await API.get('/empresas/obligaciones-fiscales/');
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getObligacionesFiscalesDeContribuyentes = async (params = null) => {
  try {
    const response = await API.get('/empresas/obligaciones-fiscales-de-contribuyentes/', { params });
    return formatReceived(response.data);
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getEstatusDeObligacionesFiscales = async () => {
  try {
    const response = await API.get('empresas/estatus-de-obligaciones-fiscales/');
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const postConstanciasContribuyente = async (values) => {
  try {
    const response = await API.post('empresas/constancias-contribuyentes/', values);
    return response.data;
  } catch (err) {
    _logger(err);
    return null;
  }
};

export const getMunicipios = async (params = null) => {
  try {
    const response = await API.get('/catalogos/municipios/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return null;
  }
};

export const getCodigosPostales = async (params = null, all = true) => {
  try {
    const response = await API.get('/catalogos/codigos-postales/', { params });
    if (all) {
      if (!response.data.next) return response.data.results;
      const page = new URL(response.data.next).searchParams.get('page');
      const colonias = await getCodigosPostales({ ...params, page });
      return response.data.results.concat(colonias);
    }
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getConstancias = async (params = null) => {
  try {
    const response = await API.get('/empresas/constancias-contribuyentes/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getPeriodos = async () => {
  try {
    const response = await API.get('/recaudacion/periodos-public/');
    return response.data.results;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getTiposDeDeclaracionesEmpresariales = async () => {
  try {
    const response = await API.get('/empresas/tipos-de-declaraciones-empresariales/');
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getTiposDeActividadEmpresarial = async () => {
  try {
    const response = await API.get('/empresas/clases-de-actividades-empresariales-public/');
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getTiposDeVialidades = async () => {
  try {
    const response = await API.get('/usuarios/tipo-vialidad/');
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Catálogos del Mes
 * @param {object} params
 * @returns {Promise<object[]>}
 */
export const getCatalogoDelMes = async (dispatch) => {
  try {
    const response = await API.get('catalogos/catalogos-del-mes/');
    if (dispatch) {
      dispatch(setMeses(response.data));
    }
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getConstanciasContribuyente = async (params) => {
  try {
    const response = await API.get('empresas/constancias-contribuyentes/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};
