import _logger from 'utils/logger';
import API from 'api/index';
import { handleLogin, updateUserInfo } from 'store/auth';
import {
  AUTH_CONTRIBUYENTE, CATALOGOS_DE_CANAL_DE_PAGO, ENV_NAME, ID_ENTIDAD,
} from 'utils/env';
import { getContribuyente, getCiudadano } from 'api/padrones';

const baseURI = '/cuentaunicasir/auth/';

/**
 * POST obtener token de acceso
 * @param {object} values
 * @param {number} values.lada
 * @param {number} values.numero_de_celular
 * @returns promise<object|boolean>
 */
export const postSolicitarCodigoDeAcceso = async (values = null) => {
  try {
    const response = await API.post(`${baseURI}solicitar-codigo-de-acceso/`, values);
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return false;
};

const getAllContribuyentes = async (params) => {
  if (AUTH_CONTRIBUYENTE) return [];
  const response = await getContribuyente(false, params);
  const pages = Math.ceil(response.count / 10);
  const contribuyentes = response.results;
  if (pages > 1) {
    const promises = (new Array(pages - 1).fill(0))
      .map((_, idx) => getContribuyente(false, { ...params, page: idx + 2 }));
    const responses = await Promise.all(promises);
    contribuyentes.push(...responses.map((r) => r.results).flat());
  }
  return contribuyentes;
};

const getCiudadanoDeContribuyente = async (params) => {
  if (!AUTH_CONTRIBUYENTE) return null;
  const ciudadano = await getCiudadano(false, params);
  return ciudadano.results[0];
};

export const getInfoUser = async (dispatch, _user, token) => {
  const contribuyentes = await getAllContribuyentes({ ciudadano: _user.id });
  const ciudadano = _user.ciudadano && await getCiudadanoDeContribuyente({ id: _user.ciudadano });
  if (_user) {
    const handler = token ? handleLogin : updateUserInfo;
    dispatch(handler({
      token,
      ciudadano,
      contribuyentes,
      user: _user,
    }));
  }
  return !!_user;
};

/**
 * POST Login
 * @param {object} values
 * @param {string} values.codigo
 * @returns <Promise<boolean>>
 */
export const postLogin = async (dispatch, values = null) => {
  try {
    const response = await API.post(`${baseURI}obtener-acceso/`, {
      ...values,
      entidad: ID_ENTIDAD,
      catalogo_canal_de_pago: ENV_NAME !== 'juarez'
        ? CATALOGOS_DE_CANAL_DE_PAGO.PORTAL_FINANCIERO : CATALOGOS_DE_CANAL_DE_PAGO.PAGO_EN_LINEA,
    });
    const info = await getInfoUser(
      dispatch,
      response.data.ciudadano || response.data.contribuyente,
      response.data.access_token,
    );
    return info;
  } catch (error) {
    _logger(error);
  }
  return false;
};
