export const {
  NODE_ENV,
  REACT_APP_DEV: _DEV,
  REACT_APP_SCHEME: SCHEME,
  REACT_APP_APIHOST: APIHOST,
  REACT_APP_PATH: PATH,
  REACT_APP_ENV: ENV,
  REACT_APP_ENV_NAME: ENV_NAME,
  REACT_APP_MAPBOX_TOKEN: MAPBOX_TOKEN = 'pk.eyJ1IjoiYWxmcmVkb2FyYWdvbiIsImEiOiJja25ob2Jjb3cwbGFkMndsd2dxa3ptbW1nIn0.RMjRPIOqD194HvOMxu8iqQ',
  REACT_APP_NETPAY_PUBLIC_KEY: NETPAY_PUBLIC_KEY = 'pk_netpay_uppwsWcVEwjcMTKhExsKENZif',
  REACT_APP_NETPAY_PRIVATE_KEY: NETPAY_PRIVATE_KEY = 'sk_netpay_fPENSUJIzhduvwQMZqBkUzdtotRnKHDrjxjHXluBmcJFR',
  REACT_APP_REPORTEADOR_SCHEME: REPORTEADOR_SCHEME,
  REACT_APP_REPORTEADOR_APIHOST: REPORTEADOR_APIHOST,
  REACT_APP_REPORTEADOR_PATH: REPORTEADOR_PATH,
  REACT_APP_META_TITLE: APP_TITLE,
  REAC_APP_ID_ENTIDAD: ID_ENTIDAD = 1,
  REACT_APP_ACCOUNT_AVAILABLE: _ACCOUNT_AVAILABLE = false,
  REACT_APP_APPOINTMENTS_AVAILABLE: _APPOINTMENTS_AVAILABLE = false,
} = process.env;

export const ENV_NAMES_ENUM = {
  NAYARIT: 'nayarit',
  JUAREZ: 'juarez',
  HERMOSILLO: 'hermosillo',
  ROATAN: 'roatan',
};

export const CLAVE_CATASTRAL_SEGMENTOS = {
  [ENV_NAMES_ENUM.JUAREZ]: true,
  [ENV_NAMES_ENUM.NAYARIT]: false,
  [ENV_NAMES_ENUM.HERMOSILLO]: true,
};

export const USER_PORTAL_ID = {
  juarez: 1,
  nayarit: 328,
  hermosillo: 3,
};

export const CATALOGOS_DE_CANAL_DE_PAGO = {
  PAGO_EN_LINEA: 4,
  PORTAL_FINANCIERO: 5,
};

export const RECEIPT_LABEL_ENUM = {
  [ENV_NAMES_ENUM.NAYARIT]: 'recibo',
  [ENV_NAMES_ENUM.JUAREZ]: 'ticket',
  [ENV_NAMES_ENUM.HERMOSILLO]: 'recibo',
};

export const PROD = ENV === 'PRODUCTION';
export const DEV = _DEV || NODE_ENV === 'development';

export const RECEIPT_LABEL = RECEIPT_LABEL_ENUM[ENV_NAME] || 'comprobante';
export const ACCOUNT_AVAILABLE = _ACCOUNT_AVAILABLE || DEV;
export const APPOINTMENTS_AVAILABLE = _APPOINTMENTS_AVAILABLE || DEV;
export const SHOW_REDONDEO = ENV_NAME === ENV_NAMES_ENUM.JUAREZ;
export const REPUVE = !!process.env.REACT_APP_REPUVE;
export const AUTH_CONTRIBUYENTE = !!process.env.REACT_APP_AUTH_CONTRIBUYENTE;

export const IPROVINAY = !!process.env.REACT_APP_IPROVINAY;

export const NETPAY_BASE_URL = PROD
  ? 'https://suite.netpay.com.mx/gateway-ecommerce/v3/'
  : 'https://gateway-154.netpaydev.com/gateway-ecommerce/v3/';
