import { message } from 'antd';
import API from 'api/index';
import _logger from 'utils/logger';

export const baseURI = 'tramites/complementos-de-tramites/';

/**
 * GET Complementos de Tramites
 * param {object} params
 * @returns {Promise<object>}
 */
export const getComplementosDeTramites = async (params) => {
  try {
    const response = await API.get(baseURI, { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Complemento de Tramite
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getComplementoDeTramite = async (id) => {
  try {
    const response = await API.get(`${baseURI}${id}`);
    return response.data;
  } catch (err) {
    _logger(err);
    return null;
  }
};

/**
 * POST Complemento de Tramite
  * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postComplementoDeTramite = async (values) => {
  try {
    const response = await API.post(baseURI, values);
    message.info(response.data.detail || 'Agregado correctamente');
    return response.data;
  } catch (err) {
    _logger(err);
    return false;
  }
};

/**
 * PATCH Complemento de Tramite
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchComplementoDeTramite = async (id, values) => {
  try {
    const response = await API.patch(`${baseURI}${id}`, values);
    message.info(response.data.detail || 'Editado correctamente');
    return response.data;
  } catch (err) {
    _logger(err);
    return false;
  }
};

/**
 * DELETE Complemento de Tramite
 * @param {number} id
 * @returns {Promise<object|boolean>}
 */
export const deleteComplementoDeTramite = async (id) => {
  try {
    const response = await API.delete(`${baseURI}${id}`);
    message.info(response.data.detail || 'Eliminado correctamente');
    return true;
  } catch (err) {
    _logger(err);
    return false;
  }
};
