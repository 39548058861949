import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Table,
  Spin,
  Row,
  Col,
  Grid,
  Form,
  Input,
  DatePicker,
} from 'antd';
import {
  DownOutlined,
  RightOutlined,
  SearchOutlined,
  ArrowLeftOutlined,
  DollarCircleOutlined,
} from '@ant-design/icons';
import { COLORS, Button } from 'components';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/auth';
import { toMoment } from 'utils/normalizers';
import {
  getEstatusDeObligacionesFiscales,
  getObligacionesFiscales,
  getObligacionesFiscalesDeContribuyentes,
} from 'api/catalogos';
import { getUnidadesDeRecaudacion } from 'api/configuracion';
import { getTipoDePadronPorContenType, gettersListMap } from 'api/padrones';
import { selectPadrones } from 'store/catalogos';
import {
  ciudadanoToString,
  empresaToString,
  infraccionesToString,
  licenciaFuncionamientoToString,
  padronesDerivadosEmpresaDefaultToString,
  predioToString,
  remolqueToString,
  vehiculoToString,
} from 'utils/padronesToString';
import { PROD } from 'utils/env';
import { padronesEnum } from 'views/Tramites/GPM/enums';
import { getRoute } from 'components/Shortcuts';
import { useNavigate } from 'react-router-dom';

const { useBreakpoint } = Grid;

const extractData = (data) => {
  if (!data) return [];
  if (Array.isArray(data)) return data;
  if ('results' in data) return data.results;
  return [];
};

const contribuyenteToString = (contribuyente) => {
  if (!contribuyente) return '';
  if (contribuyente.tipo_de_persona === 1) {
    return `${contribuyente?.clave} ${contribuyente?.nombre_completo} - ${contribuyente?.rfc}`;
  }
  return `${contribuyente?.clave} - ${contribuyente?.razon_social} - ${contribuyente?.rfc}`;
};

const customPadronToString = ({
  padrones,
  _padron,
  tipoDePadron,
  options,
}) => {
  const { withTipoDePadron = false, withAddress = null } = options;
  if (!_padron) return '';
  if (typeof _padron !== 'object') return PROD ? '' : 'Padron is not an object ';
  const padron = { ..._padron };
  const padronToStringMap = {
    [padronesEnum.CIUDADANO]: ciudadanoToString,
    [padronesEnum.EMPRESA]: empresaToString,
    [padronesEnum.PREDIO]: (predio) => predioToString(predio, withAddress),
    [padronesEnum.VEHICULO]: vehiculoToString,
    [padronesEnum.CONTRIBUYENTE]: contribuyenteToString,
    [padronesEnum.MOTOCICLETA]: remolqueToString,
    [padronesEnum.REMOLQUE]: remolqueToString,
    [padronesEnum.INFRACCIONES]: infraccionesToString,
    [padronesEnum.MULTAS]: infraccionesToString,
    [padronesEnum.EXPEDIENTE_LICENCIA_FUNCIONAMIENTO]: licenciaFuncionamientoToString,
  };

  const tipoDePadronStr = withTipoDePadron ? padrones.find((p) => p.id === tipoDePadron)?.descripcion.toUpperCase().concat(':') : '';
  const padronString = padronToStringMap[tipoDePadron]?.(padron) || padronesDerivadosEmpresaDefaultToString(padron)?.trim() || '';
  return `${tipoDePadronStr} ${padronString || 'Sin información'}`;
};

const MisObligaciones = () => {
  const navigate = useNavigate();
  const padrones = useSelector(selectPadrones);
  const user = useSelector(selectUser);
  const [form] = Form.useForm();
  const [formInfo] = Form.useForm();
  const screens = useBreakpoint();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [visible, setVisible] = useState(false);
  const [objectsPadrones, setObjectsPadrones] = useState([]);

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const [
        _obligacionesFiscales,
        _estadoDeObligacionesFiscales,
        _unidadesDeRecaudacion,
        _data,
      ] = await Promise.all([
        getObligacionesFiscales(),
        getEstatusDeObligacionesFiscales(),
        getUnidadesDeRecaudacion(),
        getObligacionesFiscalesDeContribuyentes({ contribuyente: user.id }),
      ]);
      if (mounted) {
        const estado = document.getElementById('padron_status')
          .innerText.replace('PADRÓN ', '');
        const unidad_recaudadora = _unidadesDeRecaudacion
          .find((u) => u.id === user.unidad_de_recaudacion)?.descripcion;

        form.setFieldsValue({
          fecha_de_alta: user?.fecha_de_alta_de_padron_estatal
            ? toMoment(user.fecha_de_alta_de_padron_estatal) : null,
          fecha_de_ultimo_movmimiento: user?.fecha_de_ultimo_movimiento
            ? toMoment(user.fecha_de_ultimo_movimiento) : null,
          fecha_de_inicio: user?.fecha_inicio_operaciones_contribuyente
            ? toMoment(user.fecha_inicio_operaciones_contribuyente) : null,
          unidad_recaudadora,
          estado,
        });
        const mappedData = _data.map((d) => {
          const obligacion_fiscal = d.obligacion_fiscal
            ? _obligacionesFiscales.find((o) => o.id === d.obligacion_fiscal)?.descripcion : null;
          const tipoDePadron = getTipoDePadronPorContenType(d.content_type_padron);
          const content_type_padron = padrones.find((p) => p.id === tipoDePadron);
          const fecha_inicio = toMoment(d.fecha_inicio).format('DD/MM/YYYY');
          const fecha_ultimo_estado = toMoment(d.fecha_ultimo_estado).format('DD/MM/YYYY');
          const estatus_de_obligaciones_fiscal = _estadoDeObligacionesFiscales
            .find((e) => e.id === d.estatus_de_obligaciones_fiscal)?.descripcion;
          const promise = gettersListMap[tipoDePadron]({ id: d.object_id_padron });

          return {
            ...d,
            obligacion_fiscal,
            _content_type_padron: content_type_padron,
            content_type_padron: content_type_padron?.descripcion || 'Sin información',
            fecha_inicio,
            fecha_ultimo_estado,
            estatus_de_obligaciones_fiscal,
            tipoDePadron,
            promise,
          };
        });
        const _padrones = await Promise.all(mappedData.map((d) => d.promise));
        setObjectsPadrones(_padrones.map(extractData)
          .flat().map((p) => {
            const label = customPadronToString({
              padrones,
              _padron: p,
              tipoDePadron: p.tipo_de_padron,
              options: { withAddress: p.tipo_de_padron === padronesEnum.PREDIO },
            });

            return {
              ...p,
              key: `${p.id}-${p.tipo_de_padron}`,
              label,
              clave: p.clave,
            };
          }));
        setData(mappedData);
        setLoading(false);
      }
    };
    fetchData();
    return () => { mounted = false; };
  }, []);

  const goToPay = (key) => {
    const match = data.find((d) => d.id === key);
    const _padron = {
      id: match.tipoDePadron,
      descripcion: padrones.find((p) => p.id === match.tipoDePadron)?.descripcion,
    };
    const route = getRoute(_padron);
    const url = new URL(`${window.location.origin}/pagos/consulta-de-adeudos/${route}`);
    url.searchParams.append('padron', match.object_id_padron);
    url.searchParams.append('tipo_de_padron', match.tipoDePadron);
    navigate(url.pathname + url.search);
  };

  const columns = [
    {
      title: 'Tipo de obligación',
      dataIndex: 'obligacion_fiscal',
      key: 'obligacion_fiscal',
      width: 750,
      responsive: ['xs', 'sm'],
    },
    {
      title: 'Padrón',
      dataIndex: 'tipoDePadron',
      key: 'tipoDePadron',
      width: 800,
      responsive: ['xs', 'sm'],
      render: (val, record) => {
        const key = `${record.object_id_padron}-${val}`;
        return objectsPadrones.find((p) => p.key === key)?.label;
      },
    },
    {
      title: 'Fecha de inicio',
      dataIndex: 'fecha_inicio',
      key: 'fecha_inicio',
      width: 320,
      responsive: ['md'],
    },
    {
      title: 'Fecha de fin',
      dataIndex: 'fecha_ultimo_estado',
      key: 'fecha_ultimo_estado',
      width: 320,
      responsive: ['md'],
    },
    {
      title: 'Estado',
      dataIndex: 'estatus_de_obligaciones_fiscal',
      key: 'estatus_de_obligaciones_fiscal',
      width: 320,
      responsive: ['md'],
    },
    {
      title: 'Actions',
      key: '_',
      dataIndex: '_',
      width: 350,
      responsive: ['xs', 'sm'],
      render: (_, record) => (
        <Button
          $accent
          onClick={() => goToPay(record.id)}
          icon={<DollarCircleOutlined />}
        >
          Consultar adeudos
        </Button>
      ),
    },
  ];

  const rowExpandable = () => (!screens.md);

  const expandedRowRender = (record) => columns.map((column) => (
    <Row
      key={column.key}
      justify="space-between"
    >
      <b>
        {`${column.title}:`}
      </b>
      <span>
        {column.render
          ? column.render(record[column.dataIndex], record)
          : record[column.dataIndex]}
      </span>
    </Row>
  ));

  const expandIcon = ({ expanded, onExpand, record }) => (!screens.md) && (record && (
    expanded ? (
      <DownOutlined onClick={(e) => {
        e.stopPropagation();
        onExpand(record, e);
      }}
      />
    ) : (
      <RightOutlined onClick={(e) => {
        e.stopPropagation();
        onExpand(record, e);
      }}
      />
    )
  ));

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  const onClickRow = async () => {
    setLoading(true);
    const [key] = selectedRowKeys;
    const match = data.find((d) => d.id === key);
    const _key = `${match.object_id_padron}-${match.tipoDePadron}`;
    const _contribuyente = objectsPadrones.find((p) => p.key === _key);
    formInfo.setFieldsValue({
      clave_cuenta_de_padron_obligado: _contribuyente.clave,
    });
    setLoading(false);
    setVisible(true);
  };

  return (
    <Spin spinning={loading}>
      <Container>
        {!visible && (
        <Form
          layout="vertical"
          form={form}
        >
          <Row gutter={10}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item label="Fecha de alta al padrón estatal" name="fecha_de_alta">
                <DatePicker disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item label="Fecha de último movimiento" name="fecha_de_ultimo_movmimiento">
                <DatePicker disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item label="Fecha de inicio de operaciones" name="fecha_de_inicio">
                <DatePicker disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item label="Unidad de recaudación" name="unidad_recaudadora">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item label="Estado del padrón" name="estado">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        )}
        <Row justify="end" style={{ marginBlock: 10 }} gutter={10}>
          <Col>
            <Button
              icon={visible ? <ArrowLeftOutlined /> : <SearchOutlined />}
              onClick={() => setVisible(!visible)}
              disabled={!visible && !selectedRowKeys.length}
            >
              {!visible ? 'Vista previa' : 'Volver'}
            </Button>
          </Col>
          <Col>
            <Button
              $accent
              onClick={() => goToPay(selectedRowKeys[0])}
              disabled={!visible && !selectedRowKeys.length}
              icon={<DollarCircleOutlined />}
            >
              Consultar adeudos
            </Button>
          </Col>
        </Row>
        {!visible ? (
          <Row>
            <Col span={24}>
              <Table
                size="small"
                columns={columns}
                dataSource={data}
                rowSelection={rowSelection}
                onRow={(record) => ({
                  onClick: () => {
                    setSelectedRowKeys([record.key]);
                    formInfo.setFieldsValue({
                      ...record,
                    });
                  },
                  onDoubleClick: () => onClickRow(),
                })}
                expandable={{
                  expandedRowRender,
                  rowExpandable,
                  expandIcon,
                }}
              />
            </Col>
          </Row>
        ) : (
          <Form form={formInfo} layout="vertical">
            <Row gutter={10}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item label="Clave de padrón obligado" name="clave_cuenta_de_padron_obligado">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item label="Obligación Fiscal" name="obligacion_fiscal">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item label="Padrón" name="content_type_padron">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item label="Fecha de inicio" name="fecha_inicio">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item label="Fecha de último estado" name="fecha_ultimo_estado">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item label="Estado" name="estatus_de_obligaciones_fiscal">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Container>
    </Spin>
  );
};

const Container = styled.div`
.ant-table-thead > tr > th {
  background-color: ${COLORS.accent};
  color: white;
  font-weight: bold;
}
.ant-form-item {
  margin-bottom: 5px;
}

`;

export default MisObligaciones;
