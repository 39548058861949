import React from 'react';
import {
  Outlet,
  useLocation,
} from 'react-router-dom';
import { Card, Row, Grid } from 'antd';
import { extra, getRoute } from 'components/Shortcuts';
import { ENV_NAME, ENV_NAMES_ENUM } from 'utils/env';
import NavigationCard from './components/NavigationCard';

const propsPerEntity = {
  [ENV_NAMES_ENUM.NAYARIT]: {
    cardsStyle: 1,
  },
  [ENV_NAMES_ENUM.HERMOSILLO]: {
    cardsStyle: 1,
  },
  [ENV_NAMES_ENUM.JUAREZ]: {
    cardsStyle: 2,
  },
  [ENV_NAMES_ENUM.ROATAN]: {
    cardsStyle: 1,
  },
};

const current = propsPerEntity[ENV_NAME] || {};
const { useBreakpoint } = Grid;

const Layout = () => {
  const screen = useBreakpoint();
  const { pathname } = useLocation();
  const classifiersLength = localStorage.getItem('clasificadoresLen');

  const justifies = {
    1: 'center',
    2: 'space-around',
  };

  const hideOn = ['/pagos/consulta-de-adeudos', '/pagos'].includes(pathname);

  const justifyRow = hideOn && (justifies[classifiersLength] || 'center');
  const gutter = screen.md ? [30, 20] : [0, 20];

  return (
    <>
      <Row
        gutter={gutter}
        justify={justifyRow}
        style={{ padding: '25px 25px 0 25px' }}
      >
        <Outlet />
      </Row>
      <br />
      {current.cardsStyle === 1 && (
        <Row gutter={[10, 10]} justify="center">
          {extra().map((e) => (
            <NavigationCard
              text={e.nombre}
              to={getRoute(e)}
              type="banner"
              bannerBody={e.bannerBody}
              buttonLabel={e.buttonLabel}
              key={e.id}
            />
          ))}
        </Row>
      )}
      {current.cardsStyle === 2 && (
      <Card style={{ background: 'transparent' }}>
        {(pathname.split('/').length === 4) && (
          <Row gutter={[10, 10]} justify="center">
            {extra().map((e) => (
              <NavigationCard
                text={e.nombre}
                to={getRoute(e)}
                key={e.id}
              />
            ))}
          </Row>
        )}
      </Card>
      )}
    </>
  );
};

export default Layout;
