/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Col,
  Form,
  Input,
  Row,
  Card,
  Spin,
  DatePicker,
} from 'antd';
import {
  getCategoriasDeQuejas,
  getMotivosDeQuejas,
  getQueja,
} from 'api/solicitudes/quejas';
import { useLocation } from 'react-router';
import Select from 'components/Select';
import { useSelector } from 'react-redux';
import { selectEstadosGlobales } from 'store/catalogos';

const ConsultaQueja = ({ queja: folioQueja }) => {
  const estadosGlobales = useSelector(selectEstadosGlobales);
  const [form] = Form.useForm();
  const [queja, setQueja] = useState({});
  const [categorias, setCategorias] = useState([]);
  const [motivos, setMotivos] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const _folioQueja = folioQueja || location.state?.queja || 'R-24-00162535';

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const [
        _motivos,
        _categorias,
        _queja,
      ] = await Promise.all([
        getMotivosDeQuejas(),
        getCategoriasDeQuejas(),
        getQueja({ folio: _folioQueja }),
      ]);
      if (mounted) {
        setQueja(_queja[0]);
        setCategorias(_categorias);
        setMotivos(_motivos);
        setLoading(false);
      }
    };
    fetchData();
    return () => { mounted = false; };
  }, []);

  useEffect(() => {
    if (!queja) return;

    form.setFieldsValue({
      objeto_de_alteracion: motivos
        .find((e) => parseInt(queja.objeto_de_alteracion, 10) === e.id)?.descripcion,
      estado: estadosGlobales.find((estado) => estado.id === queja.estados_globales)?.descripcion,
      observaciones: queja.observaciones,
      fecha_de_los_hechos: queja.fecha_de_los_hechos,
      institucion_de_queja: queja.institucion_de_queja,
      oficina_de_queja: queja.oficina_de_queja,
      categoria_de_solicitud: queja.categoria_de_solicitud,
      hora_de_los_hechos: queja.hora_de_los_hechos,
      detalle_de_hechos: queja.detalle_de_hechos,
      folio: queja.folio,
    });
  }, [queja]);

  return (
    <Spin spinning={loading} tip="Cargando...">
      <Card
        title="Datos de la queja"
      >
        <Form form={form} layout="vertical">
          <Row gutter={[10, 10]}>
            {/* <FormItems cita={cita} /> */}
            <Col xs={24} sm={24} md={12}>
              <Form.Item label="Folio" name="folio">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item label="Estado" name="estado">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item label="Tipo de protesta" name="categoria_de_solicitud">
                <Select disabled options={categorias} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item label="Objeto de alteración o incumplimiento" name="objeto_de_alteracion">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item label="Fecha" name="fecha_de_los_hechos">
                <DatePicker format="DD/MM/YYYY" disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item label="Hora" name="hora_de_los_hechos">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="Nombre de la oficina en donde sucedieron los hechos"
                name="oficina_de_queja"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label="De que institución es el Trámite/Servicio o el personal con quien trató"
                name="institucion_de_queja"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item label="Detalle de los hechos" name="detalle_de_hechos">
                <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item label="Observaciones de resolución" name="observaciones">
                <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} disabled />
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={24} md={12}>
            <Form.Item label="Tipo de queja" name="tipo_de_queja">
              <Input disabled />
            </Form.Item>
          </Col>
          */}

          </Row>
        </Form>
      </Card>
    </Spin>
  );
};

export default ConsultaQueja;
