import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Row,
  Grid,
  Result,
  Collapse,
  Form,
  Col,
  Input,
} from 'antd';
import { ArrowLeftOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Text } from 'components';
import _logger from 'utils/logger';
import Moment from 'moment';
import { sendPDFToEmail } from 'api/configuracion';

const getExtension = (url) => new URL(url).pathname.match(/[^\\]*\.(\w+)$/)[1];

export const isImageUrl = (url) => url && /jpeg|jpg|png/i.test(getExtension(url));
export const isPDFUrl = (url) => url && /pdf/i.test(getExtension(url));
export const isPDFBase64 = (b64) => /^data:application\/pdf/.test(b64);

const Previewer = ({
  visible,
  base64,
  onCancel,
  error,
  modal,
  ifameHeight,
  iFrameUrl,
  isPDF,
  isImage: _isImage,
  controls,
  fileName,
  allowSend,
}) => {
  const screens = Grid.useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [visibleInput, setVisibleInput] = useState(false);
  const [form] = Form.useForm();

  const fullFileName = `${Moment().format('YYYYMMDD HH:mm:ss')} - ${fileName}.pdf`;

  const innerCancel = () => {
    setVisibleInput(false);
    form.resetFields();
  };
  const sendTo = async () => {
    setLoading(true);
    await form.validateFields();
    const values = {};
    values.archivo_base64 = base64.replace('data:application/pdf;base64,', '');
    if (visibleInput) {
      const { email: _email } = form.getFieldsValue();
      values.email = _email;
    }
    values.nombre_del_archivo = fullFileName;
    values.reporte_redaccion = fileName;
    const sent = await sendPDFToEmail(values);
    if (sent) {
      innerCancel();
    }
    setLoading(false);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const SubmitControls = () => (
    <Row className="form-submit-controls">
      {!visibleInput ? (
        <Button
          type="link"
          onClick={onCancel}
        >
          <ArrowLeftOutlined />
          {screens.md && 'Regresar'}
        </Button>
      ) : (
        <Button
          type="link"
          onClick={() => innerCancel()}
        >
          <ArrowLeftOutlined />
          {screens.md && 'Regresar'}
        </Button>
      )}
      {!visibleInput && allowSend && (
      <Button
        type="link"
        onClick={() => setVisibleInput(true)}
      >
        <SendOutlined />
        {screens.md && 'Enviar a Correo'}
      </Button>
      )}
      {(visibleInput && allowSend) && (
      <Button
        type="link"
        onClick={async () => sendTo()}
      >
        <SendOutlined />
        {screens.md && 'Enviar'}
      </Button>
      )}
    </Row>
  );

  const isValidBase64 = () => {
    try {
      if (base64) {
        const _base64 = base64.substring(base64.indexOf(',') + 1);
        const decoded = window.atob(_base64);
        const encoded = window.btoa(decoded);
        const equals = encoded === _base64;
        return equals;
      }
    } catch (err) {
      _logger(err);
    }
    return false;
  };

  const rules = {
    email: [
      { required: true },
      { type: 'email' },
    ],
  };

  const getContent = (idx, style) => {
    const _iFrameUrl = typeof idx === 'number' ? iFrameUrl[idx] : iFrameUrl;
    if (isImageUrl(_iFrameUrl) || _isImage) {
      return (<img src={_iFrameUrl || base64} alt="img_preview" style={style || { width: '100%' }} />);
    }
    if (_iFrameUrl) {
      const encoded = encodeURIComponent(_iFrameUrl);
      const googleViewerURI = `https://docs.google.com/viewer?url=${encoded}&embedded=true`;
      return (
        <iframe
          src={isPDFUrl(_iFrameUrl) ? `${_iFrameUrl}${controls ? '' : '#toolbar=0'}` : googleViewerURI}
          title="preview"
          style={{
            minHeight: modal && ifameHeight === '100%' ? '75vh' : null,
            height: ifameHeight,
            width: '100%',
          }}
        />
      );
    }
    if (isPDF) {
      return (
        <iframe
          src={`data:application/pdf;base64,${base64}`}
          title="preview"
          style={{
            minHeight: modal ? '75vh' : null,
            height: ifameHeight,
            width: '100%',
          }}
        />
      );
    }
    if (isValidBase64()) {
      return (
        <iframe
          src={`${base64}${controls ? '' : '#toolbar=0'}`}
          title="preview"
          style={{
            minHeight: modal ? '75vh' : null,
            height: ifameHeight,
            width: '100%',
          }}
        />
      );
    }
    return modal ? 'No fue posible mostrar el archivo' : null;
  };

  if (modal) {
    return (
      <Modal
        title={(
          <Row justify="space-between">
            <Text>
              Vista previa
            </Text>
            <SubmitControls />
          </Row>
        )}
        className="modal-preview"
        visible={visible}
        footer={null}
        closable={false}
        onCancel={onCancel}
        width={screens?.md ? '60%' : '90%'}
        maskClosable={false}
        centered
        destroyOnClose
        loading={loading}
      >

        {visibleInput ? (
          <Form
            layout="vertical"
            form={form}
            onFinish={sendTo}
            scrollToFirstError
          >
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name="email"
                  rules={rules.email}
                  label="Correo electrónico"
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Row>
          </Form>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {Array.isArray(iFrameUrl) ? (
              <Collapse accordion>
                {iFrameUrl.map((e, idx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Collapse.Panel key={e + idx} header={e}>
                    {getContent(idx, { height: '100%', width: '100%', objectFit: 'contain' })}
                  </Collapse.Panel>
                ))}
              </Collapse>
            ) : getContent()}
          </>
        )}
      </Modal>
    );
  }

  if (error) {
    return (
      <Row justify="center" style={{ width: '100%' }}>
        <Result
          status="warning"
          title="No fue posible cargar el contenido"
          extra={error}
        />
      </Row>
    );
  }

  return getContent();
};

Previewer.propTypes = {
  onCancel: PropTypes.func,
  visible: PropTypes.bool.isRequired,
  base64: PropTypes.string,
  modal: PropTypes.bool,
  ifameHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  iFrameUrl: PropTypes.string,
  isImage: PropTypes.bool,
  isPDF: PropTypes.bool,
  controls: PropTypes.bool,
  fileName: PropTypes.string,
  allowSend: PropTypes.bool,
};

Previewer.defaultProps = {
  base64: null,
  modal: true,
  ifameHeight: '100%',
  error: null,
  onCancel: null,
  iFrameUrl: null,
  isImage: false,
  isPDF: false,
  controls: true,
  fileName: 'recibo',
  allowSend: false,
};
export default Previewer;
