import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Form,
  Input,
  Col,
  Button,
} from 'antd';
import { Text } from 'components';

const ImporteModal = ({
  form,
  showImporteModal,
  setShowImporteModal,
  tempCargos,
  setSelectedCargos,
}) => (
  <Modal
    title="Importe"
    visible={showImporteModal}
    onCancel={() => setShowImporteModal(false)}
    footer={null}
  >
    <Text>
      El importe de este cargo es capturable, por favor ingrese el importe
      correspondiente.
    </Text>
    <Form
      form={form}
      layout="vertical"
      onFinish={(values) => {
        const copy = [...tempCargos];
        copy[tempCargos.length - 1].importe = parseFloat(values.importe);
        setSelectedCargos(copy);
        setShowImporteModal(false);
      }}
    >
      <Col span={24}>
        <Form.Item
          name="importe"
          label="Importe"
          rules={[{ required: true, message: 'Por favor ingrese el importe.' }]}
        >
          <Input
            type="number"
            min={0}
            step="0.01"
            autoFocus
            placeholder="Importe"
          />
        </Form.Item>
      </Col>
      <Button
        htmlType="submit"
        type="primary"
        $accent
      >
        Guardar
      </Button>
    </Form>
  </Modal>
);

ImporteModal.propTypes = {
  form: PropTypes.shape.isRequired,
  showImporteModal: PropTypes.bool.isRequired,
  setShowImporteModal: PropTypes.func.isRequired,
  tempCargos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelectedCargos: PropTypes.func.isRequired,
};

export default ImporteModal;
