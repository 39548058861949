import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gettersListMap } from 'api/padrones';
import { Button, Text } from 'components';
import padronToString, { getPadronKey } from 'utils/padronesToString';
import getParamsPadron from 'views/Tramites/GPM/paramsPadronesAfectados';
import { padronesEnum, getEnumValues, solicitantesEnum } from 'views/Tramites/GPM/enums';
import PadronSolicitante from 'views/Tramites/GPM/components/PadronSolicitante';
import { LeftOutlined } from '@ant-design/icons';
import SelectableList from 'views/Tramites/GPM/components/SelectableList';
import addressToString from 'utils/address';
import { ENV_NAME, ENV_NAMES_ENUM, PROD } from 'utils/env';
import { GISInfoResult } from 'views/Payments/components/SearchForm';
import ModalBusquedaPadron from './ModalBusquedaPadron';
import ArcGisMap, { validateGIS } from './ArcGisMap';

const pluralizePadronName = (descripcion) => descripcion.trim().split(' ').map((_word, idx, words) => {
  const word = [..._word].join('');
  const conectors = words.filter((w) => w.length <= 2);
  if (word.length <= 2 || word.at(-1) === 's'
    || (idx !== 0 && conectors.length && !conectors.some((c) => c === word))) {
    return word;
  }
  return word.concat(/[aeiou]/i.test(word.at(-1)) ? 's' : 'es');
}).join(' ');

const PadronAfectado = ({
  setLoading,
  tipoDePadron: _tipoDePadron,
  tipoPadronSolicitante,
  padronSolicitante,
  padrones,
  padronAfectado,
  setPadronAfectado,
  plantillaDeTramite,
  tramite,
  getAdeudo,
  loading,
  hasGISInfo,
  setHasGISInfo,
  screenshot,
  callback,
}) => {
  const [dataSource, setDataSource] = useState([]);
  const [visibleModalBusqueda, setVisibleModalBusqueda] = useState(false);

  const tipoDePadron = padrones.find((p) => _tipoDePadron === p.id);
  const withGISInfo = ENV_NAME === ENV_NAMES_ENUM.JUAREZ && _tipoDePadron === padronesEnum.PREDIO
    && plantillaDeTramite.requiere_gis;

  useEffect(() => {
    const fetchPadrones = async () => {
      setLoading(true);
      const params = getParamsPadron(
        plantillaDeTramite,
        tipoPadronSolicitante,
        padronSolicitante,
      );
      if (!params) {
        setLoading(false);
        return;
      }
      params.page = 1;
      const _dataSource = await gettersListMap[tipoDePadron.id](params);
      setDataSource(_dataSource || []);
      setLoading(false);
    };
    if (!padronAfectado) {
      fetchPadrones();
    }
  }, []);

  useEffect(() => {
    const validate = async () => {
      setLoading(true);
      const [hasInfo] = await validateGIS(padronAfectado.clave_catastral_municipal);
      setHasGISInfo(hasInfo);
      setLoading(false);
    };
    if (padronAfectado) {
      if (withGISInfo) {
        validate();
      }
    } else {
      setHasGISInfo(false);
    }
  }, [padronAfectado]);

  if (padronAfectado) {
    return (
      <>
        {!tramite && (
          <Button type="link" onClick={() => setPadronAfectado()}>
            <LeftOutlined />
            Volver
          </Button>
        )}
        <PadronSolicitante
          padronAfectado={padronAfectado}
          tipoDePadronAfectado={tipoDePadron.id}
          plantillaDeTramite={plantillaDeTramite}
          padronSolicitante={padronSolicitante}
          tipoPadronSolicitante={tipoPadronSolicitante}
          padrones={padrones}
          showSwap={false}
          tramite={tramite}
        />
        {withGISInfo && padronAfectado && (
          <>
            <br />
            {!hasGISInfo && !loading && (
              <GISInfoResult
                type="danger"
                departamento={plantillaDeTramite.departamentos[0]?.unidad_operativa}
              />
            )}
            <ArcGisMap
              clave_catastral_municipal={padronAfectado.clave_catastral_municipal}
              layersVisible={false}
              visibleButton={false}
              required={false}
              screenshot={screenshot}
              setLoading={setLoading}
              visible={hasGISInfo}
              callback={callback}
            />
          </>
        )}
      </>
    );
  }

  const padronNamePlural = pluralizePadronName(tipoDePadron.descripcion);

  const descriptionNormalizerMap = {
    [padronesEnum.PREDIO]: (predio) => {
      const separator = predio.descripcion && predio.direccion_index ? ' - ' : '';
      const descriptor = `${predio.descripcion}${separator}${addressToString(predio.direccion_index)}`;
      if (!descriptor && !PROD) {
        return 'Sin descripción';
      }
      return descriptor;
    },
  };

  const descriptionNormalizer = descriptionNormalizerMap[_tipoDePadron]
    || ((item) => padronToString(item, _tipoDePadron));

  const onSelect = async (item) => {
    setPadronAfectado(item);
    if (plantillaDeTramite.tipos_de_cargos_consulta.length) {
      await getAdeudo(tipoDePadron.id, item.id, setPadronAfectado);
    }
  };

  return (
    <>
      <Text style={{ marginBottom: 20, width: '100%' }}>
        Seleciona
        {' '}
        {tipoDePadron.descripcion.toLowerCase()}
        {' '}
        con el cual quieres realizar tu solicitud
      </Text>
      <SelectableList
        dataSource={dataSource}
        getter={(params) => gettersListMap[_tipoDePadron](params)}
        titleNormalizer={(item) => getPadronKey(item, _tipoDePadron)}
        descriptionNormalizer={descriptionNormalizer}
        onSelect={onSelect}
        search={{
          placeholder: `Buscar en mis ${padronNamePlural.toLowerCase()}`,
          wrapperStyle: { width: 300, marginLeft: 'auto' },
        }}
        title={`Mis ${padronNamePlural}`}
        listWrapperStyle={plantillaDeTramite.es_propietario ? {} : { height: '50vh' }}
        emptyListDescriptor={`${padronNamePlural} registrados a nombre de ${padronToString(padronSolicitante, tipoPadronSolicitante)}`}
        // withGISInfo={ENV_NAME === ENV_NAMES_ENUM.JUAREZ && _tipoDePadron === padronesEnum.PREDIO
        //   && plantillaDeTramite.pasos_para_tramites
        //     .some((p) => p.tipo_de_paso === tiposDePasosEnum.GIS)}
        // departamento={plantillaDeTramite.departamentos[0]?.unidad_operativa}
      />
      {!plantillaDeTramite.es_propietario && (
        <Text style={{ marginBottom: 20, width: '100%' }}>
          O si desea realizar su solicitud con un
          {' '}
          {tipoDePadron.descripcion.toLowerCase()}
          {' '}
          que no le pertenece
          <Button type="link" onClick={() => setVisibleModalBusqueda(true)}>
            <Text strong $accent>
              presione aquí para buscar
            </Text>
          </Button>
        </Text>
      )}
      <ModalBusquedaPadron
        visible={visibleModalBusqueda}
        onCancel={() => {
          setVisibleModalBusqueda(false);
          setPadronAfectado();
        }}
        onSelect={(item) => {
          setVisibleModalBusqueda(false);
          onSelect(item);
        }}
        tipoDePadron={tipoDePadron}
        // departamento={plantillaDeTramite.departamentos[0]?.unidad_operativa}
        // withGISInfo={ENV_NAME === ENV_NAMES_ENUM.JUAREZ && _tipoDePadron === padronesEnum.PREDIO
        // && plantillaDeTramite.pasos_para_tramites
        //   .some((p) => p.tipo_de_paso === tiposDePasosEnum.GIS)}
      />
    </>
  );
};

PadronAfectado.propTypes = {
  tipoDePadron: PropTypes.oneOf(getEnumValues(padronesEnum)).isRequired,
  tipoPadronSolicitante: PropTypes.oneOf(getEnumValues(solicitantesEnum)).isRequired,
  setLoading: PropTypes.func.isRequired,
  padronSolicitante: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
  padrones: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    descripcion: PropTypes.string.isRequired,
  })).isRequired,
  padronAfectado: PropTypes.shape({
    id: PropTypes.number.isRequired,
    clave_catastral_municipal: PropTypes.string,
  }),
  setPadronAfectado: PropTypes.func.isRequired,
  plantillaDeTramite: PropTypes.shape({
    descripcion: PropTypes.string,
    es_propietario: PropTypes.bool,
    requiere_gis: PropTypes.bool,
    departamentos: PropTypes.arrayOf(PropTypes.shape({ unidad_operativa: PropTypes.shape({}) })),
    tipos_de_cargos_consulta: PropTypes.arrayOf(PropTypes.number).isRequired,
    pasos_para_tramites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  contribuyente: PropTypes.shape({
    clave: PropTypes.string.isRequired,
    nombre: PropTypes.string,
    id: PropTypes.number.isRequired,
  }),
  tramite: PropTypes.shape({ folio: PropTypes.string.isRequired }),
  getAdeudo: PropTypes.func,
  setHasGISInfo: PropTypes.func,
  loading: PropTypes.bool,
  hasGISInfo: PropTypes.bool,
  screenshot: PropTypes.string,
  callback: PropTypes.func,
};

PadronAfectado.defaultProps = {
  padronAfectado: null,
  contribuyente: null,
  tramite: null,
  getAdeudo: null,
  setHasGISInfo: null,
  loading: false,
  hasGISInfo: false,
  screenshot: null,
  callback: () => { },
};

export default PadronAfectado;
