import React from 'react';
import NayaritPrivacyNotice from 'components/PrivacyNotices/Nayarit';
import JuarezPrivacyNotice from 'components/PrivacyNotices/Juarez';
import NoDisponible from 'components/PrivacyNotices/NoDisponible';
import { ENV_NAME, ENV_NAMES_ENUM } from 'utils/env';

const PrivacyNotice = () => {
  const privacyNotices = {
    [ENV_NAMES_ENUM.NAYARIT]: NayaritPrivacyNotice,
    [ENV_NAMES_ENUM.JUAREZ]: JuarezPrivacyNotice,
    // [ENV_NAMES_ENUM.HERMOSILLO]:
  };
  const Component = privacyNotices[ENV_NAME] || NoDisponible;
  return <Component />;
};

export default PrivacyNotice;
