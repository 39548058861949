import http from 'api';
import _logger from 'utils/logger';
import { message } from 'antd';
import { formatReceived, formatSent } from 'utils/formatters';

export const baseURI = '/empresas/empresas/';

/**
 * GET Empresas
 * @param {Object} params
 * @returns {Promise<Array<object>>}
 */
export const getEmpresas = async (params = null) => {
  try {
    const response = await http.get(baseURI, { params });
    const output = { ...response.data, results: formatReceived(response.data.results) };
    return output;
  } catch (err) {
    _logger(err);
    return { results: [] };
  }
};

/**
 * POST Empresa
  * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postEmpresa = async (values) => {
  try {
    const response = await http.post(baseURI, formatSent(values));
    message.info('Agregado correctamente');
    return formatReceived(response.data);
  } catch (err) {
    _logger(err);
    return false;
  }
};
