import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Modal,
  Form,
  message,
} from 'antd';
import {
  // ArrowLeftOutlined,
  StopOutlined,
} from '@ant-design/icons';
// import FormSubmitControls from 'components/FormSubmitCustomControls';
import Select from 'components/Select';
import { getRazonesDeRechazoCita } from 'api/catalogos';
import { patchCancelarCita } from 'api/tramites/citas';

const ModalCancelarCita = ({
  visible,
  setVisible,
  callback,
  cita,
}) => {
  const [form] = Form.useForm();
  const [razones, setRazones] = useState([]);

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      const _razones = await getRazonesDeRechazoCita({ es_razon_rechazo_ciudadano: true });
      if (mounted) setRazones(_razones);
    };
    fetch();
    return () => { mounted = false; };
  }, []);

  const onFinish = async () => {
    const { razon_de_rechazo_cita } = form.getFieldsValue();
    const canceled = await patchCancelarCita(cita, razon_de_rechazo_cita);
    if (canceled) {
      callback(canceled);
      setVisible(false);
      message.info('Cita cancelada correctamente');
      form.resetFields();
    }
  };

  const rules = { required: [{ required: true }] };

  return (
    <Modal
      visible={visible}
      title="Cancelar Cita"
      afterClose={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      onOk={form.submit}
      okText={(
        <>
          Cancelar
          {' '}
          <StopOutlined />
        </>
      )}
      okButtonProps={{
        className: 'no-color',
        danger: true,
        style: { padding: '2px 15px' },
      }}
      cancelButtonProps={{
        style: { display: 'none' },
      }}
    >
      <Row>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            name="cancelar_tramite"
            onFinish={() => onFinish()}
          >
            <Col span={24}>
              <Form.Item
                label="Motivo de cancelación"
                name="razon_de_rechazo_cita"
                rules={rules.required}
              >
                <Select
                  options={razones}
                  getLabel={(e) => e.descripcion}
                  getValue={(e) => e.id}
                />
              </Form.Item>
            </Col>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

ModalCancelarCita.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  cita: PropTypes.number.isRequired,
};

export default ModalCancelarCita;
