import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Modal,
  Form,
  Input,
  message,
  Typography,
} from 'antd';
import {
  ArrowLeftOutlined,
  StopOutlined,
} from '@ant-design/icons';
import FormSubmitControls from 'components/FormSubmitCustomControls';
import Select from 'components/Select';
import { getRazonesDeRechazo } from 'api/catalogos';
import { cancelarTramite } from 'api/tramites/tramites';
import { procedenciasEnum } from 'views/Tramites/GPM/enums';

const ModalCancelarTramite = ({
  visible,
  setVisible,
  callback,
  tramite,
  cargos,
  cancelarPadron,
  tipoDePadron,
}) => {
  const [form] = Form.useForm();
  const [razones, setRazones] = useState([]);

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      const _razones = await getRazonesDeRechazo({ es_razon_rechazo_ciudadano: false });
      if (mounted) {
        setRazones(_razones);
      }
    };
    fetch();
    return () => { mounted = false; };
  }, []);

  const cargosGenerados = cargos.filter((c) => c.procedencia === procedenciasEnum.GPM);
  const cargosLigados = cargos.filter((c) => c.procedencia !== procedenciasEnum.GPM);
  const allGeneradosSaldados = !!cargosGenerados.length && cargosGenerados.every((c) => c.saldado);
  const ligadosSaldados = !!cargosLigados.length && cargosLigados.some((c) => c.saldado);

  const onFinish = async () => {
    const values = form.getFieldsValue();
    values.tramite = tramite;
    values.cancelar_padron = cancelarPadron;
    values.cancelar_cargos = cargosGenerados.some((c) => !c.saldado);
    const canceled = await cancelarTramite(values);
    if (canceled) {
      callback(canceled);
      setVisible(false);
      message.info('Trámite cancelado correctamente');
    }
    form.resetFields();
  };

  const controls = [
    {
      text: 'Regresar',
      icon: <ArrowLeftOutlined />,
      onClick: () => {
        setVisible(false);
      },
    },
    {
      text: 'Cancelar',
      icon: <StopOutlined />,
      onClick: () => form.submit(),
      danger: true,
      className: 'no-color',
    },
  ];

  const rules = { required: [{ required: true }] };

  return (
    <Modal
      footer={null}
      closable={false}
      visible={visible}
      title={<FormSubmitControls label="Cancelar Trámite" controls={controls} />}
    >
      <Row>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            name="cancelar_tramite"
            onFinish={() => onFinish()}
          >
            <Col span={24}>
              <Form.Item
                label="Comentarios"
                name="comentario_de_rechazo"
                rules={rules.required}
              >
                <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} allowClear />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Motivo de cancelación"
                name="razones_de_rechazo"
                rules={rules.required}
              >
                <Select
                  options={razones}
                  getLabel={(e) => e.descripcion}
                  getValue={(e) => e.id}
                />
              </Form.Item>
            </Col>
            <Typography.Text type="warning">
              {!!cargosGenerados.length && !allGeneradosSaldados && (
                <>
                  Los cargos generados mediante el trámite (no saldados)
                  serán cancelados.
                  <br />
                </>
              )}
              {!!cargosLigados.length && !ligadosSaldados && (
                <>
                  Los cargos ligados al trámite (no saldados) pero generados por otros medios
                  dejaran de estar relacionados a este trámite
                </>
              )}
            </Typography.Text>
            <br />
            {cancelarPadron && (
              <Typography.Text type="danger">
                {`* El padrón ${tipoDePadron} registrado durante el trámite será eliminado con esta acción`}
              </Typography.Text>
            )}
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

ModalCancelarTramite.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  tramite: PropTypes.number,
  cancelarPadron: PropTypes.bool,
  tipoDePadron: PropTypes.string,
  cargos: PropTypes.arrayOf(PropTypes.shape()),
};
ModalCancelarTramite.defaultProps = {
  tramite: null,
  cancelarPadron: false,
  tipoDePadron: null,
  cargos: [],
};

export default ModalCancelarTramite;
