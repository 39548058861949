/* eslint-disable max-len */
import { selectPadrones } from 'store/catalogos';
import store from 'store/store';
import { padronesEnum } from 'views/Tramites/GPM/enums';
import { DEV, PROD } from 'utils/env';
import { selectClaveDePredioPrincipal } from 'store/auth';
import getAddress from 'utils/address';
import { listToString, normalizeText } from 'utils/normalizers';

const noKey = (padron, prop) => (PROD ? `Sin ${prop || 'identificador'}` : `ID: ${padron.id}`);
const vehiculares = [padronesEnum.VEHICULO, padronesEnum.MOTOCICLETA, padronesEnum.REMOLQUE];

const getKeyPredio = (predio = {}) => {
  const config = selectClaveDePredioPrincipal(store.getState());
  const keysMap = {
    1: 'cuenta_unica_de_predial',
    2: 'CURT',
    3: 'clave_catastral_estandar',
    4: 'clave_catastral_municipal',
    5: 'clave_sectorial',
  };

  if (config) {
    const key = keysMap[config.clave_de_predio];
    return predio[key] || noKey(predio, normalizeText(key));
  }

  const keyWithValue = Object.values(keysMap).find((k) => predio[k]);
  return keyWithValue ? predio[keyWithValue] : noKey(predio);
};

const _getPadronKey = (padron, tipoDePadron) => {
  if ('empresa' in padron) {
    return padron.clave || padron.empresa?.clave;
  }
  if (vehiculares.includes(tipoDePadron)) {
    return padron.numero_de_placa_vigente || padron.placa || padron.serie
      || padron.numero_de_identificacion_vehicular;
  }
  if (tipoDePadron === padronesEnum.PREDIO) return getKeyPredio(padron);
  return padron.clave_ciudadana || padron.clave;
};

export const getPadronKey = (pd, tipoDePadron) => _getPadronKey(pd, tipoDePadron) || noKey(pd);

export const getNombreCompleto = (_padron) => {
  if (!_padron || typeof _padron !== 'object') return '';
  const padron = { ..._padron };
  if (padron.razon_social) return padron.razon_social;
  padron.nombre_completo = padron.nombre_completo
    || `${padron.nombre || ''} ${padron.apellido_paterno || ''} ${padron.apellido_materno || ''}`.trim();

  if (padron.nombre_completo) return padron.nombre_completo;
  if (padron.ciudadano) {
    const nombre_completo_ciudadano = getNombreCompleto(padron.ciudadano);
    return nombre_completo_ciudadano;
  }
  if (padron.contribuyente) {
    const nombre_completo_contribuyente = getNombreCompleto(padron.contribuyente);
    return nombre_completo_contribuyente;
  }
  if (DEV) return `Padron sin nombre (ID: ${padron.id})`;
  return '';
};

const fieldToCensure = (field) => /descripcion_predio/.test(field);

const map = (padron, descriptors) => descriptors.map((descriptor) => {
  if (Array.isArray(descriptor)) {
    const [_padronKey, ..._descriptors] = descriptor;
    return padron[_padronKey] ? map(padron[_padronKey], _descriptors) : null;
  }
  if (typeof descriptor === 'function') return descriptor(padron);
  if (descriptor === 'numero_de_placa_vigente' && !padron[descriptor]) return 'Sin placa';
  return fieldToCensure(descriptor) && padron.censure
    ? (padron[descriptor] || '').split(' ').map((e) => e.replace(/(?<!^)./g, '*')).join(' ')
    : padron[descriptor];
});

const join = (padron, descriptors) => map(padron, descriptors.filter((d) => d)).flat(Infinity).filter((e) => e).join(' - ');

const getAddressPredio = (predio) => getAddress(predio.direccion_index);
const getAddressLicencia = (empresa) => getAddress(empresa.direccion_de_ubicacion);

export const ciudadanoToString = (ciudanano) => join(ciudanano, ['clave_ciudadana', getNombreCompleto]);
export const contribuyenteToString = (contribuyente) => join(contribuyente, ['clave', getNombreCompleto]);

export const propietarioToString = (padron) => {
  if (!padron) return '';
  const keys = Object.keys(padron);
  const field = keys.find((f) => /contribuyente/.test(f) && padron[f])
    || keys.find((f) => /ciudadano|propietario/.test(f) && padron[f]);
  if (!field) return 'Sin propietario';
  return (/contribuyente/.test(field) ? contribuyenteToString : ciudadanoToString)(padron[field]);
};

const getPropietarioPredio = (predio) => {
  if (predio.nombres_de_participes?.length) return listToString(predio.nombres_de_participes);
  return propietarioToString(predio);
};

export const empresaToString = (e) => join(e, ['clave', 'nombre_comercial']);
export const vehiculoToString = (v) => join(v, ['numero_de_placa_vigente', getNombreCompleto]);
export const remolqueToString = (r) => join(r, ['numero_de_placa_vigente', 'serie']);
export const padronesDerivadosEmpresaDefaultToString = (p) => join(p, ['clave', 'descripcion', empresaToString]);
export const infraccionesToString = (i) => join(i, ['clave', getNombreCompleto, 'numero_de_placa']);
export const predioToString = (p, address) => join(p, [getKeyPredio, getPropietarioPredio, address && getAddressPredio]);
export const licenciaFuncionamientoToString = (l) => join(l, ['clave_de_control', ['empresa', propietarioToString], ['empresa', empresaToString], ['empresa', getAddressLicencia]]);

const padronToString = (_padron, tipo_de_padron, withTipoDePadron = false, withAddress = null) => {
  if (!_padron) return PROD ? '' : 'Padron not given';
  if (typeof _padron !== 'object') return PROD ? '' : 'Padron is not an object ';
  const padron = { ..._padron };
  const padronToStringMap = {
    [padronesEnum.CIUDADANO]: ciudadanoToString,
    [padronesEnum.EMPRESA]: empresaToString,
    [padronesEnum.PREDIO]: (predio) => predioToString(predio, withAddress),
    [padronesEnum.VEHICULO]: vehiculoToString,
    [padronesEnum.CONTRIBUYENTE]: contribuyenteToString,
    [padronesEnum.MOTOCICLETA]: remolqueToString,
    [padronesEnum.REMOLQUE]: remolqueToString,
    [padronesEnum.INFRACCIONES]: infraccionesToString,
    [padronesEnum.MULTAS]: infraccionesToString,
    [padronesEnum.EXPEDIENTE_LICENCIA_FUNCIONAMIENTO]: licenciaFuncionamientoToString,
  };

  const padrones = selectPadrones(store.getState());
  const tipoDePadronStr = withTipoDePadron ? padrones.find((p) => p.id === tipo_de_padron)?.descripcion.toUpperCase().concat(':') : '';
  const padronString = padronToStringMap[tipo_de_padron]?.(padron) || padronesDerivadosEmpresaDefaultToString(padron)?.trim() || '';
  return `${tipoDePadronStr} ${padronString || 'Sin información'}`;
};

export default padronToString;
