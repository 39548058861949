import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Spin,
  Col,
  Modal,
  Row,
  DatePicker,
  Input,
  message,
  Table as AntTable,
} from 'antd';
import FormSubmitCustomControls from 'components/FormSubmitCustomControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import { DATETIME_FRONTEND_FORMAT, DATE_FRONTEND_FORMAT } from 'utils/formatValues';
import {
  deleteObligacionFiscalContribuyente,
  getObligacionesFiscalesContribuyente,
  patchObligacionFiscalContribuyente,
  postObligacionFiscalContribuyente,
} from 'api/empresas/obligacionesFiscalesContribuyente';
import { getEstatusDeObigacionesFiscales, getObligacionesFiscales } from 'api/empresas';
import { useSelector } from 'react-redux';
import { InputSearchPro } from 'components/InputSearch';
import gettersMap, { getContenTypePorTipoDePadron, gettersListMap, getTipoDePadronPorContenType } from 'api/padrones';
import _logger from 'utils/logger';
import { toMoment } from 'utils/normalizers';
import { selectPadrones } from 'store/catalogos';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import getParamsPadron from '../paramsPadronesAfectados';
import { padronesEnum } from '../enums';

const ObligacionesFiscalesContribuyente = ({
  contribuyente,
  tipoDePadron,
  padron,
  callback,
  fetchCallback,
  mutable,
}) => {
  const [form] = Form.useForm();
  const estatus_de_obligaciones_fiscal = Form.useWatch('estatus_de_obligaciones_fiscal', form);
  const tipo_de_padron = Form.useWatch('tipo_de_padron', form);
  const padrones = useSelector(selectPadrones)
    .filter((e) => ![1, 2, 3, 4, 5, 15, 16, 17].includes(e.id));
  const descripcionPadron = padrones.find((e) => e.id === tipo_de_padron)?.descripcion || '';
  const [data, setData] = useState([]);
  const [padronFound, setPadronFound] = useState(padron);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [obligaciones, setObligaciones] = useState([]);
  const [estatusDeObligaciones, setEstatusObligaciones] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    const params = { contribuyente };
    if (padron) {
      params.content_type_padron = getContenTypePorTipoDePadron(tipoDePadron);
      params.object_id_padron = padron.id;
    }
    const _data = await getObligacionesFiscalesContribuyente(params);
    setData(_data);
    if (fetchCallback) fetchCallback(_data);
    setLoading(false);
  };

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      const [
        _obligaciones,
        _estatus,
      ] = await Promise.all([
        getObligacionesFiscales(),
        getEstatusDeObigacionesFiscales(),
      ]);
      await fetchData();
      if (mounted) {
        const filtered = padron
          ? _obligaciones.filter((o) => o.tipo_padron === tipoDePadron)
          : _obligaciones;
        setObligaciones(filtered);
        setEstatusObligaciones(_estatus);
      }
    };
    fetch();
    return () => { mounted = false; };
  }, []);

  const fillValuesPadron = () => {
    if (padronFound) {
      form.setFieldsValue({
        object_id_padron: padronFound.id,
        clave_cuenta_de_padron_obligado: padronFound?.clave,
        contribuyente_rfc: padronFound?.empresa?.contribuyente?.rfc,
      });
    }
  };

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setSelectedRowKeys([]);
    setPadronFound(padron);
    fillValuesPadron();
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const [key] = selectedRowKeys;
      values.contribuyente = contribuyente;
      values.content_type_padron = getContenTypePorTipoDePadron(values.tipo_de_padron);
      if (padron && !key) {
        values.object_id_padron = padron.id;
      }
      const saved = await (key
        ? patchObligacionFiscalContribuyente(key, values)
        : postObligacionFiscalContribuyente(values));
      if (saved) {
        const descripcionObligacion = obligaciones
          .find((o) => o.id === values.obligacion_fiscal)?.descripcion;
        const movementType = key ? 'editada' : 'registrada';
        const padronToStr = ` ${saved.id} - ${padronFound.clave} - ${padronFound.empresa?.nombre_comercial
          || padronFound.empresa?.contribuyente?.nombre_completo}`;
        const detailItems = [
          `Fecha inicio: ${values.fecha_inicio.format()}`,
          values.fecha_fin ? `Fecha fin: ${values.fecha_fin.format() || 'N/A'}` : null,
          `Estatus: ${estatusDeObligaciones.find((e) => e.id === values.estatus_de_obligaciones_fiscal)?.descripcion}`,
          values.observaciones ? `Observaciones: ${values.observaciones}` : null,
        ].filter((e) => e);
        const detail = detailItems.join(', ');
        saved.message = `Obligación ${descripcionObligacion} ${padron ? '' : `para ${descripcionPadron} ${padronToStr}`} [${movementType}]: ${detail}`;
        onCancel();
        await fetchData();
        if (callback) callback(saved);
      }
      setLoading(false);
    } catch (err) {
      _logger(err, setLoading);
    }
  };

  const retrievePadron = async (id, tipoPadron) => {
    const _padrones = await gettersMap[tipoPadron]({ id });
    if (_padrones.length || _padrones.count) {
      if ((_padrones.length || _padrones.count) === 1) {
        const _padronFound = _padrones.results?.[0] || _padrones[0];
        setPadronFound(_padronFound);
        form.setFieldsValue({ object_id_padron: _padronFound.id });
      }
    }
  };

  const searchPadron = async () => {
    setLoading(true);
    const q = form.getFieldValue('q');
    const _tipo_de_padron = form.getFieldValue('tipo_de_padron');
    if (q) {
      const params = getParamsPadron(
        { tipo_de_padron: _tipo_de_padron },
        padronesEnum.CONTRIBUYENTE,
        { id: contribuyente },
      );
      const _padrones = await gettersListMap[_tipo_de_padron]({ q, ...params });
      if (_padrones.length || _padrones.count) {
        if ((_padrones.length || _padrones.count) === 1) {
          const _padronFound = _padrones.results?.[0] || _padrones[0];
          setPadronFound(_padronFound);
          form.setFieldsValue({ object_id_padron: _padronFound.id });
        } else {
          message.warn('Ingrese una búsqueda más específica');
        }
      } else {
        message.warn('No se encontro el padrón');
      }
    }
    setLoading(false);
  };

  const onClickEdit = async () => {
    const [key] = selectedRowKeys;
    const match = data.find((e) => e.id === key);
    await retrievePadron(
      match.object_id_padron,
      getTipoDePadronPorContenType(match.content_type_padron),
    );
    setVisible(true);
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
    form.setFieldsValue({ tipo_padron: tipoDePadron });
  };

  const onClickDelete = () => setVisibleAlert(true);

  const deleteItem = async () => {
    setLoading(true);
    const [key] = selectedRowKeys;
    const deleted = await deleteObligacionFiscalContribuyente(key);
    if (deleted) {
      const match = data.find((e) => e.id === key);
      const obligacion = obligaciones.find((o) => o.id === match.obligacion_fiscal)?.descripcion;
      if (callback) callback({ message: `Obligación ${obligacion} eliminada` });
      setVisibleAlert(false);
      onCancel();
      await fetchData();
    }
    setLoading(false);
  };

  const rowSelection = mutable ? {
    selectedRowKeys,
    type: 'radio',
  } : null;

  const columns = [
    {
      title: 'Clave',
      key: 'clave_cuenta_de_padron_obligado',
      dataIndex: 'clave_cuenta_de_padron_obligado',
      width: 100,
    },
    {
      title: 'Obligación Fiscal',
      key: 'obligacion_fiscal',
      dataIndex: 'obligacion_fiscal',
      width: 200,
      render: (val) => obligaciones.find((e) => e.id === val)?.descripcion,
    },
    {
      title: 'Fecha Inicio',
      key: 'fecha_inicio',
      dataIndex: 'fecha_inicio',
      width: 120,
      render: (val) => val?.format(),
      responsive: ['md'],
    },
    {
      title: 'Fecha Fin',
      key: 'fecha_fin',
      dataIndex: 'fecha_fin',
      width: 120,
      render: (val) => val?.format(),
      responsive: ['md'],
    },
    {
      title: 'Estado',
      key: 'estatus_de_obligaciones_fiscal',
      dataIndex: 'estatus_de_obligaciones_fiscal',
      width: 100,
      render: (val) => estatusDeObligaciones.find((e) => e.id === val)?.descripcion,
      responsive: ['md'],
    },
  ].filter((e) => e);

  const resetAndValidate = () => {
    setPadronFound();
    form.resetFields(['q']);
    form.validateFields(['q']);
  };

  const rules = { required: [{ required: true }] };

  const onChangeObligacion = (value) => {
    if (value && !padron) {
      const match = obligaciones.find((e) => e.id === value);
      form.setFieldsValue({ tipo_de_padron: match.tipo_padron });
      resetAndValidate();
    }
  };

  useEffect(() => { fillValuesPadron(padronFound); }, [padronFound]);

  const onRow = (record) => ({
    onClick: () => {
      setSelectedRowKeys([record.id]);
      form.setFieldsValue({
        ...record,
        tipo_de_padron: getTipoDePadronPorContenType(record.content_type_padron),
      });
    },
    onDoubleClick: () => onClickEdit(),
  });

  return (
    <>
      <Spin tip="Cargando..." spinning={loading}>
        {mutable && (
          <FormSubmitCustomControls
            controls={[
              // (padron || !callback) &&
              {
                text: 'Agregar',
                icon: <PlusOutlined />,
                onClick: onClickAdd,
              },
              {
                text: 'Editar',
                icon: <EditOutlined />,
                onClick: onClickEdit,
                disabled: !selectedRowKeys.length,
              },
              (padron || !callback) && {
                text: 'Eliminar',
                icon: <DeleteOutlined />,
                onClick: onClickDelete,
                disabled: !selectedRowKeys.length,
              },
            ].filter((e) => e)}
            style={{ width: '100%' }}
            justify="end"
          />
        )}
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data}
            rowSelection={rowSelection}
            onRow={onRow}
            rowKey="id"
            scroll={{
              y: 600,
              x: columns.reduce((acum, curr) => acum + curr.width, 0),
              scrollToFirstRowOnChange: true,
            }}
          />
        </Col>
      </Spin>
      <Modal
        visible={visible}
        closable={false}
        footer={null}
        title={(
          <FormSubmitCustomControls
            label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Obligación Fiscal`}
            onFinish={onFinish}
            onCancel={onCancel}
            loading={loading}
          />
        )}
        keyboard={!loading}
        maskClosable={!loading}
        onCancel={onCancel}
        forceRender
      >
        <Spin tip="Cargando..." spinning={loading}>
          <Col span={24}>
            <Form
              form={form}
              layout="vertical"
              onFinish={() => onFinish()}
              initialValues={padron ? {
                estatus_de_obligaciones_fiscal: 1,
                fecha_inicio: toMoment(new Date()),
              } : null}
            >
              <Row gutter={10}>
                <Col span={24}>
                  <Form.Item
                    name="obligacion_fiscal"
                    label="Obligación Fiscal"
                    rules={rules.required}
                  >
                    <Select
                      options={obligaciones}
                      onChange={onChangeObligacion}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Form.Item name="tipo_de_padron" hidden><Input /></Form.Item>
                {!padron && (
                  <Col span={24}>
                    <InputSearchPro
                      label={`Búscar ${descripcionPadron}`}
                      name="q"
                      breakPoints={{ md: 24 }}
                      disabled={!tipo_de_padron}
                      rules={rules.required}
                      inputProps={{
                        onPressEnter: () => searchPadron(),
                        onChange: ({ target: { value } }) => {
                          if (!value) {
                            resetAndValidate();
                          }
                        },
                      }}
                      resultProps={{
                        name: 'object_id_padron',
                        label: descripcionPadron,
                        rules: rules.required,
                        dataSource: padronFound,
                        render: (_padron) => `
                          ${_padron?.clave} -
                          ${_padron?.empresa?.nombre_comercial || _padron?.empresa?.contribuyente?.nombre_completo}
                        `,
                        keyLabelRender: false,
                        onClear: resetAndValidate,
                        breakPoints: {
                          md: 24,
                        },
                      }}
                    />
                  </Col>
                )}
                {padronFound && (
                  <>
                    <Col span={12}>
                      <Form.Item
                        name="clave_cuenta_de_padron_obligado"
                        label="Clave"
                      >
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="contribuyente_rfc"
                        label="RFC"
                      >
                        <Input allowClear disabled />
                      </Form.Item>
                    </Col>
                  </>
                )}
                <Col span={24}>
                  <Form.Item
                    name="fecha_inicio"
                    label="Fecha de Inicio"
                    rules={rules.required}
                  >
                    <DatePicker
                      placeholder=""
                      format={DATE_FRONTEND_FORMAT}
                      showTime
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="fecha_fin"
                    label="Fecha Fin"
                    rules={[2, 3].includes(estatus_de_obligaciones_fiscal) ? rules.required : []}
                  >
                    <DatePicker
                      placeholder=""
                      format={DATE_FRONTEND_FORMAT}
                      showTime
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="estatus_de_obligaciones_fiscal"
                    label="Estado"
                    rules={rules.required}
                  >
                    <Select
                      disabled={!!padron && !!fetchCallback}
                      options={estatusDeObligaciones}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="observaciones"
                    label="Observaciones"
                    rules={[2, 3].includes(estatus_de_obligaciones_fiscal) ? rules.required : []}
                  >
                    <Input.TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
                  </Form.Item>
                </Col>
                {!!selectedRowKeys.length && !!padron && (
                  <Col span={24}>
                    <Form.Item
                      name="fecha_ultimo_estado"
                      label="Fecha Último Estado"
                    >
                      <DatePicker
                        placeholder=""
                        format={DATETIME_FRONTEND_FORMAT}
                        showTime
                        disabled={!!fetchCallback}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <Form.Item hidden>
                <Button htmlType="submit" />
              </Form.Item>
            </Form>
          </Col>
        </Spin>
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => {
          setVisibleAlert(false);
          setSelectedRowKeys([]);
        }}
        visible={visibleAlert}
        content=" el registro seleccionado?"
        loading={loading}
      />
    </>
  );
};

const Table = styled(AntTable)`
  .ant-table-thead>tr>th{
    background: transparent;
    border-bottom: 2px solid;
  }
`;

ObligacionesFiscalesContribuyente.propTypes = {
  contribuyente: PropTypes.number,
  permission: PropTypes.shape({
    add: PropTypes.bool.isRequired,
    change: PropTypes.bool.isRequired,
    delete: PropTypes.bool.isRequired,
    export: PropTypes.bool.isRequired,
    import: PropTypes.bool.isRequired,
    view: PropTypes.bool.isRequired,
  }).isRequired,
  tipoDePadron: PropTypes.number,
  callback: PropTypes.func,
  fetchCallback: PropTypes.func,
  padron: PropTypes.shape({ id: PropTypes.number, clave: PropTypes.string }),
  mutable: PropTypes.bool,
};

ObligacionesFiscalesContribuyente.defaultProps = {
  contribuyente: null,
  tipoDePadron: null,
  callback: null,
  fetchCallback: null,
  padron: null,
  mutable: true,
};

export default ObligacionesFiscalesContribuyente;
