import React from 'react';
import { Card, Text } from 'components';

const NoDisponible = () => (
  <Card>
    <Text>
      El servicio de transparencia no se encuentra disponible en este momento, por favor intente
      más tarde.
    </Text>
  </Card>
);

export default NoDisponible;
