import React from 'react';
import {
  Form,
  Input,
  Col,
  Grid,
  Row,
} from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { noSpaces, titleize } from 'utils/formatters';
import { Button, Text, Title } from 'components';
import { DeleteOutlined } from '@ant-design/icons';
import { ENV_NAME, ENV_NAMES_ENUM, CLAVE_CATASTRAL_SEGMENTOS } from 'utils/env';

const { useBreakpoint } = Grid;

const PredioInput = ({ loading, modal }) => {
  const form = Form.useFormInstance();
  const screen = useBreakpoint();
  const segmenentsLengths = {
    localidad: 2,
    sector: 3,
    manzana: 3,
    lote: 3,
    condominio: ENV_NAME === ENV_NAMES_ENUM.NAYARIT ? 5 : 4,
  };
  const fields = Object.keys(segmenentsLengths);
  const innerHTMLFields = [...document.getElementsByClassName('ant-form-item')]
    .filter((t) => fields.includes(t.outerText.toLowerCase()));

  const jump = (evt) => {
    const { target: { value, id }, nativeEvent } = evt;
    if (value?.length === segmenentsLengths[id]
      && fields.includes(id)
      && nativeEvent.inputType === 'insertText'
      && fields.at(-1) !== id) {
      const idx = fields.filter((_, i) => i !== fields.length - 1).indexOf(id);
      const next = innerHTMLFields[idx + 1];
      next?.children[0].children[0].children[0].focus();
    } else if (nativeEvent.inputType === 'deleteContentBackward' && value?.length === 0) {
      const idx = fields.filter((_, i) => i > 0).indexOf(id);
      const prev = innerHTMLFields[idx];
      prev?.children[0].children[0].children[0].focus();
    }
  };

  return (
    <>
      <Row justify="center" style={{ marginBottom: 50 }}>
        <Col>
          <Title level={4}>
            INGRESE LOS DATOS DE SU CUENTA CATASTRAL
          </Title>
        </Col>
        {!modal && (
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Title level={5}>
              NOTA: Solo se podrá hacer un pago por tarjeta diario.
            </Title>
          </Col>
        )}
        <Text strong>
          Ejemplo: 00-000-000-000-000
          {ENV_NAME === ENV_NAMES_ENUM.JUAREZ && '0'}
        </Text>

      </Row>
      {
        CLAVE_CATASTRAL_SEGMENTOS[ENV_NAME] && (
          <Row justify="end">
            <Col>
              <Button
            // $accent
                size="small"
                onClick={() => { form.resetFields(fields); }}
              >
                <Text>Limpiar campos</Text>
                <DeleteOutlined />
              </Button>
            </Col>
          </Row>
        )
      }
      <Form.Item>
        {
          CLAVE_CATASTRAL_SEGMENTOS[ENV_NAME] ? (
            <InputGroup style={{ marginTop: 30 }}>
              <Row justify="space-between" style={{ width: 'inherit' }}>
                {fields.toString().replaceAll(',', ' - ').split(' ').map((e, i) => {
                  const isHypen = e === '-';
                  let output = null;
                  if (isHypen) {
                    output = (
                      <div className="container">
                        <span className="separador">
                          -
                        </span>
                      </div>
                    );
                  } else {
                    output = (
                      <Form.Item
                        name={e}
                        label={titleize(e)}
                        rules={[{ required: true }]}
                        hasFeedback={false}
                        normalize={(value) => noSpaces(value.replace(/\D/g, ''))}
                      >
                        <Input
                          placeholder={screen.xs ? e.toUpperCase().slice(0, 3) : ''}
                          maxLength={segmenentsLengths[e]}
                          disabled={loading}
                          onChange={jump}
                          style={{ height: 40 }}
                          onPaste={(event) => {
                            const pastedText = (event.clipboardData
                            || window.clipboardData).getData('text');
                            if (/\d{2}-\d{3}-\d{3}-\d{3}-\d+/.test(pastedText)) {
                              event.preventDefault();
                              const segments = pastedText.split('-');
                              const entries = fields.map((f, idx) => [f, noSpaces(segments[idx])]);
                              const values = Object.fromEntries(entries);
                              form.setFieldsValue(values);
                            }
                          }}
                        />
                      </Form.Item>
                    );
                  }
                  return (
                  // eslint-disable-next-line react/no-array-index-key
                    <Col key={i + 1} className={`${isHypen ? 'separador' : 'input'}-col`}>
                      {output}
                    </Col>
                  );
                })}
              </Row>
              <br />
              <br />
            </InputGroup>
          ) : (
            <Row justify="space-between" style={{ width: 'inherit' }}>
              <Col span={24}>
                <Form.Item
                  name="q"
                  label="Clave Catastral"
                  rules={[{ required: true }]}
                  hasFeedback={false}
                >
                  <Input
                    placeholder={screen.xs ? 'CLAVE' : ''}
                    disabled={loading}
                    style={{ height: 40 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )
        }
      </Form.Item>
    </>
  );
};

PredioInput.propTypes = {
  loading: PropTypes.bool,
  modal: PropTypes.bool,
};

PredioInput.defaultProps = {
  loading: false,
  modal: false,
};

const InputGroup = styled(Input.Group)`
  display: flex;
  flex-wrap: wrap;

  &:last-child {
    justify-content: center;
  }

  .ant-form-item-explain-error{
    display: none;
  }

  .separador {
    font-size: 20px;
    position: absolute;
    margin: 40px 0px;
    font-weight: bold;
  } 

  .container {
    display: grid;
    justify-items: center;
  }

  .input-condominio {
    display: none;
  }

  .ant-form-item-control {
    align-self: center;
  }

  .ant-form-item-control {
    max-width: 80px !important;
  }

  .ant-input-affix-wrapper-status-success {
    border-color: #52c41a;
  }

  .ant-input-affix-wrapper-status-error {
    border-color: #f5222d;
  }

  .ant-input-suffix {
    display: none;
  }

  @media only screen and (max-width: 575px) {
    .ant-form-item-control {
      max-width: 15vw !important;
    }
    .ant-form-item-label {
      display: none;
    }
    .ant-form-item {
      .ant-form-item-control::after {
        display: none;
      }
    }
    input {
      font-size: 12px;
    }
    .ant-form-item-explain-error {
      display: none;
    }
  }

  @media only screen and (max-width: 575px) {
    .separador-col {
      display: none;
    }
  }
`;

export default PredioInput;
