import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Modal, Row, Button as AntButton,
} from 'antd';
import { Button, Text, Title } from 'components';
import { WarningOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectEntidad } from 'store/auth';
import { useNavigate } from 'react-router';

const AlertItem = ({ alerta }) => (
  <li>
    <Text>
      {alerta.descripcion}
    </Text>
    <br />
    <span>
      {alerta.observaciones}
    </span>
  </li>
);

AlertItem.propTypes = {
  alerta: PropTypes.shape({
    id: PropTypes.number,
    descripcion: PropTypes.string,
    observaciones: PropTypes.string,
  }).isRequired,
};

const ModalAlertas = ({
  restrictivas,
  informativas,
  callback,
}) => {
  const navigation = useNavigate();
  const entidad = useSelector(selectEntidad);
  const restrictivasLen = restrictivas.length;
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (restrictivas.length || informativas.length) {
      setVisible(true);
    }
  }, [restrictivas, informativas]);

  const onCancel = () => {
    setVisible(false);
    if (restrictivas?.length) {
      callback();
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={(
        <Row align="middle" gutter={10} style={{ gap: 10 }}>
          <WarningOutlined style={{ fontSize: 20 }} className="warning" />
          <Title level={3}>
            Alertas
          </Title>
        </Row>
      )}
      centered
      closable={false}
      footer={(
        <Row gutter={[10, 10]} justify="end">
          <Col xs={24} sm={24} md={8}>
            <Button block onClick={onCancel}>Cerrar</Button>
          </Col>
          {!restrictivas.length && (
            <Col xs={24} sm={24} md={8}>
              <Button block $accent onClick={onCancel}>Aceptar</Button>
            </Col>
          )}
        </Row>
      )}
    >
      <div>
        {!!restrictivas.length && (
          <>
            <Text>
              USTED DEBE PRESENTARSE EN ALGUNA OFICINA DE RECAUDACIÓN DE LA
              {' '}
              {entidad.razon_social?.toUpperCase()}
              {' '}
              A CONSULTAR EL DETALLE.
            </Text>
            <br />
            <AntButton
              type="link"
              onClick={() => {
                navigation('/oficinas');
              }}
            >
              Ver oficinas
            </AntButton>
            <br />
            <br />
            <Title level={4}>
              Debido a
              {' '}
              {restrictivasLen > 1 ? 'las siguientes alertas' : 'la siguiente alerta'}
              {' '}
              no es posible realizar el pago/declaración:
            </Title>
            <ul>
              {restrictivas.map((alerta) => (<AlertItem key={alerta.id} alerta={alerta} />))}
            </ul>
          </>
        )}
      </div>
      <div>
        {!!informativas.length && (
          <>
            <Title level={4}>Alertas informativas</Title>
            <ul>
              {informativas.map((alerta) => (<AlertItem key={alerta.id} alerta={alerta} />))}
            </ul>
          </>
        )}

      </div>
    </Modal>
  );
};

ModalAlertas.propTypes = {
  restrictivas: PropTypes.arrayOf(PropTypes.shape({})),
  informativas: PropTypes.arrayOf(PropTypes.shape({})),
  callback: PropTypes.func.isRequired,
};

ModalAlertas.defaultProps = {
  restrictivas: [],
  informativas: [],
};

export default ModalAlertas;
