import React from 'react';

import RegisterPadron from 'views/Payments/components/RegisterPadron';
import { useNavigate } from 'react-router';
import { postSolicitarCodigoDeAcceso } from 'api/auth';
import useQuery from 'hooks/useQuery';
import { AUTH_CONTRIBUYENTE } from 'utils/env';

const SignIn = () => {
  const navigate = useNavigate();
  const redirect = useQuery(['redirect']);
  const redirectParam = redirect ? `?redirect=${redirect}` : '';
  const toLogin = '/mi-cuenta/login'.concat(redirectParam);

  const register = async (registered) => {
    if (registered) {
      const values = AUTH_CONTRIBUYENTE ? {
        rfc: registered.rfc,
      } : {
        numero_de_celular: registered.numero_de_celular,
        lada: registered.lada,
      };
      const success = await postSolicitarCodigoDeAcceso(values);
      if (success) {
        navigate(toLogin);
      }
    }
  };

  return (
    <RegisterPadron
      tipoDePadron={AUTH_CONTRIBUYENTE ? 15 : 1}
      callback={register}
      onCancel={() => navigate('/mi-cuenta')}
      general={!AUTH_CONTRIBUYENTE}
    />
  );
};

export default SignIn;
