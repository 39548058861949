import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { normalizeText } from 'utils/normalizers';
import {
  Button,
  COLORS,
  Text,
  Title,
} from 'components';
import { Input, List, Modal } from 'antd';
import styled from 'styled-components';
import ArcGisMap, { validateGIS } from 'views/Tramites/GPM/components/ArcGisMap';
import { GISInfoResult } from 'views/Payments/components/SearchForm';

const SelectableList = ({
  dataSource: _initialDataSource,
  emptyListDescriptor,
  getter,
  titleNormalizer,
  descriptionNormalizer,
  onSelect,
  search,
  selected,
  listWrapperStyle,
  title,
  withGISInfo,
  departamento,
}) => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [claveCatastral, setClaveCatastral] = useState();

  const normalizeData = async (data) => {
    const normalizer = (item) => ({
      ...item,
      title: titleNormalizer(item),
      description: descriptionNormalizer(item),
    });
    if (withGISInfo) {
      const promises = (data.results || data).map((e) => validateGIS(e.clave_catastral_municipal));
      const responses = await Promise.all(promises);
      data.results?.forEach((_, idx) => {
        // eslint-disable-next-line prefer-destructuring, no-param-reassign
        data.results[idx].hasGISInfo = responses[idx][0];
        // eslint-disable-next-line prefer-destructuring, no-param-reassign
        data.results[idx].disabled = !data.results[idx].hasGISInfo;
      });
    }
    if (data.results) {
      return { ...data, results: data.results.map(normalizer) };
    }
    return data.map(normalizer);
  };

  useEffect(() => {
    const normalize = async () => {
      const _normalized = await normalizeData(_initialDataSource);
      setDataSource(_normalized);
      setFiltered(_normalized.results || _normalized);
    };
    normalize();
  }, [_initialDataSource]);

  const onSearch = (_val) => {
    if (_val) {
      const val = normalizeText(_val);
      const _filtered = (dataSource.results || dataSource).filter((item) => {
        const _props = ['title', 'description'];
        return _props.some((prop) => normalizeText(item[prop]).includes(val));
      });
      setFiltered(_filtered);
    } else {
      setFiltered(dataSource.results || dataSource);
    }
  };

  const onLoadMore = async () => {
    setLoading(true);
    const url = new URL(dataSource.next);
    const _dataSource = await getter(url.searchParams);
    if (_dataSource) {
      const _normalized = await normalizeData(_dataSource.results);
      _dataSource.results = [
        ...dataSource.results,
        ..._normalized,
      ];
      setDataSource(_dataSource);
      setFiltered(_dataSource.results);
    }
    setLoading(false);
  };

  const loadMore = dataSource.next && getter ? (
    <div
      style={{
        textAlign: 'center',
        marginTop: 12,
        height: 32,
        lineHeight: '32px',
      }}
    >
      <Button onClick={onLoadMore} loading={loading}>
        <Text>
          Cargar más
        </Text>
      </Button>
    </div>
  ) : null;

  const onClickGis = (e, item) => {
    e.stopPropagation();
    setClaveCatastral(item.clave_catastral_municipal);
  };

  return (
    <>
      {title && (
        <Title level={4} $accent style={{ marginBottom: 20 }}>
          {title}
        </Title>
      )}
      {!!search && (
        <div style={{ width: '100%', ...search.wrapperStyle }}>
          <Input.Search
            placeholder={search?.placeholder || 'Buscar'}
            onChange={(e) => {
              e.preventDefault();
              onSearch(e.target.value);
            }}
            onPressEnter={(e) => e.preventDefault()}
            style={{ width: '100%', ...search?.style }}
          />
          {!!(dataSource.count ?? dataSource.length) && (
            <Text style={{
              fontSize: 14,
              display: 'block',
              textAlign: 'right',
              paddingRight: 5,
            }}
            >
              Mostrando
              {' '}
              {filtered.length}
              {' '}
              {`resultado${filtered.length !== 1 ? 's' : ''}`}
              {' '}
              de
              {' '}
              {`${dataSource.count ?? dataSource.length}`}
            </Text>
          )}
        </div>
      )}
      <div style={{
        width: '100%',
        height: '60vh',
        overflowY: 'scroll',
        ...listWrapperStyle,
      }}
      >
        <List
          itemLayout="horizontal"
          loadMore={loadMore}
          dataSource={filtered}
          rowKey="id"
          style={{ width: '100%' }}
          locale={{
            emptyText: (
              <Text strong>
                {(dataSource.length || dataSource.count)
                  ? 'No se encontraron coincidencias' : `No hay ${emptyListDescriptor}`}
              </Text>
            ),
          }}
          renderItem={(item) => (
            <List.Item>
              <ListItemMeta
                style={{ cursor: item.disabled ? 'not-allowed' : 'pointer' }}
                $selected={selected === item.id}
                onClick={() => !item.disabled && onSelect(item)}
                title={item.title}
                description={!withGISInfo ? item.description : (
                  <>
                    {item.description}
                    <br />
                    <GISInfoResult
                      hasGISInfo={item.hasGISInfo}
                      onClick={(e) => onClickGis(e, item)}
                      style={{ fontSize: 'inherit' }}
                      departamento={departamento}
                    />
                  </>
                )}
              />
            </List.Item>
          )}
        />
      </div>
      {withGISInfo && (
        <Modal
          title={(
            <>
              Información de georreferencia
              {' '}
              {claveCatastral}
            </>
          )}
          visible={!!claveCatastral}
          width="55vw"
          footer={null}
          onCancel={() => setClaveCatastral()}
          bodyStyle={{ padding: '0px 24px' }}
          destroyOnClose
        >
          {claveCatastral && (
            <ArcGisMap
              clave_catastral_municipal={claveCatastral}
              layersVisible={false}
              takeScreenshot={false}
              width="50vw"
              heigth="50vw"
              withLabel={false}
            />
          )}
        </Modal>
      )}
    </>
  );
};

SelectableList.propTypes = {
  dataSource: PropTypes.oneOfType([
    PropTypes.shape({
      count: PropTypes.number.isRequired,
      next: PropTypes.string,
      results: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  emptyListDescriptor: PropTypes.string,
  getter: PropTypes.func,
  titleNormalizer: PropTypes.func.isRequired,
  descriptionNormalizer: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  search: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({
    placeholder: PropTypes.string,
    wrapperStyle: PropTypes.shape({}),
  })]),
  selected: PropTypes.number,
  listWrapperStyle: PropTypes.shape({}),
  title: PropTypes.string,
  withGISInfo: PropTypes.bool,
  departamento: PropTypes.shape({}),
};

SelectableList.defaultProps = {
  dataSource: [],
  emptyListDescriptor: 'datos',
  search: true,
  getter: null,
  selected: null,
  listWrapperStyle: null,
  title: null,
  withGISInfo: false,
  departamento: null,
};

const ListItemMeta = styled(List.Item.Meta)`
  padding: 5px 10px;
  &:hover {
    * {
      color: ${COLORS.white};
      font-weight: bold;
    }
    background-color: ${COLORS.accent};
    border-radius: 12px;
  }
  ${(props) => props.$selected && `
  * {
      color: ${COLORS.white};
      font-weight: bold;
    }
    background-color: ${COLORS.accent};
    border-radius: 12px;
  `}
`;

export default SelectableList;
