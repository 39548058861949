import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import SelectableList from 'views/Tramites/GPM/components/SelectableList';
import { getEnumValues, solicitantesEnum } from 'views/Tramites/GPM/enums';
import { useSelector } from 'react-redux';
import { selectContribuyentes, selectUser } from 'store/auth';
import { ciudadanoToString, contribuyenteToString, getPadronKey } from 'utils/padronesToString';

const ModalSeleccionarSolicitante = ({
  visible,
  onSelect,
  onCancel,
  tipoPadronSolicitante,
  padronSolicitante,
}) => {
  const user = useSelector(selectUser);
  const contribuyentes = useSelector(selectContribuyentes);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title="Selección de solicitante"
      footer={null}
    >
      {tipoPadronSolicitante === solicitantesEnum.CONTRIBUYENTE && (
        <SelectableList
          dataSource={[user]}
          titleNormalizer={(p) => `Ciudadano: ${getPadronKey(p, solicitantesEnum.CIUDADANO)}`}
          descriptionNormalizer={(p) => ciudadanoToString(p, solicitantesEnum.CIUDADANO)}
          onSelect={(item) => onSelect(item, solicitantesEnum.CIUDADANO)}
          selected={tipoPadronSolicitante === solicitantesEnum.CONTRIBUYENTE
            ? padronSolicitante : null}
          search={false}
          listWrapperStyle={{ height: 'auto', marginBottom: 10 }}
        />
      )}
      <SelectableList
        dataSource={contribuyentes}
        titleNormalizer={(p) => getPadronKey(p, solicitantesEnum.CONTRIBUYENTE)}
        descriptionNormalizer={(p) => contribuyenteToString(p, solicitantesEnum.CONTRIBUYENTE)}
        onSelect={(item) => onSelect(item, solicitantesEnum.CONTRIBUYENTE)}
        selected={tipoPadronSolicitante === solicitantesEnum.CONTRIBUYENTE
          ? padronSolicitante : null}
        search={{ placeholder: 'Buscar contribuyente' }}
      />
    </Modal>
  );
};

ModalSeleccionarSolicitante.propTypes = {
  visible: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  tipoPadronSolicitante: PropTypes.oneOf(getEnumValues(solicitantesEnum)).isRequired,
  padronSolicitante: PropTypes.number.isRequired,
};

ModalSeleccionarSolicitante.defaultProps = {
  visible: false,
};

export default ModalSeleccionarSolicitante;
