import { message } from 'antd';
import http from 'api';
import { formatReceived, formatSent } from 'utils/formatters';
import _logger from 'utils/logger';
import { toMoment } from 'utils/normalizers';

export const baseURI = 'empresas/obligaciones-fiscales-de-contribuyentes/';

/**
 * GET Obligaciones Fiscales
 * @param {Object} params
 * @returns {Promise<object[]>}
 */
export const getObligacionesFiscalesContribuyente = async (params = null) => {
  try {
    const response = await http.get(baseURI, { params });
    return formatReceived(response.data);
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Obligacion Fiscal
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getObligacionFiscalContribuyente = async (id) => {
  try {
    const response = await http.get(`${baseURI}${id}`);
    return formatReceived(response.data);
  } catch (err) {
    _logger(err);
    return null;
  }
};

/**
 * POST Obligacion Fiscal
  * @param {object} values
  * @returns {Promise<object|boolean>}
 */
export const postObligacionFiscalContribuyente = async (values) => {
  try {
    const response = await http.post(baseURI, formatSent(values, {}, true));
    message.success(response || 'Agregado correctamente');
    return formatReceived(response.data);
  } catch (err) {
    _logger(err);
    return false;
  }
};

/**
 * PATCH Obligacion Fiscal
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchObligacionFiscalContribuyente = async (id, values) => {
  try {
    // eslint-disable-next-line no-param-reassign
    values.fecha_ultimo_estado = toMoment(new Date());
    const response = await http.patch(`${baseURI}${id}`, formatSent(values, {}, true));
    message.success(response || 'Editado correctamente');
    return formatReceived(response.data);
  } catch (err) {
    _logger(err);
    return false;
  }
};
/**
 * DELETE Obligacion Fiscal
 * @param {number} id
 * @returns {Promise<object|boolean>}
 */
export const deleteObligacionFiscalContribuyente = async (id) => {
  try {
    const response = await http.delete(`${baseURI}${id}/`);
    message.success(response || 'Eliminado correctamente');
    return true;
  } catch (err) {
    _logger(err);
    return false;
  }
};
