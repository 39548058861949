/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ArrowLeftOutlined,
  LoadingOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Grid,
  Card,
  message,
  Tooltip,
  DatePicker,
} from 'antd';
import {
  Aviso,
  Button,
  TerminosYCondiciones,
  Text,
  Title,
} from 'components';
import LadaNumero from 'components/LadaNumero';
import Select from 'components/Select';
import { titleize, toUpper, toUpperFromConfig } from 'utils/formatters';
import { getRegimenesCapitales, getTiposDePersonas } from 'api/catalogos';
import _logger from 'utils/logger';
import {
  gettersListMap,
  postCiudadano,
  postContribuyente,
} from 'api/padrones';
import { CURP_VALIDATOR, PHONE_NUMBER, RFC_VALIDATOR } from 'utils/patterns';
import { useLocation } from 'react-router';
import { padronesEnum } from 'views/Tramites/GPM/enums';
import { getEstadosCiviles, getGeneros } from 'api/usuarios';
import AvatarUploader from 'components/AvatarUploader';
// import { ENV_NAME, ENV_NAMES_ENUM } from 'utils/env';

const { useBreakpoint } = Grid;

const trueFalseOptions = [
  { descripcion: 'Si', id: true },
  { descripcion: 'No', id: false },
];

const ValidatorLoading = () => (
  <Tooltip title="Validando información">
    <LoadingOutlined />
  </Tooltip>
);

const CURP = ({
  isCiudadano,
  foregin,
  tipoDePersona,
  general,
  rules,
  loading,
  disabled,
  loadingCURP,
  order,
}) => (((isCiudadano && !foregin) || (!isCiudadano && tipoDePersona !== 2)) && !general) && (
  <Col xs={24} sm={24} md={24} lg={8} order={order || (isCiudadano ? 1 : 2)}>
    <Form.Item
      label="CURP"
      name={isCiudadano ? 'CURP' : 'curp'}
      rules={rules.curp}
      normalize={toUpper}
      validateTrigger={['onBlur', 'onChange']}
    >
      <Input
        maxLength={18}
        disabled={loading || foregin || disabled}
        suffix={loadingCURP ? <ValidatorLoading /> : null}
      />
    </Form.Item>
  </Col>
);

const RFC = ({
  rules,
  tipoDePersona,
  loading,
  disabled,
  order,
  loadingRFC,
}) => (
  <Col xs={24} sm={24} md={24} lg={8} order={order}>
    <Form.Item
      label="RFC"
      name="rfc"
      rules={rules.rfc}
      normalize={toUpper}
    >
      <Input
        maxLength={tipoDePersona === 2 ? 12 : 13}
        disabled={loading || disabled}
        suffix={loadingRFC ? <ValidatorLoading /> : null}
      />
    </Form.Item>
  </Col>
);

const INE = ({
  isCiudadano,
  rules,
  tipoDePersona,
  loading,
  disabled,
  order,
}) => isCiudadano && (
  <Col xs={24} sm={24} md={24} lg={8} order={order}>
    <Form.Item
      label="INE"
      name="ine"
      rules={rules.ine}
      normalize={toUpper}
    >
      <Input maxLength={tipoDePersona === 2 ? 12 : 13} disabled={loading || disabled} />
    </Form.Item>
  </Col>
);

const FechaDeNacimiento = ({
  isCiudadano,
  loading,
  disabled,
  order,
}) => isCiudadano && (
  <Col xs={24} sm={24} md={24} lg={8} order={order}>
    <Form.Item
      name="fecha_nacimiento"
      label="Fecha de nacimiento"
      normalize={toUpper}
    >
      <DatePicker placeholder="" disabled={loading || disabled} />
    </Form.Item>
  </Col>
);

const FormItems = ({
  requiredCURP,
  rules,
  setForegin,
  loading,
  foregin,
  loadingCURP,
  tiposDePersonas,
  tipoDePersona,
  regimenesCapitales,
  form,
  ladaFormItemName,
  numberFormItemName,
  loadingCelular,
  loadingEmail,
  disabled,
  disabledContactInfo,
  isCiudadano,
  generos,
  estadosCiviles,
  general,
  loadingRFC,
}) => (
  <>
    {!requiredCURP && (
      <Col xs={24} sm={24} md={24} lg={8} order={1}>
        <Form.Item
          label="¿Es extranjero?"
          name="es_extranjero"
          rules={rules.required}
        >
          <Select
            onChange={setForegin}
            disabled={loading || disabled}
            options={trueFalseOptions}
            allowClear={false}
          />
        </Form.Item>
      </Col>
    )}
    <CURP
      isCiudadano={isCiudadano}
      foregin={foregin}
      tipoDePersona={tipoDePersona}
      general={general}
      rules={rules}
      loading={loading}
      disabled={disabled}
      loadingCURP={loadingCURP}
    />
    {!isCiudadano && !general && (
      <>
        <Col xs={24} sm={24} md={24} lg={8} order={1}>
          <Form.Item
            label="Tipo de persona"
            name="tipo_de_persona"
            rules={rules.required}
          >
            <Select
              disabled={loading || disabled}
              options={tiposDePersonas}
            />
          </Form.Item>
        </Col>
        <RFC
          rules={rules}
          tipoDePersona={tipoDePersona}
          loading={loading}
          disabled={disabled}
          order={1}
          loadingRFC={loadingRFC}
        />
      </>
    )}
    {(isCiudadano || tipoDePersona !== 2) ? (
      <>
        <Col xs={24} sm={24} md={24} lg={8} order={2}>
          <Form.Item
            label="Nombre(s)"
            name="nombre"
            rules={rules.required}
            normalize={(v) => toUpperFromConfig(v, titleize)}
          >
            <Input disabled={loading || disabled} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} order={3}>
          <Form.Item
            label="Apellido Paterno"
            name="apellido_paterno"
            rules={rules.required}
            normalize={(v) => toUpperFromConfig(v, titleize)}
          >
            <Input disabled={loading || disabled} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} order={4}>
          <Form.Item
            label="Apellido Materno"
            name="apellido_materno"
            normalize={(v) => toUpperFromConfig(v, titleize)}
          >
            <Input disabled={loading || disabled} />
          </Form.Item>
        </Col>
        {isCiudadano && general && (
          <>
            <Col md={8} sm={24} xs={24} order={5}>
              <Form.Item
                label="Género"
                name="genero"
              >
                <Select options={generos} disabled={loading || disabled} />
              </Form.Item>
            </Col>
            <Col md={8} sm={24} xs={24} order={6}>
              <Form.Item
                label="Estado civil"
                name="estado_civil"
              >
                <Select options={estadosCiviles} disabled={loading || disabled} />
              </Form.Item>
            </Col>
          </>
        )}
      </>
    ) : (
      <>
        <Col xs={24} sm={24} md={24} lg={8} order={3}>
          <Form.Item
            label="Régimen Capital"
            name="regimen_capital"
            rules={rules.required}
          >
            <Select
              disabled={loading || disabled}
              options={regimenesCapitales}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={16} xl={8} order={3}>
          <Form.Item
            label="Razón Social"
            name="razon_social"
            tooltip="No incluir las siglas de régimen capital"
            rules={rules.required}
            normalize={(v) => toUpperFromConfig(v, titleize)}
          >
            <Input disabled={loading || disabled} />
          </Form.Item>
        </Col>
      </>
    )}
    <LadaNumero
      form={form}
      names={{
        lada: ladaFormItemName,
        numero_de_telefono: numberFormItemName,
      }}
      order={7}
      rules={rules}
      suffix={loadingCelular ? <ValidatorLoading /> : null}
      disabled={loading || disabledContactInfo}
    />
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={8}
      order={8}
    >
      <Form.Item
        label="Correo electrónico"
        name={isCiudadano ? 'email' : 'correo_electronico'}
        rules={rules.email}
        validateTrigger={['onBlur', 'onChange']}
      >
        <Input
          disabled={loading || disabledContactInfo}
          suffix={loadingEmail ? (<ValidatorLoading />) : null}
        />
      </Form.Item>
    </Col>
    {/* {isCiudadano && (
      <Col xs={24} sm={24} md={8} order={9}>
        <Form.Item
          name="email_alternativo"
          rules={rules.email_alternativo}
          label="Correo alternativo"
          normalize={(v) => toUpperFromConfig(v)}
        >
          <Input allowClear disabled={loading || disabled} />
        </Form.Item>
      </Col>
    )} */}
    <Form.Item hidden>
      <Button htmlType="submit" />
    </Form.Item>
  </>
);

const RegisterPadron = ({
  callback,
  tipoDePadron,
  onCancel,
  onlyItems,
  rulesRFC,
  rulesCURP,
  disabled,
  disabledContactInfo,
  selfId,
  general,
  identidad,
}) => {
  const { pathname } = useLocation();
  const isCiudadano = tipoDePadron === padronesEnum.CIUDADANO;
  const ladaFormItemName = isCiudadano ? 'lada' : 'lada_celular';
  const numberFormItemName = isCiudadano ? 'numero_de_celular' : 'telefono_celular';
  const screens = useBreakpoint();
  const form = onlyItems ? Form.useFormInstance() : Form.useForm()[0];
  const tipoDePersona = Form.useWatch('tipo_de_persona', form);
  const [foregin, setForegin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tiposDePersonas, setTiposDePersonas] = useState([]);
  const [regimenesCapitales, setRegimenesCapitales] = useState([]);
  const [acceptedTermns, setAcceptedTermns] = useState(false);
  const [loadingCURP, setLoadingCURP] = useState(false);
  const [loadingRFC, setLoadingRFC] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [loadingCelular, setLoadingCelular] = useState(false);
  const [generos, setGeneros] = useState([]);
  const [estadosCiviles, setEstadosCiviles] = useState([]);

  const isInMicuenta = pathname.includes('mi-cuenta');
  const inInAppointments = pathname.includes('citas');

  const padronString = isCiudadano ? 'Ciudadano' : 'Contribuyente';

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const promises = [
        getTiposDePersonas(),
        getRegimenesCapitales(),
      ];
      if (general) {
        promises.push(getGeneros());
        promises.push(getEstadosCiviles());
      }
      const [
        _tiposDePersonas,
        _regimenesCapitales,
        _generos = [],
        _estadosCiviles = [],
      ] = await Promise.all(promises);
      setGeneros(_generos);
      setEstadosCiviles(_estadosCiviles);
      setTiposDePersonas(_tiposDePersonas);
      setRegimenesCapitales(_regimenesCapitales);
      setLoading(false);
    };
    fetch();
  }, []);

  const onFinish = async () => {
    try {
      setLoading(true);
      const values = form.getFieldsValue();
      if (!acceptedTermns) {
        message.warn('Debe aceptar el aviso de privacidad y los términos de uso', 2.5);
        setLoading(false);
        return;
      }
      await form.validateFields();
      const registered = await (isCiudadano
        ? postCiudadano : postContribuyente)(values);
      if (registered) {
        setLoading(false);
        await callback({
          ...registered,
          ...values,
        });
      }
      setLoading(false);
    } catch (err) {
      _logger(err);
      setLoading(false);
    }
  };

  const uniqueValidator = {
    validator: async ({ field }) => {
      const values = form.getFieldsValue();
      const emailField = isCiudadano ? 'email' : 'correo_electronico';
      if (field === emailField) {
        const email = values[emailField];
        if (!email) return;
        setLoadingEmail(true);
        const { results: [exist] } = await gettersListMap[tipoDePadron]({ [emailField]: email });
        setLoadingEmail(false);
        if (exist && exist.id !== selfId) {
          throw new Error(`Ya existe un ${isCiudadano ? 'ciudadano' : 'contribuyente'} con este correo electrónico`);
        }
        return;
      }
      if (field === numberFormItemName) {
        const numero = values[numberFormItemName];
        if (!numero) return;
        if (!numero?.toString()?.length === 10) {
          throw new Error('Ingrese un número celular correcto');
        }
        setLoadingCelular(true);
        // eslint-disable-next-line max-len
        const { results: [exist] } = await gettersListMap[tipoDePadron]({ [numberFormItemName]: numero });
        setLoadingCelular(false);
        if (exist && exist.id !== selfId) {
          throw new Error(`Ya existe un ${isCiudadano ? 'ciudadano' : 'contribuyente'} con este número de celular`);
        }
        return;
      }
      const curpField = isCiudadano ? 'CURP' : 'curp';
      if (field === curpField) {
        const curp = values[curpField];
        if (!curp) return;
        await CURP_VALIDATOR(tipoDePersona || 1).validator(field, curp);
        setLoadingCURP(true);
        const { results: [exist] } = await gettersListMap[tipoDePadron]({ [curpField]: curp });
        setLoadingCURP(false);
        if (exist && exist.id !== selfId) {
          throw new Error(`Ya existe un ${isCiudadano ? 'ciudadano' : 'contribuyente'} con esta CURP`);
        }
        return;
      }
      if (field === 'rfc') {
        if (!values.rfc) return;
        await RFC_VALIDATOR(tipoDePersona || 1).validator(field, values.rfc);
        setLoadingRFC(true);
        const { results: [exist] } = await gettersListMap[tipoDePadron]({ rfc: values.rfc });
        setLoadingRFC(false);
        if (exist && exist.id !== selfId) {
          throw new Error(`Ya existe un ${isCiudadano ? 'ciudadano' : 'contribuyente'} con este RFC`);
        }
      }
    },
  };

  const requiredCURP = isInMicuenta || inInAppointments;

  const requiredRule = { required: true };

  const rules = {
    required: [requiredRule],
    email: [
      requiredRule,
      { type: 'email' },
      uniqueValidator,
    ],
    email_alternativo: [
      requiredRule,
      { type: 'email' },
    ],
    [numberFormItemName]: [
      requiredRule,
      uniqueValidator,
      {
        pattern: PHONE_NUMBER,
        message: 'Ingrese un número de celular válido',
      },
    ],
    lada: [
      {
        ...requiredRule,
        message: 'Requerido',
      },
      uniqueValidator,
    ],
    curp: rulesCURP || [
      { required: (isCiudadano && !foregin) },
      uniqueValidator,
    ],
    rfc: rulesRFC || [
      requiredRule,
      uniqueValidator,
    ],
  };

  if (onlyItems) {
    if (identidad) {
      return (
        <>
          {isCiudadano && (
            <Col xs={24} sm={24} md={8} order={1}>
              <AvatarUploader
                form={form}
                imageURL={form.getFieldValue('foto')}
                disabled={loading || disabled}
              />
            </Col>
          )}
          <RFC
            rules={rules}
            tipoDePersona={tipoDePersona}
            loading={loading}
            disabled={disabled}
            order={2}
            loadingRFC={loadingRFC}
          />
          <CURP
            isCiudadano={isCiudadano}
            foregin={foregin}
            tipoDePersona={tipoDePersona}
            general={general}
            rules={rules}
            loading={loading}
            disabled={disabled}
            loadingCURP={loadingCURP}
            order={3}
          />
          <INE
            isCiudadano={isCiudadano}
            rules={rules}
            tipoDePersona={tipoDePersona}
            loading={loading}
            disabled={disabled}
            order={4}
          />
          <FechaDeNacimiento
            isCiudadano={isCiudadano}
            loading={loading}
            disabled={disabled}
            order={5}
          />
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </>
      );
    }

    return (
      <FormItems
        requiredCURP={requiredCURP}
        rules={rules}
        setForegin={setForegin}
        loading={loading}
        foregin={foregin}
        loadingCURP={loadingCURP}
        tiposDePersonas={tiposDePersonas}
        tipoDePersona={tipoDePersona}
        regimenesCapitales={regimenesCapitales}
        form={form}
        numberFormItemName={numberFormItemName}
        ladaFormItemName={ladaFormItemName}
        loadingCelular={loadingCelular}
        loadingEmail={loadingEmail}
        disabled={disabled}
        disabledContactInfo={disabledContactInfo}
        isCiudadano={isCiudadano}
        generos={generos}
        estadosCiviles={estadosCiviles}
        general={general}
        loadingRFC={loadingRFC}
      />
    );
  }

  return (
    <Card
      title={(
        <Title level={2} $accent>
          Alta de
          {' '}
          {padronString}
        </Title>
      )}
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
      }}
      bordered
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{ width: '100%' }}
        initialValues={{
          lada: 34,
          tipo_de_persona: 2,
          es_extranjero: false,
        }}
      >
        <Row gutter={10}>
          <FormItems
            requiredCURP={requiredCURP}
            rules={rules}
            setForegin={setForegin}
            loading={loading}
            foregin={foregin}
            loadingCURP={loadingCURP}
            tiposDePersonas={tiposDePersonas}
            tipoDePersona={tipoDePersona}
            regimenesCapitales={regimenesCapitales}
            form={form}
            numberFormItemName={numberFormItemName}
            ladaFormItemName={ladaFormItemName}
            loadingCelular={loadingCelular}
            loadingEmail={loadingEmail}
            disabledContactInfo={disabledContactInfo}
            isCiudadano={isCiudadano}
            generos={generos}
            estadosCiviles={estadosCiviles}
            general={general}
            loadingRFC={loadingRFC}
          />
          <Col span={24} order={10}>
            <Checkbox
              onChange={({ target: { checked } }) => setAcceptedTermns(checked)}
              checked={acceptedTermns}
              disabled={loading}
            >
              <Text>
                Conozco el
                {' '}
                <Aviso />
                {' '}
                y acepto los
                {' '}
                <TerminosYCondiciones />
                {' '}
                del portal
              </Text>
            </Checkbox>
          </Col>
        </Row>
        <br />
        <Row justify="space-between">
          <Col xs={24} sm={24} md={8} order={screens.md ? 1 : 2}>
            <Form.Item>
              <Button
                block={!screens.md}
                loading={loading}
                onClick={onCancel}
              >
                <ArrowLeftOutlined />
                Regresar
              </Button>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} order={screens.md ? 2 : 1}>
            <Form.Item>
              <Button
                block={!screens.md}
                htmlType="submit"
                loading={loading}
                style={{ float: 'right' }}
                $accent
              >
                Guardar
                <SaveOutlined />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

RegisterPadron.propTypes = {
  onCancel: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  tipoDePadron: PropTypes.number.isRequired,
  onlyItems: PropTypes.bool,
  rulesRFC: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
  disabledContactInfo: PropTypes.bool,
  selfId: PropTypes.number,
  general: PropTypes.bool,
  identidad: PropTypes.bool,
};

RegisterPadron.defaultProps = {
  onlyItems: false,
  disabled: false,
  disabledContactInfo: false,
  rulesRFC: null,
  selfId: null,
  general: false,
  identidad: false,
};

export default RegisterPadron;
