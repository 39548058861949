/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Card as AntCard,
  Form as AntForm,
  Grid,
  Tabs as AntTabs,
} from 'antd';
import {
  BankOutlined,
  ClockCircleOutlined,
  FileTextOutlined,
  LeftOutlined,
  MenuUnfoldOutlined,
  ScheduleOutlined,
} from '@ant-design/icons';
import { getPlantillasDeTramites, patchVistas } from 'api/tramites';
import {
  Button,
  COLORS,
  Text,
} from 'components';
import {
  Navigate,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { fetchCatalogosRequisitos } from 'views/Tramites/GPM/components/RequisitosDelTramite';

import isWebView from 'utils/isWebView';
import { tiposDePasosEnum } from './GPM/enums';
import BannerTramiteInfo from './components/BannerTramiteInfo';
import InformacionGeneral from './components/InformacionGeneral';
import RequisitosTab from './components/RequisitosTab';
import Formatos from './components/Formatos';
import PasosTab from './components/PasosTab';
import FundamentosLegalesTab from './components/FundamentosLegalesTab';
import OficinasDeAtencionTab from './components/OficinasDeAtencionTab';

const GoBackButton = () => {
  const navigate = useNavigate();
  const _isWebView = isWebView();

  if (_isWebView) {
    return null;
  }

  return (
    <>
      <br />
      <ActionButton
        icon={<LeftOutlined />}
        onClick={() => {
          navigate('/tramites');
        }}
      >
        Volver al listado de trámites y servicios
      </ActionButton>
      <br />
      <br />
    </>
  );
};

const Tramite = () => {
  const { idPlantilla } = useParams();
  const screens = Grid.useBreakpoint();
  const [formMapa] = AntForm.useForm();
  const [loading, setLoading] = useState(true);
  const [plantilla, setPlantilla] = useState(null);

  const [tiposDeRequisitos, setTiposDeRequisitos] = useState([]);
  const [catalogosDeRequisitos, setCatalogosDeRequisitos] = useState([]);
  const [categoriasDeRequisitos, setCategoriasDeRequisitos] = useState([]);
  const [unidadesDePlazo, setUnidadesDePlazo] = useState([]);
  const [requisitos, setRequisitos] = useState([]);

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const { results: [_data] } = await getPlantillasDeTramites({ id: idPlantilla });
      if (!_data) return mounted ? setLoading(false) : null;
      const _allRequisitosIds = [
        _data.requisitos_generales.map((r) => r.id),
        _data.casos_de_los_requisitos.map((c) => c.requisitos.map((r) => r.id)),
        _data.pasos_para_tramites.filter((p) => p.tipo_de_paso === tiposDePasosEnum.REQUISITOS
          && p.mostrar_requisitos_en_ficha).map((p) => p.requisitos.map((r) => r.id)),
      ].flat(2);
      const [
        _requisitos,
        _tiposDeRequisitos,
        _categoriasDeRequisitos,
        _unidadesDePlazo,
        _catalogosDeRequisitos,
      ] = await fetchCatalogosRequisitos(_allRequisitosIds);
      const updated = await patchVistas(idPlantilla, _data.volumen_de_consultas);
      _data.volumen_de_consultas = updated?.volumen_de_consultas || 0;
      if (mounted) {
        setRequisitos(_requisitos);
        setTiposDeRequisitos(_tiposDeRequisitos);
        setCategoriasDeRequisitos(_categoriasDeRequisitos);
        setCatalogosDeRequisitos(_catalogosDeRequisitos);
        setUnidadesDePlazo(_unidadesDePlazo);
        setPlantilla(_data);
        setTimeout(() => setLoading(false));
      }
      return null;
    };
    fetchData();
    return () => { mounted = false; };
  }, []);

  if (!loading && !plantilla) {
    return <Navigate to="/not-found" />;
  }

  const pasosRequisitosMostrar = plantilla?.pasos_para_tramites
    .filter((p) => p.tipo_de_paso === tiposDePasosEnum.REQUISITOS && p.mostrar_requisitos_en_ficha);

  return (
    <>
      <GoBackButton />
      <BannerTramiteInfo
        plantilla={plantilla}
        loading={loading}
      />
      <br />
      <RoundedCard>
        <Tabs tabPosition={screens.lg ? 'left' : 'top'}>
          <Tabs.TabPane
            tab={(
              <Text $accent>
                <ClockCircleOutlined />
                Información general
              </Text>
            )}
            key="1"
          >
            <InformacionGeneral plantilla={plantilla} loading={loading} />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <Text $accent>
                <MenuUnfoldOutlined />
                Requisitos
              </Text>
            )}
            key="2"
            forceRender
          >
            <RequisitosTab
              catalogosDeRequisitos={catalogosDeRequisitos}
              categoriasDeRequisitos={categoriasDeRequisitos}
              plantilla={plantilla}
              requisitos={requisitos}
              pasosRequisitosMostrar={pasosRequisitosMostrar}
              tiposDeRequisitos={tiposDeRequisitos}
              unidadesDePlazo={unidadesDePlazo}
              defaultExpanded
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <Text $accent>
                <FileTextOutlined />
                Formatos
              </Text>
            )}
            key="3"
          >
            <Formatos
              plantilla={plantilla}
              loading={loading}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <Text $accent>
                <ScheduleOutlined />
                Pasos a seguir
              </Text>
            )}
            key="4"
          >
            <PasosTab plantilla={plantilla} />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <Text $accent>
                <BankOutlined />
                Fundamento Jurídico
              </Text>
            )}
            key="5"
          >
            <FundamentosLegalesTab plantilla={plantilla} />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={(
              <Text $accent>
                <BankOutlined />
                Oficinas de atención
              </Text>
            )}
            key="6"
          >
            <AntForm form={formMapa}>
              <OficinasDeAtencionTab
                oficinasDeAtencion={plantilla?.oficina_de_atencion ?? []}
                loading={loading}
              />
            </AntForm>
          </Tabs.TabPane>
        </Tabs>
      </RoundedCard>
      <Footer>
        La legalidad, veracidad y calidad de la información de la presente ficha de trámite es
        estrícta responsabilidad de la dependencia, entidad o empresa productiva del Estado que
        la proporcionó al ser autoridad responsable de la gestión del trámite en virtud
        de sus atribuciones y/o facultades normativas.
      </Footer>
    </>
  );
};

const Footer = styled.span`
  display: block;
  font-size: 1rem;
  color: #929292;
  text-align: center;
  padding: 1rem;

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  
  }
`;

const Tabs = styled(AntTabs)`
  .ant-tabs-tab{
    color: ${COLORS.accent};
    padding: 0.5rem 1rem;
    &:hover{
      color: ${COLORS.accent};
      text-decoration: underline;
    }
  }
  .ant-tabs-ink-bar{
    background: ${COLORS.accent};
  }
  .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab-active{
    background-color: ${COLORS.accent}26 !important;
    &:hover{
      text-decoration: none;
    }
  }
`;

const RoundedCard = styled(AntCard)`
  border-radius: 10px;
  @media only screen and (max-width: 576px) {
    .ant-card-body{
      padding: 20px;
    }
    margin: 10px;
  }
`;

const ActionButton = styled(Button).attrs({ $accent: true })`
  border-radius: 0.5rem;
  margin-bottom: 8px;
  width: -webkit-fill-available;
  white-space: break-spaces;
  height: auto;
`;

export default Tramite;
