/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Form,
  Row,
  Col,
  Input,
  Card,
} from 'antd';
import { StopOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router';
import { getCitas } from 'api/tramites/citas';
import Mapa from 'components/Mapa';
import { Button } from 'components';
import { getPlantillasDeTramites } from 'api/tramites';
import address from 'utils/address';
import { DATE_FRONTEND_FORMAT } from 'utils/normalizers';
import ModalCancelarCita from 'views/Appointments/components/ModalCancelarCita';

export const estadosCitasMap = Object.freeze({
  PROGRAMADA: 1,
  EN_PROCESO: 2,
  NO_ASISTIO: 3,
  FINALIZADA: 4,
  CANCELADA: 5,
});

export const estadosDeCitas = [
  {
    id: 1,
    descripcion: 'Programada',
  },
  {
    id: 2,
    descripcion: 'En proceso',
  },
  {
    id: 3,
    descripcion: 'No asistió',
  },
  {
    id: 4,
    descripcion: 'Finalizada',
  },
  {
    id: 5,
    descripcion: 'Cancelada',
  },
];

const FormItems = ({ cita }) => (
  <>
    <Col xs={24} sm={24} md={12}>
      <Form.Item label="Folio" name="folio" labelCol={{ span: 6 }}>
        <Input disabled />
      </Form.Item>
    </Col>
    <Col xs={24} sm={24} md={12}>
      <Form.Item label="Estado" name="estado_de_cita" labelCol={{ span: 6 }}>
        <Input disabled />
      </Form.Item>
    </Col>
    <Col xs={24} sm={24} md={12}>
      <Form.Item label="Horario" name="horario" labelCol={{ span: 6 }}>
        <Input disabled />
      </Form.Item>
    </Col>
    <Col xs={24} sm={24} md={12}>
      <Form.Item label="Fecha" name="fecha" labelCol={{ span: 6 }}>
        <Input disabled />
      </Form.Item>
    </Col>
    <Col span={24}>
      <Form.Item label="Trámite" name="tramite" labelCol={{ span: 3 }}>
        <Input disabled />
      </Form.Item>
    </Col>
    <Col span={24}>
      <Form.Item label="Dirección" name="direccion" labelCol={{ span: 3 }}>
        <Input disabled />
      </Form.Item>
    </Col>
    <Col span={24}>
      <Form.Item label="Ubicacion" labelCol={{ span: 3 }} />
      <Row>
        <Col xs={0} sm={0} md={3} />
        <Col xs={24} sm={24} md={21} style={{ marginTop: -60 }}>
          <Mapa
            interactive={false}
            coords={cita?.coords || []}
            height={240}
          />
        </Col>
      </Row>
    </Col>
  </>
);

const ConsultaCita = ({ cita: _folioCita }) => {
  const [form] = Form.useForm();
  const formInstance = Form.useFormInstance();
  const location = useLocation();
  const folioCita = _folioCita || location.state?.cita;
  const [cita, setCita] = useState();
  const [visible, setVisible] = useState(false);

  const getCoords = (o) => (o?.direccion?.longitud && o?.direccion?.latitud
    ? [o.direccion.longitud, o.direccion.latitud] : []);

  useEffect(() => {
    const fetchData = async () => {
      const [_cita] = await getCitas({ folio: folioCita });
      if (!_cita) return;
      [_cita.plantilla] = (await getPlantillasDeTramites({ id: _cita.plantilla_de_tramite }))
        .results;
      if (!_cita.plantilla) return;
      _cita.oficina = _cita.plantilla.centro_de_costos.find((e) => e.id === _cita.oficina);
      if (!_cita.oficina) return;
      _cita.coords = getCoords(_cita.oficina);
      setCita(_cita);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!cita) return;
    (_folioCita ? formInstance : form).setFieldsValue({
      folio: cita.folio,
      tramite: cita.plantilla.nombre,
      fecha: cita.horario_inicio.format(DATE_FRONTEND_FORMAT),
      horario: `${cita.horario_inicio.format('hh:mm a')} - ${cita.horario_fin.format('hh:mm a')}`,
      estado_de_cita: estadosDeCitas.find((e) => e.id === cita.estado_de_cita).descripcion,
      direccion: address(cita.oficina.direccion),
    });
  }, [cita]);

  if (_folioCita) {
    return (<FormItems cita={cita} />);
  }

  return (
    <Card
      title="Datos de la cita"
      extra={cita?.estado_de_cita === estadosCitasMap.PROGRAMADA && (
        <Button type="link" danger onClick={() => setVisible(true)}>
          Cancelar
          <StopOutlined />
        </Button>
      )}
    >
      <Form form={form}>
        <Row gutter={[10, 10]}>
          <FormItems cita={cita} />
        </Row>
      </Form>
      <ModalCancelarCita
        cita={cita?.id}
        visible={visible}
        setVisible={setVisible}
        callback={(_cita) => {
          const values = {
            ...cita,
            razon_de_rechazo_cita: _cita.razon_de_rechazo_cita,
            estados_globales: _cita.estados_globales,
            estado_de_cita: _cita.estado_de_cita,
          };
          setCita(values);
        }}
      />
    </Card>
  );
};

export default ConsultaCita;
