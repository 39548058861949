/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import { Card, Text, Title } from 'components';
// import { List } from 'antd';

const PrivacyNotice = () => (
  <Card style={{ textAlign: 'justify' }}>
    <Title $accent style={{ textAlign: 'center' }}>
      AVISO DE PRIVACIDAD INTEGRAL
    </Title>
    <br />
    <Text>
      El Municipio de Juárez, por conducto de la Tesorería Municipal, mediante las direcciones que
      la conforman ubicada en la oficina central de la Unidad Administrativa "Benito Juárez" con
      domicilio en Av. Francisco Villa # 950, Colonia Centro en esta ciudad de Ciudad Juárez,
      Chihuahua; da a conocer a los usuarios el siguiente aviso de privacidad integral, la información
      aquí descrita es cumplimiento del artículo 67, de la Ley de Protección de Datos Personales del
      Estado de Chihuahua.
    </Text>
    <br />
    <br />
    <Text>
      La finalidad para la cual serán recabados sus datos personales es para las siguientes finalidades
      que son necesarias dentro de las actividades de “La Tesorería Municipal"
    </Text>
    <br />
    <br />
    <Text>
      <li>
        Trámites administrativos, como pueden ser relativos a recursos financieros, patrimoniales
        o fiscales
      </li>
      <li>
        Confirmar su identidad, para la elaboración de recibos y facturas por los ingresos y
        pagos realizados para demostrar ante las autoridades fiscalizadoras que el pago
        correspondiente, se efectuó al beneficiario o en su caso a la persona acreditada para
        recibir el cheque, dicha información será digitalizada y enviada al Archivo Municipal
        como soporte de pólizas.
      </li>
      <li>
        Cumplir con los requerimientos legales que le son aplicables.
      </li>
      <li>
        Elaboración de los informes estadísticos que solicitan los órganos de fiscalización.
      </li>
      <li>
        Los datos personales recabados serán protegidos y tratados en la base de datos Sybase,
        el cual tiene su fundamento en el Código Municipal para el Estado de Chihuahua
        artículo 64 Fracción II, IV,IX y XIII.
      </li>
    </Text>
    <br />
    <br />
    <Text>
      Para llevar a cabo las finalidades descritas en el presente aviso de privacidad y dependiendo
      específicamente del trámite a realizar, se utilizan de manera enunciativa mas no limitativa, los
      siguientes datos personales:
    </Text>
    <br />
    <br />
    <Text>
      <li>
        Datos de identificación: como nombre, número de cuenta bancaria e interbancaria,
        estado civil, firma autógrafa y electrónica, registro federal de contribuyente (RFC),
        Clave Única de Registro de Población (CURP), numero de seguridad social,
        nacionalidad, fecha de nacimiento, datos contenidos en acta de nacimiento.
      </li>
      <li>
        Datos de Contacto: como domicilio, números telefónicos fijos o celulares o correos
        electrónicos de índole particular entre otros.
      </li>
      <li>
        Datos Académicos: como calificaciones cuantitativas, cualitativas, promedios y
        observaciones a las calificaciones, evaluaciones y las opiniones vertidas en ellas.
      </li>
      <li>
        Datos Patrimoniales, Financieros y Fiscales.
      </li>
    </Text>
    <br />
    <br />
    <Text>
      Además " La Tesorería" podrá utilizar para las finalidades descritas anteriormente los siguientes
      datos personales considerados como sensibles, que requieren especial atención:
    </Text>
    <br />
    <br />
    <Text>
      <li>
        Datos respecto de su estado o condición da salud física o mental.
      </li>
      <li>
        Datos sobre afiliación sindical.
      </li>
      <li>
        U otros.
      </li>
    </Text>
    <br />
    <br />
    <Text>
      El titular de los datos podrá ejercer sus Derechos de Acceso, Rectificación, Cancelación,
      Oposición y Portabilidad de Datos Personales, así como negativa al tratamiento y tratamiento
      de sus datos, ante la Unidad de Transparencia con domicilio en Francisco Villa 950 Norte Col.
      Centro, Área de Sótano ala sur de la Unidad Administrativa “Lic. Benito Juárez”, teléfono
      7370000 Extensión 70532, 70451 y 70453, correo electrónico
      unidadtransparencia@juarez.gob.mx, o por medio de la Plataforma Nacional de
      Transparencia
      {' '}
      <a to="http://www.plataformadetransparencia.org.mx" href="blank">
        http://www.plataformadetransparencia.org.mx
      </a>
      .
    </Text>
  </Card>
);

export default PrivacyNotice;
