import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Upload,
  Form,
  message,
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const AvatarUploader = ({
  form,
  disabled,
  formItemName,
  formItemLabel,
  imageURL: _imageURL,
}) => {
  const [fileList, setFileList] = useState([]);
  const [imageURL, setImageURL] = useState();

  React.useEffect(() => {
    setImageURL(_imageURL);
    // eslint-disable-next-line
  }, [_imageURL]);

  return (
    <Form.Item
      label={formItemLabel}
      style={{ marginBottom: 0 }}
      className="avatar-uploader-cont"
      name={formItemName}
    >
      <Upload
        name="avatar"
        className="avatar-uploader"
        accept="image/png, image/jpeg, image/jpg"
        onRemove={() => {
          setFileList([]);
        }}
        onChange={(info) => {
          const { file } = info;
          const ext = file.name.split('.').pop();

          const isValidExt = ext === 'jpg' || ext === 'jpeg' || ext === 'png';
          if (!isValidExt) {
            message.warn('Solo se admiten archivos en formato JPG, JPEG o PNG');
          }

          const isLt2M = file.size / 1024 / 1024 < 2;
          if (!isLt2M) {
            message.warn('El archivo no debe sobrepasar los 2MB');
          }
          if (isValidExt && isLt2M) {
            getBase64(file, setImageURL);
            setFileList([file]);
          } else {
            setImageURL();
            setFileList([]);
          }
        }}
        listType="picture-card"
        beforeUpload={() => false}
        showUploadList={false}
        disabled={disabled}
        fileList={fileList}
      >
        {imageURL
          ? (
            <div className="preview">
              <img src={imageURL} alt="avatar" style={{ width: '100%' }} />
              { /* eslint-disable-next-line */ }
              <div
                className="remove"
                onClick={(e) => {
                  // eslint-disable-next-line
                  e?.stopPropagation();
                  form.resetFields([formItemName]);
                  setImageURL();
                }}
              >
                <DeleteOutlined />
              </div>
              <div className="replace">
                <EditOutlined />
              </div>
            </div>
          )
          : (
            <div>
              <PlusOutlined />
              <div className="ant-upload-text">Subir</div>
            </div>
          )}
      </Upload>
    </Form.Item>
  );
};

AvatarUploader.propTypes = {
  disabled: PropTypes.bool,
  formItemName: PropTypes.string,
  formItemLabel: PropTypes.string,
  imageURL: PropTypes.string,
  form: PropTypes.shape({
    resetFields: PropTypes.func.isRequired,
  }).isRequired,
};

AvatarUploader.defaultProps = {
  disabled: false,
  formItemName: 'foto',
  formItemLabel: 'Fotografía',
  imageURL: null,
};

export default AvatarUploader;
