import React, { useEffect, useState, useMemo } from 'react';
import {
  Form,
  Modal,
  Spin,
  Col,
  message,
  Dropdown,
  Tooltip,
  Collapse,
  Row,
  Menu,
  Input,
  Tag,
} from 'antd';
import PropTypes from 'prop-types';
import {
  getRequisitosDeTramites,
  deleteRequisitosDeTramites,
  postRequisitosDeTramites,
  patchRequisitosDeTramites,
} from 'api/tramites/requisitosDeTramites';
import FormSubmitCustomControls from 'components/FormSubmitCustomControls';
import ModalDelete from 'components/ModalDelete';
import Select from 'components/Select';
import Uploader from 'components/Uploader';
import { patchTramite } from 'api/tramites/tramites';
import {
  getDocumentosDeTramites,
  postDocumentoDeTramite,
} from 'api/tramites/documentosDeTramites';
import {
  getCategoriasDeRequisitos,
  getManyCatalogosDeRequisitos,
  getManyRequisitos,
  getTiposDeRequisitos,
  getUnidadesDePlazo,
} from 'api/tramites/index';
import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { toMoment, listToString } from 'utils/normalizers';
import styled from 'styled-components';
import { isSpecificGlobalStatus, ESTADOS_GLOBALES_MAP } from 'utils/estadosGlobales';
import {
  statusRequisitosEnum,
  solicitantesEnum,
  tiposDeRequisitosEnum,
} from 'views/Tramites/GPM/enums';
import Previewer from 'components/Previewer';
import { Button, Text, Title } from 'components';
import { getContenTypePorTipoDePadron, getTipoDePadronPorContenType } from 'api/padrones';
import { DEV, PROD } from 'utils/env';
import isWebView from 'utils/isWebView';

const now = toMoment(new Date());
const rules = { required: [{ required: true }] };

const mapDocumentos = (doc) => ({
  ...doc,
  fecha_vencimiento: toMoment(doc.fecha_vencimiento),
  vigente: doc.fecha_vencimiento ? toMoment(doc.fecha_vencimiento).isAfter(now) : true,
});

const renderInfoRequisito = (
  requisito,
  unidadesDePlazo,
  renderDescripcion = false,
  setPreview = null,
  interactive = false,
) => {
  const infoProps = [
    {
      name: 'cantidad_de_originales',
      label: 'Cantidad de originales',
    },
    {
      name: 'cantidad_de_copias',
      label: 'Cantidad de copias',
    },
    {
      name: 'tiene_vigencia',
      label: 'Tiene vigencia',
      render: (val, record) => {
        if (val) {
          const cantidad = record.vigencia_en_dias;
          const unidad = unidadesDePlazo.find((e) => e.id === record.unidad_de_plazo);
          const separator = !(cantidad && unidad) ? ', ' : ' ';
          if (!(cantidad && unidad) && !PROD) {
            return `Si, ${cantidad || 'cantidad no definida'}${separator}${unidad?.descripcion || 'unidad de plazo no definida'}`;
          }
        }
        return 'No';
      },
    },
    {
      name: 'observaciones',
      label: 'Observaciones',
    },
    {
      name: 'documentoDigitalExistente',
      label: 'Documento existente',
      condition: (record) => record.permite_documento_digital,
      render: (documento) => {
        if (documento && interactive) {
          return (
            <Text>
              {documento.fecha_vencimiento?.format() || ''}
              <Text type={!documento.vigente ? 'danger' : 'success'}>
                {` (${!documento.vigente ? 'Expirado' : 'Vigente'})`}
              </Text>
              <Button type="link" onClick={() => setPreview(documento)}>
                <EyeOutlined />
                Ver documento
              </Button>
            </Text>
          );
        }
        return null;
      },
    },
  ];
  return (
    <Col
      span={renderDescripcion ? 12 : 24}
      key={`requisito_${requisito.id}`}
    >
      {renderDescripcion && (
      <>
        <Text
          style={{ fontSize: 16, textTransform: 'uppercase' }}
          className={`${requisito.requisitoDeTramite ? 'success' : ''}`}
        >
          {requisito.descripcion}
        </Text>
        <br />
      </>
      )}
      <Text className="no-bold">
        <b>
          Tipo de requisito
          {': '}
        </b>
        {requisito.tipo_de_requisito.descripcion}
      </Text>
      <br />
      {requisito.tipo_de_requisito.id === tiposDeRequisitosEnum.INCLUSIVO && requisito.related && (
      <>
        <Text className="no-bold">
          <b>
            Requisitos relacionados
            {': '}
          </b>
          {listToString(requisito.related.map((r) => r.descripcion))}
        </Text>
        <br />
      </>
      )}
      {infoProps.filter((p) => (p.condition ? p.condition(requisito) : true)).map(({
        label,
        name,
        render,
      }) => (
        <React.Fragment key={name}>
          <Text className="no-bold">
            <b>{label}</b>
            {': '}
            {(render ? render(requisito[name], requisito) : requisito[name])
            ?? `Sin ${label.toLowerCase()}`}
          </Text>
          <br />
        </React.Fragment>
      ))}
    </Col>
  );
};

export const Requisitos = ({
  categorias,
  onClickAdd,
  onClickLinkDigital,
  getStatusTag,
  onClickView,
  onClickDelete,
  interactive,
  unidadesDePlazo,
  setPreview,
  disabled,
}) => (
  <RequisitosContainer>
    {categorias.map((categoria) => (
      <Col span={24} style={{ marginBottom: 25 }} key={`categoria_${categoria.id}`}>
        <Title level={4}>
          {categoria.descripcion}
        </Title>
        <Collapse ghost>
          {categoria.groups.map((_group, _idx) => {
            const group = { ..._group, id: _idx };
            if (group.tipo_de_requisito.id === tiposDeRequisitosEnum.EXCLUSIVO) {
              const requisitosToString = listToString(group.requisitos
                .map((e) => e.descripcion), 'disjunction');
              const idxRequisitoDeTramite = group.requisitos
                .findIndex((requisito) => requisito.requisitoDeTramite);
              const _requisito = group.requisitos[idxRequisitoDeTramite];
              if (_requisito) {
                group.requisitos = [
                  _requisito,
                  ...group.requisitos.filter((e, idx) => idx !== idxRequisitoDeTramite),
                ];
              }
              const items = group.requisitos.map((requisito) => {
                const output = {
                  key: `ddmi_requisito_${requisito.id}`,
                  label: requisito.descripcion,
                  children: [],
                };
                if (requisito.permite_documento_electronico) {
                  output.children = [
                    {
                      key: `add_doc_digital_requisito_${requisito.id}`,
                      onClick: ({ domEvent }) => {
                        domEvent.stopPropagation();
                        onClickAdd(requisito);
                      },
                      label: 'Nuevo archivo dígital',
                    },
                  ];
                  if (requisito.documentoDigitalExistente) {
                    output.children = [
                      ...output.children,
                      {
                        key: `link_doc_digital_requisito_${requisito.id}`,
                        onClick: ({ domEvent }) => {
                          domEvent.stopPropagation();
                          onClickLinkDigital(
                            requisito,
                            requisito.documentoDigitalExistente,
                          );
                        },
                        label: `Vincular documento dígital existente ${DEV ? `(${requisito.documentoDigitalExistente.id})` : ''}`,
                      },
                    ];
                  }
                }
                return output;
              });

              const requisitoDeTramite = _requisito?.requisitoDeTramite;
              const tag = getStatusTag(requisitoDeTramite);
              return (
                <Collapse.Panel
                  header={(
                    <Text>
                      <span className="required-mark">*</span>
                      {requisitosToString}
                      {!!requisitoDeTramite && (<CheckCircleOutlined />)}
                    </Text>
                  )}
                  // eslint-disable-next-line no-nested-ternary
                  extra={interactive ? (
                    _requisito ? (
                      <>
                        <Button
                          type="link"
                          onClick={(e) => {
                            e.stopPropagation();
                            onClickView(_requisito);
                          }}
                        >
                          <EyeOutlined />
                          {requisitoDeTramite.estados_globales === ESTADOS_GLOBALES_MAP.ACTIVO
                            && requisitoDeTramite.es_requisito_incompleto ? 'Revisar' : 'Ver'}
                        </Button>
                        {requisitoDeTramite.estados_globales === ESTADOS_GLOBALES_MAP.ACTIVO && (
                          <Button
                            type="link"
                            onClick={(e) => {
                              e.stopPropagation();
                              onClickDelete(_requisito);
                            }}
                            className="no-color"
                            disabled={disabled}
                            danger
                          >
                            <DeleteOutlined />
                            Eliminar
                          </Button>
                        )}
                        {tag}
                      </>
                    ) : (
                      <Dropdown
                        overlay={<Menu items={items} />}
                        placement="top"
                        disabled={disabled}
                      >
                        <Button
                          type="link"
                          onClick={(e) => e.stopPropagation()}
                          style={{ marginLeft: 'auto' }}
                          disabled={disabled}
                        >
                          <PlusOutlined />
                          Agregar
                        </Button>
                      </Dropdown>
                    )
                  ) : null}
                  key={`group_${group.id}`}
                  forceRender
                >
                  <Row gutter={[20, 30]}>
                    {group.requisitos.map((requisito) => renderInfoRequisito(
                      requisito,
                      unidadesDePlazo,
                      true,
                      setPreview,
                      interactive,
                    ))}
                  </Row>
                </Collapse.Panel>
              );
            }
            return group.requisitos.map((requisito) => {
              const canAdd = !requisito.requisitoDeTramite
                && requisito.tipo_de_requisito.id !== tiposDeRequisitosEnum.DINAMICO;
              const items = [];
              if (requisito.permite_documento_electronico) {
                items.push({
                  key: `add_doc_digital_requisito_${requisito.id}`,
                  onClick: ({ domEvent }) => {
                    domEvent.stopPropagation();
                    onClickAdd(requisito);
                  },
                  label: 'Nuevo archivo dígital',
                });
                if (requisito.documentoDigitalExistente) {
                  items.push({
                    key: `link_doc_digital_requisito_${requisito.id}`,
                    onClick: ({ domEvent }) => {
                      domEvent.stopPropagation();
                      onClickLinkDigital(
                        requisito,
                        requisito.documentoDigitalExistente,
                      );
                    },
                    label: `Vincular documento dígital existente ${DEV ? `(${requisito.documentoDigitalExistente.id})` : ''}`,
                  });
                }
              }
              const requisitoDeTramite = requisito?.requisitoDeTramite;
              const tag = getStatusTag(requisitoDeTramite);
              return (
                <Collapse.Panel
                  header={(
                    <Text>
                      {requisito.required && (<span className="required-mark">*</span>)}
                      {requisito.descripcion}
                      {requisito.tipo_de_requisito.id === tiposDeRequisitosEnum.DINAMICO && (
                        <Tooltip title="Será obtenido de forma dinámica">
                          <QuestionCircleOutlined />
                        </Tooltip>
                      )}
                    </Text>
                  )}
                  extra={(
                    <>
                      {requisitoDeTramite && interactive && (
                        <>
                          <Button
                            type="link"
                            onClick={(e) => {
                              e.stopPropagation();
                              onClickView(requisito);
                            }}
                          >
                            <EyeOutlined />
                            {requisitoDeTramite.estados_globales === ESTADOS_GLOBALES_MAP.ACTIVO
                              && requisitoDeTramite.es_requisito_incompleto ? 'Revisar' : 'Ver'}
                          </Button>
                          {requisitoDeTramite.estados_globales === ESTADOS_GLOBALES_MAP.ACTIVO && (
                            <Button
                              type="link"
                              onClick={(e) => {
                                e.stopPropagation();
                                onClickDelete(requisito);
                              }}
                              className="no-color"
                              disabled={disabled}
                              danger
                            >
                              <DeleteOutlined />
                              Eliminar
                            </Button>
                          )}
                          {tag}
                        </>
                      )}
                      {canAdd && interactive && (
                        <Dropdown
                          overlay={<Menu items={items} />}
                          placement="top"
                          disabled={disabled}
                        >
                          <Button
                            type="link"
                            onClick={(e) => e.stopPropagation()}
                            style={{ marginLeft: 'auto' }}
                            disabled={disabled}
                          >
                            <PlusOutlined />
                            Agregar
                          </Button>
                        </Dropdown>
                      )}
                    </>
                  )}
                  key={`cp_requisito_${requisito.id}`}
                  forceRender
                >
                  {renderInfoRequisito(
                    requisito,
                    unidadesDePlazo,
                    false,
                    setPreview,
                    interactive,
                  )}
                </Collapse.Panel>
              );
            });
          })}
        </Collapse>
      </Col>
    ))}
  </RequisitosContainer>
);

Requisitos.propTypes = {
  categorias: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  unidadesDePlazo: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClickAdd: PropTypes.func,
  onClickLinkDigital: PropTypes.func,
  getStatusTag: PropTypes.func,
  onClickView: PropTypes.func,
  onClickDelete: PropTypes.func,
  interactive: PropTypes.bool,
  setPreview: PropTypes.func,
  disabled: PropTypes.bool,
};

Requisitos.defaultProps = {
  onClickAdd: null,
  onClickLinkDigital: null,
  getStatusTag: () => null,
  setPreview: null,
  onClickView: null,
  onClickDelete: null,
  interactive: null,
  disabled: false,
};

export const groupsByCategories = (
  categoriasDeRequisitos = [],
  catalogosDeRequisitos = [],
  requisitos = [],
  tiposDeRequisitos = [],
  requisitosDeTramites = [],
  documentosParaTramites = [],
  tipoDePadronSolicitante = 1,
  tipoDePadronAfectado = null,
) => {
  const _categorias = categoriasDeRequisitos.map((categoria) => {
    const catalogos = catalogosDeRequisitos
      .filter((catalogo) => catalogo.categoria === categoria.id).map(({ id }) => id);
    const _requisitos = requisitos
      .filter((requisito) => catalogos.includes(requisito.catalogo_de_requisitos)
        && (!requisito.padron || tipoDePadronSolicitante === requisito.padron
          || tipoDePadronAfectado === requisito.padron))
      .map((requisito) => {
        const catalogo_de_requisitos = catalogosDeRequisitos
          .find((catalogo) => catalogo.id === requisito.catalogo_de_requisitos);
        const tipo_de_requisito = tiposDeRequisitos
          .find((e) => e.id === requisito.tipo_de_requisito);
        const related = tipo_de_requisito.id === tiposDeRequisitosEnum.INCLUSIVO
          ? requisitos.filter((req) => {
            const cat = catalogosDeRequisitos.find((c) => c.id === req.catalogo_de_requisitos);
            return req.tipo_de_requisito === tipo_de_requisito.id
                && cat.categoria === categoria.id
                && req.id !== requisito.id;
          }) : [];
        const relatedUploaded = related.some((r) => requisitosDeTramites
          .some((re) => re.requisito === r.id));
        const _requisitoDeTramite = requisitosDeTramites
          .find((re) => re.requisito === requisito.id);
        const required = tipo_de_requisito.id === tiposDeRequisitosEnum.OBLIGATORIO
            || (tipo_de_requisito.id === tiposDeRequisitosEnum.INCLUSIVO && relatedUploaded)
            || (tipo_de_requisito.id === tiposDeRequisitosEnum.EXCLUSIVO
              && !(relatedUploaded || _requisitoDeTramite));
        return ({
          ...requisito,
          catalogo_de_requisitos,
          tipo_de_requisito,
          required,
          requisitoDeTramite: _requisitoDeTramite,
          related,
        });
      })
      .sort((a, b) => a.catalogo_de_requisitos.numero - b.catalogo_de_requisitos.numero);
    const filterCatalogs = (catalogo) => _requisitos
      .some((r) => r.catalogo_de_requisitos.id === catalogo.id);
    const _catalogos = catalogosDeRequisitos.filter(filterCatalogs).map((_catalogo) => ({
      ..._catalogo,
      requisitos: _requisitos
        .filter((requisito) => requisito.catalogo_de_requisitos.id === _catalogo.id),
    }));
    if (!_requisitos.length) {
      return null;
    }
    const output = {
      ...categoria,
      requisitos: _catalogos.map((e) => e.requisitos).flat().map((requisito) => ({
        documentoDigitalExistente: isWebView() ? null : documentosParaTramites
          .findLast((doc) => doc.padron_object_id && doc.estados_globales === 4 && doc.vigente
            && doc.catalogo_de_requisitos === requisito.catalogo_de_requisitos.id),
        ...requisito,
      })),
    };
    output.groups = tiposDeRequisitos.map((tipo_de_requisito) => {
      const reqs = output.requisitos
        .filter((requisito) => requisito.tipo_de_requisito.id === tipo_de_requisito.id)
        .sort((a, b) => a.catalogo_de_requisitos.numero - b.catalogo_de_requisitos.numero);
      if (!reqs.length) return null;
      return {
        tipo_de_requisito,
        requisitos: reqs,
      };
    }).filter((e) => e);
    return output;
  }).filter((e) => e);
  return _categorias;
};

export const fetchCatalogosRequisitos = async (_requisitos = []) => {
  const alreadyObjects = typeof _requisitos[0] === 'object';
  const requisitos = alreadyObjects ? _requisitos
    : await getManyRequisitos(_requisitos);
  const promises = [
    getTiposDeRequisitos(),
    getCategoriasDeRequisitos(),
    getUnidadesDePlazo(),
    getManyCatalogosDeRequisitos(requisitos.map((e) => e.catalogo_de_requisitos)),
  ];

  const [
    tiposDeRequisitos,
    categoriasDeRequisitos,
    unidadesDePlazo,
    catalogosDeRequisitos,
  ] = await Promise.all(promises);

  return [
    requisitos,
    tiposDeRequisitos,
    categoriasDeRequisitos,
    unidadesDePlazo,
    catalogosDeRequisitos,
  ];
};

const RequisitosDeTramite = ({
  casoDeRequisitos,
  tramite,
  setStatusRequisitos,
  setCasoDeRequisitos,
  loading,
  setLoading,
  setShowMessagesRequisitos,
  showMessagesRequisitos,
  requisitosGenerales,
  interactive,
  disabled,
  postLog,
}) => {
  const [form] = Form.useForm();
  const [requisitosDeTramites, setRequisitosDeTramites] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedRequisito, setSelectedRequisito] = useState(null);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [editing, setEditing] = useState(false);
  const [preview, setPreview] = useState();

  // Catalogos
  const [tiposDeRequisitos, setTiposDeRequisitos] = useState([]);
  const [catalogosDeRequisitos, setCatalogosDeRequisitos] = useState([]);
  const [categoriasDeRequisitos, setCategoriasDeRequisitos] = useState([]);
  const [unidadesDePlazo, setUnidadesDePlazo] = useState([]);
  const [documentosParaTramites, setDocumentosParaTramites] = useState([]);
  const [requisitos, setRequisitos] = useState([]);

  const tipoDePadronSolicitante = tramite.solicitante_ciudadano
    ? solicitantesEnum.CIUDADANO : solicitantesEnum.CONTRIBUYENTE;
  const solicitanteId = tramite.solicitante_ciudadano
    ? tramite.ciudadano : tramite.contribuyente;
  const contentTypeSolicitante = getContenTypePorTipoDePadron(tipoDePadronSolicitante);
  const afectadoId = tramite.object_id_padron;
  const contentTypeAfectado = tramite.content_type_a_padron;
  const tipoDePadronAfectado = tramite.content_type_a_padron
    ? getTipoDePadronPorContenType(tramite.content_type_a_padron) : null;

  const fetchData = async (_documentosParaTramites = documentosParaTramites) => {
    setLoading(true);
    const _requisitosDeTramites = await getRequisitosDeTramites({
      tramite: tramite?.id,
    });
    setRequisitosDeTramites(
      _requisitosDeTramites.filter((e) => e.estados_globales !== 5)
        .map((r) => ({
          ...r,
          documentos_de_tramite: isWebView() ? null : _documentosParaTramites
            .findLast((e) => e.id === r.documentos_de_tramite?.id) || r.documentos_de_tramite,
        })),
    );
    setLoading(false);
  };

  const fetchDocumentosParaTramites = async (_set = false) => {
    const promises = [
      getDocumentosDeTramites({
        padron_object_id: solicitanteId,
        padron_content: contentTypeSolicitante,
      }),
    ];
    if (solicitanteId !== afectadoId || contentTypeSolicitante !== contentTypeAfectado) {
      promises.push(getDocumentosDeTramites({
        padron_object_id: afectadoId,
        padron_content: contentTypeAfectado,
      }));
    }
    const [
      _documentosDeTramitesSolicitante,
      _documentosDeTramitesAfectado = [],
    ] = await Promise.all(promises);
    const _documentos = [..._documentosDeTramitesSolicitante, ..._documentosDeTramitesAfectado]
      .map(mapDocumentos);
    if (_set) {
      setDocumentosParaTramites(_documentos);
      await fetchData(_documentos);
    }
    return _documentos;
  };

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      setLoading(true);
      const promises = [
        fetchCatalogosRequisitos(requisitosGenerales || casoDeRequisitos?.requisitos),
      ];

      if (interactive) {
        promises.push(fetchDocumentosParaTramites());
        promises.push(getRequisitosDeTramites({ tramite: tramite?.id }));
      }
      const [
        [
          _requisitos,
          _tiposDeRequisitos,
          _categoriasDeRequisitos,
          _unidadesDePlazo,
          _catalogosDeRequisitos,
        ],
        _documentos = [],
        _requisitosDeTramites = [],
      ] = await Promise.all(promises);
      if (mounted) {
        setTiposDeRequisitos(_tiposDeRequisitos);
        setCategoriasDeRequisitos(_categoriasDeRequisitos);
        setCatalogosDeRequisitos(_catalogosDeRequisitos);
        setUnidadesDePlazo(_unidadesDePlazo);
        setDocumentosParaTramites(_documentos);
        setRequisitosDeTramites(_requisitosDeTramites.map((r) => ({
          ...r,
          documentos_de_tramite: isWebView() ? null : _documentos
            .findLast((e) => e.id === r.documentos_de_tramite?.id) || r.documentos_de_tramite,
        })));
        setRequisitos(_requisitos);
        setLoading(false);
      }
    };
    fetch();
    return () => { mounted = false; };
  }, []);

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    setSelectedRequisito();
    setEditing(false);
  };

  const onFinish = async () => {
    setLoading(true);
    const values = form.getFieldsValue();
    const requisito = requisitos.find((r) => r.id === values.requisito);
    const valuesDocumento = {
      archivo: values.archivo,
      requisito: values.requisito,
      fecha_vencimiento: values.fecha_vencimiento,
      estados_globales: 2,
      catalogo_de_requisitos: requisito.catalogo_de_requisitos,
    };
    const catalogoDeRequisito = catalogosDeRequisitos
      .find((c) => c.id === requisito.catalogo_de_requisitos);
    if (catalogoDeRequisito.padron) {
      if (catalogoDeRequisito.padron === tipoDePadronAfectado) {
        valuesDocumento.padron_object_id = afectadoId;
        valuesDocumento.padron_content = contentTypeAfectado;
      } else if (catalogoDeRequisito.padron === tipoDePadronSolicitante) {
        valuesDocumento.padron_object_id = solicitanteId;
        valuesDocumento.padron_content = contentTypeSolicitante;
      }
    }
    const savedFile = await postDocumentoDeTramite(valuesDocumento);
    if (savedFile) {
      const valuesRequisito = {
        estados_globales: 1,
        requisito: requisito.id,
        tramite: tramite.id,
        documentos_de_tramite: savedFile.id,
        descripcion: requisito.descripcion + (new Date()).getTime(),
        es_documento_digital: true,
        es_requisito_incompleto: false,
      };
      const saved = selectedRequisito
        ? await patchRequisitosDeTramites(selectedRequisito.id, valuesRequisito)
        : await postRequisitosDeTramites(valuesRequisito);
      if (saved) {
        if (!tramite.caso && casoDeRequisitos) {
          const updatedTramite = await patchTramite(tramite.id, { caso: casoDeRequisitos.id });
          if (updatedTramite) {
            setCasoDeRequisitos(casoDeRequisitos.id);
          }
        }
        onCancel();
        await fetchDocumentosParaTramites(true);
      }
    } else {
      message.info(`Ah ocurrido un error al ${selectedRequisito ? 'actualizar' : 'registrar'} el requisito, intentelo de nuevo`);
    }
    setLoading(false);
  };

  const onClickLinkDigital = async (requisito, documento) => {
    setLoading(true);
    const values = {
      estados_globales: 1,
      requisito: requisito.id,
      tramite: tramite.id,
      descripcion: requisito.descripcion + (new Date()).getTime(),
      documentos_de_tramite: documento.id,
      fecha_de_vencimiento: documento.fecha_vencimiento,
    };
    const saved = await postRequisitosDeTramites(values);
    if (saved) {
      onCancel();
      await fetchData();
    }
    setLoading(false);
  };

  const onClickAdd = (requisito) => {
    onCancel();
    const values = {
      requisito: requisito.id,
      tiene_vigencia: requisito.tiene_vigencia,
      es_documento_digital: true,
    };
    form.setFieldsValue(values);
    setVisible(true);
  };

  const onClickView = (requisito) => {
    const { requisitoDeTramite: _requisitoDeTramite } = requisito;
    const values = {
      observaciones: _requisitoDeTramite.observaciones,
      es_requisito_incompleto: _requisitoDeTramite.es_requisito_incompleto,
      observaciones_de_incompleto: _requisitoDeTramite.observaciones_de_incompleto,
      es_documento_digital: _requisitoDeTramite.es_documento_digital,
      requisito: requisito.id,
      permite_documento_electronico: requisito.permite_documento_electronico,
      estados_globales: requisito.estados_globales,
      tiene_vigencia: requisito.tiene_vigencia,
      fecha_vencimiento: toMoment(_requisitoDeTramite.fecha_de_vencimiento),
    };
    const documento = _requisitoDeTramite.documentos_de_tramite;
    if (documento) {
      values.archivo = documento.archivo;
    }
    form.setFieldsValue(values);
    setSelectedRequisito(requisito.requisitoDeTramite);
    setVisible(true);
  };

  const onClickDelete = (requisito) => {
    setSelectedRequisito(requisito.requisitoDeTramite);
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    setLoading(true);
    const deleted = await deleteRequisitosDeTramites(selectedRequisito.id);
    if (deleted) {
      setVisibleAlert(false);
      onCancel();
      await fetchData();
    }
    setLoading(false);
  };

  const categoriesDependencies = [requisitosDeTramites, categoriasDeRequisitos,
    catalogosDeRequisitos, tiposDeRequisitos, requisitos];

  const categorias = useMemo(() => {
    if (categoriesDependencies.some((dep, idx) => idx && !dep.length)) {
      return [];
    }
    return groupsByCategories(
      categoriasDeRequisitos,
      catalogosDeRequisitos,
      requisitos,
      tiposDeRequisitos,
      requisitosDeTramites,
      documentosParaTramites,
      tipoDePadronSolicitante,
      tipoDePadronAfectado,
    );
  }, categoriesDependencies);

  const _requisitosDeTramites = requisitosDeTramites
    .filter((rt) => requisitos.some((r) => r.id === rt.requisito));

  const hasRequisitosActivos = _requisitosDeTramites
    .some((r) => isSpecificGlobalStatus(r, ESTADOS_GLOBALES_MAP.ACTIVO));
  const hasRequisitosIncompletos = _requisitosDeTramites.some((r) => r.es_requisito_incompleto
    && isSpecificGlobalStatus(r, ESTADOS_GLOBALES_MAP.ACTIVO));

  const getRequisitosRequeridos = () => {
    const groups = categorias.map((categoria) => categoria.groups).flat();
    const groupsExclusivos = groups
      .filter((group) => group.tipo_de_requisito.id === tiposDeRequisitosEnum.EXCLUSIVO);
    const groupsNoExclusivos = groups
      .filter((group) => group.tipo_de_requisito.id !== tiposDeRequisitosEnum.EXCLUSIVO);
    const _requisitosRequeridos = groupsNoExclusivos.map((group) => group.requisitos)
      .flat()
      .filter((e) => e.required);
    groupsExclusivos.forEach((group) => {
      const hasRequisito = group.requisitos.some((requisito) => requisito.requisitoDeTramite);
      if (!hasRequisito) {
        _requisitosRequeridos.push(
          listToString(group.requisitos.map((r) => r.descripcion), 'disjunction'),
        );
      }
    });
    return _requisitosRequeridos;
  };

  const status = useMemo(() => {
    const requisitosRequeridos = getRequisitosRequeridos();
    const hasAllRequisitos = requisitosRequeridos.every((e) => e.requisitoDeTramite);
    if (!requisitos.length || !hasAllRequisitos) {
      return statusRequisitosEnum.INCOMPLETE;
    }
    if (hasAllRequisitos && !requisitosRequeridos.length) {
      return statusRequisitosEnum.INCOMPLETE_SAVE;
    }
    if (hasRequisitosIncompletos) {
      return statusRequisitosEnum.NEED_UPDATE;
    }
    if (hasRequisitosActivos) {
      return statusRequisitosEnum.COMPLETE;
    }
    return statusRequisitosEnum.CONTINUE;
  }, [requisitosDeTramites, categorias, requisitos]);

  useEffect(() => setStatusRequisitos(status), [status]);

  const onConfirm = async () => {
    setLoading(true);
    const actives = requisitosDeTramites
      .filter((requisito) => requisito.estados_globales === ESTADOS_GLOBALES_MAP.ACTIVO);
    if (!actives.length) {
      if (postLog) {
        await postLog('Requisitos enviados para revisión', true);
        setLoading(false);
        return;
      }
    }
    const values = {
      estados_globales: ESTADOS_GLOBALES_MAP.EN_PROCESO,
      es_requisito_incompleto: true,
    };
    const promises = actives.map(({ id }) => patchRequisitosDeTramites(id, values));
    const responses = await Promise.all(promises);
    if (responses.every((e) => e)) {
      setShowMessagesRequisitos(false);
    } else {
      message.warn('Ha ocurrido un error al autorizar los registros, inténte de nuevo');
    }
    if (responses.some((e) => e)) {
      if (postLog) {
        await postLog('Requisitos enviados para revisión', true);
      }
      await fetchData();
    }
    setLoading(false);
  };

  useEffect(() => {
    if (showMessagesRequisitos) {
      Modal.confirm({
        onOk: onConfirm,
        onCancel: () => setShowMessagesRequisitos(false),
        okText: 'Continuar',
        title: 'Confirmación',
        content: (
          <>
            Los requisitos en estatus
            {' '}
            <Tag color="default" style={{ margin: 0 }}>
              Cargado
            </Tag>
            {' '}
            seran enviados para su revisión.
            <br />
            Ya no será posible realizar modificaciones, ¿desea continuar?
          </>
        ),
        okButtonProps: { className: 'no-color' },
        cancelButtonProps: { className: 'no-color' },
      });
    }
  }, [showMessagesRequisitos]);

  const getStatusTag = (_requisito) => {
    if (_requisito) {
      if (_requisito.estados_globales === ESTADOS_GLOBALES_MAP.ACTIVO) {
        if (_requisito.es_requisito_incompleto) {
          return (
            <Tag color="warning">
              Requiere revisión
            </Tag>
          );
        }
        return (
          <Tag color="default">
            Cargado
          </Tag>
        );
      }
      if (_requisito.estados_globales === ESTADOS_GLOBALES_MAP.EN_PROCESO) {
        return (
          <Tag color="processing">
            Enviado
          </Tag>
        );
      }
      if (_requisito.estados_globales === ESTADOS_GLOBALES_MAP.AUTORIZADO) {
        return (
          <Tag color="success">
            Autorizado
          </Tag>
        );
      }
    }
    return null;
  };

  const isActive = selectedRequisito && isSpecificGlobalStatus(
    selectedRequisito,
    ESTADOS_GLOBALES_MAP.ACTIVO,
  );

  return (
    <>
      <Requisitos
        categorias={categorias}
        onClickAdd={onClickAdd}
        onClickLinkDigital={onClickLinkDigital}
        getStatusTag={getStatusTag}
        onClickView={onClickView}
        onClickDelete={onClickDelete}
        renderInfoRequisito={renderInfoRequisito}
        interactive={interactive}
        unidadesDePlazo={unidadesDePlazo}
        disabled={disabled
          || [statusRequisitosEnum.COMPLETE, statusRequisitosEnum.CONTINUE].includes(status)}
      />
      <Modal
        visible={visible}
        footer={null}
        closable={false}
        maskClosable={false}
        keyboard={!loading}
        centered
        width="50%"
        title={(
          <FormSubmitCustomControls
            label={`${selectedRequisito
              ? ((editing && 'Editar') || 'Ver') : 'Agregar'} Requisito de trámite`}
            controls={[
              {
                text: 'Regresar',
                icon: <ArrowLeftOutlined />,
                onClick: editing ? () => {
                  form.setFieldsValue({
                    archivo: selectedRequisito.documentos_de_tramite?.archivo,
                  });
                  setEditing(false);
                } : onCancel,
              },
              isActive && !editing && {
                text: 'Editar',
                icon: <EditOutlined />,
                onClick: () => {
                  form.setFieldsValue({ archivo: null });
                  setEditing(true);
                },
              },
              (editing || !selectedRequisito) && {
                text: 'Guardar',
                icon: <SaveOutlined />,
                onClick: form.submit,
              },
            ].filter((c) => c)}
            loading={loading}
          />
        )}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            onFinish={() => onFinish()}
          >
            <Col span={24}>
              <Form.Item
                label="Requisito"
                name="requisito"
                rules={rules.required}
              >
                <Select disabled options={requisitos} />
              </Form.Item>
            </Col>
            {(!!selectedRequisito?.documentos_de_tramite?.archivo && !editing) && (
              <Form.Item label="Documento Dígital">
                <Previewer
                  iFrameUrl={selectedRequisito.documentos_de_tramite.archivo}
                  modal={false}
                  ifameHeight="50vh"
                  visible
                />
              </Form.Item>
            )}
            {(!selectedRequisito || editing) && (
              <Uploader
                title="Documento Dígital"
                limitMB={5}
                formItemName="archivo"
                allowedExtensions={['pdf', 'docx', 'doc', 'jpeg', 'jpg', 'png', 'cvs', 'xlsx']}
                rules={rules.required}
                allowPreview={false}
                showPreviewMessage={false}
                fullSize
              />
            )}
            {selectedRequisito?.observaciones && !editing && (
              <Col span={24}>
                <Form.Item
                  label="Observaciones"
                  name="observaciones"
                  rules={rules.required}
                >
                  <Input.TextArea
                    disabled
                    className="warn bold"
                    autoSize={{ minRows: 3, maxRows: 3 }}
                  />
                </Form.Item>
              </Col>
            )}
            {selectedRequisito?.observaciones_de_incompleto && !editing && (
              <Col span={24}>
                <Form.Item
                  label="Observaciones"
                  name="observaciones_de_incompleto"
                  rules={rules.required}
                >
                  <Input.TextArea
                    disabled
                    className="warn bold"
                    autoSize={{ minRows: 3, maxRows: 3 }}
                  />
                </Form.Item>
              </Col>
            )}
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => setVisibleAlert(false)}
        visible={visibleAlert}
        loading={loading}
        content={`el Requisito ${selectedRequisito?.descripcion}`}
      />
      <Previewer
        iFrameUrl={preview?.archivo}
        base64={preview?.archivo}
        visible={!!preview}
        onCancel={() => setPreview()}
      />
    </>
  );
};

RequisitosDeTramite.propTypes = {
  casoDeRequisitos: PropTypes.shape({
    id: PropTypes.number,
    requisitos: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
  }),
  tramite: PropTypes.shape({
    id: PropTypes.number,
    caso: PropTypes.number,
    solicitante_ciudadano: PropTypes.bool,
    ciudadano: PropTypes.number,
    contribuyente: PropTypes.number,
    content_type_a_padron: PropTypes.number,
    object_id_padron: PropTypes.number,
    canal_de_pago: PropTypes.number,
  }),
  setStatusRequisitos: PropTypes.func.isRequired,
  setCasoDeRequisitos: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
  setShowMessagesRequisitos: PropTypes.func,
  showMessagesRequisitos: PropTypes.bool,
  requisitosGenerales: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({ id: PropTypes.number }), PropTypes.number])),
  interactive: PropTypes.bool,
  disabled: PropTypes.bool,
  postLog: PropTypes.func,
};

RequisitosDeTramite.defaultProps = {
  loading: false,
  casoDeRequisitos: null,
  tramite: null,
  setShowMessagesRequisitos: null,
  showMessagesRequisitos: false,
  requisitosGenerales: null,
  setCasoDeRequisitos: null,
  interactive: true,
  disabled: false,
  postLog: null,
};

const RequisitosContainer = styled.div`
  width: 100%;
  height: 100%;
  .required-mark {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    line-height: 1;
    font-weight: bold;
  }
  .no-bold {
    font-weight: normal !important;;
  }
  .ant-typography .anticon {
    margin-left: 5px;
  }
`;

export default RequisitosDeTramite;
