import React, { useState } from 'react';
import {
  Button,
  COLORS,
  Card,
  Text,
  Title,
  FlexCol,
} from 'components';
import {
  Row,
  Col,
  Form,
  Input,
  message,
} from 'antd';
import LinkWithTooltip from 'components/LinkWithTooltip';
import { EditOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { getCitas } from 'api/tramites/citas';
import { useNavigate } from 'react-router';

const Appointments = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState();

  const onFinish = async (params) => {
    setLoading(true);
    const [cita] = await getCitas(params);
    setLoading(false);
    if (!cita) {
      message.info(`No se encontro cita con folio ${params.folio}`);
      return;
    }
    navigate('consulta', { state: { cita: params.folio } });
  };

  return (
    <Row gutter={[10, 20]} style={{ marginBottom: 20 }}>
      <Col span={24}>
        <Title level={2}>
          Citas
        </Title>
      </Col>
      <Col xs={24} sm={24} md={12}>
        <Card bg={COLORS.white}>
          <FlexCol style={{ height: '100%' }} justifyContent="space-between" alignItems="start">
            <Text>
              <Title level={4}>
                Registro de cita
              </Title>
              <br />
              <Text style={{ marginBottom: 15 }}>
                En este apartado podrá registrar las citas de los trámites enlistados
              </Text>
            </Text>
            <Button $accent>
              <LinkWithTooltip
                to="nueva"
                text="Registrar"
                icon={EditOutlined}
              />
            </Button>
          </FlexCol>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={12}>
        <Card bg={COLORS.white}>
          <FlexCol
            style={{ height: '100%', width: '100%' }}
            justifyContent="space-between"
            alignItems="start"
          >
            <Title level={4} style={{ marginBottom: 15 }}>
              Consultar o cancelar citas
            </Title>
            <br />
            <Form
              layout="vertical"
              onFinish={onFinish}
              style={{ width: '100%' }}
            >
              <Col span={24}>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Ingrese número de folio de la cita"
                  name="folio"
                >
                  <Input disabled={loading} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Ingrese código de referencia"
                  name="codigo"
                >
                  <Input disabled={loading} />
                </Form.Item>
              </Col>
              <Form.Item noStyle>
                <Button $accent htmlType="submit" disabled={loading}>
                  Consultar
                  {loading ? <LoadingOutlined /> : <SearchOutlined />}
                </Button>
              </Form.Item>
            </Form>
          </FlexCol>
        </Card>
      </Col>
    </Row>
  );
};

export default Appointments;
