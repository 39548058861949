import React from 'react';
import { Text } from 'components';

// eslint-disable-next-line react/prop-types
const WaitingAlert = ({ visible = false }) => visible && (
  <Text type="warning" style={{ float: 'right' }} strong>
    * Nos está tomando más de lo esperado, por favor, sea paciente.
  </Text>
);
export default WaitingAlert;
