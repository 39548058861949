import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Previewer from 'components/Previewer';
import { postObtenerDocumento } from 'api/reporteador';
import { Button, COLORS, Text } from 'components';
import { ReloadOutlined } from '@ant-design/icons';

// eslint-disable-next-line react/prop-types
const RetryError = ({ onClick }) => (
  <Button onClick={onClick} color={COLORS.accent}>
    <Text>
      Reintentar
    </Text>
    <ReloadOutlined />
  </Button>
);

const Documento = ({
  tramite,
  callback,
  reporteID,
  setLoading,
  firmasElectronicas,
  iFrameUrl,
  attrs,
}) => {
  const [error, setError] = useState();
  const values = useMemo(() => {
    if (!(tramite && reporteID)) {
      return null;
    }
    const _values = {
      ID: reporteID,
      Parametros: [
        {
          Nombre: 'tramite',
          TipoParametro: 'Texto',
          Valor: tramite.id,
        },
        {
          Nombre: 'clave',
          TipoParametro: 'Texto',
          Valor: tramite.id,
        },
      ],
    };
    Object.keys(attrs).forEach((attr) => {
      _values.Parametros.push({
        Nombre: attr,
        TipoParametro: 'Texto',
        Valor: attrs[attr],
      });
    });
    if (firmasElectronicas.length) {
      const cadena = firmasElectronicas[0]?.cadena;
      const firma_electronica = firmasElectronicas.map((f) => f.asString).join('\n')
        .concat(`CADENA ORIGINAL ${cadena}`);
      _values.Parametros.push({
        Nombre: 'firma_electronica',
        TipoParametro: 'Texto',
        Valor: firma_electronica,
      });
      firmasElectronicas.forEach((f, idx) => {
        _values.Parametros.push({
          Nombre: `firma_electronica_${idx + 1}`,
          TipoParametro: 'Texto',
          Valor: f,
        });
      });
      _values.Parametros.push({
        Nombre: 'cadena_original',
        TipoParametro: 'Texto',
        Valor: cadena,
      });
    }
    return _values;
  }, [tramite, reporteID]);

  const getDocumento = async () => {
    setLoading(true);
    const [err, __base64] = await postObtenerDocumento(values);
    if (callback) {
      callback(__base64, err);
      setError(err);
      return;
    }
    setLoading(false);
  };

  useEffect(() => {
    if (tramite && reporteID && !iFrameUrl) {
      getDocumento();
    }
  }, [reporteID]);

  return (
    <Previewer
      iFrameUrl={iFrameUrl}
      error={error === 'retry' ? <RetryError error={error} onClick={getDocumento} /> : error}
      modal={false}
      visible
      // controls={false}
      ifameHeight="50vh"
    />
  );
};

Documento.propTypes = {
  tramite: PropTypes.shape({
    attrs: PropTypes.shape({}),
    id: PropTypes.number,
  }),
  callback: PropTypes.func,
  reporteID: PropTypes.number,
  iFrameUrl: PropTypes.string,
  setLoading: PropTypes.func,
  firmasElectronicas: PropTypes.arrayOf(PropTypes.shape({
    cadena: PropTypes.string.isRequired,
    asString: PropTypes.string.isRequired,
  })),
  attrs: PropTypes.shape({}),
};

Documento.defaultProps = {
  attrs: null,
  callback: null,
  firmasElectronicas: [],
  iFrameUrl: null,
  reporteID: null,
  setLoading: () => null,
  tramite: null,
};

export default Documento;
