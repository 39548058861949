import { Result, Col, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const styles = {
  body: {
    position: 'fixed',
    zIndex: 9999,
    top: '50%',
    left: '50%',
    minWidth: '100%',
    minHeight: '100%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spin: {
    fontSize: '30px',
    margin: '0px 0px 0px 0px',
    padding: '0px 0px 0px 0px',
    color: 'rgba(0, 0, 0, 0.85)',
  },
};

const Spinner = ({
  visible,
  seconds,
}) => {
  const [generado, setGenerado] = useState(false);
  // eslint-disable-next-line no-promise-executor-return
  const delay = (ms) => new Promise((res) => setTimeout(res, (ms * 1000)));

  useEffect(() => {
    if (visible) {
      delay(seconds).then(() => {
        setGenerado(true);
      }).catch(() => {
        setGenerado(false);
      });
    }
  });

  return (
    visible && (
    <Col md={24}>
      <Result
        style={styles.body}
        status={generado ? 'success' : ''}
        icon={(visible && !generado) ?? <Spin delay={500} size="large" style={styles.spin} tip="" />}
        title={generado ? 'El recibo ha sido generado con éxito.' : 'Generando...'}
        subTitle="Espere un momento por favor."
      />
    </Col>
    )
  );
};
Spinner.propTypes = {
  visible: PropTypes.bool,
  seconds: PropTypes.number,
};

Spinner.defaultProps = {
  visible: false,
  seconds: 3,
};
export default Spinner;
