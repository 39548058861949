/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Modal, Row, message } from 'antd';
import SearchForm from 'views/Payments/components/SearchForm';
import gettersMap from 'api/padrones';
import { Button } from 'components';
import { padronesEnum } from 'views/Tramites/GPM/enums';

const ModalBusquedaPadron = ({
  visible,
  tipoDePadron,
  onCancel,
  onSelect: _onSelect,
  departamento,
  withGISInfo,
}) => {
  const [loading, setLoading] = useState(false);
  const [padron, setPadron] = useState();
  const [hasGISInfo, setHasGISInfo] = useState(false);

  const onFinishSearch = async (values) => {
    setLoading(true);
    const paramsByTipoDePadron = {
      [padronesEnum.PREDIO]: {
        q: `${values?.localidad}-${values?.sector}-${values?.manzana}-${values?.lote}-${values?.condominio}`,
      },
      [padronesEnum.VEHICULO]: {
        numero_de_identificacion_vehicular: values.serie,
        numero_de_placa_vigente: values.placa,
      },
      [padronesEnum.MOTOCICLETA]: {
        serie: values.serie,
        numero_de_placa_vigente: values.placa,
      },
      [padronesEnum.REMOLQUE]: {
        serie: values.serie,
        placa: values.placa,
      },
    };
    const params = paramsByTipoDePadron[tipoDePadron.id] || { q: values.q };
    const match = await gettersMap[tipoDePadron.id](params);
    if (match) {
      setPadron(match);
    } else {
      message.info(`${tipoDePadron.descripcion} no encontrado`);
    }
    setLoading(false);
  };

  const onSelect = () => {
    _onSelect({
      ...padron,
      censure: true,
    });
  };

  return (
    <Modal
      visible={visible}
      title={`Búsqueda de ${tipoDePadron.descripcion}`}
      onCancel={() => {
        onCancel();
        setPadron();
      }}
      width="60%"
      footer={null}
      destroyOnClose
    >
      <SearchForm
        padron={padron}
        onCancel={() => setPadron()}
        loading={loading}
        setLoading={setLoading}
        onFinishSearch={onFinishSearch}
        tipoDePadron={tipoDePadron.id}
        withGISInfo={withGISInfo}
        hasGISInfo={hasGISInfo}
        setHasGISInfo={setHasGISInfo}
        departamento={departamento}
        modal
      />
      {padron && (tipoDePadron.id !== 3 || hasGISInfo || !withGISInfo) && (
        <Row justify="end">
          <Button onClick={onSelect} $accent>
            Continuar con
            {' '}
            {tipoDePadron.descripcion}
            {' '}
            encontrado
          </Button>
        </Row>
      )}
    </Modal>
  );
};

export default ModalBusquedaPadron;
