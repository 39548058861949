import React, { useEffect, useState } from 'react';
import {
  Button,
  Tabs,
  Text,
} from 'components';
import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Spin,
} from 'antd';
import { formatReceived } from 'utils/formatters';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from 'store/auth';
import {
  InfoCircleOutlined,
  IdcardOutlined,
  EnvironmentOutlined,
  SaveOutlined,
  EditOutlined,
} from '@ant-design/icons';
import _logger from 'utils/logger';
import { getRegimenesFiscales, patchCiudadanoPublic, patchContribuyentePublic } from 'api/padrones';
import {
  AUTH_CONTRIBUYENTE, ENV_NAME, ENV_NAMES_ENUM, REPUVE,
} from 'utils/env';
import RegisterPadron from 'views/Payments/components/RegisterPadron';
import Select from 'components/Select';
import { getRegimenesCapitales, getTiposDePersonas } from 'api/catalogos';
// import { estadosDelPadronEnum } from 'utils/enums';
import LadaNumero from 'components/LadaNumero';
import Direccion from 'components/Direccion/index';
import { getEstadosCiviles, getGeneros } from 'api/usuarios';

const { TabPane } = Tabs;

const MisDatos = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const [form] = Form.useForm();
  const [formInfo] = Form.useForm();
  const [formDireccion] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [currentTabKey, setCurrentTabKey] = useState('general');
  const [isEditing, setIsEditing] = useState(false);

  const [tiposDePersona, setTiposDePersona] = useState([]);
  const [regimenesCapitales, setRegimenesCapitales] = useState([]);

  const [generos, setGeneros] = useState([]);
  const [estadosCiviles, setEstadosCiviles] = useState([]);

  useEffect(() => {
    let mounted = true;
    const fetchAll = async () => {
      const requests = AUTH_CONTRIBUYENTE ? [
        getTiposDePersonas(),
        getRegimenesCapitales(),
        getRegimenesFiscales(user?.regimen_fiscal),
      ] : [
        getGeneros(),
        getEstadosCiviles(),
      ];

      const responses = await Promise.all(requests);
      if (mounted) {
        if (AUTH_CONTRIBUYENTE) {
          setTiposDePersona(responses[0]);
          setRegimenesCapitales(responses[1]);
        } else {
          setGeneros(responses[0]);
          setEstadosCiviles(responses[1]);
        }
        const formattedUser = {
          ...formatReceived(user),
          regimenF: AUTH_CONTRIBUYENTE ? responses[2].descripcion : '',
        };
        form.setFieldsValue(formattedUser);
        formInfo.setFieldsValue(formattedUser);
        setLoading(false);
      }
    };
    fetchAll();
    return () => { mounted = false; };
  }, []);

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      await formInfo.validateFields();
      await formDireccion.validateFields();
      const values = {
        ...form.getFieldsValue(),
        ...formInfo.getFieldsValue(),
        direcciones: formDireccion.getFieldsValue(),
      };
      if (AUTH_CONTRIBUYENTE) {
        await patchContribuyentePublic(user.id, values, dispatch);
      } else {
        await patchCiudadanoPublic(user.id, values, dispatch);
      }
      setIsEditing(false);
      setLoading(false);
    } catch (error) {
      _logger(error);
      setLoading(false);
      if (error.errorFields) {
        const forms = [form, formInfo].map((f) => ({
          tabKey: f?.__INTERNAL__?.name,
          keys: f?.getFieldsError()?.map((e) => e.name[0]),
        }));
        const errorKeys = error.errorFields.map((e) => e.name[0]);
        const formWithErrors = forms.find((f) => f.keys.some((k) => errorKeys.includes(k)));
        if (formWithErrors) {
          setCurrentTabKey(formWithErrors.tabKey);
        }
      }
    }
  };

  return (
    <Spin spinning={loading} tip="Cargando...">
      <Row>
        <Col span={24}>
          <Tabs activeKey={currentTabKey} onChange={setCurrentTabKey} type="card">
            <TabPane
              tab={(
                <span>
                  <InfoCircleOutlined />
                  Información General
                </span>
              )}
              key="general"
            >
              <Form
                form={form}
                layout="vertical"
                onFinish={() => onFinish()}
                name="general"
              >
                <Row gutter={10}>
                  {!REPUVE && (
                    <>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name={AUTH_CONTRIBUYENTE ? 'clave' : 'clave_ciudadana'}
                          label={AUTH_CONTRIBUYENTE ? 'Clave' : 'Clave ciudadana'}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      {!AUTH_CONTRIBUYENTE && (
                        <>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              name="nombre"
                              label="Nombre(s)"
                            >
                              <Input disabled={!isEditing} />
                            </Form.Item>
                          </Col>

                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              name="apellido_paterno"
                              label="Apellido paterno"
                            >
                              <Input disabled={!isEditing} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              name="apellido_materno"
                              label="Apellido materno"
                            >
                              <Input disabled={!isEditing} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              name="genero"
                              label="Género"
                            >
                              <Select
                                options={generos}
                                getLabel={(e) => e.descripcion}
                                getValue={(e) => e.id}
                                disabled={!isEditing}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              name="estado_civil"
                              label="Estado civil"
                            >
                              <Select
                                options={estadosCiviles}
                                getLabel={(e) => e.descripcion}
                                getValue={(e) => e.id}
                                disabled={!isEditing}
                              />
                            </Form.Item>
                          </Col>
                          <LadaNumero
                            form={form}
                            names={{
                              lada: 'lada_celular',
                              numero_de_telefono: 'telefono_celular',
                            }}
                            label="Celular"
                            disabled={!isEditing}
                          />
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              name="email"
                              label="Correo electrónico"
                            >
                              <Input disabled={!isEditing} />
                            </Form.Item>
                          </Col>
                          {/* <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              name="email_alternativo"
                              label="Correo electrónico alternativo"
                            >
                              <Input disabled />
                            </Form.Item>
                          </Col> */}
                        </>
                      )}
                      {AUTH_CONTRIBUYENTE && (
                        <>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              name="tipo_de_persona"
                              label="Tipo de persona"
                            >
                              <Select options={tiposDePersona} disabled />
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={24} md={8}>
                            <Form.Item
                              name="rfc"
                              label="RFC"
                            >
                              <Input disabled />
                            </Form.Item>
                          </Col>
                          {user.tipo_de_persona === 1 && (
                          <>
                            <Col xs={24} sm={24} md={8}>
                              <Form.Item
                                name="curp"
                                label="CURP"
                              >
                                <Input disabled />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8}>
                              <Form.Item
                                name="nombre"
                                label="Nombre(s)"
                              >
                                <Input disabled />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8}>
                              <Form.Item
                                name="apellido_paterno"
                                label="Apellido paterno"
                              >
                                <Input disabled />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8}>
                              <Form.Item
                                name="apellido_materno"
                                label="Apellido materno"
                              >
                                <Input disabled />
                              </Form.Item>
                            </Col>
                          </>
                          )}
                          {user.tipo_de_persona === 2 && (
                          <>
                            <Col xs={24} sm={24} md={8}>
                              <Form.Item
                                name="razon_social"
                                label="Razón social"
                              >
                                <Input disabled />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8}>
                              <Form.Item
                                name="regimen_capital"
                                label="Régimen capital"
                              >
                                <Select disabled options={regimenesCapitales} />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8}>
                              <Form.Item
                                name="regimenF"
                                label="Régimen fiscal (SAT)"
                              >
                                <Input disabled />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8}>
                              <Form.Item
                                name="nombre_comercial"
                                label="Nombre comercial"
                              >
                                <Input disabled />
                              </Form.Item>
                            </Col>
                          </>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {REPUVE && (
                    <RegisterPadron
                      tipoDePadron={AUTH_CONTRIBUYENTE ? 15 : 1}
                      callback={() => null}
                      onCancel={() => null}
                      onlyItems
                      selfId={user.id}
                      loading={loading}
                      disabled={loading || !isEditing}
                      disabledContactInfo={loading || !isEditing}
                      general
                    />
                  )}
                </Row>
              </Form>
            </TabPane>
            <TabPane
              tab={(
                <span>
                  <IdcardOutlined />
                  {AUTH_CONTRIBUYENTE ? 'Datos de contacto' : 'Información de identidad'}
                </span>
              )}
              key="identidad"
            >
              <Form
                form={formInfo}
                layout="vertical"
                onFinish={() => onFinish()}
                name="identidad"
              >
                <Row gutter={10}>
                  {REPUVE && (
                    <RegisterPadron
                      tipoDePadron={AUTH_CONTRIBUYENTE ? 15 : 1}
                      callback={() => null}
                      onCancel={() => null}
                      onlyItems
                      selfId={user.id}
                      loading={loading}
                      disabled={loading || !isEditing}
                      identidad
                    />
                  ) }
                  {!AUTH_CONTRIBUYENTE && (
                    <>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="RFC"
                          label="RFC"
                        >
                          <Input disabled={!isEditing} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="CURP"
                          label="CURP"
                        >
                          <Input disabled={!isEditing} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="INE"
                          label="INE"
                        >
                          <Input disabled={!isEditing} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={8}>
                        <Form.Item
                          name="fecha_nacimiento"
                          label="Fecha de nacimiento"
                        >
                          <DatePicker disabled={!isEditing} />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                  {AUTH_CONTRIBUYENTE && (
                  <>
                    <LadaNumero
                      form={formInfo}
                      names={{
                        lada: 'lada',
                        numero_de_telefono: 'telefono',
                      }}
                      label="Teléfono"
                      disabled
                    />
                    <LadaNumero
                      form={formInfo}
                      names={{
                        lada: 'lada_celular',
                        numero_de_telefono: 'telefono_celular',
                      }}
                      label="Celular"
                      disabled
                    />
                    <Col xs={24} sm={24} md={8}>
                      <Form.Item
                        name="correo_electronico"
                        label="Correo electrónico"
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  </>
                  )}
                </Row>
              </Form>
            </TabPane>
            {AUTH_CONTRIBUYENTE && (
              <TabPane
                tab={(
                  <span>
                    <EnvironmentOutlined />
                    Dirección Fiscal
                  </span>
              )}
                key="direccion"
                forceRender
              >
                <Form
                  form={formDireccion}
                  layout="vertical"
                  onFinish={() => onFinish()}
                  name="direccion"
                  initialValues={{
                    estado: 'Nayarit',
                  }}
                >
                  <Direccion
                    isEditing={isEditing}
                    propName="direcciones"
                    setLoading={setLoading}
                    fatherObject={user}
                  />
                </Form>
              </TabPane>
            )}
          </Tabs>
        </Col>
      </Row>
      {!AUTH_CONTRIBUYENTE && ENV_NAME === ENV_NAMES_ENUM.JUAREZ && (
        <>
          <br />
          <Row justify="end">
            <Button
              $accent
              onClick={() => (isEditing ? onFinish() : setIsEditing(true))}
              loading={loading}
            >
              <Text>{isEditing ? 'Guardar' : 'Editar'}</Text>
              {isEditing ? <SaveOutlined /> : <EditOutlined />}
            </Button>
          </Row>
        </>
      )}
    </Spin>
  );
};

export default MisDatos;
