const mutableStatus = [1, 3, 2];
const usableStatus = [4, 3];

export const ESTADOS_GLOBALES_MAP = {
  ACTIVO: 1,
  EN_PROCESO: 2,
  INACTIVO: 3,
  AUTORIZADO: 4,
  CANCELADO: 5,
};

export const isMutable = (obj = {}) => {
  const statusId = obj?.estados_globales?.id || obj?.estados_globales;
  return !statusId || mutableStatus.includes(statusId);
};

export const isAuthorized = (obj = {}) => {
  const statusId = obj.estados_globales?.id || obj.estados_globales;
  return !statusId || statusId === 4;
};

export const isUsable = (obj = {}) => {
  const statusId = obj.estados_globales?.id || obj.estados_globales;
  return !statusId || usableStatus.includes(statusId);
};

export const isSpecificGlobalStatus = (obj, status) => {
  const statusId = obj.estados_globales?.id || obj.estados_globales;
  return statusId === status;
};
