import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Input,
  Form,
  DatePicker,
  Button,
  Row,
} from 'antd';
import sortCargos from 'utils/sorterCargos';
import {
  DECIMAL_PATTERN,
  decimalNormalizer,
  toInteger,
  toMoment,
} from 'utils/normalizers';
import { aplicacionesCargosEnum, tiposDatosEnum } from 'views/Tramites/GPM/enums';
import TableAdeudo from 'views/Tramites/GPM/components/TableAdeudo';
import Variables, { getVariableRules } from 'views/Tramites/GPM/components/Variables';
import { PROD } from 'utils/env';
import { Text } from 'components';
import NetPaybutton, { paymentLocationsEnum } from 'views/Payments/components/NetPayButton';
import { DATE_FORMAT, toCurrency } from 'utils/formatters';
import Select from 'components/Select';

const mapId = (c) => c.id;
const ENV_NAME = process.env.REACT_APP_ENV_NAME;

const isExpired = (record) => {
  if (!record?.fecha_de_vencimiento) return true;
  return toMoment(record.fecha_de_vencimiento, DATE_FORMAT).isBefore(new Date());
};

export const getMandatoryKeys = (cargosTramite, mandatoryTypes = []) => {
  const matches = cargosTramite
    .filter((c) => !c.es_accesorio
      && mandatoryTypes.includes(c.tipo_de_cargo?.id) && isExpired(c.tipo_de_cargo))
    .map(mapId);
  const accesories = matches
    .map((m) => cargosTramite.filter((c) => c.es_accesorio && c.cargo_padre === m).map(mapId))
    .flat();
  return [...matches, ...accesories];
};

export const tiposDeAplicacionesAdmitidas = [
  aplicacionesCargosEnum.FIJO,
  aplicacionesCargosEnum.FORMULA,
  aplicacionesCargosEnum.MULTIPLO,
  aplicacionesCargosEnum.PORCENTAJE,
  aplicacionesCargosEnum.TARIFA,
  aplicacionesCargosEnum.SQL_FUNCTION,
];

const renderCapturaDeImporte = (tc, catalogoDeVariables, disabled, tarifas) => {
  if (tc.tipo_de_aplicacion === aplicacionesCargosEnum.CAPTURABLE) {
    return (
      <Form.Item
        label={`Importe ${(tc.importe_minimo || tc.importe_maximo) && `(${tc.importe_minimo} - ${tc.importe_maximo})`}`}
        name={[tc.id, 'importe']}
        rules={[
          {
            validator: async (rule, value) => {
              if (value) {
                if (!DECIMAL_PATTERN.test(value)) {
                  throw new Error('Ingrese un decimal válido');
                }
                if (value < tc.importe_minimo) {
                  throw new Error('Ingrese una cantidad mayor/igual al importe mínimo del cargo');
                }
                if (value > tc.importe_maximo) {
                  throw new Error('Ingrese una cantidad menor/igual al importe máximo del cargo');
                }
              } else {
                throw new Error('El campo es requerido');
              }
            },
          },
        ]}
        normalize={(val) => decimalNormalizer(val)}
      >
        <Input disabled={!!disabled} />
      </Form.Item>
    );
  }
  // TODO: Por revisar a fondo, nayarit no usa tarifa actualmente
  if ([aplicacionesCargosEnum.TARIFA, aplicacionesCargosEnum.MULTIPLO]
    .includes(tc.tipo_de_aplicacion)) {
    if (tc.tipo_de_dato) {
      return (
        <Form.Item
          name={[tc.id, tc.catalgo_de_tarifa ? 'variable' : 'importe']}
          label={`Introduzca ${tc.parametro_descripcion}`}
          rules={getVariableRules(tc.tipo_de_dato)}
          normalize={(val) => {
            if (tc.tipo_de_dato === tiposDatosEnum.ENTERO) {
              return toInteger(val);
            }
            if (tc.tipo_de_dato === tiposDatosEnum.DECIMAL) {
              return decimalNormalizer(val);
            }
            return val;
          }}
        >
          {tc.tipo_de_dato.id === tiposDatosEnum.RANGO_DE_FECHAS ? (
            <DatePicker.RangePicker
              disabled={!!disabled}
              disabledDate={(date) => date.isAfter(new Date())}
            />
          ) : (
            <Input disabled={!!disabled} allowClear />
          )}
        </Form.Item>
      );
    }
    return (
      <Form.Item
        name={[tc.id, 'importe']}
        rules={[{ required: true }]}
        label="Concepto"
      >
        <Select
          disabled={!!disabled}
          options={tarifas.filter((t) => t.catalogo_de_tarifa === tc.catalogo_de_tarifa)}
          getLabel={(e) => `${e.concepto} - ${toCurrency(e.impuesto_por_pagar || 0)}`}
        />
      </Form.Item>
    );
  }

  return (
    <Variables
      catalogoDeVariables={catalogoDeVariables}
      variables={tc.variables}
      prename={tc.id}
      disabled={disabled}
    />
  );
};

const Cargos = ({
  disabled: _disabled,
  cargosTramite,
  getAcuseTramite,
  catalogoDeVariables,
  getRecibo,
  imprimirOrdenDePago,
  setCargosSaldados,
  cargosSaldados,
  referencia,
  loading,
  padron,
  solicitante,
  tiposDeCargos,
  tiposDeCargosConsulta,
  id_reporte_acuse,
  tarifas,
}) => {
  const disabled = cargosSaldados || _disabled;
  const tiposDeCargosNoGenerados = tiposDeCargos
    .filter((t) => !cargosTramite.some((c) => c.tipo_de_cargo.id === t.id));
  const mandatoyKeys = getMandatoryKeys(cargosTramite, tiposDeCargosConsulta);
  const _cargosConsulta = mandatoyKeys.map((k) => cargosTramite.find((c) => c.id === k));
  const _cargosGenerados = cargosTramite.filter((cargo) => {
    if (cargo.es_accesorio) {
      const cargoPadre = cargosTramite.find((c) => c.id === cargo.cargo_padre);
      return tiposDeCargos.some((tc) => tc.id === cargoPadre.tipo_de_cargo.id);
    }
    return tiposDeCargos.some((tc) => tc.id === cargo.tipo_de_cargo.id);
  });

  const cargosConsulta = useMemo(() => sortCargos(_cargosConsulta), []);

  const cargosGenerados = useMemo(() => sortCargos(_cargosGenerados), [_cargosGenerados]);

  const _cargos = [...cargosGenerados, ...cargosConsulta];

  useEffect(() => {
    const _cargosSaldados = _cargos.length && _cargos.every((c) => c.saldado);
    if (tiposDeCargos.length && !_cargosGenerados.length) {
      setCargosSaldados(false);
    } else {
      setCargosSaldados(_cargosSaldados);
    }

    if (_cargosSaldados) {
      getRecibo(_cargos[0].id, false);
    }
  }, []);

  const hasCargos = (tiposDeCargos.length ? !!cargosGenerados.length : !!cargosConsulta.length);

  const renderCargo = (cargo, type = 'success') => (
    <Col span={24} key={cargo.id}>
      <Text type={type}>
        {type === 'warning' && '(Recargo) '}
        {cargo.es_accesorio && '(Accesorio) '}
        {[cargo.tipo_de_cargo?.periodo_fiscal?.periodo, cargo.descripcion].filter((e) => e).join(' - ')}
      </Text>
      {!cargo.saldado && (
        <Text type={type} style={{ float: 'right' }}>
          {toCurrency(cargo.adeudo_total || cargo.importe_total)}
        </Text>
      )}
      {cargosGenerados.filter((e) => e.cargo_padre === cargo.id && e.es_accesorio)
        .map((ac) => renderCargo(ac))}
      {!!cargo?.recargos?.length && cargo.recargos.map((r) => renderCargo(r, 'warning'))}
    </Col>
  );

  return (
    <>
      {!!tiposDeCargos.length && !cargosGenerados.length && (
        <>
          <Text strong>
            Al proceder con el trámite se generará el adeudo por los siguientes conceptos:
          </Text>
          <Col span={24} style={{ marginTop: 10 }} />
          {tiposDeCargosNoGenerados.map((tc) => (
            <Col span={24} key={tc.id} style={{ marginBottom: 5 }}>
              <Text
                delete={cargosGenerados.some((c) => c.tipo_de_cargo.id === tc.id)}
                type={cargosGenerados.length ? 'warning' : null}
              >
                <b>
                  {`${tc.clave}`}
                </b>
                {` - ${tc.descripcion}`}
              </Text>
              {!PROD && !tiposDeAplicacionesAdmitidas.includes(tc.tipo_de_aplicacion)
                && 'TEST ONLY: tipo_de_aplicacion invalido'}
              {renderCapturaDeImporte(tc, catalogoDeVariables, _disabled, tarifas)}
            </Col>
          ))}
          <Col span={24} style={{ marginBottom: 40 }} />
        </>
      )}
      {!!cargosGenerados.length && (
        <>
          <Row style={{ width: '100%' }}>
            <Text style={{ fontSize: 20 }}>
              Cargos
              {' '}
              {cargosSaldados ? 'saldados' : 'generados'}
            </Text>
          </Row>
          <Row style={{ width: '100%', paddingRight: 30 }}>
            {cargosGenerados.filter((e) => !e.es_accesorio).map((cargo) => renderCargo(cargo))}
          </Row>
        </>
      )}
      {(!!cargosGenerados.length || !!cargosConsulta.length)
        && (<Col span={24} style={{ marginTop: 20 }} />)}
      {!!tiposDeCargosConsulta.length && !!cargosConsulta.length && (
        <Col span={24}>
          <Row>
            <Text style={{ fontSize: 20 }}>
              Adeudo
            </Text>
          </Row>
          <Col span={24} style={{ padding: '15px 10px 30px 0' }}>
            <TableAdeudo
              cargos={cargosConsulta}
              selectedRowKeys={cargosConsulta.map((c) => c.id)}
              tableProps={{
                restHeigth: 250,
                rowSelection: null,
                onRow: null,
              }}
            />
          </Col>
        </Col>
      )}
      {(!!cargosGenerados.length || !!cargosConsulta.length)
        && (<Col span={24} style={{ marginTop: 20 }} />)}
      {id_reporte_acuse && (
        <Col span={8}>
          <Button block onClick={getAcuseTramite}>
            Obtener Acuse de Trámite
          </Button>
        </Col>
      )}
      <br />
      {cargosSaldados && (
        <Col span={8}>
          <Button block onClick={() => getRecibo(_cargos[0].id)}>
            Obtener
            {' '}
            {ENV_NAME !== 'juarez' ? 'Recibo' : 'Ticket'}
          </Button>
        </Col>
      )}
      {!cargosSaldados && hasCargos && (
        <>
          {!PROD && (
            <Col span={8}>
              <Button
                block
                onClick={() => imprimirOrdenDePago(_cargos)}
                disabled={disabled}
              >
                Obtener Orden de Pago
              </Button>
            </Col>
          )}
          <NetPaybutton
            referencia={referencia}
            loading={loading}
            cargos={cargosGenerados}
            padron={{
              ...padron,
              solicitante,
              propNameContactInfo: 'solicitante',
            }}
            createdBy={paymentLocationsEnum.GPM}
            generarReferencia={() => imprimirOrdenDePago(_cargos, false, true)}
          />
        </>
      )}
    </>
  );
};

Cargos.propTypes = {
  disabled: PropTypes.bool,
  cargosTramite: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number.isRequired })).isRequired,
  tiposDeCargos: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number.isRequired })),
  tiposDeCargosConsulta: PropTypes.arrayOf(PropTypes.number),
  getAcuseTramite: PropTypes.func,
  catalogoDeVariables: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getRecibo: PropTypes.func.isRequired,
  imprimirOrdenDePago: PropTypes.func.isRequired,
  cargosSaldados: PropTypes.bool,
  setCargosSaldados: PropTypes.func.isRequired,
  referencia: PropTypes.string,
  loading: PropTypes.bool,
  solicitante: PropTypes.shape({}).isRequired,
  padron: PropTypes.shape({}).isRequired,
  id_reporte_acuse: PropTypes.number,
  tarifas: PropTypes.arrayOf(PropTypes.shape({ })),
};

Cargos.defaultProps = {
  disabled: false,
  cargosSaldados: false,
  referencia: null,
  loading: false,
  tiposDeCargos: null,
  tiposDeCargosConsulta: [],
  id_reporte_acuse: null,
  getAcuseTramite: null,
  tarifas: [],
};

export default Cargos;
