import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Typography,
  Row,
  Col,
} from 'antd';
import { Button } from 'components';

const { Text } = Typography;

const ModalConfirm = ({
  onFinish,
  visible,
  body,
  btnText,
  setVisible,
  callback,
  loading,
}) => (
  <Modal
    title={(<Text>Confirmación</Text>)}
    visible={visible}
    footer={null}
    closable={false}
  >
    <Row justify="center">
      <Text strong style={{ fontSize: 20, textAlign: 'center' }}>
        {body || 'Al generar no podrá ser modificado. ¿Desea continuar?.'}
      </Text>
      <Col style={{ display: 'flex', gap: '15px', marginTop: '20px' }}>
        <Button
          style={{ borderRadius: 5 }}
          onClick={() => {
            if (typeof callback === 'function') callback();
            setVisible(false);
          }}
          $accent
        >
          No, Cancelar
        </Button>
        <Button
          style={{ borderRadius: 5 }}
          onClick={() => {
            if (typeof onFinish === 'function') onFinish();
            setVisible(false);
          }}
          loading={loading}
          $accent
        >
          {btnText}
        </Button>
      </Col>
    </Row>
  </Modal>
);

ModalConfirm.propTypes = {
  onFinish: PropTypes.func,
  visible: PropTypes.bool,
  body: PropTypes.string.isRequired,
  setVisible: PropTypes.func.isRequired,
  btnText: PropTypes.string,
  callback: PropTypes.func,
  loading: PropTypes.bool,
};

ModalConfirm.defaultProps = {
  onFinish: null,
  visible: false,
  loading: false,
  callback: null,
  btnText: 'Generar, reporte',
};

export default ModalConfirm;
