import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col } from 'antd';
import styled from 'styled-components';
import { Text } from 'components';
import { getTramitesCount } from 'api/tramites/index';
import { CATALOGOS_DE_CANAL_DE_PAGO } from 'utils/env';
import Skeletons from './SkeletosWrappers';

const initialData = {
  tramites: 0,
  servicios: 0,
  en_linea: 0,
};

const { SkeletonWrapperText } = Skeletons;

const CardsCount = ({
  callback,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(initialData);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      const response = await getTramitesCount();
      if (isMounted) {
        setData(response);
        setLoading(false);
      }
    };
    fetchData();
    return () => { isMounted = false; };
  }, []);

  return (
    <Col xs={24} sm={24} md={24} xl={4}>
      <Container>
        <CustomCard
          title={(<Text>Trámites</Text>)}
          bordered={false}
          onClick={() => callback({ clasificacion: true })}
        >
          <SkeletonWrapperText loading={loading} center>
            <Text>{data.tramites + data.en_linea}</Text>
          </SkeletonWrapperText>
        </CustomCard>
        <CustomCard
          title={(<Text>Servicios</Text>)}
          bordered={false}
          onClick={() => callback({ clasificacion: false })}
        >
          <SkeletonWrapperText loading={loading} center>
            <Text>{data.servicios}</Text>
          </SkeletonWrapperText>
        </CustomCard>
        <CustomCard
          title={(<Text>En línea</Text>)}
          bordered={false}
          onClick={() => callback({
            catalogo_de_canales_de_pago: CATALOGOS_DE_CANAL_DE_PAGO.PAGO_EN_LINEA,
          })}
        >
          <SkeletonWrapperText loading={loading} center>
            <Text>{data.en_linea}</Text>
          </SkeletonWrapperText>
        </CustomCard>
      </Container>
    </Col>
  );
};

CardsCount.propTypes = {
  callback: PropTypes.func,
};

CardsCount.defaultProps = {
  callback: () => {},
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-direction: column;
  gap: 1rem;
  width: -webkit-fill-available;

  @media screen and (max-width: 1200px) {
    flex-direction: row;
  }
`;

const CustomCard = styled(Card)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
`;

export default CardsCount;
