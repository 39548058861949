import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import {
  Requisitos,
  groupsByCategories,
} from 'views/Tramites/GPM/components/RequisitosDelTramite';
import { Title } from 'components';
import RequisitosIcon from 'assets/RequisitosIcon';
import { padronesEnum } from '../GPM/enums';

const { Panel } = Collapse;

const fontSize = 26;
const expandIcon = () => <RequisitosIcon style={{ fontSize }} />;

const RequisitosTab = ({
  plantilla,
  categoriasDeRequisitos,
  catalogosDeRequisitos,
  requisitos,
  tiposDeRequisitos,
  unidadesDePlazo,
  pasosRequisitosMostrar,
  defaultExpanded,
}) => {
  const [defaultActiveKey, setDefaultActiveKey] = useState(['0']);
  const [activeKey, setActiveKey] = useState([]);

  useEffect(() => {
    const casosDeLosRequisitos = plantilla?.casos_de_los_requisitos?.length
      ? plantilla?.casos_de_los_requisitos.map((caso) => 'caso'.concat(caso?.id)) : [];
    const _defaultActiveKey = defaultExpanded ? [...casosDeLosRequisitos] : [];
    setDefaultActiveKey((prev) => [...prev, ..._defaultActiveKey]);
  }, [plantilla]);

  useEffect(() => {
    const pasos = pasosRequisitosMostrar?.length
      ? pasosRequisitosMostrar.map((paso) => 'paso'.concat(paso.id)) : [];
    setDefaultActiveKey((prev) => [...prev, ...pasos]);
  }, [pasosRequisitosMostrar]);

  useEffect(() => {
    if (plantilla?.requisitos_generales.length) {
      setDefaultActiveKey((prev) => [...prev, '0']);
    }
  }, [plantilla]);

  useEffect(() => {
    if (defaultActiveKey.length) {
      defaultActiveKey.forEach((key) => {
        if (activeKey.indexOf(key) === -1) {
          setActiveKey((prev) => [...prev, key]);
        }
      });
    }
  }, [defaultActiveKey]);

  return (
    <Collapse
      ghost
      expandIcon={expandIcon}
      defaultActiveKey={defaultActiveKey}
      activeKey={activeKey}
    >
      {!!plantilla?.requisitos_generales.length && (
        <Panel
          header={(
            <Title level={4}>
              Requisitos generales
            </Title>
          )}
          key="0"
          className="collapse-child"
          forceRender
        >
          <Requisitos
            categorias={groupsByCategories(
              categoriasDeRequisitos,
              catalogosDeRequisitos,
              plantilla?.requisitos_generales
                .map((r) => requisitos.find((_r) => _r.id === r.id)),
              tiposDeRequisitos,
              [],
              [],
              padronesEnum.CIUDADANO,
              plantilla?.tipo_de_padron,
            )}
            renderInfoRequisito
            interactive={false}
            unidadesDePlazo={unidadesDePlazo}
          />
        </Panel>
      )}
      {plantilla?.casos_de_los_requisitos?.map((caso) => (
        <Panel
          key={'caso'.concat(caso?.id)}
          header={(<Title level={4}>{caso.nombre}</Title>)}
          className="collapse-child"
          forceRender
        >
          <Requisitos
            categorias={groupsByCategories(
              categoriasDeRequisitos,
              catalogosDeRequisitos,
              caso.requisitos.map((r) => requisitos.find((_r) => _r.id === r.id)),
              tiposDeRequisitos,
              [],
              [],
              padronesEnum.CIUDADANO,
              plantilla?.tipo_de_padron,
            )}
            renderInfoRequisito
            interactive={false}
            unidadesDePlazo={unidadesDePlazo}
          />
        </Panel>
      ))}
      {pasosRequisitosMostrar?.map((paso) => (
        <Panel
          key={'paso'.concat(paso.id)}
          header={(<Title level={4}>{paso.nombre}</Title>)}
          className="collapse-child"
          forceRender
        >
          <Requisitos
            categorias={groupsByCategories(
              categoriasDeRequisitos,
              catalogosDeRequisitos,
              paso.requisitos.map((r) => requisitos.find((_r) => _r.id === r.id)),
              tiposDeRequisitos,
              [],
              [],
              padronesEnum.CIUDADANO,
              plantilla?.tipo_de_padron,
            )}
            renderInfoRequisito
            interactive={false}
            unidadesDePlazo={unidadesDePlazo}
          />
        </Panel>
      ))}
    </Collapse>
  );
};

RequisitosTab.propTypes = {
  plantilla: PropTypes.shape({
    requisitos_generales: PropTypes.arrayOf(PropTypes.number),
    casos_de_los_requisitos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      nombre: PropTypes.string,
      requisitos: PropTypes.arrayOf(PropTypes.number),
    })),
    tipo_de_padron: PropTypes.string,
  }),
  categoriasDeRequisitos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    nombre: PropTypes.string,
    descripcion: PropTypes.string,
  })),
  catalogosDeRequisitos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    nombre: PropTypes.string,
    descripcion: PropTypes
      .oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    requisitos: PropTypes.arrayOf(PropTypes.number),
  })),
  requisitos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    nombre: PropTypes.string,
    descripcion: PropTypes.string,
    tipo_de_requisito: PropTypes.number,
    plazo: PropTypes.number,
    unidad_de_plazo: PropTypes.string,
    catalogo: PropTypes.number,
    categoria: PropTypes.number,
    requisitos: PropTypes.arrayOf(PropTypes.number),
  })),
  tiposDeRequisitos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    nombre: PropTypes.string,
    descripcion: PropTypes.string,
  })),
  unidadesDePlazo: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    nombre: PropTypes.string,
    descripcion: PropTypes
      .oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  })),
  pasosRequisitosMostrar: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    nombre: PropTypes.string,
    descripcion: PropTypes.string,
    requisitos: PropTypes.arrayOf(PropTypes.number),
  })),
  defaultExpanded: PropTypes.bool,
};

RequisitosTab.defaultProps = {
  plantilla: {},
  categoriasDeRequisitos: [],
  catalogosDeRequisitos: [],
  requisitos: [],
  tiposDeRequisitos: [],
  unidadesDePlazo: [],
  pasosRequisitosMostrar: [],
  defaultExpanded: false,
};

export default RequisitosTab;
