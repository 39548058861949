import API from 'api';
import { message } from 'antd';
import getFormattedValues from 'utils/formatValues';
import _logger from 'utils/logger';
import { toMoment } from 'utils/normalizers';
import { DATE_FORMAT } from 'utils/formatters';
import { CATALOGOS_DE_CANAL_DE_PAGO } from 'utils/env';
import { postHistorialDeCita } from './historialDeCita';

export const baseURI = 'recaudacion/citas-public/';

/**
 * GET Citas
 * param {object} params
 * @returns {Promise<object>}
 */
export const getCitas = async (params) => {
  try {
    const response = await API.get(baseURI, { params });
    return response.data.map(({ fecha_cita, ...c }) => ({
      ...c,
      fecha_cita: toMoment(fecha_cita),
      horario_inicio: toMoment(`${fecha_cita} ${c.horario_inicio}`, [`${DATE_FORMAT} hh:mm:ss`]),
      horario_fin: toMoment(`${fecha_cita} ${c.horario_fin}`, [`${DATE_FORMAT} hh:mm:ss`]),
    }));
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * POST Cita
  * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postCita = async (_values) => {
  try {
    const values = getFormattedValues({
      ..._values,
      catalogo_canal_de_pago: CATALOGOS_DE_CANAL_DE_PAGO.PAGO_EN_LINEA,
    });
    const response = await API.post(baseURI, values);
    await postHistorialDeCita({
      cita: response.data.id,
      observaciones: 'Cita programada',
      estado_de_cita: 1,
    });
    message.info(response.detail || 'Agregado correctamente');
    return response.data;
  } catch (err) {
    _logger(err);
    return false;
  }
};

/**
 * PATCH Cita
 * @param {number} id
 * @param {number} razon_de_rechazo_cita
 * @returns {Promise<object|boolean>}
 */
export const patchCancelarCita = async (id, razon_de_rechazo_cita) => {
  try {
    const response = await API.patch(`${baseURI}${id}`, {
      estados_globales: 5,
      estado_de_cita: 5,
      razon_de_rechazo_cita,
    });
    message.info(response.detail || 'Editado correctamente');
    await postHistorialDeCita({
      cita: id,
      observaciones: 'Cita cancelada',
      estado_de_cita: 5,
    });
    return response.data;
  } catch (err) {
    _logger(err);
    return false;
  }
};
