import axios from 'axios';
import _logger from 'utils/logger';
import API from 'api/index';
import { toCurrency } from 'utils/formatters';
import { DATETIME_FORMAT, formatReceived } from 'utils/formatValues';
// import getExpiryDate from 'utils/getExpiryDate';
import {
  NETPAY_PRIVATE_KEY,
  NETPAY_BASE_URL,
  ID_ENTIDAD,
  CATALOGOS_DE_CANAL_DE_PAGO,
  ENV_NAME,
} from 'utils/env';
import round from 'utils/round';
import { setClasificadoresDeTiposDeCargos } from 'store/catalogos';
import { toMoment } from 'utils/normalizers';
import {
  selectConfiguracionEntidad,
  selectToken,
} from 'store/auth';
import store from 'store/store';
import { message } from 'antd';
import { padronesEnum } from 'views/Tramites/GPM/enums';
import { findCiudadano } from './padrones';

export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_NETPAY_FORMAT = 'YYYY/MM/DD';

// SI LA FECHA DE VENCIMIENTO ES IGUAL A LA FECHA ACTUAL SE QUEDA EN LA FECHA ACTUAL.
// DESPUES DEL LA FECHA DE VENCIMIENTO SE VA HASTA EL FIN DEL MES.
// ANTES DE LA FECHA DE VENCIMIENTO SE QUEDA LA FECHA DE VENCIMIENTO.
// SI ES FINAL DEL MES LO MANDAMOS AL FINAL DEL MES SIGUIENTE.
// SINO TIENE FECHA LO MANDAMOS AL FINAL DEL MES Y SI ES FINAL DEL MES NOS MANDA AL OTRO MES.
const calculateExpiryDate = (cargos, allowInpc = false) => {
  const entidadInfo = selectConfiguracionEntidad(store.getState());
  const INPC = entidadInfo?.dia_de_mes_para_actualizacion_inpc || 0; // es 10

  let expiryDate = null;
  const currentDate = toMoment(new Date());
  const lastDayInstance = toMoment(new Date()).endOf('month');
  const vencimiento = toMoment(cargos.find((c) => c?.tipo_de_cargo?.fecha_de_vencimiento)
    ?.tipo_de_cargo?.fecha_de_vencimiento) || null;

  if (INPC !== 0 && allowInpc) {
    const INPC_DATE = toMoment(new Date()).set('date', INPC);

    if (INPC_DATE.isSame(currentDate, 'day')) {
      expiryDate = currentDate;
    } else if (currentDate.isSame(lastDayInstance, 'day')) {
      expiryDate = toMoment(new Date()).add(1, 'month').endOf('month');
    } else if (INPC_DATE.isAfter(currentDate)) {
      expiryDate = INPC_DATE;
    } else if (INPC_DATE.isBefore(currentDate)) {
      expiryDate = lastDayInstance;
    }

    return toMoment(expiryDate).format(DATE_NETPAY_FORMAT);
  }

  if ((vencimiento) && (vencimiento).isSame(currentDate, 'day')) {
    expiryDate = currentDate;
  } else if ((vencimiento) && (vencimiento).isAfter(currentDate)) {
    expiryDate = vencimiento;
  } else if (vencimiento && vencimiento.isBefore(currentDate)) {
    expiryDate = lastDayInstance;
  } else if (!vencimiento && currentDate.isBefore(lastDayInstance)) {
    expiryDate = lastDayInstance;
  } else {
    expiryDate = toMoment(new Date()).add(1, 'month').endOf('month');
  }

  return toMoment(expiryDate).format(DATE_NETPAY_FORMAT);
};

/**
 * GET Tipos de Cargos
 * @param {object} params
 * @param {string} params.q
 * @returns {Promise<object[]>}
 */
export const getTiposDeCargos = async (params = null) => {
  try {
    const response = await API.get('recaudacion/tipos-de-cargos-public', { params });
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return { results: [] };
};

/**
 * GET Clasificadores y Sub Clasificadores de Tipos de Cargo
 * @param {object} params
 * @returns {Promise<object[]>}
 */
export const getClasificadoresDeTiposDeCargo = async (dispatch) => {
  try {
    const response = await API.get('recaudacion/clasificadores-de-tipos-de-cargo-portal-public');
    const data = {};
    data.clasificadores = response.data.filter((e) => e.clasificador.length === 2);
    data.subClasificadores = response.data.filter((e) => e.clasificador.length === 5);
    if (dispatch) {
      dispatch(setClasificadoresDeTiposDeCargos(data));
    }
    return data;
  } catch (error) {
    _logger(error);
    return null;
  }
};

export const generarReciboPorNetPay = async (values) => {
  try {
    // ENVIAR INFO AL BACKEND DE LOS CAMPOS QUE SE VAN A USAR
    const response = await API.post('/recaudacion/recibo/generar-recibo-por-referencia-de-pago-netpay/', values);
    return {
      ...response.data,
      base64: `data:application/pdf;base64,${response.data.data}`,
    };
  } catch (error) {
    _logger(error, true);
    return null;
  }
};

export const postGenerarDeclaracion = async (values) => {
  try {
    const response = await API.post('empresas/declaraciones-empresariales-public/', values);
    return response.data;
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

export const patchGenerarDeclaracion = async (values) => {
  try {
    const response = await API.patch(`empresas/declaraciones-empresariales-public/${values.id}`, {
      estatus_de_declaracion_empresarial: values.estatus_de_declaracion_empresarial,
      razones_para_presentar_en_ceros: values.razones_para_presentar_en_ceros,
      numero_de_habitaciones: values?.numero_de_habitaciones || null,
      numero_de_empleados: values?.numero_de_empleados || null,
      entidad: 1,
    });
    return response.data;
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

/**
 * GET Declaracion en PDF
 * @param {number} id
 * @returns {Promise<string>} base64
 */
export const getDeclaracionPDF = async (id) => {
  try {
    const response = await API.get(`/empresas/declaraciones-empresariales-public/${id}/pdf/`);
    return `data:application/pdf;base64,${response.data.pdf}`;
  } catch (error) {
    _logger(error);
  }
  return null;
};

/**
 * GET Declaraciones
 * @param {object} param
 * @returns {Promise<string>} base64
 */
export const getDeclaraciones = async (params = null) => {
  try {
    const response = await API.get('/empresas/declaraciones-empresariales-public/', { params });
    return formatReceived(response.data);
  } catch (error) {
    _logger(error);
  }
  return null;
};

/**
 * POST Obtener cargo de padron
 * @param {object} values
 * @param {number} values.padron
 * @param {number} values.padron_id
 * @returns {Promise<object[]>}
 */
export const getCargosPadron = async (values, pub = true) => {
  try {
    const config = {};
    if (!pub) {
      const token = selectToken(store.getState());
      config.headers = { Authorization: `Bearer ${token}` };
    }
    const response = await API.post(`recaudacion/consulta-caja${pub ? '-public/entidad/' : '/padron'}`, {
      ...values,
      entidad: ID_ENTIDAD,
      canal_de_pago: ENV_NAME !== 'juarez' ? CATALOGOS_DE_CANAL_DE_PAGO.PORTAL_FINANCIERO : CATALOGOS_DE_CANAL_DE_PAGO.PAGO_EN_LINEA,
    }, config);
    if (Array.isArray(response.data)) {
      const { cargos, ...padronInfo } = response.data[0];
      return [null, cargos, padronInfo];
    }
    const { cargos, ...padronInfo } = response.data;
    return [null, cargos, padronInfo];
  } catch (error) {
    _logger(error);
    return [error.response?.data?.detail || 'Función no disponible, vuelva a intentarlo más tarde.', []];
  }
};

/**
 * Generar Cargos
 * @param {object} values
 * @returns {object} {success, failed, base64}
 */
const generarCargo = async (values) => {
  try {
    const response = await API.post('recaudacion/generar-cargos-con-canal-de-pago', values);
    const { data: [cargoPadre, { cargos_hijos = [] } = {}] } = response;
    const data = [cargoPadre, ...cargos_hijos];
    return { data };
  } catch (_error) {
    _logger(_error.message);
    if (_error?.response?.headers['content-type'].includes('application/json')) {
      let error = _error.response.data.detail || _error.response.data;
      if (_error.response.data.detail) {
        return { tipo_de_cargo: values.tipo_de_cargo, error };
      }
      const keys = Object.keys(error);
      error = keys.length ? error[keys[0]] : 'Error desconocido';
      return { tipo_de_cargo: values.tipo_de_cargo, error };
    }
    return { tipo_de_cargo: values.tipo_de_cargo, error: _error.message };
  }
};

/**
 * POST Generar Cargos Publicos
 * @param {object[]} requestsValues
 * @param {number} requestsValues.tipo_de_cargo
 * @param {number} requestsValues.padron
 * @param {number} requestsValues.padron_id
 * @param {number} requestsValues.canal_de_pago
 * @param {number} requestsValues.entidad
 * @param {number} requestsValues.cantidad
 * @param {object[]} requestsValues.variables
 * @param {number} requestsValues.variables.id
 * @param {string} requestsValues.variables.nombre
 * @param {number} requestsValues.variables.valor
 */
export const postGenererCargos = async (
  _requestsValues,
  generarDeclaracion = false,
  inZeros = false,
  decValues = {},
) => {
  const output = {
    success: [],
    successDiff: [],
    failed: [],
    declaracion: null,
  };
  if (!inZeros) {
    const requestsValues = _requestsValues
      .map(({ cantidad, ...e }) => new Array(cantidad).fill(e)).flat();
    const promises = requestsValues.map((reqValues) => generarCargo(reqValues));
    const responses = await Promise.allSettled(promises);
    const success = responses.filter((r) => r.value.data).map((r) => r.value.data).flat();
    const failed = responses.filter((r) => r.value.error).map((r) => r.value);
    output.success = success;
    output.successDiff = [...new Set(success.map((c) => c.tipo_de_cargo?.id || c.tipo_de_cargo))]
      .map((tc) => {
        const tipoDeCargo = success.find((c) => (c.tipo_de_cargo.id || c.tipo_de_cargo) === tc);
        const qty = success.filter((c) => (c.tipo_de_cargo.id || c.tipo_de_cargo) === tc).length;
        return {
          ...tipoDeCargo,
          importe: tipoDeCargo.importe * qty,
          qty,
        };
      });
    output.failed = [...new Set(failed.map((c) => c.tipo_de_cargo))]
      .map((tc) => {
        const qty = failed.filter((c) => c.tipo_de_cargo === tc).length;
        return { ...failed.find((c) => c.tipo_de_cargo === tc), qty };
      });
  }
  if (generarDeclaracion && (output.success.length || inZeros)) {
    const declaracion = await postGenerarDeclaracion({
      ...decValues,
      cargo: output.success[0]?.id,
    });
    output.declaracion = declaracion;
  }
  return output;
};

/**
 * POST Generar Referencia De Pago Netpay
 * @param {number} tipo_de_padron
 * @param {number} padron_id
 * @param {string} description
 * @param {object[]} cargos
 * @param {object[]} padrones
 * @returns {Promise<object>}
 */
export const postGenererReferenciasNetpay = async (
  tipoDePadron,
  padron,
  cargos,
  padrones,
  _ciudadano,
  infoNotario,
  totalProp = 'adeudo_total',
) => {
  try {
    const ciudadano = _ciudadano || findCiudadano(padron, tipoDePadron);
    const importe = cargos.reduce((acum, curr) => acum + curr[totalProp], 0);
    const [amount] = round(importe);
    // const lastDayInstance = toMoment(new Date()).endOf('month');
    // const fecha = lastDayInstance.format(DATE_FORMAT);
    const allowInpc = padronesEnum.ALCOHOL === tipoDePadron;
    const expiryDate = calculateExpiryDate(cargos, allowInpc);
    const nuevaFecha = expiryDate.replace(/\//g, '-');
    const _infoNotario = infoNotario || {};
    const values = {
      description: cargos.length === 1 && cargos[0].descripcion.length <= 250
        ? cargos[0].descripcion : padrones.find((p) => p.id === tipoDePadron)?.descripcion,
      expiryDate,
      amount: amount || 0,
      paymentMethod: 'cash',
      currency: 'MXN',
      billing: {
        canal_de_pago: ENV_NAME !== 'juarez' ? CATALOGOS_DE_CANAL_DE_PAGO.PORTAL_FINANCIERO : CATALOGOS_DE_CANAL_DE_PAGO.PAGO_EN_LINEA,
        cargos: cargos.map((e) => e.id),
        padron_id: padron.id || padron,
        tipo_de_padron: tipoDePadron,
        importe: amount || 0,
        fecha: nuevaFecha,
        merchantReferenceCode: null,
        ciudadano,
        referencia_normal: true,
        ..._infoNotario,
        observaciones_complemento: 'Antes de realizar el pago, favor de asegurarse de que la información presentada y los cargos sean correctos, ya que no habrá devoluciones posteriores.',
      },
    };
    const response = await API.post('recaudacion/referencias-pago-netpay-public', values);
    return {
      ...response.data,
      base64: `data:application/pdf;base64,${response.data.data}`,
      folio: response.data.folio,
    };
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

/**
 * GET Periodos Fiscales
 * @param {object} params
 * @param {string} params.q
 * @param {number} params.page
 * @returns {Promise<object[]>}
 */
export const getPeriodosFiscales = async (params, fetchAllPages = false) => {
  try {
    const output = [];
    const { data } = await API.get('catalogos/periodos-fiscales-public', { params });
    output.push(...(data.results || data));
    if (fetchAllPages && data.results) {
      const totalPages = parseInt(data.count / 10, 10) + (data.count % 10 ? 0 : 1);
      const promises = Array(totalPages).fill(0)
        .map((e, idx) => getPeriodosFiscales({ page: idx + 2 }, false));
      const responses = await Promise.all(promises);
      output.push(...responses.flat());
    }
    return output;
  } catch (error) {
    _logger(error);
  }
  return [];
};

/**
 * GET Unidad de Medida y Actualizacion
 * @returns {Promise<object>}
 */
export const getUMA = async () => {
  try {
    const response = await API.get('configuracion/uma-public');
    return response.data[response.data.length - 1]?.valor_diario;
  } catch (error) {
    _logger(error);
  }
  return [];
};

/**
 * GET Salario Minimo
 * @returns {Promise<object>}
 */
export const getSM = async () => {
  try {
    const response = await API.get('configuracion/salarios-minimos-public');
    return response.data[response.data.length - 1]?.salario_minimo_nominal;
  } catch (error) {
    _logger(error);
  }
  return [];
};

export const getOficinasDeAtencion = async (params = null) => {
  try {
    const response = await API.get('/configuracion/caar-public/', { params });
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return [];
};

export const tokenizeAmount = async (amount) => {
  try {
    const response = await axios
      .post(
        `${NETPAY_BASE_URL}token/amount`,
        { amount },
        {
          headers: {
            Accept: 'application/json',
            Authorization: NETPAY_PRIVATE_KEY,
            'Content-Type': 'application/json',
          },
        },
      );
    return response?.data?.tokenAmount;
  } catch (error) {
    _logger(error);
  }
  return null;
};

export const getRecibo = async (params = {}) => {
  try {
    const response = await API.get('/recaudacion/recibos-externo/', { params });
    return response.data.map((e) => ({
      ...formatReceived(e),
      fecha_de_creacion: toMoment(e.fecha_de_creacion, DATETIME_FORMAT)?.format(),
      importe_total: toCurrency(e.importe_total),
    }));
  } catch (error) {
    _logger(error, true);
  }
  return [];
};

export const getReciboPorOrden = async (values) => {
  const _values = values;
  try {
    _values.canal_de_pago = 5;
    const response = await API.post('/recaudacion/recibo/folio-referencia/', _values);
    const base64 = `data:application/pdf;base64,${response.data.data}`;
    return {
      ...response.data,
      base64,
    };
  } catch (error) {
    message.warn(error.response?.data?.detail || error.response?.data || error.message);
  }
  return null;
};

export const postFacturar = async (values) => {
  try {
    const response = await API.post('recaudacion/facturacion-externa/', values);
    return response.data;
  } catch (_error) {
    _logger(_error);
    let error = _error.response?.data.detail || _error.response?.data || _error.message;
    if (typeof error === 'object') {
      const keys = Object.keys(error);
      error = keys.length ? error[keys[0]] : 'Error desconocido';
    }
    return { error };
  }
};
export const getDatosDePagoConFolioDeOrdenDePago = async (values) => {
  try {
    const response = await API.post('recaudacion/referencias-de-pago/folio/', {
      ...values,
      entidad: ID_ENTIDAD,
    });
    if (response?.status === 204) {
      message.info('No se encontro la orden de pago buscada');
      return null;
    }
    response.data.cargos = response.data.cargos.map(({ total_real, ...e }) => ({
      ...e,
      importe: total_real,
    }));
    response.data.base64 = `data:application/pdf;base64,${response.data.data}`;
    delete response.data.data;
    return response.data;
  } catch (_error) {
    _logger(_error, true);
    return null;
  }
};

/**
 * GET Tickets
 * @param {object[number]} recibos_id
  * @returns Promise<object[]>
 */
export const getTickets = async (recibos_id = [], tramite_en_proceso = true) => {
  try {
    const URI = tramite_en_proceso
      ? 'recaudacion/recibo-ticket/pdfs/' : 'recaudacion/recibo-ticket-public/pdfs/';
    const { status, data } = await API.post(URI, {
      recibos_id,
      entidad_id: 1,
      tramite_en_proceso,
    });
    const base64 = `data:application/pdf;base64,${data.data}`;
    return { success: status === 200, base64 };
  } catch (err) {
    _logger(err, true);
    return { success: false };
  }
};

/**
 * POST PDF recibo de pago de trámite
 * @param {number} cargo
 * @returns {Promise<Object>}
 */
export const getReciboPDFDeTramite = async (cargo) => {
  try {
    const { data } = await API.post('/recaudacion/tramite/pdfs/', { cargo });
    const base64 = `data:application/pdf;base64,${data.data}`;
    return { base64, id: data.id[0], folio: data.folio[0] };
  } catch (err) {
    _logger(err);
    return null;
  }
};

export const postCargosPorReferenciaDePago = async ({ folio }) => {
  try {
    const values = { folio, entidad: ID_ENTIDAD };
    const { data } = await API.post('recaudacion/referencias-de-pago/cargos/', values);
    return data.cargos;
  } catch (error) {
    _logger(error);
    return [];
  }
};

export const getClasesDeDeclaracion = async (params = {}) => {
  try {
    const response = await API.get('empresas/clases-de-declaraciones-empresariales-public/', { params });
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return [];
};

export const getEstatusDeDeclaracion = async (params = {}) => {
  try {
    const response = await API.get('empresas/estatus-de-declaraciones-empresariales-public/', { params });
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return [];
};

/**
 * POST Generar Referencia De Pago del Banco
 * @param {number} tipo_de_padron
 * @param {number} padron_id
 * @param {string} description
 * @param {object[]} cargos
 * @param {object[]} padrones
 * @returns {Promise<object>}
 */
export const postGenererReferenciasBanco = async (
  tipoDePadron,
  padron,
  cargos,
  padrones,
  _ciudadano,
  infoNotario,
  totalProp = 'adeudo_total',
) => {
  try {
    const ciudadano = _ciudadano || findCiudadano(padron, tipoDePadron);
    const importe = cargos.reduce((acum, curr) => acum + curr[totalProp], 0);
    const [amount] = round(importe);
    const allowInpc = padronesEnum.ALCOHOL === tipoDePadron;
    const expiryDate = calculateExpiryDate(cargos, allowInpc);
    const nuevaFecha = expiryDate.replace(/\//g, '-');
    const _infoNotario = infoNotario || {};
    const values = {
      description: cargos.length === 1 && cargos[0].descripcion.length <= 250
        ? cargos[0].descripcion : padrones.find((p) => p.id === tipoDePadron)?.descripcion,
      expiryDate,
      amount: amount || 0,
      paymentMethod: 'cash',
      currency: 'MXN',
      billing: {
        canal_de_pago: 5,
        cargos: cargos.map((e) => e.id),
        padron_id: padron.id || padron,
        tipo_de_padron: tipoDePadron,
        importe: amount || 0,
        fecha: nuevaFecha,
        merchantReferenceCode: null,
        ciudadano,
        referencia_normal: false,
        ..._infoNotario,
      },
    };
    const response = await API.post('recaudacion/referencias-pago-netpay-public', values);
    return {
      ...response.data,
      values,
      folio_banco: response.data.folio,
    };
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

/**
 * POST Generar Referencia De Pago del Banco
 * @param {number} tipo_de_padron
 * @param {number} padron_id
 * @param {string} description
 * @param {object[]} cargos
 * @param {object[]} padrones
 * @returns {Promise<object>}
 */
export const postReferenciasBanco = async ({
  tipoDePadron,
  padron,
  cargos,
  padrones,
  _ciudadano,
  infoNotario,
  totalProp,
}) => {
  try {
    const ciudadano = _ciudadano || findCiudadano(padron, tipoDePadron);
    const importe = cargos.reduce((acum, curr) => acum + curr[totalProp], 0);
    const [amount] = round(importe);
    const allowInpc = padronesEnum.ALCOHOL === tipoDePadron;
    const expiryDate = calculateExpiryDate(cargos, allowInpc);
    const nuevaFecha = expiryDate.replace(/\//g, '-');
    const _infoNotario = infoNotario || {};
    const values = {
      description: cargos.length === 1 && cargos[0].descripcion.length <= 250
        ? cargos[0].descripcion : padrones.find((p) => p.id === tipoDePadron)?.descripcion,
      expiryDate,
      amount: amount || 0,
      paymentMethod: 'cash',
      currency: 'MXN',
      billing: {
        canal_de_pago: 5,
        cargos: cargos.map((e) => e.id),
        padron_id: padron.id || padron,
        tipo_de_padron: tipoDePadron,
        importe: amount || 0,
        fecha: nuevaFecha,
        merchantReferenceCode: null,
        ciudadano,
        referencia_normal: false,
        ..._infoNotario,
      },
    };
    const response = await API.post('recaudacion/referencias-pago-netpay-public', values);
    return {
      ...response.data,
      values,
      folio_banco: response.data.folio,
    };
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

export const getReferenciaBancoPublic = async (folio, descripcion) => {
  try {
    const response = await API.get('recaudacion/referencia-de-pago-pagable-public', {
      params: {
        folio,
        descripcion,
      },
    });
    return response.data;
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

export const printReferenceOrder = async (values) => {
  try {
    const response = await API.post('recaudacion/referencias-de-pago/folio/', values);
    return response.data;
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

export const getEstadosDeVehiculo = async (params = null) => {
  try {
    const response = await API.get('/recaudacion/estados-del-vehiculo/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getMarcasDeVehiculos = async (params = null) => {
  try {
    const response = await API.get('/recaudacion/marcas-de-vehiculos/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getServiciosDeVehiculo = async (params = null) => {
  try {
    const response = await API.get('/recaudacion/servicios-del-vehiculo/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getTiposDeTenencias = async (params = null) => {
  try {
    const response = await API.get('/recaudacion/tipos-de-tenencias-vehiculares/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Lineas de Vehiculos
 * @returns {Promise<object[]>}
*/
export const getLineasDeVehiculos = async (params = null) => {
  try {
    const response = await API.get('/recaudacion/lineas-vehiculares/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Tipos de Vehiculos
 * @returns {Promise<object[]>}
*/
export const getTiposDeVehiculos = async (params = null) => {
  try {
    const response = await API.get('/recaudacion/tipos-de-vehiculos/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};
export const postDeclaracionIsan = async (values) => {
  try {
    const response = await API.post('recaudacion/declaraciones-empresariales-isan-public', values);
    return response.data;
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

export const postDecaracionAnalisisISAN = async (values) => {
  try {
    const response = await API.post('recaudacion/declaraciones-empresariales-analisis-de-isan-public', values);
    return response.data;
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

export const postDecaracionEnanjenantesNotarios = async (values) => {
  try {
    const response = await API.post('empresas/declaraciones-empresariales-de-notarios-con-enajenantes-public', values);
    return response.data;
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

export const banorteEncrypted = async (values) => {
  try {
    const response = await API.post('recaudacion/consulta-banorte/', values);
    return response;
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

export const PDFISAN = async (values) => {
  try {
    const response = await API.post(`empresas/declaraciones-empresariales-public/${values}/pdf-isan/`, values);
    return response.data;
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

/**
 * @description POST Declaracion CRIE
 * @param {object} values
 * @returns {Promise<object>}
 */
export const postDeclaracionCRIE = async (values) => {
  try {
    const { entidad: { id } } = store.getState().auth;
    const _values = { ...values, entidad: id };
    const response = await API.post('empresas/declaraciones-empresariales-crie-public', _values);
    return response.data;
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

/**
 * @description GET Declaracion CRIE
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getDeclaracionCRIE = async (id) => {
  try {
    const response = await API.get('empresas/declaraciones-empresariales-crie-public/', {
      params: {
        declaracion_empresarial: id,
      },
    });
    return response.data;
  } catch (error) {
    _logger(error, true);
  }
  return [];
};

/**
 * @description GET tipos de retenciones
 * @param {object} params
 * @returns {Promise<object>}
 */
export const getTiposDeRetenciones = async (params = null) => {
  try {
    const response = await API.get('empresas/tipos-de-pagos-retenidos-public', { params });
    return response.data;
  } catch (error) {
    _logger(error, true);
  }
  return [];
};

/**
 * @description GET Declaraciones ISAN
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getDeclaracionISAN = async (id) => {
  try {
    const response = await API.get(`/empresas/declaraciones-empresariales-public/${id}/pdf-isan/`);
    return `data:application/pdf;base64,${response.data.pdf}`;
  } catch (error) {
    _logger(error);
  }
  return null;
};

/**
 * @description GET Declaraciones ISAN
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getDeclaracionesISAN = async (id) => {
  try {
    const response = await API.get('recaudacion/declaraciones-empresariales-isan-public/', {
      params: {
        declaracion_empresarial: id,
      },
    });
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return [];
};

/**
 * @description GET Declaraciones NOTARIOS
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getDeclaracionNotarios = async (id) => {
  try {
    const response = await API.get(`/empresas/declaraciones-empresariales-de-notarios-public/${id}/pdf-notarios/`);
    return `data:application/pdf;base64,${response.data.pdf}`;
  } catch (error) {
    _logger(error);
  }
  return null;
};

/**
 * @description POST Declaraciones NOTARIOS
 * @param {number} id
 * @returns {Promise<object>}
 */
export const postDeclaracionNotarios = async (values) => {
  try {
    const response = await API.post('/empresas/declaraciones-empresariales-de-notarios-public', values);
    return response.data;
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

/**
 * @description GET referencias de pago
 * @param {object} params
 * @returns {Promise<object>}
 */
export const getReferenciasDePago = async (params = null) => {
  try {
    const response = await API.get('recaudacion/referencias-de-pago-public', { params });
    return response?.data[0] || response.data.results[0];
  } catch (error) {
    _logger(error, true);
    return null;
  }
};

/**
 * @description POST declaraciones empresariales
 * @param {object} params
 * @returns {Promise<object>}
 */
export const postRechazarDeclaracion = async (params = null) => {
  try {
    const response = await API.post('empresas/declaraciones/cancelar_declaracion/', {
      ...params,
      entidad: ID_ENTIDAD,
    });
    return response?.data || response.data.results;
  } catch (error) {
    _logger(error, true);
    return null;
  }
};

/**
 * @description POST Declaraciones Alcoholes
 * @param {number} id
 * @returns {Promise<object>}
 */
export const postDeclaracionAlcoholes = async (values) => {
  try {
    const response = await API.post('empresas/declaraciones-empresariales-de-bebidas-alcoholicas-public', values);
    return response.data;
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

/**
 * @description POST Declaraciones Hospedaje
 * @param {number} id
 * @returns {Promise<object>}
 */
export const postDeclaracionHospedaje = async (values) => {
  try {
    const response = await API.post('empresas/declaraciones-empresariales-de-hospedaje-public', values);
    return response.data;
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

/**
 * @description POST Declaraciones Nomina
 * @param {number} id
 * @returns {Promise<object>}
 */
export const postDeclaracionNomina = async (values) => {
  try {
    const response = await API.post('empresas/declaraciones-empresariales-de-nominas-public', values);
    return response.data;
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

/**
 * @description POST Declaraciones Nomina
 * @param {number} id
 * @returns {Promise<object>}
 */
export const postDeclaracionRetenedorNomina = async (values) => {
  try {
    const response = await API.post('empresas/declaraciones-empresariales-de-retenciones-de-nomina-public', values);
    return response.data;
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

/**
 * @description POST Declaraciones Alcoholes
 * @param {number} id
 * @returns {Promise<object>}
 */
export const postDeclaracionJuegos = async (values) => {
  try {
    const response = await API.post('empresas/declaraciones-empresariales-de-apuestas-public', values);
    return response.data;
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

export const postLogErrorNetpay = async (error) => {
  try {
    const response = await API.post('recaudacion/registros-de-errores/', error);
    localStorage.removeItem('paymentError');
    return response.data;
  } catch (err) {
    throw new Error('Error al registrar el error');
  }
};

/**
 * @description POST Declaraciones Cedular Profesional
 * @param {number} id
 * @returns {Promise<object>}
 */
export const postDeclaracionCedular = async (values) => {
  try {
    const response = await API.post('empresas/declaraciones-empresariales-de-servicios-public', values);
    return response.data;
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

/**
 * @description POST Declaraciones Cedular Arrendamiento
 * @param {number} id
 * @returns {Promise<object>}
 */
export const postDeclaracionCedularArrendamiento = async (values) => {
  try {
    const response = await API.post('empresas/declaraciones-empresariales-de-arrendamientos-public', values);
    return response.data;
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

/**
 * @description POST Declaraciones Cedular Arrendamiento
 * @param {number} id
 * @returns {Promise<object>}
 */
export const postDeclaracionCedularRetenedores = async (values) => {
  try {
    const response = await API.post('empresas/declaraciones-empresariales-de-retenciones-cedulares-public', values);
    return response.data;
  } catch (error) {
    _logger(error, true);
  }
  return null;
};

/**
 * @description GET TIPOS DE UNIDADES ISAN
 * @returns {Promise<object>}
 */
export const getTiposDeUnidadesISAN = async (values) => {
  try {
    const response = await API.get('recaudacion/declaraciones-empresariales-isan-tipos-de-unidades-public', values);
    return response.data;
  } catch (error) {
    _logger(error, true);
  }
  return null;
};
