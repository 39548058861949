import React from 'react';
import PropTypes from 'prop-types';
import {
  EnvironmentOutlined,
  UserOutlined,
  PhoneOutlined,
  SendOutlined,
  GlobalOutlined,
} from '@ant-design/icons';
import {
  Text,
  Title,
  Button,
  COLORS,
  CardContainer,
} from 'components';
import {
  Divider,
  Row,
  Col,
  Space,
  Form,
  Input,
  Grid,
} from 'antd';
import { toInteger } from 'utils/formatters';
import { PHONE_NUMBER } from 'utils/patterns';
import Select from 'components/Select';
import { ENV_NAME, ENV_NAMES_ENUM, REPUVE } from 'utils/env';

const asuntos = [
  'Tengo una duda con los trámites y servicios',
  'No pude realizar mi pago',
  'No me encuentro en el padrón',
  'Encontré un error en este sitio',
  'Otro',
].map((e) => ({ descripcion: e, id: e }));

const statesInfo = {
  [ENV_NAMES_ENUM.NAYARIT]: {
    contactSection: [
      {
        icon: EnvironmentOutlined,
        name: 'Casa de Gobierno de Nayarit',
        content: [{
          href: 'https://goo.gl/maps/Bi89Epb53LfqAyj36',
          text: 'Av. México S/N Centro, Tepic, Nayarit.',
        }],
      },
      {
        icon: UserOutlined,
        name: 'Atención telefónica',
        content: [
          {
            href: 'tel:3112152000',
            text: 'Tel. (311) 215 2000',
          },
          {
            href: 'tel:3112152100',
            text: 'Tel. (311) 215 2100',
          },
          {
            href: 'tel:3112152200',
            text: 'Tel. (311) 215 2200',
          },
        ],
      },
      {
        icon: PhoneOutlined,
        name: 'Atención al contribuyente',
        content: [
          {
            href: 'tel:3112580450',
            text: 'Tel. (311) 258 0450',
          },
          {
            href: 'tel:3112169649',
            text: 'Tel. (311) 216 9649',
          },
          {
            href: 'tel:8007275234',
            text: 'Tel. 800 727 5234 (Lada sin costo)',
          },
        ],
      },
    ],
  },
  [ENV_NAMES_ENUM.JUAREZ]: {
    contactSection: [
      {
        icon: EnvironmentOutlined,
        name: 'Gobierno Municipal H. Ayuntamiento de Juárez',
        content: [{
          href: 'https://goo.gl/maps/XiPs6fDSub73zREs7',
          text: 'Unidad Administrativa Benito Juárez Av. Francisco Villa 950 N., Centro C.P. 32000, Juárez, Chihuahua',
        }],
      },
      {
        icon: PhoneOutlined,
        name: 'Atención telefónica',
        content: [
          {
            href: 'tel:6567370000',
            text: 'Tel. 656 737 0000',
          },
        ],
      },
      // {
      //   icon: WhatsAppOutlined,
      //   name: 'Asistencia vía WhatsApp',
      //   content: [
      //     {
      //       href: 'https://web.whatsapp.com/send?phone=+526566436941',
      //       text: '¡Click Aquí!',
      //     },
      //   ],
      // },
    ],
  },
  [ENV_NAMES_ENUM.HERMOSILLO]: {
    contactSection: [
      {
        icon: EnvironmentOutlined,
        name: 'H. Ayuntamiento De Hermosillo',
        content: [{
          href: 'https://goo.gl/maps/sutger8zMRZbpT138',
          text: 'Bulevar Hidalgo y Comonfort Col. Centenario, CP 83260, Hermosillo. Sonora',
        }],
      },
      {
        icon: PhoneOutlined,
        name: 'Atención telefónica',
        content: [
          {
            href: 'tel:6622893000',
            text: 'Tel. 662 289 3000',
          },
        ],
      },
    ],
  },
  [ENV_NAMES_ENUM.ROATAN]: {
    contactSection: [
      {
        icon: EnvironmentOutlined,
        name: 'Municipalidad de Roatán',
        content: [{
          href: 'https://goo.gl/maps/5X8Dv6m3LQc3J5Xy6',
          text: 'Calle 15 de Septiembre, Roatán, Islas de la Bahía, Honduras',
        }],
      },
      {
        icon: PhoneOutlined,
        name: 'Atención telefónica',
        content: [
          {
            href: 'tel:50499018629',
            text: 'Tel. +504 9901 8629',
          },
        ],
      },
    ],

  },
};
const statesInfoRepuve = {
  [ENV_NAMES_ENUM.NAYARIT]: {
    contactSection: [
      {
        icon: EnvironmentOutlined,
        name: 'REPUVE Tepic',
        content: [{
          href: '',
          text: 'Avenida Rey Nayar 169B, Colonia Aviación, CP 63190 , Tepic, Nayarit, Mexico',
        }],
      },
      {
        icon: UserOutlined,
        name: 'Atención telefónica',
        content: [
          {
            href: 'tel:3112118100',
            text: 'Tel. (311) 211 81 00 ext 10421',
          },
        ],
      },
      {
        icon: GlobalOutlined,
        name: 'Atención digital',
        content: [
          {
            href: 'www.repuve.minayarit.gob.mx',
            text: 'Pagina Web: www.repuve.minayarit.gob.mx',
          },
          {
            href: 'mailto:repuvetepic@gmail.com',
            text: 'Correo: repuvetepic@gmail.com',
          },
        ],
      },
    ],
  },
};

const currentCountry = (REPUVE ? statesInfoRepuve : statesInfo)[ENV_NAME];

const rules = {
  required: [{ required: true }],
  email: [{ required: true }, { type: 'email' }],
  phone: [
    { required: true },
    {
      pattern: PHONE_NUMBER,
      message: 'Ingrese un número teléfonico válido',
    },
  ],
};

const ContactSection = ({
  icon: Icon,
  title,
  links,
  screens,
}) => (
  <Col xs={24} sm={24} md={8}>
    <Space
      style={{
        width: '100%',
        paddingTop: 20,
        minHeight: screens.md ? 200 : 'auto',
        textAlign: 'center',
      }}
      direction="vertical"
      align="center"
    >
      <Icon style={{ fontSize: 40 }} />
      <Text strong>
        {title}
      </Text>
      {links.map((link, idx) => (
        <a
          key={link.href + idx.toString()}
          style={{ color: COLORS.accent, fontWeight: 'bold' }}
          href={link.href}
          rel="noreferrer"
          target="blank"
        >
          {link.text}
        </a>
      ))}
    </Space>
  </Col>
);

ContactSection.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  screens: PropTypes.shape({ md: PropTypes.bool }).isRequired,
};

const PortalGuide = () => {
  const screens = Grid.useBreakpoint();
  return (
    <>
      <CardContainer style={{ background: COLORS.white }}>
        <Title $accent $centered>Contacto</Title>
        <Divider />
        <Space style={{ width: '100%', marginBottom: 20 }} direction="vertical" align="center">
          <Text>
            Puedes acudir físicamente o contactarnos por los distintos medios que tenemos para ti.
          </Text>
        </Space>
        <Row justify="center" align="middle" gutter={20}>
          {currentCountry.contactSection.map((item) => (
            <ContactSection
              screens={screens}
              icon={item.icon}
              title={item.name}
              links={item.content}
              key={item.name}
            />
          ))}
        </Row>
      </CardContainer>
      <CardContainer style={{ background: COLORS.white, display: 'none' }}>
        <Title $accent $centered>Escríbenos</Title>
        <Divider />
        <Space style={{ width: '100%', marginBottom: 20 }} direction="vertical" align="center">
          <Text>
            ¿Aún tienes alguna duda o quieres hacer una sugerencia?
          </Text>
        </Space>
        <Form
          layout="vertical"
        >
          <Row gutter={10} justify="end">
            <Col xs={24} sm={24} md={12}>
              <Form.Item label="Nombre(s)" name="nombre" rules={rules.required}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item label="Apellido(s)" name="apellidos" rules={rules.required}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Correo electrónico" name="correo" rules={rules.email}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item label="Celular" normalize={(val) => toInteger(val)} name="celular" rules={rules.phone}>
                <Input maxLength={10} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item label="Asunto" name="asunto" rules={rules.required}>
                <Select options={asuntos} />
              </Form.Item>
            </Col>
            <Col span={24} style={{ height: 200 }}>
              <Form.Item label="Contenido" name="contenido" rules={rules.required}>
                <Input.TextArea autoSize={{ minRows: 5, maxRows: 5 }} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button style={{ float: 'right' }} block={!screens.md} $accent htmlType="submit">
              Enviar
              <SendOutlined />
            </Button>
          </Form.Item>
        </Form>
      </CardContainer>
    </>
  );
};

export default PortalGuide;
