import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Empty,
  Form,
  List,
  Modal,
  Row,
  Grid,
} from 'antd';
import { Button, Text, Title } from 'components';
import address from 'utils/address';
import Mapa from 'components/Mapa';
import {
  ClockCircleOutlined,
  EnvironmentOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons';
import Skeletons from './SkeletosWrappers';

const { SkeletonWrapperText, SkeletosWrapperTitle } = Skeletons;
const { useBreakpoint } = Grid;

const OficinasDeAtencionTab = ({
  oficinasDeAtencion,
  loading,
}) => {
  const screen = useBreakpoint();
  const form = Form.useFormInstance();
  const [current, setCurrent] = useState(null);
  const [coords, setCoords] = useState([]);
  if (!oficinasDeAtencion?.length) {
    return (
      <Empty
        description="No hay oficinas de atención disponibles de momento"
      />
    );
  }

  return (
    <>
      <List
        header={(
          <SkeletosWrapperTitle loading={loading}>
            <Title level={2} $accent>
              Oficinas de atención
            </Title>
          </SkeletosWrapperTitle>
        )}
        dataSource={oficinasDeAtencion}
        renderItem={(item) => (
          <>
            <Row>
              <Col span={24}>
                <Title level={4} $accent>
                  {item?.descripcion}
                </Title>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col>
                <Text>
                  <SkeletonWrapperText>
                    <EnvironmentOutlined />
                    {' '}
                    {address(item?.direccion)}
                  </SkeletonWrapperText>
                </Text>
              </Col>
              <Col>
                <Button
                  shape="round"
                  $accent
                  onClick={() => {
                    setCurrent(item);
                    if (item?.direccion) {
                      const { direccion } = item;
                      if (direccion) {
                        const { latitud, longitud } = direccion;
                        if (latitud && longitud) {
                          setCoords([longitud, latitud, item]);
                        }
                      }
                    }
                  }}
                >
                  <Text>
                    Ver información
                  </Text>
                </Button>
              </Col>
            </Row>
            <br />
          </>
        )}
      />
      <Modal
        title={(
          <Title level={3} $accent>
            {current?.descripcion}
          </Title>
        )}
        visible={!!current}
        onCancel={() => {
          setCurrent(null);
          setCoords([]);
        }}
        footer={null}
        centered
        width={screen.xl ? '60%' : '90%'}
        forceRender
      >
        <Row gutter={[10, 20]}>
          <Col xs={24} sm={24} xl={16}>
            <Row align="middle" gutter={[10, 20]}>
              <Col span={24} style={{ alignSelf: 'center' }}>
                <Text $accent $bold>
                  <EnvironmentOutlined style={{ fontSize: 'larger' }} />
                  {' '}
                  {address(current?.direccion)}
                </Text>
              </Col>
              <Col span={24}>
                <Text $accent $bold>
                  <ClockCircleOutlined style={{ fontSize: 'larger' }} />
                  {' '}
                  {current?.horario ?? 'No disponible'}
                </Text>
              </Col>
              <Col span={24}>
                <Text $accent $bold>
                  <PhoneOutlined style={{ fontSize: 'larger' }} />
                  {' '}
                  {current?.numero_de_telefono ?? 'No disponible'}
                </Text>
              </Col>
              {current?.correo_electronico && (
                <Col span={24}>
                  <Text $accent $bold>
                    <MailOutlined style={{ fontSize: 'larger' }} />
                    {' '}
                    {current?.correo_electronico ?? 'No disponible'}
                  </Text>
                </Col>
              )}
              {current?.jefe && (
                <Col span={24}>
                  <Text $accent $bold>
                    <UserOutlined style={{ fontSize: 'larger' }} />
                    {' '}
                    {current?.jefe}
                  </Text>
                </Col>
              )}
            </Row>
          </Col>
          <Col xs={24} sm={24} xl={8}>
            <Mapa
              form={form}
              interactive={false}
              coords={coords}
              coordsList={[]}
              style={{ display: !coords.length ? 'none' : 'block' }}
            />
            <Empty
              description="No hay ubicación disponible"
              style={{ display: coords.length ? 'none' : 'block' }}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

OficinasDeAtencionTab.propTypes = {
  oficinasDeAtencion: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    descripcion: PropTypes.string,
    direccion: PropTypes.string,
    horario: PropTypes.string,
    numero_de_telefono: PropTypes.string,
    correo_electronico: PropTypes.string,
    jefe: PropTypes.string,
  })),
  loading: PropTypes.bool.isRequired,
};

OficinasDeAtencionTab.defaultProps = {
  oficinasDeAtencion: [],
};

export default OficinasDeAtencionTab;
