/* eslint-disable react/jsx-filename-extension */
import { CloseCircleOutlined } from '@ant-design/icons';
import { Col, message } from 'antd';
import { Button, Text } from 'components';
import React from 'react';
import { DEV, PROD } from 'utils/env';

const defaultErrorMessage = 'Ha ocurrido un error inesperado, intentelo de nuevo o contacte a soporte.';
const capitalizeFirstLetter = ([first, ...rest]) => [first.toUpperCase(), ...rest].join('');

export const closableMessageWarn = (_message, duration = 30) => {
  message.warn({
    content: (
      <Col>
        <Text style={{ fontSize: 'inherit' }}>
          {_message}
        </Text>
        <Button
          icon={<CloseCircleOutlined />}
          onClick={() => message.destroy()}
          type="link"
          style={{
            position: 'absolute',
            top: -30,
            right: -20,
          }}
        />
      </Col>
    ),
    duration,
    style: {
      top: 30,
    },
  });
};

// TODO: extract exception_value in localhost from text/html content on server error 500

export const handleError = (err, fallbackMessage = defaultErrorMessage) => {
  if (err.response) {
    const { detail } = err.response.data;
    if (detail) {
      return message.warn(Array.isArray(detail) ? `${detail.join(', ').replace('.', '')}.` : detail);
    }
    if (err.response.headers['content-type'].includes('application/json')) {
      const keys = Object.keys(err.response.data);
      let normalized = keys.map((key) => {
        let errors = '';
        if (typeof err.response.data[key] === 'object') {
          errors = `${Object.values(err.response.data[key]).flat().join(', ').replace('.', '')}.`;
        } else if (Array.isArray(err.response.data[key])) {
          errors = `${err.response.data[key].join(', ').replace('.', '')}.`;
        } else if (typeof err.response.data[key] === 'string') {
          errors = err.response.data[key];
        }
        return ({
          key,
          displayName: capitalizeFirstLetter(key.replace(/_/g, ' ')),
          errors,
        });
      });
      normalized = normalized.filter((i) => i.errors.length);
      if (normalized.length) {
        return closableMessageWarn((
          <>
            <strong>Solucione las siguientes advertencias</strong>
            <br />
            {normalized.map((item) => (
              <React.Fragment key={item.key}>
                <p style={{ margin: 0, display: 'block', textAlign: 'left' }}>
                  <strong style={{ marginRight: 3 }}>
                    {item.displayName}
                    :
                  </strong>
                  {item.errors}
                </p>
              </React.Fragment>
            ))}
          </>
        ));
      }
    }
    return message.warn(fallbackMessage);
  }
  if (!PROD) {
    // eslint-disable-next-line no-console
    console.error(err);
    return message.warn(err.message);
  }
  return null;
};

export const _debugger = () => {
  if (DEV) {
    // eslint-disable-next-line no-debugger
    debugger;
  }
};

const _logger = (...args) => {
  // eslint-disable-next-line no-console
  console.log(...args);
  if (args[0] instanceof Error) {
    const _args = [args[0]];
    if (typeof args[1] === 'string') {
      _args.push(args[1]);
    }
    handleError(..._args);
  }
};

export default _logger;
