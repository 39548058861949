import React, { useState } from 'react';
import {
  Button,
  COLORS,
  Card,
  Text,
  Title,
  FlexCol,
} from 'components';
import {
  Row,
  Col,
  Form,
  Input,
  message,
} from 'antd';
import LinkWithTooltip from 'components/LinkWithTooltip';
import { EditOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import _logger from 'utils/logger';
import { getQueja } from 'api/solicitudes/quejas';
import { useNavigate } from 'react-router';

const Complains = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const [_queja] = await getQueja(values);
      if (!_queja) {
        message.info(`No se encontro cita con folio ${values.folio}`);
        return;
      }
      navigate('consulta', { state: { queja: values.folio } });
    } catch (error) {
      _logger(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row gutter={[10, 20]} style={{ marginBottom: 20 }}>
      <Col span={24}>
        <Title level={2}>
          Protesta ciudadana
        </Title>
      </Col>
      <Col xs={24} sm={24} md={12}>
        <Card bg={COLORS.white}>
          <FlexCol
            style={{ height: '100%' }}
            justifyContent="space-between"
            alignItems="start"
          >
            <Text>
              <Title level={4}>
                Registro de protesta ciudadana
              </Title>
              <br />
              <Text style={{ marginBottom: 15 }}>
                En este apartado podrá registrar los diferentes tipos de protestas ciudadanas,
                tales como; Consultas, Sugerencias o Quejas.
              </Text>
            </Text>
            <Button $accent>
              <LinkWithTooltip
                to="nueva"
                text="Registrar"
                icon={EditOutlined}
              />
            </Button>
          </FlexCol>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={12}>
        <Card bg={COLORS.white}>
          <FlexCol
            style={{ height: '100%', width: '100%' }}
            justifyContent="space-between"
            alignItems="start"
          >
            <Title level={4} style={{ marginBottom: 15 }}>
              Consultar o cancelar quejas
            </Title>
            <br />
            <Form
              layout="vertical"
              onFinish={onFinish}
              style={{ width: '100%' }}
              form={form}
            >
              <Col span={24}>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Ingrese número de folio de la queja"
                  name="folio"
                >
                  <Input disabled={loading} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Ingrese código de referencia"
                  name="codigo"
                >
                  <Input disabled={loading} />
                </Form.Item>
              </Col>
              <Form.Item noStyle>
                <Button $accent htmlType="submit" disabled={loading}>
                  Consultar
                  {loading ? <LoadingOutlined /> : <SearchOutlined />}
                </Button>
              </Form.Item>
            </Form>
          </FlexCol>
        </Card>
      </Col>
    </Row>
  );
};

export default Complains;
