import API from 'api/index';
import getFormattedValues, { formatReceived } from 'utils/formatValues';
import _logger from 'utils/logger';

export const baseURI = '/recaudacion/cargos';

/**
 * GET Cargos
 * @returns {Promise<Array<object>>}
 */
export const getCargos = async (params = null) => {
  try {
    const response = await API.get(baseURI, { params });
    return formatReceived(response.data);
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Cargos
 * @returns {Promise<Array<object>>}
 */
export const getCargosPaginados = async (params = null, all = true) => {
  try {
    const response = await API.get(`${baseURI}-paginados`, { params });
    if (all) {
      if (!response.data.next) return response.data.results;
      const page = new URL(response.data.next).searchParams.get('page');
      const _cargos = await getCargosPaginados({ ...params, page }, all);
      return response.data.results.concat(_cargos);
    }
    return formatReceived(response.data);
  } catch (err) {
    _logger(err);
    return all ? [] : { results: [] };
  }
};

/**
 * POST Generar Cargos
 * @param {Object} values
 * @param {Number} times
 * @returns {Promise<boolean>}
 */
export const postGenerarCargos = async (values, times = 1) => {
  try {
    const promises = [];
    for (let i = 0; i < times; i += 1) {
      promises.push(API.post('recaudacion/generar-cargos/', {
        ...getFormattedValues(values),
        tramite: values.tramite || null,
      }));
    }
    const responses = await Promise.all(promises);
    const datos = responses.map((e) => formatReceived(e.data));
    return datos;
  } catch (err) {
    _logger(err);
    return [];
  }
};
