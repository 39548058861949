import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import {
  Card as AntCard,
  Row,
  Col,
  Input,
  Divider,
  Form,
  // Pagination as AntPagination,
  Result as AntResult,
  message,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { getOficinasDeAtencion } from 'api/recaudacion';
import Mapa from 'components/Mapa';
import {
  COLORS,
  Title,
  Button,
  Text,
} from 'components';
import address from 'utils/address';
import { ENV_NAME, ENV_NAMES_ENUM } from 'utils/env';

const Oficinas = () => {
  const inputRef = useRef();
  const [form] = Form.useForm();
  const [formMapa] = Form.useForm();
  const [data, setData] = useState({ results: [] });
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(0);
  const [coords, setCoords] = useState([]);
  const [coordsList, setCoordsList] = useState([]);
  const [mapHeight, setMapHeight] = useState(0);
  // const [currentPage, setCurrentPage] = useState(1);

  const normalizeAdress = (_data = []) => _data
    .filter((e) => e.direccion && e?.direccion?.latitud && e?.direccion?.longitud)
    .map((e) => ({
      ...e,
      detalle: e.descripcion,
      _descripcion: e.unidad_responsable.descripcion,
      direccion: {
        ...e.direccion,
        title: e.descripcion,
        detalle: address(e.direccion) || ' ',
      },
    }));

  const getCoords = (_coordList = []) => _coordList.map((e) => ({
    longitud: e.direccion.longitud,
    latitud: e.direccion.latitud,
    direccion: e.direccion,
  }));

  const resizeMap = () => {
    setTimeout(() => {
      const _mapHeigth = document.getElementById('left-col')?.clientHeight || 300;
      setMapHeight(_mapHeigth);
    });
  };

  useEffect(() => {
    resizeMap();
  }, [coords, data, checked, coordsList]);

  let objOficinas = [];

  const getOficinas = async (page = 1, descripcion = null) => {
    setLoading(true);
    const _oficinas = await getOficinasDeAtencion({ page, descripcion });
    if (_oficinas?.next) {
      objOficinas = [...objOficinas, ..._oficinas.results];
      await getOficinas(page + 1, descripcion);
    } else {
      objOficinas = [...objOficinas, ..._oficinas.results];
    }
    setLoading(false);
    return objOficinas;
  };

  const fetchData = async (page = 1) => {
    setLoading(true);
    setChecked(0);
    setCoords([]);
    // setCurrentPage(page);
    const _oficinas = await getOficinas(page);
    const results = normalizeAdress(_oficinas);
    const _data = { ..._oficinas, results };
    const _coordsList = getCoords(_data.results);
    setData(_data);
    setCoordsList(_coordsList);
    setLoading(false);
  };
  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      setLoading(true);
      const _oficinas = await getOficinas();
      const results = normalizeAdress(_oficinas);
      const _data = { ..._oficinas, results };
      const _coordsList = getCoords(_data.results);
      if (mounted) {
        setCoordsList(_coordsList);
        setData(_data);
        setLoading(false);
      }
    };
    fetch();
    return () => { mounted = false; };
  }, []);

  // const itemRender = (_, type, originalElement) => {
  //   if (type === 'prev') {
  //     return (
  //       <Button
  //         className="left"
  //         loading={loading}
  //         opacity={1}
  //         disabled={!data?.previous}
  //       >
  //         Anterior
  //       </Button>
  //     );
  //   }

  //   if (type === 'next') {
  //     return (
  //       <Button
  //         className="right"
  //         loading={loading}
  //         opacity={1}
  //         disabled={!data?.next}
  //       >
  //         Siguiente
  //       </Button>
  //     );
  //   }

  //   return originalElement;
  // };

  const handleSearch = async () => {
    setLoading(true);
    const { descripcion } = form.getFieldsValue();
    if (descripcion) {
      const _oficinas = await getOficinas(1, descripcion);
      if (_oficinas.length > 0) {
        const results = normalizeAdress(_oficinas);
        const _data = { ..._oficinas, results };
        const _coordsList = getCoords(_data.results);
        setData(_data);
        setCoordsList(_coordsList);
      } else {
        message.info('No se encontró la oficina de atención que se esta consultando o no está disponible su ubicación.');
      }
    }
    setLoading(false);
  };

  return (
    <Row>
      <Col sm={24} md={24} lg={12} id="left-col">
        <Card>
          <Form
            layout="vertical"
            form={form}
          >
            <Row gutter={10}>
              <Col span={24}>
                <Title level={3}>
                  Búsqueda de Oficinas
                </Title>
                <Form.Item name="descripcion">
                  <Input
                    disabled={!data.results?.length || loading}
                    onPressEnter={handleSearch}
                    size="large"
                    ref={inputRef}
                    onChange={({ target: { value } }) => {
                      if (!value) {
                        fetchData();
                        setTimeout(() => inputRef.current.focus());
                      }
                    }}
                    addonAfter={(
                      <SearchButton
                        loading={loading}
                        onClick={() => handleSearch()}
                        disabled={!data.results?.length}
                      >
                        Buscar
                        <SearchOutlined />
                      </SearchButton>
                )}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Divider />
          <Row style={{ overflowY: 'scroll', maxHeight: '600px' }}>

            {data.results.map((item) => (
              <Col span={23} key={item.id}>
                <LocationButton
                  bg={checked === item.id ? COLORS.cream2 : COLORS.accent}
                  color={checked === item.id ? COLORS.black : COLORS.white}
                  theme={checked === item.id ? 'filled' : 'outlined'}
                  opacity={1}
                  onClick={() => {
                    if (checked === item.id) {
                      setChecked(0);
                      setCoords([]);
                    } else {
                      setChecked(item.id);
                      const _coords = [
                        item.direccion.longitud,
                        item.direccion.latitud,
                        item.direccion,
                      ];
                      setCoords(_coords);
                    }
                  }}
                  block
                >
                  {item.detalle}
                  {checked === item.id && (
                  <Row>
                    {item._descripcion && (
                      <Col span={24}>
                        <Text>
                          {item._descripcion}
                        </Text>
                      </Col>
                    )}
                    {item.numero_de_telefono && (
                      <Col span={24}>
                        <Text>
                          Teléfono:
                          {' '}
                          {item.numero_de_telefono}
                        </Text>
                      </Col>
                    )}
                    {item.correo_electronico && (
                      <Col span={24}>
                        <Text>
                          Correo electrónico:
                          {' '}
                          {item.correo_electronico}
                        </Text>
                      </Col>
                    )}
                    {item.direccion && (
                      <Col span={24}>
                        <Text>
                          Dirección:
                          {' '}
                          {address(item.direccion)}
                        </Text>
                      </Col>
                    )}
                  </Row>
                  )}
                </LocationButton>
              </Col>
            ))}
          </Row>

          <br />
          {/* <Row justify="center">
            <Pagination
              style={{ margin: 0 }}
              showQuickJumper={false}
              total={data?.count}
              onChange={fetchData}
              itemRender={itemRender}
              current={currentPage}
              disabled={loading}
            />
          </Row>
          */}
          <Row justify="left">
            <Col span={24}>
              <Text>
                Oficinas de Gobierno:
                {' '}
                {' '}
                {data?.results.length}
                {' '}
                {' '}
                {data?.results.length > 0 ? 'Disponibles' : 'No Disponibles'}
              </Text>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12}>
        {!coordsList.length && loading ? (
          <Result
            title="De momento no hay Oficinas de Gobierno disponibles."
          />
        ) : (
          <Form form={formMapa}>
            <Mapa
              height={mapHeight}
              form={formMapa}
              interactive={false}
              coords={coords}
              coordsList={coordsList}
            />
          </Form>
        )}
      </Col>
    </Row>
  );
};

const stylesByEntity = {
  [ENV_NAMES_ENUM.HERMOSILLO]: {
    bgLocationButton: COLORS.gray,
    activeItem: COLORS.white,
    colorActiveItem: COLORS.white,
    outlineActive: COLORS.accent,
  },
};

const current = stylesByEntity[ENV_NAME] || {
  bgLocationButton: COLORS.gray,
  activeItem: COLORS.gray,
  colorActiveItem: COLORS.white,
  outlineActive: COLORS.accent,
};

const LocationButton = styled(Button)`
  margin: 5px;
  height: auto;
  white-space: break-spaces;
  border-radius: 0.5rem;
  &:active, &:visited, &:focus {
    background: ${current.bgLocationButton};
  }
`;

const SearchButton = styled(Button)`
  border: transparent;
  width: 100% !important;
  box-shadow: none;
`;

const Card = styled(AntCard)`
  border-radius: 0.25rem;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.15);
  height: 100%;
`;

// const Pagination = styled(AntPagination)`
//   li {
//     margin: 0px;
//     outline: none;
//     height: auto;
//     color: ${COLORS.accent} !important;
//     a {
//       padding: 2px;
//     }
//   }
//   .ant-pagination-item-active {
//     &:active,
//     &:visited,
//     &:focus {
//       background: ${current.activeItem} !important;
//       color: ${current.colorActiveItem} !important;
//       outline: ${current.outlineActive} !important;
//     }
//   }

//   .left {
//     border-radius: 0.5rem 0 0 0.5rem;
//   }

//   .right {
//     border-radius: 0 0.5rem 0.5rem 0;
//   }
// `;

const Result = styled(AntResult)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default Oficinas;
