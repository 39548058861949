import React from 'react';
import PropTypes from 'prop-types';
import FundamentosLegalesItems from './FundamentosLegalesItems';

const FundamentosLegalesTab = ({ plantilla }) => (
  <>
    <FundamentosLegalesItems
      fundamentosLegales={plantilla?.fundamento_legal ?? []}
      title="Fundamentos legales del trámite"
    />
    <FundamentosLegalesItems
      fundamentosLegales={plantilla?.fundamento_legal_monto ?? []}
      title="Fundamentos legales del monto"
    />
    <FundamentosLegalesItems
      fundamentosLegales={plantilla?.tipo_de_resolucion_plantilla
        ?.map((tr) => tr?.fundamento_legal).flat().filter(Boolean) ?? []}
      title="Fundamentos legales de la vigencia de resolución"
    />
    <FundamentosLegalesItems
      fundamentosLegales={plantilla?.plazo_prevencion
        .map((p) => p.fundamento_legal).flat().filter(Boolean) ?? []}
      title="Fundamentos legales del plazo de prevención"
    />
    <FundamentosLegalesItems
      fundamentosLegales={plantilla?.plazo_maximo
        ?.map((r) => r?.fundamento_legal).flat().filter(Boolean) ?? []}
      title="Fundamentos legales del plazo máximo"
    />
    <FundamentosLegalesItems
      fundamentosLegales={plantilla?.formato_requerido
        ?.map((r) => r?.fundamento_legal).flat().filter(Boolean) ?? []}
      title="Fundamentos legales de formatos requeridos"
    />
    <FundamentosLegalesItems
      fundamentosLegales={(plantilla?.requisitos_generales
        ?.map((rg) => rg.fundamento_legal).flat().filter(Boolean) ?? [])
        .concat(plantilla?.casos_de_los_requisitos?.map((c) => c.requisitos)
          .flat().map((r) => r.fundamento_legal).flat()
          .filter(Boolean) ?? [])}
      title="Fundamentos legales de los requisitos generales"
    />
    <FundamentosLegalesItems
      fundamentosLegales={plantilla?.canal_de_presentacion_plantilla
        .flat().map((r) => r.fundamento_legal).flat().filter(Boolean) ?? []}
      title="Fundamentos legales de los canales de presentación"
    />
  </>
);

const fundamentoLegalShape = {
  id: PropTypes.number,
  denominacion: PropTypes.string,
  articulo: PropTypes.string,
  nombre_de_ordenamiento: PropTypes.shape({
    descripcion: PropTypes.string,
    hipervinculo: PropTypes.string,
  }),
};

FundamentosLegalesTab.propTypes = {
  plantilla: PropTypes.shape({
    plazo_maximo: PropTypes.arrayOf(PropTypes.shape(fundamentoLegalShape)),
    fundamento_legal_monto: PropTypes.arrayOf(PropTypes.shape(fundamentoLegalShape)),
    fundamento_legal: PropTypes.arrayOf(PropTypes.shape(fundamentoLegalShape)),
    tipo_de_resolucion_plantilla: PropTypes.arrayOf(PropTypes.shape({
      fundamento_legal: PropTypes.arrayOf(PropTypes.shape(fundamentoLegalShape)),
    })),
    formato_requerido: PropTypes.arrayOf(PropTypes.shape({
      fundamento_legal: PropTypes.arrayOf(PropTypes.shape(fundamentoLegalShape)),
    })),
    plazo_prevencion: PropTypes.arrayOf(PropTypes.shape(fundamentoLegalShape)),
    requisitos_generales: PropTypes.arrayOf(PropTypes.shape(fundamentoLegalShape)),
    casos_de_los_requisitos: PropTypes.arrayOf(PropTypes.shape({
      requisitos: PropTypes.arrayOf(PropTypes.shape({
        fundamento_legal: PropTypes.arrayOf(PropTypes.shape(fundamentoLegalShape)),
      })),
    })),
    canal_de_presentacion_plantilla: PropTypes.arrayOf(PropTypes.shape({
      requisitos: PropTypes.arrayOf(PropTypes.shape({
        fundamento_legal: PropTypes.arrayOf(PropTypes.shape(fundamentoLegalShape)),
      })),
    })),
  }).isRequired,
};

export default FundamentosLegalesTab;
