const withComma = (value) => (value ? `${value},` : '');

module.exports = (values) => {
  if (values) {
    const { codigo_postal, tipo_de_vialidad } = values;
    const municipio = codigo_postal?.municipio;
    const calle = values?.calle_principal || '';
    const numExterior = `${values?.numero_exterior && values?.numero_exterior !== 'S/N' ? '#' : ''}${values?.numero_exterior || ''}`;
    const colonia = codigo_postal?.d_tipo_asenta || '';
    const asentamiento = codigo_postal?.d_asenta || '';
    const codigo = codigo_postal?.d_codigo || '';
    const nombreMunicipio = municipio?.nom_mun || '';
    const estado = codigo_postal?.municipio?.estado?.nombre_de_AGEE || '';
    const args = [
      `${tipo_de_vialidad?.descripcion || ''} ${calle}`,
      numExterior,
      `${colonia} ${asentamiento}`,
      codigo,
      nombreMunicipio,
      estado,
    ];
    const str = args.map((e = '') => withComma(e.toString().trim())).filter((e) => e).join(' ');
    return str.trim().replace(/,\s*$/, '');
  }
  return '';
};
