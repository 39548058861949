import React, { useEffect, useState } from 'react';
import { Button } from 'components';
import { DeleteFilled, EditFilled, PlusOutlined } from '@ant-design/icons';
import {
  Table,
  Row,
  Col,
  Modal,
  Form,
  Input,
  Tooltip,
} from 'antd';

import PropTypes from 'prop-types';
import { postDecaracionEnanjenantesNotarios } from 'api/recaudacion';
import _logger from 'utils/logger';
import ModalConfirm from 'components/ModalConfirm';

const transformToUpperCase = (value) => value.toUpperCase();

const TableNotarios = ({
  callback,
  callbackCount,
  clearEnajenantes,
  setExecuteOnfinish,
  setCountEnajenantes,
}) => {
  const [form] = Form.useForm();
  const formPadre = Form.useFormInstance();
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const _rules = {
    required: (message) => ({
      required: true,
      message,
    }),
    numeric: [
      {
        validator: async (_, value) => {
          const regex = /^[0-9]+([.][0-9]{1,2})?$/;
          if (!value) {
            return Promise.resolve();
          }
          if (value?.split('.')?.[1]?.length > 2) {
            return Promise.reject(new Error('Solo se permiten hasta dos decimales (0.00)'));
          }
          if (!regex.test(value)) {
            return Promise.reject(new Error('Solo se permiten números'));
          }
          return Promise.resolve();
        },
      },
    ],
  };

  const sumarTotal = () => {
    const total = data.reduce((acc, item) => acc + parseFloat(item.porcentaje), 0);
    formPadre.setFieldsValue({ porcentaje_total_enajenante: total });
    callbackCount(data);
  };

  useEffect(() => {
    sumarTotal();
  }, [data]);

  const comprobarPorcentaje = (value, type) => {
    if (type === 'add') {
      if (value) {
        const total = data.reduce((acc, item) => acc + parseFloat(item.porcentaje), 0);
        if (total + parseFloat(value) > 100) {
          return Promise.reject(new Error('El porcentaje total no puede ser mayor a 100%'));
        }
      }
      return Promise.resolve('ok');
    }
    if (type === 'update') {
      const total = value.reduce((acc, item) => acc + parseFloat(item.porcentaje), 0);
      if (total > 100) {
        return Promise.reject(new Error('El porcentaje total no puede ser mayor a 100%'));
      }
      return Promise.resolve('ok');
    }
    return Promise.reject(new Error('Error en el tipo de validación'));
  };

  const addEnanjenante = async () => {
    await form.validateFields();
    const values = form.getFieldValue();

    comprobarPorcentaje(values.porcentaje, 'add').then((response) => {
      if (response === 'ok') {
        const _data = [...data, { ...values, key: data.length + 1 }];
        setCountEnajenantes(_data.length);
        setData(_data);
        setModalOpen(false);
        form.resetFields();
      }
    }).catch((error) => {
      _logger(error);
    });
  };

  const deleteEnanjenante = () => {
    const auto = form.getFieldValue();
    const newData = data.filter((item) => item.key !== auto.key);
    setCountEnajenantes(newData.length);
    setData(newData);
    setModalOpen(false);
  };

  const updateEnanjenante = async () => {
    try {
      await form.validateFields();
      const enanjenante = form.getFieldValue();
      const newData = data.map((item) => {
        if (item.key === enanjenante.key) {
          return enanjenante;
        }
        return item;
      });

      comprobarPorcentaje(newData, 'update').then((response) => {
        if (response === 'ok') {
          setData(newData);
          setModalOpen(false);
          form.resetFields();
        }
      }).catch((error) => {
        _logger(error);
      });
    } catch (error) {
      _logger(error);
    }
  };

  const concatRazonSocial = () => {
    const nombre = form.getFieldValue('nombre') || '';
    const apellidoPaterno = form.getFieldValue('apellido_paterno') || '';
    const apellidoMaterno = form.getFieldValue('apellido_materno') || '';
    const razonSocial = `${apellidoPaterno} ${apellidoMaterno} ${nombre}`;
    form.setFieldsValue({ razon_social_nombre_completo: razonSocial });
  };

  const onFinish = async (_id) => {
    try {
      const values = data.map((item) => ({
        nombre: transformToUpperCase(item.nombre),
        apellido_paterno: transformToUpperCase(item.apellido_paterno),
        apellido_materno: transformToUpperCase(item.apellido_materno),
        razon_social_nombre_completo: transformToUpperCase(item.razon_social_nombre_completo),
        porcentaje: item.porcentaje,
        porcentaje_total_enajenante: item.porcentaje_total,
        declaracion_empresarial_de_notario: _id,
        entidad: 1,
      }));

      await postDecaracionEnanjenantesNotarios(values);
    } catch (error) {
      _logger(error);
    } finally {
      form.resetFields();
      setData([]);
      setModalOpen(false);
      callback();
    }
  };

  const handleOnfinish = (id) => {
    if (data.length > 0) {
      onFinish(id);
    } else if (data.length <= 0) {
      callback();
    }
  };

  const handleOnDelete = (val) => {
    setSelectedRowKeys(val);
    if (selectedRowKeys) {
      setOpenDelete(true);
    }
  };

  const columns = [
    {
      title: 'NOMBRE (S)',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'APELLIDO PATERNO',
      dataIndex: 'apellido_paterno',
      key: 'apellido_paterno',
    },
    {
      title: 'APELLIDO MATERNO',
      dataIndex: 'apellido_materno',
      key: 'apellido_materno',
    },
    {
      title: 'RAZÓN SOCIAL O NOMBRE COMPLETO',
      dataIndex: 'razon_social_nombre_completo',
      key: 'razon_social_nombre_completo',
    },
    {
      title: 'PORCENTAJE %',
      dataIndex: 'porcentaje',
      key: 'porcentaje',
    },
    {
      title: 'ACCIONES',
      key: 'acciones',
      render: (_, val) => (
        <Row justify="center">
          <Button
            type="link"
            onClick={() => {
              handleOnDelete(val);
            }}
          >
            <Tooltip title="Eliminar">
              <DeleteFilled />
            </Tooltip>
          </Button>
          <Button
            type="link"
            onClick={
            () => {
              setModalOpen(true);
              setSelectedRowKeys(val);
            }
          }
          >
            <Tooltip title="Editar">
              <EditFilled />
            </Tooltip>
          </Button>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    setExecuteOnfinish(() => handleOnfinish);
  }, [modalOpen]);

  useEffect(() => {
    setData([]);
    form.resetFields();
  }, [clearEnajenantes]);

  const modal = () => (
    <Modal
      title="Agregar"
      onCancel={() => {
        setModalOpen(false);
        form.resetFields();
      }}
      style={{ top: 20 }}
      visible={modalOpen}
      footer={[
        // <Button
        //   key="update"
        //   onClick={() => {
        //     if (selectedRowKeys) {
        //       deleteEnanjenante();
        //     } else {
        //       setModalOpen(false);
        //       form.resetFields();
        //     }
        //   }}
        // >
        //   {selectedRowKeys ? 'Eliminar' : 'cerrar'}
        // </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            if (selectedRowKeys) {
              updateEnanjenante();
            } else {
              addEnanjenante();
            }
          }}
        >
          {selectedRowKeys ? 'Actualizar' : 'Agregar'}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
      >
        <Row>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              name="nombre"
              label="Nombre"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'El Nombre es requerido.',
                },
              ]}
            >
              <Input onChange={() => concatRazonSocial()} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              name="apellido_paterno"
              label="Apellido paterno"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'El campo es requerido.',
                },
              ]}
            >
              <Input onChange={() => concatRazonSocial()} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              name="apellido_materno"
              label="Apellido materno"
              hasFeedback
              rules={[
                _rules.required('Las campo son requeridas.'),
              ]}
            >
              <Input onChange={() => concatRazonSocial()} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              name="razon_social_nombre_completo"
              label="Razón social o nombre completo"
              hasFeedback
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              label="Porcentaje de participación"
              name="porcentaje"
              hasFeedback
              rules={[
                _rules.required('Las campo son requeridas.'),
                ..._rules.numeric,
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>

    </Modal>
  );

  return (
    <>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={8}>
          <Button
            size="small"
            icon={<PlusOutlined />}
            style={{ border: 'none', marginTop: '10px' }}
            onClick={() => {
              setModalOpen(true);
              setSelectedRowKeys(false);
              form.resetFields();
            }}
          >
            Agregar
          </Button>
        </Col>
      </Row>
      <Table
        style={{ margin: '10px 0 30px 5px' }}
        dataSource={data}
        columns={columns}
        pagination={false}
        onRow={(record) => ({
          onClick: () => {
            // setModalOpen(true);
            // setSelectedRowKeys(true);
            form.setFieldsValue(record);
          },
        })}
      />
      { modal() }
      <ModalConfirm
        title="Eliminar"
        body="¿Está seguro de eliminar el enajenante?"
        btnText="Si, Eliminar"
        onFinish={() => deleteEnanjenante()}
        setVisible={setOpenDelete}
        visible={openDelete}
      />
    </>
  );
};

export default TableNotarios;

TableNotarios.propTypes = {
  callback: PropTypes.func.isRequired,
  callbackCount: PropTypes.func.isRequired,
  clearEnajenantes: PropTypes.bool.isRequired,
  setExecuteOnfinish: PropTypes.func.isRequired,
  setCountEnajenantes: PropTypes.func,
};

TableNotarios.defaultProps = {
  setCountEnajenantes: () => {},
};
