import { message } from 'antd';
import axios from 'axios';
import {
  REPORTEADOR_SCHEME,
  REPORTEADOR_APIHOST,
  REPORTEADOR_PATH,
  PROD,
} from 'utils/env';

const available = !!(REPORTEADOR_SCHEME && REPORTEADOR_APIHOST && REPORTEADOR_PATH);

export const baseURL = `${REPORTEADOR_SCHEME}://${REPORTEADOR_APIHOST}${REPORTEADOR_PATH}`;

const defaultOptions = {
  baseURL,
  timeout: 90000,
  headers: {
    'Content-Type': 'application/json',
  },
};

const http = axios.create(defaultOptions);

const requestInterceptor = async (options) => {
  if (options.url[options.url.length - 1] !== '/') {
    // eslint-disable-next-line no-param-reassign
    options.url = `${options.url}/`;
  }
  return options;
};

http.interceptors.request.use(requestInterceptor);

const _logger = (error) => {
  // eslint-disable-next-line no-console
  console.error(error);
  if (error?.message) {
    message.warn(error.message);
  } else if (error?.response?.data?.errors?.['$.ID'][0]) {
    message.warn(error.response.data.errors?.['$.ID'][0]);
  }
};

/**
 * POST para obtener base64 del reporteador
 * @param {Object} values
 * @returns {Promise<string>}
 */
export const postObtenerDocumento = async (values) => {
  try {
    if (!available) {
      return ['Ha ocurrido un error al obtener el reporte'.concat(PROD ? '' : ': URL DE REPORTEADOR NO DEFINIDA')];
    }
    const { data } = await http.post('api/ConsumirReportes/ObtenerReporte_pdf', values);
    const { Error: hasError, MensajeError } = data;
    if (hasError) {
      _logger(MensajeError);
      return [MensajeError, null];
    }
    const base64 = `data:application/pdf;base64,${data.Documento.Base64}`;
    return [null, base64];
  } catch (err) {
    _logger(err);
    return ['Ha ocurrido un error al obtener el reporte'];
  }
};

/**
 * @description GET PDF Declaracion CRIE
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getDeclaracionCRIEPDF = async (id) => {
  try {
    const values = {
      ID: PROD ? 167 : 169,
      Parametros: [
        {
          Nombre: 'id_declaracion',
          TipoParametro: 'Entero',
          Valor: id,
        },
      ],
    };
    const { data } = await http.post('api/ConsumirReportes/ObtenerReporte_pdf', values);
    const { Error: hasError, MensajeError } = data;
    if (hasError) {
      _logger(MensajeError);
      return [MensajeError, null];
    }
    const base64 = `data:application/pdf;base64,${data.Documento.Base64}`;
    return [null, base64];
  } catch (error) {
    _logger(error);
    return ['Ha ocurrido un error al obtener el reporte'];
  }
};

/**
 * @description GET PDF Declaracion NOTARIOS
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getDeclaracionNOTARIOPDF = async (id) => {
  try {
    const values = {
      ID: PROD ? 166 : 171,
      Parametros: [
        {
          Nombre: 'declaracionID',
          TipoParametro: 'Entero',
          Valor: id,
        },
      ],
    };
    const { data } = await http.post('api/ConsumirReportes/ObtenerReporte_pdf', values);
    const { Error: hasError, MensajeError } = data;
    if (hasError) {
      _logger(MensajeError);
      return [MensajeError, null];
    }
    const base64 = `data:application/pdf;base64,${data.Documento.Base64}`;
    return [null, base64];
  } catch (error) {
    _logger(error);
    return ['Ha ocurrido un error al obtener el reporte'];
  }
};

/**
 * @description GET PDF Declaracion ALCOHOLESS
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getDeclaracionAlcoholesPDF = async (id) => {
  try {
    const values = {
      ID: PROD ? 189 : 193,
      Parametros: [
        {
          Nombre: 'declaracionID',
          TipoParametro: 'Entero',
          Valor: id,
        },
      ],
    };
    const { data } = await http.post('api/ConsumirReportes/ObtenerReporte_pdf', values);
    const { Error: hasError, MensajeError } = data;
    if (hasError) {
      _logger(MensajeError);
      return [MensajeError, null];
    }
    const base64 = `data:application/pdf;base64,${data.Documento.Base64}`;
    return [null, base64];
  } catch (error) {
    _logger(error);
    return ['Ha ocurrido un error al obtener el reporte'];
  }
};

export const getPDFGeneral = async ({ id: { prod, dev }, declaracionId }) => {
  try {
    const values = {
      ID: PROD ? prod : dev,
      Parametros: [
        {
          Nombre: 'declaracionID',
          TipoParametro: 'Entero',
          Valor: declaracionId,
        },
      ],
    };
    const { data } = await http.post('api/ConsumirReportes/ObtenerReporte_pdf', values);
    const { Error: hasError, MensajeError } = data;
    if (hasError) {
      _logger(MensajeError);
      return [MensajeError, null];
    }
    const base64 = `data:application/pdf;base64,${data.Documento.Base64}`;
    return [null, base64];
  } catch (error) {
    _logger(error);
    return ['Ha ocurrido un error al obtener el reporte'];
  }
};

/**
 * @description GET PDF Declaracion HOSPEDAJE
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getDeclaracionHospedajePDF = async (id) => {
  try {
    const values = {
      ID: PROD ? 198 : 195,
      Parametros: [
        {
          Nombre: 'declaracionID',
          TipoParametro: 'Entero',
          Valor: id,
        },
      ],
    };
    const { data } = await http.post('api/ConsumirReportes/ObtenerReporte_pdf', values);
    const { Error: hasError, MensajeError } = data;
    if (hasError) {
      _logger(MensajeError);
      return [MensajeError, null];
    }
    const base64 = `data:application/pdf;base64,${data.Documento.Base64}`;
    return [null, base64];
  } catch (error) {
    _logger(error);
    return ['Ha ocurrido un error al obtener el reporte'];
  }
};

/**
 * @description GET PDF Declaracion HOSPEDAJE
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getDeclaracionNominasPDF = async (id) => {
  try {
    const values = {
      ID: PROD ? 199 : 200,
      Parametros: [
        {
          Nombre: 'declaracionID',
          TipoParametro: 'Entero',
          Valor: id,
        },
      ],
    };
    const { data } = await http.post('api/ConsumirReportes/ObtenerReporte_pdf', values);
    const { Error: hasError, MensajeError } = data;
    if (hasError) {
      _logger(MensajeError);
      return [MensajeError, null];
    }
    const base64 = `data:application/pdf;base64,${data.Documento.Base64}`;
    return [null, base64];
  } catch (error) {
    _logger(error);
    return ['Ha ocurrido un error al obtener el reporte'];
  }
};

/**
 * @description GET PDF Declaracion HOSPEDAJE
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getDeclaracionRetencionNominasPDF = async (id) => {
  try {
    const values = {
      ID: PROD ? 218 : 219,
      Parametros: [
        {
          Nombre: 'declaracionID',
          TipoParametro: 'Entero',
          Valor: id,
        },
      ],
    };
    const { data } = await http.post('api/ConsumirReportes/ObtenerReporte_pdf', values);
    const { Error: hasError, MensajeError } = data;
    if (hasError) {
      _logger(MensajeError);
      return [MensajeError, null];
    }
    const base64 = `data:application/pdf;base64,${data.Documento.Base64}`;
    return [null, base64];
  } catch (error) {
    _logger(error);
    return ['Ha ocurrido un error al obtener el reporte'];
  }
};

/**
 * @description GET PDF Declaracion JUEGOS
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getDeclaracionJuegosPDF = async (id) => {
  try {
    const values = {
      ID: PROD ? 207 : 206,
      Parametros: [
        {
          Nombre: 'declaracionID',
          TipoParametro: 'Entero',
          Valor: id,
        },
      ],
    };
    const { data } = await http.post('api/ConsumirReportes/ObtenerReporte_pdf', values);
    const { Error: hasError, MensajeError } = data;
    if (hasError) {
      _logger(MensajeError);
      return [MensajeError, null];
    }
    const base64 = `data:application/pdf;base64,${data.Documento.Base64}`;
    return [null, base64];
  } catch (error) {
    _logger(error);
    return ['Ha ocurrido un error al obtener el reporte'];
  }
};

/**
 * @description GET PDF Declaracion CEDULAR MENSUAL PROFESIONAL
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getDeclaracionCedularProfesionalPDF = async (id) => {
  try {
    const values = {
      ID: PROD ? 211 : 212,
      Parametros: [
        {
          Nombre: 'declaracionID',
          TipoParametro: 'Entero',
          Valor: id,
        },
      ],
    };
    const { data } = await http.post('api/ConsumirReportes/ObtenerReporte_pdf', values);
    const { Error: hasError, MensajeError } = data;
    if (hasError) {
      _logger(MensajeError);
      return [MensajeError, null];
    }
    const base64 = `data:application/pdf;base64,${data.Documento.Base64}`;
    return [null, base64];
  } catch (error) {
    _logger(error);
    return ['Ha ocurrido un error al obtener el reporte'];
  }
};
/**
 * @description GET PDF Declaracion CEDULAR RETENEDOR
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getDeclaracionCedularRetenedorPDF = async (id) => {
  try {
    const values = {
      ID: PROD ? 214 : 210,
      Parametros: [
        {
          Nombre: 'declaracionID',
          TipoParametro: 'Entero',
          Valor: id,
        },
      ],
    };
    const { data } = await http.post('api/ConsumirReportes/ObtenerReporte_pdf', values);
    const { Error: hasError, MensajeError } = data;
    if (hasError) {
      _logger(MensajeError);
      return [MensajeError, null];
    }
    const base64 = `data:application/pdf;base64,${data.Documento.Base64}`;
    return [null, base64];
  } catch (error) {
    _logger(error);
    return ['Ha ocurrido un error al obtener el reporte'];
  }
};

/**
 * @description GET PDF Declaracion CEDULAR MENSUAL ARRENDAMIENTO
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getDeclaracionCedularArrendamientoPDF = async (id) => {
  try {
    const values = {
      ID: PROD ? 215 : 208,
      Parametros: [
        {
          Nombre: 'declaracionID',
          TipoParametro: 'Entero',
          Valor: id,
        },
      ],
    };
    const { data } = await http.post('api/ConsumirReportes/ObtenerReporte_pdf', values);
    const { Error: hasError, MensajeError } = data;
    if (hasError) {
      _logger(MensajeError);
      return [MensajeError, null];
    }
    const base64 = `data:application/pdf;base64,${data.Documento.Base64}`;
    return [null, base64];
  } catch (error) {
    _logger(error);
    return ['Ha ocurrido un error al obtener el reporte'];
  }
};

/**
 * @description GET PDF Declaracion NOTARIOS
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getReporteDeRegulacion = async (id) => {
  try {
    const values = {
      ID: PROD ? 178 : 175,
      Parametros: [
        {
          Nombre: 'tramite',
          TipoParametro: 'Entero',
          Valor: id,
        },
      ],
    };
    const { data } = await http.post('api/ConsumirReportes/ObtenerReporte_pdf', values);
    const { Error: hasError, MensajeError } = data;
    if (hasError) {
      _logger(MensajeError);
      return [MensajeError, null];
    }
    const base64 = `data:application/pdf;base64,${data.Documento.Base64}`;
    return [null, base64];
  } catch (error) {
    _logger(error);
    return ['Ha ocurrido un error al obtener el reporte'];
  }
};

export default http;
