import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, message, Modal } from 'antd';
import { CloseCircleOutlined, CreditCardOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { generarReciboPorNetPay, tokenizeAmount } from 'api/recaudacion';
import { Button, COLORS, Text } from 'components';
import { CATALOGOS_DE_CANAL_DE_PAGO, ENV_NAME, RECEIPT_LABEL } from 'utils/env';
import round from 'utils/round';
import { useSelector } from 'react-redux';
import { selectEntidad, selectPublicoGeneral } from 'store/auth';
import { normalizeValue } from 'utils/formatters';
import formatReference from 'utils/formatReference';
import { getEnumValues } from 'views/Tramites/GPM/enums';

export const getNetPayBtn = () => document.querySelector('#netpay-btn');
export const getNetPayCheckoutButton = () => document.querySelector('#netpay-checkout');

export const paymentLocationsEnum = {
  PAYMENTS: 'PAYMENTS',
  DEBTS: 'DEBTS',
  PAYMENT_ORDER: 'PAYMENT_ORDER',
  GPM: 'GPM',
  WEBVIEW: 'WEBVIEW',
};

const getToday = () => new Date().toISOString().substring(0, 10);

const clearButton = () => {
  const button = getNetPayCheckoutButton();
  button.dataset.merchantReferenceCode = '';
  button.dataset.token = '';
  button.dataset.productCount = '';
  button.dataset.phoneNumber = '';
  button.dataset.email = '';
  button.dataset.street1 = '';
  // button.dataset.country = '';
  // button.dataset.city = '';
  // button.dataset.postalCode = '';
  // button.dataset.state = '';
};

export const clearDataset = (prefix) => {
  clearButton();
  // Transaction
  localStorage.setItem(`${prefix}_importe_sin_redondeo`, '');
  localStorage.setItem(`${prefix}_response`, '');
  localStorage.setItem(`${prefix}_merchantReferenceCode`, '');
  localStorage.setItem(`${prefix}_token`, '');
  localStorage.setItem(`${prefix}_productCount`, '');
  localStorage.setItem(`${prefix}_amount`, '');
  // Contact
  localStorage.setItem(`${prefix}_phoneNumber`, '');
  localStorage.setItem(`${prefix}_email`, '');
  // Address
  localStorage.setItem(`${prefix}_street1`, '');
  // localStorage.setItem(`${prefix}_country`, '');
  // localStorage.setItem(`${prefix}_city`, '');
  // localStorage.setItem(`${prefix}_postalCode`, '');
  // localStorage.setItem(`${prefix}_state`, '');
  localStorage.setItem(`${prefix}_date`, '');
  localStorage.setItem(`${prefix}_recibo_data`, '');
};

const retryPayment = (createdBy) => {
  const fromToday = localStorage.getItem(`${createdBy}_date`) === getToday();
  const button = getNetPayCheckoutButton();
  const _merchantReferenceCode = localStorage.getItem(`${createdBy}_merchantReferenceCode`);
  if (button && _merchantReferenceCode && fromToday) {
    // Transaction
    button.dataset.merchantReferenceCode = localStorage.getItem(`${createdBy}_merchantReferenceCode`);
    button.dataset.token = localStorage.getItem(`${createdBy}_token`);
    button.dataset.productCount = localStorage.getItem(`${createdBy}_productCount`);
    button.dataset.amount = localStorage.getItem(`${createdBy}_amount`);
    // Contact
    button.dataset.phoneNumber = localStorage.getItem(`${createdBy}_phoneNumber`);
    button.dataset.email = localStorage.getItem(`${createdBy}_email`);
    // Address
    button.dataset.street1 = localStorage.getItem(`${createdBy}_street1`);
    button.dataset.country = localStorage.getItem(`${createdBy}_country`);
    button.dataset.city = localStorage.getItem(`${createdBy}_city`);
    button.dataset.postalCode = localStorage.getItem(`${createdBy}_postalCode`);
    button.dataset.state = localStorage.getItem(`${createdBy}_state`);
    setTimeout(() => button.click(), 1000);
  } else if (_merchantReferenceCode && !fromToday) {
    clearDataset();
  }
};

const wait = (ms) => new Promise((res) => { setTimeout(res, ms); });

const setText = async () => {
  let _btn = null;
  while (!_btn) {
    _btn = document.querySelector('#cardInfoNext');
    // eslint-disable-next-line no-await-in-loop
    await wait(1000);
    if (_btn) break;
  }
  _btn.innerText = 'Pagar';
};

const onFailedTicket = (
  createdBy,
  onCancel = (_param) => clearDataset(_param),
  onOk = () => window.location.reload(),
) => {
  message.destroy();
  Modal.confirm({
    title: 'Consulta de comprobante',
    content: (
      <Text>
        Se ha detectado un error al consultar
        {' '}
        {RECEIPT_LABEL}
        , ¿desea volver a consultarlo?
      </Text>
    ),
    onOk,
    okText: 'Reintentar',
    onCancel: () => onCancel(createdBy),
    cancelText: 'Descartar',
    cancelButtonProps: {
      style: {
        height: 'auto',
      },
      danger: true,
    },
    okButtonProps: {
      style: {
        color: COLORS.white,
        background: COLORS.accent,
        height: 'auto',
      },
    },
  });
};

export const validateLastTransaction = async (
  isDeclaration,
  callback,
  setLoading,
  createdBy,
  showCompleteMessage = false,
  setGenerandoRecibo = false,
) => {
  const merchantReferenceCode = localStorage.getItem(`${createdBy}_merchantReferenceCode`);
  let response = localStorage.getItem(`${createdBy}_response`);
  const transactionDate = localStorage.getItem(`${createdBy}_date`);
  const reciboStored = localStorage.getItem(`${createdBy}_recibo_data`);
  const importe_sin_redondeo = parseFloat(localStorage.getItem(`${createdBy}_importe_sin_redondeo`));
  if (merchantReferenceCode) {
    if (response) {
      setGenerandoRecibo(true);
      response = JSON.parse(response);
      response.importe_sin_redondeo = importe_sin_redondeo;
      if (setGenerandoRecibo === false) setLoading(true);
      const recibo = reciboStored || await generarReciboPorNetPay({
        folio: merchantReferenceCode,
        canal_de_pago: isDeclaration || ENV_NAME === 'nayarit' ? CATALOGOS_DE_CANAL_DE_PAGO.PORTAL_FINANCIERO
          : CATALOGOS_DE_CANAL_DE_PAGO.PAGO_EN_LINEA,
        response,
        importe_sin_redondeo,
      });
      if (recibo && !reciboStored) {
        localStorage.setItem(`${createdBy}_recibo_data`, JSON.stringify(recibo));
      }
      const output = typeof recibo === 'string' ? JSON.parse(recibo) : recibo;
      if (output) {
        const reciboFormated = await formatReference(output, false);
        if (reciboFormated) {
          callback(reciboFormated);
          clearDataset(createdBy);
        } else {
          onFailedTicket(createdBy);
        }
      } else {
        onFailedTicket(createdBy);
      }
      setLoading(false);
    } else if (transactionDate === getToday()) {
      Modal.confirm({
        title: 'Reintentar pago',
        content: (
          <>
            <Text>
              Se ha detectado un intento de pago reciente, ¿desea volver a intentarlo?
            </Text>
            {showCompleteMessage && (
              <>
                <br />
                <br />
                <Text>
                  O puede continuar mas tarde en la sección de
                  {' '}
                  <b>Consulta de Adeudos</b>
                </Text>
              </>
            )}
          </>
        ),
        onOk: () => {
          retryPayment(createdBy);
          setTimeout(() => setText(), 2000);
        },
        okText: 'Reintentar',
        onCancel: () => clearDataset(createdBy),
        cancelText: 'Descartar',
        cancelButtonProps: {
          style: {
            height: 'auto',
          },
        },
        okButtonProps: {
          style: {
            color: COLORS.white,
            background: COLORS.accent,
            height: 'auto',
          },
        },
      });
    } else {
      clearDataset(createdBy);
    }
  }
};

export const NetpayBtn = ({
  loading,
  onClick,
  style,
  disabled,
}) => (
  <Col xs={24} sm={24} md={8}>
    <Button
      block
      $accent
      onClick={onClick}
      loading={loading}
      id="netpay-btn"
      style={style}
      disabled={disabled}
    >
      <Text>
        Pagar en línea
      </Text>
      <CreditCardOutlined />
    </Button>
  </Col>
);

NetpayBtn.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.shape({}),
};

NetpayBtn.defaultProps = {
  disabled: false,
  loading: false,
  onClick: null,
  style: null,
};

export const alertLineaDeCaptura = () => {
  message.destroy();
  message.warn({
    content: (
      <Col>
        <strong>Código de barras no disponible.</strong>
        <br />
        <p style={{ width: 300 }}>
          Favor de acudir a una oficina de recaudación para realizar su tramite de
          emparejamiento con su cuenta unica.
        </p>
        <Button
          icon={<CloseCircleOutlined />}
          onClick={() => message.destroy()}
          type="link"
          style={{
            position: 'absolute',
            top: -30,
            right: -20,
          }}
        />
        <Button
          onClick={() => {
            message.destroy();
            window.open('/oficinas', '_blank');
          }}
        >
          Ver oficinas de atención
          <EnvironmentOutlined />
        </Button>
      </Col>
    ),
    duration: 0,
    style: {
      top: 30,
    },
  });
};

const NetPaybutton = ({
  cargos,
  padron,
  loading,
  referencia,
  createdBy,
  totalProp,
  generarReferencia,
  productCount,
  excludeValuesContactPublicoGeneral,
  total: _total,
  style,
  disabled,
}) => {
  const [tokenizedAmount, setTokenizedAmount] = useState();
  const entidad = useSelector(selectEntidad);
  const ciudadanoPublicoGeneral = useSelector(selectPublicoGeneral);
  const total = _total || cargos.reduce((acum, curr) => acum + curr[totalProp], 0);
  const [_amount, rounded] = round(total);

  const setValues = () => {
    const button = getNetPayCheckoutButton();
    if (button && referencia) {
      let _padron;
      if (padron.propNameContactInfo === 'self') {
        _padron = padron.empresa || padron;
      } else if (padron.propNameContactInfo) {
        _padron = padron[padron.propNameContactInfo]
        || padron?.empresa?.[padron.propNameContactInfo];
      } else {
        _padron = { ...ciudadanoPublicoGeneral };
        _padron.excludeValuesContactPublicoGeneral = excludeValuesContactPublicoGeneral;
      }

      const phoneNumber = _padron?.excludeValuesContactPublicoGeneral ? ''
        : _padron?.numero_de_celular || _padron?.telefono_celular || '';
      const email = _padron?.excludeValuesContactPublicoGeneral ? ''
        : _padron?.email || _padron?.correo_electronico || '';
      const address = _padron?.direccion || entidad?.direccion;

      // Address
      const country = 'Mexico';
      const street1 = normalizeValue(address?.calle_principal, false)?.trim() || '';
      const city = normalizeValue(address?.codigo_postal?.municipio?.nom_mun, false);
      const postalCode = normalizeValue(address?.codigo_postal?.d_codigo, false);
      const state = normalizeValue(address?.codigo_postal
        ?.municipio?.estado?.nombre_de_AGEE, false);

      // Transaction
      button.dataset.merchantReferenceCode = referencia;
      button.dataset.token = tokenizedAmount;
      button.dataset.productCount = productCount || cargos.length;
      button.dataset.amount = _amount;
      localStorage.setItem(`${createdBy}_importe_sin_redondeo`, _amount - rounded);
      localStorage.setItem(`${createdBy}_merchantReferenceCode`, referencia);
      localStorage.setItem(`${createdBy}_token`, tokenizedAmount);
      localStorage.setItem(`${createdBy}_productCount`, cargos.length);
      localStorage.setItem(`${createdBy}_amount`, _amount);
      // Contact
      button.dataset.phoneNumber = phoneNumber;
      button.dataset.email = email;
      localStorage.setItem(`${createdBy}_phoneNumber`, phoneNumber);
      localStorage.setItem(`${createdBy}_email`, email);
      // Address
      button.dataset.street1 = street1;
      button.dataset.country = country;
      button.dataset.city = city;
      button.dataset.postalCode = postalCode;
      button.dataset.state = state;
      localStorage.setItem(`${createdBy}_street1`, street1);
      localStorage.setItem(`${createdBy}_country`, country);
      localStorage.setItem(`${createdBy}_city`, city);
      localStorage.setItem(`${createdBy}_postalCode`, postalCode);
      localStorage.setItem(`${createdBy}_state`, state);

      localStorage.setItem('PAYMENT_TYPE', createdBy);
      localStorage.setItem(`${createdBy}_date`, getToday());
    }
  };

  const tokenize = async () => {
    if (_amount) {
      const _tokenizedAmount = await tokenizeAmount(_amount);
      setTokenizedAmount(_tokenizedAmount);
    }
  };

  useEffect(() => {
    if (referencia) {
      tokenize();
    }
  }, [referencia, _amount]);

  const onPay = () => {
    setValues();
    const button = getNetPayCheckoutButton();
    if (button && referencia) {
      setTimeout(() => button.click(), 1000);
      setTimeout(() => setText(), 2000);
    }
  };

  if (!referencia) {
    return (
      <NetpayBtn
        style={style}
        onClick={generarReferencia}
        loading={loading}
        disabled={disabled}
      />
    );
  }

  return (<NetpayBtn style={style} onClick={onPay} loading={loading} disabled={disabled} />);
};

NetPaybutton.propTypes = {
  cargos: PropTypes.arrayOf(PropTypes.shape({
    importe: PropTypes.number.isRequired,
  })).isRequired,
  padron: PropTypes.shape({
    numero_de_celular: PropTypes.number,
    numero_de_telefono: PropTypes.number,
    telefono_celular: PropTypes.number,
    telefono_fijo: PropTypes.number,
    lada_celular: PropTypes.number,
    lada_fijo: PropTypes.number,
    correo_electronico: PropTypes.string,
    email: PropTypes.string,
    empresa: PropTypes.shape({
      lada_fijo: PropTypes.number,
      lada_celular: PropTypes.number,
      numero_de_celular: PropTypes.number,
      numero_de_telefono: PropTypes.number,
      telefono_celular: PropTypes.number,
      telefono_fijo: PropTypes.number,
      correo_electronico: PropTypes.string,
      direccion_de_ubicacion: PropTypes.shape({}),
      direccion: PropTypes.shape({}),
      ciudadano: PropTypes.shape({
        direccion: PropTypes.shape({}),
      }),
      contribuyente: PropTypes.shape({
        direccion: PropTypes.shape({}),
      }),
    }),
    contribuyente: PropTypes.shape({
      lada_fijo: PropTypes.number,
      lada_celular: PropTypes.number,
      numero_de_celular: PropTypes.number,
      numero_de_telefono: PropTypes.number,
      telefono_celular: PropTypes.number,
      telefono_fijo: PropTypes.number,
      correo_electronico: PropTypes.string,
      direccion: PropTypes.shape({}),
    }),
    propietario: PropTypes.shape({
      direccion: PropTypes.shape({}),
    }),
    ciudadano: PropTypes.shape({
      direccion: PropTypes.shape({}),
    }),
    contribuyente_propietario: PropTypes.shape({
      direccion: PropTypes.shape({}),
    }),
    direccion: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    direcciones: PropTypes.shape({}),
    direccion_index: PropTypes.shape({}),
    direccion_notificacion_index: PropTypes.shape({}),
    direccion_notificacion: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    direcciones_de_notificacion: PropTypes.shape({}),
    propNameContactInfo: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool,
  referencia: PropTypes.string,
  createdBy: PropTypes.oneOf(getEnumValues(paymentLocationsEnum)).isRequired,
  totalProp: PropTypes.string,
  generarReferencia: PropTypes.func,
  productCount: PropTypes.number,
  excludeValuesContactPublicoGeneral: PropTypes.bool,
  total: PropTypes.number,
  style: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

NetPaybutton.defaultProps = {
  loading: false,
  referencia: null,
  totalProp: 'adeudo_total',
  generarReferencia: null,
  productCount: null,
  excludeValuesContactPublicoGeneral: true,
  total: null,
  style: null,
  disabled: false,
};

export default NetPaybutton;
