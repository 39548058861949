import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Grid,
  Col,
} from 'antd';
import { Button } from 'components/index';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';

export const backControl = (onClick) => ({
  text: 'Regresar',
  icon: <ArrowLeftOutlined />,
  onClick: () => onClick(),
});

export const finishControl = (onClick) => ({
  text: 'Guardar',
  icon: <SaveOutlined />,
  onClick: () => onClick(),
});

const FormSubmitCustomControls = ({
  controls: _controls,
  loading,
  label,
  onCancel,
  onFinish,
  justify,
  style,
}) => {
  const screens = Grid.useBreakpoint();
  const controls = [
    onCancel && backControl(onCancel),
    ..._controls,
    onFinish && finishControl(onFinish),
  ].filter((e) => e);

  return (
    <Row justify={justify} style={style}>
      {!!label && (<Col>{label}</Col>)}
      <Col>
        {controls.filter((e) => e).map(({
          icon,
          key,
          onClick,
          disabled,
          text,
          ...btnProps
        }, idx) => (
          btnProps && (
            <Button
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...btnProps}
              type="link"
              onClick={onClick}
              key={key || idx}
              disabled={loading || disabled}
              fontSize={14}
            >
              {icon}
              {screens.md && (text)}
            </Button>
          )
        ))}
      </Col>
    </Row>
  );
};

FormSubmitCustomControls.propTypes = {
  controls: PropTypes.arrayOf(PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
  })),
  loading: PropTypes.bool,
  label: PropTypes.string,
  onCancel: PropTypes.func,
  onFinish: PropTypes.func,
  justify: PropTypes.oneOf(['center', 'end', 'start', 'space-between', 'space-around', 'space-evenly']),
  style: PropTypes.shape({}),
};

FormSubmitCustomControls.defaultProps = {
  loading: false,
  label: null,
  onCancel: null,
  onFinish: null,
  controls: [],
  justify: 'space-between',
  style: null,
};

export default FormSubmitCustomControls;
