import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Upload,
  Form,
  message,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import Select from 'components/PaginatedSelect';

const { Dragger } = Upload;

const Evidencia = ({ visible }) => {
  const form = Form.useFormInstance();
  const hasEvidence = Form.useWatch('tiene_pruebas', form);
  const props = {
    multiple: true,
    accept: ['.jpg', '.jpeg', '.png'],
    listType: 'picture',
    customRequest: (event) => {
      setTimeout(() => {
        event.onSuccess();
      }, 500);
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} subido correctamente.`, 3.5);
      } else if (status === 'error') {
        message.error(`${info.file.name} error al subir.`, 3.5);
      }
    },
    onDrop(e) {
      e.preventDefault();
    },
  };

  return (
    <Col span={visible ? 24 : 0}>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            label="¿Tiene evidencias de los hechos?"
            name="tiene_pruebas"
          >
            <Select trueFalse />
          </Form.Item>
        </Col>
        {hasEvidence && (
          <Col span={24}>
            <Form.Item
              label="Evidencia"
              name="evidencia"
              valuePropName="fileList"
              getValueFromEvent={(e) => e.fileList}
              rules={[{ required: true, message: 'Por favor suba al menos una evidencia.' }]}
            >
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Haga click o arrastre los archivos a esta área para subirlos.
                </p>
                <p className="ant-upload-hint">
                  Puede subir varios archivos de hasta 10MB.
                </p>
              </Dragger>
            </Form.Item>
          </Col>
        )}
      </Row>
    </Col>
  );
};

Evidencia.propTypes = {
  visible: PropTypes.bool,
};

Evidencia.defaultProps = {
  visible: false,
};

export default Evidencia;
