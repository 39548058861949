import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Divider,
  Modal,
  DatePicker,
  Spin,
} from 'antd';
import {
  DATE_FORMAT,
} from 'utils/formatters';
import { postDeclaracionNotarios, patchGenerarDeclaracion } from 'api/recaudacion';
import { SyncOutlined } from '@ant-design/icons';
import { Button, Text } from 'components';
import _logger from 'utils/logger';
import propTypes from 'prop-types';
import { RFC_PATTERN, CURP_PATTERN } from 'utils/patterns';
import TableNotarios from 'components/TableNotarios';
import ModalConfirm from './ModalConfirm';

const _rules = {
  required: (message) => ({
    required: true,
    message,
  }),
  numeric: [
    {
      validator: async (_, value) => {
        const regex = /^[0-9]+([.][0-9]{1,2})?$/;
        if (!value) {
          return Promise.resolve();
        }
        if (value?.split('.')?.[1]?.length > 2) {
          return Promise.reject(new Error('Solo se permiten hasta dos decimales (0.00).'));
        }
        if (!regex.test(value)) {
          return Promise.reject(new Error('Solo se permiten números.'));
        }
        return Promise.resolve();
      },
    },
  ],
  mayorCero: [
    {
      validator: async (_, value) => {
        if (value <= 0) {
          throw new Error('El valor debe ser mayor a 0.');
        }
      },
    },
  ],
  totalPorcentajeEnajenante: [
    {
      validator: async (_, value) => {
        if (value > 100) {
          throw new Error('El valor total no debe ser diferente de 100%');
        }
        if (value < 100) {
          throw new Error('El valor total no debe ser diferente de 100%');
        }
      },
    },
  ],
  numericInt: [
    {
      validator: async (_, value) => {
        const regex = /^[0-9]+$/;
        if (!value) {
          return Promise.resolve();
        }
        if (!regex.test(value)) {
          return Promise.reject(new Error('Solo se permiten números enteros.'));
        }
        return Promise.resolve();
      },
    },
  ],
  rfc: [{ pattern: RFC_PATTERN, message: ' Ingrese un RFC valido.' }],
  curp: [{ pattern: CURP_PATTERN, message: ' Ingrese una CURP valida.' }],
};

const FormNotarios = ({
  declaracionId,
  statusDeclaracion,
  padron,
  isComplementaria,
  visible,
  setVisible,
  callback,
  callbackCancel,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [padronValues, setPadronValues] = useState([]);
  const [porcentajeTotal, setPorcentajeTotal] = useState(0);
  const [postEnajenantes, setPostEnajenantes] = useState();
  const [countEnajenantes, setCountEnajenantes] = useState(0);

  const responseSucces = () => {
    setLoading(true);
    const _values = form.getFieldsValue();
    const cantidad_a_cargo = parseFloat(_values?.cantidad_a_cargo) || 0;

    setCountEnajenantes(0);
    callback(cantidad_a_cargo <= 0);
    setIsVisibleModal(false);
    setVisible(false);
    form.resetFields();
    setLoading(false);
  };

  const clearAllInfo = () => {
    form.resetFields();
    setPadronValues([]);
    setPorcentajeTotal(0);
  };

  const callbackCount = () => {
    const _total_procentaje = form.getFieldsValue(['porcentaje_total_enajenante']);
    setPorcentajeTotal(_total_procentaje);

    const values = form.getFieldsValue();
    const a = parseFloat(values.porcentaje0_enajenante) || 0;
    const b = _total_procentaje?.porcentaje_total_enajenante || 0;

    const total = [a, b].reduce((acc, item) => acc + item, 0);
    form.setFieldsValue({ porcentaje_total_enajenante: total });

    if (total > 100) {
      form.validateFields(['porcentaje_total_enajenante']).then(() => {
        form.setFields([
          {
            name: 'porcentaje_total_enajenante',
            errors: ['El valor no puede ser diferente de 100%'],
          },
        ]);
      });
    } else if (total < 100) {
      form.validateFields(['porcentaje_total_enajenante']).then(() => {
        form.setFields([
          {
            name: 'porcentaje_total_enajenante',
            errors: ['El valor no puede ser diferente de 100%'],
          },
        ]);
      });
    } else {
      form.setFieldsValue({ porcentaje_total_enajenante: total });
      form.setFields([
        {
          name: 'porcentaje_total_enajenante',
          errors: [],
        },
      ]);
    }
  };

  // Resetea el formulario cuando se cierra.
  useEffect(() => {
    if (visible) form.resetFields();
  }, [visible]);

  useEffect(() => {
    const padronInfo = {
      razon_social_nombre_completo: padron?.empresa?.contribuyente?.razon_social || '',
      rfc: padron?.empresa?.contribuyente?.rfc || '',
      curp: padron?.empresa?.contribuyente?.ciudadano?.curp || '',
      nombre: padron?.empresa?.contribuyente?.ciudadano?.nombre || '',
      apellido_paterno: padron?.empresa?.contribuyente?.ciudadano?.apellido_paterno || '',
      apellido_materno: padron?.empresa?.contribuyente?.ciudadano?.apellido_materno || '',
    };
    setPadronValues(padronInfo);
    setLoading(false);
  }, [declaracionId]);

  function redondear(numero) {
    const decimal = numero - Math.floor(numero);
    return decimal >= 0.51 ? Math.ceil(numero) : Math.floor(numero);
  }

  const concatRazonSocial = () => {
    const nombre = form.getFieldValue('nombre_enajenante') || '';
    const apellidoPaterno = form.getFieldValue('apellido_paterno_enajenante') || '';
    const apellidoMaterno = form.getFieldValue('apellido_materno_enajenante') || '';
    const razonSocial = `${apellidoPaterno} ${apellidoMaterno} ${nombre}`;
    form.setFieldsValue({ razon_social_nombre_completo_enajenante: razonSocial });
  };

  const patchDeclaracionStatus = async (values) => {
    try {
      let statusId = null;

      const statusObject = {
        generada: statusDeclaracion.find(
          (status) => !status.declaracion_presentada && !status.declaracion_en_ceros,
        )?.id,
        en_ceros: statusDeclaracion.find(
          (status) => status.declaracion_en_ceros,
        )?.id,
        sin_importe: statusDeclaracion.find(
          (status) => status.descripcion === 'Declaración con Cantidades a Pagar y a Favor en Cero',
        )?.id || 0,
        saldo_favor: statusDeclaracion.find(
          (status) => status.descripcion === 'Declaración con Cantidad a Favor',
        )?.id || 0,
      };

      if (parseFloat(values.cantidad_a_favor) > 0) {
        statusId = statusObject.saldo_favor;
      } else if (parseFloat(values.cantidad_a_cargo) <= 0) {
        statusId = statusObject.sin_importe;
      } else {
        statusId = statusObject.generada;
      }
      const request = await patchGenerarDeclaracion({
        id: values.declaracion_empresarial,
        estatus_de_declaracion_empresarial: statusId,
        razones_para_presentar_en_ceros: values.razones_para_presentar_en_ceros,
      });

      if (!request) throw new Error('Error al actualizar la declaración.');
    } catch (error) {
      _logger(error);
    }
  };

  // Envia los datos del formulario.
  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();

      const _padronValues = {
        razon_social_nombre_completo: padron?.empresa?.contribuyente?.razon_social || '',
        rfc: padron?.empresa?.contribuyente?.rfc || '',
        curp: padron?.empresa?.contribuyente?.ciudadano?.curp || '',
        nombre: padron?.empresa?.contribuyente?.ciudadano?.nombre || '',
        apellido_paterno: padron?.empresa?.contribuyente?.ciudadano?.apellido_paterno || '',
        apellido_materno: padron?.empresa?.contribuyente?.ciudadano?.apellido_materno || '',
      };

      let _values = {
        ...padronValues,
        ...values,
        ..._padronValues,
        entidad: 1,
        declaracion_empresarial: declaracionId,
        fecha_de_pago_realizado_anterior: null,
        fecha_firma_de_escritura: values.fecha_firma_de_escritura.format(DATE_FORMAT),
        monto_pagado_con_anterioridad: 0,
      };

      if (!isComplementaria) {
        _values = {
          ..._values,
          fecha_de_pago_realizado_anterior: null,
          monto_pagado_con_anterioridad: 0,
        };
      }

      await patchDeclaracionStatus(_values);
      const declaracionNotario = await postDeclaracionNotarios(_values);
      if (declaracionNotario?.id && countEnajenantes > 0) {
        postEnajenantes(declaracionNotario?.id);
      } else {
        responseSucces();
      }
    } catch (error) {
      _logger(error);
    } finally {
      setLoading(false);
    }
  };

  /*
   Calcula el pago en exceso
   Resta: impuesto_a_pagar_a_entidad_federativa  - monto_pagado_con_anterioridad llenar
   cuando es resultado negativo, y poner el absoluto/positivo de este resultado.
  */
  const totalPagoEnExceso = () => {
    const values = form.getFieldsValue();

    // const determinado = parseFloat(values.impuesto_a_pagar_a_entidad_federativa) || 0;
    const isr = parseFloat(values.impuesto_sobre_la_renta) || 0;
    const actualizacion = parseFloat(values.actualizacion) || 0;
    const recargos = parseFloat(values.recargos) || 0;
    const multas = parseFloat(values.multa_por_correccion_fiscal) || 0;
    const pagado_con_anterioridad = parseFloat(values.monto_pagado_con_anterioridad) || 0;

    const _contribuciones = [
      isr,
      actualizacion,
      recargos,
      multas,
    ].reduce((acc, item) => acc + item, 0);

    const isNegative = _contribuciones - pagado_con_anterioridad < 0;

    if (isNegative) {
      form.setFieldsValue({
        cantidad_a_favor: redondear(
          parseFloat(pagado_con_anterioridad - _contribuciones).toFixed(2),
        ),
        cantidad_a_cargo: 0,
      });
    } else {
      form.setFieldsValue({
        cantidad_a_favor: 0,
        cantidad_a_cargo: redondear(
          parseFloat(_contribuciones - pagado_con_anterioridad).toFixed(2),
        ),
      });
    }

    form.setFieldsValue({ total_de_contribuciones: parseInt(_contribuciones, 10) });
  };

  // Calcula el impuesto a pagar a la entidad federativa (el menor de los dos).
  const impuestoAPagar = () => {
    const values = form.getFieldsValue();

    const a = parseFloat(values.impuesto_estatal_determinado) || 0;
    const b = parseFloat(values.pago_provisional) || 0;

    const _pagar = a > b ? b : a;

    form.setFieldsValue({
      impuesto_a_pagar_a_entidad_federativa: parseInt(_pagar, 10),
      impuesto_sobre_la_renta: parseInt(_pagar, 10),
    });

    totalPagoEnExceso();
  };

  /*
  Calcula el porcentaje total del enajenante (suma de los 11 porcentajes) y
  lo pone en el campo 12, el total de los 11 porcentajes no debe ser diferente de 100% (1)
  y no debe ser diferente de 100% (1).
  */
  const totalPorcentajeEnajenante = () => {
    const values = form.getFieldsValue();
    const a = parseFloat(values.porcentaje0_enajenante) || 0;
    const b = porcentajeTotal.porcentaje_total_enajenante || 0;

    const total = [a, b].reduce((acc, item) => acc + item, 0);

    form.setFieldsValue({ porcentaje_total_enajenante: total });
    if (total > 100) {
      form.validateFields(['porcentaje_total_enajenante']).then(() => {
        form.setFields([
          {
            name: 'porcentaje_total_enajenante',
            errors: ['El valor total no puede ser diferente de 100%'],
          },
        ]);
      });
    } else if (total < 100) {
      form.validateFields(['porcentaje_total_enajenante']).then(() => {
        form.setFields([
          {
            name: 'porcentaje_total_enajenante',
            errors: ['El valor total no puede ser diferente de 100%'],
          },
        ]);
      });
    } else {
      form.setFieldsValue({ porcentaje_total_enajenante: total });
      form.setFields([
        {
          name: 'porcentaje_total_enajenante',
          errors: [],
        },
      ]);
    }
  };

  return (
    <>
      <Modal
        title={(
          <Row justify="space-between">
            <Text $accent>
              Formato de Notarios
            </Text>
          </Row>
      )}
        visible={visible}
        closable={false}
        footer={(
          <Row justify="end" gutter={[10, 15]}>
            <Col xs={24} sm={24} md={6} lg={8}>
              <Button
                style={{ width: '100%', borderRadius: 5 }}
                onClick={() => {
                  clearAllInfo();
                  callbackCancel();
                  setVisible(false);
                }}
                loading={loading}
                $accent
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={24} sm={24} md={6} lg={8}>
              <Button
                type="primary"
                style={{ width: '100%' }}
                onClick={() => setIsVisibleModal(true)}
                loading={loading}
                icon={<SyncOutlined />}
                $accent
              >
                Generar
              </Button>
            </Col>
          </Row>
      )}
        width="75vw"
      >
        <Spin spinning={loading} tip="Cargando...">
          <Form
            form={form}
            layout="vertical"
            onFinish={() => onFinish()}
            initialValues={{
              clave_de_entidad_federativa: 18,
              cantidad_a_cargo: 0,
              porcentaje0_enajenante: 0,
              porcentaje_total_enajenante: 0,
              monto_de_enajenacion: 0,
              ganancia_obtenida: 0,
              impuesto_estatal_determinado: 0,
              pago_provisional: 0,
              impuesto_a_pagar_a_entidad_federativa: 0,
              impuesto_sobre_la_renta: 0,
              actualizacion: 0,
              recargos: 0,
              multa_por_correccion_fiscal: 0,
              total_de_contribuciones: 0,
              fecha_de_pago_realizado_anterior: null,
              fecha_firma_de_escritura: null,
              monto_pagado_con_anterioridad: 0,
              cantidad_a_favor: 0,
              rfc_enajenante: '',
              curp_enajenante: '',
              nombre_enajenante: '',
              apellido_paterno_enajenante: '',
              apellido_materno_enajenante: '',
            }}
          >
            <Row gutter={10}>
              <Divider orientation="center">
                <Text $accent>
                  II. DATOS DE IDENTIFICACIÓN
                </Text>
              </Divider>

              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  name="fecha_firma_de_escritura"
                  label="Fecha de la firma de la escritura"
                  rules={[
                    _rules.required('El campo es requerido'),
                  ]}
                >
                  <DatePicker format={DATE_FORMAT} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  name="numero_de_escritura"
                  label="Número de escritura"
                  rules={[
                    _rules.required('El campo es requerido'),
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  name="clave_catastral"
                  label="Clave catastral"
                  rules={[
                    _rules.required('El campo es requerido'),
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  name="ubicacion_de_inmueble"
                  label="Ubicación del inmueble"
                  rules={[
                    _rules.required('El campo es requerido'),
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  name="clave_de_entidad_federativa"
                  label="Clave de la entidad federativa"
                  rules={[
                    _rules.required('El campo es requerido'),
                  ]}
                >
                  <Input value={18} disabled />
                </Form.Item>
              </Col>
              <Divider orientation="center">
                <Text $accent>
                  II. DETERMINACIÓM DEL IMPUESTO SOBRE LA RENTA
                </Text>
              </Divider>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  hasFeedback
                  name="monto_de_enajenacion"
                  label="A. Monto de la contraprestación pactada por la enajenación"
                  rules={[
                    _rules.required('El campo es requerido'),
                    ..._rules.numeric,
                    ..._rules.numericInt,
                  ]}
                >
                  <Input prefix="$" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  hasFeedback
                  name="ganancia_obtenida"
                  label="B. Ganancia obtenida"
                  rules={[
                    _rules.required('El campo es requerido'),
                    ..._rules.numeric,
                    ..._rules.numericInt,
                  ]}
                >
                  <Input
                    prefix="$"
                    onChange={(value) => {
                      form.setFieldsValue({
                        impuesto_estatal_determinado: parseInt(value.target.value * 0.05, 10),
                      });
                      impuestoAPagar();
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  hasFeedback
                  name="impuesto_estatal_determinado"
                  label="C. Impuesto estatal determinado (B x 5% Art 127 LISR)"
                  rules={[
                    _rules.required('El campo es requerido'),
                  ]}
                >
                  <Input prefix="$" disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  hasFeedback
                  name="pago_provisional"
                  label="D. pago provisional conforme al artículo 126 de la LISR"
                  rules={[
                    _rules.required('El campo es requerido'),
                    ..._rules.numeric,
                    ..._rules.numericInt,
                  ]}
                >
                  <Input prefix="$" onChange={() => impuestoAPagar()} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  hasFeedback
                  name="impuesto_a_pagar_a_entidad_federativa"
                  label="Impuesto determinado a pagar a la entidad federativa (el menor de c ó d )"
                  rules={[
                    _rules.required('El campo es requerido'),
                    ..._rules.numericInt,
                  ]}
                >
                  <Input prefix="$" onChange={() => totalPagoEnExceso()} disabled />
                </Form.Item>
              </Col>
              <Divider orientation="center">
                <Text $accent>
                  III. PAGO DEL IMPUESTO
                </Text>
              </Divider>
              <Col xs={24} sm={24} md={4} lg={8}>
                <Form.Item
                  label="A. ISR a pagar a la entidad federativa"
                  name="impuesto_sobre_la_renta"
                  hasFeedback
                >
                  <Input prefix="$" onChange={() => totalPagoEnExceso()} disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={4} lg={8}>
                <Form.Item
                  label="B. Actualización"
                  hasFeedback
                  name="actualizacion"
                  rules={[_rules.required('El campo es requerido'), ..._rules.numeric, ..._rules.numericInt]}
                >
                  <Input prefix="$" onChange={() => totalPagoEnExceso()} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={4} lg={8}>
                <Form.Item
                  name="recargos"
                  label="C. Recargos"
                  hasFeedback
                  rules={[_rules.required('El campo es requerido'), ..._rules.numeric, ..._rules.numericInt]}
                >
                  <Input prefix="$" onChange={() => totalPagoEnExceso()} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={4} lg={8}>
                <Form.Item
                  label="D. Multas por corrección fiscal"
                  name="multa_por_correccion_fiscal"
                  hasFeedback
                  rules={[_rules.required('El campo es requerido'), ..._rules.numeric, ..._rules.numericInt]}
                >
                  <Input prefix="$" onChange={() => totalPagoEnExceso()} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={4} lg={8}>
                <Form.Item
                  label="E. Total de contribuciones (A + B + C + D) o en su caso solo A"
                  name="total_de_contribuciones"
                  hasFeedback
                >
                  <Input prefix="$" disabled />
                </Form.Item>
              </Col>
              {/* {
                isComplementaria && (
                  <>
                    <Col xs={24} sm={24} md={4} lg={8}>
                      <Form.Item
                        label="F. Fecha del pago realizado anterior"
                        name="fecha_de_pago_realizado_anterior"
                        hasFeedback
                        rules={[_rules.required('El campo es requerido')]}
                      >
                        <DatePicker format={DATE_FORMAT} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={8}>
                      <Form.Item
                        hasFeedback
                        name="monto_pagado_con_anterioridad"
                        label="G. Monto pagado con anterioridad"
                        rules={[
                          ..._rules.numeric,
                          ..._rules.numericInt,
                        ]}
                      >
                        <Input prefix="$" onChange={() => totalPagoEnExceso()} />
                      </Form.Item>
                    </Col>
                  </>
                )
              } */}
              <Col xs={24} sm={24} md={4} lg={8}>
                <Form.Item
                  hasFeedback
                  name="cantidad_a_cargo"
                  label="H. Cantidad a cargo (E - G) o en su caso solo E"
                  rules={[
                    _rules.required('El campo es requerido'),
                  ]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={4} lg={8}>
                <Form.Item
                  hasFeedback
                  name="cantidad_a_favor"
                  label="I. Pago en exceso (G - E) cuando G es mayor a E"
                >
                  <Input prefix="$" disabled />
                </Form.Item>
              </Col>
              <Divider orientation="center">
                <Text $accent>
                  IV. DATOS DEL ENAJENANTE
                </Text>
              </Divider>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Text $accent>
                  Datos del enajenante, representante común o equivalente
                </Text>
              </Col>
              <br />
              <br />
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  name="rfc_enajenante"
                  label="Registro Federal de Contribuyentes"
                  rules={[..._rules.rfc]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  name="curp_enajenante"
                  label="Clave Única de Registro de Población"
                  rules={[..._rules.curp]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  name="nombre_enajenante"
                  label="Nombre"
                  required
                >
                  <Input onChange={() => concatRazonSocial()} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  name="apellido_paterno_enajenante"
                  label="Apellido Paterno"
                >
                  <Input onChange={() => concatRazonSocial()} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  name="apellido_materno_enajenante"
                  label="Apellido Materno"
                >
                  <Input onChange={() => concatRazonSocial()} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  name="razon_social_nombre_completo_enajenante"
                  label="Razón social o nombre completo"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item name="porcentaje0_enajenante" label="Porcentaje">
                  <Input onChange={() => totalPorcentajeEnajenante()} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <Text $accent>
                  Datos de propietarios o sociedad conyugal
                </Text>
              </Col>
              <br />
              <br />
              <Col xs={24} sm={24} md={24} lg={24}>
                <TableNotarios
                  setExecuteOnfinish={setPostEnajenantes}
                  callback={responseSucces}
                  callbackCount={() => callbackCount()}
                  setCountEnajenantes={(value) => setCountEnajenantes(value)}
                />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <Form.Item
                  name="porcentaje_total_enajenante"
                  label="Porcentaje total de enajenantes"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
      <ModalConfirm
        visible={isVisibleModal}
        setVisible={setIsVisibleModal}
        onFinish={() => onFinish()}
        body="Al generar la declaración no podrá ser modificada. ¿Desea continuar?"
        loading={loading}
      />
    </>
  );
};

FormNotarios.propTypes = {
  declaracionId: propTypes.number.isRequired,
  padron: propTypes.shape({
    empresa: propTypes.shape({
      contribuyente: propTypes.shape({
        razon_social: propTypes.string,
        rfc: propTypes.string,
        ciudadano: propTypes.shape({
          curp: propTypes.string,
          nombre: propTypes.string,
          apellido_paterno: propTypes.string,
          apellido_materno: propTypes.string,
        }),
      }),
    }),
  }).isRequired,
  statusDeclaracion: propTypes.number.isRequired,
  callbackCancel: propTypes.func.isRequired,
  visible: propTypes.bool,
  setVisible: propTypes.func.isRequired,
  callback: propTypes.func.isRequired,
  isComplementaria: propTypes.bool.isRequired,
};

FormNotarios.defaultProps = {
  visible: false,
};

export default FormNotarios;
