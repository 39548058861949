import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import redux_logger from 'redux-logger';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import auth from 'store/auth';
import catalogos from 'store/catalogos';
import app from 'store/app';
import { NODE_ENV } from 'utils/env';

const notInProductionEnv = NODE_ENV !== 'production';

const persistConfig = {
  key: 'store',
  storage,
  blacklist: ['catalogos', 'app'],
};

const reducers = combineReducers({ auth, catalogos, app });
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      immutableCheck: false,
    });
    if (notInProductionEnv) {
      middlewares.push(redux_logger);
    }
    return middlewares;
  },
  devTools: notInProductionEnv,
});

export default store;
