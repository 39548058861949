import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Tabs, Text } from 'components';
import { List, Tag } from 'antd';
import {
  EyeOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons';
import { getTramites } from 'api/tramites/tramites';
import { Link } from 'react-router-dom';
import { tiposDePasosEnum } from 'views/Tramites/GPM/enums';
import padronToString from 'utils/padronesToString';
import { CATALOGOS_DE_CANAL_DE_PAGO } from 'utils/env';

const { TabPane } = Tabs;

const sharedProps = { params: {} };

const Tramites = ({ params }) => {
  const [loading, setLoading] = useState(true);
  const [tramites, setTramites] = useState([]);

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      const _tramites = await getTramites(params, true);
      if (mounted) {
        setTramites(_tramites);
        setLoading(false);
      }
    };
    fetch();

    return () => { mounted = false; };
  }, []);

  return (
    <List
      loading={loading}
      itemLayout="horizontal"
      dataSource={tramites}
      rowKey="id"
      style={{ width: '100%' }}
      renderItem={({
        id,
        solicitante_ciudadano,
        plantilla,
        folio,
        ciudadano,
        contribuyente,
        tipo_padron_solicitante,
        padron_afectado,
        estados_globales,
      }) => (
        <List.Item
          actions={[
            <Button $accent>
              <Link to={`/tramites/tramite/${plantilla.id}`}>
                <Text>
                  Ver ficha
                </Text>
                <EyeOutlined />
              </Link>
            </Button>,
            plantilla.catalogo_de_canales_de_pago.includes(CATALOGOS_DE_CANAL_DE_PAGO.PAGO_EN_LINEA)
              && (
                <Button $accent>
                  <Link to={`/tramites/seguimiento/${id}`}>
                    <Text>
                      Ver solicitud
                    </Text>
                    <EyeOutlined />
                  </Link>
                </Button>
              ),
          ].filter((e) => e)}
        >
          <List.Item.Meta
            title={(
              <>
                {plantilla.nombre}
                {' - '}
                {folio}
                {(estados_globales === 4 && plantilla.pasos_para_tramites.at(-1)
                  ?.tipo_de_paso === tiposDePasosEnum.EMISION_DE_DOCUMENTO) && (
                    <Tag style={{ marginLeft: 10 }}>
                      Emite documento
                    </Tag>
                )}
              </>
            )}
            description={(
              <>
                {padronToString(solicitante_ciudadano
                  ? ciudadano : contribuyente, tipo_padron_solicitante, true)}
                {!!padron_afectado && (
                  <>
                    <br />
                    {padronToString(padron_afectado, padron_afectado.tipo_de_padron, true)}
                  </>
                )}
              </>
            )}
          />
        </List.Item>
      )}
      locale={{ emptyText: 'No hay trámites en proceso' }}
    />
  );
};

Tramites.propTypes = {
  params: PropTypes.shape({}),
};

Tramites.defaultProps = sharedProps;

const MisTramites = () => (
  <Tabs type="card" defaultActiveKey="en_proceso">
    <TabPane
      tab={(
        <>
          <FieldTimeOutlined />
          Trámites En Proceso
        </>
      )}
      key="en_proceso"
      forceRender
    >
      <Tramites params={{ estados_globales: 2 }} />
    </TabPane>
    <TabPane
      tab={(
        <>
          <CheckCircleOutlined />
          Trámites Finalizados
        </>
      )}
      key="finalizados"
      forceRender
    >
      <Tramites params={{ estados_globales: 4 }} />
    </TabPane>
    <TabPane
      tab={(
        <>
          <CloseCircleOutlined />
          Trámites Cancelados
        </>
      )}
      key="cancelados"
      forceRender
    >
      <Tramites params={{ estados_globales: 5 }} />
    </TabPane>
  </Tabs>
);

export default MisTramites;
