import React, { useEffect, useState } from 'react';
import { Button } from 'components';
import { PlusOutlined, DeleteFilled, EditFilled } from '@ant-design/icons';
import {
  Table,
  Row,
  Col,
  Modal,
  Form,
  Input,
  Tooltip,
} from 'antd';
import ModalConfirm from 'components/ModalConfirm';

import PropTypes from 'prop-types';
import { postDecaracionAnalisisISAN, getTiposDeUnidadesISAN } from 'api/recaudacion';
import _logger from 'utils/logger';
import Select from './Select';

const TableISAN = ({
  declaracionIsanId,
  callback,
  callbackCount,
}) => {
  const [form] = Form.useForm();
  const formPadre = Form.useFormInstance();
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState(false);
  const [catalogoTipo, setCatalogoTipo] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);

  const getTiposDeUnidades = async () => {
    try {
      const response = await getTiposDeUnidadesISAN();
      if (response) setCatalogoTipo(response);
    } catch (error) {
      _logger(error);
    }
  };

  useEffect(() => { getTiposDeUnidades(); }, []);

  const _rules = {
    required: (message) => ({
      required: true,
      message,
    }),
    numeric: [
      {
        validator: async (_, value) => {
          const regex = /^[0-9]+([.][0-9]{1,2})?$/;
          if (!value) {
            return Promise.resolve();
          }
          if (value?.split('.')?.[1]?.length > 2) {
            return Promise.reject(new Error('Solo se permiten hasta dos decimales (0.00)'));
          }
          if (!regex.test(value)) {
            return Promise.reject(new Error('Solo se permiten números'));
          }
          return Promise.resolve();
        },
      },
    ],
    mayorCero: [
      {
        validator: async (_, value) => {
          if (value <= 0) {
            throw new Error('El valor debe ser mayor a 0');
          } else {
            return Promise.resolve();
          }
        },
      },
    ],
    numericInt: [
      {
        validator: async (_, value) => {
          const regex = /^[0-9]+$/;
          if (!value) {
            return Promise.resolve();
          }
          if (!regex.test(value)) {
            return Promise.reject(new Error('Solo se permiten números enteros.'));
          }
          return Promise.resolve();
        },
      },
    ],
  };

  const sumarTotal = () => {
    const total = data.reduce((acc, item) => acc + parseFloat(item.total), 0);
    formPadre.setFieldsValue({
      total_de_sumas_de_valor_total: total,
      suma_acumulada: total,
      suma_de_montos_anotados: total,
    });
  };

  useEffect(() => {
    sumarTotal();
    callbackCount(data);
  }, [data]);

  const addAuto = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldValue();
      const _data = [...data, { ...values, key: data.length + 1 }];
      setData(_data);
      setModalOpen(false);
      form.resetFields();
    } catch (error) {
      _logger(error);
    }
  };

  const deleteAuto = () => {
    const auto = form.getFieldValue();
    const newData = data.filter((item) => item.key !== auto.key);
    setData(newData);
    setModalOpen(false);
  };

  const updateAuto = async () => {
    try {
      await form.validateFields();
      const auto = form.getFieldValue();
      const newData = data.map((item) => {
        if (item.key === auto.key) {
          return auto;
        }
        return item;
      });
      setData(newData);
      setModalOpen(false);
      form.resetFields();
    } catch (error) {
      _logger(error);
    }
  };

  const onFinish = async () => {
    const values = data.map((item) => ({
      tipo_de_unidad_isan: item.tipo_de_unidad_isan,
      modelo: item.modelo,
      unidades: item.unidades,
      valor_total_enajenacion: item.total,
      declaracion_empresarial_isan: declaracionIsanId,
      entidad: 1,
    }));

    const response = await postDecaracionAnalisisISAN(values);
    if (response) {
      form.resetFields();
      setData([]);
      setModalOpen(false);
      callback('analisis_isan', declaracionIsanId);
    }
  };

  const handleOnDelete = (val) => {
    setSelectedRowKeys(val);
    if (selectedRowKeys) {
      setOpenDelete(true);
    }
  };

  const columns = [
    {
      title: 'TIPO',
      dataIndex: 'tipo_de_unidad_isan',
      key: 'tipo',
    },
    {
      title: 'MODELO',
      dataIndex: 'modelo',
      key: 'modelo',
    },
    {
      title: 'UNIDADES',
      dataIndex: 'unidades',
      key: 'unidades',
    },
    {
      title: 'VALOR TOTAL DE LA ENAJENACIÓN',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'ACCIONES',
      key: 'acciones',
      render: (_, val) => (
        <Row justify="center">
          <Button
            type="link"
            onClick={() => {
              handleOnDelete(val);
            }}
          >
            <Tooltip title="Eliminar">
              <DeleteFilled />
            </Tooltip>
          </Button>
          <Button
            type="link"
            onClick={
            () => {
              setModalOpen(true);
              setSelectedRowKeys(val);
            }
          }
          >
            <Tooltip title="Editar">
              <EditFilled />
            </Tooltip>
          </Button>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    if (declaracionIsanId) {
      onFinish();
    }
  }, [declaracionIsanId]);

  const modal = () => (
    <Modal
      title="Agregar"
      onCancel={() => {
        setModalOpen(false);
        form.resetFields();
      }}
      style={{ top: 20 }}
      visible={modalOpen}
      footer={[
        <Button
          key="update"
          onClick={() => {
            setModalOpen(false);
            form.resetFields();
          }}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            if (selectedRowKeys) {
              updateAuto();
            } else {
              addAuto();
            }
          }}
        >
          {selectedRowKeys ? 'Actualizar' : 'Agregar'}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
      >
        <Row gutter={10}>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              name="tipo_de_unidad_isan"
              label="Tipo de unidad"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'El tipo es requerido.',
                },
              ]}
            >
              <Select options={catalogoTipo} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              name="modelo"
              label="Modelo"
              hasFeedback
              rules={[
                _rules.required('El modelo es requerido.'),
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              name="unidades"
              label="Unidades"
              hasFeedback
              rules={[
                _rules.required('Las unidades son requeridas.'),
                ..._rules.numericInt,
                ..._rules.mayorCero,
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              name="total"
              label="Valor total de la enajenación"
              hasFeedback
              rules={[
                _rules.required('Las unidades son requeridas.'),
                ..._rules.numeric,
                ..._rules.mayorCero,
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );

  return (
    <>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={8}>
          <Button
            size="small"
            icon={<PlusOutlined />}
            style={{ border: 'none', marginTop: '10px' }}
            onClick={() => {
              setModalOpen(true);
              setSelectedRowKeys(false);
              form.resetFields();
            }}
          >
            Agregar
          </Button>
        </Col>
      </Row>
      <Table
        style={{ width: '70%', margin: '10px 0 30px 5px' }}
        dataSource={data}
        columns={columns}
        pagination={false}
        onRow={(record) => ({
          onClick: () => {
            form.setFieldsValue(record);
          },
        })}
      />
      { modal() }
      <ModalConfirm
        title="Eliminar"
        body="¿Está seguro de eliminar la unidad?"
        btnText="Si, Eliminar"
        onFinish={() => deleteAuto()}
        setVisible={setOpenDelete}
        visible={openDelete}
      />
    </>
  );
};

export default TableISAN;

TableISAN.propTypes = {
  declaracionIsanId: PropTypes.number.isRequired,
  callback: PropTypes.func.isRequired,
  callbackCount: PropTypes.func.isRequired,
};
