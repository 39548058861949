/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  ladas: [],
  tiposVialidades: [],
  estadosGlobales: [],
  padrones: [],
  contentTypesPadrones: [],
  clasificadoresDeTiposDeCargos: {},
};

export const catalogosSlice = createSlice({
  name: 'catalogos',
  initialState,
  reducers: {
    setLadas: (state, { payload }) => {
      state.ladas = payload;
    },
    setTiposDeVialidades: (state, { payload }) => {
      state.tiposVialidades = payload;
    },
    setEstadosGlobales: (state, { payload }) => {
      state.estadosGlobales = payload;
    },
    setPadrones: (state, { payload }) => {
      state.padrones = payload;
    },
    setContentTypesPadrones: (state, { payload }) => {
      state.contentTypesPadrones = payload;
    },
    setClasificadoresDeTiposDeCargos: (state, { payload }) => {
      state.clasificadoresDeTiposDeCargos = payload;
    },
    setMetodosDePago: (state, { payload }) => {
      state.metodosDePago = payload;
    },
    setMeses: (state, { payload }) => {
      state.meses = payload;
    },
    clearCatalogs: (state) => {
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key] || [];
      });
    },
  },
});

export const {
  setLadas,
  setTiposDeVialidades,
  setEstadosGlobales,
  setBancos,
  setMetodosDePago,
  setMonedas,
  setPadrones,
  setContentTypesPadrones,
  setClasificadoresDeTiposDeCargos,
  setMeses,
  clearCatalogs,
} = catalogosSlice.actions;

// Data Selectors
export const selectLadas = ({ catalogos }) => catalogos.ladas;
export const selectTiposVialidades = ({ catalogos }) => catalogos.tiposVialidades;
export const selectEstadosGlobales = ({ catalogos }) => catalogos.estadosGlobales;
export const selectBancos = ({ catalogos }) => catalogos.bancos;
export const selectMetodosDePago = ({ catalogos }) => catalogos.metodosDePago;
export const selectMonedas = ({ catalogos }) => catalogos.monedas;
export const selectPadrones = ({ catalogos }) => catalogos.padrones;
export const selectContentTypesPadrones = ({ catalogos }) => catalogos.contentTypesPadrones;
export const selectMeses = ({ catalogos }) => catalogos.meses;
export const selectClasificadoresDeTiposDeCargos = ({ catalogos }) => catalogos
  .clasificadoresDeTiposDeCargos;

export default catalogosSlice.reducer;
