import React from 'react';
import { Card, Text, Title } from 'components';
import { REPUVE } from 'utils/env';

const PrivacyNotice = () => (
  <Card>
    <Title $accent style={{ textAlign: 'center' }}>
      AVISO DE PRIVACIDAD
    </Title>
    <br />
    <Text>
      {REPUVE
        ? 'Sistema Estatal de Seguridad Pública'
        : 'La Secretaria de Administración y Finanzas del Gobierno del Estado de Nayarit'}
      ,
      ubicada en Avenida México y calle Abasolo sin número, Colonia Centro, C.P. 63000,
      en la ciudad de Tepic, Nayarit, es la dependencia responsable del tratamiento,
      uso y protección de sus datos personales que de manera directa o indirecta pueden ser
      utilizados en el ejercicio de sus funciones y atribuciones propias, mismos que serán
      salvaguardados conforme a la Constitución Política de los Estados Unidos Mexicanos, la Ley
      General de Protección de Datos Personales en posesión de Sujetos Obligados, las disposiciones
      de la Ley de Protección de Datos Personales en posesión de Sujetos Obligados del Estado de
      Nayarit, y demás normativa interna aplicable.
    </Text>
    <br />
    <br />
    <Text>
      Los datos personales, se refieren a la información concerniente a una persona física
      identificada o identificable, es decir, cuando su identidad pueda determinarse directa o
      indirectamente a través de cualquier información; y por datos personales sensibles, aquellos
      que afecten a la esfera más íntima de su titular, o cuya utilización indebida pueden dar
      origen a discriminación o conlleve un riesgo grave para éste, de conformidad a lo dispuesto
      por las fracciones VIII y IX del artículo 4 de la Ley de Protección de Datos Personales en
      Posesión de los Sujetos Obligados del Estado de Nayarit.
    </Text>
    <br />
    <br />
    <Text>
      El tratamiento de sus datos personales se realiza con fundamento en lo establecido en los
      artículos 6 fracciones II y III, 16 párrafo segundo de la Constitución Política de los
      Estados Unidos Mexicanos; 1, 2, 3 fracciones II, XXXIII, 21, 22 fracción II, 28 y demás
      relativos de la Ley General de Protección de Datos Personales en posesión de los Sujetos
      Obligados.
    </Text>
    <br />
    <br />
    <Text>
      Los datos personales que pueden ser sometidos a tratamiento de manera indirecta, en razón
      del asesoramiento, orientación y apoyo a los sujetos obligados de la administración pública
      estatal, para que cumplan de manera eficaz con sus obligaciones en materia de transparencia,
      derecho de acceso a la información pública y protección de datos personales, son: nombre,
      domicilio, teléfono, correo electrónico y firma; es oportuno señalar que esta información
      puede ser transferida a terceros sin consentimiento del titular en los siguientes casos:
      cuando una ley así lo disponga, debiendo dichos supuestos ser acordes con las bases,
      principios y disposiciones establecidos en la Ley de Protección de Datos Personales en
      posesión de sujetos obligados local, en ningún caso, podrán contravenirla; cuando los datos
      personales se requieran para ejercer un derecho o cumplir obligaciones derivadas de una
      relación jurídica entre el titular y el responsable; cuando exista una situación de
      emergencia que potencialmente pueda dañar a un individuo en su persona o en sus bienes;
      cuando los datos personales sean necesarios en la atención de algún servicio sanitario de
      prevención o diagnóstico; en el supuesto de que los datos personales figuren en fuentes de
      acceso público; cuando exista una orden judicial, resolución o mandato fundado y motivado de
      autoridad competente; cuando los datos personales se sometan a un procedimiento previo de
      disociación; para el reconocimiento o defensa de derechos del titular ante autoridad
      competente; cuando el titular de los datos personales sea una persona reportada como
      desaparecida en los términos de la ley en la materia; o cuando las transferencias que se
      realicen entre responsables, sean sobre datos personales que se utilicen para el ejercicio
      de facultades propias, compatibles o análogas con la finalidad que motivó el tratamiento de
      los datos personales. Los datos personales podrán ser recabados de manera directa o
      indirectamente a través de las Unidades de Transparencia de los Sujetos Obligados, ya sea
      por medios electrónicos o por comparecencia, y serán utilizados para las finalidades
      siguientes: brindar seguimiento a las solicitudes de acceso a información pública,
      solicitudes de ejercicio de derechos ARCO, recursos de revisión, recursos de transparencia,
      recursos de revisión en materia de protección de datos personales en posesión de Sujetos
      Obligados del Poder Ejecutivo, realización de capacitaciones a sociedad civil, el registro de
      los participantes a eventos y capacitaciones, así como la celebración de convenios con
      personas físicas y/o morales. Con relación a la transferencia de información confidencial,
      los terceros receptores de los datos personales pueden ser los Sujetos Obligados
      pertenecientes a la Administración Pública estatal, a los que se dirijan las solicitudes
      tanto de información pública como del ejercicio de derechos ARCO que sean de su competencia,
      las diferentes áreas del sujeto obligado, en caso de que se dé vista por el posible
      incumplimiento a la Ley que rige la materia.
    </Text>
    <br />
    <br />
    <Text>
      Usted puede solicitar a la Secretaria de Administración y Finanzas, en cualquier tiempo, su
      Acceso, Rectificación, Cancelación, Oposición o Revocación del consentimiento, mediante la
      presentación de solicitud de ejercicio de derechos ARCO ante la Unidad de Transparencia,
      ubicada en
      {REPUVE
        ? 'Calle Platino #34, Col. Ciudad Industrial, Tepic, Nayarit'
        : 'Veracruz #144 Sur, planta baja, colonia Centro, C.P. 63000, en la ciudad Tepic, Nayarit'}
      .
      Tiene el inalienable derecho de conocer qué datos personales tenemos de usted, para
      qué los utilizamos y las condiciones del uso que les damos (Acceso). De igual forma, es su
      derecho solicitar la corrección de su información personal en caso de que no esté actualizada,
      sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases
      de datos cuando considere que la misma no está siendo utilizada conforme a los principios,
      deberes y obligaciones previstas en la normativa (Cancelación); así como oponerse al uso de
      sus datos personales para fines específicos (Oposición). Estos derechos se conocen como
      derechos ARCO. Cualquier cambio o modificación al presente aviso de privacidad se hará del
      conocimiento de los titulares de la información confidencial, a través de la página de
      internet de este sujeto obligado, la cual es
      {' '}
      <a to="https://www.nayarit.gob.mx" target="blank">
        https://www.nayarit.gob.mx
      </a>
    </Text>
  </Card>
);

export default PrivacyNotice;
