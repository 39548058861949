import Moment from 'moment';
import { selectConfiguracionEntidad } from 'store/auth';
import store from 'store/store';
import { DECIMAL_PATTERN, INTEGER_PATTERN } from 'utils/patterns';

export const dateRegExp = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
export const fullDateRegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}((.\d+)-\d{2}:\d{2})?$/;
export const dateAmericaMazatlanRegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{6}-\d{2}:\d{2}/;
export const dateTimeArizonaTimeRegexExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{6}/;
export const dateRegExps = [
  /^\d{4}-\d{2}-\d{2}$/,
  /^\d{2}-\d{2}-\d{4}$/,
  fullDateRegExp,
  dateAmericaMazatlanRegExp,
  dateTimeArizonaTimeRegexExp,
];

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMATS = [
  'YYYY-MM-DD',
  'DD-MM-YYYY',
  'YYYY-MM-DDTHH:mm:ss',
  'YYYY-MM-DDTHH:mm:ss.SSSSSSZZ',
  'YYYY-MM-DDTHH:mm:ss.SSSSSS',
];
export const TIME_FORMAT = 'hh:mm:ss';
export const FULL_DATE_FRONTEND_FORMAT = 'DD/MM/YYYY hh:mm a';
export const DATE_FRONTEND_FORMAT = 'DD-MM-YYYY';

/**
 * Format to Currency
 * @param {string|number} value
 * @param {boolean} withoutCurrencySymbol
 * @returns string
 */
export const toCurrency = (value, withoutCurrencySymbol = false) => {
  const val = typeof value !== 'number' ? 0 : value;
  const output = new Intl.NumberFormat('en-us', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
  }).format(val);

  if (withoutCurrencySymbol) {
    return output.replace('$', '');
  }

  return output;
};

/**
 * Format to Upper
 * @param {string} val
 * @returns string
 */
export const toUpper = (val = '') => val?.toUpperCase();

/**
 * Titleizer
 * @param {string} val
 * @returns string
 */
export const titleize = (val) => (val ? `${val[0].toUpperCase()}${val.substring(1).toLowerCase()}` : '');

/**
 * Format to Upper based on entity config
 * @param {string} val
 * @returns string
 */
export const toUpperFromConfig = (v = '', fallbackFormatter = (x) => x) => (selectConfiguracionEntidad(store.getState()).solo_mayusculas ? v?.toUpperCase() : fallbackFormatter(v));

/**
 * Format to Integer
 * @param {string} val
 * @returns number|null
 */
export const toInteger = (val) => parseInt(val.replace(/[^0-9]+/g, '') || 0, 10) || null;

/**
 * Format to Integer
 * @param {string} val
 * @returns number|null
 */
export const decimalNormalizer = (_val = '') => {
  if (DECIMAL_PATTERN.test(_val)) {
    return _val;
  }
  if (parseFloat(_val)) {
    return parseFloat(_val);
  }
  const val = _val.toString();
  const normalized = val.replace(INTEGER_PATTERN, '');
  const includeDot = val[val.length - 1] === '.';
  return includeDot ? normalized.concat('.') : parseInt(normalized, 10);
};

/**
 * List Formatter
 * @param {string[]} list
 * @param {'conjunction'|'disjunction'} type
 * @returns
 */
export const listToString = (
  list,
  type = 'conjunction',
) => new Intl.ListFormat('es-MX', { type }).format(list);

/**
 * Normalizer
 * @param {string} val
 * @returns string
 */
export const normalizeValue = (val = '', toLowerCase = true) => {
  if (!toLowerCase) {
    return val.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
  return val.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
};

/**
 * Humanizer
 * @param {string} val
 * @returns string
 */
export const humanizeString = (string, _titleize = false) => {
  const splitted = string.split('/');
  const huminized = splitted[splitted.length - 1]
    .toLowerCase().replace(/[_-]+/g, ' ')
    .replace(/\s{2,}/g, ' ')
    .trim();
  if (_titleize) {
    return titleize(huminized);
  }
  return huminized;
};

/**
 * Spaces remover
 * @param {string} val
 * @returns string
 */
export const noSpaces = (val) => val?.replace(/\s/g, '');

const isJSON = (val) => {
  try {
    const parsed = JSON.parse(val);
    return parsed;
  } catch {
    return false;
  }
};

const formatReceivedValues = (values = {}) => {
  const keys = Object.keys(values);
  const clone = {};
  keys.forEach((prop) => {
    const value = values[prop];
    clone[prop] = value;
    // check to discart falsy values
    if ((value === null || value === undefined || value === '')) {
      delete clone[prop];
    }
    const regexIdx = dateRegExps.findIndex((e) => e.test(value));
    if (regexIdx !== -1) {
      clone[prop] = new Moment(value, [DATE_FORMATS[regexIdx]]);
    }

    if ((prop === 'direccion' || prop === 'domicilio')) {
      const parsedValue = isJSON(value);
      if (/^[0-9]$/.test(value)) {
        clone[prop] = value;
      } else if (parsedValue) {
        clone[prop] = parsedValue;
      }
    }
  });
  clone.key = clone.id;
  return clone;
};

export const formatReceived = (values) => {
  if (Array.isArray(values)) {
    return values.map((e) => formatReceivedValues(e));
  }
  return formatReceivedValues(values);
};

export const hasFiles = (values = {}, files = []) => files
  .some((prop) => values[prop] && typeof values[prop] !== 'string');

export const formatSent = (values, options = {}, fullDate = false) => {
  const {
    files = [],
    formData: asFormData,
    clean = true,
  } = options;
  const keys = Object.keys(values);
  const clone = {};
  keys.forEach((prop) => {
    const value = values[prop];
    clone[prop] = value;

    // check to discart falsy values
    if (clean && (value === null || value === undefined || value === '')) {
      delete clone[prop];
    } else if (value === null || value === undefined || value === '') {
      clone[prop] = asFormData ? '' : null;
    }

    // check if the values is a date, only in get
    if (value instanceof Date || dateRegExps.some((e) => e.test(value))) {
      clone[prop] = new Moment(value);
    }

    // check if the value is a Moment instance, only if not get
    if (Moment.isMoment(value)) {
      if ((Array.isArray(fullDate) && fullDate.includes(prop)) || fullDate === true) {
        clone[prop] = value.format(`${DATE_FORMAT}[T]HH:mm:ss[Z]`);
      } else {
        clone[prop] = value.format(DATE_FORMAT);
      }
    }

    // checking if the value is a instance of file
    if (files.includes(prop)) {
      if (value?.file instanceof File) {
        clone[prop] = value.file;
      } else if (!asFormData && !clean) {
        clone[prop] = null;
      } else {
        delete clone[prop];
      }
    }
  });
  if (asFormData) {
    const formData = new FormData();
    const valuesKeys = Object.keys(clone);
    valuesKeys.forEach((key) => {
      formData.append(`${key}`, clone[key]);
    });
    return formData;
  }
  return clone;
};

/**
 * Hide characters of a string
 * @param {string} value Word to hide
 * @param {boolean} firstChar Just show the first character
 * @param {number} lastChar Number of characters to show
 * @returns string Example: Hello World -> H**** W****
 */
export const hideProp = (value = '', firstChar = true, lastChar = 1) => {
  if (value === '') return undefined;
  const splited = value.split(' ');
  const output = splited
    .map((p) => p
      .split('')
      .map((c, idx, arr) => ((!firstChar
        && idx >= arr.length - lastChar)
        || (firstChar && idx === 0)
        ? c : '*'))
      .join(''))
    .join(' ');
  return output;
};

export const fundamentoLegaleFormat = (value = null) => {
  if (!value) return null;
  const {
    denominacion,
    articulo,
    fraccion,
    inciso,
    parrafo,
    apartado,
  } = value;

  const articuloStr = articulo ? `Artículo: ${articulo}` : '';
  const fraccionStr = fraccion ? `Fracción: ${fraccion}` : '';
  const incisoStr = inciso ? `Inciso: ${inciso}` : '';
  const parrafoStr = parrafo ? `Párrafo: ${parrafo}` : '';
  const apartadoStr = apartado ? `Apartado: ${apartado}` : '';
  // separado con guiones
  const chain = [
    denominacion,
    articuloStr,
    fraccionStr,
    incisoStr,
    parrafoStr,
    apartadoStr,
  ].filter((e) => e);

  return chain.join(' - ').trim();
};

/**
  * @param {object[]} value
  * @param {string} value[].denominacion
  * @param {string} value[].articulo
  * @param {string} value[].fraccion
  * @param {string} value[].inciso
  * @param {string} value[].parrafo
  * @param {string} value[].apartado
  * @returns string
*/
export const fundamentosLegalesFormat = (value = []) => {
  const chain = value.map(fundamentoLegaleFormat);
  return listToString(chain);
};
