import API from 'api/index';
import _logger from 'utils/logger';

export const baseURI = 'tramites/plantillas-de-tramites/';

/**
 * GET Plantillas Tramites
 * param {object} params
 * @returns {Promise<object>}
 */
export const getPlantillasTramites = async (params) => {
  try {
    const response = await API.get(baseURI, { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return { results: [] };
  }
};

/**
 * GET Plantilla de Tramite
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getPlantillaTramite = async (id) => {
  try {
    const response = await API.get(`${baseURI}${id}`);
    response.data.pasos_para_tramites = response.data.pasos_para_tramites
      ?.sort((a, b) => a.orden - b.orden) || [];
    return response.data;
  } catch (err) {
    _logger(err);
    return null;
  }
};
