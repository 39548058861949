import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Form,
  DatePicker,
  Input,
} from 'antd';
import Select from 'components/Select';
import { HideToPreserve } from 'components/Steps';
import { getCategoriasDeQuejas, getMotivosDeQuejas } from 'api/solicitudes/quejas';

const InformacionGeneral = ({ visible }) => {
  const [motivos, setMotivos] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [filteredMotivos, setFilteredMotivos] = useState();

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const [_motivos, _categorias] = await Promise.all([
        getMotivosDeQuejas(),
        getCategoriasDeQuejas(),
      ]);
      if (mounted) {
        setMotivos(_motivos);
        setCategorias(_categorias);
      }
    };
    fetchData();
    return () => { mounted = false; };
  }, []);

  const rules = {
    required: [{ required: true }],
  };

  const onChangeCategoria = (value) => {
    if (!value) {
      setFilteredMotivos([]);
      return;
    }
    const filtered = motivos.filter((motivo) => motivo.categoria_de_la_queja === value);
    setFilteredMotivos(filtered);
  };

  return (
    <HideToPreserve visible={visible}>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            label="Tipo de protesta"
            name="categoria_de_solicitud"
            rules={rules.required}
          >
            <Select options={categorias} onChange={onChangeCategoria} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            label="Objeto de alteración o incumplimiento"
            name="objeto_de_alteracion"
            rules={rules.required}
          >
            <Select options={filteredMotivos} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            label="Fecha de los hechos"
            name="fecha_de_los_hechos"
            rules={rules.required}
          >
            <DatePicker
              disabledDate={(current) => current.isAfter(new Date())}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item
            label="Hora aproximada de los hechos"
            name="hora_de_los_hechos"
            rules={rules.required}
          >
            <DatePicker.TimePicker placeholder="" use12Hours format="h:mm:ss a" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Nombre de la oficina en donde sucedieron los hechos"
            name="oficina_de_queja"
            rules={rules.required}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="De que institución es el Trámite/Servicio o el personal con quien trató"
            name="institucion_de_queja"
            rules={rules.required}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Ingrese el nombre del trámite o servicio que quería realizar"
            name="tramite_de_queja"
            rules={rules.required}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Detalle de los hechos"
            name="detalle_de_hechos"
            rules={rules.required}
          >
            <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
          </Form.Item>
        </Col>
      </Row>
    </HideToPreserve>
  );
};

InformacionGeneral.propTypes = {
  visible: PropTypes.bool,
};

InformacionGeneral.defaultProps = {
  visible: false,
};

export default InformacionGeneral;
