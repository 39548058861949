import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Calendar,
  Col,
  Form,
  // Grid,
  Input,
  // Modal,
  Row,
  Tooltip,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Select from 'components/Select';
import PaginatedSelect from 'components/PaginatedSelect';
import { toMoment } from 'utils/normalizers';
import {
  COLORS,
  FlexRow,
  Text,
  LabelValue,
  // Button,
} from 'components';
import styled from 'styled-components';
import { getDiasNoLaborales, getPlantillasDeTramites } from 'api/tramites';
import address from 'utils/address';
import { Requisitos, fetchCatalogosRequisitos, groupsByCategories } from 'views/Tramites/GPM/components/RequisitosDelTramite';
import { getEnumValues, padronesEnum } from 'views/Tramites/GPM/enums';
import { DEV, REPUVE } from 'utils/env';
import Mapa from 'components/Mapa';
import useQuery from 'hooks/useQuery';
// import useCountdown from 'hooks/useCountDown';
import { getCitas } from 'api/tramites/citas';
import { stepsEnum } from 'views/Appointments/enums';
import { useSelector } from 'react-redux';
import { selectConfiguracionEntidad } from 'store/auth';
import { getNowMoment } from 'api/configuracion';

const availabilityColors = {
  DISPONIBLE: `${COLORS.success}80`,
  POCA_DISPONIBILIDAD: `${COLORS.warn}80`,
  NO_DISPONIBLE: `${COLORS.danger}80`,
  NO_APLICA: `${COLORS.gray}0d`,
  CERRADO: `${COLORS.cream}80`,
};

const getLabel = (label, number) => (
  <strong>
    <Badge count={number} style={{ marginRight: 5 }} />
    {label}
  </strong>
);

// eslint-disable-next-line react/prop-types
// const Countdown = ({ callback }) => {
//   const screens = Grid.useBreakpoint();
//   const [minutes, seconds] = useCountdown(300, callback);

//   return (
//     <>
//       <Col
//         style={screens.md ? {
//           position: 'absolute',
//           top: 20,
//           right: 20,
//           zIndex: 9,
//         } : {
//           position: 'sticky',
//           top: 0,
//           backgroundColor: '#FAFAFA',
//           zIndex: 9,
//           width: 140,
//           marginLeft: 'auto',
//         }}
//       >
//         <Button
//           $interactive={false}
//           type="primary"
//           color={COLORS.white}
//           style={{ borderRadius: 8 }}
//         >
//           <Text>
//             {`${minutes.toString().padStart(2, '0')} : ${seconds.toString().padStart(2, '0')}`}
//           </Text>
//           <br />
//           <Text $xs>
//             Minutos
//             {' '}
//             {' '}
//             Segundos
//           </Text>
//         </Button>
//       </Col>
//       {!screens.md && (<Col span={24} />)}
//     </>
//   );
// };

const headerRender = ({ onChange, value }, fromDate, disabled, updateCalendar, toDate) => {
  const options = Array(toDate ? toDate.diff(fromDate, 'months') + 1 : 1).fill(0).map((_, i) => {
    const d = fromDate.clone().add(i, 'months');
    return {
      value: d.format('[xx]/MM/YYYY'),
      label: `${d._locale._months[d.month()]} ${d.format('YYYY')}`,
      className: 'month-item',
    };
  });

  return (
    <div style={{ padding: 8 }}>
      <Row gutter={8} justify="space-between">
        <Col>
          <Form.Item
            label={getLabel('Seleccione la fecha para su cita', 3)}
            style={{ height: 0 }}
          />
          <Select
            size="small"
            allowClear={false}
            showSearch={false}
            dropdownMatchSelectWidth={false}
            value={value?.format?.('[xx]/MM/YYYY') || options[0].value}
            onChange={(val) => {
              const targetDate = toMoment(val.replace('xx', '01'), ['DD/MM/YYYY']);
              onChange(targetDate);
              updateCalendar(targetDate);
            }}
            options={options}
            getValue={(e) => e.value}
            getLabel={(e) => e.label}
            style={{ width: 180 }}
            disabled={disabled || options.length === 1}
          />
        </Col>
      </Row>
    </div>
  );
};

const unidadDePlazoEnum = Object.freeze({
  MINUTOS: 2,
  HORAS: 3,
  DIAS: 4,
  SEMANAS: 5,
  MESES: 6,
});

const unidadPlazoToMomentUnit = Object.freeze({
  [unidadDePlazoEnum.MINUTOS]: 'minutes',
  [unidadDePlazoEnum.HORAS]: 'hours',
  [unidadDePlazoEnum.DIAS]: 'day',
  [unidadDePlazoEnum.SEMANAS]: 'week',
  [unidadDePlazoEnum.MESES]: 'month',
});

const getAttentionMinutes = (oficina, plantilla) => {
  const unitToMinutes = {
    [unidadDePlazoEnum.MINUTOS]: (m) => m,
    [unidadDePlazoEnum.HORAS]: (m) => m * 60,
  };
  const unidadDePlazo = plantilla.horario_unidad_de_plazo || oficina.horario_unidad_de_plazo;
  const cantidad = plantilla.horario_cantidad || oficina.horario_cantidad;
  return unitToMinutes[unidadDePlazo](cantidad);
};

const getNumOfSpaces = (oficina, horario, date, plantilla) => {
  const attentionMinutes = getAttentionMinutes(oficina, plantilla);
  const entrada = toMoment(`${date.format('DD/MM/YYYY')} ${horario.entrada}`, ['DD/MM/YYYY hh:mm:ss']);
  const salida = toMoment(`${date.format('DD/MM/YYYY')} ${horario.salida}`, ['DD/MM/YYYY hh:mm:ss']);
  const minutesDiff = salida.diff(entrada, 'minutes');
  const numOfSpaces = parseInt(minutesDiff / attentionMinutes, 10);
  return { entrada, numOfSpaces };
};

const dateFullCellRender = (date, today, fecha, citasConfig, oficina, plantilla) => {
  // ocultar fechas de otros meses
  if (date.get('month') !== fecha?.get('month')) return null;

  const config = citasConfig[date.format('DD/MM/YYYY')];

  if (config && (!config.isOpen || !config.isLaboral)) {
    return (
      <FlexRow style={{ background: availabilityColors.CERRADO }}>
        <Text $xs color="rgba(0, 0, 0, 0.5)">
          <Tooltip title={config.noLaboralReason || 'Oficina cerrada'}>
            {date.get('date')}
          </Tooltip>
        </Text>
      </FlexRow>
    );
  }

  // formato 'disabled'
  if (!oficina || !config || config.outOfRange) {
    return (
      <FlexRow style={{ background: availabilityColors.NO_APLICA }}>
        <Text $xs color="rgba(0, 0, 0, 0.5)">
          {date.get('date')}
        </Text>
      </FlexRow>
    );
  }

  const availableQty = config.spaces.reduce((a, c) => a + c.ventanillas - c.agendadas, 0);
  // fechas sin disponibilidad
  if (!availableQty) {
    return (
      <FlexRow style={{ background: availabilityColors.NO_DISPONIBLE }}>
        <Text $xs color="rgba(0, 0, 0, 0.5)">
          {date.get('date')}
        </Text>
      </FlexRow>
    );
  }

  const totalCount = oficina
    .horarios.reduce((a, c) => a + getNumOfSpaces(oficina, c, date, plantilla).numOfSpaces, 0);
  const availablePercent = parseInt((100 / totalCount) * availableQty, 10);

  // fechas con disponibilidad
  return (
    <FlexRow style={{ background: availabilityColors[availablePercent > 30 ? 'DISPONIBLE' : 'POCA_DISPONIBILIDAD'] }}>
      <Text $xs strong color="rgba(0, 0, 0, 0.5)">
        {date.get('date')}
        {/* {DEV && (
          <Text
            $xxs
            color="rgba(0, 0, 0, 0.5)"
            style={{
              position: 'absolute',
              right: 6,
              bottom: 0,
            }}
          >
            {availablePercent}
            %
          </Text>
        )} */}
      </Text>
    </FlexRow>
  );
};

const DatosCita = ({
  visible,
  plantilla: plantillaId,
  setLoading,
  setCurrentStep,
  currentStep,
}) => {
  const form = Form.useFormInstance();
  const _plantilla = Form.useWatch('plantilla', form);
  const _oficina = Form.useWatch('oficina', form);
  const fecha = Form.useWatch('fecha', form);
  const [plantillaIdParam] = useQuery(['plantilla']);
  const confEntidad = useSelector(selectConfiguracionEntidad);

  // requisitos
  const [tiposDeRequisitos, setTiposDeRequisitos] = useState([]);
  const [catalogosDeRequisitos, setCatalogosDeRequisitos] = useState([]);
  const [categoriasDeRequisitos, setCategoriasDeRequisitos] = useState([]);
  const [unidadesDePlazo, setUnidadesDePlazo] = useState([]);
  const [requisitos, setRequisitos] = useState([]);
  const [citasDisponibles, setCitasDisponibles] = useState({});
  const [diasNoLaborales, setDiasNoLaborales] = useState([]);
  // const [showCountdown, setShowCountdown] = useState(false);
  const [invalidDate, setInvalidDate] = useState(false);
  const [pointCoords, setPointCoords] = useState(false);
  const now = toMoment(new Date());
  // const [now, setNow] = useState(toMoment(new Date()));
  // const [fromDate, setFromDate] = useState(toMoment(new Date()));
  // const [toDate, setToDate] = useState();

  const [plantillas, setPlantillas] = useState({ results: [] });
  const plantilla = plantillas.results.find((e) => e.id === _plantilla);

  const oficinas = useMemo(() => {
    if (!plantilla) return [];
    const _oficinas = plantilla.oficina_de_atencion
      .filter((o) => o.ventanilla?.some((v) => v.plantillas_de_tramites.includes(_plantilla)));
    return _oficinas.map(({ ventanilla: ventanillas = [], ...o }) => ({ ...o, ventanillas }));
  }, [plantilla]);

  const oficina = oficinas.find((e) => e.id === _oficina);
  const diasNoLaboralesOficina = diasNoLaborales
    .filter((e) => e.aplica_todas || e.oficina === _oficina);
  const weekdayToCatalog = (d) => d.day() || 7;

  const getLastLaboralDay = (_from, _to) => {
    const date = _to.clone();
    let isLaboralDay = false;
    while (!isLaboralDay && !date.isSame(_from.clone(), 'd')) {
      isLaboralDay = !diasNoLaboralesOficina.some((e) => e.fecha.isSame(date, 'd')) && oficina.horarios
        .some((h) => h.dias_laborales.some((ld) => ld.id === weekdayToCatalog(date)));
      if (isLaboralDay) {
        return date;
      }
      date.add(-1, 'd');
    }
    return null;
  };

  // const onLaboralSchedule = (moment) => oficina.horarios.some((h) => {
  //   const entradaHMS = h.entrada.split(':').map((e) => parseInt(e, 10));
  //   const entrada = moment.clone().month(moment.month()).day(moment.day());
  //   entrada.hour(entradaHMS[0]).minutes(entradaHMS[1]).seconds(entradaHMS[2]).milliseconds(0);

  //   const salidaHMS = h.salida.split(':').map((e) => parseInt(e, 10));
  //   const salida = moment.clone().month(moment.month()).day(moment.day());
  //   salida.hour(salidaHMS[0]).minutes(salidaHMS[1]).seconds(salidaHMS[2]).milliseconds(0);

  //   return moment.isBetween(entrada, salida, '[)');
  // });

  const getFromDateToDate = () => {
    if (!_oficina) return [now];
    const {
      cantidad_agenda_cita_desde = 1,
      unidad_agenda_cita_desde = unidadDePlazoEnum.DIAS,
      cantidad_agenda_cita_hasta = 0,
      unidad_agenda_cita_hasta = unidadDePlazoEnum.MESES,
    } = confEntidad;

    const momentUnitDesde = unidadPlazoToMomentUnit[unidad_agenda_cita_desde];
    const _fromDate = now.clone().add(cantidad_agenda_cita_desde, momentUnitDesde);
    if (!_fromDate.isSame(now, 'd')) {
      const [h, m, s] = oficina.horarios[0].entrada.split(':').map((e) => parseInt(e, 10));
      _fromDate.hour(h).minutes(m).seconds(s).milliseconds(0);
    }

    const momentUnitHasta = unidadPlazoToMomentUnit[unidad_agenda_cita_hasta];
    const _toDate = now.clone();
    if (unidad_agenda_cita_hasta > 4) _toDate.endOf(momentUnitHasta);
    _toDate.add(cantidad_agenda_cita_hasta, momentUnitHasta);
    const lastLaboralDay = getLastLaboralDay(_fromDate, _toDate);
    if (!lastLaboralDay || lastLaboralDay.isSame(_fromDate.clone(), 'd')) {
      _toDate.add(1, momentUnitHasta);
    }
    return [_fromDate, _toDate];
  };

  const [fromDate, toDate] = useMemo(getFromDateToDate, [_oficina]);

  const resetFields = (field) => {
    const fields = ['plantilla', 'oficina', 'horario'];
    const idx = fields.indexOf(field) + 1;
    const fieldsToClear = fields.splice(idx);
    if (fieldsToClear.some((f) => form.getFieldValue(f))) {
      form.resetFields(fieldsToClear);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!plantilla) {
        setRequisitos([]);
        setTiposDeRequisitos([]);
        setCategoriasDeRequisitos([]);
        setCatalogosDeRequisitos([]);
        setUnidadesDePlazo([]);
        return;
      }
      setPointCoords(false);
      setLoading(true);
      if (!plantillaId) {
        const _allRequisitos = plantilla.requisitos_generales.map((r) => r.id).concat(plantilla
          .casos_de_los_requisitos.map((c) => c.requisitos.map((r) => r.id))).flat();
        const [
          _requisitos,
          _tiposDeRequisitos,
          _categoriasDeRequisitos,
          _unidadesDePlazo,
          _catalogosDeRequisitos,
        ] = await fetchCatalogosRequisitos(_allRequisitos);
        setRequisitos(_requisitos);
        setTiposDeRequisitos(_tiposDeRequisitos);
        setCategoriasDeRequisitos(_categoriasDeRequisitos);
        setCatalogosDeRequisitos(_catalogosDeRequisitos);
        setUnidadesDePlazo(_unidadesDePlazo);
      }
      setLoading(false);
      form.setFieldValue('idAcuse', plantilla.reporte_cita);
    };
    fetchData();
  }, [_plantilla, plantillas]);

  const updateCalendar = async (_fecha) => {
    setLoading(true);
    const rango_de_fechas = [
      _fecha.isSame(fromDate, 'month') ? fromDate.clone().startOf('day') : _fecha.clone().startOf('month'),
      toDate.isBefore(_fecha.clone().endOf('month')) ? toDate.clone() : _fecha.clone().endOf('month'),
    ];
    const _citas = await getCitas({
      oficina: _oficina,
      fecha_cita_after: rango_de_fechas[0].format('YYYY-MM-DD'),
      fecha_cita_before: rango_de_fechas[1].format('YYYY-MM-DD'),
      estado_de_cita: 1,
    });

    const attentionMinutes = getAttentionMinutes(oficina, plantilla);
    if (!attentionMinutes) {
      setLoading(false);
      setCitasDisponibles({});
      return;
    }
    const schedule = {};
    const date = _fecha.clone().startOf('month');
    const citasBetween = (_start) => _citas
      .filter((c) => _start.isBetween(c.horario_inicio, c.horario_fin, undefined, '[)')).length;

    while (date.isBefore(_fecha.clone().endOf('month'))) {
      const isOpen = oficina.horarios
        .some((h) => h.dias_laborales.some((ld) => ld.id === weekdayToCatalog(date)));
      const noLaboral = diasNoLaboralesOficina.find((e) => e.fecha.isSame(date));
      const outOfRange = !date.clone().isBetween(fromDate.clone(), rango_de_fechas[1].clone(), 'day', '[]');
      schedule[date.format('DD/MM/YYYY')] = {
        isOpen,
        isLaboral: !noLaboral,
        noLaboralReason: noLaboral?.descripcion,
        outOfRange,
        // eslint-disable-next-line no-loop-func
        spaces: !isOpen || noLaboral || !!outOfRange ? [] : oficina.horarios.map((horario) => {
          const { entrada, numOfSpaces } = getNumOfSpaces(oficina, horario, date, plantilla);
          const spaces = (new Array(numOfSpaces)).fill(null).map((_, i) => {
            const start = toMoment(entrada.clone().add(attentionMinutes * i, 'm'));
            const end = toMoment(entrada.clone().add(attentionMinutes * (i + 1), 'm'));
            return {
              valid: start.isSameOrAfter(fromDate),
              start,
              end,
              agendadas: citasBetween(start),
              ventanillas: oficina.ventanillas.length,
              d: start.clone(),
              label: `${start.format('hh:mm')} - ${end.format('hh:mm a')}`,
              value: start.format('L LTS').concat(`-${end.format('LTS')}`),
            };
          });
          return spaces;
        }).flat().filter((e) => e.valid && e.agendadas < e.ventanillas),
      };
      // cambiamos fecha al día siguiente
      date.add(1, 'd');
    }
    const _horario = form.getFieldValue('horario');
    if (!schedule[fecha?.format('DD/MM/YYYY')]?.spaces.some((e) => e.value === _horario)) {
      const firstDayWSpaces = Object.keys(schedule).find((k) => schedule[k].spaces.length);
      form.setFieldsValue({
        fecha: schedule[firstDayWSpaces]?.spaces[0].start || rango_de_fechas[0],
      });
    } else if (_horario) {
      setTimeout(() => form.setFieldsValue({ fecha: toMoment(_horario.split(' ')[0]) }), 1000);
    } else {
      form.setFieldsValue({ fecha: fromDate });
    }
    if (currentStep && currentStep !== stepsEnum.DATOS_CITA) {
      setCurrentStep(stepsEnum.DATOS_CITA);
    }
    setLoading(false);
    // setShowCountdown(false);
    setCitasDisponibles(schedule);
  };

  const isValidDate = async () => {
    if (DEV) return true;
    const nowConfig = await getNowMoment();
    if (nowConfig?.moment.isBetween(now.clone(), now.clone().add(30, 'seconds'))) return true;
    // TODO: revisar porque a Karina le falla,
    // posiblemente hacer endpoint en el backend para solventar
    // setLoading('invalid_date');
    // return false;
    return true;
  };

  useEffect(() => {
    // setShowCountdown(false);
    const _fetchCitas = async () => {
      if (oficina) {
        setPointCoords(true);
        setTimeout(() => updateCalendar(now));
      }
    };
    _fetchCitas();
  }, [_oficina]);

  useEffect(() => {
    form.setFieldsValue({
      fecha: (DEV && form.getFieldValue('fecha')) || fromDate,
      plantilla: plantillaId || parseInt(plantillaIdParam, 10) || (DEV && form.getFieldValue('plantilla')) || undefined,
    });
    const fetch = async () => {
      const _diasNoLaborales = await getDiasNoLaborales();
      setDiasNoLaborales(_diasNoLaborales);
      const validDate = await isValidDate();
      setInvalidDate(!validDate);
    };
    fetch();
  }, []);

  if (invalidDate) {
    throw new Error(JSON.stringify({
      title: 'Revise la fecha y hora de su dispositivo',
      subTitle: 'Una vez corregida inténtelo de nuevo',
      status: 'warning',
    }));
  }

  // const onTimeout = () => {
  //   // setShowCountdown(false);
  //   Modal.destroyAll();
  //   Modal.info({
  //     title: '',
  //     content: 'Se ha alcanzado el límite de tiempo para
  // la selección de la fecha y horario, intentelo de nuevo.',
  //     // afterClose: () => updateCalendar(fecha),
  //     afterClose: () => {
  //       setCitasDisponibles({});
  //       form.resetFields(['oficina', 'horario']);
  //     },
  //   });
  // };

  const horariosDisponibles = citasDisponibles[fecha?.format('DD/MM/YYYY')]?.spaces || [];
  const rules = [{ required: true, message: '' }];

  const getCoords = (o) => (o?.direccion?.longitud && o?.direccion?.latitud
    ? [o.direccion.longitud, o.direccion.latitud, o.direccion] : []);
  const coordsToObject = ([longitud, latitud, direccion]) => ({ longitud, latitud, direccion });

  const coords = getCoords(oficina);
  const _coordsList = oficinas.map((o) => getCoords(o)).filter((e) => e.length);
  const coordsList = _coordsList.map(coordsToObject);
  const mapKey = coords.join().concat(_coordsList.flat().join());

  return (
    <>
      {/* {showCountdown && (<Countdown callback={onTimeout} />)} */}
      <Container span={visible ? 24 : 0}>
        <Row gutter={[10, 10]}>
          <Col xs={24} sm={24} md={12}>
            <Row gutter={[10, 20]}>
              <Col span={24}>
                <Form.Item
                  label={getLabel('Seleccione el trámite o servicio', 1)}
                  name="plantilla"
                  style={{ margin: 0 }}
                  rules={rules}
                >
                  <PaginatedSelect
                    disabled={!!plantillaId || !!plantillaIdParam}
                    allowClear={false}
                    onChange={() => resetFields('plantilla')}
                    labelProp="nombre"
                    dataSource={plantillas}
                    pagination={{
                      getter: getPlantillasDeTramites,
                      setter: (dataSource) => {
                        setPlantillas(dataSource);
                      },
                      config: {
                        params: {
                          id: plantillaId || parseInt(plantillaIdParam, 10) || undefined,
                          estado_de_ficha: 3,
                          requiere_cita: true,
                        },
                        auto: true,
                        onlyOnePage: false,
                        useLoadingState: true,
                        qName: 'nombre',
                      },
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={getLabel('Seleccione oficina de atención', 2)}
                  name="oficina"
                  style={{ margin: 0 }}
                  rules={rules}
                >
                  <Select
                    disabled={!plantilla}
                    allowClear={false}
                    options={oficinas}
                    onChange={() => resetFields('oficina')}
                  />
                </Form.Item>
                {!!_plantilla && (
                  <>
                    <br />
                    {oficina && (
                      <>
                        <Text strong>
                          Información del módulo
                        </Text>
                        <StyledLabelValue
                          label="Dirección:"
                          value={address(oficina?.direccion)}
                          break={false}
                        />
                        <StyledLabelValue
                          label="Teléfono:"
                          value={oficina?.numero_de_telefono}
                          break={false}
                        />
                      </>
                    )}
                    <StyledLabelValue
                      label="Ubicación:"
                      value={(
                        <React.Fragment key={mapKey}>
                          <Mapa
                            interactive={false}
                            coords={pointCoords ? coords : []}
                            coordsList={pointCoords ? [] : coordsList}
                            height={plantillaId ? 240 : 120}
                          />
                        </React.Fragment>
                      )}
                    />
                    {!!plantilla?.requisitos_generales.length && !plantillaId && (
                      <>
                        <br />
                        <Text strong>
                          Requisitos generales
                        </Text>
                        <RequisitosContainer>
                          <Requisitos
                            categorias={groupsByCategories(
                              categoriasDeRequisitos,
                              catalogosDeRequisitos,
                              plantilla?.requisitos_generales
                                .map((r) => requisitos.find((_r) => _r.id === r.id)),
                              tiposDeRequisitos,
                              [],
                              [],
                              REPUVE ? padronesEnum.CONTRIBUYENTE : padronesEnum.CIUDADANO,
                              plantilla?.tipo_de_padron,
                            )}
                            renderInfoRequisito
                            interactive={false}
                            unidadesDePlazo={unidadesDePlazo}
                          />
                        </RequisitosContainer>
                      </>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Row gutter={[10, 20]}>
              <Col span={24}>
                <Form.Item name="fecha" rules={rules} noStyle>
                  <Calendar
                    disabledDate={(d) => !oficina
                      || !d.isBetween(fromDate.clone().startOf('month'), fromDate.clone().endOf('month'))}
                    mode="month"
                    fullscreen={false}
                    onChange={(_fecha) => {
                      form.setFieldValue('fecha', _fecha);
                      form.resetFields(['horario']);
                    }}
                    dateFullCellRender={(d) => dateFullCellRender(
                      d,
                      fromDate,
                      fecha,
                      citasDisponibles,
                      oficina,
                      plantilla,
                    )}
                    headerRender={(args) => headerRender(
                      args,
                      fromDate,
                      !oficina, // !showCountdown
                      updateCalendar,
                      toDate,
                    )}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  label={getLabel('Seleccione horario', 4)}
                  name="horario"
                  rules={rules}
                >
                  <Select
                    disabled={!_oficina || !horariosDisponibles.length}
                    allowClear={false}
                    options={horariosDisponibles}
                    getValue={(e) => e.value}
                    getLabel={(e) => e.label}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Row>
                  <Col span={24}>
                    <AvailabilityText bg={availabilityColors.DISPONIBLE}>
                      Alta disponibilidad
                    </AvailabilityText>
                  </Col>
                  <Col span={24}>
                    <AvailabilityText bg={availabilityColors.POCA_DISPONIBILIDAD}>
                      Poca disponibilidad
                    </AvailabilityText>
                  </Col>
                  <Col span={24}>
                    <AvailabilityText bg={availabilityColors.NO_DISPONIBLE}>
                      No disponible
                    </AvailabilityText>
                  </Col>
                  <Col span={24}>
                    <AvailabilityText $bordered bg={availabilityColors.NO_APLICA}>
                      No aplica
                      {' '}
                      <Tooltip title="Días anteriores o fuera del rango para agendar">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </AvailabilityText>
                  </Col>
                  <Col span={24}>
                    <AvailabilityText $bordered bg={availabilityColors.CERRADO}>
                      Cerrado
                      {' '}
                      <Tooltip title="Por horario o día festivo">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </AvailabilityText>
                  </Col>
                  <Col span={24}>
                    <AvailabilityText
                      today={now.date()}
                      $bordered
                      bg={availabilityColors.NO_APLICA}
                    >
                      Hoy
                    </AvailabilityText>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Form.Item name="idAcuse" hidden>
          <Input />
        </Form.Item>
      </Container>
    </>

  );
};

const AvailabilityText = styled(Text)`
  float: right;
  margin-bottom: 5px;
  width: 100%;
  text-align: right;
  white-space: nowrap;
  :after {
    content: '';
    display: inline-block;
    width: 25px;
    height: 25px;
    background: ${(props) => props.bg};
    margin-left: 5px;
    margin-bottom: -5px;
    border-radius: 4px;
    ${(props) => props.$bordered && `border: 1px solid ${COLORS.gray}40;`}
  }
  ${(props) => !!props.today && `
    :after {
      content: '${props.today}';
      border-radius: 50%;
      background: ${COLORS.accent};
      color: ${COLORS.white};
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      line-height: 22px;
    }
  `}
`;

const Container = styled(Col)`
  ${FlexRow} {
    height: 50px;
    margin-inline: 5px;
    padding: 5px;
  }
  .ant-picker-cell.ant-picker-cell-in-view {
    padding: 0;
  }

  table {
    border-spacing: 0;
  }
  .ant-picker-cell{
    :before {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-left: calc(50% - 20px);
    }
    div span {
      color: ${COLORS.accent};
      font-weight: bold;
    }
  }

  .ant-picker-cell-selected {
    :before {
      border: 1px solid ${COLORS.gray};
    }
  }

  .ant-picker-cell-today {
    :before {
      background-color: ${COLORS.accent} !important;
      z-index: 1;
    }
    div span {
      color: ${COLORS.white};
      position: relative;
      z-index: 2;
    }
  }

  .ant-form-item-required {
    :before {
      content: none !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .ant-picker-cell:before {
      width: 30px;
      height: 30px;
      margin-left: 8px;
    }
  }
`;

const RequisitosContainer = styled.div`
  .ant-collapse-header {
    padding: 6px 8px !important;
  }
  .ant-col-24 {
    margin-bottom: 0 !important;
  }
  h4 {
    font-size: 14px !important;
  }
  span.ant-typography {
    font-size: 12px !important;
  }
  .ant-collapse-content-box {
    padding: 6px 8px;
  }
`;

const StyledLabelValue = styled(LabelValue).attrs((attrs) => ({
  ...attrs,
  md: 24,
  strong: 'label',
  xs: true,
}))`margin-bottom: 5px;`;

DatosCita.propTypes = {
  plantilla: PropTypes.number,
  visible: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func,
  currentStep: PropTypes.oneOf([...getEnumValues(stepsEnum), null]),
};

DatosCita.defaultProps = {
  plantilla: null,
  visible: true,
  setCurrentStep: null,
  currentStep: null,
};

export default DatosCita;
