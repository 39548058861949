import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import round from 'utils/round';
import { DEV, PROD } from 'utils/env';
import { debtsColumns } from 'utils/debtsFormatter';
import styled from 'styled-components';
import { COLORS, Text } from 'components';
import { toCurrency } from 'utils/formatters';
import sortCargos from 'utils/sorterCargos';

const roundToTwo = (num) => +(`${Math.round(`${num}e+2`)}e-2`);

export const getTotalPadron = (cargos, selectedRowKeys) => {
  const selected = cargos.filter((cargo) => selectedRowKeys.includes(cargo.id));
  const reduced = selected.reduce((accum, curr) => accum + curr.adeudo_total, 0);
  return round(roundToTwo((reduced * 100) / 100));
};

const getSummary = (cargos) => !PROD && (
  <>
    <Table.Summary.Row>
      <Table.Summary.Cell index={0} colSpan={DEV ? 4 : 2}>
        <Text $accent $bold>
          Subtotales
        </Text>
      </Table.Summary.Cell>
      {debtsColumns.filter((c, idx) => idx > 1).map((col, idx) => (
        <Table.Summary.Cell index={idx + 1} align="right">
          <Text $accent $bold>
            {toCurrency(cargos.reduce((acum, curr) => acum + curr[col.key], 0))}
          </Text>
        </Table.Summary.Cell>
      ))}
    </Table.Summary.Row>
    <Table.Summary.Row>
      <Table.Summary.Cell index={0} colSpan={DEV ? 4 : 2}>
        <Text $accent $bold>
          Total
        </Text>
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1} align="right">
        <Text $accent $bold>
          {toCurrency(getTotalPadron(cargos, cargos.map((c) => c.id))[0])}
        </Text>
      </Table.Summary.Cell>
    </Table.Summary.Row>
  </>
);

const TableAdeudoPadron = ({ cargos }) => {
  const columns = [
    DEV && {
      title: 'ID (DEV)',
      dataIndex: 'id',
      key: 'id',
      width: 120,
    },
    DEV && {
      title: 'Tipo de Cargo (DEV)',
      dataIndex: 'tipo_de_cargo',
      key: 'tipo_de_cargo',
      width: 180,
      render: (val) => val?.clave || val?.id || val,
    },
    ...debtsColumns.map((c) => {
      if (c.key === 'descripcion') {
        return { ...c, width: 400 };
      }
      return c;
    }),
  ].filter((e) => e);

  return (
    <StyledTable
      dataSource={sortCargos(cargos)}
      columns={columns}
      pagination={{ pageSize: Infinity, showSizeChanger: false }}
      scroll={{
        y: 500,
        x: columns.reduce((prev, next) => prev + next.width, 0),
        scrollToFirstRowOnChange: true,
      }}
      rowClassName={({ adeudo_total, es_accesorio }) => {
        let className = '';
        if (Number.isNaN(adeudo_total)) {
          className += 'warn-row ';
        }
        if (!es_accesorio) {
          className += 'bold';
        }
        return className;
      }}
      locale={{
        emptyText: 'Sin adeudo',
      }}
      rowKey="id"
      size="small"
      summary={getSummary}
      bordered
      sticky
    />
  );
};

TableAdeudoPadron.propTypes = {
  cargos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const StyledTable = styled(Table)`
  .ant-table-header th {
    background: ${COLORS.accent};
    color: ${COLORS.white};
    font-weight: bold;
  }
`;

export default TableAdeudoPadron;
