import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Form,
  Input,
  Row,
} from 'antd';
import { HideToPreserve } from 'components/Steps';
import { Text } from 'components';

const ServidorPublico = ({ visible }) => (
  <HideToPreserve visible={visible}>
    <Row gutter={[10, 20]}>
      <Col span={24}>
        <Text>
          Si desea quejarse o denunciar a un servidor público, por favor proporcione la
          siguiente información.
        </Text>
      </Col>
      <Col xs={24} sm={24} md={12}>
        <Form.Item
          label="Nombre"
          name="nombre_de_servidor_publico"
        >
          <Input />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={12}>
        <Form.Item
          label="Características físicas"
          name="descripcion_fisica_del_servidor"
        >
          <Input />
        </Form.Item>
      </Col>
      <Text>
        Indicar nombre de los testigos presenciales, si los hubiere.
      </Text>
    </Row>

  </HideToPreserve>
);

ServidorPublico.propTypes = {
  visible: PropTypes.bool,
};

ServidorPublico.defaultProps = {
  visible: false,
};

export default ServidorPublico;
