import React from 'react';
import propTypes from 'prop-types';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Col,
  Form,
  Input,
  Row,
  Tooltip,
} from 'antd';
import Select from 'components/Select';
import { toInteger } from 'utils/formatters';
import { Button } from 'components';

const rules = [{ required: true }];

const FormItemsDireccion = ({
  colonias,
  isEditing,
  onSearchCodigoPostal,
  vialidades,
  onClickCopy,
}) => {
  const form = Form.useFormInstance();

  return (
    <Col span={24}>
      <Row gutter={10} align="middle">
        <Col xs={24} sm={24} md={8}>
          <Form.Item
            label={(
              <>
                <span style={{ marginRight: 5 }}>
                  Código Postal
                </span>
                <Tooltip
                  title="Ingresar este campo llenara automáticamente el municipio y las colonias disponibles"
                >
                  <QuestionCircleOutlined className="primary-on-hover" />
                </Tooltip>
              </>
            )}
            name="codigo_postal"
            normalize={toInteger}
          >
            <Input.Search
              onPressEnter={(e) => {
                if (typeof onSearchCodigoPostal === 'function') {
                  e.preventDefault();
                  onSearchCodigoPostal(form.getFieldValue('codigo_postal'), true);
                }
              }}
              disabled={!isEditing}
              maxLength={6}
              onSearch={(d_codigo) => typeof onSearchCodigoPostal === 'function'
              && onSearchCodigoPostal(d_codigo, true)}
            />
          </Form.Item>
        </Col>
        {!!onClickCopy && (
        <Col xs={24} sm={24} md={8}>
          <Form.Item label=" ">
            <Button onClick={onClickCopy}>
              Copiar dirección
            </Button>
          </Form.Item>
        </Col>
        )}
      </Row>
      <Row gutter={10}>
        <Form.Item
          name="id"
          hidden
        >
          <Input disabled />
        </Form.Item>
        <Col xs={24} sm={24} md={6}>
          <Form.Item
            name="pais"
            label="País"
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item
            name="estado"
            label="Estado"
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item
            name="municipio"
            label="Municipio"
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item
            name="colonia"
            label="Colonia"
            rules={rules}
          >
            <Select
              options={colonias}
              getLabel={(e) => e.d_asenta}
              disabled={!isEditing}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item noStyle>
            <Input.Group style={{ display: 'flex' }} className="select-input" compact>
              <Form.Item
                name="tipo_de_vialidad"
                label="Tipo"
                rules={rules}
              >
                <Select
                  style={{ width: 150 }}
                  options={vialidades}
                  disabled={!isEditing}
                />
              </Form.Item>
              <Form.Item
                name="calle_principal"
                label="Vialidad"
                style={{ width: 'calc(100% - 150px)' }}
                rules={rules}
              >
                <Input maxLength={170} allowClear disabled={!isEditing} />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item
            name="numero_exterior"
            label="Número Exterior"
            rules={rules}
          >
            <Input maxLength={20} allowClear disabled={!isEditing} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item
            name="numero_interior"
            label="Número Interior"
          >
            <Input maxLength={20} allowClear disabled={!isEditing} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <Form.Item noStyle>
            <Input.Group style={{ display: 'flex' }} className="select-input" compact>
              <Form.Item
                name="tipo_de_vialidad_entre"
                label="Tipo"
              >
                <Select
                  style={{ width: 150 }}
                  options={vialidades}
                  disabled={!isEditing}
                />
              </Form.Item>
              <Form.Item
                name="entre_calle"
                label="Entre Vialidad"
                style={{ width: 'calc(100% - 150px)' }}
              >
                <Input maxLength={170} allowClear disabled={!isEditing} />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Form.Item noStyle>
            <Input.Group style={{ display: 'flex' }} className="select-input" compact>
              <Form.Item
                name="tipo_de_vialidad_y_calle"
                label="Tipo"
              >
                <Select
                  style={{ width: 150 }}
                  options={vialidades}
                  disabled={!isEditing}
                />
              </Form.Item>
              <Form.Item
                name="y_calle"
                label="Y Vialidad"
                style={{ width: 'calc(100% - 150px)' }}
              >
                <Input maxLength={170} allowClear disabled={!isEditing} />
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="descripcion_de_la_ubicacion"
            label="Descripción de la Ubicación"
            className="with-textarea"
          >
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 3 }}
              maxLength={250}
              allowClear
              disabled={!isEditing}
            />
          </Form.Item>
        </Col>
      </Row>
    </Col>
  );
};

FormItemsDireccion.propTypes = {
  isEditing: propTypes.bool.isRequired,
  onSearchCodigoPostal: propTypes.func,
  colonias: propTypes.arrayOf(propTypes.shape({
    d_asenta: propTypes.string,
    d_codigo: propTypes.string,
  })),
  vialidades: propTypes.arrayOf(propTypes.shape({
    value: propTypes.string,
    label: propTypes.string,
  })),
  onClickCopy: propTypes.func,
};

FormItemsDireccion.defaultProps = {
  onSearchCodigoPostal: undefined,
  colonias: [],
  vialidades: [],
  onClickCopy: null,
};

export default FormItemsDireccion;
