import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Typography } from 'antd';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import MapboxWorker from 'mapbox-gl/dist/mapbox-gl-csp-worker';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { createAxiosInstance } from 'api/index';
import _logger from 'utils/logger';
import { ENV_NAME, ENV_NAMES_ENUM } from 'utils/env';
import { logo } from 'assets';

const baseURL = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';
const API = createAxiosInstance(baseURL);

mapboxgl.workerClass = MapboxWorker;

mapboxgl.accessToken = 'pk.eyJ1IjoiYWxmcmVkb2FyYWdvbiIsImEiOiJja25ob2Jjb3cwbGFkMndsd2dxa3ptbW1nIn0.RMjRPIOqD194HvOMxu8iqQ';

const countryCoords = {
  [ENV_NAMES_ENUM.NAYARIT]: [-104.89569, 21.50951],
  [ENV_NAMES_ENUM.JUAREZ]: [-106.442, 31.733],
  [ENV_NAMES_ENUM.HERMOSILLO]: [-110.941, 29.098],
  [ENV_NAMES_ENUM.ROATAN]: [-86.566, 16.333],
};

const Mapa = ({
  form,
  interactive,
  showAddress,
  currentStep,
  height,
  coords,
  coordsList,
  style,
}) => {
  const mapContainer = useRef();
  const [fullAddress, setFullAddress] = useState();
  const [map, setMap] = useState();

  const initialCoords = countryCoords[ENV_NAME] || [];

  const getFullAddress = async (lat, lon) => {
    try {
      const URL = `${baseURL}${lat},${lon}.json`;
      const res = await API.get(URL, {
        params: {
          access_token: mapboxgl.accessToken,
        },
      });
      setFullAddress(res.data?.features[0]?.place_name);
    } catch (err) {
      _logger(err);
    }
  };

  const resizeMap = () => {
    setTimeout(() => {
      if (map) {
        map.resize();
      }
    });
  };

  const getInnerHTML = (objDir) => `
    <div style="width: 230px; overflow: hidden; word-break: break-all;">
      <div style="width: 100%;">
        <img src="${logo}" style="height: 60px; height: 60px;aspect-ratio: 3/1;"></img>
      </div>
      <div style="width: 100%;">
        <strong>${objDir.title}</strong>
        <br />
        <span>${objDir.detalle}</span>
        <br />
        <span>${!['N/A', null].includes(objDir?.descripcion_de_la_ubicacion) ? objDir.descripcion_de_la_ubicacion : ''}</span>
      </div>
    </div>
  `;

  const findCenter = (markers) => {
    const lats = markers.map((m) => m.latitud);
    const lngs = markers.map((m) => m.longitud);
    return [
      (Math.min(...lngs) + Math.max(...lngs)) / 2,
      (Math.min(...lats) + Math.max(...lats)) / 2,
    ];
  };

  useEffect(() => {
    document.querySelectorAll('#map .mapboxgl-marker').forEach((m) => m.remove());
    const [_longitud, _latitud] = coords;
    let center = _longitud && _latitud ? [_longitud, _latitud] : null;
    if (!center && coordsList.length) {
      center = findCenter(coordsList);
    } else {
      center = initialCoords;
    }

    const _map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/light-v10',
      center,
      zoom: _longitud ? 10 : 7,
      minZoom: _longitud ? 10 : 7,
      interactive,
    });
    _map.on('load', () => {
      _map.resize();
      document.querySelectorAll('#map .mapboxgl-marker').forEach((m) => m.remove());
      if (coordsList.length) {
        coordsList.forEach((coord) => {
          const { latitud, longitud, direccion } = coord;
          const marker = new mapboxgl.Marker({ draggable: interactive, color: '#413c3e' })
            .setLngLat([longitud, latitud]);
          if (direccion) {
            const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(getInnerHTML(direccion));
            marker.setPopup(popup);
          }
          marker.addTo(_map);
        });
      } else {
        new mapboxgl.Marker({ draggable: interactive, color: '#413c3e' })
          .setLngLat(center)
          .addTo(_map);
      }
    });
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl,
    });
    geocoder.on('result', (e) => {
      const [lon, lat] = e.result.center;
      if (form) {
        form.setFieldsValue({ longitud: lon, latitud: lat });
      }
      document.querySelectorAll('#map .mapboxgl-marker').forEach((m) => m.remove());
      const mrkr = new mapboxgl.Marker({ draggable: interactive, color: 'yellow' })
        .setLngLat(e.result.center)
        .addTo(_map);
      mrkr.on('dragend', (evt) => {
        if (form) {
          form.setFieldsValue({
            longitud: evt.target._lngLat.lng,
            latitud: evt.target._lngLat.lat,
          });
        }
      });
    });
    if (interactive) {
      _map.addControl(geocoder);
    }
    if (showAddress) {
      getFullAddress(_longitud, _latitud);
    }
    setMap(_map);
    setTimeout(resizeMap);

    return () => _map.remove;
  }, []);

  useEffect(() => {
    if (map) {
      document.querySelectorAll('#map .mapboxgl-marker').forEach((m) => m.remove());
      if (coords.length) {
        const [longitud, latitud, direccion] = coords;
        const center = [longitud || initialCoords[0], latitud || initialCoords[1]];
        map.jumpTo({ center, zoom: 15 });
        const marker = new mapboxgl.Marker({ draggable: interactive, color: 'red' })
          .setLngLat(center);
        if (direccion) {
          const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(getInnerHTML(direccion));
          popup.remove();
          marker.setPopup(popup);
        }
        marker.addTo(map);
      } else if (coordsList.length) {
        const center = findCenter(coordsList);
        map.jumpTo({ center, zoom: 7 });
        coordsList.forEach(({ latitud, longitud, direccion }) => {
          const marker = new mapboxgl.Marker({ draggable: interactive, color: '#413c3e' })
            .setLngLat([longitud, latitud]);
          if (direccion) {
            const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(getInnerHTML(direccion));
            popup.remove();
            marker.setPopup(popup);
          }
          marker.addTo(map);
        });
      } else {
        const center = [initialCoords[0], initialCoords[1]];
        map.jumpTo({ center, zoom: 15 });
      }
    }
    const popups = document.querySelectorAll('#map .mapboxgl-popup-close-button');
    if (popups?.length && map) {
      popups.forEach((m) => m.click());
    }
  }, [coords, coordsList]);

  useEffect(resizeMap, [currentStep]);

  return (
    <div style={{ width: '100%', ...style }}>
      {(showAddress && fullAddress) && (
        <div>
          <br />
          <Typography.Text>
            {`Ubicación: ${fullAddress}`}
          </Typography.Text>
        </div>
      )}
      <Form.Item name="longitud" hidden><Input /></Form.Item>
      <Form.Item name="latitud" hidden><Input /></Form.Item>
      <div id="map" style={{ height }} ref={mapContainer} />
    </div>
  );
};

Mapa.propTypes = {
  form: PropTypes.shape({ setFieldsValue: PropTypes.func.isRequired }),
  interactive: PropTypes.bool,
  showAddress: PropTypes.bool,
  currentStep: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  coords: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({})])).isRequired,
  coordsList: PropTypes.arrayOf(PropTypes.shape({})),
  style: PropTypes.shape({}),
};

Mapa.defaultProps = {
  interactive: true,
  showAddress: false,
  currentStep: null,
  height: 350,
  coordsList: [],
  form: null,
  style: {},
};

export default Mapa;
