/* eslint-disable no-plusplus */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { loadModules } from 'esri-loader';
import { createAxiosInstance } from 'api/index';
import _logger from 'utils/logger';
import styled from 'styled-components';
import {
  Col,
  Row,
  Space,
  Result,
  Grid,
  Form,
  Input,
} from 'antd';
import { CameraOutlined, LinkOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  Button,
  COLORS,
  Text,
  Title,
} from 'components';
import { ENV_NAME, ENV_NAMES_ENUM } from 'utils/env';

const baseURL = 'https://services7.arcgis.com/Xmm2kRiMsztfCefg/';
const HTTP = createAxiosInstance(baseURL);

const coloniasConfig = {
  [ENV_NAMES_ENUM.JUAREZ]: {
    url: `${baseURL}arcgis/rest/services/Cd_Juarez/FeatureServer/1`,
    title: 'Colonias',
  },
}[ENV_NAME];

const municipiosConfig = {
  [ENV_NAMES_ENUM.JUAREZ]: {
    url: `${baseURL}arcgis/rest/services/Cd_Juarez/FeatureServer/3`,
    title: 'Municipios',
  },
}[ENV_NAME];

const prediosRender = {
  type: 'simple',
  symbol: {
    type: 'simple-fill',
    color: [0, 0, 255, 0.1],
    outline: {
      width: 0.5,
      color: 'white',
    },
  },
};

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);
  return result ? result.splice(1).map((e) => parseInt(e, 16)).filter((e) => e) : null;
};

const prediosConfig = {
  url: `${baseURL}arcgis/rest/services/pract/FeatureServer/0`,
  title: 'Predios',
  renderer: prediosRender,
  visible: true,
};

const generateQuery = (_clave_catastral, format = 'geojson') => {
  if (_clave_catastral) {
    const idxOfHypen = _clave_catastral.indexOf('-');
    const placesToRemove = idxOfHypen !== -1 ? idxOfHypen + 1 : 0;
    const clave_catastral = _clave_catastral.substring(placesToRemove).replace(/-/g, '');
    const where = `(CLAVECATA%20LIKE%20%27${clave_catastral}%25%27)`;
    return `${prediosConfig.url}/query?where=${where}&f=${format}`;
  }
  return `${prediosConfig.url}/query?f=${format}`;
};

const getModules = async () => {
  try {
    const modules = await loadModules([
      'esri/views/MapView',
      'esri/Map',
      'esri/layers/FeatureLayer',
      'esri/widgets/LayerList',
      'esri/layers/GraphicsLayer',
      'esri/Graphic',
      'esri/widgets/Fullscreen',
    ]);
    return [null, modules];
  } catch {
    return ['Ha ocurrido un error al cargar los módulos', null];
  }
};

export const validateGIS = async (_clave_catastral) => {
  try {
    const idxOfHypen = _clave_catastral.indexOf('-');
    const placesToRemove = idxOfHypen !== -1 ? idxOfHypen + 1 : 0;
    const clave_catastral = _clave_catastral.substring(placesToRemove).replace(/-/g, '');

    const response = await HTTP.get(`${generateQuery(clave_catastral)}&returnUniqueIdsOnly=true`);
    if (response.status === 200 && response.data.uniqueIds?.length === 1) {
      return [true, response.data.uniqueIds[0]];
    }
  } catch (error) {
    _logger(error);
  }
  return [false];
};

const getAttributes = async (uniqueIds) => {
  try {
    const params = '&outFields=*&returnGeometry=false';
    const response = await HTTP
      .get(generateQuery(null, 'json').concat(params).concat(`&objectIds=${uniqueIds}`));
    if (response.status === 200) {
      const attributes = response.data?.features[0]?.attributes;
      if (attributes) {
        return attributes;
      }
    }
  } catch (error) {
    _logger('error', error);
  }
  return null;
};

const getRings = async (_clave_catastral) => {
  try {
    const [isValid, uniqueIds] = await validateGIS(_clave_catastral);
    if (isValid) {
      const response = await HTTP.get(generateQuery(_clave_catastral));
      if (response.status === 200) {
        const rings = response.data.features?.[0]?.geometry?.coordinates;
        const attributes = await getAttributes(uniqueIds);
        if (rings && attributes) {
          return [null, rings, attributes];
        }
      }
    }
  } catch (error) {
    _logger('error', error);
  }
  return [`No fue posible encontrar las coordenadas para clave catastral "${_clave_catastral}"`];
};

const isWhiteImage = (cadenaBase64, umbralBlanco = 255) => {
  const datosDecodificados = atob(cadenaBase64);
  const arrayBytes = new Uint8Array(datosDecodificados.length);
  for (let i = 0; i < datosDecodificados.length; i++) {
    arrayBytes[i] = datosDecodificados.charCodeAt(i);
  }

  let conteoBlanco = 0;

  for (let i = 0; i < arrayBytes.length; i++) {
    if (arrayBytes[i] >= umbralBlanco) {
      conteoBlanco++;
    }
  }

  return conteoBlanco === 0;
};

const ArcGisMap = ({
  clave_catastral_municipal: _clave_catastral,
  setLoading,
  callback,
  layersVisible,
  height,
  width,
  visible,
  withLabel,
  screenshot,
  disabled: _disabled,
  estados_globales,
  visibleButton,
  required,
}) => {
  const disabled = (_disabled && screenshot) || estados_globales === 5;
  const screens = Grid.useBreakpoint();
  const form = Form.useFormInstance();
  const base64 = Form.useWatch('base64', form);
  const [error, setError] = useState();
  const [attributes, setAttributes] = useState();
  const takeScreenshotButtonRef = useRef();

  const loadMap = async () => {
    const [modulesError, modules] = await getModules();
    if (modulesError) {
      throw new Error(modulesError);
    }
    const [MapView, Map, FeatureLayer, LayerList, GraphicsLayer, Graphic] = modules;

    const [ringsError, rings, _attributes] = await getRings(_clave_catastral);
    if (ringsError) throw new Error(ringsError);
    setAttributes(_attributes);
    if (screenshot) {
      callback({
        usosuelo: _attributes.Nombre_USO,
        claveus: _attributes.Clave_USO,
        descripcionus: _attributes.descripcio,
      });
      setLoading(false);
      form.setFieldsValue({ base64: screenshot });
      return;
    }
    const map = new Map({ basemap: 'gray-vector' });
    const lats = rings[0].map((e) => e[0]);
    const longs = rings[0].map((e) => e[1]);
    const latMax = Math.max(...lats);
    const latMin = Math.min(...lats);
    const latDiff = latMax - latMin;
    const longMax = Math.max(...longs);
    const longMin = Math.min(...longs);
    const longDiff = longMax - longMin;
    const lat = latMax - (latDiff / 2);
    const long = longMax - (longDiff / 2);
    const view = new MapView({
      container: 'mapDiv',
      map,
      center: [lat, long],
      zoom: screens.md ? 19 : 18,
      constraints: {
        minZoom: 16,
        maxZoom: 21,
      },
    });

    // Preventing drag
    view.on('drag', (event) => event.stopPropagation());
    view.on('drag', ['Shift'], (event) => event.stopPropagation());
    view.on('drag', ['Shift', 'Control'], (event) => event.stopPropagation());

    // Preventing zoom
    view.on('key-down', (event) => {
      const prohibitedKeys = ['+', '-', 'Shift', '_', '='];
      const keyPressed = event.key;
      if (prohibitedKeys.indexOf(keyPressed) !== -1) {
        event.stopPropagation();
      }
    });
    view.on('mouse-wheel', (event) => event.stopPropagation());
    view.on('double-click', (event) => event.stopPropagation());
    view.on('double-click', ['Control'], (event) => event.stopPropagation());

    // Layers
    const municipios = new FeatureLayer(municipiosConfig);
    const colonias = new FeatureLayer(coloniasConfig);
    const predios = new FeatureLayer(prediosConfig);
    await municipios.load();
    await colonias.load();
    await predios.load();

    map.addMany([predios]);

    if (layersVisible) {
      const listaCapas = new LayerList({ view });
      view.ui.add(listaCapas, 'top-right');
    }

    // Polygon para predio de clave catastral brindada
    const graphicsLayer = new GraphicsLayer();
    map.add(graphicsLayer);
    const polygon = { type: 'polygon', rings };

    const color = hexToRgb(COLORS.accent);
    if (color.length !== 3) {
      color.push(100);
    }
    color.push(0.7);
    const simpleFillSymbol = {
      type: 'simple-fill',
      color: color.length !== 3 ? color.concat([100]) : color,
      outline: {
        color: [255, 255, 255],
        width: 1,
      },
    };

    const polygonGraphicSS = new Graphic({
      geometry: polygon,
      symbol: {
        ...simpleFillSymbol,
        color: [120, 120, 120],
      },
    });

    graphicsLayer.add(polygonGraphicSS);

    const takeScreenshot = async () => {
      try {
        if (form.getFieldValue('base64')) {
          form.setFieldsValue({ base64: null });
          callback(null);
          return;
        }
        setLoading(true);
        const options = {
          ignoreBackground: true,
          quality: 100,
          format: 'png',
        };
        const _screenshot = await view.takeScreenshot(options);
        const isWhite = isWhiteImage(_screenshot?.dataUrl.replace('data:image/png;base64,', ''));
        if (_screenshot?.dataUrl && !isWhite) {
          form.setFieldsValue({ base64: _screenshot.dataUrl });
          callback({
            usosuelo: _attributes.Nombre_USO,
            claveus: _attributes.Clave_USO,
            descripcionus: _attributes.descripcio,
          });
        }
      } catch (err) {
        _logger(err);
      }
      setLoading(false);
    };
    view.when(() => {
      if (!required) {
        setTimeout(takeScreenshot, 5000);
      } else {
        setLoading(false);
        takeScreenshotButtonRef.current.addEventListener('click', takeScreenshot);
      }
      setError();
    });
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      try {
        if (_clave_catastral) {
          await loadMap();
        }
      } catch (err) {
        _logger(err);
        setError(err.message);
        setLoading(false);
      }
    };
    load();

    return () => {
      form.resetFields(['base64']);
    };
  }, []);

  if (!_clave_catastral) {
    return 'Clave catastral no brindada';
  }

  if (error) {
    return (
      <Row justify="center" style={{ width: '100%' }}>
        <Result
          status="warning"
          title="No fue posible cargar la información"
          extra={error}
        />
      </Row>
    );
  }

  return (
    <>
      <Row style={{ width: '100%', marginTop: 15 }} gutter={[10, 10]}>
        {!!attributes && (
          <>
            {withLabel && (
              <Col span={24}>
                <Title level={3}>
                  <Text>
                    Información del predio:
                  </Text>
                </Title>
              </Col>
            )}
            <Col xs={24} sm={24} md={12}>
              <Space>
                <Text strong>
                  CLAVE CATASTRAL:
                </Text>
                <Text>
                  {attributes.CLAVECATA}
                </Text>
              </Space>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Space>
                <Text strong>
                  USO DE SUELO:
                </Text>
                <Text>
                  {attributes.Nombre_USO}
                </Text>
              </Space>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Space>
                <Text strong>
                  CLAVE USO DE SUELO:
                </Text>
                <Text>
                  {attributes.Clave_USO}
                </Text>
              </Space>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Space>
                <Text strong>
                  LIGA PDF USO:
                </Text>
                {attributes.url_uso?.trim() ? (
                  <Button type="link" href={attributes.url_uso} target="_blank">
                    <Text>
                      Visitar
                    </Text>
                    <LinkOutlined />
                  </Button>
                ) : (
                  <Text>
                    Sin liga
                  </Text>
                )}
              </Space>
            </Col>
            <Col span={24}>
              <Space>
                <Text strong>
                  DESCRIPCIÓN USO DE SUELO:
                </Text>
                <Text>
                  {attributes.descripcio?.trim() || 'Sin descripción'}
                </Text>
              </Space>
            </Col>
          </>
        )}
        <Col span={visibleButton ? 8 : 0} offset={visibleButton ? 16 : 0}>
          <Button
            style={{ marginBottom: 15, float: attributes ? 'right' : 'left' }}
            disabled={disabled}
            ref={takeScreenshotButtonRef}
          >
            {!base64 ? 'Tomar captura' : 'Volver a capturar'}
            {!base64 ? <CameraOutlined /> : <ReloadOutlined />}
          </Button>
        </Col>
      </Row>
      {!!base64 && (<img src={base64} style={{ width: '100%' }} alt="GIS screenshot" />)}
      <MapDiv height={height} width={width} $visible={visible && !base64} />
      <Form.Item name="base64" rules={[{ required }]} hidden><Input /></Form.Item>
    </>
  );
};

const MapDiv = styled.div.attrs((attrs) => ({
  id: 'mapDiv',
  style: {
    height: attrs.height || '50vw',
    width: attrs.width || '100vw',
    display: attrs.$visible ? 'flex' : 'none',
  },
}))`
  margin-top: 30px;
  .esri-layer-list__item:first-of-type {
    display: none;
  }
`;

ArcGisMap.propTypes = {
  clave_catastral_municipal: PropTypes.string,
  setLoading: PropTypes.func,
  callback: PropTypes.func,
  layersVisible: PropTypes.bool,
  disabled: PropTypes.bool,
  withLabel: PropTypes.bool,
  visible: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  screenshot: PropTypes.string,
  estados_globales: PropTypes.number,
  visibleButton: PropTypes.bool,
  required: PropTypes.bool,
};

ArcGisMap.defaultProps = {
  clave_catastral_municipal: '',
  setLoading: () => { },
  callback: () => { },
  layersVisible: false,
  height: null,
  width: null,
  disabled: true,
  visible: true,
  withLabel: true,
  screenshot: null,
  estados_globales: null,
  visibleButton: true,
  required: true,
};

export default ArcGisMap;
