import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Result } from 'antd';
import { Button, Text } from 'components';

const NotFound = ({ fullsize }) => {
  const navigate = useNavigate();

  return (
    <div style={{
      height: `100${fullsize ? 'vh' : '%'}`,
      width: `100${fullsize ? 'vw' : '%'}`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}
    >
      <Result
        status="404"
        title="404"
        subTitle="Lo sentimos, la página a la que esta intentando acceder no existe."
      />
      <Button
        // type="link"
        onClick={() => navigate('/')}
        style={{ margin: '0 auto' }}
        $accent
      >
        <Text>
          Volver al inicio
        </Text>
      </Button>
    </div>
  );
};

NotFound.propTypes = {
  fullsize: PropTypes.bool,
};

NotFound.defaultProps = {
  fullsize: true,
};

export default NotFound;
