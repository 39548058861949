import { message } from 'antd';
import API from 'api';
import { selectCiudadano, selectContribuyentes, selectUser } from 'store/auth';
import store from 'store/store';
import _logger from 'utils/logger';
import { AUTH_CONTRIBUYENTE, CATALOGOS_DE_CANAL_DE_PAGO, DEV } from 'utils/env';
import { getPlantillaTramite } from 'api/tramites/plantillasDeTramites';
import { solicitantesEnum } from 'views/Tramites/GPM/enums';
import { getContenTypePorTipoDePadron, getTipoDePadronPorContenType, gettersListMap } from 'api/padrones';

export const baseURI = 'tramites/tramites/';
/**
 * GET Tramites
 * @param {object} params
 * @param {number} params.id
 * @returns {Promise<object[]>}
 */
export const getTramites = async (_params, indexData) => {
  try {
    const user = selectUser(store.getState());
    const contribuyentes = selectContribuyentes(store.getState());
    const ciudadano = selectCiudadano(store.getState());
    const params = {
      ..._params,
      [AUTH_CONTRIBUYENTE ? 'contribuyente' : 'ciudadano']: user.id,
    };
    if (params.id) {
      delete params.ciudadano;
      delete params.contribuyente;
    }
    const promises = [API.get(baseURI, { params })];
    if (contribuyentes?.length && !params.id) {
      const promisesTramitesContribuyentes = contribuyentes
        .map((c) => API.get(baseURI, { params: { ...params, contribuyente: c.id } }));
      promises.push(...promisesTramitesContribuyentes);
    }
    if (contribuyentes?.length && !params.id) {
      delete params.ciudadano;
      const promisesTramitesContribuyentes = contribuyentes
        .map((c) => API.get(baseURI, { params: { ...params, contribuyente: c.id } }));
      promises.push(...promisesTramitesContribuyentes);
    }
    if (ciudadano && !params.id) {
      promises.push(API.get(baseURI, { params: { ...params, ciudadano: ciudadano.id } }));
    }
    const responsesTramites = await Promise.all(promises);
    const tramites = responsesTramites.map((r) => r.data).flat();
    const plantillasIds = [...new Set(tramites.map((t) => t.plantilla))];
    const promisesPlantillas = plantillasIds.map((p) => getPlantillaTramite(p));
    const responsesPlantillas = await Promise.all(promisesPlantillas);
    const plantillas = responsesPlantillas.flat();

    const contentObjectsAfectados = [...new Set(tramites
      .filter((t) => t.object_id_padron).map((t) => `${t.content_type_a_padron}-${t.object_id_padron}`))];
    const promisesContentObject = contentObjectsAfectados.map((p) => {
      const [contentType, objectId] = p.split('-');
      const tipoDePadron = getTipoDePadronPorContenType(parseInt(contentType, 10));
      return gettersListMap[tipoDePadron]({ id: objectId });
    });
    const responsesContentObject = await Promise.all(promisesContentObject);
    const contentObjects = responsesContentObject.flat();
    const mapper = (_tramite) => {
      const tramite = { ..._tramite };
      tramite.plantilla = plantillas.find((p) => p.id === tramite.plantilla);
      if (indexData) {
        if (tramite.ciudadano) {
          tramite.ciudadano = AUTH_CONTRIBUYENTE ? ciudadano : user;
        } else {
          tramite.contribuyente = AUTH_CONTRIBUYENTE
            ? user : contribuyentes.find((c) => c.id === tramite.contribuyente);
        }
        tramite.tipo_padron_solicitante = tramite.solicitante_ciudadano
          ? solicitantesEnum.CIUDADANO : solicitantesEnum.CONTRIBUYENTE;
        tramite.padron_afectado = contentObjects.find((co) => co.id === tramite.object_id_padron
          && tramite.content_type_a_padron === getContenTypePorTipoDePadron(co.tipo_de_padron));
      }
      return tramite;
    };
    if (params.id && !DEV) {
      return tramites.filter((t) => {
        if (t.ciudadano) {
          return AUTH_CONTRIBUYENTE
            ? (t.ciudadano?.id || t.ciudadano) === ciudadano?.id
            : (t.ciudadano?.id || t.ciudadano) === user.id;
        }
        return AUTH_CONTRIBUYENTE
          ? user.id === (t.contribuyente?.id || t.contribuyente)
          : contribuyentes.some((c) => (t.contribuyente?.id || t.contribuyente) === c.id);
      }).map(mapper);
    }
    return tramites.map(mapper);
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Tramite
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getTramite = async (id) => {
  try {
    const response = await API.get(`${baseURI}${id}`);
    return response.data;
  } catch (err) {
    _logger(err);
    return null;
  }
};

/**
 * POST Tramite
  * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postTramite = async (values) => {
  try {
    const response = await API.post(baseURI, {
      ...values,
      canal_de_pago: CATALOGOS_DE_CANAL_DE_PAGO.PAGO_EN_LINEA,
    });
    message.info(response.data?.detail || 'Trámite generado correctamente');
    return response.data;
  } catch (err) {
    _logger(err);
    return null;
  }
};

/**
 * PATCH Tramite
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchTramite = async (id, values) => {
  try {
    const response = await API.patch(`${baseURI}${id}`, values);
    message.info(response.data.detail || 'Editado correctamente');
    return response.data;
  } catch (err) {
    _logger(err);
    return false;
  }
};

/**
 * POST Tramite
 * @param {number} id
 * @returns {Promise<boolean>}
 */
export const cancelarTramite = async (values) => {
  try {
    const response = await API.post(`${baseURI}cancelar`, values);
    message.info(response.data.detail || 'Cancelado correctamente');
    return true;
  } catch (err) {
    _logger(err);
    return false;
  }
};
