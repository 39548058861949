import http from 'api';
import _logger from 'utils/logger';
import { message } from 'antd';
import { formatReceived, formatSent } from 'utils/formatters';

export const baseURI = 'empresas/actividades-de-contribuyentes/';

/**
 * GET Actividades de Contribuyentes
 * @param {Object} params
 * @returns {Promise<Array<object>>}
 */
export const getActividadesDeContribuyente = async (params = null) => {
  try {
    const response = await http.get(baseURI, { params });
    return formatReceived(response.data);
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Actividades de Contribuyentes
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getActividadDeContribuyente = async (id) => {
  try {
    const response = await http.get(`${baseURI}${id}`);
    return formatReceived(response.data);
  } catch (err) {
    _logger(err);
    return null;
  }
};

/**
 * POST Actividad de Contribuyente
  * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postActividadDeContribuyente = async (values) => {
  try {
    const response = await http.post(baseURI, formatSent(values));
    message.success(response || 'Agregado correctamente');
    return formatReceived(response.data);
  } catch (err) {
    _logger(err);
    return false;
  }
};

/**
 * PATCH Actividad de Contribuyente
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchActividadDeContribuyente = async (id, values) => {
  try {
    const response = await http.patch(`${baseURI}${id}`, formatSent(values));
    message.success(response || 'Editado correctamente');
    return formatReceived(response.data);
  } catch (err) {
    _logger(err);
    return false;
  }
};
/**
 * DELETE Actividad de Contribuyente
 * @param {number} id
 * @returns {Promise<object|boolean>}
 */
export const deleteActividadDeContribuyente = async (id) => {
  try {
    const response = await http.delete(`${baseURI}${id}/`);
    message.success(response || 'Eliminado correctamente');
    return true;
  } catch (err) {
    _logger(err);
    return false;
  }
};
