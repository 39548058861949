import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Modal,
  Row,
  Col,
} from 'antd';
import { patchCiudadanoPublic, patchContribuyentePublic, propsToRequire } from 'api/padrones';
import LadaNumero from 'components/LadaNumero';
import _logger from 'utils/logger';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Text,
  Title,
} from 'components';

const ModalUpdateInfo = ({
  visible,
  onCancel,
  setVisible,
  idPadron,
  tipoDePadronId,
  callback,
  initialValues,
}) => {
  const _tipoDePadron = tipoDePadronId === 1 ? 'ciudadano' : 'contribuyente';
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const updaters = {
    1: patchCiudadanoPublic,
    15: patchContribuyentePublic,
  };
  const handleOk = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const _values = {
        [propsToRequire?.[_tipoDePadron]?.[0]]: values[propsToRequire?.[_tipoDePadron]?.[0]],
        [propsToRequire?.[_tipoDePadron]?.[1]]: values[propsToRequire?.[_tipoDePadron]?.[1]],
        [propsToRequire?.[_tipoDePadron]?.[2]]: values[propsToRequire?.[_tipoDePadron]?.[2]],
      };
      const updated = await updaters[tipoDePadronId](values.id, _values);
      if (updated) {
        setVisible(false);
        callback();
      }
      setLoading(false);
    } catch (err) {
      _logger(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      id: idPadron,
      [propsToRequire?.[_tipoDePadron]?.[2]]: 34,
    });
  }, [idPadron, visible]);

  return (
    <Modal
      title={(<Title level={3}>Actualizar información</Title>)}
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      okText="Actualizar"
      cancelText="Cancelar"
      maskClosable={false}
      centered
      closable={false}
      confirmLoading={loading}
      footer={(
        <Row justify="end" gutter={[10, 15]}>
          <Col xs={24} sm={24} md={8}>
            <Button
              onClick={onCancel}
              icon={<ArrowLeftOutlined />}
              disabled={loading}
              style={{ width: '100%' }}
            >
              Cancelar
            </Button>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Button
              type="primary"
              style={{ width: '100%' }}
              onClick={handleOk}
              loading={loading}
              icon={<EditOutlined />}
              $accent
            >
              Actualizar
            </Button>
          </Col>
        </Row>
      )}
    >
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleOk}
            initialValues={initialValues}
          >
            <Col span={24}>
              <Card>
                <Text style={{ textAlign: 'center' }}>
                  El padrón al que intentas acceder no tiene información de contacto, por favor
                  actualiza la información para poder continuar.
                </Text>
              </Card>
            </Col>
            <br />
            <Col span={24}>
              <Form.Item
                rules={[{ required: true }, { type: 'email' }]}
                label="Correo electrónico"
                name={propsToRequire?.[_tipoDePadron]?.[0]}
              >
                <Input />
              </Form.Item>
            </Col>
            <LadaNumero
              form={form}
              names={{
                lada: propsToRequire?.[_tipoDePadron]?.[2],
                numero_de_telefono: propsToRequire?.[_tipoDePadron]?.[1],
              }}
              label="Teléfono"
              hasFeedback
              fullSize
            />
            <Form.Item name="id" hidden>
              <Input disabled />
            </Form.Item>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

ModalUpdateInfo.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  setVisible: PropTypes.func.isRequired,
  idPadron: PropTypes.number.isRequired,
  tipoDePadronId: PropTypes.number.isRequired,
  callback: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    id: PropTypes.number,
    correo_electronico: PropTypes.string,
    numero_de_telefono: PropTypes.number,
    lada: PropTypes.number,
  }),
};

ModalUpdateInfo.defaultProps = {
  initialValues: {
    id: 0,
    correo_electronico: '',
    numero_de_telefono: 0,
  },
};

export default ModalUpdateInfo;
