import API from 'api';
import { message } from 'antd';
import getFormattedValues from 'utils/formatValues';
import _logger from 'utils/logger';

export const baseURI = 'tramites/requisitos-de-tramites/';

/**
 * GET Requisitos De Tramites
 * param {object} params
 * @returns {Promise<object>}
 */
export const getRequisitosDeTramites = async (params) => {
  try {
    const response = await API.get(baseURI, { params });
    return response.data.map(({ documentos_de_tramite: docs = [], ...e }) => ({
      ...e,
      documentos_de_tramite: docs.length > 1 ? docs : docs[0],
    }));
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Requisito de Tramites
 * @param {number} id
 * @returns {Promise<object>}
 */
export const getRequisitoDeTramite = async (id) => {
  try {
    const response = await API.get(`${baseURI}${id}`);
    return response.data;
  } catch (err) {
    _logger(err);
    return null;
  }
};

/**
 * POST Requisito de Tramites
  * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postRequisitosDeTramites = async (_values) => {
  try {
    const values = getFormattedValues(_values);
    if (typeof values.documentos_de_tramite === 'number') {
      values.documentos_de_tramite = [values.documentos_de_tramite];
    }
    const response = await API.post(baseURI, values);
    message.info(response.detail || 'Agregado correctamente');
    return response.data;
  } catch (err) {
    _logger(err);
    return false;
  }
};

/**
 * PATCH Requisito de Tramites
 * @param {number} id
 * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const patchRequisitosDeTramites = async (id, _values) => {
  try {
    const values = getFormattedValues(_values, { clean: false });
    const response = await API.patch(`${baseURI}${id}`, values);
    message.info(response.detail || 'Editado correctamente');
    return response.data;
  } catch (err) {
    _logger(err);
    return false;
  }
};

/**
 * DELETE Requisito de Tramites
 * @param {number} id
 * @returns {Promise<object|boolean>}
 */
export const deleteRequisitosDeTramites = async (id) => {
  try {
    const response = await API.delete(`${baseURI}${id}`);
    message.info(response.detail || 'Eliminado correctamente');
    return true;
  } catch (err) {
    _logger(err);
    return false;
  }
};
