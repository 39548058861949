import Moment from 'moment';
import { selectEntidad } from 'store/auth';
import store from 'store/store';

export const DATE_FRONTEND_FORMAT = 'DD/MM/YYYY';
Moment.defaultFormat = DATE_FRONTEND_FORMAT;

export const DECIMAL_PATTERN = /^(\d)*(\.\d{1,2})?$/;
export const INTEGER_PATTERN = /^\d+$/;
export const INTEGER_PATTERN_REPLACE = /[^0-9]+/g;

export const noSpaces = (val) => val?.replace(/\s/g, '');
export const toUpper = (val = '') => val?.toUpperCase();
export const toInteger = (val) => val.replace(INTEGER_PATTERN_REPLACE, '') || null;
export const toSlug = (val) => val.replace(/ /g, '-').replace(/[^\w-]+/g, '') || null;
export const generateDecimalPattern = (places = 2) => `/^(d)*(.d{1,${places}})?$/`;
export const normalizeText = (text = '') => text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

/**
 * Parse to moment instance
 * @param {string|Date} date
 * @param {string[]} formats
 * @returns {Moment}
 */
export const toMoment = (date, formats) => {
  if (date) {
    const moment = new Moment(date, formats);
    if (moment.isValid()) {
      return moment;
    }
  }
  return null;
};

/**
 * Format to Integer
 * @param {string} val
 * @returns number|null
 */
export const decimalNormalizer = (_val = '') => {
  if (DECIMAL_PATTERN.test(_val)) {
    return _val;
  }
  const val = _val.toString();
  const finishWithDot = val.at(-1) === '.';
  const valWithoutDot = finishWithDot ? val.substring(0, val.length - 1) : val;
  const otherDotIdx = valWithoutDot.indexOf('.');
  const hasTwoNonConsecutiveDots = otherDotIdx !== -1
    && (finishWithDot && otherDotIdx !== valWithoutDot.length - 1);
  if ((!finishWithDot || hasTwoNonConsecutiveDots) && parseFloat(valWithoutDot)) {
    return parseFloat(valWithoutDot);
  }
  const preDotValue = toInteger(valWithoutDot) || '';
  return preDotValue.concat(finishWithDot ? '.' : '');
};

/**
 * List Formatter
 * @param {string[]} list
 * @param {'conjunction'|'disjunction'} type
 * @returns
 */
export const listToString = (
  list,
  type = 'conjunction',
) => new Intl.ListFormat('es-MX', { type }).format(list);

export const capitalize = (sentence, fromConfig = true) => {
  const { solo_mayusculas = false } = selectEntidad(store.getState()) || {};
  return (!fromConfig || solo_mayusculas) && sentence ? sentence.toUpperCase() : sentence;
};

export const getDistinctValues = (values = []) => [...new Set(values)];
