import API from 'api';
import _logger from 'utils/logger';

export const baseURI = 'tramites/comentarios-de-tramites/';

/**
 * GET Comentarios de Tramite
 * param {object} params
 * @returns {Promise<object[]>}
 */
export const getComentariosDeTramites = async (params) => {
  try {
    const response = await API.get(baseURI, { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * POST Comentario de Tramite
  * @param {object} values
  * @param {string} values.comentario
  * @param {boolean} values.es_comentario_interno
  * @param {number} values.tramite
  * @param {File[]} values.archivos
 * @returns {Promise<object|boolean>}
 */
export const postComentarioDeTramite = async (values) => {
  try {
    const response = await API.post(baseURI, values);
    return response.data;
  } catch (err) {
    _logger(err);
    return false;
  }
};
