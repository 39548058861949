import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Card,
  Col,
  DatePicker,
  Divider,
  Empty,
  Form,
  Grid,
  List,
  message,
  Row,
  Spin,
  Table,
  Tag,
} from 'antd';
import {
  Button,
  COLORS,
  Text,
  Title,
} from 'components';
import {
  CaretRightOutlined,
  EyeOutlined,
  FileOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'store/auth';
import { getTipoDeDocumentoParaContribuyente } from 'api/tramites';
import { postObtenerDocumento } from 'api/reporteador';
import Previewer from 'components/Previewer';
import {
  getCatalogoDelMes,
  getConstanciasContribuyente,
  postConstanciasContribuyente,
} from 'api/catalogos';
import Moment from 'moment';
import { selectMeses } from 'store/catalogos';
import Select from 'components/Select';
import { getPeriodosFiscales } from 'api/recaudacion';

const { useBreakpoint } = Grid;

const reportesMap = {
  155: {
    propName: 'IdContribuyente',
    type: 'Entero',
  },
  143: {
    propName: 'IdCiudadano',
    type: 'Entero',
  },
};

const getParams = (ID, _values) => {
  const availableReports = Object.keys(reportesMap);
  if (!availableReports.includes(ID.toString())) {
    message.error('No se encontró el reporte solicitado.');
    return [];
  }
  const { propName, type } = reportesMap[ID];
  const params = [
    {
      Nombre: propName,
      Valor: _values.id,
      TipoParametro: type,
    },
  ];
  return params;
};

const sorter = (a, b) => new Date(b?.fecha_emision) - new Date(a?.fecha_emision);

const getLastConstancia = (_data, _constancias) => {
  if (!_constancias.length) return [];
  const lastOnes = _data.map((constancia) => [
    constancia?.id,
    _constancias.filter((c) => c.tipo_documento_contribuyente?.id === constancia?.id)
      .sort(sorter)[0],
  ]);
  return lastOnes.map(([, c]) => c).filter(Boolean);
};

const MisConstancias = () => {
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const user = useSelector(selectUser);
  const meses = useSelector(selectMeses);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [base64, setBase64] = useState('');
  const [pdfError, setPdfError] = useState('');
  const [loading, setLoading] = useState(true);
  const [visiblePreviewer, setVisiblePreviewer] = useState(false);
  const [constancias, setConstancias] = useState([]);
  const [allConstancias, setAllConstancias] = useState([]);
  const [periodos, setPeriodos] = useState([]);

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const [
        _data,
        _constancias,
        _periodos,
      ] = await Promise.all([
        getTipoDeDocumentoParaContribuyente(),
        getConstanciasContribuyente({ contribuyente: user.id }),
        getPeriodosFiscales(),
        getCatalogoDelMes(dispatch),
      ]);

      if (mounted) {
        setData(_data);
        setConstancias(getLastConstancia(_data, _constancias));
        setPeriodos(_periodos);
        setLoading(false);
      }
    };
    fetchData();
    return () => { mounted = false; };
  }, []);

  if (data.length === 0) {
    return (
      <Row style={{ height: '30vh' }} align="middle">
        <Col span={24}>
          <Empty description="No tienes constancias disponibles" />
        </Col>
      </Row>
    );
  }

  const onCancel = () => {
    setVisiblePreviewer(false);
    setBase64('');
    setPdfError('');
  };

  const getDocumento = async ({ reporte_id: ID, id }) => {
    setLoading(true);
    const Parametros = getParams(ID, user);
    const _values = { ID, Parametros };
    const [error, _base64] = await postObtenerDocumento(_values);

    if (error) {
      message.warn('No se pudo guardar el documento, intenta de nuevo más tarde.');
      setPdfError(error);
    }
    if (_base64) {
      setVisiblePreviewer(true);
      setBase64(_base64);
    }
    const fecha_emision = new Date().toISOString();
    const values = {
      contribuyente: user.id,
      imagenb64: _base64,
      fecha_emision,
      tipo_documento_contribuyente: id,
      estado_de_constancia: 1,
    };
    const saved = await postConstanciasContribuyente(values);
    if (saved) {
      const _constancias = getLastConstancia(data, [...constancias, saved]);
      setConstancias(_constancias);
    } else {
      message.warn('No se pudo guardar el documento, intenta de nuevo más tarde.');
    }

    setLoading(false);
  };

  const handleFilter = async (value) => {
    setLoading(true);
    const { fecha_emision, mes, anio } = value;
    if (!fecha_emision && !mes && !anio) {
      setLoading(false);
      message.warn('Debes ingresar al menos un campo para buscar');
      setAllConstancias([]);
      return;
    }
    const constanciasPreMap = await getConstanciasContribuyente({
      contribuyente: user.id,
      fecha_emision: fecha_emision ? fecha_emision.format('YYYY-MM-DD') : null,
    });

    const _constacias = constanciasPreMap.map((c) => {
      const date = new Date(c.fecha_emision);
      const _mes = date.getMonth() + 1;
      const _anio = date.getFullYear();
      return {
        ...c,
        mes: _mes,
        anio: _anio,
      };
    }).filter((c) => c.mes === mes || c.anio === anio);
    setLoading(false);
    if (_constacias.length > 0) {
      setAllConstancias(_constacias);
    } else {
      message.warn('No se encontraron constancias con los datos ingresados');
    }
  };

  return (
    <Spin spinning={loading} tip="Cargando...">
      <Row gutter={[20, 20]}>
        <List
          loading={loading}
          itemLayout="horizontal"
          locale={{ emptyText: 'No hay documentos' }}
          rowKey="id"
          dataSource={data}
          style={{
            width: '100%',
          }}
          pagination={{
            pageSize: 5,
            size: 'small',
          }}
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 3,
            lg: 3,
            xl: 3,
            xxl: 3,
          }}
          renderItem={(item) => (
            <Card
              actions={[
                <Button
                  icon={<FileOutlined />}
                  $accent
                  disabled={loading}
                  onClick={() => {
                    getDocumento(item);
                  }}
                >
                  <Text>
                    Generar
                  </Text>
                </Button>,
              ]}
            >
              <Row align="middle" gutter={10}>
                <Col>
                  <Avatar>
                    <FileTextOutlined />
                  </Avatar>
                </Col>
                <Col>
                  <Text $bold>{item.descripcion}</Text>
                </Col>
              </Row>
            </Card>
          )}
        />
        <Divider orientation="left">
          <Title $bold $accent level={3}>
            Últimas constancias generadas
          </Title>
        </Divider>
        <List
          loading={loading}
          itemLayout="horizontal"
          locale={{ emptyText: 'No hay constancias' }}
          rowKey="id"
          dataSource={constancias}
          style={{ width: '100%' }}
          pagination={{
            pageSize: 5,
            size: 'small',
          }}
          renderItem={({
            tipo_documento_contribuyente,
            fecha_emision,
            imagenb64,
            folio_documento,
          }) => (
            <List.Item actions={[
              <Button
                $accent
                $block
                onClick={() => {
                  setBase64(imagenb64);
                  setVisiblePreviewer(true);
                }}
              >
                Ver Documento
                <EyeOutlined />
              </Button>,
            ]}
            >
              <List.Item.Meta
                title={(
                  <Text $bold>
                    {`${folio_documento} - ${tipo_documento_contribuyente?.descripcion}`}
                  </Text>
                )}
                description={(
                  <Row gutter={screens.md ? 16 : 0}>
                    <Col>
                      <Tag color={COLORS.accent}>
                        Fecha de emisión:
                        {' '}
                        {Moment(fecha_emision).format('DD/MM/YYYY')}
                      </Tag>
                    </Col>
                  </Row>
                )}
              />
            </List.Item>
          )}
        />
        <Divider orientation="left">
          <Title $bold $accent level={3}>
            Para búsqueda de constancias pesadas, ingrese lo siguiente
          </Title>
        </Divider>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleFilter}
          >
            <Row gutter={10}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Fecha exacta de expedición"
                  name="fecha_emision"
                >
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Mes de expedición"
                  name="mes"
                >
                  <Select options={meses} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Periodo de expedición"
                  name="anio"
                >
                  <Select
                    options={periodos}
                    getLabel={(p) => p.periodo}
                    getValue={(p) => p.periodo}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <Col xs={24} sm={24} md={8}>
                <Form.Item>
                  <Button
                    block
                    $accent
                    htmlType="submit"
                  >
                    Buscar
                    <CaretRightOutlined />
                  </Button>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Table
                  dataSource={allConstancias}
                  pagination={{
                    pageSize: 5,
                    size: 'small',
                  }}
                  columns={[
                    {
                      title: 'Folio',
                      dataIndex: 'folio_documento',
                      key: 'folio_documento',
                    },
                    {
                      title: 'Tipo',
                      dataIndex: 'tipo_documento_contribuyente',
                      key: 'tipo_documento_contribuyente',
                      render: (text) => text?.descripcion ?? '',
                    },
                    {
                      title: 'Fecha de emisión',
                      dataIndex: 'fecha_emision',
                      key: 'fecha_emision',
                      render: (text) => Moment(text).format('DD/MM/YYYY'),
                    },
                    {
                      title: 'Acciones',
                      dataIndex: 'acciones',
                      key: 'acciones',
                      render: (text, record) => (
                        <Button
                          $accent
                          icon={<EyeOutlined />}
                          onClick={() => {
                            setBase64(record.imagenb64);
                            setVisiblePreviewer(true);
                          }}
                        >
                          Ver
                        </Button>
                      ),
                    },
                  ]}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Previewer
        base64={base64}
        visible={visiblePreviewer}
        onCancel={onCancel}
        error={pdfError}
        allowSave
      />
    </Spin>
  );
};

const Avatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
`;

export default MisConstancias;
