import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Text } from 'components';
import LinkWithTooltip from 'components/LinkWithTooltip';
import { DeleteOutlined, RightOutlined } from '@ant-design/icons';
import {
  Col,
  Form,
  Input,
  Row,
} from 'antd';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated, selectUser } from 'store/auth';
import RegisterPadron from 'views/Payments/components/RegisterPadron';
import { ACCOUNT_AVAILABLE, AUTH_CONTRIBUYENTE, DEV } from 'utils/env';
import { RFC_PATTERN } from 'utils/patterns';
import { gettersListMap } from 'api/padrones';
import { padronesEnum } from 'views/Tramites/GPM/enums';

const solicitanteFields = [
  'ciudadano',
  'contribuyente',

  'CURP',
  'first_name',
  'last_name',
  'second_last_name',
  'email',
  'lada',
  'numero_de_celular',

  'tipo_de_persona',
  'rfc',
  'curp',
  'nombre',
  'apellido_paterno',
  'apellido_materno',
  'correo_electronico',
  'lada_celular',
  'telefono_celular',
  'razon_social',
];

export const solicitanteHasContactInfo = (values, tipoDePadron) => {
  if (tipoDePadron === padronesEnum.CONTRIBUYENTE) {
    return values.correo_electronico && values.lada_celular && values.telefono_celular;
  }
  return values.correo_electronico && values.lada && values.numero_de_celular;
};

const DatosSolicitante = ({ visible }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const user = useSelector(selectUser);
  const form = Form.useFormInstance();

  const tipoDePadron = AUTH_CONTRIBUYENTE ? padronesEnum.CONTRIBUYENTE : padronesEnum.CIUDADANO;
  const solicitanteName = tipoDePadron === padronesEnum.CIUDADANO ? 'ciudadano' : 'contribuyente';
  const solicitanteId = Form.useWatch(solicitanteName, form);

  const hasContactInfo = useMemo(() => {
    if (!solicitanteId) return false;
    const values = form.getFieldValue();
    return solicitanteHasContactInfo(values, tipoDePadron);
  }, [solicitanteId]);

  const rules = {
    rfc: [
      { required: true },
      {
        validator: async (_, _value) => {
          if (!_value) return;
          const value = _value.toString().toUpperCase();
          const lengthsByTipoPersona = {
            1: 13, // fisica
            2: 12, // moral
            3: 13, // extranjero
          };
          const tipoDePersona = form.getFieldValue('tipo_de_persona');
          const invalidLength = value.length !== lengthsByTipoPersona[tipoDePersona];
          const invalid = !RFC_PATTERN.test(value) || invalidLength;
          if (invalid) throw new Error('Ingrese un RFC válido.');
          const id = form.getFieldValue('id');
          const propsNames = { 1: 'RFC', 15: 'rfc' };
          const propName = propsNames[tipoDePadron];
          const params = { [propName]: value };
          const matches = await (gettersListMap[tipoDePadron])(params);
          const [match] = matches.results.filter((e) => e.id !== id);
          if (match) {
            form.setFieldsValue({ ...match, [solicitanteName]: match.id });
          }
        },
      },
    ],
  };

  useEffect(() => {
    if (isAuthenticated) {
      form.setFieldsValue({ ...user, [solicitanteName]: user.id });
    }
  }, []);

  const getRedirect = () => {
    const url = new URL(window.location);
    const fields = ['plantilla', 'oficina', 'fecha', 'horario'];
    const values = form.getFieldsValue(fields);
    values.fecha = values.fecha?.format();
    const path = fields.map((f) => `${values[f]}`).join();
    const encoded = encodeURIComponent(`${url.pathname}?config=${path}`);
    return encoded;
  };

  return (
    <Col span={visible ? 24 : 0}>
      {!isAuthenticated && ACCOUNT_AVAILABLE && (
        <>
          <Text>
            Si ya se encuentra registrado puede:
            <Button style={{ marginInline: 15 }} $accent>
              <LinkWithTooltip
                to={`/mi-cuenta${DEV ? `/?redirect=${getRedirect()}` : ''}`}
                text="Iniciar sesión"
                icon={RightOutlined}
              />
            </Button>
            o ingrese su
            {' '}
            <b>{tipoDePadron === padronesEnum ? 'CURP' : 'RFC'}</b>
            {' '}
            para intentar autorellenar sus datos.
          </Text>
          <Col span={24} style={{ height: 30 }} />
          <Text>
            De lo contrario capture su información para registrarse:
          </Text>
          <Col span={24} style={{ height: 15 }} />
        </>
      )}
      <Row gutter={10} style={{ height: '100%', paddingBottom: 20 }}>
        {solicitanteId && (
          <Col span={8} order={0}>
            <Form.Item label="Clave" name={tipoDePadron === padronesEnum.CIUDADANO ? 'clave_ciudadana' : 'clave'}>
              <Input disabled />
            </Form.Item>
          </Col>
        )}
        <RegisterPadron
          tipoDePadron={tipoDePadron}
          callback={() => null}
          onCancel={() => null}
          rulesRFC={rules.rfc}
          onlyItems
          disabled={!!solicitanteId}
          disabledContactInfo={hasContactInfo}
          selfId={solicitanteId}
        />
        <Col span={8} order={9}>
          <Form.Item label=" ">
            <Button disabled={isAuthenticated} onClick={() => form.resetFields(solicitanteFields)}>
              Limpiar
              <DeleteOutlined />
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item hidden name={solicitanteName}>
        <Input />
      </Form.Item>
    </Col>
  );
};

DatosSolicitante.propTypes = {
  visible: PropTypes.bool,
};

DatosSolicitante.defaultProps = {
  visible: false,
};

export default DatosSolicitante;
