import React, { useMemo } from 'react';
import {
  Typography,
  Button as AntButton,
  Col,
  Tabs as AntTabs,
  Tooltip,
} from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ENV_NAME, ENV_NAMES_ENUM } from 'utils/env';
import { hideProp } from 'utils/formatters';
import entityInfo from 'utils/entityInfo';
import { toMoment } from 'utils/normalizers';

const themes = {
  [ENV_NAMES_ENUM.NAYARIT]: {
    accent: '#681B2D',
    gray: '#413c3e',
    cream: '#D0BAA7',
    cream2: '#C7B29E',
    text: '#404041',
  },
  [ENV_NAMES_ENUM.JUAREZ]: {
    accent: '#5F2A45',
    gray: '#54636d',
    cream: '#54636d',
    cream2: '#54636d',
    text: '#4C5860',
  },
  [ENV_NAMES_ENUM.HERMOSILLO]: {
    accent: '#f16710',
    gray: '#eda502',
    cream: '#CDCDCD',
    cream2: '#6c757b',
    text: '##b5c1c9',
    defaultColorButton: '#f16710',
  },
  [ENV_NAMES_ENUM.ROATAN]: {
    accent: '#ccae7a',
    gray: '#c39260',
    cream: '#148da0',
    cream2: '#fff0d1',
    text: '#3D3D3D',
  },
};

export const COLORS = {
  ...themes[ENV_NAME],
  white: '#FFFFFF',
  black: '#000000',
  success: '#28a745',
  warn: '#faad14',
  danger: '#ff4d4f',
};

export const TerminosYCondiciones = () => (entityInfo?.terminos ? (
  <AntButton
    type="link"
    onClick={() => window.open(entityInfo.terminos, '_blank')}
    style={{ padding: 0, border: 'none' }}
  >
    términos de uso
  </AntButton>
) : 'términos de uso');

export const Aviso = () => (
  <AntButton
    type="link"
    onClick={() => window.open('/aviso-de-privacidad', '_blank')}
    style={{ padding: 0, border: 'none' }}
  >
    aviso de privacidad
  </AntButton>
);

export const LabelValue = ({
  label,
  value: _value,
  md,
  hidden,
  hiddenConfig,
  tip,
  strong,
  xs,
  break: _break,
}) => {
  const value = useMemo(() => {
    if (!_value) return `Sin ${label?.toLowerCase().replace(':', '')}`;
    if (hidden) {
      return hideProp(_value?.toString().trim(), hiddenConfig.firstChar, hiddenConfig.lastChar);
    }
    if (/\d{4}-\d{2}-\d{2}/.test(_value)) return toMoment(_value).format();
    if (typeof _value === 'string') return _value?.toString().trim();
    return _value;
  }, [_value]);

  return (
    <Col xs={24} sm={24} md={md}>
      <Tooltip title={tip}>
        <Text $xs={xs} strong={strong === 'label'}>
          {label}
        </Text>
      </Tooltip>
      {_break ? (<br />) : ' '}
      <Text $xs={xs} strong={strong === 'value'}>
        {value}
      </Text>
    </Col>
  );
};

LabelValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node]),
  md: PropTypes.number,
  hidden: PropTypes.bool,
  tip: PropTypes.string,
  hiddenConfig: PropTypes.shape({
    firstChar: PropTypes.bool,
    lastChar: PropTypes.number,
  }),
  strong: PropTypes.oneOf(['label', 'value']),
  xs: PropTypes.bool,
  break: PropTypes.bool,
};

LabelValue.defaultProps = {
  value: null,
  md: 12,
  hidden: false,
  tip: null,
  hiddenConfig: {
    firstChar: true,
    lastChar: 4,
  },
  strong: 'value',
  xs: false,
  break: true,
};

export const CardContainer = styled.div.attrs({ className: 'card-container' })`
  box-shadow: 0 2px 6px #00000026;
  padding: 24px;
  border-radius: 8px;
  &.col {
    background: ${COLORS.cream};
    display: flex;
    flex-direction: column;
    align-items: center;
    .ant-btn, .ant-typography  {
      margin: 12px;
    }
  }
  @media only screen and (max-width: 576px) {
    border-radius: 0;
  }
`;

export const Card = styled.div`
  border: 1px solid #0000001F;
  padding: 18px;
  border-radius: 8px;
  box-shadow: 0 1px 1px #00000026;
  background: ${(props) => props.bg || 'transparent'};
  height: 100%;
  &.col {
    display: flex;
    flex-direction: column;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: ${(props) => props.alignItems || 'center'};
  justify-content: ${(props) => props.justifyContent || 'center'};
  flex: ${(props) => props.flex};
`;

export const FlexCol = styled(FlexRow)`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.alignItems || 'center'};
  justify-content: ${(props) => props.justifyContent || 'center'};
  flex: ${(props) => props.flex};
`;

export const Button = styled(AntButton).attrs(({ $interactive = true, ...attrs }) => ({ ...attrs, $interactive }))`
  :not(.ant-btn-dangerous):not(.ant-btn-primary) {
    color: ${COLORS?.defaultColorButton || COLORS.gray};
    ${(props) => !props.bg && 'background: transparent !important;'}
  }
  height: auto;
  word-break: break-word !important;
  text-overflow: unset !important;
  white-space: unset !important;
  /* width: fit-content; */
  ${(props) => props.bg && `background: ${props.bg} !important;`}
  &:not(.ant-btn-dangerous):not(.ant-btn-primary):not(.ant-btn-link) {
    &, * {
      ${(props) => props.color && `color: ${props.color} !important;
        border-color: ${props.color} !important;`}
      ${(props) => props.$accent && `color: ${COLORS.white} !important;
        border-color: ${COLORS.accent} !important;
        background: ${COLORS.accent} !important;`}
    }
  }
  &:not(.ant-btn-link) {
    line-height: 24.5px;
    &:not(:disabled):not(.ant-btn-dangerous):not(.ant-btn-primary){
      border-color: ${COLORS?.defaultColorButton || COLORS.gray};
    }
    &, * {
      ${(props) => props.color && `color: ${props.color} !important;
        border-color: ${props.color};`}
      ${(props) => props.$accent && `
        color: ${COLORS.white};
        border-color: ${COLORS.accent};
        background: ${COLORS.accent};`}
    }
    &:not(:disabled):not(.ant-btn-dangerous):not(.ant-btn-primary) {
      &:active, &:hover, &:visited, &:focus {
        ${(props) => props.color && `color: ${props.color} !important;
          border-color: ${props.color} !important;`}
        ${(props) => props.bg && `background: ${props.bg} !important;`}
        ${(props) => props.$accent && `
          color: ${COLORS.white} !important;
          border-color: ${COLORS.accent} !important;
          background: ${COLORS.accent} !important;`}
      }
      &:hover {
        opacity: ${(props) => ('opacity' in props ? props.opacity : 0.7)};
      }
    }
  }
  &:disabled, &:disabled:hover {
    ${(props) => props.bg && `background: ${props.bg} !important;`}
    ${(props) => props.$accent && `background: ${COLORS.accent} !important;`}
  }

  @media only screen and (max-width: 576px) {
    :not(.ant-btn-block) span:not(.anticon):not(.ant-typography) {
      display: none;
    }
  }
  a+.anticon, .ant-typography+.anticon {
    margin-left: 5px;
  }

  &.ant-btn-link:not(.ant-btn-dangerous):not(.ant-btn-primary) {
    color: ${COLORS.text};
    ${(props) => props.color && `color: ${props.color} !important;`}
  }

  :not(:hover) .anticon svg *.transparent {
    fill: #FFFFFF;
    ${(props) => props.bg && `fill: ${props.bg};`}
  }

  &:hover .anticon svg *.transparent {
    ${(props) => props.bg && `fill: ${props.bg};`}
    ${(props) => props.$accent && `fill: ${COLORS.accent};`}
  }
  ${(props) => props.$accent && `&:not(.ant-btn-dangerous):not(.ant-btn-primary):hover .anticon svg *:not(.transparent) {
      fill: #FFFFFF;
    }`}


  &.ant-btn-dangerous.ant-btn-link * {
    color: #ff7875 !important;
  }
  ${(props) => !props.$interactive && `
    cursor: default;
    pointer-events: none;
  `}
  ${(props) => !!props.fontSize && `
    * {
      font-size: ${props.fontSize}px !important;
    }
  `}
`;

export const Text = styled(Typography.Text)`
  color: ${(props) => props.color || (props.$accent && COLORS.accent) || COLORS.text};
  font-size: 18px;
  ${(props) => (props.$centered && 'text-align: center; width: 100%; margin 0 auto;')};
  ${(props) => (props.$block && 'width: 100%')};

  &.ant-typography-edit-content {
    .ant-input {
      left: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .ant-typography-copy {
    color: ${(props) => props.color || (props.$accent && COLORS.accent) || COLORS.text};
  }
  @media only screen and (max-width: 576px) {
    font-size: 14px !important;
  }

  &, & * {
    ${(props) => props.$uppercase && 'text-transform: uppercase;'}
    ${(props) => props.$lowercase && 'text-transform: lowercase;'}
    ${(props) => props.$capitalize && 'text-transform: capitalize;'}
    ${(props) => props.$bold && 'font-weight: bold;'}
    ${(props) => props.$xs && 'font-size: 12px !important;'}
    ${(props) => props.$xxs && 'font-size: 7px !important;'}
  }
`;

export const Title = styled(Typography.Title)`
  margin: 0 !important;
  ${(props) => (props.$centered && 'text-align: center;')}
  ${(props) => (props.$accent && `color: ${COLORS.accent} !important;`)}
  ${(props) => (props.color && `color: ${props.color} !important;`)};
  ${(props) => (!props.color && !props.$accent && `color: ${COLORS.text} !important;`)}
  ${(props) => (!props.level && 'font-size: 38px !important;')}
  ${(props) => (props.level === 2 && 'font-size: 32px !important;')}
  ${(props) => (props.level === 3 && 'font-size: 28px !important;')}
  ${(props) => props.$uppercase && 'text-transform: uppercase;'}
  ${(props) => props.$lowercase && 'text-transform: lowercase;'}
  ${(props) => props.$capitalize && 'text-transform: capitalize;'}

  @media only screen and (max-width: 576px) {
    ${(props) => (!props.level && 'font-size: 26px !important;')}
    ${(props) => (props.level === 2 && 'font-size: 22px !important;')}
    ${(props) => (props.level === 3 && 'font-size: 18px !important;')}
  }
  ${(props) => props.$bold && 'font-weight: bold;'}
`;

export const Tabs = styled(AntTabs)`
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${COLORS.accent};
    background-color: #f0f2f5
  }
  .ant-tabs-tab-btn:hover {
    color: ${COLORS.accent};
  }

  .ant-tabs-nav {
    margin-bottom: 0px;

    .ant-tabs-nav-list {
      .ant-tabs-tab {
        background-color: transparent;
        border: solid 2px #d3d3d3;
        border-radius: 8px 8px 0 0;
        border-bottom: ${COLORS.accent} !important;
      }

      .ant-tabs-tab-active {
        border: solid 2px ${COLORS.accent};
        border-bottom: none;
      }
    }
  }

  .ant-tabs-content-holder {
    padding: 10px;
    background-color: transparent;
    border: solid 2px ${COLORS.accent};
    border-top: none !important;
    border-radius: 0 0 8px 8px;
  }

  .ant-tabs-ink-bar {
    position: absolute;
    pointer-events: none;
  }
`;

export const EmptyLabel = styled(Text).attrs((props) => ({
  children: props.$md ? '0' : '',
}))`
  color: transparent;
`;
