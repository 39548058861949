import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Form,
  Upload,
  Button,
  Tooltip,
  message,
  Typography,
} from 'antd';

import {
  UploadOutlined,
  EyeOutlined,
  CheckCircleOutlined,
  FileUnknownOutlined,
} from '@ant-design/icons';

import Previewer from 'components/Previewer';

const { Text } = Typography;

const Uploader = ({
  title,
  limitMB,
  fullSize,
  formItemName,
  allowedExtensions,
  allowPreview,
  showPreviewMessage,
  rules,
  ...rest
}) => {
  const form = Form.useFormInstance();
  const file = Form.useWatch(formItemName, form);
  const defaultBreakPoints = {
    xs: 24,
    sm: 24,
    md: 8,
  };
  const [fileList, setFileList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [iFrameUrl, setIFrameUrl] = useState(null);

  const getProps = () => (fullSize ? { span: 24 } : defaultBreakPoints);
  const accept = allowedExtensions.map((e) => `.${e}`).join(',');
  const formats = allowedExtensions.join(', ').toUpperCase();

  useEffect(() => {
    if (!file) {
      setFileList([]);
    }
  }, [file]);

  const preview = () => {
    if (file && typeof file === 'string') {
      setIFrameUrl(file);
      setVisible(true);
    }
  };

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Col {...getProps()}>
        <Form.Item
          name={formItemName}
          label={(
            <>
              <Text style={{ marginRight: 5 }}>{title}</Text>
              {(allowPreview && file && typeof file === 'string') && (
                <Tooltip title="Click para ver preview">
                  <Button
                    type="link"
                    onClick={preview}
                    icon={<EyeOutlined />}
                  />
                </Tooltip>
              )}
              {(!allowPreview && file && typeof file === 'string' && showPreviewMessage) && (
                <Tooltip
                  title="Por motivos de seguridad este archivo
                    no es accesible, si desea modificarlo suba un archivo nuevo"
                >
                  <CheckCircleOutlined style={{ marginLeft: 10, color: '#1bc943' }} />
                </Tooltip>
              )}
              {!!accept && (
                <Tooltip
                  title={`Se admiten archivos en formato ${formats}`}
                >
                  <FileUnknownOutlined style={{ marginLeft: 10 }} />
                </Tooltip>
              )}
            </>
          )}
          rules={rules}
        >
          <Upload
            beforeUpload={() => false}
            onRemove={() => {
              setFileList([]);
            }}
            onChange={(e) => {
              const { name } = e.file;
              if (e.file.status !== 'removed') {
                const isValidExt = allowedExtensions.includes(name.split('.').pop());

                if (!isValidExt) {
                  message.warn(`Solo se admiten archivos en formato ${formats}`);
                }
                const isLmt = e.file.size / 1024 / 1024 < limitMB;
                if (!isLmt) {
                  message.warn(`El archivo no debe sobrepasar los ${limitMB}MB`);
                }
                if (isValidExt && isLmt) {
                  setFileList([e.file]);
                }
              } else {
                setFileList([]);
              }
            }}
            fileList={fileList}
            accept={accept}
            disabled={!!rest.disabled}
          >
            <Button disabled={!!rest.disabled}>
              <UploadOutlined />
              {' '}
              Subir archivo
            </Button>
          </Upload>
        </Form.Item>
      </Col>
      {allowPreview && (
        <Previewer
          visible={visible}
          onCancel={() => setVisible(false)}
          iFrameUrl={iFrameUrl}
        />
      )}
    </>
  );
};

Uploader.propTypes = {
  allowPreview: PropTypes.bool,
  showPreviewMessage: PropTypes.bool,
  fullSize: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  limitMB: PropTypes.number.isRequired,
  formItemName: PropTypes.string.isRequired,
  allowedExtensions: PropTypes.arrayOf(PropTypes.string).isRequired,
  rules: PropTypes.arrayOf(PropTypes.shape({})),
};

Uploader.defaultProps = {
  fullSize: false,
  allowPreview: true,
  showPreviewMessage: true,
  rules: [],
};

export default Uploader;
