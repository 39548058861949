/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Skeleton,
  Grid,
  Modal,
  Tooltip,
} from 'antd';
import {
  Title,
  Button,
  COLORS,
  Text,
} from 'components';
import {
  DeleteOutlined,
  UserAddOutlined,
  SearchOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  noSpaces,
} from 'utils/formatters';
import PadronInfo from 'views/Payments/components/PadronInfo';
import WaitingAlert from 'views/Payments/components/WaitingAlert';
import { scrollToTop } from 'components/ScrollToTop';
import Select from 'components/Select';
import { useLocation } from 'react-router';
import { extractSegment } from 'utils/toSlug';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectPublicoGeneral } from 'store/auth';
import { selectPadrones } from 'store/catalogos';
import ArcGisMap, { validateGIS } from 'views/Tramites/GPM/components/ArcGisMap';
import { ENV_NAME, ENV_NAMES_ENUM } from 'utils/env';
import { getContribuyente } from 'api/padrones';
import { padronesEnum } from 'views/Tramites/GPM/enums';
import PredioInput from './PredioInput';
import ModalName from './ModalName';
import RegisterPadron from './RegisterPadron';

const defaultLabelByPadron = 'Clave, RFC o CURP';

const labelByPadron = {
  2: 'Clave',
  3: 'Clave catastral',
  4: 'Placa o número de serie',
  9: 'Número de Permiso, Clave, RFC o CURP',
  16: 'Placa o número de serie',
  17: 'Placa o número de serie',
  18: 'Clave',
  19: 'Clave, Folio, Placa, Licencia o Nombre de Infractor',
  20: 'Clave',
  21: 'Clave',
  22: 'Clave',
  23: 'Clave',
  24: 'Clave',
  25: 'Clave o Folio',
};

export const GISInfoResult = ({
  hasGISInfo,
  type,
  style,
  onClick,
  departamento,
}) => (hasGISInfo ? (
  <Text type={type} style={style}>
    Georreferencia del predio (Mapa) localizada, para consultar haz click aquí:
    {' '}
    <Button onClick={onClick} style={{ padding: '0 5px' }}>
      <Text style={{ fontSize: 'inherit' }}>
        Ver
      </Text>
    </Button>
  </Text>
) : (
  <Text type={type} style={style}>
    La georreferencia del predio (Mapa) no fue localizada en la base de datos.
    Le pedimos acercarse a las oficinas de
    {' '}
    {departamento?.descripcion}
    , para hacer su trámite.
  </Text>
));

const SearchForm = ({
  padron,
  onCancel,
  loading = true,
  setLoading,
  form: _form,
  classifierParam,
  currentClassifier,
  onFinishSearch,
  changePeriodoFiscal,
  tipoDePadron,
  periodosFiscales,
  visibleAlert,
  setTipoDePadron,
  modal = false,
  withGISInfo = false,
  hasGISInfo,
  setHasGISInfo,
  departamento,
}) => {
  const [form] = _form ? [_form] : Form.useForm();
  const ciudadanoPublicoGeneral = useSelector(selectPublicoGeneral);
  const { pathname } = useLocation();
  const screens = Grid.useBreakpoint();
  const [visibleRegister, setVisibleRegister] = useState(false);
  const [visibleModalName, setVisibleModalName] = useState(false);
  const [visibleMap, setVisibleMap] = useState(false);
  const [periodoFiscal, setPeriodoFiscal] = useState(null);
  const { padron: _padron } = useParams();
  const padrones = useSelector(selectPadrones);

  const padronString = padrones.find((e) => e.id === tipoDePadron)?.descripcion || '';

  // SE HABILITA EL DOBLE INPUT PARA PADRONES ESPECIFICOS
  const enableDobleInput = [
    padronesEnum.HOSPEDAJE,
    padronesEnum.ARRENDAMIENTO,
    padronesEnum.NOMINA,
    padronesEnum.CEDULAR,
    padronesEnum.JUEGO_DE_AZAR,
    padronesEnum.AGENCIA,
    padronesEnum.VENTA_DE_ALCOHOL,
    padronesEnum.RETENEDOR_DE_CEDULAR,
    padronesEnum.RETENEDOR_DE_NOMINA,
    padronesEnum.NOTARIO,
  ].includes(tipoDePadron) && ENV_NAME === 'nayarit';

  useEffect(() => {
    const validate = async () => {
      if (padron && tipoDePadron === padronesEnum.PREDIO) {
        setLoading(true);
        const [hasInfo] = await validateGIS(padron.clave_catastral_municipal);
        setHasGISInfo(hasInfo);
        setLoading(false);
      }
    };
    if (withGISInfo) {
      validate();
    }
  }, [padron]);

  useEffect(() => {
    const fetchData = async () => {
      if (_padron) {
        const _tipoDePadron = parseInt(extractSegment(_padron, '-', -1), 10);
        setTipoDePadron(_tipoDePadron);
        form.setFieldsValue({ padron: _tipoDePadron });
      }
      setTimeout(() => setLoading(false), 2000);
    };
    fetchData();
  }, []);

  const onFinishRegister = async (registered) => {
    setLoading(true);
    if (registered) {
      form.resetFields();
      form.setFieldsValue({
        q: registered.clave_ciudadana
        || registered.CURP
        || registered.rfc
        || registered.email
        || registered.numero_de_celular,
        padron: tipoDePadron,
        periodo_fiscal: periodosFiscales
          .find((p) => p.periodo === new Date().getFullYear())?.id,
      });
      setVisibleRegister(false);
      setTimeout(() => form.submit(), 500);
    }
    setLoading(false);
  };

  if (visibleRegister) {
    return (
      <RegisterPadron
        tipoDePadron={tipoDePadron === 1 ? tipoDePadron : 15}
        callback={onFinishRegister}
        onCancel={() => {
          form.resetFields(['q']);
          setVisibleRegister(false);
          scrollToTop(screens);
        }}
      />
    );
  }

  const onClickCobroSinRegistro = async () => {
    setTimeout(() => {
      onFinishSearch({ id: ciudadanoPublicoGeneral.id, padron: 1 });
    }, 1000);
  };

  const validActiveContribuyente = async () => {
    const params = form.getFieldsValue();
    params.periodo_fiscal = periodosFiscales && periodosFiscales[0]?.id;
    if (params?.empresa__contribuyente__rfc) {
      const { results } = await getContribuyente(false, {
        rfc: params.empresa__contribuyente__rfc,
      });
      if (results?.length > 0) {
        const contribuyente = results[0];
        setVisibleRegister(false);
        if (contribuyente?.estado_del_padron === 2) {
          onFinishSearch({ ...params, q: contribuyente });
        } else {
          Modal.warn({
            width: '90vw',
            closable: true,
            title: <Text strong $accent>Advertencia Contribuyente Suspendido</Text>,
            content: (
              <Text strong>
                Favor de pasar al departamento de atención al
                contribuyente a regularizarse.
              </Text>
            ),
            okButtonProps: { style: { display: 'none', heigth: 0 } },
            bodyStyle: { paddingBottom: 0 },
          });
        }
      } else {
        setVisibleRegister(true);
        scrollToTop(screens);
      }
    } else {
      onFinishSearch(params);
    }
  };

  return (
    <Card
      title={modal && !padron ? null : (
        <Row justify="space-between">
          <Skeleton
            active
            loading={false}
            paragraph={{ rows: 0, width: '100%' }}
          >
            <Col>
              <Title level={2} $accent>
                {!padron ? `Búsqueda de ${pathname.includes('consulta-de-adeudos')
                  ? padronString : 'padrón'}` : 'Información de padrón'}
              </Title>
            </Col>
            {padron && (
              <Col>
                <Button
                  onClick={() => {
                    onCancel();
                    setVisibleMap(false);
                    if (setHasGISInfo) {
                      setHasGISInfo(false);
                    }
                    if (_padron) {
                      const _tipoDePadron = parseInt(extractSegment(_padron, '-', -1), 10);
                      setTipoDePadron(_tipoDePadron);
                      form.setFieldsValue({ padron: _tipoDePadron });
                    }
                  }}
                  loading={loading}
                  style={{ fontWeight: 'bold' }}
                  type="link"
                  block
                  danger
                >
                  Borrar búsqueda
                  <DeleteOutlined />
                </Button>
              </Col>
            )}
          </Skeleton>
        </Row>
      )}
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
      }}
      bordered={!modal}
    >
      {!padron && (
        <Form
          layout="vertical"
          form={form}
          onFinish={(values) => (ENV_NAME === ENV_NAMES_ENUM.NAYARIT
            ? validActiveContribuyente() : onFinishSearch(values))}
          style={{ width: '100%' }}
        >
          {!modal && (
            <Form.Item
              label="Padrón para realizar su pago"
              name="padron"
              rules={[{ required: true }]}
              hidden={pathname.includes('consulta-de-adeudos')}
            >
              <Select
                disabled={loading || (classifierParam && classifierParam !== '03')}
                options={padrones.filter((e) => ((classifierParam && classifierParam === '03')
                  ? [
                    padronesEnum.CIUDADANO,
                    padronesEnum.CONTRIBUYENTE,
                    padronesEnum.EMPRESA,
                  ].includes(e.id) : e))}
                onChange={setTipoDePadron}
              />
            </Form.Item>
          )}
          {tipoDePadron === padronesEnum.PREDIO && (
            <PredioInput loading={loading} modal={modal} />
          )}
          {![
            padronesEnum.VEHICULO,
            padronesEnum.PREDIO,
            padronesEnum.MOTOCICLETA,
            padronesEnum.REMOLQUE,
          ].includes(tipoDePadron) && (
            <Form.Item
              label={(
                <Skeleton
                  title={{ width: 200 }}
                  paragraph={{ rows: 0 }}
                  active
                  loading={(classifierParam && !currentClassifier) && loading}
                >
                  { enableDobleInput
                    ? (
                      <Tooltip title="Si su clave inicia con una letra favor de agregar los guiones, pero si son solo números omitir el mensaje">
                        Clave de Registro Estatal
                        <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                      </Tooltip>
                    )
                    : labelByPadron[tipoDePadron] || defaultLabelByPadron }
                </Skeleton>
              )}
              name={enableDobleInput ? 'clave' : 'q'}
              rules={[{ required: true }]}
              normalize={(value) => value.toUpperCase()}
            >
              <Input disabled={loading} />
            </Form.Item>
          )}
          {enableDobleInput && (
            <Form.Item
              label={(
                <Skeleton
                  title={{ width: 200 }}
                  paragraph={{ rows: 0 }}
                  active
                  loading={(classifierParam && !currentClassifier) && loading}
                >
                  RFC
                </Skeleton>
              )}
              name="empresa__contribuyente__rfc"
              rules={[{ required: true }]}
              normalize={(value) => noSpaces(value.toUpperCase())}
            >
              <Input disabled={loading} />
            </Form.Item>
          )}
          {[
            padronesEnum.VEHICULO,
            padronesEnum.MOTOCICLETA,
            padronesEnum.REMOLQUE,
          ].includes(tipoDePadron) && (
            <>
              <Form.Item
                label={(
                  <Skeleton
                    title={{ width: 200 }}
                    paragraph={{ rows: 0 }}
                    active
                    loading={(classifierParam && !currentClassifier) && loading}
                  >
                    Serie
                  </Skeleton>
                )}
                name="serie"
                rules={[{ required: true }]}
                normalize={noSpaces}
              >
                <Input disabled={loading} />
              </Form.Item>
              <Form.Item
                label={(
                  <Skeleton
                    title={{ width: 200 }}
                    paragraph={{ rows: 0 }}
                    active
                    loading={(classifierParam && !currentClassifier) && loading}
                  >
                    Placa (Favor de omitir guiones)
                  </Skeleton>
                )}
                name="placa"
                rules={[{ required: true }]}
                normalize={noSpaces}
              >
                <Input disabled={loading} />
              </Form.Item>
            </>
          )}
          {/* {classifierParam && classifierParam !== '03' && (
            <Form.Item
              label="Periodo fiscal"
              name="periodo_fiscal"
              rules={[{ required: true }]}
            >
              <Select
                disabled={loading || (classifierParam && classifierParam === '02')}
                options={periodosFiscales}
                getLabel={(e) => e.periodo}
                onChange={(e) => setPeriodoFiscal(e)}
              />
            </Form.Item>
          )} */}
          <Row justify={[padronesEnum.CIUDADANO, padronesEnum.CONTRIBUYENTE]
            .includes(tipoDePadron) && !modal ? 'space-between' : 'end'}
          >
            {[padronesEnum.CIUDADANO, padronesEnum.CONTRIBUYENTE]
              .includes(tipoDePadron) && !modal && (
              <Col xs={24} sm={24} md={8}>
                <Form.Item>
                  <Button
                    block={!screens.md}
                    loading={loading}
                    onClick={() => {
                      setVisibleRegister(true);
                      form.setFieldsValue({ es_extranjero: false });
                      scrollToTop(screens);
                    }}
                    color={COLORS.accent}

                  >
                    Registrarse
                    <UserAddOutlined />
                  </Button>
                </Form.Item>
              </Col>
            )}
            <Col xs={24} sm={24} md={8} lg={8} style={{ textAlign: 'center' }}>
              <Form.Item>
                <Button
                  block={!screens.md}
                  htmlType="submit"
                  loading={loading}
                  $accent
                  style={{ float: 'right' }}
                >
                  Ingresar
                  <SearchOutlined />
                </Button>
              </Form.Item>
              <WaitingAlert visible={visibleAlert} />
            </Col>
          </Row>
          <Form.Item hidden>
            <Button htmlType="submit" />
          </Form.Item>
        </Form>
      )}
      <PadronInfo
        padron={padron}
        padrones={padrones}
        tipoDePadron={tipoDePadron}
        periodosFiscales={periodosFiscales}
        periodoFiscal={periodoFiscal}
        callback={(e) => {
          changePeriodoFiscal(e);
          setPeriodoFiscal(e?.id || periodosFiscales[0]?.id);
        }}
        allowChangePeriodo={classifierParam && classifierParam !== '03'}
      />
      {withGISInfo && padron && tipoDePadron === padronesEnum.PREDIO && !loading && (
        <>
          <br />
          {hasGISInfo && !visibleMap && (
            <GISInfoResult hasGISInfo onClick={() => setVisibleMap(true)} type="danger" />
          )}
          {!hasGISInfo && <GISInfoResult type="danger" departamento={departamento} />}
          {hasGISInfo && visibleMap && (
            <ArcGisMap
              clave_catastral_municipal={padron.clave_catastral_municipal}
              layersVisible={false}
              visible={visibleMap}
              takeScreenshot={false}
              width="50vw"
              heigth="50vw"
            />
          )}
        </>
      )}
      <ModalName
        visible={visibleModalName}
        setVisible={setVisibleModalName}
        callback={() => {
          onClickCobroSinRegistro();
        }}
        onCancel={() => {
          setVisibleModalName();
          if (localStorage.getItem('nameTemp')) {
            localStorage.removeItem('nameTemp');
          }
        }}
      />
    </Card>
  );
};

export default SearchForm;
