/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Form,
  Input,
  Grid,
  Tooltip,
  message,
  Col,
  Modal,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import {
  CloseCircleOutlined,
  CopyOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, COLORS, Title } from 'components';
import { normalizeValue } from 'utils/formatters';
import { capitalize } from 'utils/normalizers';
import Select from './Select';
import { defRender } from './PaginatedSelect';

const { confirm } = Modal;
const { Text } = Typography;

const InputSearch = ({
  label,
  name,
  dataSource,
  setDataSource,
  inputProps,
  disabled,
}) => {
  const ref = useRef();
  const [originalDataSource, setOriginalDataSource] = useState([]);

  useEffect(() => {
    if (!originalDataSource.length) {
      setOriginalDataSource(dataSource);
    }
  }, [dataSource]);

  const filter = (val) => {
    if (val) {
      setDataSource(
        dataSource.filter((e) => normalizeValue(e[name]).includes(normalizeValue(val))),
      );
    } else {
      setDataSource(originalDataSource);
    }
  };

  return (
    <Form
      layout="vertical"
      name={`search-by-${name}`}
      onValuesChange={(changed) => {
        clearTimeout(ref.current);
        ref.current = setTimeout(() => filter(changed[name]), 500);
      }}
    >
      <Title level={4} style={{ textAlign: 'center' }}>
        {label}
      </Title>
      <Form.Item name={name} style={{ margin: 0 }}>
        <Input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...inputProps}
          disabled={disabled}
          suffix={(
            <Button
              disabled={disabled}
              type="link"
              color={COLORS.accent}
              htmlType="submit"
            >
              <SearchOutlined />
            </Button>
          )}
        />
      </Form.Item>
    </Form>
  );
};

InputSearch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  dataSource: PropTypes.array,
  setDataSource: PropTypes.func.isRequired,
  inputProps: PropTypes.instanceOf(Input.propTypes),
  disabled: PropTypes.bool,
};

InputSearch.defaultProps = {
  label: 'Búsqueda',
  dataSource: [],
  inputProps: Input.defaultProps,
  disabled: false,
};

const defBreakpoints = {
  xs: 24,
  sm: 24,
  md: 8,
};

const getBreakPoints = (resultProps, breakPoints) => {
  if (
    Array.isArray(resultProps.dataSource)
      ? resultProps.dataSource.length
      : resultProps.dataSource
  ) {
    return { ...defBreakpoints, ...resultProps.breakPoints } || defBreakpoints;
  }
  return { ...defBreakpoints, ...breakPoints };
};

const showConfirm = (e, resultProps) => {
  confirm({
    title: `¿Está seguro que desea retirar ${resultProps.name}?`,
    icon: <ExclamationCircleOutlined />,
    content: resultProps.contentConfirm,
    onOk() {
      message.success('Se ha retiró correctamente');
      e.stopPropagation();
      resultProps.onClearConfirm(e);
    },
    onCancel() {
      // console.log('Cancel');
    },
  });
};

export const InputSearchPro = ({
  disabled,
  label,
  name,
  rules,
  onClickAdvanced,
  onPressEnter,
  inputProps,
  prefix,
  tooltip,
  resultProps,
  breakPoints,
  order,
  copyableResult,
  advancedComponent: AdvancedSearch,
  advancedComponentProps,
  getter,
  disabledOnResult,
  qName,
}) => {
  const form = Form.useFormInstance();
  const screens = Grid.useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [visibleAdvanced, setVisibleAdvanced] = useState(false);

  const showResult = Array.isArray(resultProps.dataSource)
    ? !!resultProps.dataSource.length
    : !!resultProps.dataSource;
  const searchBreakpoints = !showResult
    ? getBreakPoints(resultProps, breakPoints)
    : { span: 0 };
  const resultBreakpoints = showResult
    ? getBreakPoints(resultProps, breakPoints)
    : { span: 0 };

  const Prefix = () => !!prefix && (
  <Form.Item
    name={prefix.name}
    style={{
      ...prefix.style,
      width: !screens.md ? '100%' : prefix?.style?.width,
    }}
    rules={prefix.rules}
    label={prefix.label}
    hasFeedback
  >
    <Select
      style={{ width: !screens.md ? '100%' : prefix?.style?.width }}
      dataSource={prefix.dataSource}
      render={prefix.render}
      onChange={prefix.onChange}
      disabled={disabled || prefix.disabled}
      allowClear={prefix.allowClear}
    />
  </Form.Item>
  );

  const SearchBox = () => (
    <Form.Item
      style={{ width: '100%' }}
      name={name}
      normalize={(value) => {
        if (typeof inputProps?.normalize === 'function') {
          return inputProps.normalize(value);
        }
        return capitalize(value);
      }}
      label={(
        <span>
          {label}
          {tooltip && (
            <>
              {' '}
              <Tooltip title={tooltip}>
                <QuestionCircleOutlined />
              </Tooltip>
            </>
          )}
        </span>
      )}
      rules={showResult ? [] : rules}
      hasFeedback
    >
      <Input
        allowClear
        {...inputProps}
        onPressEnter={(e) => {
          e.preventDefault();
          if (inputProps.onPressEnter) {
            inputProps.onPressEnter(name);
          }
        }}
        disabled={disabled || inputProps.disabled || loading}
      />
    </Form.Item>
  );

  const onSelectItem = (record) => {
    form.setFieldsValue({
      [resultProps.name]: record.id,
      [name]: null,
    });
    if (resultProps.setDataSource) {
      resultProps.setDataSource(record);
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    const q = form.getFieldValue(name);
    if (q) {
      const params = {
        ...advancedComponentProps?.extraParams,
        [qName || resultProps.labelProp]: q,
      };
      const data = await getter(params);
      if ((data.results || data).length === 1) {
        const [selected] = data.results || data;
        onSelectItem(selected);
      } else {
        message.info(`No se encontró ${resultProps.label}`);
      }
    }
    setLoading(false);
  };

  const SearchButton = () => (
    <Form.Item
      style={{ display: 'flex', alignItems: 'flex-end' }}
    >
      <Button
        disabled={disabled || loading}
        onClick={() => {
          if (getter) {
            handleSearch();
          }
          if (onPressEnter) {
            onPressEnter(name);
          }
          if (inputProps.onPressEnter) {
            inputProps.onPressEnter(name);
          }
        }}
      >
        <SearchOutlined />
      </Button>
    </Form.Item>
  );

  const AdvancedButton = () => (!!onClickAdvanced || AdvancedSearch) && (
  <Form.Item
    style={{ display: 'flex', alignItems: 'flex-end' }}
    label=""
  >
    <Button
      onClick={() => {
        if (onClickAdvanced) {
          onClickAdvanced();
        }
        if (AdvancedSearch) {
          setVisibleAdvanced(true);
        }
      }}
      disabled={disabled || loading}
      style={{ width: 48 }}
    >
      <Tooltip title="Búsqueda avanzada">
        <Text>...</Text>
      </Tooltip>
    </Button>
  </Form.Item>
  );

  const keyLabelRenderFunc = (item) => `${item[resultProps.keyProp]} - ${item[resultProps.labelProp]}`;

  const getText = (obj) => {
    if (resultProps.keyLabelRender) {
      return keyLabelRenderFunc(obj);
    }
    if (resultProps.render) {
      return resultProps.render(obj);
    }
    if (resultProps.labelProp) {
      return defRender(obj[resultProps.labelProp]);
    }
    return defRender(obj);
  };

  return (
    <>
      <Col {...resultBreakpoints} order={order}>
        <FormItemRelative>
          <Form.Item
            name={resultProps.name}
            label={resultProps.label}
            rules={showResult ? resultProps.rules : []}
          >
            <Select
              dataSource={
                Array.isArray(resultProps.dataSource)
                  ? resultProps.dataSource
                  : [resultProps.dataSource]
              }
              keyLabelRender={!!resultProps.keyLabelRender}
              keyProp={resultProps.keyProp}
              labelProp={resultProps.labelProp}
              render={resultProps.render}
              async={resultProps.async}
              onChange={resultProps.onChange}
              disabled={disabledOnResult}
              allowClear={false}
              showArrow={false}
            />
          </Form.Item>
          {resultProps.onClearConfirm && (
            <CloseCircleOutlined
              onClick={(e) => showConfirm(e, resultProps, name)}
            />
          )}
          {resultProps.onClear && (
            <CloseCircleOutlined
              onClick={(e) => {
                e.stopPropagation();
                resultProps.onClear(e);
              }}
            />
          )}

          {copyableResult && (
            <CopyOutlined
              onClick={(e) => {
                e.stopPropagation();
                const obj = Array.isArray(resultProps.dataSource)
                  ? resultProps.dataSource[0]
                  : resultProps.dataSource;
                const txt = getText(obj);
                navigator.clipboard.writeText(txt);
                message.info('Texto copiado');
              }}
              style={{ right: resultProps.onClear || resultProps.onClearConfirm ? 30 : 5 }}
            />
          )}
        </FormItemRelative>
      </Col>
      <Col {...searchBreakpoints} order={order}>
        {screens.xs ? (
          <Form.Item noStyle>
            <Prefix />
            <Input.Group style={{ display: 'flex' }} compact>
              <SearchBox />
              <SearchButton />
              <AdvancedButton />
            </Input.Group>
          </Form.Item>
        ) : (
          <Input.Group style={{ display: 'flex' }} compact>
            <Prefix />
            <SearchBox />
            <SearchButton />
            <AdvancedButton />
          </Input.Group>
        )}
      </Col>
      {AdvancedSearch && (
        <AdvancedSearch
          visible={visibleAdvanced}
          setVisible={(_visible) => {
            setVisibleAdvanced(_visible);
          }}
          callback={(record) => {
            if (resultProps.setDataSource) {
              onSelectItem(record);
            }
          }}
          {...advancedComponentProps}
        />
      )}
    </>
  );
};

const FormItemRelative = styled.div`
  position: relative;
  width: 100%;
  .anticon-close-circle,
  .anticon-copy {
    position: absolute;
    top: 34px;
    z-index: 2;
  }
  .anticon-close-circle {
    right: 10px;
  }
`;

InputSearchPro.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  rules: PropTypes.arrayOf(PropTypes.shape({})),
  onClickAdvanced: PropTypes.func,
  onPressEnter: PropTypes.func,
  inputProps: PropTypes.shape({
    disabled: PropTypes.bool,
    normalize: PropTypes.func,
    onPressEnter: PropTypes.func,
  }),
  prefix: PropTypes.shape({
    disabled: PropTypes.bool,
    allowClear: PropTypes.bool,
    render: PropTypes.func,
    onChange: PropTypes.func,
    name: PropTypes.string,
    rules: PropTypes.shape({}),
    label: PropTypes.string,
    dataSource: PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.arrayOf(PropTypes.shape({})),
    ]),
    style: PropTypes.shape({
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  resultProps: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape),
    PropTypes.shape({
      count: PropTypes.number,
      name: PropTypes.string,
    }),
  ]),
  breakPoints: PropTypes.shape({}),
  order: PropTypes.number,
  copyableResult: PropTypes.bool,
  advancedComponent: PropTypes.func,
  getter: PropTypes.func,
  advancedComponentProps: PropTypes.shape({
    extraParams: PropTypes.shape({}),
  }),
  disabledOnResult: PropTypes.bool,
  qName: PropTypes.string,
};

InputSearchPro.defaultProps = {
  disabled: false,
  label: null,
  name: 'q',
  onClickAdvanced: null,
  rules: [],
  onPressEnter: () => {},
  inputProps: {},
  prefix: null,
  tooltip: null,
  resultProps: null,
  breakPoints: {},
  order: null,
  copyableResult: true,
  advancedComponent: null,
  getter: null,
  advancedComponentProps: null,
  disabledOnResult: true,
  qName: null,
};

export default InputSearch;
