import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Form } from 'antd';
import { getCodigosPostales, getTiposDeVialidades } from 'api/catalogos';
import _logger from 'utils/logger';
import FormItemsDireccion from './FormItemsDireccion';

export const formatAddressValues = (dir) => {
  const values = {};
  Object.keys(dir).forEach((key) => {
    const val = dir[key];
    values[key] = val && val !== 'N/A' ? val : undefined;
  });
  return values;
};

const Direccion = ({
  isEditing,
  propName,
  fatherObject,
  setLoading,
  altPropName,
}) => {
  const form = Form.useFormInstance();
  const [colonias, setColonias] = useState([]);
  const [vialidades, setVialidades] = useState([]);

  const onSearchCodigoPostal = async (d_codigo, validate = true) => {
    try {
      setLoading(true);
      if (validate) {
        await form.validateFields(['codigo_postal']);
      }
      const _colonias = await getCodigosPostales({ d_codigo });
      const municipio = _colonias[0]?.municipio.nom_mun;
      const estado = _colonias[0]?.municipio.estado.nombre_de_AGEE;
      setColonias(_colonias);
      const values = {
        municipio,
        estado,
        pais: 'México',
      };
      if (_colonias.length === 1) {
        values.colonia = _colonias[0].id;
      } else if (validate) {
        form.resetFields(['colonia']);
      }
      setTimeout(() => {
        form.setFieldsValue(values);
      });
    } catch (error) {
      _logger(error);
    } finally {
      setLoading(false);
    }
  };

  const setDireccion = async (_propName = propName) => {
    if (fatherObject?.[_propName]) {
      const direction = formatAddressValues(fatherObject[_propName]);
      const municipio = direction.codigo_postal?.municipio?.nom_mun;
      const codigo_postal = direction.codigo_postal?.d_codigo;
      await onSearchCodigoPostal(codigo_postal, false);
      form.setFieldsValue({
        ...direction,
        municipio,
        codigo_postal,
        colonia: direction.codigo_postal.id,
        pais: direction.codigo_postal?.municipio?.estado?.pais?.descripcion,
      });
    } else {
      form.resetFields();
    }
  };

  useEffect(() => {
    let isMounted = true;
    setDireccion();
    const fetchData = async () => {
      const _vialidades = await getTiposDeVialidades();
      if (isMounted) {
        setVialidades(_vialidades);
      }
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  const rules = {
    required: [
      {
        required: true,
      },
    ],
    codigo_postal: [
      {
        required: true,
        message: 'El código postal es requerido',
      },
      {
        pattern: /^[0-9]{5}$/,
        message: 'El código postal debe ser de 5 dígitos',
      },
    ],
  };

  const onClickCopy = () => setDireccion(altPropName);

  return (
    <FormItemsDireccion
      isEditing={isEditing}
      onSearchCodigoPostal={onSearchCodigoPostal}
      rules={rules}
      vialidades={vialidades}
      colonias={colonias}
      onClickCopy={altPropName ? onClickCopy : null}
    />
  );
};

Direccion.propTypes = {
  isEditing: propTypes.bool,
  propName: propTypes.string.isRequired,
  altPropName: propTypes.string,
  fatherObject: propTypes.shape({}).isRequired,
  setLoading: propTypes.func.isRequired,
};

Direccion.defaultProps = {
  isEditing: false,
  altPropName: null,
};

export default Direccion;
