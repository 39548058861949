import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Button,
  Spin,
  Col,
  Modal,
  Row,
  DatePicker,
  Input,
  Table,
} from 'antd';
import Select from 'components/Select';
import FormSubmitCustomControls from 'components/FormSubmitCustomControls';
import {
  deleteActividadEconomicaDeContribuyente,
  getActividadesEconomicasDeContribuyente,
  patchActividadEconomicaDeContribuyente,
  postActividadEconomicaDeContribuyente,
} from 'api/empresas/actividadesEconomicasContribuyente';
import {
  deleteActividadDeContribuyente,
  getActividadesDeContribuyente,
  patchActividadDeContribuyente,
  postActividadDeContribuyente,
} from 'api/empresas/actividadesContribuyente';
import { getActividadEconomica, getActividadesEconomicas, getCatalogoActividades } from 'api/empresas';
import _logger from 'utils/logger';
import { DATE_FRONTEND_FORMAT } from 'utils/formatters';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import ModalDelete from 'components/ModalDelete';
import { decimalNormalizer, toMoment } from 'utils/normalizers';

const ActividadesEconomicas = ({
  contribuyente,
  sat,
  mutable,
  fetchCallback,
  callback,
}) => {
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [actividades, setActividades] = useState({ results: [] });
  const [data, setData] = useState([]);
  const selectedActividad = data.find((e) => e.id === selectedRowKeys[0]);

  const fetchData = async () => {
    setLoading(true);
    const getter = sat ? getActividadesEconomicasDeContribuyente : getActividadesDeContribuyente;
    const _data = await getter({ contribuyente });
    if (fetchCallback) fetchCallback(_data);
    setData(_data);
    setLoading(false);
  };

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      setLoading(true);
      const _actividades = await (!sat ? getCatalogoActividades : getActividadesEconomicas)();
      await fetchData();
      if (mounted) {
        setActividades(_actividades);
        setLoading(false);
      }
    };
    fetch();
    return () => { mounted = false; };
  }, []);

  const fillActividad = async (act) => {
    if (act?.sub_grupo?.descripcion && act.sub_grupo?.grupo?.descripcion) {
      form.setFieldsValue({
        actividad_economica: act.id,
        sub_grupo: act.sub_grupo?.descripcion,
        grupo: act.sub_grupo?.grupo?.descripcion,
      });
      return;
    }
    setLoading(true);
    const actividad = await getActividadEconomica(act?.id || act);
    if (actividad) {
      form.setFieldsValue({
        actividad_economica: actividad.id,
        sub_grupo: actividad?.sub_grupo?.descripcion,
        grupo: actividad?.sub_grupo?.grupo?.descripcion,
      });
    }
    setLoading(false);
  };

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      const [key] = selectedRowKeys;
      values.contribuyente = contribuyente;
      let saved = null;
      if (sat) {
        // PERTENECE A SAT
        saved = await (key
          ? patchActividadEconomicaDeContribuyente(key, values)
          : postActividadEconomicaDeContribuyente(values));
      } else {
        // PERTENECE A CATALOGO DE LA ENTIDAD
        saved = await (key
          ? patchActividadDeContribuyente(key, values)
          : postActividadDeContribuyente(values));
      }
      if (saved) {
        if (callback) {
          const _actividad = sat ? saved.actividad_economica : saved.actividad;
          const actividad = _actividad?.descripcion || actividades
            .find((a) => a.id === saved[sat ? 'actividad_economica' : ' actividad']).descripcion;
          saved.message = `Actividad ${actividad} ${key ? 'agregada' : 'editada'}`;
          callback(saved);
        }
        onCancel();
        await fetchData();
      }
    } catch (err) {
      _logger(err);
    }
    setLoading(false);
  };

  const onClickEdit = async () => {
    if (sat) await fillActividad(selectedActividad);
    setVisible(true);
  };

  const onClickAdd = () => {
    onCancel();
    setVisible(true);
  };

  const onClickDelete = () => {
    setVisibleAlert(true);
  };

  const deleteItem = async () => {
    setLoading(true);
    const [key] = selectedRowKeys;
    let deleted = null;
    if (sat) {
      deleted = await deleteActividadEconomicaDeContribuyente(key);
    } else {
      deleted = await deleteActividadDeContribuyente(key);
    }
    if (deleted) {
      if (callback) {
        const match = data.find((e) => e.id === key);
        const actividad = (actividades.results || actividades)
          .find((a) => a.id === match.actividad_economica);
        callback({ message: `Actividad ${actividad.descripcion} eliminada` });
      }
      setVisibleAlert(false);
      onCancel();
      await fetchData();
    }
    setLoading(false);
  };

  const rowSelection = mutable ? {
    selectedRowKeys,
    type: 'radio',
  } : null;

  const columns = [
    {
      title: 'Actividad Económica',
      key: `${sat ? 'actividad_economica' : 'actividad'}`,
      dataIndex: `${sat ? 'actividad_economica' : 'actividad'}`,
      width: 300,
      render: (val) => `${val?.actividad?.descripcion || val?.descripcion}`,
    },
    {
      title: 'Fecha Inicio',
      key: 'fecha_inicio',
      dataIndex: 'fecha_inicio',
      width: 100,
      render: (val) => val?.format(),
      responsive: ['md'],
    },
    {
      title: 'Porcentaje',
      key: 'porcentaje_de_actividad',
      dataIndex: 'porcentaje_de_actividad',
      width: 100,
      render: (val) => `${val}%`,
      responsive: ['md'],
    },
  ];

  const rules = {
    required: [{ required: true }],
    porcentaje: [
      { required: true },
      {
        validator: async (_, _val) => {
          const val = parseFloat(_val);
          if (val) {
            if (val > 100) {
              throw new Error('No se puede superar el 100%');
            }
            const sum = data.reduce((acum, curr) => acum + curr.porcentaje_de_actividad, 0)
              - (selectedActividad?.porcentaje_de_actividad || 0) + val;
            if (sum > 100) {
              throw new Error('La suma de los porcentajes no puede superar el 100%');
            }
          }
        },
      },

    ],
  };

  const onRow = (record) => ({
    onClick: () => {
      setSelectedRowKeys([record.id]);
      form.setFieldsValue(record);
      if (!sat) form.setFieldsValue({ actividad: record?.actividad?.id });
    },
    onDoubleClick: () => onClickEdit(),
  });

  const onSelect = (act) => {
    if (!sat) return;
    fillActividad(actividades.find((a) => a.id === act));
  };

  return (
    <Spin key={sat ? 'economicas sat' : 'economicas a secas'} tip="Cargando..." spinning={loading}>
      <Col span={24}>
        {mutable && (
          <FormSubmitCustomControls
            controls={[
              {
                text: 'Agregar',
                icon: <PlusOutlined />,
                onClick: () => onClickAdd(),
              },
              {
                text: 'Editar',
                icon: <EditOutlined />,
                onClick: () => onClickEdit(),
                disabled: !selectedRowKeys.length,
              },
              {
                text: 'Eliminar',
                icon: <DeleteOutlined />,
                onClick: () => onClickDelete(),
                disabled: !selectedRowKeys.length,
              },
            ]}
            style={{ width: '100%' }}
            justify="end"
          />
        )}
        <Table
          columns={columns}
          dataSource={data}
          rowSelection={rowSelection}
          onRow={onRow}
          rowKey="id"
          scroll={{
            y: 600,
            x: columns.reduce((acum, curr) => acum + curr.width, 0),
            scrollToFirstRowOnChange: true,
          }}
        />
      </Col>
      <Modal
        visible={visible}
        closable={false}
        footer={null}
        title={(
          <FormSubmitCustomControls
            label={`${selectedRowKeys.length ? 'Editar' : 'Agregar'} Actividad`}
            onFinish={onFinish}
            onCancel={onCancel}
            loading={loading}
          />
        )}
        keyboard={!loading}
        maskClosable={!loading}
        onCancel={onCancel}
      >
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={() => onFinish()}
          >
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item
                  name={sat ? 'actividad_economica' : 'actividad'}
                  label="Actividad"
                  rules={rules.required}
                >
                  <Select options={actividades} onSelect={onSelect} />
                </Form.Item>
              </Col>
              {sat && (
                <>
                  <Col span={24}>
                    <Form.Item
                      name="grupo"
                      label="Grupo"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="sub_grupo"
                      label="Subgrupo"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col span={24}>
                <Form.Item
                  name="porcentaje_de_actividad"
                  label="Porcentaje de Actividad"
                  rules={rules.porcentaje}
                  normalize={decimalNormalizer}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="fecha_inicio"
                  label="Fecha de Inicio de la Actividad"
                  rules={rules.required}
                >
                  <DatePicker
                    disabled={!mutable}
                    placeholder=""
                    format={DATE_FRONTEND_FORMAT}
                    disabledDate={(d) => d.isAfter(toMoment(new Date()))}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="fecha_fin"
                  label="Fecha de Fin"
                >
                  <DatePicker
                    disabled={!mutable}
                    placeholder=""
                    format={DATE_FRONTEND_FORMAT}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Form>
        </Col>
      </Modal>
      <ModalDelete
        onDelete={deleteItem}
        onCancel={() => {
          setVisibleAlert(false);
          setSelectedRowKeys([]);
        }}
        visible={visibleAlert}
        content=" el registro seleccionado?"
        loading={loading}
      />
    </Spin>
  );
};

ActividadesEconomicas.propTypes = {
  contribuyente: PropTypes.number,
  permission: PropTypes.shape({
    add: PropTypes.bool.isRequired,
    change: PropTypes.bool.isRequired,
    delete: PropTypes.bool.isRequired,
    export: PropTypes.bool.isRequired,
    import: PropTypes.bool.isRequired,
    view: PropTypes.bool.isRequired,
  }).isRequired,
  sat: PropTypes.bool,
  mutable: PropTypes.bool,
  fetchCallback: PropTypes.func,
  callback: PropTypes.func,
};

ActividadesEconomicas.defaultProps = {
  contribuyente: null,
  sat: false,
  mutable: true,
  fetchCallback: null,
  callback: null,
};

export default ActividadesEconomicas;
