import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Col,
  Select,
} from 'antd';
import PropTypes from 'prop-types';
import { normalizeValue, toInteger } from 'utils/formatters';
import { useSelector } from 'react-redux';
import { selectLadas } from 'store/catalogos';

const LadaNumero = ({
  form: _form,
  names,
  disabled,
  rules: _rules,
  hasFeedback,
  required,
  label,
  order,
  fullSize,
  hidden,
  suffix,
  lg,
  md,
}) => {
  const form = _form || Form.useFormInstance();
  const _ladas = useSelector(selectLadas);
  const defaultBp = {
    xs: 24,
    sm: 24,
    md: md || 24,
    lg: lg || 16,
    xl: 8,
  };
  const bp = fullSize ? { span: 24 } : defaultBp;
  const [ladas, setLadas] = useState([]);

  useEffect(() => {
    const fetchLadas = async () => {
      setLadas(_ladas.map((e) => ({
        label: (
          <>
            {e.lada}
            <br />
            {e.pais}
          </>
        ),
        value: e.id,
      })));
      form.setFieldsValue({ [names.lada]: 34 });
    };
    fetchLadas();
  }, []);

  const rules = _rules || {
    [names.numero_de_telefono]: [
      {
        pattern: /^[0-9]{10}$/,
        message: 'Ingrese un número teléfonico válido',
      },
      { required },
      {
        validator: async () => {
          if (!required && form.getFieldValue(names.lada)) {
            throw new Error('El campo es requerido');
          }
        },
      },
    ],
    lada: [{
      required: required || form.getFieldValue(names.numero_de_telefono),
      message: 'Requerido',
    }],
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Col {...bp} order={order}>
      <Form.Item noStyle hidden={hidden}>
        <Input.Group className="select-input lada-num" style={{ display: 'flex' }} compact>
          <Form.Item
            rules={rules.lada}
            name={names.lada}
            hasFeedback={hasFeedback}
            label="Lada"
            style={{ width: 100 }}
            hidden={hidden}
          >
            <Select
              showSearch
              filterOption={(input, option) => {
                const normalzedInput = normalizeValue(input);
                const normalzedOption = normalizeValue(option?.label?.props?.children);
                return normalzedOption.indexOf(normalzedInput) >= 0;
              }}
              dropdownClassName="lada-dropdown wrap-text"
              disabled={disabled}
              options={ladas}
            />
          </Form.Item>
          <Form.Item
            label={label}
            name={names.numero_de_telefono}
            rules={rules[names.numero_de_telefono]}
            normalize={toInteger}
            style={{ width: 'calc(100% - 100px)' }}
            hidden={hidden}
          >
            <Input maxLength={10} allowClear disabled={disabled} suffix={suffix} />
          </Form.Item>
        </Input.Group>
      </Form.Item>
    </Col>
  );
};

LadaNumero.propTypes = {
  form: PropTypes.shape().isRequired,
  names: PropTypes.exact({
    lada: PropTypes.string,
    numero_de_telefono: PropTypes.string,
  }),
  tooltip: PropTypes.shape({
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.node,
    ]),
  }),
  disabled: PropTypes.bool,
  rules: PropTypes.shape(),
  hasFeedback: PropTypes.bool,
  required: PropTypes.bool,
  label: PropTypes.string,
  order: PropTypes.number,
  fullSize: PropTypes.bool,
  hidden: PropTypes.bool,
  suffix: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  md: PropTypes.oneOf([8, 16, 24]),
  lg: PropTypes.oneOf([8, 16, 24]),
};

LadaNumero.defaultProps = {
  names: {
    lada: 'lada',
    numero_de_telefono: 'numero_de_telefono',
  },
  disabled: false,
  rules: null,
  hasFeedback: true,
  required: true,
  tooltip: null,
  label: 'Número',
  order: null,
  fullSize: false,
  hidden: false,
  suffix: null,
  md: null,
  lg: null,
};

export default LadaNumero;
