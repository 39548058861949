import React from 'react';
import PropTypes from 'prop-types';

import {
  Form, Col, Row, Modal, Input, Divider,
} from 'antd';
import { Button } from 'components';
import LadaNumero from 'components/LadaNumero';
import { RFC_PATTERN, CURP_PATTERN } from 'utils/patterns';
import _logger from 'utils/logger';

const _rules = {
  required: (message) => ({

    message,
  }),
  numeric: [
    {
      validator: async (_, value) => {
        const regex = /^[0-9]+([.][0-9]{1,2})?$/;
        if (!value) {
          return Promise.resolve();
        }
        if (value?.split('.')?.[1]?.length > 2) {
          return Promise.reject(new Error('Solo se permiten hasta dos decimales (0.00).'));
        }
        if (!regex.test(value)) {
          return Promise.reject(new Error('Solo se permiten números.'));
        }
        return Promise.resolve();
      },
    },
  ],
  mayorCero: [
    {
      validator: async (_, value) => {
        if (value <= 0) {
          throw new Error('El valor debe ser mayor a 0.');
        }
      },
    },
  ],
  totalPorcentajeEnajenante: [
    {
      validator: async (_, value) => {
        if (value > 100) {
          throw new Error('El total de los 11 porcentajes no debe ser mayor a 100% (1).');
        }
        if (value < 100) {
          throw new Error('El total de los 11 porcentajes no debe ser menor a 100% (1).');
        }
      },
    },
  ],
  rfc: [{ pattern: RFC_PATTERN, message: ' Ingrese un RFC valido.' }],
  curp: [{ pattern: CURP_PATTERN, message: ' Ingrese una CURP valida.' }],
};

const ModalRegistralesService = ({ visible, setVisible, callback }) => {
  const [form] = Form.useForm();

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      callback(values);
    } catch (error) {
      _logger(error);
    }
  };

  return (
    <Modal
      title="Servicios Registrales"
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={
        [
          <Button key="back" onClick={() => setVisible(false)}>
            Cancelar
          </Button>,
          <Button key="submit" onClick={() => onFinish()} $accent>
            Generar orden de pago
          </Button>,
        ]
      }
      forceRender
      centered
      // onCancel={onCancel}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ modifier: 'public' }}
      >
        <Row justify="start" gutter={10}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="numero_de_instrumentro_escritura"
              label="Número de instrumentro escritura"
              hasFeedback
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="nombre_completo_del_notario"
              label="Nombre completo del notario"
              hasFeedback
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="rfc"
              label="RFC"
              hasFeedback
              rules={_rules.rfc}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="curp"
              label="CURP"
              hasFeedback
              rules={[..._rules.curp]}
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              name="nombre_completo_razon_social"
              label="Nombre completo o razón social"
              hasFeedback
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} />
          <Divider />
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="entidad_federativa"
              label="Entidad federativa"
              hasFeedback
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="municipio"
              label="Municipio"
              hasFeedback
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="localidad"
              label="Localidad"
              hasFeedback
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="codigo_postal"
              label="Código postal"
              hasFeedback
              rules={_rules.numeric}
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              name="colonia"
              label="Colonia"
              hasFeedback
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={16}>
            <Form.Item
              name="calle"
              label="Calle"
              hasFeedback
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              name="numero_y_letra_de_direccion"
              label="Número exterior"
              hasFeedback
              rules={_rules.numeric}
            >
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <LadaNumero
              form={form}
              hasFeedback
              required={false}
              names={{
                lada: 'lada',
                numero_de_telefono: 'telefono',
              }}
              label="Teléfono"
              fullSize
            />
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              name="observaciones_complemento"
              label="Observaciones"
              hasFeedback
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

ModalRegistralesService.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
};

export default ModalRegistralesService;
