/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  ConfigProvider,
  DatePicker,
  Input,
  Spin,
  message,
  Modal,
  Pagination,
} from 'antd';
import { CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import esES from 'antd/es/locale/es_ES';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import store from 'store/store';
import ErrorBoundary from 'components/ErrorBoundary';
import { DEV } from 'utils/env';
import Moment from 'moment';
import App from 'App';

const validateMessages = {
  required: 'El campo es requerido',
  types: {
    email: 'Ingrese un correo electrónico válido',
    integer: 'Ingrese un número enteró',
    url: 'Ingrese una URL válida',
    float: 'Ingrese un decimal válido',
  },
};

const autoComplete = DEV ? 'on' : 'off';
const persistor = persistStore(store);

Pagination.defaultProps = {
  ...Pagination.defaultProps,
  locale: {
    jump_to: 'Ir a página',
    page: '',
  },
};
Modal.defaultProps = {
  ...Modal.defaultProps,
  closeIcon: <CloseCircleOutlined />,
};

Spin.defaultProps = {
  indicator: <LoadingOutlined />,
  tip: 'Cargando',
};

Input.defaultProps = {
  allowClear: true,
};

Input.TextArea.defaultProps = {
  allowClear: true,
};

DatePicker.defaultProps = {
  format: 'DD-MM-YYYY',
  locale: esES,
  placeholder: '',
};

DatePicker.RangePicker.defaultProps = {
  format: 'DD-MM-YYYY',
  locale: esES,
  placeholder: '',
};

Moment.defaultFormatUtc = 'DD-MM-YYYY HH:mm:ss';
Moment.defaultFormat = 'DD-MM-YYYY';
Moment.locale('es', {
  months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
  monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sept_Oct_Nov_Dec'.split('_'),
  weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
  weekdaysShort: 'Dom_Lun_Mar_Mier_Jue_Vier_Sab'.split('_'),
  weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D [de] MMMM [de] YYYY',
    LLL: 'D [de] MMMM [de] YYYY HH:mm',
    LLLL: 'dddd, D [de] MMMM [de] YYYY HH:mm',
  },
  calendar: {
    sameDay() {
      return '[Hoy a] LT';
    },
    sameElse: 'L',
  },
  relativeTime: {
    future: 'en %s',
    past: 'hace %s',
    s: 'unos segundos',
    ss: '%d segundos',
    m: 'un minuto',
    mm: '%d minutos',
    h: 'una hora',
    hh: '%d horas',
    d: 'un día',
    dd: '%d días',
    M: 'un mes',
    MM: '%d meses',
    y: 'un año',
    yy: '%d años',
  },
  ordinalParse: /\d{1,2}º/,
  ordinal: '%dº',
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
});

message.config({
  top: 100,
  duration: 2.5,
  maxCount: 1,
});

document.title = process.env.REACT_APP_META_TITLE;

ReactDOM.createRoot(document.getElementById('root')).render(
  <ConfigProvider form={{ validateMessages }} input={{ autoComplete }} locale={esES}>
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </ConfigProvider>,
);
