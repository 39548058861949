import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Form,
  Row,
  message,
} from 'antd';
import { Button, Text, Title } from 'components';
import { DownloadOutlined } from '@ant-design/icons';
import Previewer from 'components/Previewer';
import { postObtenerDocumento } from 'api/reporteador';

const ConfirmarCita = ({ visible, cita, setLoading }) => {
  const form = Form.useFormInstance();
  const idAcuse = Form.useWatch('idAcuse', form);
  const [acuse, setAcuse] = useState();

  const obtenerAcuse = async () => {
    setLoading(true);
    const values = {
      ID: idAcuse,
      Parametros: [
        {
          Nombre: 'cita',
          TipoParametro: 'Texto',
          Valor: cita.id,
        },
      ],
    };
    const [err, _acuse] = await postObtenerDocumento(values);
    if (_acuse) {
      setAcuse(_acuse);
    } else {
      message.warn(err);
    }
    setLoading(false);
  };

  return (
    <>
      <Col span={visible ? 24 : 0}>
        <Row gutter={10} style={{ height: '100%', paddingBottom: 20 }}>
          <Col span={24} style={{ marginBottom: 30, marginTop: 30 }}>
            <Title $bold level={3}>
              Folio:
              {' '}
              {cita?.folio}
            </Title>
            <br />
            <Text>
              Su cita ha sido agendada correctamente
              {idAcuse && ', si desea descargar su acuse presione el siguiente botón'}
              .
            </Text>
            {idAcuse && (
              <Button onClick={obtenerAcuse} $accent style={{ float: 'right', marginRight: 20 }}>
                <Text>
                  Descargar acuse
                </Text>
                <DownloadOutlined />
              </Button>
            )}
          </Col>
          <Col span={24}>
            <Text>
              Se enviaron los detalles al correo electrónico proporcionado.
              <br />
              No olvides revisar la bandeja de
              {' '}
              <Text $accent strong>
                &quot;spam&quot;
              </Text>
              .
            </Text>
          </Col>
        </Row>
      </Col>
      <Previewer
        visible={!!acuse}
        onCancel={() => setAcuse()}
        base64={acuse}
      />
    </>
  );
};

ConfirmarCita.propTypes = {
  visible: PropTypes.bool,
  cita: PropTypes.shape({ id: PropTypes.number, folio: PropTypes.string }),
  setLoading: PropTypes.func.isRequired,
};

ConfirmarCita.defaultProps = {
  visible: false,
  cita: null,
};

export default ConfirmarCita;
