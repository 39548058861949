/* eslint-disable react/forbid-prop-types */
/* eslint-disable global-require */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { BarcodeOutlined } from '@ant-design/icons';
import {
  Modal,
  Card,
  Col,
  Row,
  Spin,
} from 'antd';
import {
  Title, Button, Text,
} from 'components';

import generateReferenceBank from 'api/referenciasDeBanco';
import { printReferenceOrder } from 'api/recaudacion';
import Previewer from 'components/Previewer';
import _logger from 'utils/logger';

/* eslint-disable import/no-dynamic-require */
const ENV_NAME = process.env.REACT_APP_ENV_NAME;

const { Meta } = Card;

const bancos = [
  // {
  //   id: 1,
  //   name: 'BancaNet Directo',
  //   descripcion: 'banamex',
  //   referencia: generateReferenceBank,
  //   instrucciones: 'AQUI LAS INSTRCCIONES DE BANAMEX',
  //   descripcion: 'BANAMEX',
  // },
  {
    id: 2,
    name: 'Multipagos BBVA',
    banco: 'bbva',
    referencia: generateReferenceBank,
    instrucciones: 'AQUI LAS INSTRCCIONES DE BBVA',
    descripcion: 'BANCOMER BBVA',
  },
  {
    id: 3,
    name: 'Micrositio de Pagos',
    banco: 'santander',
    referencia: generateReferenceBank,
    instrucciones: 'AQUI LAS INSTRCCIONES DE SANTANDER',
    descripcion: 'BANCO SANTANDER',
  },
  {
    id: 4,
    name: 'Otros bancos',
    banco: 'otros',
    referencia: generateReferenceBank,
    instrucciones: 'AQUI LAS INSTRCCIONES',
    descripcion: 'BANCO SANTANDER',
  },
];

const ModalBancos = ({
  visible,
  onCancel,
  cargosGenerados,
  padron,
  tipoDePadron,
  padrones,
  currentClassifier,
  cbReferenciaBanco,
  isNewFolio,
}) => {
  const formRef = useRef();
  const [url, setUrl] = useState();
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [previewBase64, setPreviewBase64] = useState();
  const [visibleInstrucciones, setVisibleInstrucciones] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (url) {
      setTimeout(() => {
        formRef.current.submit();
      }, 500);
    }
  }, [url]);

  // Functions
  const showOrder = async () => {
    try {
      setLoading(true);
      if (isNewFolio) {
        const request = await cbReferenciaBanco();
        if (request) {
          const values = {
            folio: request.folio_banco,
            entidad: 1,
            referencia_normal: false,
            base64: request.data,
          };
          const order = await printReferenceOrder(values);
          if (order) {
            setPreviewBase64(values.base64 || order?.data);
            setVisiblePreview(true);
          }
        }
      } else {
        setVisiblePreview(true);
      }
    } catch (error) {
      _logger(error);
    } finally {
      setLoading(false);
    }
  };

  const selectedBanco = async (banco, urlBanco, descripcion) => {
    try {
      setLoading(true);
      if (banco === 'otros') {
        setVisibleInstrucciones(true);
        return;
      }
      const result = await urlBanco[banco](
        tipoDePadron,
        padron,
        cargosGenerados,
        padrones,
        currentClassifier,
        descripcion,
      );
      if (result) setUrl(result);
    } catch (error) {
      _logger(error);
    } finally {
      setLoading(false);
    }
  };

  const showDialog = (values) => {
    Modal.info({
      title: 'Bancomer BBVA',
      footer: null,
      content: (
        <div>
          <Text>
            Favor de seleccionar la opcion de cheque en linea para poder procesar
            correctamente su pago.
          </Text>
        </div>
      ),
      onOk() {
        selectedBanco(values.banco, values.urlBanco, values.descripcion);
      },
    });
  };

  // Components
  const card = (name, banco, urlBanco, descripcion) => {
    const logo = require(`../../../../public/${ENV_NAME}/bancos/${banco}.png`).default;
    return (
      <Col key={name}>
        <Card
          key={name}
          hoverable
          onClick={() => {
            if (banco === 'bbva') {
              showDialog({
                name, banco, urlBanco, descripcion,
              });
              return;
            }
            selectedBanco(banco, urlBanco, descripcion);
          }}
          style={{
            width: 270,
            margin: 10,
            height: '85%',
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.3)',
            borderRadius: 8,
          }}
          cover={(
            <img
              alt={`banco-${name}`}
              src={logo}
              style={{ paddingInline: 40, paddingTop: 50, paddingBottom: 0 }}
            />
        )}
        >
          <Meta
            title={name}
            style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 20, fontWeight: 'bold',
            }}
          />
          {/* {
            banco === 'bbva' && (
            <Text
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: 20,
                fontSize: 16,
                textAlign: 'center',
              }}
            >
              Solo utilizar método de pago
              <p style={{ fontWeight: 700 }}> cheque en línea. </p>
            </Text>
            )
          } */}
        </Card>
      </Col>
    );
  };

  const stepsIntructions = (step, textStep) => (
    <Col sm={24} md={24}>
      <Text style={{ fontSize: 22, fontWeight: 'bold' }} $accent>{step}</Text>
      <Text style={{ fontSize: 18, fontWeight: 'bold', marginLeft: '15px' }}>{textStep}</Text>
    </Col>
  );
  return (
    <>
      <Modal
        style={{ top: 20 }}
        width={1200}
        visible={visible}
        onCancel={() => onCancel()}
        footer={null}
      >
        <Spin spinning={loading} tip="Cargando...">
          <Row gutter={[10, 15]} justify="center">
            <Title level={4} $accent style={{ fontWeight: 'bold' }}>
              SERVICIO DE PAGO CON TRANSFERENCIA BANCARIA
            </Title>
            <Col>
              <Title level={4} style={{ fontSize: 20 }}>
                Realizar tu transferencia bancaria en cualquiera de los siguientes bancos
              </Title>
            </Col>
            <Col span={24}>
              <Row gutter={[10, 10]} justify="center">
                {bancos.map((banco) => card(
                  banco.name,
                  banco.banco,
                  banco.referencia,
                  banco.descripcion,
                ))}
              </Row>
            </Col>
          </Row>
          <br />
        </Spin>
      </Modal>
      <Previewer
        visible={visiblePreview}
        base64={previewBase64}
        isPDF
        onCancel={() => setVisiblePreview(false)}
      />
      <Modal
        visible={visibleInstrucciones}
        footer={null}
        onCancel={() => setVisibleInstrucciones(false)}
        style={{ top: 20 }}
        width="60%"
      >
        <Spin spinning={loading} tip="Cargando...">
          <Title level={4} style={{ fontSize: 20 }} strong>INTRUCCIONES</Title>
          <br />
          <Row gutter={[10, 15]}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              style={{
                padding: '20px',
                backgroundColor: '#fff',
                borderRadius: '8px',
              }}
            >
              {stepsIntructions(1, 'Ingresa a tu banca en línea.')}
              {stepsIntructions(2, 'Por primera vez, realice la alta del beneficiario, de lo contrario, seleccione la clave interbancaria correspondiente.')}
              {stepsIntructions(3, 'Realiza tu transferencia interbancaria con los siguientes datos:')}
              <br />
              <Text style={{ fontSize: 18, fontWeight: 'bold', display: 'inline-block' }}>Banco destino: BBVA</Text>
              <br />
              <Text strong>Pasos a seguir para generar su pago interbancario</Text>
              <br />
              <br />
              <Text strong>1.- Dar click en generar orden de transferencia interbacaria</Text>
              <br />
              <br />
              <Text strong>
                2.- A continuación, aparecera en la parte inferior del formato el
                logotipo de BBVA donde viene la clabe interbancaria de 18 dígitos y abajo
                la referencia de 20 dígitos con los cuales podra realizar su pago en cualquier
                banco.
              </Text>
              <br />
              <br />
              <Text strong>
                3.- Imprima o descargue el formato de pago interbancario.
              </Text>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12}>
              <div style={{
                padding: '20px', backgroundColor: '#681B2D', borderRadius: '8px', border: '1px solid #fff', height: '100%',
              }}
              >
                <Text style={{ fontSize: 20, fontWeight: 'bold', color: '#fff' }}>Información para la alta del beneficiario</Text>
                <br />
                <br />
                <Text strong style={{ color: '#fff' }}>Nombre</Text>
                <br />
                <Text style={{ color: '#fff' }}>SECRETARíA DE ADMINISTRACIÓN Y FINANZAS.</Text>
                <br />
                <br />
                <Text strong style={{ color: '#fff' }}>RFC</Text>
                <br />
                <Text style={{ color: '#fff' }}>SADO91223KK7</Text>
                <br />
                <br />
                <Text strong style={{ color: '#fff' }}>Teléfono</Text>
                <br />
                <Text style={{ color: '#fff' }}>311 215 22 00</Text>
                <br />
                <br />
                <Text strong style={{ color: '#fff' }}>Domicilio</Text>
                <br />
                <Text style={{ color: '#fff' }}>
                  AV. MEXICO SIN NUMERO, COL. CENTRO, TEPIC, NAYARIT C.P. 63000.
                </Text>
              </div>
            </Col>
          </Row>
          <br />
          <br />
          <Button
            $accent
            onClick={() => showOrder().then(() => setVisibleInstrucciones(false))}
            $block
          >
            <Text>Generar orden de transferencia interbancaria</Text>
            <BarcodeOutlined />
          </Button>
        </Spin>
      </Modal>
      <Col span={0}>
        <form method="post" action={url} target="_top" ref={formRef} />
      </Col>
    </>
  );
};

export default ModalBancos;

ModalBancos.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  cargosGenerados: PropTypes.array,
  padron: PropTypes.object,
  currentClassifier: PropTypes.string,
  tipoDePadron: PropTypes.number,
  padrones: PropTypes.array,
  cbReferenciaBanco: PropTypes.func.isRequired,
  isNewFolio: PropTypes.bool,
};

ModalBancos.defaultProps = {
  onCancel: null,
  cargosGenerados: [],
  padron: [],
  currentClassifier: '',
  tipoDePadron: [],
  padrones: [],
  isNewFolio: false,
};
