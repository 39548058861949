import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Row,
  Form,
  Col,
  Input,
  Button as AntButton,
  Divider,
  Grid,
} from 'antd';
import { Button, Text } from 'components';
import { getTiposDeRetenciones, postDeclaracionCRIE } from 'api/recaudacion';
import { CloseOutlined, SyncOutlined } from '@ant-design/icons';
import { RFC_VALIDATOR, CURP_VALIDATOR } from 'utils/patterns';
import _logger from 'utils/logger';
import Select from './PaginatedSelect';

const { useBreakpoint } = Grid;

const tiposDePersonasEnum = Object.freeze({
  FISICA: 1,
  MORAL: 2,
});

const requiredRule = { required: true };

const numberValidator = async (_, value) => {
  const regex = /^[0-9]+([.][0-9]{1,2})?$/;
  if (value) {
    if (value?.split('.')?.[1]?.length > 2) {
      throw new Error('Solo se permiten hasta dos decimales (0.00)');
    }
    if (!regex.test(value)) {
      throw new Error('Solo se permiten números');
    }
  }
};

const _rules = {
  numericRequired: [
    requiredRule,
    { validator: numberValidator },
  ],
  numeric: [
    { validator: numberValidator },
  ],
};

const tiposDeRetencionesType = {
  tiposDeRetenciones: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    descripcion: PropTypes.string,
    clave: PropTypes.string,
  })).isRequired,
};

const onChangeTipoDePersona = (value, set) => set(value
  ? tiposDePersonasEnum.FISICA : tiposDePersonasEnum.MORAL);

const getMaxLength = (tipoDePersona) => (tipoDePersona === tiposDePersonasEnum.FISICA ? 13 : 12);

const TerceroSection = () => {
  const form = Form.useFormInstance();
  const [tipoDePersona, setTipoDePersona] = useState(tiposDePersonasEnum.FISICA);
  const rules = {
    rfc: [
      requiredRule,
      RFC_VALIDATOR(tipoDePersona),
    ],
    curp: [
      CURP_VALIDATOR(tipoDePersona),
    ],
  };

  const handleOnChange = async (value) => {
    onChangeTipoDePersona(value, setTipoDePersona);
    await form.validateFields(['rfc_de_tercero', 'curp_de_tercero']);
  };

  const setRazonSocial = () => {
    const {
      nombre_de_tercero,
      apellido_paterno_de_tercero,
      apellido_materno_de_tercero,
    } = form.getFieldsValue();
    const razon_social_nombre_completo_de_tercero = `${nombre_de_tercero
    || ''} ${apellido_paterno_de_tercero
    || ''} ${apellido_materno_de_tercero || ''}`.trim();
    form.setFieldsValue({ razon_social_nombre_completo_de_tercero });
  };

  return (
    <>
      <Divider orientation="left">
        <Text $accent>
          Datos De Identificación Del Tercero
        </Text>
      </Divider>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={6}>
          <Form.Item
            name="tercero_es_persona_fisica"
            label="¿Es persona física?"
            hasFeedback
          >
            <Select
              trueFalse
              onChange={handleOnChange}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item
            name="rfc_de_tercero"
            label="RFC"
            hasFeedback
            rules={rules.rfc}
            normalize={(value) => value.toUpperCase()}
          >
            <Input allowClear maxLength={getMaxLength(tipoDePersona)} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item
            name="curp_de_tercero"
            label="CURP"
            hasFeedback
            rules={rules.curp}
            normalize={(value) => value.toUpperCase()}
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        {tipoDePersona === tiposDePersonasEnum.FISICA && (
        <>
          <Col xs={24} sm={24} md={6}>
            <Form.Item
              name="nombre_de_tercero"
              label="Nombre"
              hasFeedback
            >
              <Input allowClear onChange={setRazonSocial} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <Form.Item
              name="apellido_paterno_de_tercero"
              label="Apellido Paterno"
              hasFeedback
            >
              <Input allowClear onChange={setRazonSocial} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={6}>
            <Form.Item
              name="apellido_materno_de_tercero"
              label="Apellido Materno"
              hasFeedback
            >
              <Input allowClear onChange={setRazonSocial} />
            </Form.Item>
          </Col>
        </>
        )}
        <Col xs={24} sm={24} md={6}>
          <Form.Item
            name="razon_social_nombre_completo_de_tercero"
            label="Razón Social/Nombre Completo"
            hasFeedback
          >
            <Input disabled={tipoDePersona === tiposDePersonasEnum.FISICA} allowClear />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

const PagosRetencionesSection = ({ tiposDeRetenciones }) => (
  <>
    <Divider orientation="left">
      <Text $accent>
        Pagos Y Retenciones
      </Text>
    </Divider>
    <Row gutter={10}>
      <Col xs={24} sm={24} md={6}>
        <Form.Item
          name="porcentaje_de_rentencion_de_pago"
          label="Porcentaje de Retención"
          hasFeedback
          rules={_rules.numeric}
        >
          <Input allowClear suffix="%" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={6}>
        <Form.Item
          name="impuesto_retenido_de_pago"
          label="Impuesto Retenido"
          hasFeedback
          rules={_rules.numericRequired}
        >
          <Input allowClear prefix="$" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={6}>
        <Form.Item
          name="pago_retenido"
          label="Tipo de Retención"
          hasFeedback
        >
          <Select dataSource={tiposDeRetenciones} keyLabelRender />
        </Form.Item>
      </Col>
    </Row>
  </>
);

PagosRetencionesSection.propTypes = tiposDeRetencionesType;

const OtrosPagosRetencionesSection = ({ tiposDeRetenciones }) => (
  <>
    <Divider orientation="left">
      <Text $accent>
        Otros Pagos Y Retenciones
      </Text>
    </Divider>
    <Row gutter={10}>
      <Col xs={24} sm={24} md={6}>
        <Form.Item
          name="porcentaje_de_retencion_de_otros_pagos"
          label="Porcentaje de Retención"
          hasFeedback
          rules={_rules.numeric}
        >
          <Input allowClear suffix="%" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={6}>
        <Form.Item
          name="pagos_provisionales_por_fiducuaria_de_otros_pagos"
          label="Pagos Provisionales por Fiducuaria"
          hasFeedback
          rules={_rules.numeric}
        >
          <Input allowClear />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={6}>
        <Form.Item
          name="deduciones_correspondientes_de_otros_pagos"
          label="Deduciones Correspondientes"
          hasFeedback
          rules={_rules.numeric}
        >
          <Input allowClear />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={6}>
        <Form.Item
          name="monto_de_operacion_actividad_gravada_de_otros_pagos"
          label="Monto de Operación/Actividad Gravada"
          hasFeedback
          rules={_rules.numeric}
        >
          <Input allowClear prefix="$" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={6}>
        <Form.Item
          name="monto_de_operacion_o_activadad_exenta_de_otros_pago"
          label="Monto de Operación/Actividad Exenta"
          hasFeedback
          rules={_rules.numeric}
        >
          <Input allowClear prefix="$" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={6}>
        <Form.Item
          name="impuestos_retenido_de_otros_pagos"
          label="Impuestos Retenido"
          hasFeedback
          rules={_rules.numeric}
        >
          <Input allowClear prefix="$" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={6}>
        <Form.Item
          name="otro_pago_retenido"
          label="Tipo de Retención"
          hasFeedback
        >
          <Select dataSource={tiposDeRetenciones} keyLabelRender />
        </Form.Item>
      </Col>
    </Row>
  </>
);

OtrosPagosRetencionesSection.propTypes = tiposDeRetencionesType;

const RetencionesSection = () => {
  const form = Form.useFormInstance();
  const [tipoDePersona, setTipoDePersona] = useState(tiposDePersonasEnum.FISICA);
  // const rules = {
  //   rfc: [
  //     requiredRule,
  //     RFC_VALIDATOR(tipoDePersona),
  //   ],
  //   curp: [
  //     CURP_VALIDATOR(tipoDePersona),
  //   ],
  // };

  const handleOnChange = async (value) => {
    onChangeTipoDePersona(value, setTipoDePersona);
    await form.validateFields([
      'rfc_de_representante_de_retenedor',
      'curp_de_representante_de_retenedor',
    ]);
  };

  return (
    <>
      <Divider orientation="left">
        <Text $accent>
          Datos Del Retenedor
        </Text>
      </Divider>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={6}>
          <Form.Item
            name="retenedor_es_persona_fisica"
            label="¿Es persona física?"
          >
            <Select
              trueFalse
              onChange={handleOnChange}
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item
            name="rfc_de_retenedor"
            label="RFC"
            // rules={rules.rfc}
            normalize={(value) => value.toUpperCase()}
          >
            <Input
              allowClear
              maxLength={getMaxLength(tipoDePersona)}
              disabled
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item
            name="razon_social_nombre_completo_de_retenedor"
            label="Razón Social/Nombre Completo"
          >
            <Input allowClear disabled />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

const RepresentanteLegalSection = () => {
  const form = Form.useFormInstance();
  const rules = {
    rfc: [RFC_VALIDATOR(tiposDePersonasEnum.FISICA)],
    curp: [CURP_VALIDATOR(tiposDePersonasEnum.FISICA)],
  };

  const setRazonSocial = () => {
    const {
      nombre_de_representante_de_retenedor,
      apellido_paterno_de_representante_de_retenedor,
      apellido_materno_de_representante_de_retenedor,
    } = form.getFieldsValue();
    const razon_social_nombre_completo_de_representante_de_retenedor = `${
      nombre_de_representante_de_retenedor
    || ''} ${apellido_paterno_de_representante_de_retenedor
    || ''} ${apellido_materno_de_representante_de_retenedor || ''}`.trim();
    form.setFieldsValue({ razon_social_nombre_completo_de_representante_de_retenedor });
  };
  return (
    <>
      <Divider orientation="left">
        <Text $accent>
          Datos Del Representante Legal
        </Text>
      </Divider>
      <Row gutter={10}>
        <Col xs={24} sm={24} md={6}>
          <Form.Item
            name="nombre_de_representante_de_retenedor"
            label="Nombre"
            hasFeedback
          >
            <Input allowClear onChange={setRazonSocial} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item
            name="apellido_paterno_de_representante_de_retenedor"
            label="Apellido Paterno"
            hasFeedback
          >
            <Input allowClear onChange={setRazonSocial} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item
            name="apellido_materno_de_representante_de_retenedor"
            label="Apellido Materno"
            hasFeedback
          >
            <Input allowClear onChange={setRazonSocial} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item
            name="rfc_de_representante_de_retenedor"
            label="RFC"
            hasFeedback
            normalize={(value) => value.toUpperCase()}
            rules={rules.rfc}
          >
            <Input allowClear maxLength={getMaxLength(tiposDePersonasEnum.FISICA)} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item
            name="curp_de_representante_de_retenedor"
            label="CURP"
            rules={rules.curp}
            normalize={(value) => value.toUpperCase()}
          >
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Form.Item
            name="razon_social_nombre_completo_de_representante_de_retenedor"
            label="Razón Social/Nombre Completo"
            hasFeedback
          >
            <Input allowClear disabled />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

const ModalCRIE = ({
  visible,
  setVisble,
  callback,
  declaracionId,
  retenedor,
}) => {
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [tiposDeRetenciones, setTiposDeRetenciones] = useState([]);

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const _tiposDeRetenciones = await getTiposDeRetenciones();
      const rfc_de_retenedor = retenedor?.empresa?.contribuyente?.rfc;
      const retenedor_es_persona_fisica = rfc_de_retenedor?.length === 13;
      const razon_social_nombre_completo_de_retenedor = retenedor
        ?.empresa?.contribuyente?.razon_social;

      form.setFieldsValue({
        declaracion_empresarial: declaracionId,
        rfc_de_retenedor,
        retenedor_es_persona_fisica,
        razon_social_nombre_completo_de_retenedor,
      });
      if (mounted) {
        setTiposDeRetenciones(_tiposDeRetenciones);
        setLoading(false);
      }
    };
    if (declaracionId && visible) {
      fetchData();
    }
    return () => { mounted = false; };
  }, [declaracionId, visible]);

  const onCancel = () => {
    setVisble(false);
    setLoading(false);
    form.resetFields();
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      await postDeclaracionCRIE(values);
      callback();
      onCancel();
    } catch (error) {
      _logger(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      bodyStyle={{ padding: '0 24px' }}
      width="85vw"
      visible={visible}
      closable={false}
      title={(
        <Row justify="space-between">
          <Text $accent>
            {screens.md ? 'Constancia de Retención de Impuestos Estatales' : 'CRIE'}
          </Text>
          <Button type="link" onClick={onCancel}>
            Cerrar
            <CloseOutlined />
          </Button>
        </Row>
      )}
      forceRender
      centered
      onCancel={onCancel}
      maskClosable={false}
      footer={(
        <Row justify="end" gutter={[10, 15]}>
          <Col xs={24} sm={24} md={6}>
            <Button
              type="primary"
              style={{ width: '100%' }}
              onClick={onFinish}
              loading={loading}
              $accent
            >
              <Text>
                Generar
              </Text>
              <SyncOutlined />
            </Button>
          </Col>
        </Row>
      )}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          tercero_es_persona_fisica: true,
          retenedor_es_persona_fisica: true,
        }}
      >
        <Form.Item name="declaracion_empresarial" hidden>
          <Input />
        </Form.Item>
        <TerceroSection />
        <PagosRetencionesSection tiposDeRetenciones={tiposDeRetenciones} />
        <OtrosPagosRetencionesSection tiposDeRetenciones={tiposDeRetenciones} />
        <RetencionesSection />
        <RepresentanteLegalSection />
        <Form.Item hidden>
          <AntButton htmlType="submit" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

ModalCRIE.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisble: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  declaracionId: PropTypes.number.isRequired,
  retenedor: PropTypes.shape({
    id: PropTypes.number,
    empresa: PropTypes.shape({
      razon_social: PropTypes.string,
      ciudadano: PropTypes.shape({
        curp: PropTypes.string,
        CURP: PropTypes.string,
      }),
      contribuyente: PropTypes.shape({
        ciudadano: PropTypes.shape({
          curp: PropTypes.string,
          CURP: PropTypes.string,
        }),
        razon_social: PropTypes.string,
        rfc: PropTypes.string,
      }),
    }),
  }),
};

ModalCRIE.defaultProps = {
  retenedor: {},
};

export default ModalCRIE;
