export const RFC_PATTERN = /^(([ÑA-Z|ña-z|&amp;]{3}|[A-Z|a-z]{4})\d{2}((0[1-9]|1[012])(0[1-9]|1\d|2[0-8])|(0[13456789]|1[012])(29|30)|(0[13578]|1[02])31)(\w{2})([A|a|0-9]{1}))$|^(([ÑA-Z|ña-z|&amp;]{3}|[A-Z|a-z]{4})([02468][048]|[13579][26])0229)(\w{2})([A|a|0-9]{1})$/;
export const INE_PATTERN = /^[A-Z]{6}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])(?:0[1-9]|1[1-9]|2[1-9]|3[12])[HM]{1}[0-9]{3}$/;
export const CURP_PATTERN = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
export const DECIMAL_PATTERN = /^(\d)*(\.\d{1,2})?$/;
export const INTEGER_PATTERN = /^\d+$/;
export const PHONE_NUMBER = /[0-9]{10}/;
export const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/;
export const SLUG_PATTERN = /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/;
export const ALPHA_NUMERIC_PATTERN = /^[a-zA-Z0-9]*$/;

export const digitoVerificador = (curp17) => {
  const dict = '0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ';
  let lngSuma = 0;
  let lngDigito = 0;
  for (let i = 0; i < 17; i += 1) {
    lngSuma += dict.indexOf(curp17.charAt(i)) * (18 - i);
  }
  lngDigito = 10 - (lngSuma % 10);
  if (lngDigito === 10) {
    return 0;
  }
  return lngDigito.toString();
};

export const CURP_VALIDATOR = (tipoDePersona) => ({
  validator: async (_, value) => {
    if (value) {
      const match = value.toUpperCase().match(CURP_PATTERN);
      const verifyer = match?.[2];
      const digito = match && digitoVerificador(match?.[1]);
      const estado = value.substring(11, 13);
      const estadoValido = tipoDePersona === 3 ? estado === 'NE' : estado !== 'NE';
      let _digito = digito;
      if (typeof digito === 'number') _digito = digito.toString();
      if (!match || verifyer !== _digito || !estadoValido) {
        throw new Error('Ingrese una CURP válida');
      }
    }
  },
});

export const RFC_VALIDATOR = (tipoDePersona) => ({
  validator: async (_, _value) => {
    if (_value) {
      const value = _value.toString().toUpperCase();
      const validString = RFC_PATTERN.test(value);
      const validLength = value.length === (tipoDePersona === 2 ? 12 : 13);
      if (!validString || !validLength) {
        throw new Error('Ingrese un RFC válido');
      }
    }
  },
});

// Decimales
const MAX_PRE_DIGIT_COUNT = 12;
const MAX_POST_DIGIT_COUNT = 4;
const DEFAULT_PRE_DIGIT_COUNT = 12;
// const DEFAULT_POST_DIGIT_COUNT = 2;
// const DEFAULT_POST_DIGIT_COUNT = monedaDefault?.decimales || 2;
/**
 * Función para crear expreciones regulares para valores decimales
 * @param pre digitos a la izquierda del punto decimal
 * @param post digitos a la derecha del punto decimal
 * @param req el valor es requerido
 */
export const createDecimalRegExp = (
  moneda = null,
  config = {},
) => {
  let {
    pre = DEFAULT_PRE_DIGIT_COUNT,
    post,
  } = config;
  const { req = false } = config;
  const DEFAULT_POST_DIGIT_COUNT = moneda?.decimales ?? MAX_POST_DIGIT_COUNT;
  pre = pre >= 0 ? pre : DEFAULT_PRE_DIGIT_COUNT;
  post = post >= 0 ? post : DEFAULT_POST_DIGIT_COUNT;
  pre = pre > MAX_PRE_DIGIT_COUNT ? MAX_PRE_DIGIT_COUNT : pre;
  post = post > MAX_POST_DIGIT_COUNT ? MAX_POST_DIGIT_COUNT : post;
  const regexp = new RegExp(`^(\\d){${req ? 1 : 0},${pre}}(\\.\\d{0,${post}})?$`);
  const maxLength = pre + post + 1;
  return [regexp, maxLength];
};
