import API from 'api';
import _logger from 'utils/logger';

export const getInfoUserParaFirma = (_values) => {
  const values = {};
  values.unidad_de_recaudacion = _values?.unidad_de_recaudacion || '';
  values.unidad_responsable = _values?.unidad_responsable || '';
  values.unidad_operativa = _values?.unidad_operativa || '';
  values.puesto = _values?.puesto || '';
  return values;
};

/**
 * Generar cadena para firmar tramite
 * @param {object} tramite
 * @param {string} tramite.firma_electronica
 * @param {string} tramite.folio
 * @returns string
 */
const generarCadena = (tramite) => JSON.parse(tramite.firma_electronica || '[]')[0]?.cadena
  || tramite.folio;

/**
 * POST firmar cadena
 * @param {object} _values
 * @param {string} _values.rfc
 * @param {File} _values.archivo_cer
 * @param {File} _values.archivo_key
 * @param {string} _values.clave_privada
 * @param {?string} _values.uuid
 * @param {object} tramite
 * @param {string} tramite.firma_electronica
 * @returns Promise<Object|null>
 */
export const postFirmarCadena = async ({ paso, ..._values }, tramite) => {
  try {
    const values = {
      ..._values,
      cadena: generarCadena(tramite),
    };
    const response = await API.post('/tramites/firmado-automatico/', values);
    return {
      ...response.data.response,
      ...getInfoUserParaFirma(response.data.info_usuario),
    };
  } catch (err) {
    _logger(err);
  }
  return null;
};

export default { postFirmarCadena };
