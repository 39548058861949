/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { toCurrency } from 'utils/formatters';
import { COLORS } from 'components';
import { toMoment } from './normalizers';

const multasFilter = (e) => e.es_multa;
const noMultasFilter = (e) => !e.es_multa;

const tooltipoInfo = (record) => {
  if (record?.observaciones) {
    return (
      <Tooltip
        title={record.observaciones}
      >
        <InfoCircleOutlined />
      </Tooltip>
    );
  }
  return null;
};

const descripcionRender = (val, record) => (record?.fecha_de_vencimiento ? (
  <>
    <Tooltip
      title={`Fecha de Vencimiento ${toMoment(record.fecha_de_vencimiento).format()}`}
    >
      {`${val}`}
    </Tooltip>
    {' '}
    {tooltipoInfo(record)}
  </>
) : (
  <>
    {` ${val}`}
    {' '}
    {tooltipoInfo(record)}
  </>
));

const multasRender = (val, record) => {
  const label = toCurrency(val);
  const { recargos, gastos } = record;
  const recargosMultas = recargos.filter(multasFilter);
  const gastosMultas = gastos.filter(multasFilter);

  const elementsRecargos = recargosMultas.map((r) => (
    <li key={r.id}>
      {`${r.descripcion} (${toCurrency(r.importe_total)})`}
      {r.observaciones && (
        <Tooltip title={r.observaciones}>
          <InfoCircleOutlined />
        </Tooltip>
      )}
    </li>
  ));

  const elementsGastos = gastosMultas.map((r) => (
    <li key={r.id}>
      {`${r.descripcion} (${toCurrency(r.importe)})`}
    </li>
  ));

  const multas = elementsRecargos.concat(elementsGastos);

  const title = multas.length ? multas : null;

  return (
    <Tooltip title={title && (<ul>{title}</ul>)}>
      {label}
    </Tooltip>
  );
};

const descuentosMapper = (d) => (
  <li key={d.id}>
    {d.comentarios}
    :
    {' '}
    <strong>
      {toCurrency(d.importe_total)}
    </strong>
  </li>
);

const descuentosRender = (val, record) => {
  const label = toCurrency(val);
  const {
    descuentos_aplicables,
    descuentos_especiales,
    actualizaciones,
    recargos,
    gastos,
  } = record;
  const descuentosEspeciales = descuentos_especiales.filter((d) => d.estados_globales === 4);
  const descuentosAplicables = descuentos_aplicables;
  const descuentosSobreRecargos = recargos.filter(noMultasFilter).map((r) => (r.descuentos)).flat();
  const descuentosSobreMultas = recargos.filter(multasFilter).map((r) => (r.descuentos)).flat();
  const descuentosSobreGastos = gastos.filter(noMultasFilter).map((r) => (r.descuentos)).flat();
  const descuentosSobreMultasGastos = gastos.filter(multasFilter).map((r) => (r.descuentos)).flat();
  const descuentosSobreActualizaciones = actualizaciones.map((a) => (a.descuentos)).flat();

  const someHasLength = descuentosEspeciales.length
    || descuentosAplicables.length
    || descuentosSobreRecargos.length
    || descuentosSobreMultas.length
    || descuentosSobreGastos.length
    || descuentosSobreMultasGastos.length
    || descuentosSobreActualizaciones.length;

  const descuentosSobreCargo = descuentosEspeciales.concat(descuentosAplicables)
    .map(descuentosMapper);
  const titleDescuentosSobreRecargos = descuentosSobreRecargos.map(descuentosMapper);
  const titleDescuentosSobreMultas = descuentosSobreMultas.map(descuentosMapper);
  const titleDescuentosSobreGastos = descuentosSobreGastos.map(descuentosMapper);
  const titleDescuentosSobreMultasGastos = descuentosSobreMultasGastos.map(descuentosMapper);
  const titleDescuentosSobreActualizaciones = descuentosSobreActualizaciones.map(descuentosMapper);

  const title = someHasLength ? (
    <ul>
      {!!descuentosSobreActualizaciones.length && (
        <>
          <strong>Descuentos sobre actualizaciones</strong>
          {titleDescuentosSobreActualizaciones}
          <br />
        </>
      )}
      {!!descuentosSobreRecargos.length && (
        <>
          <strong>Descuentos sobre recargos</strong>
          {titleDescuentosSobreRecargos}
          <br />
        </>
      )}
      {!!(descuentosSobreMultas.length || descuentosSobreMultasGastos.length) && (
        <>
          <strong>Descuentos sobre multas</strong>
          {titleDescuentosSobreMultas}
          {titleDescuentosSobreMultasGastos}
          <br />
        </>
      )}
      {!!descuentosSobreGastos.length && (
        <>
          <strong>Descuentos sobre gastos</strong>
          {titleDescuentosSobreGastos}
          <br />
        </>
      )}
      {!!descuentosSobreCargo.length && (
        <>
          <strong>Descuentos sobre cargo</strong>
          {descuentosSobreCargo}
        </>
      )}

    </ul>
  ) : null;

  return (
    <Tooltip title={title}>
      {label}
    </Tooltip>
  );
};

const valDiscountsRender = (record, val, title) => (record.aplicaDescuento ? (
  <Tooltip title={record[title]}>
    {toCurrency(record[val])}
  </Tooltip>
) : toCurrency(record[val]));

export const debtsColumns = [
  {
    title: 'Periodo Fiscal',
    dataIndex: 'tipo_de_cargo',
    key: 'periodo_fiscal',
    width: 150,
    render: (tipo_de_cargo) => tipo_de_cargo?.periodo_fiscal?.periodo,
  },
  {
    title: 'Descripción',
    dataIndex: 'descripcion',
    key: 'descripcion',
    width: 300,
    render: (val) => descripcionRender(val),
  },
  {
    title: 'Adeudo Total',
    dataIndex: 'adeudo_total',
    key: 'adeudo_total',
    render: (val) => <b style={{ color: COLORS.accent }}>{toCurrency(val)}</b>,
    align: 'right',
    width: 150,
  },
  {
    title: 'Importe Base',
    dataIndex: 'importe',
    key: 'importe',
    render: (val) => toCurrency(val),
    align: 'right',
    width: 150,
  },
  {
    title: 'Actualización',
    dataIndex: 'actualizaciones_totales',
    key: 'actualizaciones_totales',
    width: 125,
    align: 'right',
    render: (text, record) => {
      const output = toCurrency(text);
      const { actualizaciones } = record;
      const title = actualizaciones.length ? actualizaciones.map((a) => (
        <li key={a.id}>
          {`${a.descripcion} (${toCurrency(a.importe_total)})`}
          {a.observaciones && (
            <Tooltip title={a.observaciones}>
              <InfoCircleOutlined />
            </Tooltip>
          )}
        </li>
      )) : null;

      return (
        <Tooltip title={title && (<ul>{title}</ul>)}>
          {output}
        </Tooltip>
      );
    },
  },
  {
    title: 'Recargos',
    dataIndex: 'recargo_total',
    key: 'recargo_total',
    width: 125,
    align: 'right',
    render: (text, record) => {
      const output = toCurrency(text);
      const recargos = record.recargos.filter(noMultasFilter);
      const title = recargos.length ? recargos.map((r) => (
        <li key={r.id}>
          {`${r.descripcion} (${toCurrency(r.importe_total)})`}
          {r.observaciones && (
            <Tooltip title={r.observaciones}>
              <InfoCircleOutlined />
            </Tooltip>
          )}
        </li>
      )) : null;

      return (
        <Tooltip title={title && (<ul>{title}</ul>)}>
          {output}
        </Tooltip>
      );
    },
  },
  {
    title: 'Multa',
    dataIndex: 'multas_totales',
    key: 'multas_totales',
    width: 125,
    align: 'right',
    render: multasRender,
  },
  {
    title: 'Gastos',
    dataIndex: 'gastos_totales',
    key: 'gastos_totales',
    width: 125,
    align: 'right',
    render: (_, record) => valDiscountsRender(record, 'gastos_totales', 'descuentos_de_gastos_str'),
  },
  {
    title: 'Desc.',
    dataIndex: 'descuentos_totales',
    key: 'descuentos_totales',
    width: 125,
    align: 'right',
    render: descuentosRender,
  },
];

const reducer = (key = 'importe_total') => (accum, curr) => accum + curr[key];

// Función para totalizar descuentos, actualizaciones y recargos
const debtsFormatter = (cargos, importe_cargo_padre) => (
  cargos
    .filter((e) => e.estados_globales !== 5)
    .map((c) => {
      const {
        descuentos_especiales = [],
        actualizaciones = [],
        recargos = [],
        descuentos_aplicables = [],
        gastos = [],
      } = c;
      // Calculando equivalencia en porcentaje
      const importe = c.tipo_de_aplicacion === 2
        ? c.importe : ((importe_cargo_padre || 100) / 100) * c.importe;

      // Actualizaciones
      const actualizaciones_totales = actualizaciones
        .reduce(reducer(), 0);

      const descuentos_de_actualizacion = actualizaciones
        .map((a) => a.descuentos).flat().reduce(reducer(), 0);

      // Recargos
      const _recargos = recargos.filter(noMultasFilter);
      const recargo_total = _recargos.reduce(reducer(), 0);

      const descuentos_de_recargos = _recargos
        .map((r) => r.descuentos).flat().reduce(reducer(), 0);

      // Multas
      const _recargosMultas = recargos.filter(multasFilter);
      const multa_recargos = _recargosMultas.reduce(reducer(), 0);
      const descuentos_de_multas = _recargosMultas
        .map((r) => r.descuentos).flat().reduce(reducer(), 0);

      const _gastosMultas = gastos.filter(multasFilter);
      const multa_gastos = _gastosMultas.reduce(reducer(), 0);
      const descuentos_de_multas_gastos = _gastosMultas
        .map((r) => r.descuentos).flat().reduce(reducer(), 0);

      // Gastos
      const _gastos = gastos.filter(noMultasFilter);
      const gastos_totales = _gastos.reduce(reducer('importe'), 0);
      const descuentos_de_gastos = _gastos
        .map((r) => r.descuentos).flat().reduce(reducer(), 0);

      const descuentos_gastos_totales = _gastos
        .map((r) => r.descuentos).flat().reduce(reducer(), 0);

      const descuentos_especiales_totales = descuentos_especiales
        .filter((d) => d.estados_globales === 4).reduce(reducer(), 0);

      const descuentos_aplicables_total = descuentos_aplicables.reduce(reducer(), 0);

      const descuentos_totales = descuentos_de_actualizacion
        + descuentos_de_recargos
        + descuentos_de_multas
        + descuentos_de_multas_gastos
        + descuentos_de_gastos
        + descuentos_especiales_totales
        + descuentos_aplicables_total
        + descuentos_gastos_totales;

      const multas_totales = multa_gastos + multa_recargos;

      const adeudo_total = (importe * (importe_cargo_padre || 1))
        + recargo_total
        + actualizaciones_totales
        + gastos_totales
        + multas_totales
        - descuentos_totales;

      return {
        ...c,
        key: c.id,
        multas_totales,
        actualizaciones_totales,
        recargo_total,
        adeudo_total,
        gastos_totales,
        descuentos_totales,
      };
    })
);

export default debtsFormatter;
