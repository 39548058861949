/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Card as AntCard,
  Row,
  Col,
  Table as AntTable,
  message,
  Form,
  Input,
  Grid,
} from 'antd';
import {
  LinkOutlined, DownOutlined, RightOutlined, SearchOutlined, FileFilled,
} from '@ant-design/icons';
import { getNombresDeOrdenamiento } from 'api/catalogos';
import { Button, CardContainer, Title } from 'components';
import { ENV_NAME, ENV_NAMES_ENUM } from 'utils/env';
import { getReporteDeRegulacion } from 'api/reporteador';
import Previewer from 'components/Previewer';

const { useBreakpoint } = Grid;

const Normateca = () => {
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const [data, setData] = useState({ results: [], count: 0 });
  const [loading, setLoading] = useState(false);
  const [base64, setBase64] = useState();
  const [visible, setVisible] = useState(false);

  const handleSearch = async () => {
    setLoading(true);
    const { params: descripcion } = form.getFieldsValue();
    if (descripcion) {
      const _data = await getNombresDeOrdenamiento({
        page: 1,
        descripcion,
      });
      if (_data.count > 0) {
        setData(_data);
      } else {
        message.info('No se encontraron Normatividades con ese nombre.', 2.5);
      }
    }
    setLoading(false);
  };

  const fetchNombresDeOrdenamiento = async (page = 1) => {
    setLoading(true);
    const _data = await getNombresDeOrdenamiento({ page });
    setData(_data);
    setLoading(false);
  };

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      setLoading(true);
      const _data = await getNombresDeOrdenamiento();
      if (mounted) {
        setData(_data);
        setLoading(false);
      }
    };
    fetchData();
    return () => { mounted = false; };
  }, []);

  const onCancel = () => {
    setVisible(false);
    setBase64();
  };

  const printFormat = async (id) => {
    setLoading(true);
    const [, _base64] = await getReporteDeRegulacion(id);
    if (_base64) {
      setBase64(_base64);
      setVisible(true);
    }
    setLoading(false);
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 320,
      responsive: ['xs', 'sm'],
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      key: 'tipo',
      width: 130,
      responsive: ['md'],
    },
    {
      title: 'Ámbito',
      dataIndex: 'ambito',
      key: 'ambito',
      width: 100,
      responsive: ['md'],
    },
    {
      title: 'Fecha de publicación',
      dataIndex: 'fecha',
      key: 'fecha',
      width: 130,
      render: (fecha) => fecha || '-',
      responsive: ['md'],
    },
    {
      title: 'Archivo',
      dataIndex: 'hipervinculo',
      key: 'hipervinculo',
      width: 80,
      responsive: ['md'],
      align: 'center',
      render: (hipervinculo) => (hipervinculo ? (
        <Row justify="center">
          <Button
            icon={<LinkOutlined />}
            type="link"
            size="large"
            disabled={loading}
            onClick={() => {
              if (hipervinculo) {
                window.open(hipervinculo, '_blank');
              } else {
                message.warn('No es posible abrir este archivo', 2.5);
              }
            }}
          />
        </Row>
      ) : ''),
    },
    ENV_NAMES_ENUM.NAYARIT && {
      title: 'Detalles',
      dataIndex: 'hipervinculo',
      key: 'hipervinculo',
      width: 80,
      responsive: ['md'],
      align: 'center',
      render: (_, record) => (
        <Row justify="center">
          <Button
            icon={<FileFilled />}
            type="link"
            size="large"
            disabled={loading}
            onClick={() => printFormat(record?.id)}
          />
        </Row>
      ),
    },
  ];

  const rowExpandable = () => (!screens.md);

  const expandedRowRender = (record) => columns.map((column) => (
    <Row
      key={column.key}
      justify="space-between"
    >
      <b>
        {`${column.title}:`}
      </b>
      <span>
        {column.render
          ? column.render(record[column.dataIndex], record)
          : record[column.dataIndex]}
      </span>
    </Row>
  ));

  const expandIcon = ({ expanded, onExpand, record }) => (!screens.md) && (record && (
    expanded ? (
      <DownOutlined onClick={(e) => {
        e.stopPropagation();
        onExpand(record, e);
      }}
      />
    ) : (
      <RightOutlined onClick={(e) => {
        e.stopPropagation();
        onExpand(record, e);
      }}
      />
    )
  ));

  return (
    <>
      <CardContainer>
        <Row justify="center">
          <Col>
            <Form
              layout="vertical"
              form={form}
            >
              <Title level={3}>
                Búsqueda de
                {' '}
                {ENV_NAME === ENV_NAMES_ENUM.NAYARIT ? 'Regulación' : 'Normatividad'}
              </Title>
              <Form.Item name="params">
                <Input
                  disabled={!data.results?.length || loading}
                  onPressEnter={handleSearch}
                  size="large"
                  style={{
                    width: '100%',
                  }}
                  onChange={({ target: { value } }) => {
                    if (!value) {
                      fetchNombresDeOrdenamiento();
                    }
                  }}
                  addonAfter={(
                    <SearchButton
                      loading={loading}
                      onClick={() => handleSearch()}
                      disabled={!data.results?.length}
                    >
                      Buscar
                      <SearchOutlined />
                    </SearchButton>
                )}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <br />
        <Wrapper>
          <Table
            expandable={{
              rowExpandable,
              expandedRowRender,
              expandIcon,
            }}
            dataSource={data.results}
            columns={columns}
            loading={loading}
            size={(screens.md) ? 'small' : 'large'}
            pagination={{
              total: data.count,
              onChange: (page) => fetchNombresDeOrdenamiento(page),
              showSizeChanger: false,
            }}
            scroll={{
              x: (screens.md) ? columns.reduce((acum, curr) => acum + curr.width, 0) : 0,
              y: 400,
            }}
          />
        </Wrapper>
      </CardContainer>
      <Previewer
        base64={base64}
        visible={visible}
        onCancel={onCancel}
      />
    </>
  );
};

const Wrapper = styled(AntCard)`
  border-radius: 0.25rem;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.15);
  max-width: 100vw;
`;

const SearchButton = styled(Button)`
  border: transparent;
  width: 100% !important;
  box-shadow: none;
  background: transparent;
`;

const Table = styled(AntTable)`
  .ant-table-thead>tr>th{
    background: transparent;
    border-bottom: 2px solid;
  }
`;

export default Normateca;
