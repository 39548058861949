import React, { useState } from 'react';
import {
  Input,
  Form,
  Row,
  message,
} from 'antd';
import { Button, Text } from 'components';
import styled from 'styled-components';
import { FormOutlined } from '@ant-design/icons';

export const joinInputs = (values, key = 'codigo') => ({ [key]: Object.values(values).join('') });

const CodeInput = () => {
  const form = Form.useFormInstance();
  const [isFilled, setIsFilled] = useState(false);
  const jump = (evt) => {
    const { target: { value, id }, nativeEvent } = evt;
    if (value?.length === 1 && nativeEvent.inputType === 'insertText') {
      const next = document.getElementById(`${parseInt(id, 10) + 1}`);
      next?.focus();
    }
    if (nativeEvent.inputType === 'deleteContentBackward' && value?.length === 0) {
      const prev = document.getElementById(`${parseInt(id, 10) - 1}`);
      prev?.focus();
    }
    if (id === '5' && value?.length === 1 && nativeEvent.inputType === 'insertText') {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  };

  return (
    <>
      <Row justify="center" style={{ marginBottom: 20 }}>
        <Text>Código de acceso</Text>
      </Row>
      <Container>
        {new Array(6).fill(0).map((_, i) => (
          <Form.Item
            key={Math.random()}
            name={i}
            rules={[{ required: true, message: '' }]}
            style={{ width: '50px', margin: '0 5px' }}
          >
            <Input
              style={{ textAlign: 'center' }}
              maxLength={1}
              onChange={jump}
              allowClear={false}
              onPaste={(event) => {
                const pastedText = (event?.clipboardData || window.clipboardData).getData('text');
                if (pastedText.length === 6) {
                  event.preventDefault();
                  const values = pastedText.split('').reduce((acc, val, idx) => ({ ...acc, [idx]: val }), {});
                  form.setFieldsValue(values);
                  setIsFilled(true);
                } else {
                  message.warn('El código debe tener 6 dígitos');
                }
              }}
            />
          </Form.Item>
        ))}
      </Container>
      <Row justify="center">
        {isFilled ? (
          <Button
            type="link"
            icon={<FormOutlined />}
            onClick={() => {
              form.resetFields();
              setIsFilled(false);
            }}
          >
            Limpiar
          </Button>

        ) : (
          <Button
            type="link"
            icon={<FormOutlined />}
            onClick={() => {
              navigator.clipboard.readText().then((text) => {
                if (text.length === 6) {
                  const values = text.split('').reduce((acc, val, idx) => ({ ...acc, [idx]: val }), {});
                  form.setFieldsValue(values);
                  setIsFilled(true);
                } else {
                  message.warn('El código debe tener 6 dígitos');
                }
              });
            }}
          >
            Pegar
          </Button>
        )}
      </Row>
    </>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

export default CodeInput;
