/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  AuditOutlined,
  CarOutlined,
  CloseOutlined,
  DatabaseOutlined,
  DollarCircleOutlined,
  EditOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
  IdcardOutlined,
  InboxOutlined,
  LockOutlined,
  LogoutOutlined,
  MenuOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Col, Row, Drawer as AntDrawer } from 'antd';
import {
  Button,
  COLORS,
  Card,
  Text,
  Title,
} from 'components';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import toSlug from 'utils/toSlug';
import PropTypes from 'prop-types';
import {
  handleLogout,
  selectUser,
  // selectEntidad,
} from 'store/auth';
import { useDispatch, useSelector } from 'react-redux';
import { estadosDelPadronEnum } from 'utils/enums';
import { getEstadosDelPadron } from 'api/catalogos';
import { AUTH_CONTRIBUYENTE } from 'utils/env';

const getIcon = ({ to, active }) => {
  const styles = {
    fontSize: 'xx-large',
    color: active ? COLORS.white : COLORS.accent,
  };
  const icons = {
    '/mi-cuenta/mis-datos': <UserOutlined style={styles} />,
    '/mi-cuenta/mis-tramites': <FileTextOutlined style={styles} />,
    '/mi-cuenta/mis-establecimientos': <DatabaseOutlined style={styles} />,
    '/mi-cuenta/mis-obligaciones': <IdcardOutlined style={styles} />,
    '/mi-cuenta/mis-pagos-de-contribuciones': <DollarCircleOutlined style={styles} />,
    '/mi-cuenta/mis-constancias': <FileDoneOutlined style={styles} />,
    '/mi-cuenta/mis-declaraciones': <AuditOutlined style={styles} />,
    '/mi-cuenta/mi-buzon': <InboxOutlined style={styles} />,
    '/mi-cuenta/mi-expediente-digital': <FolderOpenOutlined style={styles} />,
    '/mi-cuenta/mis-vehiculos': <CarOutlined style={styles} />,
  };
  return icons[to];
};

const activeUserRoutes = [
  'Mis Establecimientos',
  'Mis Obligaciones',
  'Mis Pagos de Contribuciones',
  'Mis Constancias',
  'Mis Declaraciones',
  'Mi Buzón',
  'Mi Expediente Digital',
  'Mis Vehículos',
];

const routes = [
  'Mis Datos',
  'Mis Trámites',
];

const titles = {
  '/mi-cuenta/mis-datos': 'Mis Datos',
  '/mi-cuenta/mis-tramites': 'Mis Trámites',
  '/mi-cuenta/mis-establecimientos': 'Mis Establecimientos',
  '/mi-cuenta/mis-obligaciones': 'Mis Obligaciones',
  '/mi-cuenta/mis-pagos-de-contribuciones': 'Mis Pagos de Contribuciones',
  '/mi-cuenta/mis-constancias': 'Mis Constancias',
  '/mi-cuenta/mis-declaraciones': 'Mis Declaraciones',
  '/mi-cuenta/mi-buzon': 'Mi Buzón',
  '/mi-cuenta/mi-expediente-digital': 'Mi Expediente Digital',
  '/mi-cuenta/mis-vehiculos': 'Mis Vehículos',
};

const MiCuenta = () => {
  const user = useSelector(selectUser);
  // const entidad = useSelector(selectEntidad);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [estadosDelPadron, setEstadosDelPadron] = useState([]);
  const [visibleMenu, setVisibleMenu] = useState(false);

  useEffect(() => {
    let mounted = true;
    const fetchAll = async () => {
      const [_estadosDelPadron] = await Promise.all([getEstadosDelPadron()]);
      if (mounted) {
        setEstadosDelPadron(_estadosDelPadron);
      }
    };
    fetchAll();
    return () => { mounted = false; };
  }, []);

  const logOut = () => {
    dispatch(handleLogout());
    navigate('/');
  };

  const Item = ({ text, to, active }) => (
    <Col
      span={24}
      style={{
        height: 'fit-content',
        backgroundColor: active ? COLORS.accent : 'transparent',
      }}
    >
      <Link to={toSlug(to)}>
        <Card style={{ borderRadius: 0 }}>
          <Row align="middle" style={{ height: '100%' }}>
            <Text
              style={{
                fontWeight: '500',
                width: 'calc(100% - 36px)',
                paddingRight: 5,
                textAlign: 'left',
                display: 'flex',
                alignItems: 'center',
                gap: 10,

              }}
            >
              {getIcon({ to, active })}
              <span style={{ color: active ? COLORS.white : COLORS.accent }}>
                {text}
              </span>
            </Text>
          </Row>
        </Card>
      </Link>
    </Col>
  );

  Item.propTypes = {
    text: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  };

  useEffect(() => {
    if (user.estado_del_padron === estadosDelPadronEnum.ACTIVO && routes.length === 2) {
      activeUserRoutes.forEach((route) => routes.push(route));
    }
    if (user.estado_del_padron !== estadosDelPadronEnum.ACTIVO && routes.length > 2) {
      routes.splice(2, activeUserRoutes.length);
    }
  }, [user]);

  useEffect(() => {
    setVisibleMenu(false);
  }, [window.location.pathname]);

  const title = () => {
    if (!AUTH_CONTRIBUYENTE) {
      return null;
    }

    const padronStatus = estadosDelPadron
      ?.find((estado) => estado.id === user.estado_del_padron)?.descripcion ?? 'Ingresado';

    const name = user?.tipo_de_persona === 1 ? user.nombre_completo : user.razon_social;

    return (
      <>
        <Text $accent>
          <strong>
            {user.rfc}
          </strong>
        </Text>
        {' - '}
        <strong style={{ fontSize: 18 }}>
          {name}
        </strong>
        {' - '}
        <strong style={{ fontSize: 18 }} id="padron_status">
          {padronStatus}
        </strong>
      </>
    );
  };

  return (
    <>
      <Drawer
        title={(
          <Row justify="space-between" align="bottom">
            <Col>
              <Title level={4}>
                Menú
              </Title>
            </Col>
            <Col>
              <Button
                type="link"
                onClick={() => setVisibleMenu(false)}
                icon={<CloseOutlined style={{ fontSize: 'xx-large' }} />}
              />
            </Col>
          </Row>
        )}
        getContainer={false}
        placement="left"
        closable={false}
        onClose={() => setVisibleMenu(false)}
        visible={visibleMenu}
        mask
        bodyStyle={{
          overflowX: 'hidden',
        }}
      >
        <Col span={24} style={{ display: 'grid', gap: 10 }}>
          <Row>
            {routes.map((route) => (
              <Item
                key={route}
                text={route}
                to={toSlug(`/mi-cuenta/${route}`)}
                active={pathname === toSlug(`/mi-cuenta/${route}`)}
              />
            ))}
          </Row>
          {user.estado_del_padron === estadosDelPadronEnum.INGRESADO && (
            <Row
              gutter={10}
              justify="center"
              style={{
                textAlign: 'center',
                gap: 8,
                padding: 10,
              }}
            >
              <div style={{ marginInline: 'auto' }}>
                <LockOutlined
                  style={{ fontSize: 40 }}
                />
              </div>
              Si eres un contribuyente con actividad empresarial,
              debrás dart de alta al padrón con el siguiente trámite:
              <strong>AVISO DE INSCRIPCIÓN DE ALTA DE CONTRIBUYENTE</strong>
              Ya sea en ventanilla o en línea.
              Al concluirlo podrás tener acceso a todas las opciones como:
              Obligaciones, declaraciones, buzon, constancia de situacio fiscal, entre otros.
              <Button
                $accent
                onClick={() => {
                  // console.log(entidad);
                  // cosnt id = entidad.
                  const id = 129;
                  navigate(`/tramites/tramite/${id}/nueva`);
                }}
                block
              >
                <EditOutlined />
                Iniciar en línea
              </Button>
            </Row>
          )}
          <Row justify="center" align="bottom">
            <Button
              type="link"
              danger
              icon={<LogoutOutlined />}
              onClick={logOut}
              className="danger"
              block
            >
              Cerrar sesión
            </Button>
          </Row>
        </Col>
      </Drawer>
      <Container gutter={10}>
        <Button
          type="link"
          id="menu_button"
          onClick={() => setVisibleMenu(true)}
          icon={<MenuOutlined style={{ fontSize: 'xx-large' }} />}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 10,
          }}
        >
          <Title level={4}>
            Menú
          </Title>
        </Button>
        <Col span={24}>
          <Row justify="center" style={{ marginBottom: 33, paddingInline: 18 }}>
            <Title level={3}>
              {titles?.[pathname]?.toUpperCase()}
            </Title>
          </Row>
          <Row gutter={10}>
            <Col span={24}>
              <div style={{ fontSize: 18, marginBottom: 15, textTransform: 'uppercase' }}>
                {title()}
              </div>
              <Outlet />
            </Col>
          </Row>
          <Row
            span={8}
            justify="center"
            style={{ fontSize: '19px' }}
          >
            <span style={{ marginTop: 15 }}>
              Si deseas realizar un trámite en línea, consúltalo en el apartado de trámites
              <Link to="/tramites" style={{ marginLeft: '6px' }}>
                aquí.
              </Link>
            </span>
          </Row>
        </Col>
      </Container>
    </>
  );
};

const Drawer = styled(AntDrawer)`
  position: fixed;
  top: 0;
  
  .ant-drawer-body{
    padding: 0;
  }
  @media (max-width: 992px) {
    top: 0;
  }
  
  @media (max-width: 430px) {
    .ant-drawer-content-wrapper{
      width: 100% !important;
    }
    
  }
`;

const Container = styled(Row)`
  @media (max-width: 765px) {
    padding-inline: 25px;
  }
#menu_button:hover>*{
  color: ${COLORS.accent} !important;
}
`;

export default MiCuenta;
