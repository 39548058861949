import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Table,
  Spin,
  Row,
  Col,
  Grid,
  Form,
  Input,
  Divider,
} from 'antd';
import {
  DownOutlined,
  RightOutlined,
  SearchOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import { Button, COLORS, Text } from 'components';
import { getEmpresa } from 'api/padrones';
import { selectUser } from 'store/auth';
import { useSelector } from 'react-redux';
import {
  getTiposDeActividadEmpresarial,
  getTiposDeEstablecimientos,
  getTiposDeVialidades,
} from 'api/catalogos';
import FormItemsDireccion from 'components/Direccion/FormItemsDireccion';
import address from 'utils/address';

const { useBreakpoint } = Grid;

const MisEstablecimientos = () => {
  const user = useSelector(selectUser);
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [vialidades, setVialidades] = useState([]);

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const [
        _tiposDeEstablecimientos,
        _tiposDeActividadEmpresarial,
        _tiposDeVialidades,
        _data,
      ] = await Promise.all([
        getTiposDeEstablecimientos(),
        getTiposDeActividadEmpresarial(),
        getTiposDeVialidades(),
        getEmpresa(false, { contribuyente: user.id }),
      ]);
      const formatEmpresa = (_empresa) => ({
        ..._empresa,
        actividad: _empresa.clase_actividad_empresarial_principal
          ? _tiposDeActividadEmpresarial.find((e) => e.id === _empresa
            .clase_actividad_empresarial_principal)?.descripcion : null,
        tipo_de_establecimiento: _empresa.tipo_de_establecimiento
          ? _tiposDeEstablecimientos
            .find((e) => e.id === _empresa.tipo_de_establecimiento)?.nombre : null,
        es_informal: _empresa.es_informal ? 'Sí' : 'No',
        tiene_estacionamiento: _empresa.tiene_estacionamiento ? 'Sí' : 'No',
        clase_actividad_empresarial_principal: _empresa.clase_actividad_empresarial_principal
          ? _tiposDeActividadEmpresarial
            .find((e) => e.id === _empresa.clase_actividad_empresarial_principal)?.descripcion
          : null,
        direccion: address(_empresa?.direccion_de_ubicacion),
        ..._empresa?.direccion_de_ubicacion,
        codigo_postal: _empresa?.direccion_de_ubicacion?.codigo_postal?.d_codigo,
        pais: 'México',
        estado: _empresa?.direccion_de_ubicacion?.codigo_postal?.municipio?.estado?.nombre_de_AGEE,
        municipio: _empresa?.direccion_de_ubicacion?.codigo_postal?.municipio?.nom_mun,
      });

      if (mounted) {
        setData(_data ? _data.map(formatEmpresa) : []);
        setVialidades(_tiposDeVialidades);
        setLoading(false);
      }
    };
    fetchData();
    return () => { mounted = false; };
  }, []);

  const columns = [
    {
      title: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 320,
      responsive: ['xs', 'sm'],
    },
    {
      title: 'Nombre Comercial',
      dataIndex: 'nombre_comercial',
      key: 'nombre_comercial',
      width: 800,
      responsive: ['xs', 'sm'],
    },
    {
      title: 'Actividad',
      dataIndex: 'clase_actividad_empresarial_principal',
      key: 'clase_actividad_empresarial_principal',
      width: 700,
      responsive: ['md'],
    },
    {
      title: 'Tipo de Establecimiento',
      dataIndex: 'tipo_de_establecimiento',
      key: 'tipo_de_establecimiento',
      width: 320,
      responsive: ['md'],
    },
    {
      title: 'Dirección',
      dataIndex: 'direccion',
      key: 'direccion',
      width: 550,
      responsive: ['md'],
    },
  ];

  const rowExpandable = () => (!screens.md);

  const expandedRowRender = (record) => columns.map((column) => (
    <Row
      key={column.key}
      justify="space-between"
    >
      <b>
        {`${column.title}:`}
      </b>
      <span>
        {column.render
          ? column.render(record[column.dataIndex], record)
          : record[column.dataIndex]}
      </span>
    </Row>
  ));

  const expandIcon = ({ expanded, onExpand, record }) => (!screens.md) && (record && (
    expanded ? (
      <DownOutlined onClick={(e) => {
        e.stopPropagation();
        onExpand(record, e);
      }}
      />
    ) : (
      <RightOutlined onClick={(e) => {
        e.stopPropagation();
        onExpand(record, e);
      }}
      />
    )
  ));

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  return (
    <Spin spinning={loading}>
      <Container>
        <Row justify="end" style={{ marginBlock: 10 }}>
          {!visible ? (
            <Button
              icon={<SearchOutlined />}
              onClick={() => setVisible(true)}
              disabled={!selectedRowKeys.length}
            >
              Vista previa
            </Button>
          ) : (
            <Button
              icon={<ArrowLeftOutlined />}
              onClick={() => setVisible(false)}
            >
              Volver
            </Button>
          )}
        </Row>
        {!visible ? (
          <Row>
            <Col span={24}>
              <Table
                size="small"
                columns={columns}
                dataSource={data}
                rowSelection={rowSelection}
                onRow={(record) => ({
                  onClick: () => {
                    setSelectedRowKeys([record.key]);
                    form.setFieldsValue({
                      ...record,
                    });
                  },
                  onDoubleClick: () => setVisible(true),
                })}
                expandable={{
                  expandedRowRender,
                  rowExpandable,
                  expandIcon,
                }}
              />
            </Col>
          </Row>
        ) : (
          <Form
            layout="vertical"
            form={form}
          >
            <Row gutter={10}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Clave"
                  name="clave"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={16} lg={8}>
                <Form.Item
                  label="Nombre Comercial"
                  name="nombre_comercial"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  label="Fecha de Apertura"
                  name="fecha_de_apertura"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  label="Fecha de Alta de Padron Empresa"
                  name="fecha_de_alta_de_padron_empresa"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  label="Actividad"
                  name="actividad"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  label="Tipo de Establecimiento"
                  name="tipo_de_establecimiento"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Divider orientation="left">
                  <Text>Datos de contacto</Text>
                </Divider>
              </Col>
              <Col xs={24} sm={24} md={12} lg={8}>
                <Form.Item
                  label="Nombre del Gerente/Propietario"
                  name="nombre_del_gerente_propietario"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  label="Teléfono Celular"
                  name="telefono_celular"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  label="Teléfono Fijo"
                  name="telefono_fijo"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  label="Sitio de Internet"
                  name="sitio_de_internet"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={6} lg={8}>
                <Form.Item
                  label="Correo Electrónico"
                  name="correo_electronico"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Divider orientation="left">
                  <Text>Dirección</Text>
                </Divider>
              </Col>
            </Row>
            <FormItemsDireccion
              colonias={[]}
              vialidades={vialidades}
              isEditing={false}
            />
          </Form>
        )}
      </Container>
    </Spin>
  );
};

const Container = styled.div`
.ant-table-thead > tr > th {
  background-color: ${COLORS.accent};
  color: white;
  font-weight: bold;
}
.ant-form-item{
  margin-bottom: 5px;
}
`;

export default MisEstablecimientos;
