import React, { useState } from 'react';
import {
  Col,
  Form,
  Grid,
  Modal,
  Row,
  Spin,
  Steps,
} from 'antd';
import { Button, COLORS, Text } from 'components';
import styled from 'styled-components';
import { DEV, ID_ENTIDAD } from 'utils/env';
import DatosSolicitante, { solicitanteHasContactInfo } from 'views/Appointments/components/DatosSolicitante';
import DatosCita from 'views/Appointments/components/DatosCita';
import {
  CalendarOutlined,
  LogoutOutlined,
  SaveOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import _logger from 'utils/logger';
import { Link } from 'react-router-dom';
import ConfirmarCita from 'views/Appointments/components/ConfirmarCita';
import { toMoment } from 'utils/normalizers';
import {
  patchCiudadanoPublic,
  patchContribuyentePublic,
  postCiudadano,
  postContribuyente,
} from 'api/padrones';
import { postCita } from 'api/tramites/citas';
import { stepsEnum } from 'views/Appointments/enums';
import { scrollToTop } from 'components/ScrollToTop';

const fieldsCita = ['plantilla', 'oficina', 'fecha', 'horario'];

const getTitle = (step, currentStep) => (
  <b style={{ color: step.key === currentStep ? COLORS.accent : null }}>
    {step.title}
  </b>
);

const getStatus = (currIdx, idx) => {
  if (!currIdx) return null;
  if (currIdx > idx) return 'finish';
  if (currIdx < idx) return 'wait';
  return 'process';
};

const NewAppointment = () => {
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const horario = Form.useWatch('horario', form);
  const [currentStep, setCurrentStep] = useState(stepsEnum.DATOS_CITA);
  const [_loading, setLoading] = useState(false);
  const [cita, setCita] = useState();

  const loading = !!_loading;

  const steps = [
    {
      key: stepsEnum.DATOS_CITA,
      title: 'Datos de la cita',
    },
    {
      key: stepsEnum.DATOS_SOLICITANTE,
      title: 'Datos del solicitante',
    },
    {
      key: stepsEnum.CONFIRMACION_DE_CITA,
      title: 'Confirmación de cita',
    },
  ];

  const confirmSave = () => {
    Modal.confirm({
      title: '¿Los datos ingresados son correctos?',
      content: '',
      onOk: () => {
        setCurrentStep(stepsEnum.DATOS_SOLICITANTE);
        scrollToTop(screens);
      },
      okText: 'Si, continuar',
      cancelText: 'No, cancelar',
      cancelButtonProps: {
        block: !screens.md,
        style: {
          width: screens.md && 160,
          height: 36,
          float: screens.md && 'right',
          marginLeft: screens.md && 20,
          marginBottom: !screens.md && 10,
        },
      },
      okButtonProps: {
        block: !screens.md,
        style: {
          width: screens.md && 160,
          height: 36,
          float: screens.md && 'left',
          marginLeft: screens.md && 0,
        },
        type: 'default',
      },
    });
  };

  const rightButton = () => {
    if (currentStep === stepsEnum.DATOS_CITA) {
      return (
        <Button
          $accent
          // disabled={!horario}
          // className={!horario && 'disabled'}
          onClick={() => (horario ? confirmSave() : form.validateFields(fieldsCita))}
          block
        >
          <Text>
            Guardar
          </Text>
          <SaveOutlined />
        </Button>
      );
    }
    if (currentStep === stepsEnum.DATOS_SOLICITANTE) {
      return (
        <Button
          $accent
          onClick={form.submit}
          block
        >
          <Text>
            Agendar cita
          </Text>
          <CalendarOutlined />
        </Button>
      );
    }
    return (
      <Button $accent block>
        <Link to="/citas">
          <Text>
            Salir
          </Text>
          <LogoutOutlined />
        </Link>
      </Button>
    );
  };

  const onFinish = async (_formValues) => {
    const {
      horario: _horario,
      oficina,
      plantilla,
      ...formValues
    } = _formValues;
    setLoading(true);
    const _values = { ...formValues };
    delete _values.fecha;
    delete _values.idAcuse;
    delete _values.latitud;
    delete _values.longitud;
    const [fecha_cita, inicioFin] = _horario.split(' ');
    const [horario_inicio, horario_fin] = inicioFin.split('-');
    const values = {
      plantilla_de_tramite: plantilla,
      oficina,
      entidad: ID_ENTIDAD,
      estado_de_cita: 1, // Programada
      fecha_alta: toMoment(new Date()),
      fecha_cita: toMoment(fecha_cita, ['DD/MM/YYYY']),
      horario_inicio,
      horario_fin,
      tramite: null,
      ventanilla: null,
    };

    if (_values.contribuyente) {
      values.contribuyente = _values.contribuyente;
    } else if (_values.ciudadano) {
      values.ciudadano = _values.ciudadano;
    } else {
      const valuesSolicitante = { ..._values };
      delete valuesSolicitante.ciudadano;
      delete valuesSolicitante.contribuyente;
      const solicitanteName = 'ciudadano' in _values ? 'ciudadano' : 'contribuyente';
      const solicitante = await (solicitanteName === 'ciudadano'
        ? postCiudadano : postContribuyente)(valuesSolicitante);
      if (!solicitante) {
        setLoading(false);
        return;
      }
      if (!solicitanteHasContactInfo(_values, solicitanteName === 'ciudadano' ? 1 : 15)) {
        const updated = await (solicitanteName === 'ciudadano'
          ? patchCiudadanoPublic : patchContribuyentePublic)(valuesSolicitante);
        if (!updated) {
          setLoading(false);
          return;
        }
      }
      values[solicitanteName] = solicitante.id;
      form.setFieldsValue({ [solicitanteName]: solicitante.id, ...solicitante });
    }
    const _cita = await postCita(values);
    setLoading(false);
    if (!_cita) return;
    setCita(_cita);
    setCurrentStep(stepsEnum.CONFIRMACION_DE_CITA);
  };

  const getTip = () => {
    if (_loading === 'invalid_date') {
      return (
        <Text strong>
          <WarningOutlined style={{ color: COLORS.warn, fontSize: 32 }} />
          <br />
          Revise la fecha y hora de su dispositivo.
          <br />
          Una vez corregida inténtelo de nuevo.
        </Text>
      );
    }
    return undefined;
  };

  return (
    <Container>
      <Spin spinning={loading} tip={getTip()} indicator={_loading === 'invalid_date' ? null : undefined}>
        <Steps
          size="small"
          progressDot
          labelPlacement="vertical"
          current={steps.findIndex((s) => s.key === currentStep)}
          style={{ overflowX: 'scroll', paddingTop: 10 }}
          direction="horizontal"
          responsive={false}
        >
          {steps.map((step, idx) => (
            <Steps.Step
              key={step.key}
              title={getTitle(step, currentStep)}
              status={getStatus(steps.findIndex((s) => s.key === currentStep), idx)}
            />
          ))}
        </Steps>
        <StepsContent>
          <Form
            preserve
            scrollToFirstError
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{ tipo_de_persona: 1 }}
            onFinishFailed={(errorInfo) => DEV && _logger('[onFinishFailed]', errorInfo)}
          >
            <DatosCita
              visible={currentStep === stepsEnum.DATOS_CITA}
              loading={loading}
              setLoading={setLoading}
              cita={cita}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
            <DatosSolicitante
              visible={currentStep === stepsEnum.DATOS_SOLICITANTE}
              loading={loading}
              setLoading={setLoading}
            />
            <ConfirmarCita
              visible={currentStep === stepsEnum.CONFIRMACION_DE_CITA}
              loading={loading}
              setLoading={setLoading}
              cita={cita}
            />
          </Form>
          <Row justify="space-between" style={{ marginTop: 20 }}>
            <Col />
            <Col style={{ width: 180 }}>
              <Form.Item style={{ width: 180 }}>
                {rightButton()}
              </Form.Item>
            </Col>
          </Row>
        </StepsContent>
        <span />
      </Spin>
    </Container>
  );
};

const Container = styled.div`
  min-height: 100%;
  overflow: hidden scroll;
  padding: 20px 30px;

  .ant-form-item-label label,
  .ant-select-selection-item,
  .ant-input {
    font-size: 16px;
  }
  .ant-form-item-control-input-content,
  .ant-select-single:not(.ant-select-customize-input):not(.ant-picker-calendar-year-select)
  :not(.ant-picker-calendar-month-select) .ant-select-selector {
    /* height: 36px; */
  }
  .ant-steps {
    .ant-steps-item-container>.ant-steps-item-icon {
      border-color: ${COLORS.cream};
      background: ${COLORS.cream};
      .ant-steps-icon {
        color: ${COLORS.white};
      }
    }
    .ant-steps-item-description {
      font-size: 12px;
      font-weight: bold;
    }
    .ant-steps-item-finish .ant-steps-icon-dot,
    .ant-steps-item-process .ant-steps-icon-dot {
      background: ${COLORS.accent};
      border-color: ${COLORS.accent};
    }
    .ant-steps-item-process .ant-steps-icon-dot {
      width: 14px;
      height: 14px;
      bottom: 2px !important;
      left: -2px !important;
    }
  }
  .ant-steps-item-tail:after {
    background-color: ${COLORS.text} !important;
    height: 3px;
  }
  @media only screen and (max-width: 576px) {
    padding: 12px;
  }
`;

const StepsContent = styled.div`
  position: relative;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  padding: 20px 20px 10px;
  .scrollable {
    min-height: calc(100vh - 450px);
    overflow: hidden scroll;
    margin-bottom: 10px;
  }
  .ant-form {
    min-height: calc(100vh - 450px);
  }

  @media only screen and (max-width: 576px) {
    padding: 12px;
  }
  @media only screen and (max-width: 768px) {
    .ant-form {
      height: calc(100vh - 350px) !important;
      overflow: hidden scroll !important;
    }
  }
`;

export default NewAppointment;
