import {
  Avatar,
  Card as AntCard,
  Col,
  Row,
} from 'antd';
import PropTypes from 'prop-types';
import {
  Button,
  COLORS,
  Card as CustomCard,
  Text,
  CardContainer,
  Title,
} from 'components';
import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import {
  ArrowRightOutlined,
  DollarCircleOutlined,
  DollarOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { propsPerEntity } from 'components/Shortcuts';
import { ENV_NAME } from 'utils/env';

const { Meta } = AntCard;
const types = {
  BANNER: 'banner',
  BUTTON: 'button',
  MINIBANNER: 'minibanner',
  CLASSIFIER: 'classifiers',
};
// pago-compuesto
const allowClassifiers = [
  '01.06',
  '01.07',
  '01.08',
  '01.10',
  '01.11',
  '01.12',
  '01.14',
  '01.26',
  '01.27',
  '01.28',
];
const NavigationCard = ({
  to,
  text,
  icon,
  type,
  buttonLabel,
  bannerBody,
  clasificador,
}) => {
  // useEffect(() => {
  //   console.log('clasificador', clasificador);
  // }, [clasificador]);
  const navigate = useNavigate();
  const hasIcon = !!icon && icon?.type?.render?.displayName !== 'DollarOutlined';

  if (type === types.CLASSIFIER) {
    return (
      <Col xs={24} sm={24} md={24} lg={8} style={{ minWidth: '60pc !important' }}>
        <CustomCard
          className="col"
          style={{
            border: 'none',
            boxShadow: 'none',
            padding: '0 0 40px 0',
          }}
        >
          <Button
            $accent
            block
            style={{
              minHeight: 100,
              borderRadius: 8,
              height: '-webkit-fill-available',
            }}
          >
            <Link to={to}>
              <Row>
                <Col xs={24} sm={24} md={8} style={{ alignSelf: 'center' }}>
                  {icon ? (
                    <Icon src={icon} alt="icon" />
                  ) : (
                    <DollarCircleOutlined style={{ fontSize: 50 }} />
                  )}
                </Col>
                <Col xs={24} sm={24} md={16} style={{ alignSelf: 'center' }}>
                  {text}
                </Col>
              </Row>
            </Link>
          </Button>
        </CustomCard>
      </Col>
    );
  }

  if (type === types.MINIBANNER) {
    return (
      <Col xs={24} sm={24} md={24} lg={8} style={{ minWidth: '60pc !important' }}>
        <CustomCard>
          <Row align="middle" style={{ height: '100%' }}>
            <Text
              style={{
                fontWeight: '500',
                width: 'calc(100% - 36px)',
                paddingRight: 5,
                textAlign: 'left',
                fontSize: 16,
              }}
            >
              {text}
            </Text>
            <Button
              $accent
              shape="circle"
              style={{
                float: 'right',
                height: 32,
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {
                allowClassifiers.includes(clasificador) ? (
                  <Link to={`${to}/pago-compuesto`}>
                    <RightOutlined />
                  </Link>
                ) : (
                  <Link to={to}>
                    <RightOutlined />
                  </Link>
                )
              }
            </Button>
          </Row>
        </CustomCard>
      </Col>
    );
  }

  if (type === types.BUTTON) {
    return (
      <Col xs={24} sm={24} md={8}>
        <CustomButton
          onClick={() => navigate(to)}
          block
        >
          <Row justify="center" gutter={[10, 15]}>
            <Col span={24}>
              {icon || <DollarOutlined />}
            </Col>
            <Col span={24}>
              {text}
            </Col>
          </Row>
        </CustomButton>
      </Col>
    );
  }

  if (type === types.BANNER) {
    return (
      <Col span={24}>
        <CardContainer className="col">
          <Title color={propsPerEntity[ENV_NAME].color}>
            {text}
          </Title>
          <Text style={{ textAlign: 'center' }} color={propsPerEntity[ENV_NAME].color}>
            {bannerBody}
          </Text>
          <Button color={propsPerEntity[ENV_NAME].color} $accent>
            <Link to={to}>
              {buttonLabel}
              {' '}
              <ArrowRightOutlined />
            </Link>
          </Button>
        </CardContainer>
      </Col>
    );
  }

  return (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={8}
      style={{
        minWidth: '60pc !important',
        marginBottom: '20px',
      }}
    >
      <Card
        onClick={() => navigate(to)}
        title=" "
      >
        <Meta
          className={hasIcon ? 'has-icon' : 'no-icon'}
          avatar={<Avatar src={icon || <DollarCircleOutlined />} />}
        />
        <br />
        <Row justify="center" gutter={[10, 15]} align="middle">
          <Col>
            {text}
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

const Icon = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin: 30px auto;
  border: 3px solid #000;
`;

const Card = styled(AntCard)`
  border-radius: 8px;
  min-height: 100px;
  font-size: 18px;
  height: 100%;
  font-weight: bold;
  white-space: break-spaces;
  border-color: ${COLORS.gray};
  background: transparent;
  color: ${COLORS.gray};
  .ant-card-head {
    position: absolute;
    border-bottom: 1px solid #d3d3d3;
    width: -webkit-fill-available;
  }
  .has-icon {
    .ant-avatar.ant-avatar-circle.ant-avatar-image {
      outline: 4px solid ${COLORS.accent};
      background-color: ${COLORS.accent};
    }
    span[role="img"] {
      background-color: transparent;
    }
    svg{
      color: ${COLORS.white};
    }
  }

  .ant-avatar.ant-avatar-circle.ant-avatar-image {
    display: grid;
    place-items: center;
    width: 50px;
    height: 50px;
    color: ${COLORS.accent};
    background: #f0f2f5;
  }

  span[role="img"] {
    font-size: 45px;
  }
  &:active,
  &:hover,
  &:visited,
  &:focus {
    cursor: pointer;
    box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
    outline: 2px solid ${COLORS.accent};
  }
  @media (max-width: 877px) {
    &:last-child {
      font-size: 20px;
    }
  }
  @media (max-width: 767px) {
    &:last-child {
      font-size: 30px;
    }
  }
`;

const CustomButton = styled(Button)`
  border-radius: 8px;
  min-height: 100px;
  font-size: 20px;
  padding: 30px;
  height: -webkit-fill-available;
  font-weight: bold;
  white-space: break-spaces;
  border-color: ${COLORS.gray};
  background: transparent;
  color: ${COLORS.gray};

  span[role="img"] {
    font-size: 80px;
  }
  .anticon svg *:where(path, rect) {
    fill: ${COLORS.gray};
    .transparent {
      fill: #FFFFFF;
    }
  }
  &:active,
  &:hover,
  &:visited,
  &:focus {
    color: ${COLORS.white} !important;
    border-color: ${COLORS.accent} !important;
    background: ${COLORS.accent} !important;
    opacity: 1;
    .anticon svg *:where(path, rect) .transparent {
      fill: ${COLORS.accent};
    }
  }
  @media (max-width: 877px) {
    &:last-child {
      font-size: 20px;
    }
  }
  @media (max-width: 767px) {
    &:last-child {
      font-size: 30px;
    }
  }
`;

NavigationCard.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.node,
  type: PropTypes.oneOf([
    'card',
    'banner',
    'button',
    'minibanner',
    'classifiers',
  ]),
  bannerBody: PropTypes.node,
  buttonLabel: PropTypes.string,
  clasificador: PropTypes.string.isRequired,
};

NavigationCard.defaultProps = {
  icon: null,
  type: 'card',
  bannerBody: null,
  buttonLabel: '',
};

export default NavigationCard;
