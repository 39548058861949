import API from 'api';
import { message } from 'antd';
import _logger from 'utils/logger';
import { ID_ENTIDAD } from 'utils/env';

export const baseURI = 'recaudacion/historiales-de-citas-public/';

/**
 * GET Citas
 * param {object} params
 * @returns {Promise<object>}
 */
export const getHistorialDeCitas = async (params) => {
  try {
    const response = await API.get(baseURI, { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * POST Historial De Cita
  * @param {object} values
 * @returns {Promise<object|boolean>}
 */
export const postHistorialDeCita = async (values) => {
  try {
    const response = await API.post(baseURI, {
      ...values,
      entidad: ID_ENTIDAD,
    });
    message.info(response.detail || 'Agregado correctamente');
    return response.data;
  } catch (err) {
    _logger(err);
    return false;
  }
};
