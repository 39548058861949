import { Grid, message } from 'antd';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const getScrollableArea = (screens) => document
  .querySelector(`#root > section ${screens.md ? 'main' : ''}`);

export const scrollToTop = (screens) => {
  getScrollableArea(screens)?.scrollTo({
    top: screens.md ? 0 : 160,
    left: 0,
    behavior: 'smooth',
  });
  message.destroy();
};

export default function ScrollToTop() {
  const screens = Grid.useBreakpoint();
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop(screens);
  }, [pathname]);

  return null;
}
