import React from 'react';
import PropTypes from 'prop-types';
import { Col, Typography } from 'antd';

const { Text } = Typography;

const Instrucciones = ({ instrucciones }) => {
  if (!instrucciones) {
    return null;
  }

  return (
    <Col span={24} style={{ marginBottom: 15 }}>
      <Text style={{ whiteSpace: 'pre-wrap' }}>
        {instrucciones}
      </Text>
    </Col>
  );
};

Instrucciones.propTypes = {
  instrucciones: PropTypes.string.isRequired,
};

export default Instrucciones;
