import round from 'utils/round';
import {
  postGenererReferenciasBanco,
  getReferenciaBancoPublic,
  banorteEncrypted,
} from 'api/recaudacion';
import _logger from 'utils/logger';
// import axios from 'axios';

// const http = axios.create({
//   headers: { 'Content-Type': 'application/json; charset=UTF-8', Accept: 'application/json' },
//   baseURL: 'https://banorte.com/chequeelectronico2.0/',
// });

const BBVA = async (
  tipoDePadron,
  padron,
  _cargos,
  padrones,
  currentClassifier,
  descripcion,
) => {
  const _referenciaFolio = await postGenererReferenciasBanco(
    tipoDePadron,
    padron,
    _cargos,
    padrones,
  );

  const _referencia = await getReferenciaBancoPublic(
    _referenciaFolio.folio_banco,
    descripcion,
  );

  const n_contrib = padron?.empresa?.contribuyente?.nombre_completo
    || padron?.empresa?.contribuyente?.razon_social
    || padron?.empresa?.nombre_comercial;

  const s_dec = `Impuesto de ${
    currentClassifier?.nombre || currentClassifier?.descripcion
  }`;
  const c_referencia = _referencia?.length > 0 ? _referencia[0].cadena_algoritmo : [];
  const importe = _cargos.reduce((acum, curr) => acum + curr.adeudo_total, 0);
  const t_importe = round(importe)[0].toFixed(2);

  const url = new URL('https://www.adquiramexico.com.mx/clb/endpoint/nayarit');
  // const url = new URL('https://preproadquiracloud.mx/clb/endpoint/nayarit');
  url.search = new URLSearchParams({
    s_transm: '00000000000000030587',
    c_referencia,
    t_servicio: '99',
    t_pago: '02',
    n_contrib,
    t_importe,
    s_dec,
    mp_urlsuccess: 'https://apifuncionarios.minayarit.gob.mx/configuracion/portal/',
    mp_urlfailure: 'https://apifuncionarios.minayarit.gob.mx/configuracion/portal/',
    val_1: '0',
    val_2: '0',
  });

  return url.href;
};

const Santander = async (
  tipoDePadron,
  padron,
  _cargos,
  padrones,
  currentClassifier,
  descripcion,
) => {
  const _referenciaFolio = await postGenererReferenciasBanco(
    tipoDePadron,
    padron,
    _cargos,
    padrones,
  );

  const _referencia = await getReferenciaBancoPublic(
    _referenciaFolio.folio_banco,
    descripcion,
  );
  const referencia = _referencia?.length > 0 ? _referencia[0].cadena_algoritmo : [];
  const importe = _cargos.reduce((acum, curr) => acum + curr.adeudo_total, 0);
  const t_importe = round(importe)[0].toFixed(2);
  const convenio = _referencia[0]?.clave_convenio_de_operacion_de_receptor.split(
    'Convenio ',
  )[1] || '1288';

  const url = new URL(
    'https://www.santander.com.mx/Supernet2007/homeMicrositio.jsp',
  );
  url.search = new URLSearchParams({
    url_resp: 'https://hacienda.migob.mx/',
    convenio,
    referencia,
    importe: t_importe,
  });

  return url.href;
};

const Banorte = async (
  tipoDePadron,
  padron,
  _cargos,
  padrones,
  currentClassifier,
  descripcion,
) => {
  const _referenciaFolio = await postGenererReferenciasBanco(
    tipoDePadron,
    padron,
    _cargos,
    padrones,
  );

  const _referencia = await getReferenciaBancoPublic(
    _referenciaFolio.folio_banco,
    descripcion,
  );
  const referencia = _referencia?.length > 0 ? _referencia[0].cadena_algoritmo : [];
  const importe = _cargos.reduce((acum, curr) => acum + curr.adeudo_total, 0);
  const t_importe = round(importe)[0].toFixed(2);

  const values = {
    mode: 'T',
    txCount: '1',
    responseUrl: 'https://hacienda.migob.mx/',
    txId1: referencia,
    concept1: `Impuesto de ${
      currentClassifier?.nombre || currentClassifier?.descripcion
    }`,
    clientId1: 'CHE0510161',
    reference1: referencia,
    amount: t_importe,
  };

  const encriptar = async () => {
    try {
      const response = await banorteEncrypted(values);
      if (response) {
        return response.data;
      }
      return null;
    } catch (error) {
      _logger(error);
      return null;
    }
  };

  const token = await encriptar();

  if (token) {
    return 'https://banorte.com/chequeelectronico2.0/?token=EiWZNCdNEENMfEqnoTzPfxWDhFzsiCEnnX+efH/v/5kWv+wWx6qBW2PMMqV8lkptN8pR+eKClxrRX2wsYJUFDasJC1oJRQGkRfn0OdM5Q44=';
  }

  return null;
};

const generateReferenceBank = {
  bbva: (...arg) => BBVA(...arg),
  santander: (...arg) => Santander(...arg),
  banorte: (...arg) => Banorte(...arg),
};

export default generateReferenceBank;
