import React, { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Form,
  Grid,
  Input,
  Row,
  Table,
  Modal,
} from 'antd';
import {
  Button,
  COLORS,
  Text,
  Title,
} from 'components';
import { scrollToTop } from 'components/ScrollToTop';
import {
  BarcodeOutlined,
  CloseCircleOutlined,
  // DeleteOutlined,
  DollarCircleOutlined,
  LoadingOutlined,
  SearchOutlined,
  CreditCardOutlined,
} from '@ant-design/icons';
import NetPaybutton, {
  paymentLocationsEnum,
  validateLastTransaction,
} from 'views/Payments/components/NetPayButton';
import { useSelector } from 'react-redux';
import Previewer from 'components/Previewer';
import useQuery from 'hooks/useQuery';
import { getDatosDePagoConFolioDeOrdenDePago, postCargosPorReferenciaDePago, postReferenciasBanco } from 'api/recaudacion';
import gettersMap from 'api/padrones';
import formatReference from 'utils/formatReference';
import { toCurrency } from 'utils/formatters';
import round from 'utils/round';
import Spinner from 'components/Spinner';
import ModalBancos from 'views/Payments/components/ModalBancos';
import {
  selectPadrones,
} from 'store/catalogos';

const PaymentOrder = () => {
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const [visiblePreviewRecibo, setVisiblePreviewRecibo] = useState(false);
  const [visiblePreviewOrden, setVisiblePreviewOrden] = useState(false);
  const [generandoRecibo, setGenerandoRecibo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [referencia, setReferencia] = useState();
  const [recibo, setRecibo] = useState();
  const [folio] = useQuery(['folio']);
  const [cargos, setCargos] = useState([]);
  const [visibleCargos, setVisibleCargos] = useState(false);
  const [visibleBancos, setVisibleBancos] = useState(false);
  const [isNewFolio, setIsNewFolio] = useState(true);

  const [total] = round(cargos?.reduce((accum, curr) => accum + curr.total_real, 0));

  const padrones = useSelector(selectPadrones);

  useEffect(() => {
    if (folio) {
      form.setFieldsValue({ folio });
      setTimeout(() => form.submit());
    }
  }, []);

  useEffect(() => {
    validateLastTransaction(false, async (_referencia) => {
      const _recibo = await formatReference(_referencia);
      setTimeout(() => {
        setGenerandoRecibo(false);
        setRecibo(_recibo);
        setVisiblePreviewRecibo(true);
      }, 1000);
    }, setLoading, paymentLocationsEnum.PAYMENT_ORDER, true, setGenerandoRecibo);
  }, []);

  const onCancel = () => {
    setVisiblePreviewRecibo(false);
    setVisiblePreviewOrden(false);
    setVisibleCargos(false);
    setVisibleBancos(false);
    setGenerandoRecibo(false);
    setIsNewFolio(true);
    setReferencia();
    form.resetFields();
    scrollToTop(screens);
  };

  const onFinishSearch = async () => {
    setLoading(true);
    const { folio: _folio } = form.getFieldsValue();
    const _referencia = await getDatosDePagoConFolioDeOrdenDePago({ folio: _folio });
    if (_referencia !== null) {
      const _cargos = await postCargosPorReferenciaDePago({ folio: _folio });
      setCargos(_cargos);
      const padron = await gettersMap[_referencia.tipo_de_padron]({ id: _referencia.padron_id });
      if (padron) {
        _referencia.padron = padron;
        _referencia.folio = _folio;
        setReferencia(_referencia);
      }
    }
    setLoading(false);
  };

  const generarReferenciaBanco = async (_cargos) => {
    setLoading(true);
    const cargosToReference = _cargos;
    const { padron, tipo_de_padron: tipoDePadron } = referencia;

    const _referencia = await postReferenciasBanco({
      tipoDePadron,
      padron,
      cargosToReference,
      padrones,
      cargos: _cargos,
      totalProp: 'total_real',
    });
    setIsNewFolio(false);
    setLoading(false);
    return _referencia;
  };

  const rules = {
    required: [{ required: true }],
  };

  const getBase64 = () => {
    if (visiblePreviewRecibo) {
      return recibo?.base64;
    }
    if (visiblePreviewOrden) {
      return referencia?.base64.at(-1) === ','
        ? referencia?.base64.substring(0, (referencia?.base64?.length ?? 0) - 1)
        : referencia?.base64;
    }
    return null;
  };

  return (
    <>
      <Col span={24}>
        <Title style={{ textAlign: 'center', margin: 0 }}>
          Orden de Pago
        </Title>
      </Col>
      <Card
        title={(
          <Title level={2} $accent>
            Búsqueda
          </Title>
        )}
        style={{ width: '100%' }}
        bordered
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinishSearch}
          style={{ width: '100%' }}
        >
          <Row justify="end" gutter={[10, 10]}>
            <Col span={24}>
              <Form.Item
                label="Folio"
                name="folio"
                rules={rules.required}
              >
                <Input
                  disabled={!!referencia || loading}
                  suffix={loading ? <LoadingOutlined /> : null}
                />
              </Form.Item>
            </Col>
            {referencia ? (
              <>
                <NetPaybutton
                  referencia={referencia?.folio}
                  loading={loading}
                  cargos={cargos}
                  padron={referencia.padron}
                  createdBy="PAYMENT_ORDER"
                  totalProp="total_real"
                />
                <Button
                  block={!screens.md}
                  onClick={() => setVisibleCargos(true)}
                  loading={loading}
                  $accent
                  style={{ marginRight: screens.md ? 5 : 0 }}
                >
                  Consultar cargos
                  <DollarCircleOutlined />
                </Button>
                <Button
                  block={!screens.md}
                  onClick={() => setVisiblePreviewOrden(true)}
                  loading={loading}
                  $accent
                  style={{ marginRight: screens.md ? 5 : 0 }}
                >
                  Orden de pago
                  <BarcodeOutlined />
                </Button>
                <Col xs={24} sm={24} md={6}>
                  <Button
                    onClick={() => setVisibleBancos(true)}
                    loading={loading}
                    block
                    $accent
                  >
                    Transferencia bancaria
                    <CreditCardOutlined />
                  </Button>
                </Col>
                {/* <Button
                  block={!screens.md}
                  onClick={onCancel}
                  loading={loading}
                  // style={{ marginLeft: screens.md ? 5 : 0 }}
                  $accent
                >
                  Cancelar
                  <DeleteOutlined />
                </Button> */}
                {/** '23080000164' */}
              </>
            ) : (
              <Col>
                <Button
                  block={!screens.md}
                  htmlType="submit"
                  loading={loading}
                  $accent
                >
                  Buscar
                  <SearchOutlined />
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </Card>
      <Previewer
        visible={visiblePreviewRecibo || visiblePreviewOrden}
        base64={getBase64()}
        onCancel={() => {
          if (visiblePreviewOrden) {
            setVisiblePreviewRecibo(false);
            setVisiblePreviewOrden(false);
          } else {
            onCancel();
          }
        }}
      />
      <Modal
        maskClosable
        closable={false}
        visible={visibleCargos}
        title={(
          <Row justify="space-between">
            <Col>
              <Title level={2}>
                Cargos a pagar
              </Title>
            </Col>
            <Col>
              <Title level={2} $accent>
                {toCurrency(total)}
              </Title>
            </Col>
          </Row>
        )}
        centered
        width="80em"
        okText="Cerrar"
        footer={null}
      >
        <Table
          size="small"
          rowKey="id"
          dataSource={cargos}
          bordered
          pagination={{
            position: ['bottomLeft'],
          }}
          columns={[
            {
              title: 'Descripcion',
              dataIndex: 'descripcion',
            },
            {
              title: 'Adeudo Total',
              dataIndex: 'total_real',
              render: (val) => <b style={{ color: COLORS.accent }}>{toCurrency(val)}</b>,
              align: 'right',
            },
          ]}
        />
        <br />
        <Row justify="end">
          <Button
            onClick={() => setVisibleCargos(false)}
            $accent
          >
            <Text>Cerrar</Text>
            <CloseCircleOutlined />
          </Button>
        </Row>
      </Modal>
      <Spinner visible={generandoRecibo} />
      <ModalBancos
        visible={visibleBancos}
        onCancel={() => { setVisibleBancos(false); }}
        cargosGenerados={cargos}
        cbReferenciaBanco={() => generarReferenciaBanco(cargos)}
        isNewFolio={isNewFolio}
      />
    </>
  );
};

export default PaymentOrder;
