import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Table,
  Spin,
  Row,
  Col,
  Grid,
  Form,
  Input,
} from 'antd';
import {
  DownOutlined,
  RightOutlined,
  SearchOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons';
import { Button, COLORS } from 'components';
import { getVehiculo } from 'api/padrones';
import { selectUser } from 'store/auth';
import { useSelector } from 'react-redux';
import { getEstadosDeVehiculo } from 'api/recaudacion';
import { ciudadanoToString, contribuyenteToString } from 'utils/padronesToString';
import Select from 'components/Select';

const { useBreakpoint } = Grid;

const getPropietario = (record) => {
  let propietario = '';
  if (record?.contribuyente_propietario) {
    propietario = contribuyenteToString(record?.contribuyente_propietario);
  }
  if (record?.propietario) {
    propietario = ciudadanoToString(record?.propietario);
  }
  return propietario;
};

const MisVehiculos = () => {
  const user = useSelector(selectUser);
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [estadosDelVehiculo, setEstadosDelVehiculo] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const [
        _estados_de_vehiculo,
        _data,
      ] = await Promise.all([
        getEstadosDeVehiculo(),
        getVehiculo(false, { contribuyente_propietario: user.id }),
      ]);

      if (mounted) {
        if (_data) {
          setData(_data.map((item) => ({
            ...item,
            marca: item?.linea?.marca?.nombre,
            linea: item?.linea?.nombre,
            color: item?.color_del_vehiculo?.descripcion,
            propietario: getPropietario(item),
            servicio: item?.servicio?.nombre,
            clase: item?.clase_del_vehiculo?.nombre || item?.clase_del_vehiculo?.descripcion,
          })));
        }
        setEstadosDelVehiculo(_estados_de_vehiculo);
        setLoading(false);
      }
    };
    fetchData();
    return () => { mounted = false; };
  }, []);

  const columns = [
    {
      title: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 320,
      responsive: ['xs', 'sm'],
    },
    {
      title: 'Serie',
      dataIndex: 'numero_de_identificacion_vehicular',
      key: 'numero_de_identificacion_vehicular',
      width: 800,
      responsive: ['xs', 'sm'],
    },
    {
      title: 'Placa',
      dataIndex: 'numero_de_placa_vigente',
      key: 'numero_de_placa_vigente',
      width: 700,
      responsive: ['md'],
    },
    {
      title: 'Marca',
      dataIndex: 'marca',
      key: 'marca',
      width: 320,
      responsive: ['md'],
    },
    {
      title: 'Tarjeta de Circulación',
      dataIndex: 'tarjeta_de_circulacion',
      key: 'tarjeta_de_circulacion',
      width: 550,
      responsive: ['md'],
    },
    {
      title: 'Estatus del Vehículo',
      dataIndex: 'estatus_del_vehiculo',
      key: 'estatus_del_vehiculo',
      width: 550,
      responsive: ['md'],
      render: (val) => estadosDelVehiculo.find((estado) => estado.id === val)?.nombre,
    },
  ];

  const rowExpandable = () => (!screens.md);

  const expandedRowRender = (record) => columns.map((column) => (
    <Row
      key={column.key}
      justify="space-between"
    >
      <b>
        {`${column.title}:`}
      </b>
      <span>
        {column.render
          ? column.render(record[column.dataIndex], record)
          : record[column.dataIndex]}
      </span>
    </Row>
  ));

  const expandIcon = ({ expanded, onExpand, record }) => (!screens.md) && (record && (
    expanded ? (
      <DownOutlined onClick={(e) => {
        e.stopPropagation();
        onExpand(record, e);
      }}
      />
    ) : (
      <RightOutlined onClick={(e) => {
        e.stopPropagation();
        onExpand(record, e);
      }}
      />
    )
  ));

  const rowSelection = {
    selectedRowKeys,
    type: 'radio',
  };

  return (
    <Spin spinning={loading}>
      <Container>
        <Row justify="end" style={{ marginBlock: 10 }}>
          {!visible ? (
            <Button
              icon={<SearchOutlined />}
              onClick={() => setVisible(true)}
              disabled={!selectedRowKeys.length}
            >
              Vista previa
            </Button>
          ) : (
            <Button
              icon={<ArrowLeftOutlined />}
              onClick={() => setVisible(false)}
            >
              Volver
            </Button>
          )}
        </Row>
        {!visible ? (
          <Row>
            <Col span={24}>
              <Table
                size="small"
                columns={columns}
                dataSource={data}
                rowSelection={rowSelection}
                onRow={(record) => ({
                  onClick: () => {
                    setSelectedRowKeys([record.key]);
                    form.setFieldsValue({ ...record });
                  },
                  onDoubleClick: () => setVisible(true),
                })}
                expandable={{
                  expandedRowRender,
                  rowExpandable,
                  expandIcon,
                }}
              />
            </Col>
          </Row>
        ) : (
          <Form
            layout="vertical"
            form={form}
          >
            <Row gutter={10}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Clave"
                  name="clave"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Serie"
                  name="numero_de_identificacion_vehicular"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Marca"
                  name="marca"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Línea"
                  name="linea"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Modelo"
                  name="modelo"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Propietario"
                  name="propietario"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Clase del Vehículo"
                  name="clase"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Color"
                  name="color"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Servicio"
                  name="servicio"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Número de Puertas"
                  name="numero_de_puertas"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Número de chasis"
                  name="numero_de_chasis"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Número de motor"
                  name="numero_de_motor"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  label="Procendencia del Vehículo"
                  name="procedente_de_wizard"
                >
                  <Select
                    disabled
                    options={[
                      {
                        value: true,
                        label: 'Nacional',
                      },
                      {
                        value: false,
                        label: 'Extranjero',
                      },
                    ]}
                    getLabel={(option) => option.label}
                    getValue={(option) => option.value}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={16}>
                <Form.Item
                  label="Observaciones"
                  name="observaciones"
                >
                  <Input.TextArea autoSize={{ minRows: 4, maxRows: 4 }} disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Container>
    </Spin>
  );
};

const Container = styled.div`
.ant-table-thead > tr > th {
  background-color: ${COLORS.accent};
  color: white;
  font-weight: bold;
}
.ant-form-item{
  margin-bottom: 5px;
}
`;

export default MisVehiculos;
