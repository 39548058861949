import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Modal,
  Row,
  Col,
} from 'antd';
import _logger from 'utils/logger';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Text,
  Title,
} from 'components';
import { RECEIPT_LABEL } from 'utils/env';

const ModalName = ({
  visible,
  setVisible,
  onCancel,
  callback,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  const handleOk = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const { nameTemp } = form.getFieldsValue();
      localStorage.setItem('nameTemp', nameTemp);
      form.resetFields();
      callback();
      setVisible(false);
      setLoading(false);
    } catch (err) {
      _logger(err);
      setLoading(false);
    }
  };

  return (
    <Modal
      title={(<Title level={3}>Actualizar información</Title>)}
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      okText="Actualizar"
      cancelText="Cancelar"
      maskClosable={false}
      centered
      closable={false}
      confirmLoading={loading}
      footer={(
        <Row justify="end" gutter={[10, 15]}>
          <Col xs={24} sm={24} md={8}>
            <Button
              onClick={onCancel}
              icon={<ArrowLeftOutlined />}
              disabled={loading}
              style={{ width: '100%' }}
            >
              Cancelar
            </Button>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Button
              type="primary"
              style={{ width: '100%' }}
              onClick={handleOk}
              loading={loading}
              icon={<SaveOutlined />}
              $accent
            >
              Guardar
            </Button>
          </Col>
        </Row>
      )}
    >
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleOk}
          >
            <Col span={24}>
              <Card>
                <Text style={{ textAlign: 'center' }}>
                  Si se desea hacer un cobro sin registro es necesario que se proporcione un
                  nombre para poder identificar el responsable del
                  {' '}
                  {RECEIPT_LABEL}
                  {' '}
                  que se emita.
                </Text>
              </Card>
            </Col>
            <br />
            <Col span={24}>
              <Form.Item
                rules={[{ required: true }]}
                label="Nombre"
                name="nameTemp"
              >
                <Input />
              </Form.Item>
            </Col>

            <Form.Item hidden>
              <Button htmlType="submit" />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

ModalName.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  setVisible: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
};

export default ModalName;
