/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { Text } from 'components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const A = styled.a.attrs(({ to, ...attrs }) => ({ href: to, ...attrs }))``;

const LinkWithTooltip = ({
  to,
  title,
  text,
  icon: Icon,
  linkStyle,
  textProps,
  tooltipProps,
  asA,
}) => {
  const LinkComponent = asA ? A : Link;
  return (
    <>
      <LinkComponent to={to} style={{ ...linkStyle, width: 'auto' }}>
        <Text {...textProps} style={{ fontSize: 'inherit', ...textProps }}>
          {text}
          {Icon && !title && (
            <Icon style={{ fontSize: 'inherit', color: 'inherit', marginLeft: 5 }} />
          )}
        </Text>
      </LinkComponent>
      {!!(Icon && title) && (
        <Tooltip title={title} {...tooltipProps}>
          <Text {...textProps}>
            <Icon style={{ fontSize: 'inherit', color: 'inherit', marginLeft: 5 }} />
          </Text>
        </Tooltip>
      )}
    </>
  );
};

LinkWithTooltip.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  asA: PropTypes.bool,
  icon: PropTypes.any,
  linkStyle: PropTypes.shape({}),
  textProps: PropTypes.shape({}),
  tooltipProps: PropTypes.shape({}),
};

LinkWithTooltip.defaultProps = {
  icon: null,
  linkStyle: null,
  textProps: null,
  tooltipProps: null,
  title: null,
  asA: false,
};

export default LinkWithTooltip;
