/* eslint-disable no-unused-expressions */
import { useState, useEffect, useRef } from 'react';

const initialData = {
  q: null,
  page: 1,
  count: 0,
  next: true,
  prev: false,
  params: {},
  results: [],
};

const _getter = () => new Error('Getter is required');

/**
 * @param {func} getter Function to get data
 * @param {object} config
 * @param {boolean} config.auto Auto fetch first page
 * @param {string|number} config.selfValue
 * @param {boolean} config.useLoadingState
 * @param {boolean} config.onlyOnePage
 * @param {object} config.params
 * @returns [] [data, fetchData, loading]
 */
const usePaginatedFetcher = (getter = _getter, config = {}) => {
  const [data, setData] = useState(initialData);
  const loadingRef = useRef(config.auto || false);
  const [loading, setLoading] = useState(config.auto || false);

  const getDistinctResults = (_results) => [...new Set(_results.map(({ id }) => id))]
    .map((id) => _results.find((e) => e.id === id));

  const changeLoading = (state) => {
    if (config.useLoadingState) {
      setLoading(state);
    } else {
      loadingRef.current = state;
    }
  };

  const fetchData = async (page = 1, q = null, _params = null) => {
    changeLoading(true);
    const params = { ...config.params, ..._params };
    params.page = page;
    if (q) {
      params[config?.qName || 'q'] = q;
    }
    const samePage = page === data.page && !(page === 1 && data.page === 1);
    const sameQ = q === data.q;
    if (!(samePage && sameQ)) {
      const _data = await getter(params);
      if (page !== 1 && !config.onlyOnePage) {
        setData((prev) => ({
          q,
          page,
          count: _data?.count,
          params: _params,
          next: Boolean(_data.next),
          prev: Boolean(_data.next),
          results: getDistinctResults([...prev.results, ..._data.results]),
        }));
      } else {
        setData({
          q: _data.q || null,
          page,
          count: _data.count,
          params: _params,
          next: Boolean(_data.next),
          prev: Boolean(_data.prev),
          results: _data.results,
        });
      }
    }
    changeLoading(false);
  };

  useEffect(() => {
    if (config.auto && !config.selfValue) {
      fetchData();
    }
    return () => {
      loadingRef.current = false;
    };
  }, []);

  return [data, config.useLoadingState ? loading : loadingRef.current, fetchData];
};

export default usePaginatedFetcher;
