import _logger from 'utils/logger';
import API from 'api/index';

export const getGeneros = async () => {
  try {
    const response = await API.get('usuarios/generos-public');
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return [];
};

export const getEstadosCiviles = async () => {
  try {
    const response = await API.get('usuarios/estados-civiles-public');
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return [];
};

export default {
  getGeneros,
  getEstadosCiviles,
};
