import { getContenTypePorTipoDePadron } from 'api/padrones';
import { padronesEnum, solicitantesEnum } from 'views/Tramites/GPM/enums';

const padronesDerivadosDeEmpresa = [
  padronesEnum.HOSPEDAJE,
  padronesEnum.ARRENDAMIENTO,
  padronesEnum.NOMINA,
  padronesEnum.ALCOHOL,
  padronesEnum.CEDULAR,
  padronesEnum.JUEGO_DE_AZAR,
  padronesEnum.NOTARIO,
  padronesEnum.CASA_DE_EMPENIO,
  padronesEnum.AGENCIA,
  padronesEnum.POLICIA_ESPECIAL,
  padronesEnum.COMERCIO_INFORMAL,
  padronesEnum.MERCADO,
  padronesEnum.EXPEDIENTE_LICENCIA_FUNCIONAMIENTO,
  padronesEnum.ZONA_EXCLUSIVA,
  padronesEnum.EXPEDIENTE_DE_ANUNCIO,
  padronesEnum.VENTA_DE_ALCOHOL,
  padronesEnum.RETENEDOR_DE_CEDULAR,
  padronesEnum.RETENEDOR_DE_NOMINA,
];

const getParamsPadron = (plantillaDeTramite, tipoDePadronSolicitante, padronSolicitante) => {
  const { tipo_de_padron } = plantillaDeTramite;
  if (tipo_de_padron === padronesEnum.EMPRESA) {
    return {
      [tipoDePadronSolicitante === solicitantesEnum.CIUDADANO
        ? 'ciudadano' : 'contribuyente']: padronSolicitante.id,
    };
  }
  if (tipo_de_padron === padronesEnum.CONTRIBUYENTE) {
    if (tipoDePadronSolicitante === solicitantesEnum.CIUDADANO) {
      return { ciudadano: padronSolicitante.id };
    }
    return null;
  }
  if (tipo_de_padron === padronesEnum.PREDIO) {
    const contentType = getContenTypePorTipoDePadron(tipoDePadronSolicitante);
    return {
      content_object: `${contentType}-${padronSolicitante.id}`,
    };
  }
  if ([padronesEnum.VEHICULO, padronesEnum.MOTOCICLETA, padronesEnum.REMOLQUE]
    .includes(tipo_de_padron)) {
    return {
      [tipoDePadronSolicitante === solicitantesEnum.CIUDADANO
        ? 'propietario' : 'contribuyente_propietario']: padronSolicitante.id,
    };
  }
  if (tipo_de_padron === padronesEnum.INFRACCIONES) {
    return {
      [tipoDePadronSolicitante === solicitantesEnum.CIUDADANO
        ? 'ciudadano' : 'contribuyente']: padronSolicitante.id,
    };
  }
  if (tipo_de_padron === padronesEnum.MULTAS) {
    return {
      [tipoDePadronSolicitante === solicitantesEnum.CIUDADANO
        ? 'ciudadano' : 'contribuyente']: padronSolicitante.id,
    };
  }
  if (padronesDerivadosDeEmpresa.includes(tipo_de_padron)) {
    return {
      [tipoDePadronSolicitante === solicitantesEnum.CIUDADANO
        ? 'empresa__ciudadano' : 'empresa__contribuyente']: padronSolicitante.id,
    };
  }
  return null;
};

export default getParamsPadron;
