import React, { useEffect, useState } from 'react';
import {
  Col,
  Form,
  Row,
  Spin,
  Table,
  message,
} from 'antd';
import { Button } from 'components';
import {
  CompressOutlined,
  ExpandOutlined,
  CaretRightOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import Select from 'components/Select';
import { getDeclaracionPDF, getDeclaraciones, getPeriodosFiscales } from 'api/recaudacion';
import _logger from 'utils/logger';
import { selectUser } from 'store/auth';
import { useSelector } from 'react-redux';
import { DATE_FORMAT } from 'utils/formatters';
import Moment from 'moment';
import Previewer from 'components/Previewer';
import { getCatalogoDelMes, getTiposDeDeclaracionesEmpresariales } from 'api/catalogos';
import { padronesEnum } from 'views/Tramites/GPM/enums';
import {
  getDeclaracionAlcoholesPDF,
  getDeclaracionCRIEPDF,
  getDeclaracionHospedajePDF,
  getDeclaracionNOTARIOPDF,
  getDeclaracionNominasPDF,
} from 'api/reporteador';
import { getTipoDePadronPorContenType } from 'api/padrones';

const gettersReportes = {
  [padronesEnum.NOTARIO]: getDeclaracionNOTARIOPDF,
  [padronesEnum.VENTA_DE_ALCOHOL]: getDeclaracionAlcoholesPDF,
  [padronesEnum.HOSPEDAJE]: getDeclaracionHospedajePDF,
  [padronesEnum.NOMINA]: getDeclaracionNominasPDF,
  [padronesEnum.CEDULAR]: getDeclaracionCRIEPDF,
  [padronesEnum.RETENEDOR_DE_NOMINA]: getDeclaracionCRIEPDF,
};

const MisDeclaraciones = () => {
  const user = useSelector(selectUser);
  const [form] = Form.useForm();
  const [periodosFiscales, setPeriodosFiscales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [previewBase64, setPreviewBase64] = useState('');
  const [periodos, setPeriodos] = useState([]);
  const [tiposDeDeclaraciones, setTiposDeDeclaraciones] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      const [
        _periodosFiscales,
        _periodos,
        _tiposDeDeclaraciones,
      ] = await Promise.all([
        getPeriodosFiscales(),
        getCatalogoDelMes(),
        getTiposDeDeclaracionesEmpresariales(),
      ]);
      if (isMounted) {
        setPeriodosFiscales(_periodosFiscales);
        setPeriodos(_periodos);
        setTiposDeDeclaraciones(_tiposDeDeclaraciones);
        setLoading(false);
        const currentYear = parseInt(Moment().format('YYYY'), 10);
        const pFCurrent = _periodosFiscales.find((p) => p.periodo === currentYear);
        setTimeout(() => { form.setFieldsValue({ ejercicio_anual: pFCurrent?.periodo }); });
      }
    };
    fetchData();
    return () => { isMounted = false; };
  }, []);

  const onFinish = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const values = form.getFieldsValue();
      values.contribuyente = user.id;

      // Validar que almenos un campo tenga un valor
      if (!values.tipo_de_declaracion && !values.periodo_mensual && !values.ejercicio_anual) {
        message.warn('Por favor, selecciona un valor en los campos de búsqueda.');
        setLoading(false);
        return;
      }

      const _declaraciones = await getDeclaraciones(values);
      if (_declaraciones.length > 0) {
        setData(_declaraciones);
      } else {
        message.info('No se encontraron declaraciones');
      }
      setLoading(false);
    } catch (error) {
      _logger(error);
    }
  };

  const consultarDeclaracion = async (id, tipoDePadron) => {
    setLoading(true);
    const getterDeclaracion = gettersReportes[tipoDePadron] ?? getDeclaracionPDF;
    const pdf = await getterDeclaracion(id);
    if (Array.isArray(pdf)) {
      setPreviewBase64(pdf[1]);
    } else {
      setPreviewBase64(pdf);
    }
    setVisiblePreview(true);
    setLoading(false);
  };

  const columns = [
    {
      title: 'No. de Declaración',
      dataIndex: 'numero_de_la_declaracion',
      key: 'numero_de_la_declaracion',
      width: 200,
    },
    {
      title: 'Fecha de Presentación',
      dataIndex: 'fecha_de_presentacion',
      key: 'fecha_de_presentacion',
      width: 200,
      render: (val) => Moment(val).format(DATE_FORMAT) || 'N/A',
    },
    {
      title: 'Concepto',
      dataIndex: 'cargo',
      key: 'cargo',
      width: 400,
      render: (val) => (typeof val === 'number' ? val : val?.descripcion),
    },
    {
      title: 'Estatus',
      dataIndex: 'estatus_de_declaracion_empresarial',
      key: 'estatus_de_declaracion_empresarial',
      width: 400,
      render: (val) => (typeof val === 'number' ? val : val?.descripcion),
    },
    {
      title: 'Documentos',
      key: 'id',
      width: 200,
      render: (values) => (
        <Button
          onClick={async () => {
            const tipoDePadron = getTipoDePadronPorContenType(values.content_type_padron);
            await consultarDeclaracion(values.id, tipoDePadron);
          }}
          $accent
          icon={<EyeOutlined />}
        >
          Ver
        </Button>
      ),
    },
  ];

  const expandIcon = ({ expanded, onExpand, record }) => (expanded
    ? (
      <CompressOutlined onClick={(e) => {
        e.stopPropagation();
        onExpand(record, e);
      }}
      />
    )
    : (
      <ExpandOutlined onClick={(e) => {
        e.stopPropagation();
        onExpand(record, e);
      }}
      />
    ));

  return (
    <Spin spinning={loading} tip="Cargando...">
      <Row>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <Row gutter={10}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="tipo_de_declaracion"
                  label="Tipo de declaración"
                >
                  <Select options={tiposDeDeclaraciones} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="periodo_mensual"
                  label="Periodo"
                >
                  <Select options={periodos} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="ejercicio_anual"
                  label="Periodo Fiscal"
                >
                  <Select
                    options={periodosFiscales}
                    getLabel={(p) => p.periodo}
                    getValue={(p) => p.periodo}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end" gutter={10}>
              <Col xs={24} sm={24} md={8}>
                <Form.Item>
                  <Button
                    block
                    $accent
                    htmlType="submit"
                  >
                    Consultar Declaración
                    <CaretRightOutlined />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            size="small"
            bordered
            columns={columns}
            dataSource={data}
            rowKey="id"
            loading={loading}
            expandable={{
              expandedRowRender: (record) => (record?.complementos_de_declaracion.length
                ? record?.complementos_de_declaracion
                  ?.map((complemento) => (
                    <Row gutter={[10, 10]} key={complemento.id}>
                      <strong>
                        {complemento?.variable?.descripcion_de_variable || 'variable no encontrada'}
                      </strong>
                      :
                      <Col>{complemento?.valor}</Col>
                    </Row>
                  )) : 'Esta declaración se hizo en ceros.'),
              rowExpandable: () => true,
              defaultExpandAllRows: true,
              expandIcon,
            }}
          />
        </Col>
      </Row>
      <Previewer
        visible={visiblePreview}
        base64={previewBase64}
        onCancel={() => {
          setVisiblePreview(false);
        }}
      />
    </Spin>
  );
};

export default MisDeclaraciones;
