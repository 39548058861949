import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/mi-cuenta/mis-datos');
  }, []);
  return (<span />);
};

export default Home;
