import React, { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Form,
  Grid,
  Input,
  List,
  Row,
  message,
} from 'antd';
import {
  Button,
  Title,
} from 'components';
import { scrollToTop } from 'components/ScrollToTop';
import {
  BarcodeOutlined,
  DeleteOutlined,
  FileTextOutlined,
  LoadingOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import Previewer from 'components/Previewer';
import useQuery from 'hooks/useQuery';
import { getReciboPorOrden, getReferenciasDePago } from 'api/recaudacion';
import { useNavigate } from 'react-router';

const ESTADOS_DE_FOLIO = Object.freeze({
  EN_PROCESO: 1,
  COBRADO: 2,
  RECHAZADO: 3,
  DENEGADO: 4,
  CANCELADO: 5,
  EXPIRADO: 6,
});

const Recibos = () => {
  const navigation = useNavigate();
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const [visiblePreviewRecibo, setVisiblePreviewRecibo] = useState(false);
  const [visiblePreviewOrden, setVisiblePreviewOrden] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recibo, setRecibo] = useState('');
  const [folio] = useQuery(['folio']);

  useEffect(() => {
    if (folio) {
      form.setFieldsValue({ folio });
      setTimeout(() => form.submit());
    }
  }, []);

  const onCancel = () => {
    setVisiblePreviewRecibo(false);
    setVisiblePreviewOrden(false);
    form.resetFields();
    scrollToTop(screens);
    setRecibo('');
  };

  const onFinishSearch = async () => {
    setLoading(true);
    const { folio: _folio } = form.getFieldsValue();
    const _referencia = await getReferenciasDePago({
      folio: _folio,
      estado_del_folio: ESTADOS_DE_FOLIO.COBRADO,
    });
    if (!_referencia) {
      message.warn('No se encontró la orden de pago con el folio proporcionado');
      setLoading(false);
      return;
    }
    const _recibo = await getReciboPorOrden({ folio: _folio });
    if (_recibo) {
      _referencia.folio_recibo = _recibo?.folio;
      _referencia.folio = _folio;
      setRecibo(_recibo);
    }
    setLoading(false);
  };

  const rules = {
    required: [{ required: true }],
  };

  const getBase64 = () => {
    if (visiblePreviewRecibo) {
      return recibo.base64;
    }
    return null;
  };

  return (
    <>
      <Col span={24}>
        <Title style={{ textAlign: 'center', margin: 0 }}>
          Consultar Recibo con Orden de Pago
        </Title>
      </Col>
      <Card
        title={(
          <Title level={2} $accent>
            Búsqueda
          </Title>
        )}
        style={{ width: '100%' }}
        bordered
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinishSearch}
          style={{ width: '100%' }}
        >
          <Row justify="end" gutter={[10, 10]}>
            <Col span={24}>
              <Form.Item
                label="Folio"
                name="folio"
                rules={rules.required}
              >
                <Input
                  disabled={!!recibo || loading}
                  suffix={loading ? <LoadingOutlined /> : null}
                />
              </Form.Item>
            </Col>
            {recibo ? (
              <>
                {recibo?.recibo_referencia?.length === 1 && (
                  <Button
                    block={!screens.md}
                    onClick={() => {
                      const [first] = recibo.recibo_referencia;
                      const redirect = new URLSearchParams({
                        _f: first.folio,
                        _ref: first.referencia_impresion,
                      });
                      navigation({
                        pathname: '/facturacion',
                        search: `?${redirect}`,
                      });
                    }}
                    loading={loading}
                    $accent
                    style={{ marginRight: screens.md ? 5 : 0 }}
                  >
                    Facturar
                    <FileTextOutlined />
                  </Button>
                )}
                <Button
                  block={!screens.md}
                  onClick={() => setVisiblePreviewRecibo(true)}
                  loading={loading}
                  $accent
                  style={{ marginRight: screens.md ? 5 : 0 }}
                >
                  Ver recibo
                  <BarcodeOutlined />
                </Button>
                <Button
                  block={!screens.md}
                  onClick={onCancel}
                  loading={loading}
                  $accent
                >
                  Buscar otro recibo
                  <DeleteOutlined />
                </Button>
              </>
            ) : (
              <Col>
                <Button
                  block={!screens.md}
                  htmlType="submit"
                  loading={loading}
                  $accent
                >
                  Buscar
                  <SearchOutlined />
                </Button>
              </Col>
            )}
          </Row>
        </Form>
        {recibo && recibo?.recibo_referencia?.length > 1 && (
          <div style={{ marginBlock: 10 }}>
            <List
              header={(
                <Title level={4} $accent>
                  Recibos relacionados a la orden de pago
                </Title>
              )}
              dataSource={recibo.recibo_referencia}
              bordered
              size="small"
              renderItem={(item) => (
                <List.Item>
                  <span>
                    {`Folio: ${item.folio}`}
                  </span>
                  <Button
                    block={!screens.md}
                    loading={loading}
                    $accent
                  >
                    <a
                      href={`/facturacion/?_f=${recibo.folio}&_ref=${item.referencia_de_impresion}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Facturar
                      <FileTextOutlined />
                    </a>
                  </Button>
                </List.Item>
              )}
            />
          </div>
        )}
      </Card>
      <Previewer
        visible={visiblePreviewRecibo || visiblePreviewOrden}
        base64={getBase64()}
        onCancel={() => {
          if (!visiblePreviewOrden) {
            setVisiblePreviewRecibo(false);
            setVisiblePreviewOrden(false);
          } else {
            onCancel();
          }
        }}
      />
    </>
  );
};

export default Recibos;
