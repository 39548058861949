/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { COLORS } from 'components';
import styled from 'styled-components';
import { Col } from 'antd';

export const Container = styled.div`
  min-height: 100%;
  overflow: hidden scroll;
  padding: 20px 30px;

  .ant-form-item-label label,
  .ant-select-selection-item,
  .ant-input {
    font-size: 16px;
  }
  .ant-form-item-control-input-content,
  .ant-select-single:not(.ant-select-customize-input):not(.ant-picker-calendar-year-select)
  :not(.ant-picker-calendar-month-select) .ant-select-selector {
    /* height: 36px; */
  }
  .ant-steps {
    .ant-steps-item-container>.ant-steps-item-icon {
      border-color: ${COLORS.cream};
      background: ${COLORS.cream};
      .ant-steps-icon {
        color: ${COLORS.white};
      }
    }
    .ant-steps-item-description {
      font-size: 12px;
      font-weight: bold;
    }
    .ant-steps-item-finish .ant-steps-icon-dot,
    .ant-steps-item-process .ant-steps-icon-dot {
      background: ${COLORS.accent};
      border-color: ${COLORS.accent};
    }
    .ant-steps-item-process .ant-steps-icon-dot {
      width: 14px;
      height: 14px;
      bottom: 2px !important;
      left: -2px !important;
    }
  }
  .ant-steps-item-tail:after {
    background-color: ${COLORS.text} !important;
    height: 3px;
  }
  @media only screen and (max-width: 576px) {
    padding: 12px;
  }
`;

export const StepsContent = styled.div`
  position: relative;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  padding: 20px 20px 10px;
  .scrollable {
    min-height: calc(100vh - 450px);
    overflow: hidden scroll;
    margin-bottom: 10px;
  }
  .ant-form {
    min-height: calc(100vh - 450px);
  }

  @media only screen and (max-width: 576px) {
    padding: 12px;
  }
  @media only screen and (max-width: 768px) {
    .ant-form {
      height: calc(100vh - 350px) !important;
      overflow: hidden scroll !important;
    }
  }
`;

export const HideToPreserve = ({ visible, children }) => (
  <Col span={visible ? 24 : 0}>
    {children}
  </Col>
);

HideToPreserve.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.node,
};

HideToPreserve.defaultProps = {
  visible: false,
  children: null,
};
