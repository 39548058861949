import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Col, Row, Skeleton } from 'antd';
import { Text } from 'components';

const SkeletosWrapperTitle = ({
  children,
  loading,
}) => (loading ? (
  <SkeletonTitle active>
    {children}
  </SkeletonTitle>
) : children);

SkeletosWrapperTitle.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};

SkeletosWrapperTitle.defaultProps = {
  loading: false,
};

const SkeletonTitle = styled(Skeleton).attrs({ paragraph: false })`
  .ant-skeleton-title {
    height: 35px;
    width: 70%;
    margin-left: 15%
  }
  li {
    width: 100% !important;
  }
`;

const SkeletonWrapperText = ({
  children,
  dataLabel,
  accent,
  bold,
  loading,
  wrap,
  center,
}) => (
  <Row wrap={wrap} className="skeleton-text-wrapper">
    {!!dataLabel && (
    <Col flex="none" className="hide-on-print">
      <Text
        $accent={accent}
        style={{
          paddingRight: 10,
          fontSize: 18,
          fontWeight: bold ? 'bold' : '',
          textAlign: center ? 'center' : 'left',
          justifyContent: center ? 'center' : 'flex-start',
        }}
      >
        {dataLabel ? dataLabel.concat(':') : ''}
      </Text>
    </Col>
    )}
    {loading ? <SkeletonText active /> : (
      <Text
        $accent={accent}
        style={{
          display: 'flex',
          alignItems: center ? 'center' : 'flex-start',
          justifyContent: center ? 'center' : 'flex-start',
          textAlign: center ? 'center' : 'left',
          flexDirection: center ? 'column' : 'row',
          width: '100%',
        }}
      >
        <Text
          className="show-on-print"
          style={{
            paddingRight: 5,
            fontSize: 18,
            fontWeight: bold ? 'bold' : '',
            textAlign: center ? 'center' : 'left',
            justifyContent: center ? 'center' : 'flex-start',
          }}
        >
          {dataLabel ? dataLabel.concat(':') : ''}
        </Text>
        {children}
      </Text>
    )}
  </Row>
);

SkeletonWrapperText.propTypes = {
  children: PropTypes.node.isRequired,
  dataLabel: PropTypes.string,
  accent: PropTypes.bool,
  bold: PropTypes.bool,
  loading: PropTypes.bool,
  wrap: PropTypes.bool,
  center: PropTypes.bool,
};

SkeletonWrapperText.defaultProps = {
  dataLabel: '',
  accent: false,
  bold: true,
  loading: false,
  wrap: false,
  center: false,
};

const SkeletonText = styled(Skeleton).attrs({ title: false, paragraph: { rows: 1 } })`
  li {
    width: 100% !important;
  }
  .ant-skeleton-paragraph {
    margin: 8px 0 0 0;
  }
`;

export default {
  SkeletosWrapperTitle,
  SkeletonWrapperText,
};
