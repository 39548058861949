import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Table, Tooltip } from 'antd';
import { Button, Text } from 'components';
import { EyeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import FormSubmitCustomControls from 'components/FormSubmitCustomControls';
import { toMoment } from 'utils/normalizers';
import { DATETIME_FORMAT, DATETIME_FRONTEND_FORMAT } from 'utils/formatValues';
import { tiposDePasosEnum } from '../enums';

export const fullName = (u) => `${u.first_name || ''} ${u.last_name || ''} ${u.second_last_name || ''}`.trim();

const ModalHistorial = ({
  visible,
  setVisibleSeguimiento,
  historialesPasoActual,
  plantillaDeTramite,
  pasoActual,
  setVisiblePreview,
  setBase64,
}) => {
  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'fecha_de_creacion',
      key: 'fecha_de_creacion',
      width: 190,
      render: (f) => (
        <Text $xs>
          {toMoment(f, DATETIME_FORMAT)?.format(DATETIME_FRONTEND_FORMAT) || f}
        </Text>
      ),
    },
    {
      title: 'Movimiento',
      dataIndex: 'paso_a_mandar',
      key: 'paso_a_mandar',
      width: 350,
      render: (_, record) => {
        const paso = plantillaDeTramite
          ?.pasos_para_tramites.find((p) => p.id === record.paso);
        const paso_a_mandar = plantillaDeTramite
          ?.pasos_para_tramites.find((p) => p.id === record.paso_a_mandar);
        if (paso.id === paso_a_mandar.id) {
          return (
            <Text $xs>
              SE MANTUVO EN PASO
              {' '}
              <Text $bold>{paso.nombre}</Text>
            </Text>
          );
        }
        return (
          <Text $xs>
            {paso_a_mandar.orden > paso.orden ? 'AVANZÓ' : 'RETROCEDIÓ'}
            {' DE PASO '}
            <Text $bold>{paso.nombre}</Text>
            {' a '}
            <Text $bold>{paso_a_mandar.nombre}</Text>
          </Text>
        );
      },
    },
    {
      title: 'Comentario',
      dataIndex: 'comentario',
      key: 'comentario',
      width: 600,
      render: (_comentario) => {
        if (!_comentario) return null;
        const comentario = _comentario?.comentario?.toUpperCase();
        const idx = [tiposDePasosEnum.VARIABLES, tiposDePasosEnum.CARGOS]
          .includes(pasoActual.tipo_de_paso) ? comentario.indexOf(':') : -1;
        const items = idx === -1 ? null : comentario.substring(idx + 1);
        const comment = idx === -1 ? comentario : comentario.substring(0, idx);

        return (
          <Text $xs>
            {comment}
            {!!_comentario.archivos.length && (
              <>
                {' '}
                <Tooltip title="Presione para ver archivos adjuntos">
                  <Button
                    onClick={() => {
                      let archivos = _comentario.archivos.map((e) => e.archivo);
                      archivos = archivos.length > 1 ? archivos : archivos[0];
                      setBase64((base64Src) => ({ ...base64Src, archivos }));
                      setVisiblePreview('archivos');
                    }}
                    type="link"
                  >
                    <EyeOutlined />
                  </Button>
                </Tooltip>
              </>
            )}
            {!!items && (
              <>
                {' '}
                <Tooltip title={items} overlayStyle={{ maxWidth: '70vw' }}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            )}
          </Text>
        );
      },
    },
  ];

  return (
    <Modal
      footer={null}
      visible={visible}
      title={(
        <FormSubmitCustomControls
          label="Historial de seguimiento"
          onCancel={() => setVisibleSeguimiento(false)}
        />
      )}
      closable={false}
      width="70%"
    >
      <Table
        bordered
        dataSource={historialesPasoActual}
        columns={columns}
        rowKey="id"
        size="small"
        scroll={{ x: columns.reduce((acum, curr) => acum + curr.width, 0) }}
      />
    </Modal>
  );
};

ModalHistorial.propTypes = {
  visible: PropTypes.bool,
  setVisibleSeguimiento: PropTypes.func.isRequired,
  setVisiblePreview: PropTypes.func.isRequired,
  setBase64: PropTypes.func.isRequired,
  historialesPasoActual: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  plantillaDeTramite: PropTypes.shape({
    pasos_para_tramites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }),
  pasoActual: PropTypes.shape({ tipo_de_paso: PropTypes.number }),
};

ModalHistorial.defaultProps = {
  visible: false,
  plantillaDeTramite: null,
  pasoActual: null,
};

export default ModalHistorial;
