/* eslint-disable import/prefer-default-export */
import { message } from 'antd';
import API from 'api';
import { setMetodosDePago } from 'store/catalogos';
import _logger from 'utils/logger';
import { toMoment } from 'utils/normalizers';

const WHITE_LIST_METODOS_DE_PAGO = [
  1,
  2,
  3,
  4,
  18,
];

/**
 *  GET Dependencias
 * @param {object} params
 * @returns Promise<object>
 */
export const getDependencias = async (params = null) => {
  try {
    const response = await API.get('configuracion/dependencias-public/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return { results: [], count: 0 };
  }
};
/**
 *  GET Departamentos
 * @param {object} params
 * @returns Promise<object>
 */
export const getDepartamentos = async (params = null) => {
  try {
    const response = await API.get('configuracion/departamentos-public/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return { results: [], count: 0 };
  }
};

/**
 * GET ariabless
 * @returns {Promise<Array<object>>}
 */
export const getVariables = async (params = null) => {
  try {
    const response = await API.get('configuracion/variables/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Valores por Variable de Seleccion
 * @returns {Promise<Array<object>>}
 */
export const getValoresPorVariablesDeSeleccion = async (params = null) => {
  try {
    const response = await API.get('configuracion/valores-por-variables-de-seleccion/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getCategoriasDeVariables = async (params) => {
  try {
    const response = await API.get('/empresas/categorias-de-variables/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 * GET Canales de Pago
 * @returns {Promise<Array<object>>}
 */
export const getCanalesDePago = async (params = null) => {
  try {
    const response = await API.get('configuracion/canales-de-pago-public/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getNowMoment = async (timezone = 'America/Mazatlan') => {
  try {
    const momentInTimeZone = (date, timeZone) => toMoment(date
      .toLocaleString('en-US', { timeZone }), ['MM/DD/YYYY, h:mm:ss A']);
    const response = await API.get(`https://worldtimeapi.org/api/timezone/${timezone}`);
    const localDate = new Date(response.data.datetime);
    response.data.moment = momentInTimeZone(localDate, 'america/mazatlan');
    return response.data;
  } catch (err) {
    _logger(err);
  }
  return null;
};

export const sendPDFToEmail = async (values) => {
  try {
    const response = await API.post('/configuracion/enviar-archivo-por-correo-public/', values);
    if (response) {
      message.success('El documento ha sido enviado revise su bandeja de recepción o spam', 10);
    }
    return true;
  } catch (err) {
    _logger(err);
    return false;
  }
};

export const getMetodosDePago = async (dispatch) => {
  try {
    const response = await API.get('configuracion/catalogos-de-metodos-de-pagos-public');
    const allowedMethods = response.data
      .filter((item) => WHITE_LIST_METODOS_DE_PAGO.includes(item.id))
      .map((item) => ({ ...item, value: item.clave, label: item.concepto }));
    dispatch(setMetodosDePago(allowedMethods));
    return allowedMethods;
  } catch (err) {
    _logger(err);
    return [];
  }
};

export const getUnidadesDeRecaudacion = async (params = null) => {
  try {
    const response = await API.get('configuracion/unidades-de-recaudacion/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};
