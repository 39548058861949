import { useEffect, useState, useRef } from 'react';
import sorterCargos from 'utils/sorterCargos';
import { titleize } from 'utils/formatters';
import { getCargosPadron } from 'api/recaudacion';

// eslint-disable-next-line no-promise-executor-return
const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const parser = (_data) => {
  const data = { ..._data };
  if ('importe' in data) data.importe = parseFloat(data.importe);
  if ('importe_total' in data) data.importe_total = parseFloat(data.importe_total);
  if (Number.isNaN(data.importe)) data.importe = 0;
  if (Number.isNaN(data.importe_total)) data.importe_total = 0;
  return data;
};

const parseCargos = (cargos) => cargos.map((cargo) => ({
  ...parser(cargo),
  importe: parseFloat(cargo.importe),
  descuentos_aplicables: cargo.descuentos_aplicables.map(parser),
  descuentos_especiales: cargo.descuentos_especiales.map(parser),
  recargos: cargo.recargos.map((recargo) => ({
    ...parser(recargo),
    descuentos: recargo.descuentos.map(parser),
  })),
  actualizaciones: cargo.actualizaciones.map((actualizacion) => ({
    ...parser(actualizacion),
    descuentos: actualizacion.descuentos.map(parser),
  })),
  gastos: cargo.gastos.map((gasto) => ({
    ...parser(gasto),
    descuentos: gasto.descuentos.map(parser),
  })),
}));

export const getAdeudoPadronAsync = async (
  padron,
  padron_id,
  setLoadingMessage,
  pub = false,
) => {
  const [err, cargos, response] = await getCargosPadron({ padron, padron_id }, pub);
  if (err) {
    return [[], err];
  }
  if (response.detail || response.status) {
    if (setLoadingMessage) {
      setLoadingMessage(titleize(response.detail || response.status));
    }
    await wait(5000);
    return getAdeudoPadronAsync(
      padron,
      padron_id,
      setLoadingMessage,
      pub,
    );
  }
  if (cargos) {
    const cleanCargos = parseCargos(cargos);
    if (setLoadingMessage) {
      setLoadingMessage();
    }
    return [cleanCargos, null];
  }
  if (setLoadingMessage) {
    setLoadingMessage('No fue posible obtener el adeudo del padrón');
  }
  return [[], 'No fue posible obtener el adeudo del padrón'];
};

export const getAdeudoPadron = async (
  tipoDePadron,
  padronId,
  setLoading,
  setCargos,
  setLoadingMessage,
  normalizeCargos,
  timeoutRef,
  onSuccessCallback,
  onFailCallback,
) => {
  setLoading(true);
  const response = await getCargosPadron(tipoDePadron, padronId);
  if (response.detail || response.status) {
    setLoadingMessage(titleize(response.detail || response.status));
    // eslint-disable-next-line no-param-reassign
    timeoutRef.current = setTimeout(() => getAdeudoPadron(
      tipoDePadron,
      padronId,
      setLoading,
      setCargos,
      setLoadingMessage,
      normalizeCargos,
      timeoutRef,
      onSuccessCallback,
      onFailCallback,
    ), 5000);
  } else if (response.cargos) {
    const _cargos = sorterCargos(normalizeCargos
      ? normalizeCargos(response.cargos) : response.cargos);
    setCargos(_cargos);
    if (onSuccessCallback) {
      onSuccessCallback(_cargos);
    }
    setLoading(false);
    setLoadingMessage();
    // eslint-disable-next-line no-param-reassign
    timeoutRef.current = null;
  } else {
    setLoadingMessage('No fue posible obtener el adeudo del padrón');
    setLoading(false);
    if (onFailCallback) {
      onFailCallback();
    }
    setCargos([]);
  }
};

const useAdeudoPadron = (
  tipoDePadron,
  padronId,
  normalizeCargos,
  onSuccessCallback,
  onFailCallback,
) => {
  const timeoutRef = useRef();
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [cargos, setCargos] = useState([]);

  useEffect(() => {
    if (tipoDePadron && padronId) {
      getAdeudoPadron(
        tipoDePadron,
        padronId,
        setLoading,
        setCargos,
        setLoadingMessage,
        normalizeCargos,
        timeoutRef,
        onSuccessCallback,
        onFailCallback,
      );
    } else {
      setCargos([]);
    }
  }, [tipoDePadron, padronId]);

  return [
    loading,
    loadingMessage,
    cargos,
    () => (padronId ? getAdeudoPadron(
      tipoDePadron,
      padronId,
      setLoading,
      setCargos,
      setLoadingMessage,
      normalizeCargos,
      timeoutRef,
      onSuccessCallback,
      onFailCallback,
    ) : () => {}),
  ];
};

export default useAdeudoPadron;
