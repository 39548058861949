import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Skeleton,
  Alert,
  Modal,
} from 'antd';
import { useParams, Link } from 'react-router-dom';
import { RightOutlined } from '@ant-design/icons';
import {
  Card,
  FlexRow,
  Button,
  Text,
  Title,
} from 'components';
import InputSearch from 'components/InputSearch';
import NavigationCard from 'views/Payments/components/NavigationCard';
import toSlug, { extractSegment } from 'utils/toSlug';
import { extra, getRoute, currentEntity } from 'components/Shortcuts';
import { useSelector } from 'react-redux';
import { selectClasificadoresDeTiposDeCargos } from 'store/catalogos';
import { ENV_NAME, ENV_NAMES_ENUM } from 'utils/env';

const Banner = ({ loading, title }) => (
  <Col span={24}>
    {loading ? (
      <Skeleton
        title={{ width: '100%' }}
        paragraph={{ rows: 0 }}
        active
        loading={loading}
      />
    ) : (
      <Title level={3} style={{ textAlign: 'center', margin: 0 }}>
        {title}
      </Title>
    )}
  </Col>
);

Banner.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
};

Banner.defaultProps = {
  loading: false,
  title: '',
};

const FullSkeleton = ({ loading }) => (
  <>
    <Banner loading={loading} />
    <Col xs={24} sm={24} md={{ span: 12, push: 6 }}>
      <InputSearch disabled dataSource={[]} name="nombre" setDataSource={() => { }} />
    </Col>
    <Col span={24} />
    {new Array(4).fill(0).map((e, idx) => idx).map((e) => (
      <Col xs={24} sm={24} md={8} key={e}>
        <Card>
          <FlexRow justifyContent="space-between">
            <Skeleton title={{ width: '100%' }} paragraph={{ rows: 3, width: '100%' }} />
            <Skeleton.Button active style={{ marginLeft: 15 }} shape="circle" />
          </FlexRow>
        </Card>
      </Col>
    ))}
  </>
);

FullSkeleton.propTypes = {
  loading: PropTypes.bool,
};

FullSkeleton.defaultProps = {
  loading: false,
};

const Item = ({ text, to }) => (
  <Col
    xs={24}
    sm={24}
    md={8}
  >
    <Card>
      <Row align="middle" style={{ height: '100%' }}>
        <Text
          style={{
            fontWeight: '500',
            width: 'calc(100% - 36px)',
            paddingRight: 5,
            textAlign: 'left',
          }}
        >
          {text}
        </Text>
        <Button $accent shape="circle" style={{ float: 'right', height: 32, padding: 0 }}>
          <Link to={toSlug(to)}>
            <RightOutlined />
          </Link>
        </Button>
      </Row>
    </Card>
  </Col>
);

Item.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

const showAlert = (clasificador) => {
  if (clasificador?.nombre === 'Pago de Contribuciones'
    || clasificador?.nombre === 'Retenedores de Impuestos Estatales') {
    return ENV_NAME === ENV_NAMES_ENUM.NAYARIT;
  }
  return false;
};
const _ENV_NAME = process.env.REACT_APP_ENV_NAME;

const SubClassifiers = () => {
  const clasificadores = useSelector(selectClasificadoresDeTiposDeCargos);
  const { classifier: _classifier } = useParams();
  const classifier = extractSegment(_classifier, '-', -1);
  const [clasificador, setClasificador] = useState();
  const [subClasificadores, setSubClasificadores] = useState([]);
  const [showInstructivo, setShowInstructivo] = useState(false);
  const [doc, setDoc] = useState();

  // useState(() => {
  //   console.log('classifier', classifier);
  // }, clasificadores);

  useEffect(() => {
    if (ENV_NAME === ENV_NAMES_ENUM.NAYARIT) {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      setDoc(require(`../../assets/${_ENV_NAME}/instructivos/Guia_acceso2024.pdf`));
    }
    setSubClasificadores(clasificadores.subClasificadores
      .filter((e) => extractSegment(e.clasificador) === classifier)
      .concat(currentEntity.concatExtra ? extra() : []));
    setClasificador(clasificadores.clasificadores
      .find((e) => e.clasificador === classifier));
  }, [clasificadores]);

  return (
    <>
      <Banner title={clasificador?.nombre} />
      <Col xs={24} sm={24} md={{ span: 12, push: 6 }}>
        <InputSearch dataSource={subClasificadores} name="nombre" setDataSource={setSubClasificadores} />
        {
          classifier === '03' && (
            <Row justify="center" style={{ width: '100%', marginTop: '40px' }}>
              <Button onClick={() => setShowInstructivo(true)} $accent>
                <Text>Guía pago de derechos</Text>
              </Button>
              {
              showInstructivo && (
                <Modal
                  visible={showInstructivo}
                  onCancel={() => setShowInstructivo(false)}
                  footer={null}
                  width="100%"
                >
                  <Col span={24}>
                    <iframe
                      title="Instructivo"
                      src={doc.default}
                      width="100%"
                      height="800px"
                    />
                  </Col>
                </Modal>
              )
            }
            </Row>
          )
        }
      </Col>
      {
        showAlert(clasificador) && (
        <Col xs={24} sm={24}>
          <Alert
            message="IMPORTANTE"
            description="ESTIMADO CONTRIBUYENTE: POR PROBLEMAS TÉCNICOS PRESENTADOS EN NUESTRA PÁGINA WEB AL MOMENTO DE REALIZAR EL PAGO DEL IMPUESTO CEDULAR, SE LES INFORMA QUE SE AMPLIA EL PLAZO PARA SU PRESENTACIÓN HASTA EL DÍA 15 DE ENERO DEL 2024."
            type="info"
            showIcon
          />
        </Col>
        )
      }
      <Col span={24} />
      {subClasificadores.map((e) => (
        <NavigationCard
          text={e.nombre}
          to={getRoute(e)}
          key={e.id}
          type={currentEntity.styleSubClasifiers}
          clasificador={e.clasificador}
        />
      ))}
    </>
  );
};

export default SubClassifiers;
