/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Form,
  Col,
  Spin,
  Modal,
  Input,
  DatePicker,
  Table,
  Pagination,
} from 'antd';
import {
  getClasificacionesDeComercioCatalogo,
  getTiposDeEmpresas,
  getTiposDeEstablecimiento,
  getUnidadesDeRecaudacion,
  getClasesDeActividadesEmpresariales,
} from 'api/empresas';
import {
  getEmpresas,
  postEmpresa,
} from 'api/empresas/empresas';
import Select from 'components/Select';
import { DATE_FRONTEND_FORMAT, DATE_FORMAT } from 'utils/formatValues';
import LadaNumero from 'components/LadaNumero';
import FormSubmitCustomControls from 'components/FormSubmitCustomControls';
import { ExpandOutlined, PlusOutlined } from '@ant-design/icons';
import { toMoment } from 'utils/normalizers';
import { formatAddressValues } from 'components/Direccion';

const EmpresasDeContribuyente = ({
  contribuyente,
  mutable = true,
  fetchCallback,
  callback,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ results: [], page: 1, count: 0 });
  const [esatblecimientos, setEstablecimientos] = useState([]);
  const [visible, setVisible] = useState(false);
  const [ubicacionesEmpresa, setUbicacionEmpresa] = useState([]);
  const [unidadesDeRecaudacion, setUnidadesDeRecaudacion] = useState([]);
  const [tiposDeEmpresa, setTiposDeEmpresa] = useState([]);
  const [claseActividad, setClaseActividad] = useState([]);
  const [clasificacionesDeComercio, setClasificacionesDeComercio] = useState([]);
  const [direccionFiscal, setDireccionFiscal] = useState();
  const [direccionNotificacion, setDireccionNotificacion] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const fetchData = async (page = 1, q = null) => {
    setLoading(true);
    const _data = await getEmpresas({ page, contribuyente: contribuyente.id, ...q });
    _data.page = page;
    setData(_data);
    if (fetchCallback) fetchCallback(_data);
    setLoading(false);
  };

  useEffect(() => {
    let mounted = true;
    const fetchAll = async () => {
      setLoading(true);
      const [
        _data,
        _establecimientos,
        _tiposEmpresa,
        _claseActividad,
        _clasificacionesDeComercio,
        _unidadesDeRecaudacion,
      ] = await Promise.all([
        getEmpresas({ page: 1, contribuyente: contribuyente.id }),
        getTiposDeEstablecimiento(),
        getTiposDeEmpresas(),
        getClasesDeActividadesEmpresariales(),
        getClasificacionesDeComercioCatalogo(),
        getUnidadesDeRecaudacion(),
      ]);
      // OPCIONES DE UBICACION DE EMPRESA
      const options = [{ id: null, nombre: 'NINGUNA' }];
      if (contribuyente.direcciones) {
        options.push({
          id: 1,
          nombre: 'COPIAR DIRECCIÓN FISCAL DEL CONTRIBUYENTE',
        });
      }
      if (contribuyente.direcciones_de_notificacion) {
        options.push({
          id: 2,
          nombre: 'COPIAR DIRECCIÓN DE NOTIFICACIÓN DEL CONTRIBUYENTE',
        });
      }
      if (mounted) {
        if (fetchCallback) {
          fetchCallback(_data);
        }
        setUbicacionEmpresa(options);
        setDireccionFiscal(contribuyente.direcciones);
        setDireccionNotificacion(contribuyente.direcciones_de_notificacion);
        setEstablecimientos(_establecimientos);
        setUnidadesDeRecaudacion(_unidadesDeRecaudacion);
        setClasificacionesDeComercio(_clasificacionesDeComercio);
        setClaseActividad(_claseActividad);
        setTiposDeEmpresa(_tiposEmpresa);
        setData(_data);
        setLoading(false);
      }
    };
    fetchAll();
    return () => { mounted = false; };
  }, []);

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
    setSelectedRowKeys([]);
  };

  const onFinish = async () => {
    setLoading(true);
    const values = form.getFieldsValue();
    if (values.direccion_de_ubicacion) {
      const addressMap = {
        1: direccionFiscal,
        2: direccionNotificacion,
      };
      const direccion = addressMap[values.direccion_de_ubicacion];
      delete direccion.id;
      values.direccion_de_ubicacion = formatAddressValues(direccion);
      values.direccion_de_ubicacion.codigo_postal = direccion.codigo_postal.id;
    }
    values.contribuyente = contribuyente.id;
    const saved = await postEmpresa(values);
    if (saved) {
      if (callback) {
        saved.message = `Empresa ${saved.clave} agregada`;
        callback(saved);
      }
      onCancel();
      await fetchData();
    }
    setLoading(false);
  };

  const onClickAdd = async () => setVisible(true);
  const onClickView = async () => setVisible(true);

  const columns = [
    {
      title: 'Clave',
      key: 'clave',
      dataIndex: 'clave',
      width: 300,
    },
    {
      title: 'Nombre Comercial',
      key: 'nombre_comercial',
      dataIndex: 'nombre_comercial',
      width: 300,
    },
    fetchCallback && {
      title: 'RFC',
      key: 'contribuyente',
      dataIndex: 'contribuyente',
      render: (text) => text.rfc,
      width: 300,
    },
    {
      title: 'Fecha de Apertura',
      key: 'fecha_de_apertura',
      dataIndex: 'fecha_de_apertura',
      render: (text) => toMoment(text, DATE_FORMAT)?.format(DATE_FORMAT),
      width: 300,
    },
  ].filter((e) => e);

  const onRow = (record) => ({
    onClick: () => {
      setSelectedRowKeys([record.id]);
      form.setFieldsValue(record);
    },
    onDoubleClick: () => onClickView(),
  });

  const rowSelection = mutable ? {
    selectedRowKeys,
    type: 'radio',
  } : null;

  return (
    <Col xs={24} sm={24} md={24}>
      <Spin tip="Cargando..." spinning={loading}>
        {mutable && (
          <FormSubmitCustomControls
            controls={[
              {
                text: 'Agregar',
                icon: <PlusOutlined />,
                onClick: () => onClickAdd(),
              },
              {
                text: 'Vista',
                icon: <ExpandOutlined />,
                onClick: () => onClickView(),
                disabled: !selectedRowKeys.length,
              },
            ]}
            style={{ width: '100%' }}
            justify="end"
          />
        )}
        <Table
          columns={columns}
          dataSource={data?.results}
          rowKey="id"
          pagination={false}
          rowSelection={rowSelection}
          onRow={onRow}
        />
        <Pagination
          page={data.page}
          callback={(p) => fetchData(p, null, true)}
          count={data.count}
        />
        <Modal
          title={(
            <FormSubmitCustomControls
              label="Agregar Empresa"
              onFinish={!selectedRowKeys.length ? form.submit : null}
              onCancel={onCancel}
              loading={loading}
            />
          )}
          visible={visible}
          keyboard={!loading}
          maskClosable={!loading}
          onCancel={onCancel}
          forceRender
          footer={null}
          closable={false}
          width="70%"
        >
          <Form
            name="info"
            layout="vertical"
            form={form}
            onFinish={() => onFinish()}
            scrollToFirstError
            initialValues={{ direccion_de_ubicacion: null }}
          >
            <Col md={24} sm={24} xs={24}>
              <Form.Item
                label="Nombre comercial"
                name="nombre_comercial"
              >
                <Input disabled={!!selectedRowKeys.length} allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name="unidad_recaudadora"
                label="Unidad Recaudadora"
              >
                <Select
                  disabled={!!selectedRowKeys.length}
                  options={unidadesDeRecaudacion}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name="tipo_de_empresa"
                label="Tipo de Empresa"
                hasFeedback
              >
                <Select disabled={!!selectedRowKeys.length} options={tiposDeEmpresa} />
              </Form.Item>
            </Col>
            <Col md={24} sm={24} xs={24}>
              <Form.Item
                label="Tipo de establecimiento"
                name="tipo_de_establecimiento"
              >
                <Select
                  disabled={!!selectedRowKeys.length}
                  options={esatblecimientos}
                  getLabel={(e) => e.nombre}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name="clasificacion_de_comercio"
                label="Clasificación de comercio"
                hasFeedback
              >
                <Select disabled={!!selectedRowKeys.length} options={clasificacionesDeComercio} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name="clase_actividad_empresarial_principal"
                label="Clase Actividad"
                hasFeedback
                tooltip="Clase Actividad Empresarial"
              >
                <Select disabled={!!selectedRowKeys.length} options={claseActividad} allowClear />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name="fecha_de_apertura"
                label="Fecha de apertura"
                hasFeedback
              >
                <DatePicker disabled={!!selectedRowKeys.length} format={DATE_FRONTEND_FORMAT} placeholder="" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24}>
              <Form.Item
                name="fecha_de_alta_de_padron_empresa"
                label="Fecha de alta de Padrón"
                hasFeedback
              >
                <DatePicker disabled={!!selectedRowKeys.length} format="DD/MM/YYYY" placeholder="" />
              </Form.Item>
            </Col>
            <Col md={24} sm={24} xs={24}>
              <Form.Item
                label="Dirección de Ubicación"
                name="direccion_de_ubicacion"
              >
                <Select
                  disabled={!!selectedRowKeys.length}
                  options={ubicacionesEmpresa}
                  getLabel={(e) => e.nombre}
                />
              </Form.Item>
            </Col>
            {/* <Col md={24} sm={24} xs={24}>
              <Form.Item
                label="Número de teléfono local"
                name="telefono_local"
              >
                <Input allowClear />
              </Form.Item>
            </Col> */}
            <LadaNumero
              form={form}
              names={{
                lada: 'lada_fijo',
                numero_de_telefono: 'telefono_fijo',
              }}
              tooltip="Teléfono Fijo de la Empresa"
              label="Teléfono Fijo"
              fullSize
              disabled={!!selectedRowKeys.length}
            />
            <LadaNumero
              form={form}
              names={{
                lada: 'lada_celular',
                numero_de_telefono: 'telefono_celular',
              }}
              tooltip="Teléfono Celular de la Empresa"
              label="Teléfono Celular"
              fullSize
              disabled={!!selectedRowKeys.length}
            />
            <Form.Item
              name="observaciones"
              label="Observaciones"
            >
              <Input.TextArea
                disabled={!!selectedRowKeys.length}
                autoSize={{ minRows: 2, maxRows: 3 }}
                maxLength={250}
                allowClear
              />
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
    </Col>
  );
};

export default EmpresasDeContribuyente;
