import { useEffect, useState, useRef } from 'react';

const useLoadingAlert = (_loading = true) => {
  const ref = useRef();
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [loading, setLoading] = useState(_loading);

  const showAlert = () => setVisibleAlert(_loading);

  const clearAlert = () => {
    if (ref.current) {
      clearTimeout(ref.current);
    }
    setVisibleAlert(false);
  };

  useEffect(() => {
    if (loading) {
      ref.current = setTimeout(showAlert, 10000);
    } else {
      clearAlert();
    }
  }, [loading]);

  return {
    visibleAlert,
    loading,
    setLoading,
  };
};

export default useLoadingAlert;
