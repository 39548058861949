/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import {
  Form, Input, Row, Col, Divider, Modal, DatePicker, notification,
} from 'antd';
import {
  DATE_FORMAT,
} from 'utils/formatters';
import Select from 'components/PaginatedSelect';
import { Button, Text } from 'components';
import TableISAN from 'components/TableISAN';
import propTypes from 'prop-types';
import { postDeclaracionIsan, patchGenerarDeclaracion, postRechazarDeclaracion } from 'api/recaudacion';
import { SyncOutlined } from '@ant-design/icons';
import _logger from 'utils/logger';
import ModalConfirm from './ModalConfirm';

const FormISAN = ({
  declaracionId,
  statusDeclaracion,
  padron,
  cargosGenerados,
  visible,
  setVisible,
  callback,
  callbackCancel,
}) => {
  const [formIsan] = Form.useForm();
  const [declaracionIsanId, setDeclaracionIsanId] = useState(null);
  const [padronValues, setPadronValues] = useState([]);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const _rules = {
    required: (message) => ({
      required: true,
      message,
    }),
    numeric: [
      {
        validator: async (_, value) => {
          const regex = /^[0-9]+([.][0-9]{1,2})?$/;
          if (!value) {
            return Promise.resolve();
          }
          if (value?.split('.')?.[1]?.length > 2) {
            return Promise.reject(new Error('Solo se permiten hasta dos decimales (0.00)'));
          }
          if (!regex.test(value)) {
            return Promise.reject(new Error('Solo se permiten números'));
          }
          return Promise.resolve();
        },
      },
    ],
    text: [
      {
        validator: async (_, value) => {
          const regex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]*$/;
          if (!value) {
            return Promise.resolve();
          }
          if (!regex.test(value)) {
            return Promise.reject(new Error('Solo se permiten letras'));
          }
          return Promise.resolve();
        },
      },
    ],
    cp: [
      {
        validator: async (_, value) => {
          const regex = /^[0-9]{5}$/;
          if (value && !regex.test(value)) {
            throw new Error('Solo se permiten 5 números');
          }
        },
      },
    ],
    telefono: [
      {
        validator: async (_, value) => {
          const regex = /^[0-9]{10}$/;
          if (value && !regex.test(value)) {
            throw new Error('Solo se permiten 10 números');
          }
        },
      },
    ],
    mayorCero: [
      {
        validator: async (_, value) => {
          if (value <= 0) {
            throw new Error('El valor debe ser mayor a 0');
          } else {
            return Promise.resolve();
          }
        },
      },
    ],
    numericInt: [
      {
        validator: async (_, value) => {
          const regex = /^[0-9]+$/;
          if (!value) {
            return Promise.resolve();
          }
          if (!regex.test(value)) {
            return Promise.reject(new Error('Solo se permiten números enteros.'));
          }
          return Promise.resolve();
        },
      },
    ],
    mayorQue: (fild) => [
      {
        validator: async (_, value) => {
          const valorA = formIsan.getFieldValue(fild);

          if (parseInt(valorA, 10) > 0 && parseInt(value, 10) === 0) {
            return Promise.reject(new Error('El valor debe ser mayor a 0'));
          } if (parseInt(valorA, 10) === 0 && parseInt(value, 10) > 0) {
            formIsan.setFields([{ name: fild, errors: ['El valor debe ser mayor a 0'] }]);
          } if (parseInt(valorA, 10) === 0 && parseInt(value, 10) === 0) {
            formIsan.setFields([{ name: fild, errors: [] }]);
          }
          return Promise.resolve();
        },
      },
    ],
  };

  const comprobarTotalEnajenacion = (values) => {
    const { valor_enajenacion_total, total_de_sumas_de_valor_total } = values;

    if (valor_enajenacion_total !== total_de_sumas_de_valor_total) {
      formIsan.validateFields(['valor_enajenacion_total']).then(() => {
        formIsan.setFields([
          {
            name: 'valor_enajenacion_total',
            errors: ['La suma de los valores de enajenación no coincide con el total'],
          },
        ]);
      });
      notification.info({
        message: `ENAJENACIÓN TOTAL: ${valor_enajenacion_total}`,
        description:
          `La suma de los valores de enajenación no coincide con el total de montos anotados y acumulados: ${total_de_sumas_de_valor_total}.`,
        placement: 'topRight',
        duration: 7,
      });
      return false;
    }
    return true;
  };

  const comprobarUnidadesEnajenadas = (values) => {
    const { unidades_enajenadas_totales, total_de_sumas_de_unidades } = values;

    if (unidades_enajenadas_totales !== total_de_sumas_de_unidades) {
      formIsan.validateFields(['unidades_enajenadas_totales']).then(() => {
        formIsan.setFields([
          {
            name: 'unidades_enajenadas_totales',
            errors: ['La suma de las unidades de enajenación no coincide con el total'],
          },
        ]);
      });
      notification.info({
        message: `ENAJENACIÓN TOTAL: ${unidades_enajenadas_totales}`,
        description:
          `La suma de las unidades de enajenación no coincide con el total de montos anotados y acumulados: ${total_de_sumas_de_unidades}.`,
        placement: 'topRight',
        duration: 7,
      });
      return false;
    }
    return true;
  };

  const handleCancel = async () => {
    setLoading(true);
    await postRechazarDeclaracion({
      declaracion: declaracionId,
      canal_de_pago: 5,
    });
    setLoading(false);
  };

  const calculaPagoEnExceso = () => {
    const valores = formIsan.getFieldsValue();
    const pago = parseInt(valores.importe_pagado_que_rectifica || 0, 10);
    const totalContribuciones = valores.total_de_contribuciones;

    if (pago > totalContribuciones) {
      formIsan.setFieldsValue({ cantidad_a_pagar: 0 });
      return Math.abs(totalContribuciones - pago);
    } if (pago === '') {
      formIsan.setFieldsValue({ importe_pagado_que_rectifica: parseInt(totalContribuciones, 10) });
    } else if (pago < totalContribuciones) {
      formIsan.setFieldsValue({ cantidad_a_pagar: parseInt(totalContribuciones - pago, 10) });
    }
    return 0;
  };

  const pagoEnExceso = () => {
    formIsan.setFieldsValue({ pago_en_exceso: calculaPagoEnExceso() });
  };

  const totalDeContribuciones = () => {
    const values = formIsan.getFieldsValue();
    const total = parseInt(values.impuesto_del_periodo || 0, 10)
    + parseInt(values.parte_actualizada || 0, 10)
    + parseInt(values.recargos || 0, 10)
    + parseFloat(values.multa_por_correccion || 0, 10);
    formIsan.setFieldsValue({
      total_de_contribuciones: parseInt(total, 10),
    });
    pagoEnExceso();
  };

  const defaultValues = (value, _padron) => {
    if (value) {
      formIsan.setFieldsValue({
        impuesto_del_periodo: parseInt(value.importe, 10),
        parte_actualizada: 0,
        recargos: parseInt(value.recargo_total, 10),
        cantidad_a_pagar: parseInt(value.adeudo_total, 10),
        multa_por_correccion: 0,
        total_de_contribuciones: 0,
        fecha_que_rectifica: null,
        pago_en_exceso: 0,
        importe_pagado_que_rectifica: 0,
        total_de_sumas_de_valor_total: 0,
        es_declaracion_en_ceros: false,
        calle: _padron.calle,
        colonia: _padron.colonia,
        codigo_postal: _padron.codigo_postal,
        municipio: _padron.municipio,
        entidad_federativa: _padron.estado,
        valor_total_enajenacion_automoviles: 0,
        valor_total_enajenacion_camiones: 0,
        valor_total_enajenacion_automoviles_exentos: 0,
        valor_total_enajenacion_vehiculos_exentos: 0,
        valor_total_enajenacion_automoviles_parcialmente_exentos: 0,
        valor_total_enajenacion_automoviles_electricos_con_motor_o_h: 0,
        valor_total_enajenacion_automoviles_con_baterias_electrica: 0,
        unidades_enajenadas_automoviles: 0,
        unidades_enajenadas_camiones: 0,
        unidades_enajenadas_automoviles_exentos: 0,
        unidades_enajenadas_vehiculos_exentos: 0,
        unidades_enajenadas_automoviles_parcialmente_exentos: 0,
        unidades_enajenadas_automoviles_electricos_con_motor_o_h: 0,
        unidades_enajenadas_automoviles_con_bateria_electrica: 0,
      });
      totalDeContribuciones();
    }
    return '';
  };

  const responseSucces = (value) => {
    if (value) {
      const _values = formIsan.getFieldsValue(['cantidad_a_pagar']);
      callback(_values.cantidad_a_pagar <= 0);
      setVisible(false);
      formIsan.resetFields();
    }
  };

  const callbackCount = async (value) => {
    if (value) {
      const _count = value.reduce((acc, item) => acc + parseInt(item.unidades, 10), 0);
      formIsan.setFieldsValue({ total_de_sumas_de_unidades: _count });
      formIsan.validateFields(['total_de_sumas_ de_unidades']);
    }
  };

  const sumaMontos = () => {
    const values = formIsan.getFieldsValue();
    const total = parseInt(values.valor_total_enajenacion_automoviles || 0, 10)
    + parseInt(values.valor_total_enajenacion_camiones || 0, 10)
    + parseInt(values.valor_total_enajenacion_automoviles_exentos || 0, 10)
    + parseInt(values.valor_total_enajenacion_vehiculos_exentos || 0, 10)
    + parseInt(values.valor_total_enajenacion_automoviles_parcialmente_exentos || 0, 10)
    + parseInt(values.valor_total_enajenacion_automoviles_electricos_con_motor_o_h || 0, 10)
    + parseInt(values.valor_total_enajenacion_automoviles_con_baterias_electrica || 0, 10);
    formIsan.setFieldsValue({
      valor_enajenacion_total: parseInt(total, 10),
    });
  };

  const sumarMontosUnidades = () => {
    const values = formIsan.getFieldsValue();
    const total = parseInt(values.unidades_enajenadas_automoviles || 0, 10)
    + parseInt(values.unidades_enajenadas_camiones || 0, 10)
    + parseInt(values.unidades_enajenadas_automoviles_exentos || 0, 10)
    + parseInt(values.unidades_enajenadas_vehiculos_exentos || 0, 10)
    + parseInt(values.unidades_enajenadas_automoviles_parcialmente_exentos || 0, 10)
    + parseInt(values.unidades_enajenadas_automoviles_electricos_con_motor_o_h || 0, 10)
    + parseInt(values.unidades_enajenadas_automoviles_con_bateria_electrica || 0, 10);
    formIsan.setFieldsValue({ unidades_enajenadas_totales: parseInt(total, 10) });
  };

  const patchDeclaracionStatus = async (values) => {
    try {
      let statusId = null;

      const statusObject = {
        // eslint-disable-next-line max-len
        generada: statusDeclaracion.find((status) => !status.declaracion_presentada && !status.declaracion_en_ceros)?.id,
        en_ceros: statusDeclaracion.find((status) => status.declaracion_en_ceros)?.id,
        sin_importe: statusDeclaracion.find((status) => status.descripcion === 'Declaración con Cantidades a Pagar y a Favor en Cero')?.id || 0,
        saldo_favor: statusDeclaracion.find((status) => status.descripcion === 'Declaración con Cantidad a Favor')?.id || 0,
      };

      if (parseFloat(values.pago_en_exceso) > 0) {
        statusId = statusObject.saldo_favor;
      } else if (parseFloat(values.cantidad_a_pagar) <= 0) {
        statusId = statusObject.sin_importe;
      } else {
        statusId = statusObject.generada;
      }

      const request = await patchGenerarDeclaracion({
        id: values.declaracion_empresarial,
        estatus_de_declaracion_empresarial: statusId,
        razones_para_presentar_en_ceros: values.razones_para_presentar_en_ceros,
      });

      if (!request) throw new Error('Error al actualizar la declaración.');
    } catch (error) {
      _logger(error);
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      setIsVisibleModal(false);

      await formIsan.validateFields();
      const valuesForm = formIsan.getFieldsValue();

      const enajenacionTotal = comprobarTotalEnajenacion(valuesForm);
      const unidadesEnajenadas = comprobarUnidadesEnajenadas(valuesForm);

      if (enajenacionTotal && unidadesEnajenadas) {
        valuesForm.valor_total_enajenacion_automoviles ?? 0;
        valuesForm.valor_total_enajenacion_camiones ?? 0;
        valuesForm.valor_total_enajenacion_automoviles_exentos ?? 0;
        valuesForm.valor_total_enajenacion_vehiculos_exentos ?? 0;
        valuesForm.valor_total_enajenacion_automoviles_parcialmente_exentos ?? 0;
        valuesForm.valor_total_enajenacion_automoviles_electricos_con_motor_o_h ?? 0;
        valuesForm.valor_total_enajenacion_automoviles_con_baterias_electrica ?? 0;
        valuesForm.unidades_enajenadas_automoviles ?? 0;
        valuesForm.unidades_enajenadas_camiones ?? 0;
        valuesForm.unidades_enajenadas_automoviles_exentos ?? 0;
        valuesForm.unidades_enajenadas_vehiculos_exentos ?? 0;
        valuesForm.unidades_enajenadas_automoviles_parcialmente_exentos ?? 0;
        valuesForm.unidades_enajenadas_automoviles_electricos_con_motor_o_h ?? 0;
        valuesForm.unidades_enajenadas_automoviles_con_bateria_electrica ?? 0;

        const _values = {
          declaracion_empresarial: declaracionId,
          entidad: 1,
          ...padronValues,
          ...valuesForm,
          cantidad_a_pagar: parseFloat(valuesForm.cantidad_a_pagar).toFixed(2),
          impuesto_del_periodo: parseFloat(valuesForm.impuesto_del_periodo).toFixed(2),
          importe_pagado_que_rectifica: parseFloat(
            valuesForm.importe_pagado_que_rectifica,
          ).toFixed(2),
          multa_por_correccion: parseFloat(valuesForm.multa_por_correccion).toFixed(2),
          parte_actualizada: parseFloat(valuesForm.parte_actualizada).toFixed(2),
          valor_total_enajenacion_automoviles: parseFloat(
            valuesForm.valor_total_enajenacion_automoviles,
          ).toFixed(2),
          valor_total_enajenacion_camiones: parseFloat(valuesForm.valor_total_enajenacion_camiones),
          suma_montos_anotados_de_unidades: parseFloat(
            valuesForm.total_de_sumas_de_unidades,
          ).toFixed(2),
          suma_acumulada_de_unidades: parseFloat(valuesForm.total_de_sumas_de_unidades).toFixed(2),
          suma_de_montos_anotados_de_valor_total: parseFloat(
            valuesForm.total_de_sumas_de_valor_total,
          ).toFixed(2),
          suma_acumulada_de_valor_total: parseFloat(
            valuesForm.total_de_sumas_de_valor_total,
          ).toFixed(2),
          pago_en_exceso: parseInt(valuesForm.pago_en_exceso, 10),
          fecha_que_rectifica: valuesForm?.fecha_que_rectifica?.format(DATE_FORMAT) || null,
        };

        await patchDeclaracionStatus(_values);
        const response = await postDeclaracionIsan(_values);

        if (response) setDeclaracionIsanId(response.id);
        // setIsZeroState(false);
        setIsVisibleModal(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      _logger(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const padronInfo = {
      razon_social_nombre_completo: padron?.empresa?.contribuyente?.razon_social || '',
      rfc: padron?.empresa?.contribuyente?.rfc || '',
      curp: padron?.empresa?.contribuyente?.ciudadano?.curp || '',
      nombre: padron?.empresa?.contribuyente?.ciudadano?.nombre || '',
      apellido_paterno: padron?.empresa?.contribuyente?.ciudadano?.apellido_paterno || '',
      apellido_materno: padron?.empresa?.contribuyente?.ciudadano?.apellido_materno || '',

      // EMPRESA
      estado: padron?.empresa?.direccion_de_ubicacion?.codigo_postal?.municipio?.estado?.nombre_de_AGEE || '',
      municipio: padron?.empresa?.direccion_de_ubicacion?.codigo_postal?.municipio?.nom_mun || '',
      calle: padron?.empresa?.direccion_de_ubicacion?.calle_principal || '',
      colonia: padron?.empresa?.direccion_de_ubicacion?.colonia || '',
      codigo_postal: padron?.empresa?.direccion_de_ubicacion?.codigo_postal?.d_codigo || '',
      telefono: padron?.empresa?.direccion_de_ubicacion?.telefono_fijo || padron?.empresa?.direccion_de_ubicacion?.telefono_celular || '',
    };

    defaultValues(cargosGenerados[0], padronInfo);

    setPadronValues(padronInfo);
    setLoading(false);
  }, [declaracionId, cargosGenerados]);

  const handleUnload = (event) => {
    const message = 'Se perderán los datos capturados, ¿Estás seguro de salir?';
    event.preventDefault();
    event.returnValue = message; // Esto mostrará el cuadro de confirmación
    return message;
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (visible) {
      window.addEventListener('unload', handleUnload);
      window.addEventListener('beforeunload', handleCancel);

      return () => {
        window.removeEventListener('unload', handleUnload);
        window.removeEventListener('beforeunload', handleCancel);
      };
    }
  }, [visible]);

  return (
    <>
      <Modal
        width="85vw"
        visible={visible}
        closable={false}
        title={(
          <Row justify="space-between">
            <Text $accent>
              Formato ISAN
            </Text>
          </Row>
      )}
        forceRender
        centered
        footer={(
          <Row justify="end" gutter={[10, 15]}>
            <Col xs={24} sm={24} md={6}>
              <Button
                style={{ width: '100%', borderRadius: 5 }}
                onClick={() => callbackCancel()}
                loading={loading}
                $accent
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Button
                type="primary"
                style={{ width: '100%' }}
                onClick={() => setIsVisibleModal(true)}
                loading={loading}
                icon={<SyncOutlined />}
                $accent
              >
                Generar
              </Button>
            </Col>
          </Row>
      )}
      >
        <Form
          form={formIsan}
          layout="vertical"
          onFinish={onFinish}
        >
          {/* <Row gutter={10}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="es_declaracion_en_ceros"
                label="¿Es Declaración en ceros?"
                required
              >
                <Select
                  placeholder="Selecciona una opción"
                  trueFalse
                  allowClear={false}
                  onChange={setIsZeroState}
                />
              </Form.Item>
            </Col>
            {
              isZeroState && (
              <Col xs={24} sm={24} md={8}>
                <Form.Item
                  name="razones_para_presentar_en_ceros"
                  label="Razones por las que presenta en ceros"
                  rules={[_rules.required('El campo es requerido')]}
                >
                  <Input.TextArea />
                </Form.Item>
              </Col>
              )
            }
          </Row> */}
          <Divider orientation="left">
            PRIMERA PARTE
          </Divider>
          <Row gutter={10}>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="es_matriz"
                label="Matriz"
                required
              >
                <Select
                  disabled
                  placeholder="Selecciona una opción"
                  trueFalse
                  allowClear={false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="es_sucursal"
                label="Sucursal"
                required
              >
                <Select
                  placeholder="Selecciona una opción"
                  trueFalse
                  allowClear={false}
                  onChange={(value) => {
                    formIsan.setFieldsValue({ es_matriz: !value });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="es_fabricante"
                label="Fabricante"
                key="value"
                required
              >
                <Select placeholder="Selecciona una opción" trueFalse />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="es_ensamblador"
                label="Ensambador"
                key="value"
                required
              >
                <Select placeholder="Selecciona una opción" trueFalse />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="es_distribuidor"
                label="Distribuidor"
                key="value"
                required
              >
                <Select placeholder="Selecciona una opción" trueFalse />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="es_importador"
                label="Importador"
                key="value"
                required
              >
                <Select placeholder="Selecciona una opción" trueFalse />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">
            SEGUNDA PARTE
          </Divider>
          <Row gutter={10}>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="impuesto_del_periodo"
                label="(A) Impuesto del periodo"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                  ..._rules.mayorCero,
                  ..._rules.numeric,
                ]}
              >
                <Input onChange={() => totalDeContribuciones()} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="parte_actualizada"
                label="(B) Parte actualizada"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                  ..._rules.numeric,
                ]}
              >
                <Input onChange={() => totalDeContribuciones()} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="recargos"
                label="(C) Recargos"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                  ..._rules.numeric,
                ]}
              >
                <Input onChange={() => totalDeContribuciones()} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="multa_por_correccion"
                label="(D) Multa por corrección"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                  ..._rules.numeric,
                ]}
              >
                <Input onChange={() => totalDeContribuciones()} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="total_de_contribuciones"
                label="(E) Total de contribuciones (A+B+C+D)"
                hasFeedback
              >
                <Input
                  disabled
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="importe_pagado_que_rectifica"
                label="(F) Importe pagado que rectifica"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                  ..._rules.numeric,
                ]}
              >
                <Input onChange={() => pagoEnExceso()} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="pago_en_exceso"
                label="(G) Pago en exceso (en su caso E - F)"
                hasFeedback
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="cantidad_a_pagar"
                label="(H) Cantidad a pagar (E - F)"
                hasFeedback
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item name="fecha_que_rectifica" label="Fecha que rectifica">
                <DatePicker format={DATE_FORMAT} />
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="numero_de_transferencia_de_fondos"
                label="Número de transferencia electrónica de fondos"
              >
                <Input />
              </Form.Item>
            </Col> */}
          </Row>
          <Divider orientation="left">TERCERA PARTE</Divider>
          <Col xs={24} sm={24} md={24}>
            <Row gutter={10}>
              <table>
                <thead>
                  <tr>
                    <th>Concepto</th>
                    <th>Unidades enanjenadas</th>
                    <th>Valor total de la enajenación</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ marginBottom: '50px' }}>
                    <td>
                      <span>
                        I. Automóviles enajenados en el mercado nacional
                        (Artículo 3 fracción I de la Ley Federal del ISAN).
                      </span>
                    </td>
                    <td>
                      <Form.Item
                        name="unidades_enajenadas_automoviles"
                        hasFeedback
                        rules={[
                          _rules.required('El campo es requerido'),
                          ..._rules.numericInt,
                          ..._rules.mayorQue('valor_total_enajenacion_automoviles'),
                        ]}
                      >
                        <Input onChange={() => {
                          sumarMontosUnidades();
                        }}
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="valor_total_enajenacion_automoviles"
                        hasFeedback
                        rules={[
                          _rules.required('El campo es requerido'),
                          ..._rules.numericInt,
                          ..._rules.mayorQue('unidades_enajenadas_automoviles'),
                        ]}
                      >
                        <Input onChange={() => { sumaMontos(); }} />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        J. Camiones enajenados en el mercado nacional
                        (Artículo 8 fracción I de la Ley Federal del ISAN).
                      </span>
                    </td>
                    <td>
                      <Form.Item
                        name="unidades_enajenadas_camiones"
                        hasFeedback
                        rules={[
                          _rules.required('El campo es requerido'),
                          ..._rules.numericInt,
                          ..._rules.mayorQue('valor_total_enajenacion_camiones'),
                        ]}
                      >
                        <Input onChange={() => sumarMontosUnidades()} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="valor_total_enajenacion_camiones"
                        hasFeedback
                        rules={[
                          _rules.required('El campo es requerido'),
                          ..._rules.numericInt,
                          ..._rules.mayorQue('unidades_enajenadas_camiones'),
                        ]}
                      >
                        <Input onChange={() => sumaMontos()} />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        K. Automóviles exentos en el mercado nacional
                        (Artículo 8 fracción I de la Ley Federal del ISAN).
                      </span>
                    </td>
                    <td>
                      <Form.Item
                        name="unidades_enajenadas_automoviles_exentos"
                        hasFeedback
                        rules={[
                          _rules.required('El campo es requerido'),
                          ..._rules.numericInt,
                          ..._rules.mayorQue('valor_total_enajenacion_automoviles_exentos'),
                        ]}
                      >
                        <Input onChange={() => sumarMontosUnidades()} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="valor_total_enajenacion_automoviles_exentos"
                        hasFeedback
                        rules={[
                          _rules.required('El campo es requerido'),
                          ..._rules.numericInt,
                          ..._rules.mayorQue('unidades_enajenadas_automoviles_exentos'),
                        ]}
                      >
                        <Input onChange={() => sumaMontos()} />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        K1. Automóviles enanjenados parcialmente exentos
                        en el mercado nacional
                        <br />
                        (Artículo 8 fracción II, segundo párrafo de la Ley Federal del ISAN)
                      </span>
                    </td>
                    <td>
                      <Form.Item
                        name="unidades_enajenadas_automoviles_parcialmente_exentos"
                        hasFeedback
                        rules={[
                          _rules.required('El campo es requerido'),
                          ..._rules.numericInt,
                          ..._rules.mayorQue('valor_total_enajenacion_automoviles_parcialmente_exentos'),
                        ]}
                      >
                        <Input onChange={() => sumarMontosUnidades()} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="valor_total_enajenacion_automoviles_parcialmente_exentos"
                        hasFeedback
                        rules={[
                          _rules.required('El campo es requerido'),
                          ..._rules.numericInt,
                          ..._rules.mayorQue('unidades_enajenadas_automoviles_parcialmente_exentos'),
                        ]}
                      >
                        <Input onChange={() => sumaMontos()} />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        L. Vehiculos exentos en el mercado nacional
                        (Artículo 8 fracción III de la Ley)
                      </span>
                    </td>
                    <td>
                      <Form.Item
                        name="unidades_enajenadas_vehiculos_exentos"
                        hasFeedback
                        rules={[
                          _rules.required('El campo es requerido'),
                          ..._rules.numericInt,
                          ..._rules.mayorQue('valor_total_enajenacion_vehiculos_exentos'),
                        ]}
                      >
                        <Input onChange={() => sumarMontosUnidades()} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="valor_total_enajenacion_vehiculos_exentos"
                        hasFeedback
                        rules={[
                          _rules.required('El campo es requerido'),
                          ..._rules.numericInt,
                          ..._rules.mayorQue('unidades_enajenadas_vehiculos_exentos'),
                        ]}
                      >
                        <Input onChange={() => sumaMontos()} />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        M1. Automóviles enanjenados o importados definitivamente,
                        excentos en el mercado nacional
                      </span>
                    </td>
                    <td>
                      <Form.Item
                        name="unidades_enajenadas_automoviles_con_bateria_electrica"
                        hasFeedback
                        rules={[
                          _rules.required('El campo es requerido'),
                          ..._rules.numericInt,
                          ..._rules.mayorQue('valor_total_enajenacion_automoviles_con_baterias_electrica'),
                        ]}
                      >
                        <Input onChange={() => sumarMontosUnidades()} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="valor_total_enajenacion_automoviles_con_baterias_electrica"
                        hasFeedback
                        rules={[
                          _rules.required('El campo es requerido'),
                          ..._rules.numericInt,
                          ..._rules.mayorQue('unidades_enajenadas_automoviles_con_bateria_electrica'),
                        ]}
                      >
                        <Input onChange={() => sumaMontos()} />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>
                        M2. Automóviles elécticos que
                        además cuentan con motor de combustión interna o
                        <br />
                        con motor accionado por
                        hidrógeno enanjenados o importados definitivamente,
                        <br />
                        exentos en el mercado
                        nacional
                      </span>
                    </td>
                    <td>
                      <Form.Item
                        name="unidades_enajenadas_automoviles_electricos_con_motor_o_h"
                        hasFeedback
                        rules={[
                          _rules.required('El campo es requerido'),
                          ..._rules.numericInt,
                          ..._rules.mayorQue('valor_total_enajenacion_automoviles_electricos_con_motor_o_h'),
                        ]}
                      >
                        <Input onChange={() => sumarMontosUnidades()} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="valor_total_enajenacion_automoviles_electricos_con_motor_o_h"
                        hasFeedback
                        rules={[
                          _rules.required('El campo es requerido'),
                          ..._rules.numericInt,
                          ..._rules.mayorQue('unidades_enajenadas_automoviles_electricos_con_motor_o_h'),
                        ]}
                      >
                        <Input onChange={() => sumaMontos()} />
                      </Form.Item>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      P.TOTAL
                    </td>
                    <td>
                      <Form.Item name="unidades_enajenadas_totales" hasFeedback>
                        <Input disabled />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="valor_enajenacion_total"
                        hasFeedback
                        rules={[
                          _rules.required('El campo es requerido'),
                        ]}
                      >
                        <Input disabled />
                      </Form.Item>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Row>
          </Col>
          <Divider orientation="left">DATOS DEL REPRESENTANTE LEGAL</Divider>
          <Row gutter={10}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="representante_legal_rfc"
                label="Registro Federal de Contribuyentes"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="representante_legal_curp"
                label="Clave única de registro de población"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="representante_legal_apellido_paterno"
                label="Apellido paterno"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="representante_legal_apellido_materno"
                label="Apellido materno"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="representante_legal_nombre"
                label="Nombre(s)"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">DATOS INFORMATIVOS</Divider>
          <Row gutter={10}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="numero_vehiculos_incorp_activo_fijo"
                label="Número de vehículos incorporados al activo fijo"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                  ..._rules.numeric,
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="factura_inicial_del_periodo"
                label="Facturas inicial del periodo"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="factura_final_del_periodo"
                label="Factura final del periodo"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {/* <Divider orientation="left">SI PAGA CON CHEQUE INDIQUE</Divider>
          <Row gutter={10}>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="numero_de_cheque"
                label="Número de cheque"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="numero_de_cuenta_del_cheque"
                label="Número de cuenta del cheque"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="banco_del_cheque"
                label="Banco del cheque"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row> */}
          <Divider orientation="left">ANÁLISIS DE LAS ENAJENACIONES EN EL MERCADO NACIONAL, POR MODELO, EN EL PERIODO QUE SE DECLARA</Divider>
          <TableISAN
            declaracionIsanId={declaracionIsanId}
            callback={(value) => responseSucces(value)}
            callbackCount={(value) => callbackCount(value)}
          />
          <Row gutter={10}>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="total_de_sumas_de_unidades"
                label="Unidades enajenadas totales"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                  ..._rules.mayorCero,
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <Form.Item
                name="total_de_sumas_de_valor_total"
                label="Total de montos anotados y acumulados"
                rules={[
                  _rules.required('El campo es requerido'),
                  ..._rules.mayorCero,
                ]}
                hasFeedback
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">
            DOMICILIO DEL ESTABLECIMIENTO
          </Divider>
          <Row gutter={10}>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="calle"
                label="Calle"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="nro_letra_exterior"
                label="Número/letra exterior"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="nro_letra_interior"
                label="Número/letra interior"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="colonia"
                label="Colonia"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="municipio"
                label="Municipio"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="codigo_postal"
                label="Código postal"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                ]}
              >
                <Input maxLength={5} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="localidad"
                label="Localidad"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="entidad_federativa"
                label="Entidad federativa"
                hasFeedback
                rules={[
                  _rules.required('El campo es requerido'),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <Form.Item
                name="telefono"
                label="Teléfono"
                hasFeedback
                rules={_rules.telefono}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <ModalConfirm
        visible={isVisibleModal}
        setVisible={setIsVisibleModal}
        onFinish={() => onFinish()}
        body="Al generar la declaración no podrá ser modificada. ¿Desea continuar?"
        loading={loading}
      />
    </>
  );
};

FormISAN.propTypes = {
  declaracionId: propTypes.number.isRequired,
  padron: propTypes.shape({
    empresa: propTypes.shape({
      contribuyente: propTypes.shape({
        razon_social: propTypes.string,
        rfc: propTypes.string,
        ciudadano: propTypes.shape({
          curp: propTypes.string,
          nombre: propTypes.string,
          apellido_paterno: propTypes.string,
          apellido_materno: propTypes.string,
        }),
      }),
      direccion_de_ubicacion: propTypes.shape({
        colonia: propTypes.string,
        telefono_celular: propTypes.string,
        telefono_fijo: propTypes.string,
        calle_principal: propTypes.string,
        codigo_postal: propTypes.shape({
          d_codigo: propTypes.string,
          municipio: propTypes.shape({
            nom_mun: propTypes.string,
            estado: propTypes.shape({
              nombre_de_AGEE: propTypes.string,
            }),
          }),
        }),
      }),
    }),
  }).isRequired,
  statusDeclaracion: propTypes.number.isRequired,
  callbackCancel: propTypes.func.isRequired,
  visible: propTypes.bool,
  setVisible: propTypes.func.isRequired,
  callback: propTypes.func.isRequired,
  cargosGenerados: propTypes.arrayOf(propTypes.shape({
    adeudo_total: propTypes.number,
  })),
};

FormISAN.defaultProps = {
  cargosGenerados: [],
  visible: false,
};

export default FormISAN;
