import React, { useState, useEffect, useRef } from 'react';
import {
  Form,
  Col,
  Skeleton,
  Card,
  Row,
  Table,
  DatePicker,
  message,
  Grid,
  Modal,
  Input,
  Radio,
  Tag,
  Tooltip,
  Dropdown,
  Menu,
  // Button as AntButton,
} from 'antd';
import {
  Title,
  Text,
  Button,
  COLORS,
} from 'components';
import { useParams, Navigate } from 'react-router-dom';
import {
  PlusOutlined,
  CheckOutlined,
  MinusOutlined,
  DeleteOutlined,
  SyncOutlined,
  BarcodeOutlined,
  EyeOutlined,
  SearchOutlined,
  SwapRightOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
  CompressOutlined,
  ExpandOutlined,
  CreditCardOutlined,
  HistoryOutlined,
  DownOutlined,
} from '@ant-design/icons';
import {
  getTiposDeCargos,
  getPeriodosFiscales,
  postGenererCargos,
  postGenererReferenciasNetpay,
  postGenererReferenciasBanco,
  getUMA,
  getSM,
  getDeclaracionPDF,
  getDeclaraciones,
  getClasesDeDeclaracion,
  getEstatusDeDeclaracion,
  postRechazarDeclaracion,
  getReciboPorOrden,
} from 'api/recaudacion';
import gettersMap, {
  getAlcoholById, getContenTypePorTipoDePadron, getVentaAlcoholById,
} from 'api/padrones';
import Previewer from 'components/Previewer';
import toSlug, { extractSegment } from 'utils/toSlug';
import {
  toCurrency,
  toInteger,
  decimalNormalizer,
  DATE_FORMAT,
  DATE_FRONTEND_FORMAT,
} from 'utils/formatters';
import { scrollToTop } from 'components/ScrollToTop';
import {
  DECIMAL_PATTERN,
  INTEGER_PATTERN,
  RFC_PATTERN,
  CURP_PATTERN,
  ALPHA_NUMERIC_PATTERN,
} from 'utils/patterns';
import NetPayButton, {
  getNetPayBtn,
  paymentLocationsEnum,
  validateLastTransaction,
} from 'views/Payments/components/NetPayButton';
import SearchForm from 'views/Payments/components/SearchForm';
import WaitingAlert from 'views/Payments/components/WaitingAlert';
import useWaitingAlert from 'hooks/useWaitingAlert';
import debtsFormatter, { debtsColumns } from 'utils/debtsFormatter';
import ModalUpdateInfo from 'views/Payments/components/ModalUpdateInfo';
import Moment from 'moment';
import round from 'utils/round';
import { getAdeudoPadronAsync } from 'hooks/useAdeudoPadron';
import {
  CATALOGOS_DE_CANAL_DE_PAGO,
  ENV_NAME,
  ENV_NAMES_ENUM,
  CLAVE_CATASTRAL_SEGMENTOS,
  ID_ENTIDAD,
  SHOW_REDONDEO,
  USER_PORTAL_ID,
} from 'utils/env';
import useQuery from 'hooks/useQuery';
import { useSelector } from 'react-redux';
import {
  selectClasificadoresDeTiposDeCargos,
  selectContentTypesPadrones,
  selectPadrones,
} from 'store/catalogos';
import formatValues, { DATETIME_FRONTEND_FORMAT } from 'utils/formatValues';
import ModalBancos from 'views/Payments/components/ModalBancos';
import Spinner from 'components/Spinner';
import { aplicacionesCargosEnum, padronesEnum, tiposDatosEnum } from 'views/Tramites/GPM/enums';
import { toMoment } from 'utils/normalizers';
import ModalConfirm from 'components/ModalConfirm';
import ModalImporte from 'views/Payments/components/ModalImporte';
import { selectUser } from 'store/auth';
import ModalAlertas from 'components/ModalAlertas';

const isNayarit = ENV_NAME === ENV_NAMES_ENUM.NAYARIT;

const periodosMap = Object.freeze({
  EVENTUAL: 1,
  DIARIO: 2,
  SEMANAL: 3,
  QUINCENAL: 4,
  MENSUAL: 5,
  BIMESTRAL: 6,
  TRIMESTRAL: 7,
  CUATRIMESTRAL: 8,
  SEMESTRAL: 9,
  ANUAL: 10,
});

const allowedRoutesReports = Object.freeze({
  NOTARIO: [
    'isr-enajenacion-de-bienes-inmuebles-(notarios)-12',
  ],
  SERVICIOS_REGISTRALES: [
    'servicios-registrales-12',
  ],
});

const filtersExtemporaneos = {
  [ENV_NAMES_ENUM.NAYARIT]: {
    6: '40202', //     Hospedaje
    8: '40102', //     Nomina
    9: '4060802', //   Alcohol
    10: '4060502', //  Cédulas
    11: '40404', //    Juegos de Azar
  },
  [ENV_NAMES_ENUM.JUAREZ]: {},
  [ENV_NAMES_ENUM.HERMOSILLO]: {},
};

export const recievePadronFound = async ({
  padron = null,
  setLoading = null,
  setVisibleModalUpdateInfo = null,
  callback = null,
  inNeedsInfo = {
    setPadronId: () => {},
    setTipoDePadron: () => {},
  },
}) => {
  if (typeof padron.infoNeeded === 'boolean') {
    if (!padron.infoNeeded) {
      if (typeof callback === 'function') {
        await callback();
      }
    }
    if (padron.infoNeeded) {
      setLoading(false);
      setVisibleModalUpdateInfo(true);
      inNeedsInfo.setPadronId(padron.idPadronUpdate);
      inNeedsInfo.setTipoDePadron(padron.tipoDePadronNeedsInfo);
    }
  }
  if (padron.infoNeeded?.error) {
    message.warn(padron.infoNeeded.error, 3);
    setLoading(false);
  }
};

const PaymentForm = () => {
  // Hooks
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const [formDatosGenerales] = Form.useForm();
  const [formFilter] = Form.useForm();
  const {
    classifier: _classifier,
    subclassifier: _subclassifier,
  } = useParams();
  const [tipoDeCargo] = useQuery(['tipoDeCargo']);
  const {
    visibleAlert,
    loading,
    setLoading,
  } = useWaitingAlert();
  const refTitleCargosTable = useRef();
  const clasificadores = useSelector(selectClasificadoresDeTiposDeCargos);
  const contentTypesPadrones = useSelector(selectContentTypesPadrones);
  const padrones = useSelector(selectPadrones);
  const user = useSelector(selectUser);

  // State
  const [periodoFiscal, setPeriodoFiscal] = useState();
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [previewBase64, setPreviewBase64] = useState();
  const [visibleBancos, setVisibleBancos] = useState(false);
  const [isNewFolio, setIsNewFolio] = useState(true);
  const [visiblePreviewNetPay, setVisiblePreviewNetPay] = useState(false);
  const [currentClassifier, setCurrentClassifier] = useState();
  const [padron, setPadron] = useState();
  const [tiposDeCargos, setTiposDeCargos] = useState({ results: [] });
  const [_cargosGenerados, setCargosGenerados] = useState([]);
  const [_cargosGeneradosDiff, setCargosGeneradosDiff] = useState([]);
  const [selectedCargos, setSelectedCargos] = useState([]);
  const [periodosFiscales, setPeriodosFiscales] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [referencia, setReferencia] = useState();
  const [declaracionPDF, setDeclaracionPDF] = useState();
  const [tipoDePadron, setTipoDePadron] = useState();
  const [UMA, setUMA] = useState();
  const [SM, setSM] = useState();
  const [_isZeroDeclaration, setIsZeroDeclaration] = useState(true);
  const [declaraciones, setDeclaraciones] = useState([]);
  const [claseDeDeclaracion, setClaseDeDeclaracion] = useState([]);
  const [statusDeclaracion, setStatusDeclaracion] = useState([]);
  const declaracionAnterior = useRef('');
  const [declaracionId, setDeclaracionId] = useState(0);

  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [idPadron, setIdPadron] = useState(0);

  const [tipoDePadronId, setTipoDePadronId] = useState(0);
  const [referenciaNormal, setReferenciaNormal] = useState(null);

  const [showImporteModal, setShowImporteModal] = useState(false);
  const [tempCargos, setTempCargos] = useState([]);
  const [GenerandoRecibo, setGenerandoRecibo] = useState(false);
  const [acceptDeclaration, setAcceptDeclaration] = useState(false);

  const [alertasInformativas, setAlertasInformativas] = useState([]);
  const [alertasRestrictivas, setAlertasRestrictivas] = useState([]);

  /* ? Esto es en caso de que Jair lo pida, las
    condiciones estan mal solo falta corregir y descomentar */
  // const [referenciaTemp, setReferenciaTemp] = useState([]);
  // const [referenciaNormalTemp, setReferenciaNormalTemp] = useState([]);
  const [cargosTemp, setCargosTemp] = useState([]);

  const [debtsAsked, setDebtsAsked] = useState(false);

  const classifierParam = extractSegment(_classifier, '-', -1);
  const subclassifier = extractSegment(_subclassifier, '-', -1);
  const classifier = `${classifierParam}.${subclassifier}`;
  const [variablesValues, setVariablesValues] = useState(null);

  const isDeclaration = classifierParam === '01' || classifierParam === '04';
  const isZeroDeclaration = isDeclaration && _isZeroDeclaration;

  const cargosGenerados = debtsFormatter(_cargosGenerados);
  const cargosGeneradosDiff = debtsFormatter(_cargosGeneradosDiff);

  const lastClickedCharge = useRef(0);

  const isGeneratedZeroDeclaration = isDeclaration
    && cargosGenerados.length && !cargosGenerados[0]?.importe;

  const [total, rounded] = round(cargosGenerados
    .reduce((acum, curr) => acum + curr.adeudo_total, 0));

  const calculateAmount = (record) => {
    const { importe, moneda } = record;
    const formatByMoneda = {
      3: () => +(importe * SM),
      4: () => +(importe * UMA),
    };
    return formatByMoneda[moneda] ? formatByMoneda[moneda]() : +(importe);
  };

  const getDeclaracionesGeneradas = async ({
    _tipoDepadron,
    periodo_fiscal,
    match,
  }) => {
    const content_type_padron = contentTypesPadrones.find((e) => e.nivel === _tipoDepadron)?.id;
    const ejercicio_anual = periodosFiscales.find((e) => e.id === periodo_fiscal)?.periodo;
    const _declaraciones = await getDeclaraciones({
      object_id_padron: match.id,
      content_type_padron,
      ejercicio_anual,
    });
    const _clasesDeDeclaraciones = await getClasesDeDeclaracion({
      content_type_padron,
    });
    if (_clasesDeDeclaraciones.length) {
      const clase_de_declaracion_empresarial = _clasesDeDeclaraciones[0].id;
      setClaseDeDeclaracion(clase_de_declaracion_empresarial);
    }
    setDeclaraciones(_declaraciones);
  };

  const add = (_tipoDeCargo) => {
    const copy = [...selectedCargos];
    if (!selectedCargos.some((e) => e.id === _tipoDeCargo.id)) {
      copy.push({ ..._tipoDeCargo, cantidad: 1 });
    } else {
      const idx = copy.findIndex((e) => e.id === _tipoDeCargo.id);
      if (copy[idx].cantidad < 50) {
        copy[idx][copy[idx].tipo_de_aplicacion === aplicacionesCargosEnum.MULTIPLO ? 'importe' : 'cantidad'] += 1;
      }
    }
    if (isDeclaration || _tipoDeCargo.variables?.length) {
      setExpandedRowKeys([_tipoDeCargo.id]);
    }
    if (_tipoDeCargo.tipo_de_aplicacion === aplicacionesCargosEnum.CAPTURABLE) {
      setTempCargos(copy);
      setShowImporteModal(true);
    } else {
      setSelectedCargos(copy);
    }
  };

  const fetchTiposDeCargos = async (page, descripcion, _params = {}) => {
    const currentMonth = parseInt(Moment().format('MM'), 10);
    const currentYear = parseInt(Moment().format('YYYY'), 10);
    const periodo_fiscal = form.getFieldValue('periodo_fiscal');
    const currentPeriodoFiscal = periodosFiscales
      .find((e) => e.periodo === currentYear && e.vigente);
    setLoading(true);
    const tiposDeAplicacionNoDeclaration = [
      aplicacionesCargosEnum.FIJO,
      aplicacionesCargosEnum.MULTIPLO,
      aplicacionesCargosEnum.PORCENTAJE,
      aplicacionesCargosEnum.FORMULA,
    ];
    if (classifierParam === '02') {
      // eslint-disable-next-line no-param-reassign
      delete _params.es_cargo_unico;
    }

    const params = {
      // es_cargo_unico: _params?.es_cargo_unico || false,
      ..._params,
      page: page || 1,
      canales_de_pago: isDeclaration || ENV_NAME === 'nayarit' ? CATALOGOS_DE_CANAL_DE_PAGO.PORTAL_FINANCIERO
        : CATALOGOS_DE_CANAL_DE_PAGO.PAGO_EN_LINEA,
      padron: tipoDePadron,
      tipo_de_aplicacion: isDeclaration
        ? [aplicacionesCargosEnum.FORMULA, aplicacionesCargosEnum.SQL_FUNCTION]
        : tiposDeAplicacionNoDeclaration,
      es_accesorio: false,
      clasificador_de_tipo_de_cargo_en_portal: currentClassifier?.id,
      periodo_fiscal,
    };

    // Agrega la llave (clave o descripcion) basado en el valor de la descripcion.
    params[!Number.isNaN(Number(descripcion)) ? 'clave' : 'descripcion'] = descripcion;

    if (isDeclaration || classifierParam === '02') {
      // Autodeterminables
      params.concepto_de_ingreso__naturaleza_de_ingresos = 2;
    }
    if (currentPeriodoFiscal?.id === periodo_fiscal) {
      params.identificadores = currentMonth;
    }
    if (tipoDeCargo) {
      params.id = tipoDeCargo;
    }
    const _tiposDeCargos = await getTiposDeCargos(params);
    setTiposDeCargos(_tiposDeCargos);
    _tiposDeCargos.results = _tiposDeCargos.results
      .map((_tipoDeCargo) => {
        const accesorios = debtsFormatter(
          _tipoDeCargo.accesorios[0]?.id ? _tipoDeCargo.accesorios : [],
          _tipoDeCargo,
        );
        const importe_total = calculateAmount(_tipoDeCargo);
        const importe_total_accesorios = accesorios
          .reduce((acum, curr) => acum + (curr?.importe || 0), 0);
        const importe_real = ([aplicacionesCargosEnum.FIJO, aplicacionesCargosEnum.MULTIPLO]
          .includes(_tipoDeCargo.tipo_de_aplicacion)
          && accesorios.every((e) => [
            aplicacionesCargosEnum.FIJO,
            aplicacionesCargosEnum.PORCENTAJE,
          ].includes(e?.tipo_de_aplicacion))
          ? importe_total + importe_total_accesorios : 'Por calcular');
        return {
          ..._tipoDeCargo,
          accesorios,
          importe_total_accesorios,
          importe_real,
          importe_total,
        };
      });
    _tiposDeCargos.results.forEach((_, idx) => {
      if (_tiposDeCargos.results[idx].tipo_de_aplicacion === aplicacionesCargosEnum.MULTIPLO) {
        _tiposDeCargos.results[idx].importe = 1;
      }
    });
    setTiposDeCargos(_tiposDeCargos);
    if (tipoDeCargo) {
      setTimeout(() => {
        add(_tiposDeCargos.results[0]);
        refTitleCargosTable.current
          ?.scrollIntoView({ block: 'center', behavior: 'smooth' });
      });
    }
    setLoading(false);
  };

  /**
   * @description Recibe los periodos fiscales y regresa los últimos
   * años definidos por el parametro quantityYears
   * @param {Array} periodosFiscal
   * @param {Number} quantityYears
   * @returns {Array} lastYears
   */
  const getLastYears = (periodosFiscal, quantityYears) => {
    const actualYear = new Date().getFullYear();
    const order = periodosFiscal.sort((a, b) => b.periodo - a.periodo);
    const lastYears = order.filter((e) => e?.periodo <= actualYear
    && e?.periodo >= actualYear - quantityYears);
    return lastYears;
  };

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      if (clasificadores && mounted) {
        const currentPath = window.location.pathname.split('/').at(-1);
        const _currentClassifier = clasificadores.subClasificadores
          .find((e) => toSlug(`${e.nombre} ${e.clasificador.split('.')[1]}`) === currentPath);
        setCurrentClassifier(_currentClassifier);
      }
      const [
        _periodosFiscales,
        _uma,
        _sm,
      ] = await Promise.all([
        getPeriodosFiscales(),
        getUMA(),
        getSM(),
      ]);
      const lastYears = getLastYears(_periodosFiscales, 5);
      const _padron = padrones.find((e) => e.id === parseInt(subclassifier, 10));
      if (mounted) {
        form.setFieldsValue({ padron: !classifier.startsWith('03') ? _padron?.id : 1 });
        setTipoDePadron(!classifier.startsWith('03') ? _padron?.id : 1);
        form.setFieldsValue({
          periodo_fiscal: lastYears
            .find((p) => p.periodo === new Date().getFullYear())?.id,
        });
        setPeriodosFiscales(lastYears);
        setUMA(_uma);
        setSM(_sm);
      }
      setLoading(false);
    };
    fetch();
    return () => { mounted = false; };
  }, []);

  const statusByContentTypes = async (_padron, padron_id) => {
    const content_type_padron = getContenTypePorTipoDePadron(_padron);
    const params = {
      object_id_padron: padron_id,
      content_type_padron,
    };
    const [clase] = await getClasesDeDeclaracion(params);
    if (clase) {
      const status = await getEstatusDeDeclaracion({
        clase_de_declaracion_empresarial: clase.id,
      });
      setStatusDeclaracion(status);
    }
  };

  const onFinishSearch = async (values) => {
    setLoading(true);
    const {
      padron: _padron,
      q,
      periodo_fiscal,
      ...claveRfc
    } = values;
    const defaultParams = { q };
    const paramsObj = {
      3: {
        q: CLAVE_CATASTRAL_SEGMENTOS[ENV_NAME]
          ? [values?.localidad, values?.sector, values?.manzana, values?.lote, values?.condominio].join('-')
          : values.q,
      },
      4: {
        placa: values.placa,
        numero_de_identificacion_vehicular: values.serie,
      },
      16: {
        serie: values.serie,
        numero_de_placa_vigente: values.placa,
      },
      17: {
        serie: values.serie,
        placa: values.placa,
      },
      6: isNayarit ? claveRfc : defaultParams,
      7: isNayarit ? claveRfc : defaultParams,
      8: isNayarit ? claveRfc : defaultParams,
      9: isNayarit ? claveRfc : defaultParams,
      10: isNayarit ? claveRfc : defaultParams,
      12: isNayarit ? claveRfc : defaultParams,
      11: isNayarit ? claveRfc : defaultParams,
      14: isNayarit ? claveRfc : defaultParams,
      26: isNayarit ? claveRfc : defaultParams,
      27: isNayarit ? claveRfc : defaultParams,
      28: isNayarit ? claveRfc : defaultParams,
    };
    const params = paramsObj[_padron] || defaultParams;
    const match = await gettersMap[_padron]({ ...params }, true);
    if (match) {
      if (match?.alerta?.length) {
        const alertasVigentes = match.alerta.filter((a) => a.estados_globales !== 5);
        const _alertasRestrictivas = alertasVigentes.filter((a) => a.es_restrictiva);
        const _alertasInformativas = alertasVigentes.filter((a) => !a.es_restrictiva);
        setAlertasRestrictivas(_alertasRestrictivas);
        setAlertasInformativas(_alertasInformativas);
      }
      if (_padron === padronesEnum.ALCOHOL) {
        const { detail } = await getAlcoholById(match.id);
        if (detail) {
          message.warn(detail);
          setLoading(false);
          return;
        }
      }
      if (_padron === padronesEnum.VENTA_DE_ALCOHOL) {
        const { detail } = await getVentaAlcoholById(match.id);
        if (detail) {
          message.warn(detail);
          setLoading(false);
          return;
        }
      }
      await getDeclaracionesGeneradas({
        _tipoDepadron: _padron,
        periodo_fiscal,
        match,
      });
      await recievePadronFound({
        setLoading,
        padron: match,
        setVisibleModalUpdateInfo: setVisibleUpdate,
        inNeedsInfo: {
          setPadronId: setIdPadron,
          setTipoDePadron: setTipoDePadronId,
        },
        callback: async () => {
          if (tiposDeCargos.page !== 1) await fetchTiposDeCargos();
          setPadron(match);
          setTipoDePadron(_padron);
          setPeriodoFiscal(periodo_fiscal);
        },
      });
      await statusByContentTypes(tipoDePadron, padron);
    }
    setLoading(false);
  };

  useEffect(() => {
    const autoFetch = async () => {
      const _subClass = parseInt(subclassifier, 10);
      if (user && [15, 5].includes(_subClass)) {
        await onFinishSearch({
          clave: user.clave,
          q: user?.rfc || user?.RFC,
          padron: 15,
        });
      }
    };
    autoFetch();
  }, []);

  const minus = (_tipoDeCargo) => {
    const tipo_aplicacion = _tipoDeCargo.tipo_de_aplicacion === aplicacionesCargosEnum.MULTIPLO ? 'importe' : 'cantidad';
    const copy = [...selectedCargos];
    const idx = copy.findIndex((e) => e.id === _tipoDeCargo.id);
    if (_tipoDeCargo[tipo_aplicacion] > 1) {
      copy[idx][tipo_aplicacion] -= 1;
    } else {
      copy[idx][tipo_aplicacion] = 0;
      if (expandedRowKeys[0] === _tipoDeCargo.id) {
        setTimeout(() => setExpandedRowKeys([]));
      }
    }
    setSelectedCargos(copy.filter((e) => e[tipo_aplicacion]));
  };

  const onNotValue = (
    <Tooltip title="Aún no le ha asignado un valor a esta variable.
    Presiona el botón [+] que está a la izquierda para hacer visibles
    los campos y capturar su valor (En caso de que estos estén ocultos)."
    >
      <InfoCircleOutlined className="warning" />
    </Tooltip>
  );

  const variablesColumn = selectedCargos
    .filter((c) => c.tipo_de_aplicacion === aplicacionesCargosEnum.FORMULA)
    .some((c) => c.variables.length) ? {
      title: 'Variables',
      dataIndex: 'variables',
      key: 'variables',
      width: 500,
      render: (val, record) => {
        const values = val || [];
        if (!values.length) {
          return 'Este cargo no tiene variables.';
        }
        if (!selectedCargos.some((e) => 'vars' in e)) {
          return values.map((e) => (
            <Tag
              className="warning-marker"
              key={e.descripcion_de_variable}
              icon={(isDeclaration || classifierParam === '03') ? onNotValue : null}
            >
              {e.descripcion_de_variable}
            </Tag>
          ));
        }
        const current = variablesValues?.[record?.id];
        const arr = values?.map((e) => ({
          name: e?.descripcion_de_variable,
          valor: current?.[e?.nombre_de_variable] || '',
        }));
        const output = arr?.map((e) => (
          <Tag
            className={isZeroDeclaration || e.valor ? 'success-marker' : 'warning-marker'}
            key={e.name}
            icon={isZeroDeclaration || e.valor
              ? <CheckCircleOutlined className="success" />
              : onNotValue}
          >
            {e.name}
            {' '}
            {e.valor ? '=' : ''}
            {' '}
            {e.valor}
          </Tag>
        ));
        return output;
      },
    } : null;

  const setVisiblePreviewWith = (base64) => {
    setPreviewBase64(base64);
    setVisiblePreview(true);
  };

  const generarReferencia = async (_cargos, clickNetPay, infoNotarios) => {
    setLoading(true);
    let _referencia = null;
    let cargosToReference = _cargos;
    if (debtsAsked) {
      const cargoPadre = _cargos.find((c) => c.id === lastClickedCharge.current);
      const cargosHijos = _cargos.filter((c) => c.cargo_padre === cargoPadre.id);
      cargosToReference = [cargoPadre, ...cargosHijos];
      setCargosTemp(cargosToReference);
    }
    // sino tiene referencia
    if (!referencia?.folio?.length) {
      _referencia = await postGenererReferenciasNetpay(
        tipoDePadron,
        padron,
        cargosToReference,
        padrones,
        undefined,
        infoNotarios || undefined,
      );
      setReferencia(_referencia);
    }

    // si es netpay
    if (clickNetPay) {
      setReferenciaNormal(true);
      setTimeout(() => getNetPayBtn()?.click(), 1500);
    } else if (_referencia?.base64 || referencia?.base64) {
      setVisiblePreviewWith(_referencia?.base64 ?? referencia.base64);
    }

    setLoading(false);
  };

  const generarReferenciaBanco = async (_cargos, infoNotarios) => {
    setLoading(true);
    let cargosToReference = _cargos;
    if (debtsAsked) {
      const cargoPadre = _cargos.find((c) => c.id === lastClickedCharge.current);
      const cargosHijos = _cargos.filter((c) => c.cargo_padre === cargoPadre.id);
      cargosToReference = [cargoPadre, ...cargosHijos];
      setCargosTemp(cargosToReference);
    }
    const _referencia = await postGenererReferenciasBanco(
      tipoDePadron,
      padron,
      cargosToReference,
      padrones,
      undefined,
      infoNotarios || undefined,
    );

    setVisibleBancos(false);
    setVisiblePreviewWith(`data:application/pdf;base64,${_referencia?.data}`);
    setReferenciaNormal(false);
    setReferencia(_referencia);
    setIsNewFolio(false);
    setLoading(false);
    return _referencia;
  };

  const consultarDeclaracion = async (id) => {
    setLoading(true);
    const pdf = await getDeclaracionPDF(id);
    setPreviewBase64(pdf);
    setVisiblePreview(true);
    setLoading(false);
  };

  const consultarOrdenDePago = async (_cargos) => {
    setLoading(true);
    const request = await postGenererReferenciasNetpay(
      tipoDePadron,
      padron,
      _cargos,
      padrones,
    );
    setVisiblePreviewWith(request?.base64);
    setLoading(false);
  };

  const consultarRecibo = async (values) => {
    setLoading(true);
    const { base64 } = await getReciboPorOrden(values);
    setVisiblePreviewWith(base64);
    setLoading(false);
  };

  const declaracionesColumns = [
    {
      title: 'No. de Declaración',
      dataIndex: 'numero_de_la_declaracion',
      key: 'numero_de_la_declaracion',
      width: 150,
    },
    {
      title: 'Fecha de Presentación',
      dataIndex: 'fecha_de_creacion',
      key: 'fecha_de_creacion',
      width: 200,
      render: (val) => (val ? toMoment(val)?.format(DATETIME_FRONTEND_FORMAT) : 'N/A'),
    },
    {
      title: 'Concepto',
      dataIndex: 'cargo',
      key: 'cargo',
      width: 400,
      render: (val) => (typeof val === 'number' ? val : val?.descripcion),
    },
    {
      title: 'Estatus',
      dataIndex: 'estatus_de_declaracion_empresarial',
      key: 'estatus_de_declaracion_empresarial',
      width: 400,
      render: (val) => (typeof val === 'number' ? val : val?.descripcion),
    },
    {
      title: 'Documentos',
      key: 'id',
      width: 200,
      render: (values, record) => {
        const items = [
          values.estatus_de_declaracion_empresarial?.clave === '3'
          && !record.cargo.cargo_padre
          && !record.cargo.cancelado
          && !(values.estatus_de_declaracion_empresarial?.clave === '1' && record.cargo.total_real === 0)
          && {
            key: 7,
            label: 'Recibo de pago',
            onClick: () => consultarRecibo({ folio: record.folio_referencia }),
          },
          {
            key: 1,
            label: 'Declaración',
            onClick: () => {
              consultarDeclaracion(values.id);
            },
          },
          values.estatus_de_declaracion_empresarial?.clave === '1'
          && !record.cargo.cargo_padre
          && !record.cargo.cancelado
          && !(values.estatus_de_declaracion_empresarial?.clave === '1' && record.cargo.total_real === 0)
          && {
            key: 2,
            label: 'Orden de Pago',
            onClick: () => {
              const _cargos = record?.cargos_hijos || [];
              consultarOrdenDePago(
                [record.cargo, ..._cargos].map((c) => ({ ...c, adeudo_total: c.total_real })),
              );
            },
          },
        ].filter((e) => e);

        return (
          <Dropdown overlay={<Menu items={items} />} placement="bottomLeft">
            <Button onClick={(e) => e.stopPropagation()} style={{ marginLeft: 'auto' }}>
              Seleccionar
              <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const columns = [
    {
      title: 'Clave',
      dataIndex: 'clave',
      key: 'clave',
      width: 150,
      responsive: ['md'],
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
      width: 350,
    },
    {
      title: 'Importe',
      dataIndex: 'importe_real',
      key: 'importe_real',
      align: 'right',
      render: (importe_real, { cantidad = 1, importe = 1, tipo_de_aplicacion }) => (typeof importe_real === 'string' ? importe_real : toCurrency(importe_real * (
        tipo_de_aplicacion === aplicacionesCargosEnum.MULTIPLO ? importe : cantidad
      ))),
      width: 150,
      responsive: ['md'],
    },
  ];

  useEffect(() => {
    validateLastTransaction(isDeclaration, (_recibo) => {
      if (_recibo) {
        setTimeout(() => {
          setGenerandoRecibo(false);
          setReferencia(_recibo);
          setVisiblePreviewNetPay(true);
        }, 3000);
      }
    }, setLoading, paymentLocationsEnum.PAYMENTS, true, setGenerandoRecibo);
  }, []);

  const onCancelNetPay = () => {
    setVisiblePreviewNetPay(false);
    setReferencia();
    setDeclaracionPDF();
  };

  const onCancel = () => {
    setShowImporteModal(false);
    setTempCargos([]);
    setDebtsAsked(false);
    setSelectedCargos([]);
    setExpandedRowKeys([]);
    setPadron();
    setReferenciaNormal(null);
    setIsNewFolio(true);
    setCargosGenerados([]);
    setCargosGeneradosDiff([]);
    setVisiblePreview(false);
    setVisiblePreviewNetPay(false);
    setReferencia();
    setDeclaracionPDF();
    setAcceptDeclaration(false);
    // setReferenciaTemp([]);
    // setReferenciaNormalTemp([]);
    setCargosTemp([]);
    lastClickedCharge.current = 0;
    form.resetFields(['q', 'serie', 'placa']);
    formFilter.resetFields();
    formDatosGenerales.resetFields();
    scrollToTop(screens);
    if (localStorage.getItem('nameTemp')) {
      localStorage.removeItem('nameTemp');
    }
  };

  const isNormal = (cargos) => {
    const { es_cargo_unico } = cargos;
    return es_cargo_unico ? 1 : 2;
  };

  const isRegistrales = !!allowedRoutesReports.SERVICIOS_REGISTRALES.includes(_subclassifier);

  const generarCargos = async () => {
    setLoading(true);
    const invalid = selectedCargos.find((c) => (c.variables.length && !c.vars)
      || (c.tipo_de_aplicacion === aplicacionesCargosEnum.MULTIPLO && !c.cantidad));
    if (invalid) {
      message.info('Aún no ha ingresado la información necesaria', 3);
      if (!expandedRowKeys.length) {
        setExpandedRowKeys([invalid.id]);
      }
      setLoading(false);
      return;
    }
    const values = selectedCargos.map((t) => ({
      tipo_de_cargo: t.id,
      padron: tipoDePadron,
      padron_id: padron.id,
      empresa: padron.empresa?.id,
      canal_de_pago: isDeclaration || ENV_NAME === 'nayarit' ? CATALOGOS_DE_CANAL_DE_PAGO.PORTAL_FINANCIERO
        : CATALOGOS_DE_CANAL_DE_PAGO.PAGO_EN_LINEA,
      entidad: ID_ENTIDAD,
      // cargo_en_cero: isZeroDeclaration,
      cantidad: t.tipo_de_aplicacion === aplicacionesCargosEnum.MULTIPLO ? t.importe : t.cantidad,
      importe: (t.tipo_de_aplicacion === aplicacionesCargosEnum.MULTIPLO
        ? t.cantidad : t.importe) || undefined,
      variables: t.variables?.map((variable) => {
        let valor = t.vars[variable.nombre_de_variable];
        if (variable.tipo_de_variable.id === tiposDatosEnum.RANGO_DE_FECHAS) {
          const currentDate = Moment();
          const fechaInicial = !isZeroDeclaration ? valor : currentDate;
          valor = 1; // SE ENVIA UNO PORQUE EL RANGO DE FECHAS ES DE UN DIA SOLAMENTE
          declaracionAnterior.current = Moment(fechaInicial).format('YYYY-MM-DD HH:mm:ss');
        } else if (variable.tipo_de_variable.id === tiposDatosEnum.TEXTO_LARGO) {
          valor = valor.toUpperCase();
        } else {
          valor = parseFloat(valor ?? 0);
        }
        return {
          variable_id: variable.id,
          nombre_de_variable: variable.nombre_de_variable,
          valor,
        };
      }),
    }));

    const inZero = (isDeclaration && values[0].variables.every((e) => !e.valor))
      && selectedCargos[0].tipo_de_aplicacion !== aplicacionesCargosEnum.SQL_FUNCTION;
    const decValues = {
      ejercicio_anual: selectedCargos[0].periodo_fiscal.periodo,
      periodo_mensual: selectedCargos[0].identificador_periodo || 1,
      tipo_de_cargo: selectedCargos[0].id,
      identificador_del_periodo: selectedCargos[0]?.identificador_periodo || 1,
      periodo: selectedCargos[0]?.periodo?.id || 1,
      empresa: padron.empresa?.id,
      entidad: ID_ENTIDAD,
      object_id_padron: values[0].padron_id,
      contribuyente: padron.empresa?.contribuyente?.id,
      content_type_padron: contentTypesPadrones.find((e) => e.nivel === values[0].padron)?.id,
      numero_de_empleados: inZero ? 0 : (values[0].variables
        .find((e) => e.variable_id === 20)?.valor || 0),
      importe_base_no_exento: inZero ? 0 : (values[0]
        .variables.find((e) => e.variable_id === 23)?.valor || 0),
      variables: inZero
        ? []
        : values[0].variables.filter(({ variable_id }) => variable_id !== 27)
          .map(({ variable_id, valor }) => ({ variable_id, valor })),
      tipo_de_declaracion_empresarial: isNormal(selectedCargos[0]),
      estatus_de_declaracion_empresarial: inZero
        ? statusDeclaracion[1]?.id : statusDeclaracion[0]?.id,
      importe_exento: inZero
        ? 0 : (values[0].variables.find((e) => e.variable_id === 22)?.valor || 0),
      usuario_creo: USER_PORTAL_ID[ENV_NAME],
      clase_de_declaracion_empresarial: claseDeDeclaracion || null,
      fecha_ultimo_estado: Moment().format('YYYY-MM-DD HH:mm:ss'),
      numero_de_habitaciones: inZero
        ? 0 : (values[0].variables.find((e) => e.variable_id === 21)?.valor || 0),
    };
    const tipoDeclaracion = selectedCargos[0]?.es_cargo_unico ? 1 : 2;
    if (tipoDeclaracion === 2) {
      decValues.fecha_de_declaracion_anterior = declaracionAnterior.current || null;
    }
    const response = await postGenererCargos(values, isDeclaration, inZero, decValues);
    if (response.success.length && !response.failed.length) {
      const [_cargos] = await getAdeudoPadronAsync(tipoDePadron, padron.id, null, true) || [[]];
      const created = response.success.map((c) => c.id);
      const _cargosGeneradosFormDebts = _cargos.filter((d) => created.includes(d.id)
        || (d.es_accesorio && created.includes(d.cargo_padre)));
      setCargosGenerados(_cargosGeneradosFormDebts);
    } else if (!inZero && response.failed.length) {
      Modal.warn({
        width: '35vw',
        style: { minWidth: 300 },
        title: <Text strong $accent>Advertencia</Text>,
        content: (
          <>
            <Text strong>Los siguientes cargos no han podido ser generados</Text>
            <br />
            <br />
            {response.failed.map((e) => (
              <React.Fragment key={e.tipo_de_cargo}>
                <b style={{ fontSize: '18px', fontWeight: 'bolder' }}>
                  {selectedCargos.find((s) => s.id === e.tipo_de_cargo)?.descripcion}
                </b>
                {': '}
                <br />
                <span style={{ fontSize: '18px', fontWeight: 'bolder' }}>
                  <span>
                    -
                    {' '}
                    { (response.failed[0]?.error || 'FAVOR DE REVISAR LA INFORMACIÓN INGRESADA.')}
                  </span>
                </span>
              </React.Fragment>
            ))}
            <br />
            {!!response.successDiff.length && (
              <Text strong>
                Los siguientes cargos fueron generado
              </Text>
            )}
            <br />
            {response.successDiff.filter((e) => !e.cargo_padre).map((e) => (
              <React.Fragment key={e.tipo_de_cargo}>
                {selectedCargos.find((s) => s.id === e.tipo_de_cargo)?.descripcion}
              </React.Fragment>
            ))}
            <br />
            <br />
            {!!response.successDiff.length && (
              <Text>
                Aún puede generar la orden de pago o continuar con el pago de los cargos generados
              </Text>
            )}
            <Row justify="end" style={{ marginBottom: 20 }}>
              <Button
                style={{ marginLeft: screens.md ? 10 : 0 }}
                onClick={() => Modal.destroyAll()}
                $accent
              >
                Entendido
              </Button>
            </Row>
          </>
        ),
        okButtonProps: { style: { display: 'none', heigth: 0 } },
        bodyStyle: { paddingBottom: 0 },
      });
    }
    if (response.declaracion) {
      if (inZero) {
        response.success = [{ ...selectedCargos[0], importe: 0 }];
      }
    }
    if (response.success.length) {
      const [_cargos] = await getAdeudoPadronAsync(tipoDePadron, padron.id, null, true);
      const created = response.success.map((c) => c.id);
      const _cargosGeneradosFormDebts = _cargos.filter((d) => created.includes(d.id)
        || (d.es_accesorio && created.includes(d.cargo_padre)));
      if (_cargosGeneradosFormDebts) {
        if (isDeclaration) {
          if (response.declaracion) {
            setDeclaracionId(response.declaracion?.id);
            response.declaracion.base64 = await getDeclaracionPDF(response.declaracion.id);
            await getDeclaracionesGeneradas({
              _tipoDepadron: tipoDePadron,
              periodo_fiscal: periodoFiscal,
              match: padron,
            });
            if (inZero) {
              setSelectedCargos([]);
              setVisiblePreviewWith(response.declaracion.base64);
            }
            setAcceptDeclaration(true);
          }
          setCargosGeneradosDiff(_cargosGeneradosFormDebts);
        } else {
          setCargosGeneradosDiff(response.successDiff);
        }
        setDeclaracionPDF(response.declaracion?.base64);
      }
    }
    setLoading(false);
  };

  const selectedChargesColumns = [
    classifierParam !== '01' && {
      title: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
      width: 200,
      render: (val, record) => (
        <Row align="middle">
          {[aplicacionesCargosEnum.FIJO, aplicacionesCargosEnum.MULTIPLO]
            .includes(record.tipo_de_aplicacion) && (
              <Tooltip placement="top" title="Disminuir cantidad">
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    minus(record);
                  }}
                  type="link"
                >
                  <MinusOutlined />
                </Button>
              </Tooltip>
          )}
          <Text
            editable={aplicacionesCargosEnum.MULTIPLO === record.tipo_de_aplicacion && {
              tooltip: `Click para ingresar ${record.parametro_descripcion
                || 'cantidad'} manualmente`,
              enterIcon: <CheckOutlined />,
              maxLength: 2,
              autoSize: false,
              onChange: (_val) => {
                const parsedVal = parseInt(_val, 10);
                if (!parsedVal || parsedVal > 50 || parsedVal < 1) {
                  message.info('Ingrese un valor entero no mayor a 50', 3);
                } else {
                  const copy = [...selectedCargos];
                  const idx = copy.findIndex((e) => e.id === record.id);
                  copy[idx][record.tipo_de_aplicacion === aplicacionesCargosEnum.MULTIPLO
                    ? 'importe' : 'cantidad'] = parsedVal;
                  setSelectedCargos(copy);
                }
              },
            }}
            style={{ margin: 'auto', width: 'calc(100% - 100px)', textAlign: 'center' }}
          >
            {
              record[
                record.tipo_de_aplicacion === aplicacionesCargosEnum.MULTIPLO ? 'importe' : 'cantidad'
              ]
            }
          </Text>
          {[aplicacionesCargosEnum.FIJO, aplicacionesCargosEnum.MULTIPLO]
            .includes(record.tipo_de_aplicacion) && (
              <Tooltip placement="top" title="Aumentar cantidad">
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    add(record);
                  }}
                  type="link"
                  tooltip="Agregar"
                  disabled={![aplicacionesCargosEnum.FIJO, aplicacionesCargosEnum.MULTIPLO]
                    .includes(record.tipo_de_aplicacion)}
                >
                  <PlusOutlined />
                </Button>
              </Tooltip>
          )}
        </Row>
      ),
    },
    variablesColumn,
    ...columns,
    classifierParam !== '03' && {
      title: 'Acciones',
      dataIndex: '_',
      key: '_',
      width: 150,
      render: (_, record) => (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            minus(record);
          }}
          type="link"
          icon={<DeleteOutlined />}
        >
          Eliminar
        </Button>
      ),
    },
  ].filter((c) => c);

  const generatedChargesColumns = [
    // debe ser el impuesto a cargo (cargos.importe)
    columns[1],
    isDeclaration && debtsColumns[5],
    isDeclaration && debtsColumns[6],
    {
      title: 'Subtotal',
      dataIndex: 'adeudo_total',
      key: 'adeudo_total',
      render: (val) => <b style={{ color: COLORS.accent }}>{toCurrency(val)}</b>,
      width: 150,
      align: 'right',
    },
    debtsAsked && {
      title: 'Formas de pago',
      dataIndex: '_',
      key: '_',
      width: 150,
      render: (_, record) => {
        const onClickPay = async (fromNetpay = false) => {
          lastClickedCharge.current = record.id;
          setReferencia(null);
          generarReferencia(cargosGenerados, fromNetpay);
        };

        const items = [
          {
            key: 1,
            label: 'Orden de pago',
            icon: <BarcodeOutlined />,
            onClick: () => onClickPay(),
          },
          {
            key: 2,
            label: 'Pago en línea',
            icon: <CreditCardOutlined />,
            onClick: () => onClickPay(true),
          },
          {
            key: 3,
            label: 'Transferencia bancaria',
            icon: <CreditCardOutlined />,
            onClick: () => {
              lastClickedCharge.current = record.id;
              const cargoPadre = cargosGenerados.find((c) => c.id === record.id);
              const cargosHijos = cargosGenerados.filter((c) => c.cargo_padre === cargoPadre.id);
              setCargosTemp([cargoPadre, ...cargosHijos]);
              setVisibleBancos(true);
            },
          },
        ];

        return !record.es_accesorio && (
          <Dropdown
            overlay={<Menu items={items} />}
            placement="bottomLeft"
          >
            <Button
              onClick={(e) => e.stopPropagation()}
              style={{ marginLeft: 'auto' }}
              type="link"
            >
              Seleccionar
              <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ].filter((c) => c);

  const formatsMapByPeriod = {
    [periodosMap.DIARIO]: 'D',
    [periodosMap.SEMANAL]: 'w',
    [periodosMap.MENSUAL]: 'M',
    [periodosMap.CUATRIMESTRAL]: 'Q',
    /*  [periodosMap.ANUAL]: 1, Preguntar situación con este periodo */
  };

  const chargeTypesColumns = [
    ...columns,
    {
      title: 'Acciones',
      dataIndex: '_',
      key: '_',
      width: 160,
      render: (_, record) => {
        const added = selectedCargos.some((e) => e.id === record.id);
        const format = formatsMapByPeriod[record.periodo?.id];
        let currentMonth = isDeclaration && format && parseInt(Moment()
          .format(format), 10);
        const currentYear = parseInt(toMoment(new Date(), DATE_FRONTEND_FORMAT).format('YYYY'), 10);
        const currentPeriodoFiscal = periodosFiscales.find((p) => p.periodo === currentYear).id;
        if (!format && record.periodo) {
          if (record.periodo.id === periodosMap.QUINCENAL) {
            const day = parseInt(Moment().format('D'), 10);
            const month = parseInt(Moment().format('M'), 10) - 1;
            currentMonth = month * 2 + (day > 15 ? 2 : 1);
          }
          if (record.periodo.id === periodosMap.BIMESTRAL) {
            const month = parseInt(Moment().format('M'), 10);
            currentMonth = (month % 2 === 0 ? month : month + 1) / 2;
          }
          if (record.periodo.id === periodosMap.TRIMESTRAL) {
            const month = parseInt(Moment().format('M'), 10);
            const residual = month % 3;
            const number = !residual ? 0 : 3 - residual;
            currentMonth = ((month + number) / 3);
          }
          if (record.periodo.id === periodosMap.SEMESTRAL) {
            const month = parseInt(Moment().format('M'), 10);
            const residual = month % 6;
            const number = !residual ? 0 : 6 - residual;
            currentMonth = ((month + number) / 6);
          }
        }
        const disabledByLimit = isDeclaration
        && (record.periodo_fiscal.id === currentPeriodoFiscal
          && record.periodo.id !== periodosMap.EVENTUAL
          && record.identificador_periodo >= currentMonth);

        return (
          <Button
            onClick={() => add(record)}
            disabled={added
              || (isDeclaration && (selectedCargos.length || disabledByLimit))
              || cargosGenerados.length}
            type="link"
          >
            {added ? <CheckOutlined /> : <PlusOutlined />}
            <Text>{added ? 'Agregado' : 'Agregar'}</Text>
          </Button>
        );
      },
    },
  ];

  if (!loading && !currentClassifier) {
    return <Navigate to="/not-found" />;
  }

  const conceptosDeIngresoByPadron = filtersExtemporaneos[ENV_NAME] || {};

  const onFinishForm = (values) => {
    const paramsMap = {
      es_cargo_unico_true: { es_cargo_unico: true },
      es_cargo_unico_false: { es_cargo_unico: false },
      extemporaneo: {
        concepto_de_ingreso__clave: conceptosDeIngresoByPadron[tipoDePadron],
        extemporaneo: true,
      },
    };
    fetchTiposDeCargos(1, values.q, paramsMap[values.filter]);
  };

  const formatValuesFunc = (values) => {
    const clone = { ...values };
    const keys = Object.keys(clone);
    keys.forEach((key) => {
      if (Array.isArray(clone[key])) {
        const dates = clone[key].map((e) => e?.format());
        clone[key] = (
          <span>
            {dates[0]}
            {' '}
            <SwapRightOutlined className="success" />
            {' '}
            {dates[1]}
          </span>
        );
      }
    });
    return clone;
  };

  const onFinishVariablesForm = (values, record) => {
    const formatedValues = formatValues(values, { clean: false });
    const keys = Object.keys(formatedValues);
    if (keys.every((k) => formatedValues[k])
    || (classifierParam === '01' && keys.every((k) => !formatedValues[k]))) {
      const idx = selectedCargos.findIndex((e) => e.id === record.id);
      const copy = [...selectedCargos];
      if (record.variables.length) {
        copy[idx].vars = formatedValues;
        setVariablesValues((prev = {}) => ({
          ...prev,
          [record.id]: formatValuesFunc({ ...formatedValues, cargoId: record.id }),
        }));
      } else if (record.parametro_descripcion) {
        copy[idx].importe = values.importe;
        copy[idx].cantidad = values.importe;
      }
      setSelectedCargos(copy);
      setExpandedRowKeys([]);
    } else {
      message.warn('Debe ingresar todas o ninguna de las variables', 3);
    }
  };

  const expandIcon = ({ expanded, onExpand, record }) => (expanded
    ? (
      <CompressOutlined onClick={(e) => {
        e.stopPropagation();
        onExpand(record, e);
      }}
      />
    )
    : (
      <ExpandOutlined onClick={(e) => {
        e.stopPropagation();
        onExpand(record, e);
      }}
      />
    ));

  const cargosGeneradosExist = !!cargosGenerados.length;

  // BOTONES DE ACCIONES
  // NET PAY
  const visibleNetPayButton = cargosGeneradosExist && !isGeneratedZeroDeclaration;

  // FORMATO DE DECLARACION
  const visibleDeclaracionButton = cargosGeneradosExist && isDeclaration;

  // TRANSFERENCIA BANCARIA
  const visibleTransferenciaButton = cargosGeneradosExist && !isGeneratedZeroDeclaration;

  const handleGetAllDebts = async () => {
    if (selectedCargos.length) {
      message.warn('No se puede consultar adeudos anteriores con cargos seleccionados', 3);
      return;
    }
    setLoading(true);
    const [_cargos] = await getAdeudoPadronAsync(
      tipoDePadron,
      padron.id,
      null,
      true,
    );
    const cargosSinConvenios = _cargos.filter((c) => !c.convenios);
    if (cargosSinConvenios.length) {
      const formattedCargos = debtsFormatter(cargosSinConvenios);
      setCargosGenerados(cargosSinConvenios);
      setCargosGeneradosDiff(formattedCargos);
      setDebtsAsked(true);
      setTimeout(() => {
        getNetPayBtn().style.display = 'none';
      });
    } else {
      message.info('No se encontraron adeudos anteriores', 3);
    }
    setLoading(false);
  };

  const handleAcceptDeclaration = async (isAccept = false) => {
    // canal de pago
    const canal_de_pago = isDeclaration || ENV_NAME === 'nayarit' ? CATALOGOS_DE_CANAL_DE_PAGO.PORTAL_FINANCIERO
      : CATALOGOS_DE_CANAL_DE_PAGO.PAGO_EN_LINEA;

    const values = selectedCargos.map(() => ({ canal_de_pago }));

    setLoading(true);
    if (isAccept) {
      setAcceptDeclaration(false);
    } else {
      const response = await postRechazarDeclaracion({
        declaracion: declaracionId,
        canal_de_pago: values[0].canal_de_pago,
      });
      if (response) {
        message.success('Cargos eliminados correctamente', 3);
        setAcceptDeclaration(false);
        setSelectedCargos([]);
        setCargosGenerados([]);
        setCargosGeneradosDiff([]);
        setDeclaracionId();
        setDeclaracionPDF();
      }
    }
    setLoading(false);
  };
  // Construye el array de opciones
  const optionsArray = [
    {
      label: 'Normal',
      value: 'es_cargo_unico_true',
    },
    {
      label: 'Complementaria',
      value: 'es_cargo_unico_false',
    },
  ];

  const _rules = {
    required: (_message) => ({
      required: true,
      _message,
    }),
    rfc: [{ pattern: RFC_PATTERN, message: ' Ingrese un RFC válido.' }],
    curp: [{ pattern: CURP_PATTERN, message: ' Ingrese una CURP válida.' }],
    alphaNumeric: [{ pattern: ALPHA_NUMERIC_PATTERN, message: 'Ingrese un valor alfanumérico.' }],
  };

  const formPropietario = (
    <Card
      title={(
        <Row justify="space-between" gutter={[10, 10]}>
          <Col xs={24} sm={24} md={12}>
            <Title $accent level={3}>
              Datos generales del ciudadano
            </Title>
          </Col>
        </Row>
      )}
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
      }}
    >
      <Form
        layout="vertical"
        style={{ marginTop: 20 }}
        form={formDatosGenerales}
        initialValues={{
          nombre_completo_razon_social: '',
          numero_de_instrumentro_escritura: '',
        }}
      >
        <Row gutter={[10, 5]}>
          <Col sm={24} md={8}>
            <Form.Item
              label="Nombre completo"
              name="nombre_completo_razon_social"
              rules={[_rules.required('Ingrese el nombre del notario')]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col sm={24} md={8}>
            <Form.Item
              label="Número de instrumento"
              name="numero_de_instrumentro_escritura"
              rules={[_rules.required('Ingrese el Número de instrumento'), ..._rules.alphaNumeric]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col sm={24} md={8}>
            <Form.Item label="RFC" name="rfc" rules={[..._rules.rfc]}>
              <Input />
            </Form.Item>
          </Col>
          <Col sm={24} md={8}>
            <Form.Item label="CURP" name="curp" rules={[..._rules.curp]}>
              <Input />
            </Form.Item>
          </Col>
          <Col sm={24} md={8}>
            <Form.Item label="Municipio" name="municipio">
              <Input />
            </Form.Item>
          </Col>
          <Col sm={24} md={8}>
            <Form.Item label="Localidad" name="localidad">
              <Input />
            </Form.Item>
          </Col>
          <Col sm={24} md={8}>
            <Form.Item label="Colonia" name="colonia">
              <Input />
            </Form.Item>
          </Col>
          <Col sm={24} md={8}>
            <Form.Item label="Calle" name="calle">
              <Input />
            </Form.Item>
          </Col>
          <Col sm={24} md={8}>
            <Form.Item label="Número" name="numero_y_letra_de_direccion">
              <Input />
            </Form.Item>
          </Col>
          <Col sm={24} md={8}>
            <Form.Item label="Teléfono" name="telefono">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );

  const notarioInfo = (_notario, type = 'notario') => {
    const _nombre_notario = padron?.empresa?.contribuyente?.nombre_completo
    || padron?.empresa?.contribuyente?.razon_social || '';

    if (type === 'notario') {
      return {
        ..._notario,
        numero_de_instrumentro_escritura: _notario.numero_de_instrumentro_escritura,
        nombre_completo_del_notario: _nombre_notario, // CAMBIAR
        nombre_completo_razon_social: _notario.nombre_completo_razon_social,
        rfc: _notario.rfc,
        curp: _notario.curp,
        entidad_federativa: _notario.entidad_federativa,
        municipio: _notario.municipio,
        localidad: _notario.localidad,
        colonia: _notario.colonia,
        calle: _notario.calle,
        numero_y_letra_de_direccion: _notario.numero_y_letra_de_direccion,
        telefono: _notario.telefono,
        observaciones_complemento: _notario.observaciones_complemento,
      };
    }

    return {
      numero_de_instrumentro_escritura: _notario.numero_de_instrumentro_escritura || '',
      nombre_completo_del_notario: _nombre_notario, // CAMBIAR
      nombre_completo_razon_social: _notario.nombre_completo_razon_social,
      // rfc: _padron?.contribuyente?.rfc || '',
      rfc: _notario?.rfc || '',
      curp: _notario.curp || '',
      entidad_federativa: 18,
      municipio: _notario.municipio || '',
      localidad: _notario.localidad || '',
      colonia: _notario.colonia || '',
      calle: _notario.calle || '',
      numero_y_letra_de_direccion: _notario.numero_y_letra_de_direccion || '',
      telefono: _notario.telefono || '',
      observaciones_complemento: _notario.observaciones_complemento || '',
    };
  };

  const registralesReferenceGenerate = async ({ type }) => {
    if (cargosGenerados.length) {
      formDatosGenerales.validateFields().then(() => {
        const values = formDatosGenerales.getFieldsValue();

        const infoNotarios = {
          tipo_de_complemento_referencial_id: 1,
          ...notarioInfo(values),
        };

        if (type === 'orderPay') {
          generarReferencia(cargosGenerados, false, infoNotarios);
        } else if (type === 'netpay') {
          generarReferencia(cargosGenerados, true, infoNotarios);
        } else if (type === 'bankTransfer') {
          generarReferenciaBanco(cargosGenerados, infoNotarios);
        }
      });
    } else {
      formDatosGenerales.validateFields().then(() => {
        generarCargos();
      }).catch(() => {
        message.warn('Favor de llenar todos los campos', 3);
      });
    }
  };

  return (
    <>
      <Col span={24}>
        <Skeleton
          title={{ width: '100%' }}
          paragraph={{ rows: 0 }}
          active
          loading={!currentClassifier && loading}
        >
          <Title style={{ textAlign: 'center', margin: 0 }}>
            {currentClassifier?.nombre || currentClassifier?.descripcion}
          </Title>
        </Skeleton>
      </Col>
      <SearchForm
        padron={padron}
        onCancel={onCancel}
        loading={loading}
        form={form}
        classifierParam={classifierParam}
        padrones={padrones}
        currentClassifier={currentClassifier}
        onFinishSearch={onFinishSearch}
        tipoDePadron={tipoDePadron}
        setTipoDePadron={setTipoDePadron}
        periodosFiscales={periodosFiscales}
        visibleAlert={visibleAlert}
        setLoading={setLoading}
      />
      { padron && tipoDePadron === 12 && (formPropietario)}
      {padron && (
        <>
          {!cargosGenerados.length && !tipoDeCargo && (
            <Card
              title={(
                <Row justify="space-between" gutter={[10, 10]}>
                  <Col xs={24} sm={24} md={12}>
                    <Title level={3} $accent>
                      Conceptos de pago
                    </Title>
                  </Col>
                  <Col xs={24} sm={24} md={12} xl={8}>
                    {(classifierParam === '04' && !debtsAsked) && (
                    <Button
                      $accent
                      block
                      loading={loading}
                      onClick={handleGetAllDebts}
                    >
                      Consultar adeudos anteriores
                      <HistoryOutlined />
                    </Button>
                    )}
                  </Col>
                </Row>
              )}
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%',
              }}
              bordered
            >
              <Form
                layout="vertical"
                form={formFilter}
                onFinish={onFinishForm}
                style={{ width: '100%' }}
                initialValues={{ filter: 'es_cargo_unico_true' }}
              >
                <Row gutter={10} justify="end">
                  {classifierParam === '01' && (
                    <Col span={24} style={{ marginBottom: 10 }}>
                      <Text italic>
                        En esta sección podrá realizar su declaración mensual normal y
                        complementaria, así como la retención del impuesto
                      </Text>
                    </Col>
                  )}
                  <Col span={24}>
                    <Form.Item
                      label="Búsqueda por descripción"
                      name="q"
                      hasFeedback
                    >
                      <Input disabled={cargosGenerados.length} />
                    </Form.Item>
                  </Col>
                  {!allowedRoutesReports.NOTARIO.includes(_subclassifier) && ['01', '04'].includes(classifierParam) && (
                    <Col span={24} style={{ height: screens.md ? 'auto' : 65 }}>
                      <Form.Item
                        name="filter"
                        hasFeedback
                      >
                        <Radio.Group
                          disabled={cargosGenerados.length}
                          onChange={({ target: { value } }) => onFinishForm({ filter: value })}
                          style={{ overflow: 'hidden' }}
                          options={optionsArray}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  <Col style={{
                    height: screens.md ? 'auto' : 100,
                    float: 'right',
                    width: screens.md ? 'auto' : '100%',
                  }}
                  >
                    <Form.Item>
                      <Button
                        block={!screens.md}
                        style={{ marginRight: screens.md ? 10 : 0 }}
                        loading={loading}
                        onClick={() => {
                          formFilter.resetFields();
                          fetchTiposDeCargos(1, null);
                        }}
                        disabled={cargosGenerados.length}
                      >
                        Borrar
                        <DeleteOutlined />
                      </Button>
                      <Button
                        block={!screens.md}
                        style={{ marginTop: screens.md ? 0 : 10 }}
                        htmlType="submit"
                        loading={loading}
                        $accent
                        disabled={cargosGenerados.length}
                      >
                        Buscar
                        <SearchOutlined />
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                <WaitingAlert visible={visibleAlert} />
              </Form>
              <Table
                columns={chargeTypesColumns}
                dataSource={tiposDeCargos.results
                  .sort((a, b) => a.identificador_periodo - b.identificador_periodo)}
                rowKey="id"
                pagination={{
                  total: tiposDeCargos.count,
                  onChange: (page) => fetchTiposDeCargos(page),
                  showSizeChanger: false,
                  disabled: (isDeclaration && selectedCargos.length) || cargosGenerados.length,
                }}
                loading={loading}
                size="small"
                bordered
                scroll={{
                  x: chargeTypesColumns.reduce((acum, curr) => acum + curr.width, 0),
                }}
              />
            </Card>
          )}
          <Card
            title={(
              <>
                <Row justify="space-between">
                  <Title level={3} $accent ref={refTitleCargosTable}>
                    {isDeclaration && `Declaración a ${cargosGenerados[0]?.importe
                      ? 'pagar' : 'generar'}`}
                    {!isDeclaration && `Cargos a ${cargosGenerados.length ? 'pagar' : 'generar'}`}
                  </Title>
                  {!!cargosGenerados.length && (
                  <Title level={3} $accent style={{ margin: '0 0 0 auto !important' }}>
                    {toCurrency(total)}
                  </Title>
                  )}
                </Row>
                {(!!cargosGenerados.length && SHOW_REDONDEO) && (
                  <Row justify="end">
                    <Title level={5} strong>
                      {`* Los centavos del monto total están
                      siendo redondeados (${toCurrency(rounded)}).`}
                    </Title>
                  </Row>
                )}
              </>
            )}
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '100%',
            }}
            bordered
          >
            <Table
              className="cargos-table"
              // eslint-disable-next-line max-len
              expandable={(!cargosGenerados.length && selectedCargos.some((c) => c.variables.length) && {
                // eslint-disable-next-line react/no-unstable-nested-components
                expandedRowRender: (record) => (
                  !cargosGenerados.length && (
                    <Form
                      layout="vertical"
                      name={`variables-form-${record.id}`}
                      onFinish={(values) => {
                        let _values = values;
                        const keys = Object.keys(values);
                        if (keys.every((k) => values[k])) {
                          const entries = keys.map((e) => [e, values[e] ?? 0]);
                          _values = Object.fromEntries(entries);
                        }
                        onFinishVariablesForm(_values, record);
                      }}
                      initialValues={{ importe: 1 }}
                      onValuesChange={(_, allValues) => {
                        if (!Object.keys(allValues).some((k) => allValues[k])) {
                          const _form = document.getElementById(`variables-form-${record.id}`);
                          _form?.reset();
                          setIsZeroDeclaration(true);
                        } else {
                          setIsZeroDeclaration(false);
                        }
                      }}
                    >
                      <Row gutter={[10, 10]} style={{ width: '70%' }}>
                        {record.variables.map((variable) => (
                          <Col
                            xs={24}
                            sm={24}
                            md={12}
                            key={variable.nombre_de_variable}
                          >
                            <Form.Item
                              label={variable.descripcion_de_variable}
                              name={variable.nombre_de_variable}
                              rules={[
                                { required: !isZeroDeclaration || classifierParam !== '01' },
                                ![tiposDatosEnum.RANGO_DE_FECHAS, 11]
                                  .includes(variable.tipo_de_variable.id) && {
                                  pattern: variable.tipo_de_variable.id === tiposDatosEnum.ENTERO
                                    ? INTEGER_PATTERN : DECIMAL_PATTERN,
                                  message: `Ingrese un ${variable.tipo_de_variable.id === tiposDatosEnum.ENTERO
                                    ? 'entero' : 'decimal válido'}`,
                                },
                              ].filter((r) => r)}
                              normalize={(val) => {
                                if (
                                  variable.tipo_de_variable.id === tiposDatosEnum.TEXTO_LARGO
                                  && (variable.nombre_de_variable === 'RFC' || variable.nombre_de_variable === 'CURP')
                                  && ENV_NAME === 'nayarit'
                                ) {
                                  const Patterns = { RFC: RFC_PATTERN, CURP: CURP_PATTERN };
                                  if (!val.match(Patterns[variable.nombre_de_variable])) {
                                    if (val.length > 12) {
                                      message.warn(`${variable.nombre_de_variable} inválido.`);
                                    } else {
                                      message.destroy();
                                    }
                                    return val.toUpperCase();
                                  }
                                  message.success(`${variable.nombre_de_variable} válido.`, 3);
                                  return val.toUpperCase();
                                } if (variable.tipo_de_variable.id === tiposDatosEnum.TEXTO_LARGO) {
                                  return val.toUpperCase();
                                }
                                if (variable.tipo_de_variable.id === tiposDatosEnum.ENTERO) {
                                  return toInteger(val);
                                }
                                if (variable.tipo_de_variable.id === tiposDatosEnum.DECIMAL) {
                                  return decimalNormalizer(val);
                                }
                                return val;
                              }}
                            >
                              {[tiposDatosEnum.RANGO_DE_FECHAS]
                                .includes(variable.tipo_de_variable.id) ? (
                                  <DatePicker format={DATE_FORMAT} />
                                ) : (<Input />)}
                            </Form.Item>
                          </Col>
                        ))}
                        <Col span={24}>
                          <Button htmlType="submit">
                            <Text>
                              {isZeroDeclaration ? 'Declaración en ceros' : 'Guardar'}
                            </Text>
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  )
                ),
                rowExpandable: (record) => record.variables?.length,
                expandedRowKeys,
                expandRowByClick: classifierParam !== '01',
                onExpand: (expanded, record) => {
                  if (expanded && !expandedRowKeys.length) {
                    setExpandedRowKeys([record.id]);
                    const idx = selectedCargos.findIndex((e) => e.id === record.id);
                    const copy = [...selectedCargos];
                    record.variables.map((variable) => variable.nombre_de_variable);
                    copy[idx].vars = null;
                    setSelectedCargos(copy);
                  } else if (!expanded && classifierParam !== '01') {
                    setExpandedRowKeys([]);
                  }
                },
              })}
              columns={cargosGenerados.length ? generatedChargesColumns : selectedChargesColumns}
              scroll={{
                x: (cargosGenerados.length ? generatedChargesColumns : selectedChargesColumns)
                  .reduce((acum, curr) => acum + curr.width, 0),
              }}
              dataSource={cargosGenerados.length ? cargosGeneradosDiff : selectedCargos}
              rowKey="id"
              loading={loading}
              size="small"
              bordered
            />
            <br />
            <Row gutter={[10, 10]} justify="end">
              {visibleNetPayButton && (
                <NetPayButton
                  referencia={referencia?.folio}
                  loading={loading}
                  cargos={debtsAsked ? cargosTemp : cargosGenerados}
                  padron={padron}
                  createdBy="PAYMENTS"
                  totalProp="adeudo_total"
                  productCount={debtsAsked ? cargosTemp.length : cargosGeneradosDiff.length}
                  excludeValuesContactPublicoGeneral={false}
                  generarReferencia={() => (isRegistrales === true
                    ? registralesReferenceGenerate({ type: 'netpay' })
                    : generarReferencia(cargosGenerados, true))}
                />
              )}
              <Col xs={24} sm={24} md={8}>
                <Button
                  // eslint-disable-next-line consistent-return
                  onClick={() => {
                    if (isRegistrales) {
                      registralesReferenceGenerate({ type: 'orderPay' });
                    }
                    if ((cargosGenerados.length && padron.id !== 3 && !isRegistrales)) {
                      generarReferencia(cargosGenerados, false);
                    } else if (!cargosGenerados.length && !isRegistrales) {
                      generarCargos();
                    }
                  }}
                  loading={loading}
                  disabled={!debtsAsked
                    ? ((referencia && referenciaNormal) || !selectedCargos.length) : false}
                  block
                  $accent
                >
                  {(cargosGenerados.length)
                    ? 'Generar orden de pago'
                    : `Generar ${isDeclaration ? 'declaración' : 'cargos'}`}
                  {(cargosGenerados.length)
                    ? <BarcodeOutlined /> : <SyncOutlined />}
                </Button>
              </Col>
              {visibleTransferenciaButton && (
                <Col xs={24} sm={24} md={8}>
                  <Button
                    onClick={() => {
                      setTimeout(() => { setVisibleBancos(true); }, 200);
                    }}
                    loading={loading}
                    // disabled={referencia && !referenciaNormal}
                    block
                    $accent
                  >
                    Transferencia bancaria
                    <CreditCardOutlined />
                  </Button>
                </Col>
              )}
              {visibleDeclaracionButton && (
                <Col xs={24} sm={24} md={8}>
                  <Button
                    onClick={() => {
                      setVisiblePreviewWith(declaracionPDF);
                    }}
                    loading={loading}
                    block
                  >
                    Ver declaración
                    <EyeOutlined />
                  </Button>
                </Col>
              )}
            </Row>
            {!!cargosGenerados.length && !isDeclaration && !classifier.startsWith('04') && (
              <span style={{
                marginTop: 35,
                display: 'block',
                textAlign: 'right',
                fontSize: 22,
                color: COLORS.accent,
                fontWeight: 'bold',
              }}
              >
                * El importe incluye cargos adicionales.
              </span>
            )}
            <br />
          </Card>
          {isDeclaration && (
            <Card
              title={(
                <Title level={3} $accent>
                  Declaraciones Generadas
                </Title>
              )}
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%',
              }}
              bordered
            >
              <Col span={24}>
                <Table
                  size="small"
                  bordered
                  columns={declaracionesColumns}
                  dataSource={declaraciones}
                  rowKey="id"
                  loading={loading}
                  expandable={{
                    expandedRowRender: (record) => (record?.complementos_de_declaracion.length
                      ? record?.complementos_de_declaracion
                        ?.map((complemento) => (
                          <Row gutter={[10, 10]} key={complemento.id}>
                            <strong>
                              {complemento?.variable?.descripcion_de_variable
                            || 'variable no encontrada'}
                            </strong>
                            :
                            <Col>
                              {complemento?.valor}
                            </Col>
                          </Row>
                        )) : 'Esta declaración se hizo en ceros.'),
                    rowExpandable: () => true,
                    defaultExpandAllRows: true,
                    expandIcon,
                  }}
                />
              </Col>
            </Card>
          )}
        </>
      )}
      {ENV_NAME === ENV_NAMES_ENUM.NAYARIT && (
      <ModalBancos
        visible={visibleBancos}
        onCancel={() => { setVisibleBancos(false); }}
        cargosGenerados={debtsAsked ? cargosTemp : cargosGenerados}
        padron={padron}
        padrones={padrones}
        tipoDePadron={tipoDePadron}
        currentClassifier={currentClassifier}
        // cbReferenciaBanco={() => generarReferenciaBanco(cargosGenerados)}
        cbReferenciaBanco={() => registralesReferenceGenerate({ type: 'bankTransfer' })}
        isNewFolio={isNewFolio}
      />
      )}
      <Previewer
        visible={visiblePreview}
        base64={previewBase64}
        onCancel={() => {
          setVisiblePreview(false);
        }}
      />
      <Previewer
        visible={visiblePreviewNetPay}
        base64={referencia?.base64}
        onCancel={onCancelNetPay}
        allowSend={Boolean(referencia?.base64)}
      />
      <ModalUpdateInfo
        visible={visibleUpdate}
        onCancel={() => setVisibleUpdate(false)}
        setVisible={setVisibleUpdate}
        tipoDePadron={tipoDePadron}
        idPadron={idPadron}
        tipoDePadronId={tipoDePadronId}
        callback={() => {
          form.submit();
        }}
      />
      <ModalImporte
        form={form}
        showImporteModal={showImporteModal}
        setShowImporteModal={setShowImporteModal}
        tempCargos={tempCargos}
        setSelectedCargos={setSelectedCargos}
      />
      <Spinner visible={GenerandoRecibo} />
      <ModalConfirm
        visible={acceptDeclaration}
        setVisible={setAcceptDeclaration}
        body="Una vez que se genere la declaración no podrá ser modificada. ¿Desea generar la declaración?"
        btnText="Si, generar declaración"
        callback={(isAccept) => handleAcceptDeclaration(isAccept)}
      />
      <ModalAlertas
        restrictivas={alertasRestrictivas}
        informativas={alertasInformativas}
        callback={onCancel}
      />
    </>
  );
};
export default PaymentForm;
