/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isNetpayInitialized: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    clearApp: (state) => {
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key] || [];
      });
    },
    setIsNetpayInitialized: (state) => {
      state.isNetpayInitialized = true;
    },
  },
});

export const {
  clearApp,
  setIsNetpayInitialized,
} = appSlice.actions;

// Data Selectors
export const selectIsNetpayInitialized = ({ app }) => !!app.isNetpayInitialized;

export default appSlice.reducer;
