import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Title, Button, Text } from 'components';
import {
  EyeOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import {
  List,
  Row,
  Col,
  Card as AntCard,
  Empty,
} from 'antd';
import Skeletons from './SkeletosWrappers';

const { SkeletonWrapperText } = Skeletons;

const Formatos = ({
  plantilla,
  loading,
}) => {
  if (!plantilla?.formato_requerido.length) {
    return <Empty description="No hay formatos disponibles" />;
  }

  return (
    <>
      <Title $accent level={3}>
        FORMATOS DEL TRÁMITE
      </Title>
      <List
        dataSource={plantilla?.formato_requerido}
        loading={loading}
        renderItem={(item) => (
          <RoundedCard bordered>
            <Row>
              <Title $accent level={4}>
                {item?.descripcion}
              </Title>
            </Row>
            <Row justify="space-between">
              <SkeletonWrapperText
                dataLabel="Clave"
                bold={false}
              >
                {item?.identificador_de_formato}
              </SkeletonWrapperText>
              <SkeletonWrapperText
                dataLabel="¿Se puede presentar electrónicamente?"
                bold={false}
              >
                {item?.formato_electronico ? 'Sí' : 'No'}
              </SkeletonWrapperText>
            </Row>
            {!!item?.fundamento_legal && (
            <Row>
              <SkeletonWrapperText
                accent
                bold={false}
                loading={loading}
                dataLabel="Fundamento Legal"
              >
                <br />
                <ul>
                  {item.fundamento_legal.map((f) => (
                    <li key={f.id}>
                      {f.denominacion}
                    </li>
                  ))}
                </ul>
              </SkeletonWrapperText>
            </Row>
            )}
            <br />
            <Row justify="end" gutter={[10, 10]}>
              {item?.liga_descarga_de_formato && (
              <Col>
                <Button
                  $accent
                  icon={<DownloadOutlined />}
                  shape="round"
                  onClick={() => window.open(item.liga_descarga_de_formato, '_blank')}
                >
                  <Text>Descargar</Text>
                </Button>
              </Col>
              )}
              {item?.descargar_archivo && (
              <Col>
                <Button
                  icon={<EyeOutlined />}
                  shape="round"
                  onClick={() => window.open(item.descargar_archivo, '_blank')}
                >
                  <Text>Ver</Text>
                </Button>
              </Col>
              )}
            </Row>
          </RoundedCard>
        )}
      />
    </>
  );
};

Formatos.propTypes = {
  plantilla: PropTypes.shape({
    formato_requerido: PropTypes.arrayOf(PropTypes.shape({
      descripcion: PropTypes.string,
      identificador_de_formato: PropTypes.string,
      formato_electronico: PropTypes.string,
      fundamento_legal: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        denominacion: PropTypes.string,
      })),
    })),
  }),
  loading: PropTypes.bool,
};

Formatos.defaultProps = {
  plantilla: {},
  loading: false,
};

const RoundedCard = styled(AntCard)`
  border-radius: 10px;
`;

export default Formatos;
