import { ENV_NAME, ENV_NAMES_ENUM } from 'utils/env';

const entityInfo = {
  [ENV_NAMES_ENUM.NAYARIT]: {
    name: 'Nayarit',
    terminos: '',
  },
  [ENV_NAMES_ENUM.JUAREZ]: {
    name: 'Juárez',
    terminos: 'https://dutramites.juarez.gob.mx/terminos_condiciones.pdf',
  },
  [ENV_NAMES_ENUM.HERMOSILLO]: {
    name: 'Hermosillo',
    terminos: '',
  },
};

export default entityInfo[ENV_NAME];
