import React, { useState, useEffect } from 'react';
import { getRoute, extra, currentEntity } from 'components/Shortcuts';
import { useSelector } from 'react-redux';
import NavigationCard from 'views/Payments/components/NavigationCard';
import { selectClasificadoresDeTiposDeCargos } from 'store/catalogos';

const PaymentsList = () => {
  const _clasificadores = useSelector(selectClasificadoresDeTiposDeCargos);
  const [clasificadores, setClasificadores] = useState([]);

  useEffect(() => {
    if (_clasificadores) {
      localStorage.setItem('clasificadoresLen', _clasificadores?.clasificadores?.length || 0);
      setClasificadores(_clasificadores.clasificadores.concat(currentEntity.concatExtra
        ? extra() : []));
    }
  }, [_clasificadores]);

  if (!clasificadores.length) return <span />;

  return (
    <>
      {clasificadores.map((e) => (
        <NavigationCard
          key={e.id}
          text={e.nombre}
          to={getRoute(e)}
          type={currentEntity.styleClasificador}
          icon={e.icono}
        />
      ))}
    </>
  );
};

export default PaymentsList;
