import API from 'api';
import _logger from 'utils/logger';
import { postHistorialDeTramite } from './historial';
import { postFirmarCadena } from './efirma';

/**
 *  GET Tipos de Requisitos
 * @param {object} params
 * @returns {Promise<object[]>}
 */
export const getTiposDeRequisitos = async (params) => {
  try {
    const response = await API.get('/tramites/tipos-de-requisitos/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 *  GET Categorias de Requisitos
 * @param {object} params
 * @returns {Promise<object[]>}
 */
export const getCategoriasDeRequisitos = async (params) => {
  try {
    const response = await API.get('/catalogos/categorias-para-tramites/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 *  GET Unidades de plazo
 * @param {object} params
 * @returns {Promise<object[]>}
 */
export const getUnidadesDePlazo = async (params) => {
  try {
    const response = await API.get('/catalogos/unidades-de-plazo/', { params });
    return response.data;
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 *  GET Catalogo de Requisitos
 * @param {number[]} ids
 * @returns {Promise<object[]>}
 */
export const getManyCatalogosDeRequisitos = async (_ids) => {
  try {
    const ids = [...new Set(_ids)];
    const responses = await Promise.all(ids.map((id) => API.get(`/catalogos/catalogos-de-requisitos/${id}`)));
    return responses.map(({ data }) => data);
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 *  GET Requisitos
 * @param {number[]} ids
 * @returns {Promise<object[]>}
 */
export const getManyRequisitos = async (_ids) => {
  try {
    const ids = [...new Set(_ids)];
    const responses = await Promise.all(ids.map((id) => API.get(`/tramites/requisitos/${id}`)));
    return responses.map(({ data }) => data);
  } catch (err) {
    _logger(err);
    return [];
  }
};

/**
 *  POST Firmar de Tramites
 * @param {object} tramite
 * @param {object} values
 * @param {number} values.usuario
 * @param {number} values.paso
 * @param {object} valuesLog
 * @returns {Promise<object>}
 */
export const patchFirmarTramite = async (tramite, values, valuesLog) => {
  const firmasElectronicas = JSON.parse(tramite.firma_electronica || '[]');
  const firma = await postFirmarCadena(values, tramite);
  if (!firma) {
    return null;
  }
  firma.paso = values.paso;
  const firma_electronica = [...firmasElectronicas.map((f) => ({
    ...f,
    eliminado: f.paso === firma.paso,
  })), firma];
  const valuesTramite = { firma_electronica: JSON.stringify(firma_electronica) };
  try {
    const response = await API.patch(`/tramites/tramites/${tramite.id}/`, valuesTramite);
    if (valuesLog) {
      await postHistorialDeTramite(valuesLog);
    }
    return response.data;
  } catch (err) {
    _logger(err);
    return null;
  }
};

export const getTramitesCount = async () => {
  try {
    const response = await API.get('/tramites/plantillas-de-tramites-conteo/conteo/');
    return response.data;
  } catch (err) {
    _logger(err);
    return {
      tramites: 0,
      servicios: 0,
      en_linea: 0,
    };
  }
};
