/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Card,
  Col,
  Collapse,
  Form,
  Grid,
  message,
  Row,
  Table,
  Tooltip,
} from 'antd';
import {
  Button,
  COLORS,
  Text,
  Title,
} from 'components';
import { scrollToTop } from 'components/ScrollToTop';
import SearchForm from 'views/Payments/components/SearchForm';
import {
  getPeriodosFiscales,
  postGenererReferenciasBanco,
  postGenererReferenciasNetpay,
} from 'api/recaudacion';
import gettersMap, { getAlcoholById } from 'api/padrones';
import { toCurrency } from 'utils/formatters';
import {
  RightOutlined,
  BarcodeOutlined,
  QuestionCircleOutlined,
  CreditCardOutlined,
} from '@ant-design/icons';
import NetPaybutton, {
  validateLastTransaction,
  getNetPayBtn,
  paymentLocationsEnum,
  clearDataset,
} from 'views/Payments/components/NetPayButton';
import Previewer from 'components/Previewer';
import debtsFormatter, { debtsColumns as columns } from 'utils/debtsFormatter';
import round from 'utils/round';
import { useSelector } from 'react-redux';
import { selectPublicoGeneral, selectRedondeo } from 'store/auth';
import {
  SHOW_REDONDEO,
  CLAVE_CATASTRAL_SEGMENTOS,
  ENV_NAME,
  ENV_NAMES_ENUM,
} from 'utils/env';
import { selectPadrones } from 'store/catalogos';
import { getAdeudoPadronAsync } from 'hooks/useAdeudoPadron';
import sortCargos from 'utils/sorterCargos';
import { useLocation, useNavigate } from 'react-router';
import { padronesEnum } from 'views/Tramites/GPM/enums';
import Spinner from 'components/Spinner';
import ModalAlertas from 'components/ModalAlertas';
import { recievePadronFound } from './Form';
import ModalUpdateInfo from './components/ModalUpdateInfo';
import ModalBancos from './components/ModalBancos';

const RedondeoLabel = ({ rounded, tooltip }) => !!rounded && (tooltip ? (
  <Tooltip color={COLORS.white} title={<RedondeoLabel rounded={rounded} />}>
    {' '}
    <QuestionCircleOutlined />
  </Tooltip>
) : (
  <Row justify="end">
    <Text $xs>
      * Los centavos del monto total están siendo redondeados
      {SHOW_REDONDEO && rounded && ` (${toCurrency(rounded)})`}
    </Text>
  </Row>
));

const PaymentControls = ({
  _key,
  padron,
  cargos,
  loading,
  referencia,
  onClickOrder,
  onClickPayOnline,
  onClickTranferencia,
}) => (
  <>
    <br />
    <Row gutter={[10, 10]} justify="end">
      <NetPaybutton
        excludeValuesContactPublicoGeneral
        loading={loading}
        cargos={cargos}
        padron={padron}
        createdBy="DEBTS"
        generarReferencia={() => onClickPayOnline(cargos, _key)}
        referencia={referencia?.key === _key ? referencia?.folio : ''}
        // disabled={referencia && referencia?.key !== _key}
      />
      <Col xs={24} sm={24} md={8}>
        <Button
          // disabled={referencia && referencia?.key !== _key}
          onClick={() => onClickOrder(cargos, _key)}
          loading={loading}
          block
          $accent
        >
          Generar orden de pagos
          <BarcodeOutlined />
        </Button>
      </Col>
      <Col xs={24} sm={24} md={8}>
        <Button
          // disabled={referencia && referencia?.key !== _key}
          onClick={() => onClickTranferencia(_key)}
          loading={loading}
          block
          $accent
        >
          Transferencia interbancaria
          <CreditCardOutlined />
        </Button>
      </Col>
    </Row>
  </>
);

const PanelHeader = ({
  title,
  total,
  utilizaRedondeo,
  rounded,
  screens,
  active,
}) => (
  <>
    <Row justify="space-between">
      <Title level={4} $accent $uppercase>
        {title}
      </Title>
      <Title level={4} $accent style={{ textAlign: 'right', width: screens.md ? 'auto' : '100%' }}>
        {toCurrency(total)}
        {utilizaRedondeo && !screens.md && (<RedondeoLabel rounded={rounded} tooltip />)}
      </Title>
    </Row>
    <Row justify={utilizaRedondeo ? 'space-between' : 'start'}>
      <Text $xs $capitalize>
        <RightOutlined rotate={active ? 90 : 0} />
        {`${active ? 'D' : 'Ver d'}etalle del pago`}
      </Text>
      {utilizaRedondeo && screens.md && (<RedondeoLabel rounded={rounded} />)}
    </Row>
  </>
);

const Debts = () => {
  // Hooks
  const location = useLocation();
  const ciudadanoPublicoGeneral = useSelector(selectPublicoGeneral);
  const utilizaRedondeo = useSelector(selectRedondeo);
  const padrones = useSelector(selectPadrones).filter((p) => p.available);
  const [loading, setLoading] = useState(false);
  const screens = Grid.useBreakpoint();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  // State
  const [tipoDePadron, setTipoDePadron] = useState();
  const [padron, setPadron] = useState();
  const [periodosFiscales, setPeriodosFiscales] = useState([]);
  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visiblePreviewRecibo, setVisiblePreviewRecibo] = useState(false);
  const [referencia, setReferencia] = useState();
  const [recibo, setRecibo] = useState();
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [idPadron, setIdPadron] = useState(0);
  const [tipoDePadronId, setTipoDePadronId] = useState(0);
  const [activeKey, setActiveKey] = useState();
  const [visibleBancos, setVisibleBancos] = useState(false);
  const [isNewFolio, setIsNewFolio] = useState(true);
  const [cargosTemp, setCargosTemp] = useState([]);
  const [GenerandoRecibo, setGenerandoRecibo] = useState(false);

  const [alertasInformativas, setAlertasInformativas] = useState([]);
  const [alertasRestrictivas, setAlertasRestrictivas] = useState([]);

  const [
    tramites = [],
    sistemas = [],
    cargosLibres = [],
    [total, rounded] = [],
  ] = useMemo(() => {
    if (!padron) return [];
    const idsTramites = padron.cargos.map((c) => c.tramite?.id).filter((s) => s);
    const _tramites = [...new Set(idsTramites)]
      .map((t) => padron.cargos.find((c) => c.tramite?.id === t).tramite)
      .map((trm) => ({
        ...trm,
        key: `tramite_${trm.id}`,
        cargos: sortCargos(padron.cargos.filter((c) => c.tramite?.id === trm.id)),
      }));

    const idsSistemas = [...new Set(padron.cargos
      .map((c) => (c.tramite || c.es_accesorio ? null : c.tipo_de_cargo.sistemas?.id))
      .filter((s) => s))];
    const _sistemas = idsSistemas.map((s) => {
      const cargosPadres = padron.cargos
        .filter((c) => !c.es_accesorio && c.tipo_de_cargo.sistemas?.id === s && !c.tramite);

      const accesorios = cargosPadres.map((cp) => padron.cargos
        .filter((_c) => _c.es_accesorio && _c.cargo_padre === cp.id)).flat();
      const cargos = sortCargos([...cargosPadres, ...accesorios]);
      const sistema = cargos[0].tipo_de_cargo.sistemas;
      return {
        key: `sistema_${sistema.id}`,
        ...sistema,
        cargos,
      };
    });
    const idsCargosSistemasTramites = _tramites.map((t) => t.cargos.map((c) => c.id)).flat()
      .concat(..._sistemas.map((s) => s.cargos.map((c) => c.id)).flat());
    const _cargosLibres = padron.cargos.filter((c) => !idsCargosSistemasTramites.includes(c.id));

    return [
      _tramites,
      _sistemas.filter((s) => s.cargos.length),
      sortCargos(_cargosLibres),
      round(_cargosLibres.reduce((acum, curr) => acum + curr.adeudo_total, 0)),
    ];
  }, [padron]);

  const generarReferenciaBanco = async (_cargos) => {
    setLoading(true);
    const _referencia = await postGenererReferenciasBanco(
      tipoDePadron,
      padron,
      _cargos,
      padrones,
    );
    setReferencia({ ..._referencia, antActiveKey: activeKey });
    setIsNewFolio(false);
    setLoading(false);
    return _referencia;
  };

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      const _periodosFiscales = await getPeriodosFiscales();
      if (mounted) {
        setPeriodosFiscales(_periodosFiscales);
      }
      setLoading(false);
    };
    fetch();
    return () => { mounted = false; };
  }, []);

  useEffect(() => {
    validateLastTransaction(false, (_recibo) => {
      if (_recibo) {
        setRecibo(_recibo);
        setTimeout(() => {
          setGenerandoRecibo(false);
          setReferencia(_recibo);
          setVisiblePreviewRecibo(true);
        }, 3000);
      }
    }, setLoading, paymentLocationsEnum.DEBTS, true, setVisiblePreviewRecibo);
  }, []);

  useEffect(() => {
    if (tipoDePadron && !padrones.some((e) => e.id === tipoDePadron)) {
      navigate('/not-found');
    }
  }, [tipoDePadron]);

  const onCancel = () => {
    setPadron();
    setCargosTemp();
    setVisiblePreview(false);
    setVisiblePreviewRecibo(false);
    setReferencia();
    setRecibo();
    form.resetFields(['q']);
    scrollToTop(screens);
  };

  const onCancelNetPay = () => {
    setVisiblePreviewRecibo(false);
    setRecibo();
  };

  const onFinishSearch = async (values) => {
    setLoading(true);
    const { padron: _padron, q, ...claveRfc } = values;
    const defaultParams = { q };
    const paramsObj = {
      3: {
        q: CLAVE_CATASTRAL_SEGMENTOS[ENV_NAME]
          ? [values?.localidad, values?.sector, values?.manzana, values?.lote, values?.condominio].join('-')
          : values.q,
      },
      4: {
        numero_de_identificacion_vehicular: values.serie,
        numero_de_placa_vigente: values.placa,
      },
      16: {
        serie: values.serie,
        numero_de_placa_vigente: values.placa,
      },
      17: {
        serie: values.serie,
        placa: values.placa,
      },
      6: ENV_NAME === 'nayarit' ? claveRfc : defaultParams,
      7: ENV_NAME === 'nayarit' ? claveRfc : defaultParams,
      8: ENV_NAME === 'nayarit' ? claveRfc : defaultParams,
      10: ENV_NAME === 'nayarit' ? claveRfc : defaultParams,
      11: ENV_NAME === 'nayarit' ? claveRfc : defaultParams,
      14: ENV_NAME === 'nayarit' ? claveRfc : defaultParams,
      26: ENV_NAME === 'nayarit' ? claveRfc : defaultParams,
      27: ENV_NAME === 'nayarit' ? claveRfc : defaultParams,
      28: ENV_NAME === 'nayarit' ? claveRfc : defaultParams,
    };
    const params = paramsObj[_padron] || defaultParams;
    const found = await gettersMap[_padron]({ ...params });
    if (found) {
      if (found?.alerta?.length) {
        const alertasVigentes = found.alerta.filter((a) => a.estados_globales !== 5);
        const _alertasRestrictivas = alertasVigentes.filter((a) => a.es_restrictiva);
        const _alertasInformativas = alertasVigentes.filter((a) => !a.es_restrictiva);
        setAlertasRestrictivas(_alertasRestrictivas);
        setAlertasInformativas(_alertasInformativas);
      }

      if (_padron === padronesEnum.ALCOHOL) {
        const { detail } = await getAlcoholById(found.id);
        if (detail) {
          message.warn(detail);
          setLoading(false);
          return;
        }
      }
      recievePadronFound({
        setLoading,
        setVisibleModalUpdateInfo: setVisibleUpdate,
        padron: found,
        inNeedsInfo: {
          setPadronId: setIdPadron,
          setTipoDePadron: setTipoDePadronId,
        },
        callback: async () => {
          setLoading(true);
          const _tipoDePadron = location?.state?.padron || _padron;
          const pg = ciudadanoPublicoGeneral
            && found.id === ciudadanoPublicoGeneral.id && tipoDePadron === 1;
          const [_cargos = [], err] = !pg
            ? await getAdeudoPadronAsync(tipoDePadron ?? _tipoDePadron, found.id, null, true)
            : [];
          const _cargosSinConvenios = _cargos.filter((c) => !c.convenios);
          if (err) {
            message.warn(err);
          } else if (_cargosSinConvenios.length) {
            if (location?.state?.cargo_id) {
              const cargoPadre = _cargosSinConvenios.find((c) => c.id === location.state.cargo_id);
              const cargosHijos = _cargosSinConvenios
                .filter((c) => c.cargo_padre === cargoPadre.id);
              found.cargos = debtsFormatter([cargoPadre, ...cargosHijos]);
            } else {
              found.cargos = debtsFormatter(_cargosSinConvenios);
            }
            setPadron(found);
          } else {
            message.info('Este padrón no tiene adeudos pendientes.');
          }
          setLoading(false);
        },
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    const autoFetchPadron = async () => {
      const { state } = location;
      if (state) {
        setTipoDePadron(state.padron);
        await onFinishSearch({ padron: 15, q: state.padron_RFC });
      }
    };
    autoFetchPadron();
  }, []);

  const generarReferencia = async (cargos, _key, callback) => {
    if (referencia?.key !== _key) {
      clearDataset(paymentLocationsEnum.DEBTS);
      setLoading(true);
      const _referencia = await postGenererReferenciasNetpay(
        tipoDePadron,
        padron,
        cargos,
        padrones,
      );
      if (_referencia) {
        _referencia.key = _key;
        setReferencia(_referencia);
        setTimeout(callback, 1500);
      }
      setLoading(false);
    } else {
      setTimeout(callback, 1500);
    }
  };

  const onClickOrder = async (
    cargos,
    key,
  ) => generarReferencia(cargos, key, () => setVisiblePreview(true));

  const onClickTranferencia = async (key) => {
    setCargosTemp([]);
    const finder = (s) => s.key === key;
    const _cargos = (sistemas.find(finder) || tramites.find(finder)
      || cargosLibres.find(finder))?.cargos;

    setIsNewFolio(referencia?.antActiveKey !== key);
    setCargosTemp(_cargos);
    setVisibleBancos(true);
  };

  const onClickPayOnline = async (
    cargos,
    key,
  ) => generarReferencia(cargos, key, () => getNetPayBtn()?.click());

  if (!padron) {
    return (
      <>
        <Col span={24}>
          <Title style={{ textAlign: 'center', margin: 0 }}>
            Consulta de adeudos
          </Title>
        </Col>
        <SearchForm
          padron={padron}
          onCancel={onCancel}
          loading={loading}
          form={form}
          padrones={padrones}
          onFinishSearch={onFinishSearch}
          tipoDePadron={tipoDePadron}
          setTipoDePadron={setTipoDePadron}
          periodosFiscales={periodosFiscales}
          setLoading={setLoading}
        />
        <Previewer
          visible={visiblePreviewRecibo}
          base64={recibo?.base64}
          onCancel={onCancelNetPay}
          allowSend={Boolean(recibo?.base64)}
        />
        <ModalUpdateInfo
          visible={visibleUpdate}
          onCancel={() => setVisibleUpdate(false)}
          setVisible={setVisibleUpdate}
          tipoDePadron={tipoDePadron}
          idPadron={idPadron}
          tipoDePadronId={tipoDePadronId}
          callback={() => {
            form.submit();
          }}
        />
      </>
    );
  }

  const scroll = { x: columns.reduce((acum, curr) => acum + curr.width, 0) };

  return (
    <>
      <Col span={24}>
        <Title style={{ textAlign: 'center', margin: 0 }}>
          Consulta de adeudos
        </Title>
      </Col>
      <SearchForm
        padron={padron}
        onCancel={onCancel}
        loading={loading}
        form={form}
        padrones={padrones}
        onFinishSearch={onFinishSearch}
        tipoDePadron={tipoDePadron}
        setTipoDePadron={setTipoDePadron}
        periodosFiscales={periodosFiscales}
        setLoading={setLoading}
      />
      {!!tramites.length && (
        <Card style={{ width: '100%' }}>
          <Title level={3} style={{ textAlign: 'center' }}>
            Adeudos en trámites
          </Title>
        </Card>
      )}
      {tramites.map((tramite) => {
        const _total = tramite.cargos.reduce((acum, curr) => acum + curr.adeudo_total, 0);
        const [totalTramite, roundedTramite] = round(_total);
        return (
          <Collapse
            key={`collapse_tramite_${tramite.id}`}
            activeKey={tramites.map((t) => t.key).includes(activeKey) ? activeKey : undefined}
            onChange={setActiveKey}
            style={{ width: '100%', background: '#FFFFFF' }}
            bordered={false}
            destroyInactivePanel
            accordion
          >
            <Collapse.Panel
              showArrow={false}
              header={(
                <PanelHeader
                  title={`TRÁMITE ${tramite.folio}`}
                  total={totalTramite}
                  utilizaRedondeo={utilizaRedondeo}
                  rounded={roundedTramite}
                  screens={screens}
                  active={activeKey === `tramite_${tramite.id}`}
                />
              )}
              key={`tramite_${tramite.id}`}
            >
              <Card style={{ width: '100%' }} bodyStyle={{ padding: 0 }}>
                <Table
                  columns={columns}
                  scroll={scroll}
                  dataSource={tramite.cargos}
                  rowKey="id"
                  loading={loading}
                  size="small"
                  bordered
                />
                <PaymentControls
                  _key={tramite.key}
                  padron={padron}
                  cargos={tramite.cargos}
                  loading={loading}
                  referencia={referencia}
                  onClickOrder={onClickOrder}
                  onClickPayOnline={onClickPayOnline}
                  onClickTranferencia={onClickTranferencia}
                />
              </Card>
            </Collapse.Panel>
          </Collapse>
        );
      })}
      {!!sistemas.length && (
        <Card style={{ width: '100%' }}>
          <Title level={3} style={{ textAlign: 'center' }}>
            Adeudos en sistema
          </Title>
        </Card>
      )}
      {sistemas.map((sistema) => {
        const _total = sistema.cargos.reduce((acum, curr) => acum + curr.adeudo_total, 0);
        const [totalSistema, roundedSistema] = round(_total);
        return (
          <Collapse
            key={`collapse_sistema_${sistema.id}`}
            defaultActiveKey={sistemas.map((t) => t.key)}
            onChange={setActiveKey}
            style={{ width: '100%', background: '#FFFFFF' }}
            bordered={false}
            destroyInactivePanel
            accordion
          >
            <Collapse.Panel
              showArrow={false}
              header={(
                <PanelHeader
                  title={`SISTEMA ${sistema.descripcion}`}
                  total={totalSistema}
                  utilizaRedondeo={utilizaRedondeo}
                  rounded={roundedSistema}
                  screens={screens}
                  active={activeKey === `sistema_${sistema.id}`}
                />
              )}
              key={`sistema_${sistema.id}`}
              bordered
              collapsible="disabled"
            >
              <Card style={{ width: '100%' }} bodyStyle={{ padding: 0 }}>
                <Table
                  columns={columns}
                  scroll={scroll}
                  dataSource={sistema.cargos}
                  rowKey="id"
                  loading={loading}
                  size="small"
                  bordered
                />
                <br />
                <PaymentControls
                  _key={sistema.key}
                  padron={padron}
                  cargos={sistema.cargos}
                  loading={loading}
                  referencia={referencia}
                  onClickOrder={onClickOrder}
                  onClickPayOnline={onClickPayOnline}
                  onClickTranferencia={onClickTranferencia}
                />
              </Card>
            </Collapse.Panel>
          </Collapse>
        );
      })}
      <Card style={{ width: '100%', marginTop: 20 }} bodyStyle={{ padding: 0 }}>
        <Collapse
          activeKey={activeKey === 'adeudos' ? activeKey : undefined}
          onChange={setActiveKey}
          style={{ width: '100%', background: '#FFFFFF' }}
          destroyInactivePanel
          accordion
          ghost
        >
          <Collapse.Panel
            showArrow={false}
            header={(
              <PanelHeader
                title="Otros adeudos"
                total={total}
                utilizaRedondeo={utilizaRedondeo}
                rounded={rounded}
                screens={screens}
                active={activeKey === 'adeudos'}
              />
            )}
            key="adeudos"
            collapsible={!cargosLibres.length && 'disabled'}
            bordered
          >
            <Table
              columns={columns}
              scroll={scroll}
              dataSource={cargosLibres}
              rowKey="id"
              loading={loading}
              size="small"
              bordered
            />
            <PaymentControls
              _key="adeudos"
              padron={padron}
              cargos={cargosLibres}
              loading={loading}
              referencia={referencia}
              onClickOrder={onClickOrder}
              onClickPayOnline={onClickPayOnline}
              onClickTranferencia={onClickTranferencia}
            />
          </Collapse.Panel>
        </Collapse>
      </Card>
      <Previewer
        visible={visiblePreview}
        base64={referencia?.base64}
        onCancel={() => setVisiblePreview(false)}
      />
      {ENV_NAME === ENV_NAMES_ENUM.NAYARIT && (
        <ModalBancos
          visible={visibleBancos}
          onCancel={() => { setVisibleBancos(false); }}
          cargosGenerados={cargosTemp}
          padron={padron}
          padrones={padrones}
          tipoDePadron={tipoDePadron}
          currentClassifier="Consulta de adeudos"
          cbReferenciaBanco={() => generarReferenciaBanco(cargosTemp)}
          isNewFolio={isNewFolio}
        />
      )}
      <Spinner visible={GenerandoRecibo} />
      <ModalAlertas
        restrictivas={alertasRestrictivas}
        informativas={alertasInformativas}
        callback={onCancel}
      />
    </>
  );
};

export default Debts;
