import { DebtsSVG, FaqSVG, paymentOrderSVG } from 'assets/PadronesIcons';
import React from 'react';
import { ENV_NAME, ENV_NAMES_ENUM, RECEIPT_LABEL } from 'utils/env';
import toSlug from 'utils/toSlug';

// eslint-disable-next-line react/prop-types
const Icon = ({ Item }) => (
  <span role="img" className="anticon anticon-home" aria-label="home">
    <Item />
  </span>
);

export const propsPerEntity = {
  [ENV_NAMES_ENUM.JUAREZ]: {
    color: 'white',
    shortCuts: ['debts'],
    concatExtra: true,
    styleClasificador: 'card',
    styleSubClasifiers: 'card',
  },
  [ENV_NAMES_ENUM.NAYARIT]: {
    color: '',
    shortCuts: ['faq', 'paymentOrder', 'debts', 'receiptByReference'],
    concatExtra: false,
    styleClasificador: 'classifiers',
    styleSubClasifiers: 'minibanner',
  },
  [ENV_NAMES_ENUM.HERMOSILLO]: {
    color: '#585858',
    shortCuts: ['faq', 'paymentOrder', 'debts'],
    concatExtra: false,
    styleClasificador: 'classifiers',
    styleSubClasifiers: 'minibanner',
  },
  [ENV_NAMES_ENUM.ROATAN]: {
    color: '',
    shortCuts: ['debts', 'faq', 'paymentOrder'],
    concatExtra: false,
    styleClasificador: 'classifiers',
    styleSubClasifiers: 'minibanner',
  },
};

export const currentEntity = propsPerEntity[ENV_NAME] || {};

export const extra = () => {
  const { pathname } = window.location;
  const current = propsPerEntity[ENV_NAME] || {};
  return [
    {
      id: 'paymentOrder',
      icono: <Icon Item={paymentOrderSVG} />,
      nombre: 'Orden de pago',
      buttonLabel: 'Ir a pagar',
      clasificador: '',
      show: pathname !== '/pagos/orden-de-pago',
      bannerBody: (
        <>
          Puedes realizar el pago online de tu orden de pago, solo necesitas
          tu
          {' '}
          <b>FOLIO</b>
          .
        </>
      ),
    },
    {
      id: 'debts',
      icono: <Icon Item={DebtsSVG} />,
      nombre: 'Consulta de adeudos',
      buttonLabel: 'Ir a consulta',
      clasificador: '',
      show: !pathname.includes('consulta-de-adeudos'),
      bannerBody: (
        <>
          Puedes realizar la consulta de tus adeudos actuales, solo necesitas
          tu
          {' '}
          <b>RFC</b>
          ,
          {' '}
          <b>CURP</b>
          {' '}
          o la
          {' '}
          <b>CLAVE</b>
          {' '}
          de tu padrón.
        </>
      ),
    },
    {
      id: 'faq',
      icono: <Icon Item={FaqSVG} />,
      nombre: 'Facturación electrónica',
      buttonLabel: 'Ir a facturación',
      clasificador: '',
      show: true,
      bannerBody: (
        <>
          Puedes realizar la facturación electrónica de tus pagos realizados,
          sólo necesitas
          {' '}
          <b>RFC</b>
          {' '}
          y
          {' '}
          <b>NÚMERO</b>
          {' '}
          de
          {' '}
          {RECEIPT_LABEL}
          .
        </>
      ),
    },
    {
      id: 'receiptByReference',
      icono: <Icon Item={paymentOrderSVG} />,
      nombre: 'Consulta de recibo y facturación por orden de pago',
      buttonLabel: 'Ir a Consultar',
      clasificador: '',
      show: pathname !== '/pagos/consulta-de-recibo',
      bannerBody: (
        <>
          Puedes realizar tu facturación y consultar tu recibo con tu orden de pago
          {' '}
          <b>saldada</b>
          .
        </>
      ),
    },
  ].filter((e) => e.show && current.shortCuts.includes(e?.id));
};

const getClassificator = (_classificator) => {
  if (!_classificator) {
    return '';
  }
  if (_classificator?.clasificador.includes('.')) {
    return `${_classificator.nombre} ${_classificator.clasificador.split('.')[1]}`;
  }
  return `${_classificator.nombre} ${_classificator.clasificador}`;
};

export const getRoute = (item) => {
  const { pathname } = window.location;
  const idDescription = 'id' in item && 'descripcion' in item;
  switch (item.nombre) {
    case 'Facturación electrónica':
      return '/facturacion';
    case 'Orden de pago':
      return '/pagos/orden-de-pago';
    case 'Consulta de recibo y facturación por orden de pago':
      return '/pagos/consulta-de-recibo';
    case 'Consulta de adeudos':
      if (pathname.includes('consulta-de-adeudos') || pathname.includes('pagos/')) {
        return '/pagos/consulta-de-adeudos';
      }
      return 'consulta-de-adeudos';
    default:
      return toSlug(idDescription ? `${item.descripcion} ${item.id}`
        : getClassificator(item));
  }
};

export default { getRoute, extra, propsPerEntity };
