import React, { useEffect, useState } from 'react';
import useQuery from 'hooks/useQuery';
import gettersMap from 'api/padrones';
import NetPaybutton, { getNetPayBtn, paymentLocationsEnum } from './Payments/components/NetPayButton';

const PaymentWebView = () => {
  const [
    ciudadano,
    productCount,
    ...query
  ] = useQuery([
    'ciudadano',
    'productCount',
    'tipo_de_padron',
    'object_id_padron',
    'merchantReferenceCode',
    'cargos',
    'total',
  ]);

  const [
    tipo_de_padron,
    object_id_padron,
    merchantReferenceCode,
    cargos,
    total,
  ] = query;
  const [loading, setLoading] = useState(false);
  const [padron, setPadron] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const _padron = await gettersMap[parseInt(tipo_de_padron, 10)]({ id: object_id_padron });
      if (_padron) {
        if (ciudadano) {
          _padron.solicitante = ciudadano;
          _padron.propNameContactInfo = 'solicitante';
        }
        setPadron(_padron);
        setTimeout(() => getNetPayBtn().click(), 1000);
      }
      setLoading(false);
    };
    if (query.every((p) => p)) {
      fetchData();
    }
  }, []);

  if (!query.every((p) => p)) {
    return 'missing query string params, at least is required tipo_de_padron, object_id_padron, merchantReferenceCode, cargos, total';
  }

  if (loading || !padron) {
    return null;
  }

  return (
    <NetPaybutton
      referencia={merchantReferenceCode}
      loading={loading}
      cargos={cargos?.split(',')}
      padron={padron}
      createdBy={paymentLocationsEnum.WEBVIEW}
      productCount={productCount}
      total={parseFloat(total)}
      style={{ display: 'none' }}
    />
  );
};

export default PaymentWebView;
