/* eslint-disable import/prefer-default-export */
import API from 'api';
import _logger from 'utils/logger';
import formatValues from 'utils/formatValues';
import Moment from 'moment';
import { ID_ENTIDAD } from 'utils/env';
import { formatReceived } from 'utils/formatters';

const baseURI = '/solicitudes/';

export const getQueja = async (params = {}) => {
  try {
    const response = await API.get(`${baseURI}quejas/`, { params });
    return formatReceived(response.data);
  } catch (error) {
    _logger(error);
  }
  return false;
};

export const postQueja = async (values = null) => {
  try {
    const formattedValues = {
      ...formatValues(values),
      hora_de_los_hechos: Moment(values.hora_de_los_hechos).format('HH:mm:ss'),
      entidad: ID_ENTIDAD,
    };

    const response = await API.post(`${baseURI}quejas/`, formattedValues);
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return false;
};

export const getCategoriasDeQuejas = async () => {
  try {
    const response = await API.get(`${baseURI}categorias-de-las-quejas/`);
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return [];
};

export const getMotivosDeQuejas = async () => {
  try {
    const response = await API.get(`${baseURI}motivos-de-las-quejas/`);
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return [];
};

export const postTestigos = async (quejaId, values = null) => {
  try {
    const response = await API.post(`${baseURI}testigos-de-quejas-public/`, {
      ...values,
      queja: quejaId,
    });
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return false;
};

export const postEvidencias = async (values = null) => {
  try {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const response = await API.post(`${baseURI}archivos-de-quejas/`, values, config);
    return response.data;
  } catch (error) {
    _logger(error);
  }
  return false;
};
