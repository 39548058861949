import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  message,
  Empty,
} from 'antd';
import { Button, Text, Title } from 'components';
import { fundamentoLegaleFormat } from 'utils/formatters';

const onClickWatchDoc = (documento) => {
  if (documento) {
    window.open(documento, '_blank');
  } else {
    message.warn('El documento no se encuentra disponible de momento, por favor intenta más tarde.', 2.5);
  }
};

const FundamentosLegalesItems = ({ fundamentosLegales, title, allowEmpty }) => {
  if (!allowEmpty && !fundamentosLegales.length) {
    return null;
  }

  if (!fundamentosLegales.length) {
    return (
      <Empty
        description={`No hay ${title.toLowerCase()} disponibles`}
      />
    );
  }

  const uniqueIds = [...new Set(fundamentosLegales.map((f) => f.id))];

  return (
    <>
      <Title level={3} $bold $accent>
        {title}
      </Title>
      <br />
      <ListContainer>
        {uniqueIds.map((id) => {
          const fundamento = fundamentosLegales.find((fl) => fl.id === id);
          return (
            <li key={id}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Text>
                    {fundamentoLegaleFormat(fundamento)}
                  </Text>
                </Col>
                <Col>
                  {fundamento?.nombre_de_ordenamiento?.hipervinculo && (
                    <Button
                      shape="round"
                      onClick={() => {
                        onClickWatchDoc(fundamento.nombre_de_ordenamiento.hipervinculo);
                      }}
                      $accent
                      block
                    >
                      <Text>
                        Consultar Regulación
                      </Text>
                    </Button>
                  )}
                </Col>
              </Row>
            </li>
          );
        })}
      </ListContainer>
    </>
  );
};

const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  
`;

FundamentosLegalesItems.propTypes = {
  title: PropTypes.string.isRequired,
  fundamentosLegales: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    denominacion: PropTypes.string,
    articulo: PropTypes.string,
    nombre_de_ordenamiento: PropTypes.shape({
      descripcion: PropTypes.string,
      hipervinculo: PropTypes.string,
    }),
  })).isRequired,
  allowEmpty: PropTypes.bool,
};

FundamentosLegalesItems.defaultProps = {
  allowEmpty: false,
};

export default FundamentosLegalesItems;
