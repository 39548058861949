import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Empty, Input } from 'antd';
import { Text, Title } from 'components';
import { EnvironmentOutlined, GlobalOutlined, ShopOutlined } from '@ant-design/icons';
import { titleize } from 'utils/formatters';

const { TabPane } = Tabs;

const PasosTab = ({ plantilla }) => {
  if (!plantilla) {
    return null;
  }

  if (!plantilla?.canal_de_presentacion_plantilla) {
    return <Empty description="No hay información disponible de momento, regrese más tarde." />;
  }

  return (
    <>
      <Title $accent level={3}>PASOS A SEGUIR</Title>
      <br />
      <Tabs type="card">
        {plantilla?.canal_de_presentacion_plantilla?.map((paso) => (
          <TabPane
            tab={(
              <Text $accent>
                {paso?.canal_de_presentacion?.id === 2 ? <ShopOutlined /> : <GlobalOutlined />}
                {titleize(paso?.canal_de_presentacion?.descripcion)}
              </Text>
            )}
            key={paso.id}
          >
            <Input.TextArea
              value={paso?.pasos_presenciales || paso?.pasos_por_otro_metodo || ''}
              readOnly
              bordered={false}
              autoSize={{ minRows: 3, maxRows: Number.MAX_SAFE_INTEGER }}
            />
            {paso?.ubicacion && (
              <Text
                $accent
                $bold
              >
                <EnvironmentOutlined />
                {' '}
                {paso?.ubicacion}
              </Text>
            )}
          </TabPane>
        ))}
      </Tabs>
    </>
  );
};

PasosTab.propTypes = {
  plantilla: PropTypes.shape({
    canal_de_presentacion_plantilla: PropTypes.arrayOf(PropTypes.shape({
      canal_de_presentacion: PropTypes.shape({
        descripcion: PropTypes.string,
      }),
      id: PropTypes.number,
      pasos_presenciales: PropTypes.string,
      pasos_por_otro_metodo: PropTypes.string,
    })),
  }),
};

PasosTab.defaultProps = {
  plantilla: {},
};

export default PasosTab;
