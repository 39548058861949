/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Card as AntCard,
  Row,
  Col,
  message,
  Form,
  Input,
  Spin,
  List,
} from 'antd';
import {
  DeleteOutlined,
  DollarCircleOutlined,
  LockFilled,
  SearchOutlined,
  ArrowRightOutlined,
  EyeOutlined,
  ScheduleOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import {
  getPlantillaPDF, getPlantillasNoIndexadas, getTiposDeTramites, monedasMap,
} from 'api/tramites';
import { Link } from 'react-router-dom';
import {
  Button,
  Text,
  Title,
} from 'components';
import Select from 'components/Select';
import PaginatedSelect from 'components/PaginatedSelect';
import { getCanalesDePago, getDepartamentos, getDependencias } from 'api/configuracion';
import { CATALOGOS_DE_CANAL_DE_PAGO, ACCOUNT_AVAILABLE } from 'utils/env';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from 'store/auth';
import LinkWithTooltip from 'components/LinkWithTooltip';
import { selectClasificadoresDeTiposDeCargos } from 'store/catalogos';
import { getRoute } from 'components/Shortcuts';
import isWebView from 'utils/isWebView';
import useQuery from 'hooks/useQuery';
import { listToString, toCurrency } from 'utils/formatters';
import Previewer from 'components/Previewer';
import { toMoment } from 'utils/normalizers';
import Skeletons from './components/SkeletosWrappers';
import CardsCount from './components/CardsCount';

const { SkeletonWrapperText } = Skeletons;

const canInit = (plantilla) => {
  if (plantilla?.catalogo_de_canales_de_pago?.every((c) => /^[0-9]*$/.test(c))) {
    return plantilla?.catalogo_de_canales_de_pago
      .includes(CATALOGOS_DE_CANAL_DE_PAGO.PAGO_EN_LINEA);
  }

  return plantilla?.catalogo_de_canales_de_pago
    .some((c) => c.id === CATALOGOS_DE_CANAL_DE_PAGO.PAGO_EN_LINEA);
};

export const ButtonIniciarTramite = ({
  plantilla,
  block,
  style,
  shape,
}) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const clasificadores = useSelector(selectClasificadoresDeTiposDeCargos);
  if (!plantilla || isWebView()) return null;

  const [tipoDeCargo] = plantilla.tipos_de_cargos || [];
  const subClasificador = tipoDeCargo ? clasificadores.subClasificadores
    .find((c) => c.id === tipoDeCargo.clasificador_de_tipo_de_cargo_en_portal) : null;
  if (subClasificador) {
    const clasificador = clasificadores.clasificadores
      .find((c) => c.clasificador === subClasificador.clasificador.split('.').at(0));
    const to = `/pagos/${getRoute(clasificador)}/${getRoute(subClasificador)}?tipoDeCargo=${tipoDeCargo.id}`;
    return (
      <Button style={style} $accent block={block} shape={shape}>
        <Link to={to} style={{ fontSize: 'inherit', width: 'auto' }}>
          <Text>
            Pagar en línea
            <DollarCircleOutlined style={{ fontSize: 'inherit', color: 'inherit', marginLeft: 5 }} />
          </Text>
        </Link>
      </Button>
    );
  }
  if (ACCOUNT_AVAILABLE && canInit(plantilla)) {
    return (
      <Button style={style} $accent block={block} shape={shape}>
        <LinkWithTooltip
          to={`/tramites/tramite/${plantilla.id}/nueva`}
          text="Iniciar en línea"
          title="Requiere inicio de sesión"
          icon={!isAuthenticated ? LockFilled : ArrowRightOutlined}
        />
      </Button>
    );
  }

  return null;
};

ButtonIniciarTramite.propTypes = {
  plantilla: PropTypes.shape({
    id: PropTypes.number,
    catalogo_de_canales_de_pago_objects: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
    })),
    tipos_de_cargos: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  style: PropTypes.shape({}),
  block: PropTypes.bool,
  shape: PropTypes.string,
};

ButtonIniciarTramite.defaultProps = {
  style: {},
  block: false,
  plantilla: null,
  shape: '',
};

const ButtonActions = ({
  type,
  plantilla,
  block,
  style,
}) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const clasificadores = useSelector(selectClasificadoresDeTiposDeCargos);

  if (!plantilla || isWebView()) return null;

  const [tipoDeCargo] = plantilla.tipos_de_cargos || [];
  const subClasificador = tipoDeCargo ? clasificadores.subClasificadores
    .find((c) => c.id === tipoDeCargo.clasificador_de_tipo_de_cargo_en_portal) : null;

  let to = '';
  let text = '';
  let icon = null;
  const iconStyle = { fontSize: 'inherit', color: 'inherit', marginLeft: 5 };

  if (type === 'iniciarTramite' && subClasificador) {
    const clasificador = clasificadores.clasificadores
      .find((c) => c.clasificador === subClasificador.clasificador.split('.').at(0));
    to = `/pagos/${getRoute(clasificador)}/${getRoute(subClasificador)}?tipoDeCargo=${tipoDeCargo.id}`;
    text = 'Pagar en línea';
    icon = <DollarCircleOutlined style={iconStyle} />;
  } else if (type === 'iniciarTramite' && ACCOUNT_AVAILABLE && canInit(plantilla)) {
    to = `/tramites/tramite/${plantilla.id}/nueva`;
    text = 'Iniciar en línea';
    icon = !isAuthenticated ? LockFilled : ArrowRightOutlined;
    return (
      <Button
        style={style}
        $accent
        block={block}
        shape="round"
      >
        <LinkWithTooltip
          to={to}
          text={text}
          title="Requiere inicio de sesión"
          icon={icon}
        />
      </Button>
    );
  } else if (type === 'verTramite') {
    to = `/tramites/tramite/${plantilla.id}`;
    text = 'Ver trámite';
    icon = <EyeOutlined style={iconStyle} />;
  } else if (type === 'registrarCita') {
    to = `/tramites/tramite/${plantilla.id}/cita`;
    text = 'Registrar cita';
    icon = <ScheduleOutlined style={iconStyle} />;
  } else {
    return null;
  }

  return (
    <Button
      style={style}
      $accent
      block={block}
      shape="round"
    >
      <Link to={to} style={{ fontSize: 'inherit', width: 'auto' }}>
        <Text>
          {text}
          {icon}
        </Text>
      </Link>
    </Button>
  );
};

ButtonActions.propTypes = {
  type: PropTypes.oneOf(['iniciarTramite', 'verTramite', 'registrarCita']).isRequired,
  plantilla: PropTypes.shape({
    id: PropTypes.number,
    tipos_de_cargos: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  style: PropTypes.shape({}),
  block: PropTypes.bool,
};

ButtonActions.defaultProps = {
  style: {},
  block: false,
  plantilla: null,
};

const Tramites = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState({ results: [], count: 0 });
  const [loading, setLoading] = useState(false);
  const [dependenciasTemp, setDependenciasTemp] = useState({ results: [], count: 0 });
  const [departamentosTemp, setDepartamentosTemp] = useState({ results: [], count: 0 });
  const [tiposDeTramites, setTiposDeTramites] = useState([]);
  const [canalesDePago, setCanalesDePago] = useState([]);
  const dependencias = useRef({ results: [] });
  const departamentos = useRef({ results: [] });
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [visiblePreviewer, setVisiblePreviewer] = useState(false);
  const [base64, setBase64] = useState('');
  const [error, setError] = useState('');
  const [q] = useQuery(['q']);
  const [page, setPage] = useState(1);

  const [params, setParams] = useState(null);

  const handleSearch = async (_page = 1, _params = {}) => {
    setLoading(true);
    setPage(_page);
    const paramsForm = form.getFieldsValue();
    const newParams = {
      ...paramsForm,
      ..._params,
      page: _page,
    };
    setParams(newParams);
    if (newParams.filter) {
      delete params.filter;
    }
    if (newParams) {
      const _data = await getPlantillasNoIndexadas(newParams);
      if (_data.count > 0) {
        setData(_data);
      } else {
        message.info('No se encontraron trámites o servicios relacionados.', 3);
      }
    }
    setLoading(false);
  };

  const fetchPlantillas = async (_page = 1, _q = q) => {
    setLoading(true);
    const _data = await getPlantillasNoIndexadas({ page: _page, nombre: _q });
    setData(_data);
    setLoading(false);
  };

  useEffect(() => {
    form.setFieldsValue({ nombre: q });
    let mounted = true;
    const fetchData = async () => {
      setLoading(true);
      const promises = [
        getTiposDeTramites(),
        getDepartamentos(),
        getDependencias({ es_para_multas: false }),
        getCanalesDePago(),
      ];
      if (!q) {
        promises.push(getPlantillasNoIndexadas());
      } else {
        form.setFieldsValue({ nombre: q });
        setTimeout(() => fetchPlantillas());
      }
      const [
        _tiposDeTramites,
        _departamentos,
        _dependencias,
        _canalesDePago,
        _data,
      ] = await Promise.all(promises);
      if (mounted) {
        setTiposDeTramites(_tiposDeTramites);
        setDepartamentosTemp(_departamentos);
        setDependenciasTemp(_dependencias);
        setCanalesDePago(_canalesDePago.filter((e) => [2, 4].includes(e.id)));
        if (!q) {
          setData(_data);
        } else {
          setTimeout(() => handleSearch());
        }
        setLoading(false);
      }
    };
    fetchData();
    return () => { mounted = false; };
  }, []);

  const filter = [
    {
      id: 1,
      descripcion: 'Departamento',
    },
    {
      id: 2,
      descripcion: 'Dependencia',
    },
    {
      id: 3,
      descripcion: 'Tipo de Trámite',
    },
    {
      id: 4,
      descripcion: 'Canal de Atención',
    },
  ];

  const wipe = () => {
    setSelectedFilter(0);
    form.resetFields();
  };

  return (
    <div style={{ margin: 20 }}>
      <Spin spinning={loading}>
        <Col>
          <Form
            layout="vertical"
            form={form}
          >
            <Title level={3}>
              Búsqueda de trámites y servicios
            </Title>
            <Row gutter={10}>
              <Col span={24}>
                <Form.Item name="nombre">
                  <Input
                    disabled={!data.results?.length || loading}
                    onPressEnter={() => handleSearch()}
                    style={{ width: '100%' }}
                    onChange={(e) => {
                      if (!e.target.value) {
                        fetchPlantillas();
                      }
                    }}
                    addonAfter={(
                      <SearchButton
                        loading={loading}
                        onClick={() => handleSearch()}
                        disabled={!data.results?.length}
                      >
                        Buscar
                        <SearchOutlined />
                      </SearchButton>
                    )}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  label="Buscar por"
                  name="filter"
                >
                  <Select
                    disabled={loading}
                    options={filter}
                    onChange={setSelectedFilter}
                  />
                </Form.Item>
              </Col>
              {selectedFilter === 1 && (
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="departamentos"
                    label="Seleccione una opción"
                  >
                    <PaginatedSelect
                      disabled={!data.results?.length || loading}
                      onChange={() => handleSearch()}
                      onClear={() => fetchPlantillas()}
                      dataSource={departamentosTemp.results?.length
                        ? departamentosTemp : departamentos.current}
                      pagination={{
                        getter: getDepartamentos,
                        setter: (dataSource) => {
                          departamentos.current = dataSource;
                        },
                        config: { auto: true },
                      }}
                    />
                  </Form.Item>
                </Col>
              )}
              {selectedFilter === 2 && (
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="dependencia"
                    label="Seleccione una opción"
                  >
                    <PaginatedSelect
                      disabled={!data.results?.length || loading}
                      onChange={() => handleSearch()}
                      onClear={() => fetchPlantillas()}
                      dataSource={dependenciasTemp.results?.length
                        ? dependenciasTemp : dependencias.current}
                      pagination={{
                        getter: getDependencias,
                        setter: (dataSource) => {
                          dependencias.current = dataSource;
                        },
                        config: { auto: true },
                      }}
                    />
                  </Form.Item>
                </Col>
              )}
              {selectedFilter === 3 && (
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name="tipo_de_tramite"
                    label="Seleccione una opción"
                  >
                    <Select
                      disabled={!data.results?.length || loading}
                      options={tiposDeTramites}
                      onChange={() => handleSearch()}
                      onClear={() => fetchPlantillas()}
                    />
                  </Form.Item>
                </Col>
              )}
              {selectedFilter === 4 && (
                <Col xs={24} sm={24} md={12}>
                  <Form.Item name="catalogo_de_canales_de_pago" label="Seleccione una opción">
                    <Select
                      disabled={!data.results?.length || loading}
                      options={canalesDePago || []}
                      onChange={() => handleSearch()}
                      onClear={() => fetchPlantillas()}
                    />
                  </Form.Item>
                </Col>
              )}
              {selectedFilter > 0 && (
                <Col span={12}>
                  <Button onClick={wipe}>
                    <Text>Limpiar campos</Text>
                    <DeleteOutlined />
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
        </Col>
        <br />
        <Row gutter={[10, 10]}>
          <CardsCount callback={(_params) => {
            setParams({});
            handleSearch(1, { ..._params });
          }}
          />
          <Col xs={24} sm={24} md={24} xl={20}>
            <List
              dataSource={data.results}
              itemLayout="vertical"
              pagination={{
                total: data.count,
                pageSize: 10,
                onChange: (_page) => handleSearch(_page),
                current: page,
              }}
              renderItem={(item) => {
                const _departamentos = item?.departamentos?.length
                  ? listToString(item.departamentos.map((d) => d.descripcion)) : '';
                const _depependencias = item?.departamentos?.length
                  ? listToString(item.departamentos.map((d) => d.unidad_operativa.descripcion)) : '';
                const { descripcion_de_pago } = item;

                let montoLabel = '';
                if (item?.es_tramite_gratuito) {
                  montoLabel = item?.descripcion_de_gratuito;
                } else if (item?.es_cuota_fija) {
                  montoLabel = `${toCurrency(item?.cuota_fija, item?.moneda?.id !== monedasMap.PESO)
                    ?? ''} ${item?.moneda?.clave || ''}`.trim();
                } else {
                  montoLabel = `${toCurrency(item?.cuota_variable, item?.moneda?.id !== monedasMap.PESO)
                    ?? ''} ${item.moneda?.clave || ''}`.trim();
                }
                // verify if montoLabel has a number
                if (!/\d/.test(montoLabel) || montoLabel.includes('0.00')) {
                  montoLabel = '';
                }

                return (
                  <CustomCard
                    title={(
                      <Row>
                        <Col
                          span={24}
                          style={{ whiteSpace: 'break-spaces' }}
                        >
                          <Title $bold $accent level={3}>
                            {item.nombre}
                          </Title>
                        </Col>
                        <Col span={24}>
                          <Row align="middle" gutter={10}>
                            <Col
                              xs={24}
                              sm={24}
                              md={16}
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                fontWeight: 400,
                                whiteSpace: 'break-spaces',
                              }}
                            >
                              <SkeletonWrapperText loading={loading} wrap dataLabel="Dependencia">
                                {_depependencias}
                              </SkeletonWrapperText>
                              <SkeletonWrapperText loading={loading} wrap dataLabel="Dpto">
                                {_departamentos}
                              </SkeletonWrapperText>
                              <SkeletonWrapperText loading={loading} wrap dataLabel="Homoclave">
                                {item?.homoclave}
                              </SkeletonWrapperText>
                              <SkeletonWrapperText loading={loading} wrap dataLabel="Costo">
                                {montoLabel}
                                {!item?.es_tramite_gratuito && montoLabel && descripcion_de_pago && ', '}
                                {!item?.es_tramite_gratuito && descripcion_de_pago}
                              </SkeletonWrapperText>
                            </Col>
                            <Col xs={24} sm={24} md={8}>
                              <Row align="middle" gutter={[10, 10]}>
                                <Col span={24}>
                                  <ButtonActions
                                    type="verTramite"
                                    plantilla={item}
                                    block
                                  />
                                </Col>
                                <Col span={24}>
                                  <Button
                                    block
                                    onClick={async () => {
                                      setLoading(true);
                                      await getPlantillaPDF({
                                        id: item.id,
                                        setBase64,
                                        setError,
                                        setVisiblePreviewer,
                                      });
                                      setLoading(false);
                                    }}
                                    shape="round"
                                    $accent
                                  >
                                    <Text
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 10,
                                      }}
                                    >
                                      Descargar
                                      <DownloadOutlined />
                                    </Text>
                                  </Button>
                                </Col>
                                {item.requiere_cita && (
                                <Col span={24}>
                                  <ButtonActions
                                    type="registrarCita"
                                    plantilla={item}
                                    block
                                  />
                                </Col>
                                )}
                                <Col span={24}>
                                  <ButtonActions
                                    type="iniciarTramite"
                                    plantilla={item}
                                    block
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                  >
                    {item.descripcion}
                    <br />
                    <br />
                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>
                        {`Última actualización: ${item?.fecha_de_ultimo_movimiento
                          ? toMoment(item?.fecha_de_ultimo_movimiento)
                            .format('D [de] MMMM [del] YYYY')
                          : ''}`}
                      </span>
                      <span>
                        {`Visitas: ${item?.volumen_de_consultas || 0}`}
                      </span>
                    </Col>
                  </CustomCard>
                );
              }}
            />
          </Col>
        </Row>
        <br />
      </Spin>
      <br />
      <Previewer
        visible={visiblePreviewer}
        onCancel={() => {
          setVisiblePreviewer(false);
          setBase64('');
          setError('');
        }}
        base64={base64}
        error={error}
      />
    </div>
  );
};

const CustomCard = styled(AntCard)`
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-bottom: 10px;
`;

const SearchButton = styled(Button)`
  border: transparent;
  width: 100% !important;
  box-shadow: none;
  background: transparent;
`;

export default Tramites;
