import React, { useEffect, useState } from 'react';
import { DollarOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
  Spin,
  Table,
  Tag,
} from 'antd';
import { Button } from 'components';
import { getAdeudoPadronAsync } from 'hooks/useAdeudoPadron';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/auth';
import debtsFormatter from 'utils/debtsFormatter';
import { useNavigate } from 'react-router';

const MisPagos = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [loadingMessagge, setLoadingMessagge] = useState('Cargando...');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const [_data] = await Promise.all([
        getAdeudoPadronAsync(15, user.id, setLoadingMessagge, true),
      ]);
      if (mounted) {
        setData(debtsFormatter(_data[0].map((c) => ({
          ...c,
          hasAccessories: !!_data[0].find((a) => a.cargo_padre === c.id && a.es_accesorio),
        })).filter((c) => !c.es_accesorio)));
        setLoading(false);
      }
    };
    fetchData();
    return () => { mounted = false; };
  }, []);

  const columns = [
    {
      title: 'Descripción',
      key: 'descripcion',
      dataIndex: 'descripcion',
      render: (text, record) => (
        <Row>
          <Col span={24}>
            {text}
          </Col>
          {record.hasAccessories && (
            <Col span={24}>
              <Tag color="blue">
                Tiene accesorios
              </Tag>
            </Col>
          )}
        </Row>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (record) => (
        <Row justify="end">
          <Button
            icon={<DollarOutlined />}
            $accent
            onClick={() => {
              navigate('/pagos/consulta-de-adeudos/contribuyente-15', {
                state: {
                  padron: 15,
                  padron_RFC: user.rfc,
                  cargo_id: record.id,
                },
              });
            }}
          >
            Realizar Pago
          </Button>
        </Row>
      ),
    },
  ];

  return (
    <Spin spinning={loading} tip={loadingMessagge}>
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
        // rowSelection={rowSelection}
            rowKey="id"
            size="small"
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default MisPagos;
