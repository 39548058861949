import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import padronToString from 'utils/padronesToString';
import {
  Button,
  COLORS,
  Text,
  Title,
} from 'components';
import styled from 'styled-components';
import { SwapOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { selectCiudadano, selectContribuyentes, selectUser } from 'store/auth';
import { getEnumValues, solicitantesEnum, padronesEnum } from 'views/Tramites/GPM/enums';
import ModalSeleccionarSolicitante from 'views/Tramites/GPM/components/ModalSeleccionarSolicitante';
import { AUTH_CONTRIBUYENTE } from 'utils/env';

const PadronSolicitante = ({
  plantillaDeTramite,
  tipoPadronSolicitante,
  setTipoPadronSolicitante,
  padrones,
  padronSolicitante,
  setPadronSolicitante,
  showSwap,
  padronAfectado,
  tipoDePadronAfectado,
  tramite,
  getAdeudo,
}) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const user = useSelector(selectUser);
  const contribuyentes = useSelector(selectContribuyentes) || [];
  const ciudadano = useSelector(selectCiudadano);

  if (!(plantillaDeTramite && user && tipoPadronSolicitante)) {
    return null;
  }
  const canSwap = () => {
    if (AUTH_CONTRIBUYENTE) {
      return tipoPadronSolicitante === solicitantesEnum.CONTRIBUYENTE
        ? !!ciudadano && padrones.some((p) => p.id === solicitantesEnum.CIUDADANO)
        : true;
    }
    return tipoPadronSolicitante === solicitantesEnum.CIUDADANO
      ? contribuyentes.length && padrones.some((p) => p.id === solicitantesEnum.CONTRIBUYENTE)
      : true;
  };
  const _canSwap = !tramite && canSwap();
  const tipoDePadronToSwap = _canSwap ? padrones
    .find((p) => p.id === (tipoPadronSolicitante === solicitantesEnum.CIUDADANO
      ? solicitantesEnum.CONTRIBUYENTE : solicitantesEnum.CIUDADANO)) : null;
  const solicitanteAsString = padronToString(padronSolicitante, tipoPadronSolicitante, true);
  const dividerIndexSolicitante = solicitanteAsString.indexOf(': ');
  const labelPadron = solicitanteAsString.substring(0, dividerIndexSolicitante);
  const descriptorPadron = solicitanteAsString.substring(dividerIndexSolicitante + 2);

  const afectadoAsString = padronToString(padronAfectado, tipoDePadronAfectado, true);
  const dividerIndexAfectado = afectadoAsString.indexOf(': ');
  const labelPadronAfectado = afectadoAsString.substring(0, dividerIndexAfectado);
  const descriptorPadronAfectado = afectadoAsString.substring(dividerIndexAfectado + 2);

  const onChangeSolicitante = (_tipo, _padron) => {
    setTipoPadronSolicitante(_tipo);
    setPadronSolicitante(_padron);
    getAdeudo(_tipo, _padron.id, setPadronSolicitante);
  };

  return (
    <>
      <Container align="middle">
        <Title level={3} strong $accent>
          {plantillaDeTramite.nombre}
        </Title>
        {!!tramite && (
          <Title level={3} strong>
            Folio:
            {' '}
            {tramite.folio}
          </Title>
        )}
        <Text>
          {(!plantillaDeTramite.tipo_de_padron || padronAfectado) ? (
            <>
              {' '}
              {tramite ? 'El trámite fue iniciado' : 'Está por iniciar Trámite'}
              {' '}
              con los siguientes datos de
            </>
          ) : (
            <>
              El trámite
              {' '}
              {tramite ? 'está registrado' : 'se registrará'}
              {' '}
              a nombre de
            </>
          )}
          {padronAfectado && (
            <>
              <br />
              <br />
            </>
          )}
          <strong>
            {' '}
            {labelPadron}
            {': '}
          </strong>
        </Text>
        <Title level={3} strong style={{ paddingTop: padronAfectado ? 0 : null }}>
          {descriptorPadron}
        </Title>
        {!!(_canSwap && showSwap) && (
          <>
            <Text style={{ marginTop: 30 }}>
              {tipoPadronSolicitante !== solicitantesEnum.CIUDADANO && contribuyentes.length > 1 ? (
                <>
                  En dado caso que desee tramitar como
                  {' '}
                  {tipoDePadronToSwap?.descripcion?.toUpperCase()}
                  {' '}
                  o mediante otro
                  {' '}
                  {labelPadron}
                </>
              ) : (
                <>
                  En dado caso que desee tramitar como
                  {' '}
                  {tipoDePadronToSwap?.descripcion?.toUpperCase()}
                </>
              )}
              {' '}
              presione el botón a continuacion
            </Text>
            <Button
              style={{
                marginTop: 10,
                color: COLORS.black,
                border: 'none',
                boxShadow: `1px 1px 2px 1px ${COLORS.cream}`,
              }}
              onClick={() => {
                if (tipoDePadronToSwap.id === solicitantesEnum.CIUDADANO) {
                  onChangeSolicitante(
                    tipoDePadronToSwap.id,
                    ciudadano,
                  );
                } else if (contribuyentes.length === 1 || AUTH_CONTRIBUYENTE) {
                  onChangeSolicitante(
                    tipoDePadronToSwap.id,
                    contribuyentes[0] || user,
                  );
                } else {
                  setVisibleModal(true);
                }
              }}
            >
              {tipoPadronSolicitante !== solicitantesEnum.CIUDADANO && contribuyentes.length > 1 ? (
                <Text>
                  Cambiar TRAMITADOR
                </Text>
              ) : (
                <Text>
                  Tramitar con
                  {' '}
                  {tipoDePadronToSwap?.descripcion?.toUpperCase()}
                </Text>
              )}
              {' '}
              <SwapOutlined />
            </Button>
          </>
        )}
        {padronAfectado && (
          <>
            <Text style={{ paddingTop: padronAfectado ? 10 : null }}>
              Para
              {' '}
              <Text strong>
                {labelPadronAfectado}
                {': '}
              </Text>
            </Text>
            <Title level={3} strong style={{ paddingTop: padronAfectado ? 0 : null }}>
              {descriptorPadronAfectado}
            </Title>
          </>
        )}
        <Text style={{ marginTop: 10, marginBottom: 20 }} strong>
          Para poder concluir tu solicitud correctamente, deberas seguir todos los pasos
          <br />
          hasta completar tu solicitud, la respuesta será enviada a tu correo electrónico.
          <br />
          No olvides revisar la bandeja de spam.
        </Text>
      </Container>
      <ModalSeleccionarSolicitante
        visible={visibleModal}
        emptyListDescriptor="contribuyentes"
        onSelect={(_solicitante, _tipoDeSolicitante) => {
          onChangeSolicitante(_tipoDeSolicitante, _solicitante);
          setVisibleModal(false);
        }}
        onCancel={() => setVisibleModal(false)}
        tipoPadronSolicitante={tipoPadronSolicitante}
        padronSolicitante={padronSolicitante.id}
      />
    </>
  );
};

const Container = styled(Row)`
  & > .ant-typography {
    text-align: start;
    width: 100%;
    padding-top: 30px;
  }
`;

PadronSolicitante.propTypes = {
  plantillaDeTramite: PropTypes.shape({
    nombre: PropTypes.string,
    tipo_de_padron: PropTypes.oneOf(getEnumValues(padronesEnum)),
  }),
  tipoPadronSolicitante: PropTypes.oneOf([1, 15]),
  setTipoPadronSolicitante: PropTypes.func,
  padronSolicitante: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
  setPadronSolicitante: PropTypes.func,
  padrones: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showSwap: PropTypes.bool,
  padronAfectado: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  tipoDePadronAfectado: PropTypes.number,
  tramite: PropTypes.shape({ folio: PropTypes.string.isRequired }),
  getAdeudo: PropTypes.func,
};

PadronSolicitante.defaultProps = {
  plantillaDeTramite: null,
  tipoPadronSolicitante: 1,
  showSwap: true,
  padronAfectado: null,
  tipoDePadronAfectado: null,
  setTipoPadronSolicitante: null,
  setPadronSolicitante: null,
  tramite: null,
  getAdeudo: null,
};

export default PadronSolicitante;
