import React from 'react';
import { Result } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { COLORS, Title } from 'components';

const ComingSoon = () => (
  <div style={{
    height: '50vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }}
  >
    <Result
      icon={<InfoCircleOutlined style={{ color: COLORS.accent }} />}
      title={(<Title level={3} $accent>Disponible proximamente</Title>)}
    />
  </div>
);

export default ComingSoon;
