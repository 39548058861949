/* eslint-disable no-param-reassign */
import axios from 'axios';
import { selectToken, setTokenIsExpired } from 'store/auth';
import store from 'store/store';
import {
  SCHEME,
  APIHOST,
  PATH,
  ID_ENTIDAD,
} from 'utils/env';
import jwt_decode from 'jwt-decode';

export const baseURL = `${SCHEME}://${APIHOST}${PATH}`;

const defaultOptions = {
  timeout: 90000,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const createAxiosInstance = (base) => axios.create({ ...defaultOptions, baseURL: base });

const http = createAxiosInstance(baseURL);

// endpoint que se pueden usar sin estar loggeado
const guessEndpoints = [
  'usuarios/lada/',
  'catalogos/tipos-de-personas/',
  'catalogos/padrones/',
  'empresas/regimen-fiscal/',
  'recaudacion/recibo/generar-recibo-por-referencia-de-pago-netpay/',
  'recaudacion/generar-cargos-con-canal-de-pago/',
  'recaudacion/recibos-externo/',
  'recaudacion/facturacion-externa/',
  'recaudacion/referencias-de-pago/folio/',
  'tramites/plantillas-de-tramites-indexada/',
  'tramites/firmado-automatico/',
  'recaudacion/servicios-del-vehiculo/',
  'recaudacion/estados-del-vehiculo/',
  'recaudacion/tipos-de-vehiculos/',
  'recaudacion/tipos-de-tenencias-vehiculares/',
  'recaudacion/marcas-de-vehiculos/',
  'catalogos/clave-de-documento-de-regularizacion/',
  'catalogos/versiones-vehiculares/',
  'catalogos/tipos-de-combustibles-vehiculares/',
  'catalogos/catalogos-de-paises/',
  'catalogos/usos-de-motocicletas/',
  // 'catalogos/catalogos-de-colores-vehiculares/',
  '/recaudacion/lineas-vehiculares/',
  '/tramites/plantillas-de-tramites-portal/',
  'recaudacion/referencias-de-pago-public/',
  '/solicitudes/motivos-de-las-quejas/',
  '/solicitudes/categorias-de-las-quejas/',
  '/solicitudes/testigos-de-quejas-public/',
  '/solicitudes/quejas-public/',
  '/solicitudes/evidencias-de-quejas-public/',
  '/usuarios/tipo-vialidad/',
  '/catalogos/estados-del-padron/',
  // '/empresas/tipos-de-establecimientos/',
  '/catalogos/catalogos-del-mes/',
  '/empresas/tipos-de-declaraciones-empresariales/',
  '/cuentaunicasir/vehiculos-portal/',
  '/recaudacion/registros-de-errores/',
  '/tramites/plantillas-de-tramites-conteo/conteo/',
];

// endpoint que deben contener el param de entidad
const urlsWithEntidadParamByMethod = {
  get: [
    'catalogos/nombre-de-ordenamiento',
    'configuracion/dependencias',
    'recaudacion/tipos-de-cargos-public',
    'recaudacion/clasificadores-de-tipos-de-cargo-portal-public',
    'empresas/declaraciones-empresariales-public',
    'catalogos/periodos-fiscales-public',
    'configuracion/caar-public',
    'recaudacion/recibos-externo',
    'tramites/plantillas-de-tramites-indexada',
    'tramites/tipos-de-tramites-public',
    'recaudacion/citas-public',
    'catalogos/catalogos-de-colores-vehiculares',
    'recaudacion/declaraciones-empresariales-isan-public',
    'recaudacion/lineas-vehiculares',
  ],
  post: [
    'empresas/contribuyente-public',
    'cuentaunicasir/ciudadano-public',
    'recaudacion/recibo/generar-recibo-por-referencia-de-pago-netpay',
    'empresas/declaraciones-empresariales-public',
    'recaudacion/consulta-caja-public/entidad',
    'recaudacion/generar-cargos-con-canal-de-pago',
    'recaudacion/referencias-pago-netpay-public',
    'recaudacion/facturacion-externa',
    'recaudacion/referencias-de-pago/folio',
    'recaudacion/recibo-ticket-public/pdfs',
    'recaudacion/citas-public',
  ],
  patch: [
    'empresas/contribuyente-public',
    'cuentaunicasir/ciudadano-public',
    'tramites/plantillas-de-tramites-indexada',
    'recaudacion/citas-public',
  ],
};

const requestInterceptor = async (options) => {
  if (options.url[options.url.length - 1] !== '/') {
    options.url = `${options.url}/`;
  }
  const fullurl = `${options.baseURL}${options.url}`;
  const url = new URL(fullurl);
  const includeInUrls = urlsWithEntidadParamByMethod[options.method] || [];
  const includeEntidadParam = /caja/.test(url.pathname)
    || includeInUrls.some((r) => url.pathname.includes(r));
  if (includeEntidadParam) {
    url.searchParams.set('entidad', ID_ENTIDAD);
  }

  const token = selectToken(store.getState());
  const guessEndpoint = /public|indexada|caja|auth/.test(url.pathname)
    || guessEndpoints.some((r) => url.pathname.includes(r));
  if (token && !guessEndpoint) {
    const decoded = jwt_decode(token);
    const current = new Date();
    const expiration = new Date(decoded.exp * 1000);
    const isExpired = current.getTime() >= expiration.getTime();
    options.headers.Authorization = `Bearer ${token}`;
    if (isExpired) {
      store.dispatch(setTokenIsExpired());
      return Promise.reject(new Error('Sesión expirada'));
    }
    // si esta loggeado no debe ir param entidad
    url.searchParams.delete('entidad');
  }
  options.url = url.toString().replace(options.baseURL, '');
  return options;
};

const onRejected = async (err) => {
  if (err.response?.status === 502 || err.code === 'ERR_NETWORK') {
    return axios.request(err.config);
  }
  return Promise.reject(err);
};

const excludeFakeEmail = (_padron) => {
  const padron = { ..._padron };
  const emailFields = Object.keys(padron).filter((k) => /email|correo_electronico/.test(k));
  emailFields.forEach((field) => {
    if (padron[field] === 'some@nayarit.com.mx') {
      padron[field] = null;
    }
  });
  const ciudadanoContribuyenteFields = Object.keys(padron).filter((k) => /ciudadano|contribuyente/.test(k));
  ciudadanoContribuyenteFields.forEach((field) => {
    if (padron[field] && typeof padron[field] === 'object') {
      padron[field] = excludeFakeEmail(padron[field]);
    }
  });
  return padron;
};

const onResponse = (response) => {
  if (/caja-public/.test(response.config.url)) {
    if (response.data.results) {
      response.data.results = response.data.results.map(excludeFakeEmail);
    } else if (Array.isArray(response.data)) {
      response.data = response.data.map(excludeFakeEmail);
    }
  }
  return response;
};

http.interceptors.response.use(onResponse, onRejected);
http.interceptors.request.use(requestInterceptor);

export default http;
