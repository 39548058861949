import React, { useEffect, useState } from 'react';
import {
  Button,
  COLORS,
  Tabs,
  Text,
} from 'components';
import {
  Col,
  Row,
  Grid,
  List,
  Tag,
  message,
  Divider,
} from 'antd';
import { EyeOutlined, SwapRightOutlined } from '@ant-design/icons';
import { getTramites } from 'api/tramites/tramites';
import { tiposDePasosEnum } from 'views/Tramites/GPM/enums';
import Moment from 'moment';
import { getExpedientesDeTramites } from 'api/tramites/expediente';
import Previewer from 'components/Previewer';
import { selectUser } from 'store/auth';
import { useSelector } from 'react-redux';
import { getDocumentosDeTramites } from 'api/tramites/documentosDeTramites';

const MiExpediente = () => {
  const user = useSelector(selectUser);
  const screens = Grid.useBreakpoint();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [archivo, setArchivo] = useState(null);
  const [base64, setBase64] = useState('');
  const [requisitos, setRequisitos] = useState([]);

  const consultarArchivoDeTramite = async (tramite) => {
    setLoading(true);
    const [_archivo] = await getExpedientesDeTramites({ tramite });
    if (_archivo) {
      setArchivo(_archivo);
    } else {
      message.warning('No se encontró el archivo');
    }
    setLoading(false);
  };

  const mapTramites = (tramite) => {
    const {
      id,
      folio,
      plantilla,
      fecha_de_inicio,
      fecha_de_finalizacion,
    } = tramite;

    return {
      id,
      folio,
      plantilla,
      nombre: plantilla.nombre,
      esTramite: true,
      fecha_inicio: Moment(fecha_de_inicio).format('DD/MM/YYYY'),
      fecha_fin: Moment(fecha_de_finalizacion).format('DD/MM/YYYY'),
      acciones: [
        <Button
          $accent
          onClick={() => consultarArchivoDeTramite(id)}
        >
          Ver Documento
          <EyeOutlined />
        </Button>,
      ],
    };
  };

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const [
        _documentosDeTramites,
        _tramites,
      ] = await Promise.all([
        getDocumentosDeTramites({ padron_object_id: user.id }),
        getTramites({ estados_globales: 4 }, true),
      ]);
      const tramites = _tramites.map(mapTramites);
      if (mounted) {
        setRequisitos(_documentosDeTramites);
        setData(tramites);
        setLoading(false);
      }
    };
    fetchData();
    return () => { mounted = false; };
  }, []);

  return (
    <>
      <Tabs type="card">
        <Tabs.TabPane tab="Mis Documentos" key="misDocumentos">
          <Divider orientation="left">
            <Text $bold $accent>
              Documentos de Tramites
            </Text>
          </Divider>
          <List
            loading={loading}
            itemLayout="horizontal"
            locale={{ emptyText: 'No hay documentos' }}
            rowKey="id"
            dataSource={data}
            style={{ width: '100%' }}
            renderItem={({
              folio,
              nombre,
              fecha_inicio,
              fecha_fin,
              acciones,
              plantilla,
            }) => (
              <List.Item actions={acciones}>
                <List.Item.Meta
                  title={(
                    <Text $bold>
                      {folio}
                      {' - '}
                      {nombre || 'Sin nombre'}
                      {(plantilla?.pasos_para_tramites
                        ?.at(-1)?.tipo_de_paso === tiposDePasosEnum.EMISION_DE_DOCUMENTO) && (
                        <Tag style={{ marginLeft: 10 }}>
                          Emite documento
                        </Tag>
                      )}
                    </Text>
                  )}
                  description={(
                    <Row gutter={screens.md ? 16 : 0}>
                      <Col>
                        <Tag color={COLORS.accent}>
                          Fecha de inicio:
                          {' '}
                          {fecha_inicio}
                        </Tag>
                      </Col>
                      <Col>
                        <SwapRightOutlined />
                      </Col>
                      <Col>
                        <Tag color={COLORS.accent}>
                          Fecha de fin:
                          {' '}
                          {fecha_fin}
                        </Tag>
                      </Col>
                    </Row>
                  )}
                />
              </List.Item>
            )}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Mis Requisitos" key="misRequisitos">
          <List
            loading={loading}
            itemLayout="horizontal"
            locale={{ emptyText: 'No hay constancias' }}
            rowKey="id"
            dataSource={requisitos}
            style={{ width: '100%' }}
            renderItem={({
              archivo: _archivo,
              ultima_fecha_de_modificacion,
              catalogo_de_requisitos,
            }) => (
              <List.Item actions={[
                <Button
                  $accent
                  $block
                  onClick={() => { setArchivo(_archivo); }}
                >
                  Ver Documento
                  <EyeOutlined />
                </Button>,
              ]}
              >
                <List.Item.Meta
                  title={(
                    <Text $bold>
                      {`${catalogo_de_requisitos?.nombre ?? 'Sin descripción'}`}
                    </Text>
                  )}
                  description={(
                    <Row gutter={screens.md ? 16 : 0}>
                      <Col>
                        <Tag color={COLORS.accent}>
                          Fecha de emisión:
                          {' '}
                          {Moment(ultima_fecha_de_modificacion).format('DD/MM/YYYY')}
                        </Tag>
                      </Col>
                    </Row>
                  )}
                />
              </List.Item>
            )}
          />
        </Tabs.TabPane>
      </Tabs>
      <Previewer
        visible={!!archivo || !!base64}
        onCancel={() => {
          setArchivo(null);
          setBase64('');
        }}
        iFrameUrl={archivo?.archivo ?? archivo}
        base64={base64}
      />
    </>
  );
};

export default MiExpediente;
