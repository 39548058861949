/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
const _ENV_NAME = process.env.REACT_APP_ENV_NAME;
const REPUVE = process.env.REACT_APP_REPUVE;

const ENV_NAME = REPUVE ? 'repuve' : _ENV_NAME;

// logos
export const logo = require(`./${ENV_NAME}/logo-header.svg`).default;
export const logoHeader = require(`./${ENV_NAME}/logo-finanzas.svg`).default;

// miscelaneous
export const bg = require(`./${_ENV_NAME}/bg.png`).default;
export const asistencia = require(`./${_ENV_NAME}/asistencia-contribuyente.svg`).default;
